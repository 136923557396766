import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { LocalDateC } from "../../codecs/localDate";

export const invoiceC = t.type({
  invoiceDate: LocalDateC,
  mediaId: t.number,
  stripeId: optionFromNullable(t.string),
  paid: t.boolean,
  amountDue: t.number,
  netSuite: t.boolean
});
export type InvoiceC = typeof invoiceC;
export type Invoice = t.TypeOf<InvoiceC>;


export const invoiceNotificationEmailC = t.type({
  email: t.string
});
export type InvoiceNotificationEmailC = typeof invoiceNotificationEmailC;
export type InvoiceNotificationEmail = t.TypeOf<InvoiceNotificationEmailC>;


