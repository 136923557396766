import { constant } from "@scripts/fp-ts";

export const noneStr = "—";
export const noneStrConst = constant(noneStr);

export const naStr = "N/A";
export const naStrConst = constant(naStr);

export const emptyStr = "";
export const emptyStrConst = constant(emptyStr);
