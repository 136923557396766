import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const success = {
  _tag: `Success`
} as const;

export const successTaggedC = t.type({
  _tag: t.literal(`Success`)
});
export type SuccessTaggedC = typeof successTaggedC;
export type SuccessTagged = t.TypeOf<SuccessTaggedC>;
export type Success = SuccessTagged & typeof success;
export const successC = pipe(successTaggedC, c => new t.Type<Success, SuccessTagged>(
  `Success`,
  (u: unknown): u is Success => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Success> => pipe(c.decode(u), E.map(x => ({ ...x, ...success }))),
  (x: Success): SuccessTagged => ({ ...x, _tag: `Success`}),
));
export type SuccessC = typeof successC;


export const info = {
  _tag: `Info`
} as const;

export const infoTaggedC = t.type({
  _tag: t.literal(`Info`)
});
export type InfoTaggedC = typeof infoTaggedC;
export type InfoTagged = t.TypeOf<InfoTaggedC>;
export type Info = InfoTagged & typeof info;
export const infoC = pipe(infoTaggedC, c => new t.Type<Info, InfoTagged>(
  `Info`,
  (u: unknown): u is Info => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Info> => pipe(c.decode(u), E.map(x => ({ ...x, ...info }))),
  (x: Info): InfoTagged => ({ ...x, _tag: `Info`}),
));
export type InfoC = typeof infoC;


export const warning = {
  _tag: `Warning`
} as const;

export const warningTaggedC = t.type({
  _tag: t.literal(`Warning`)
});
export type WarningTaggedC = typeof warningTaggedC;
export type WarningTagged = t.TypeOf<WarningTaggedC>;
export type Warning = WarningTagged & typeof warning;
export const warningC = pipe(warningTaggedC, c => new t.Type<Warning, WarningTagged>(
  `Warning`,
  (u: unknown): u is Warning => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Warning> => pipe(c.decode(u), E.map(x => ({ ...x, ...warning }))),
  (x: Warning): WarningTagged => ({ ...x, _tag: `Warning`}),
));
export type WarningC = typeof warningC;


export const danger = {
  _tag: `Danger`
} as const;

export const dangerTaggedC = t.type({
  _tag: t.literal(`Danger`)
});
export type DangerTaggedC = typeof dangerTaggedC;
export type DangerTagged = t.TypeOf<DangerTaggedC>;
export type Danger = DangerTagged & typeof danger;
export const dangerC = pipe(dangerTaggedC, c => new t.Type<Danger, DangerTagged>(
  `Danger`,
  (u: unknown): u is Danger => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Danger> => pipe(c.decode(u), E.map(x => ({ ...x, ...danger }))),
  (x: Danger): DangerTagged => ({ ...x, _tag: `Danger`}),
));
export type DangerC = typeof dangerC;


export const allAlertTypeC = [successC, infoC, warningC, dangerC] as const;
export const allAlertTypeNames = [`Success`, `Info`, `Warning`, `Danger`] as const;
export type AlertTypeName = (typeof allAlertTypeNames)[number];

export const AlertTypeCU = t.union([successC, infoC, warningC, dangerC]);
export type AlertTypeCU = typeof AlertTypeCU;
export type AlertTypeU = t.TypeOf<AlertTypeCU>;

export const alertTypeOrd: Ord.Ord<AlertTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allAlertType = [success, info, warning, danger] as const;
export type AlertTypeMap<A> = { [K in AlertTypeName]: A };


