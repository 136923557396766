import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const refunding = {
  _tag: `Refunding`,
  id: 1,
  name: `Refunding`
} as const;

export const refundingTaggedC = t.type({
  _tag: t.literal(`Refunding`)
});
export type RefundingTaggedC = typeof refundingTaggedC;
export type RefundingTagged = t.TypeOf<RefundingTaggedC>;
export type Refunding = RefundingTagged & typeof refunding;
export const refundingC = pipe(refundingTaggedC, c => new t.Type<Refunding, RefundingTagged>(
  `Refunding`,
  (u: unknown): u is Refunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Refunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...refunding }))),
  (x: Refunding): RefundingTagged => ({ ...x, _tag: `Refunding`}),
));
export type RefundingC = typeof refundingC;


export const advancedRefunding = {
  _tag: `AdvancedRefunding`,
  id: 2,
  name: `Advanced refunding`
} as const;

export const advancedRefundingTaggedC = t.type({
  _tag: t.literal(`AdvancedRefunding`)
});
export type AdvancedRefundingTaggedC = typeof advancedRefundingTaggedC;
export type AdvancedRefundingTagged = t.TypeOf<AdvancedRefundingTaggedC>;
export type AdvancedRefunding = AdvancedRefundingTagged & typeof advancedRefunding;
export const advancedRefundingC = pipe(advancedRefundingTaggedC, c => new t.Type<AdvancedRefunding, AdvancedRefundingTagged>(
  `AdvancedRefunding`,
  (u: unknown): u is AdvancedRefunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AdvancedRefunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...advancedRefunding }))),
  (x: AdvancedRefunding): AdvancedRefundingTagged => ({ ...x, _tag: `AdvancedRefunding`}),
));
export type AdvancedRefundingC = typeof advancedRefundingC;


export const advancedRefundingSlashNewFinancing = {
  _tag: `AdvancedRefundingSlashNewFinancing`,
  id: 3,
  name: `Advanced refunding/New financing`
} as const;

export const advancedRefundingSlashNewFinancingTaggedC = t.type({
  _tag: t.literal(`AdvancedRefundingSlashNewFinancing`)
});
export type AdvancedRefundingSlashNewFinancingTaggedC = typeof advancedRefundingSlashNewFinancingTaggedC;
export type AdvancedRefundingSlashNewFinancingTagged = t.TypeOf<AdvancedRefundingSlashNewFinancingTaggedC>;
export type AdvancedRefundingSlashNewFinancing = AdvancedRefundingSlashNewFinancingTagged & typeof advancedRefundingSlashNewFinancing;
export const advancedRefundingSlashNewFinancingC = pipe(advancedRefundingSlashNewFinancingTaggedC, c => new t.Type<AdvancedRefundingSlashNewFinancing, AdvancedRefundingSlashNewFinancingTagged>(
  `AdvancedRefundingSlashNewFinancing`,
  (u: unknown): u is AdvancedRefundingSlashNewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AdvancedRefundingSlashNewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...advancedRefundingSlashNewFinancing }))),
  (x: AdvancedRefundingSlashNewFinancing): AdvancedRefundingSlashNewFinancingTagged => ({ ...x, _tag: `AdvancedRefundingSlashNewFinancing`}),
));
export type AdvancedRefundingSlashNewFinancingC = typeof advancedRefundingSlashNewFinancingC;


export const currentRefunding = {
  _tag: `CurrentRefunding`,
  id: 4,
  name: `Current refunding`
} as const;

export const currentRefundingTaggedC = t.type({
  _tag: t.literal(`CurrentRefunding`)
});
export type CurrentRefundingTaggedC = typeof currentRefundingTaggedC;
export type CurrentRefundingTagged = t.TypeOf<CurrentRefundingTaggedC>;
export type CurrentRefunding = CurrentRefundingTagged & typeof currentRefunding;
export const currentRefundingC = pipe(currentRefundingTaggedC, c => new t.Type<CurrentRefunding, CurrentRefundingTagged>(
  `CurrentRefunding`,
  (u: unknown): u is CurrentRefunding => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CurrentRefunding> => pipe(c.decode(u), E.map(x => ({ ...x, ...currentRefunding }))),
  (x: CurrentRefunding): CurrentRefundingTagged => ({ ...x, _tag: `CurrentRefunding`}),
));
export type CurrentRefundingC = typeof currentRefundingC;


export const currentRefundingSlashNewFinancing = {
  _tag: `CurrentRefundingSlashNewFinancing`,
  id: 5,
  name: `Current refunding/New financing`
} as const;

export const currentRefundingSlashNewFinancingTaggedC = t.type({
  _tag: t.literal(`CurrentRefundingSlashNewFinancing`)
});
export type CurrentRefundingSlashNewFinancingTaggedC = typeof currentRefundingSlashNewFinancingTaggedC;
export type CurrentRefundingSlashNewFinancingTagged = t.TypeOf<CurrentRefundingSlashNewFinancingTaggedC>;
export type CurrentRefundingSlashNewFinancing = CurrentRefundingSlashNewFinancingTagged & typeof currentRefundingSlashNewFinancing;
export const currentRefundingSlashNewFinancingC = pipe(currentRefundingSlashNewFinancingTaggedC, c => new t.Type<CurrentRefundingSlashNewFinancing, CurrentRefundingSlashNewFinancingTagged>(
  `CurrentRefundingSlashNewFinancing`,
  (u: unknown): u is CurrentRefundingSlashNewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CurrentRefundingSlashNewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...currentRefundingSlashNewFinancing }))),
  (x: CurrentRefundingSlashNewFinancing): CurrentRefundingSlashNewFinancingTagged => ({ ...x, _tag: `CurrentRefundingSlashNewFinancing`}),
));
export type CurrentRefundingSlashNewFinancingC = typeof currentRefundingSlashNewFinancingC;


export const newFinancing = {
  _tag: `NewFinancing`,
  id: 6,
  name: `New financing`
} as const;

export const newFinancingTaggedC = t.type({
  _tag: t.literal(`NewFinancing`)
});
export type NewFinancingTaggedC = typeof newFinancingTaggedC;
export type NewFinancingTagged = t.TypeOf<NewFinancingTaggedC>;
export type NewFinancing = NewFinancingTagged & typeof newFinancing;
export const newFinancingC = pipe(newFinancingTaggedC, c => new t.Type<NewFinancing, NewFinancingTagged>(
  `NewFinancing`,
  (u: unknown): u is NewFinancing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewFinancing> => pipe(c.decode(u), E.map(x => ({ ...x, ...newFinancing }))),
  (x: NewFinancing): NewFinancingTagged => ({ ...x, _tag: `NewFinancing`}),
));
export type NewFinancingC = typeof newFinancingC;


export const remarketingSlashConversion = {
  _tag: `RemarketingSlashConversion`,
  id: 7,
  name: `Remarketing/Conversion`
} as const;

export const remarketingSlashConversionTaggedC = t.type({
  _tag: t.literal(`RemarketingSlashConversion`)
});
export type RemarketingSlashConversionTaggedC = typeof remarketingSlashConversionTaggedC;
export type RemarketingSlashConversionTagged = t.TypeOf<RemarketingSlashConversionTaggedC>;
export type RemarketingSlashConversion = RemarketingSlashConversionTagged & typeof remarketingSlashConversion;
export const remarketingSlashConversionC = pipe(remarketingSlashConversionTaggedC, c => new t.Type<RemarketingSlashConversion, RemarketingSlashConversionTagged>(
  `RemarketingSlashConversion`,
  (u: unknown): u is RemarketingSlashConversion => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RemarketingSlashConversion> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketingSlashConversion }))),
  (x: RemarketingSlashConversion): RemarketingSlashConversionTagged => ({ ...x, _tag: `RemarketingSlashConversion`}),
));
export type RemarketingSlashConversionC = typeof remarketingSlashConversionC;


export const allMuniIssueTypeC = [refundingC, advancedRefundingC, advancedRefundingSlashNewFinancingC, currentRefundingC, currentRefundingSlashNewFinancingC, newFinancingC, remarketingSlashConversionC] as const;
export const allMuniIssueTypeNames = [`Refunding`, `AdvancedRefunding`, `AdvancedRefundingSlashNewFinancing`, `CurrentRefunding`, `CurrentRefundingSlashNewFinancing`, `NewFinancing`, `RemarketingSlashConversion`] as const;
export type MuniIssueTypeName = (typeof allMuniIssueTypeNames)[number];

export const MuniIssueTypeCU = t.union([refundingC, advancedRefundingC, advancedRefundingSlashNewFinancingC, currentRefundingC, currentRefundingSlashNewFinancingC, newFinancingC, remarketingSlashConversionC]);
export type MuniIssueTypeCU = typeof MuniIssueTypeCU;
export type MuniIssueTypeU = t.TypeOf<MuniIssueTypeCU>;

export const muniIssueTypeOrd: Ord.Ord<MuniIssueTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniIssueType = [refunding, advancedRefunding, advancedRefundingSlashNewFinancing, currentRefunding, currentRefundingSlashNewFinancing, newFinancing, remarketingSlashConversion] as const;
export type MuniIssueTypeMap<A> = { [K in MuniIssueTypeName]: A };


