import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import * as t from "io-ts";
import { mediaC as imported64_mediaC, mediaUploadResponseC as imported25_mediaUploadResponseC } from "./media";

export const officerC = t.type({
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  media: optionFromNullable(imported64_mediaC)
});
export type OfficerC = typeof officerC;
export type Officer = t.TypeOf<OfficerC>;


export const officerPostBodyC = t.type({
  id: optionFromNullable(t.number),
  firstName: t.string,
  lastName: t.string,
  title: t.string,
  email: optionFromNullable(t.string),
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  twitterUrl: optionFromNullable(t.string),
  linkedinUrl: optionFromNullable(t.string),
  blurb: optionFromNullable(markdownC),
  issuerOrder: t.number,
  officerPageVisibility: t.boolean,
  media: optionFromNullable(imported25_mediaUploadResponseC)
});
export type OfficerPostBodyC = typeof officerPostBodyC;
export type OfficerPostBody = t.TypeOf<OfficerPostBodyC>;


export const postPrimaryOfficerC = t.type({
  id: t.number
});
export type PostPrimaryOfficerC = typeof postPrimaryOfficerC;
export type PostPrimaryOfficer = t.TypeOf<PostPrimaryOfficerC>;


export const welcomeLetterSigneeC = t.type({
  welcomeSigneeId: optionFromNullable(t.number)
});
export type WelcomeLetterSigneeC = typeof welcomeLetterSigneeC;
export type WelcomeLetterSignee = t.TypeOf<WelcomeLetterSigneeC>;


