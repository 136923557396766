import { WithStatusCU as imported249_WithStatusCU, WithStatusCU as imported250_WithStatusCU, WithStatusCU as imported251_WithStatusCU, WithStatusCU as imported252_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported48_mediaC, mediaC as imported49_mediaC, imageUploadResponseC as imported6_imageUploadResponseC } from "./media";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import { sortOrderC as imported22_sortOrderC } from "./sortOrder";
import { CustomPageCU as imported3_CustomPageCU, CustomPageCU as imported2_CustomPageCU, BucketCU as imported7_BucketCU, BucketCU as imported8_BucketCU, BucketCU as imported6_BucketCU } from "../domaintables/pages";
import { dataAndMetaC as imported3_dataAndMetaC } from "./relatedContent";
import { clientTextItemC as imported18_clientTextItemC, clientTextItemC as imported19_clientTextItemC } from "./clientTextItem";

export const customPageC = t.type({
  index: t.number,
  title: t.string,
  bucket: imported6_BucketCU
});
export type CustomPageC = typeof customPageC;
export type CustomPage = t.TypeOf<CustomPageC>;


export const customPagesC = t.type({
  customPages: t.readonlyArray(customPageC)
});
export type CustomPagesC = typeof customPagesC;
export type CustomPages = t.TypeOf<CustomPagesC>;


export const customPageDataIdC = t.type({
  page: imported2_CustomPageCU,
  title: t.string,
  bucket: imported7_BucketCU,
  data: imported249_WithStatusCU(imported18_clientTextItemC),
  photos: t.readonlyArray(imported250_WithStatusCU(imported48_mediaC))
});
export type CustomPageDataIdC = typeof customPageDataIdC;
export type CustomPageDataId = t.TypeOf<CustomPageDataIdC>;


export const customPageDataOC = t.type({
  page: imported3_CustomPageCU,
  title: t.string,
  bucket: imported8_BucketCU,
  data: optionFromNullable(imported251_WithStatusCU(imported19_clientTextItemC)),
  photos: t.readonlyArray(imported252_WithStatusCU(imported49_mediaC))
});
export type CustomPageDataOC = typeof customPageDataOC;
export type CustomPageDataO = t.TypeOf<CustomPageDataOC>;


export const customPagePostBodyC = t.type({
  customPageIndex: t.number,
  title: t.string,
  parentSectionId: t.number,
  hidden: t.boolean,
  data: markdownC,
  photos: t.readonlyArray(imported3_dataAndMetaC(imported6_imageUploadResponseC, imported22_sortOrderC))
});
export type CustomPagePostBodyC = typeof customPagePostBodyC;
export type CustomPagePostBody = t.TypeOf<CustomPagePostBodyC>;


