import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondSaleDocuments = {
  _tag: `BondSaleDocuments`,
  description: `Bond Sale Documents (POS, OS, NOS)`,
  id: 6
} as const;

export const bondSaleDocumentsTaggedC = t.type({
  _tag: t.literal(`BondSaleDocuments`)
});
export type BondSaleDocumentsTaggedC = typeof bondSaleDocumentsTaggedC;
export type BondSaleDocumentsTagged = t.TypeOf<BondSaleDocumentsTaggedC>;
export type BondSaleDocuments = BondSaleDocumentsTagged & typeof bondSaleDocuments;
export const bondSaleDocumentsC = pipe(bondSaleDocumentsTaggedC, c => new t.Type<BondSaleDocuments, BondSaleDocumentsTagged>(
  `BondSaleDocuments`,
  (u: unknown): u is BondSaleDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSaleDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSaleDocuments }))),
  (x: BondSaleDocuments): BondSaleDocumentsTagged => ({ ...x, _tag: `BondSaleDocuments`}),
));
export type BondSaleDocumentsC = typeof bondSaleDocumentsC;


export const aCFRDocuments = {
  _tag: `ACFRDocuments`,
  description: `Annual Comprehensive Financial Reports`,
  id: 7
} as const;

export const aCFRDocumentsTaggedC = t.type({
  _tag: t.literal(`ACFRDocuments`)
});
export type ACFRDocumentsTaggedC = typeof aCFRDocumentsTaggedC;
export type ACFRDocumentsTagged = t.TypeOf<ACFRDocumentsTaggedC>;
export type ACFRDocuments = ACFRDocumentsTagged & typeof aCFRDocuments;
export const aCFRDocumentsC = pipe(aCFRDocumentsTaggedC, c => new t.Type<ACFRDocuments, ACFRDocumentsTagged>(
  `ACFRDocuments`,
  (u: unknown): u is ACFRDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ACFRDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...aCFRDocuments }))),
  (x: ACFRDocuments): ACFRDocumentsTagged => ({ ...x, _tag: `ACFRDocuments`}),
));
export type ACFRDocumentsC = typeof aCFRDocumentsC;


export const financialStatementsDocuments = {
  _tag: `FinancialStatementsDocuments`,
  description: `Cash-Based or Unaudited Financial Statements`,
  id: 8
} as const;

export const financialStatementsDocumentsTaggedC = t.type({
  _tag: t.literal(`FinancialStatementsDocuments`)
});
export type FinancialStatementsDocumentsTaggedC = typeof financialStatementsDocumentsTaggedC;
export type FinancialStatementsDocumentsTagged = t.TypeOf<FinancialStatementsDocumentsTaggedC>;
export type FinancialStatementsDocuments = FinancialStatementsDocumentsTagged & typeof financialStatementsDocuments;
export const financialStatementsDocumentsC = pipe(financialStatementsDocumentsTaggedC, c => new t.Type<FinancialStatementsDocuments, FinancialStatementsDocumentsTagged>(
  `FinancialStatementsDocuments`,
  (u: unknown): u is FinancialStatementsDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialStatementsDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialStatementsDocuments }))),
  (x: FinancialStatementsDocuments): FinancialStatementsDocumentsTagged => ({ ...x, _tag: `FinancialStatementsDocuments`}),
));
export type FinancialStatementsDocumentsC = typeof financialStatementsDocumentsC;


export const budgetDocuments = {
  _tag: `BudgetDocuments`,
  description: `Budget Documents and Reports`,
  id: 9
} as const;

export const budgetDocumentsTaggedC = t.type({
  _tag: t.literal(`BudgetDocuments`)
});
export type BudgetDocumentsTaggedC = typeof budgetDocumentsTaggedC;
export type BudgetDocumentsTagged = t.TypeOf<BudgetDocumentsTaggedC>;
export type BudgetDocuments = BudgetDocumentsTagged & typeof budgetDocuments;
export const budgetDocumentsC = pipe(budgetDocumentsTaggedC, c => new t.Type<BudgetDocuments, BudgetDocumentsTagged>(
  `BudgetDocuments`,
  (u: unknown): u is BudgetDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BudgetDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...budgetDocuments }))),
  (x: BudgetDocuments): BudgetDocumentsTagged => ({ ...x, _tag: `BudgetDocuments`}),
));
export type BudgetDocumentsC = typeof budgetDocumentsC;


export const otherDocuments = {
  _tag: `OtherDocuments`,
  description: `All Other Documents`,
  id: 10
} as const;

export const otherDocumentsTaggedC = t.type({
  _tag: t.literal(`OtherDocuments`)
});
export type OtherDocumentsTaggedC = typeof otherDocumentsTaggedC;
export type OtherDocumentsTagged = t.TypeOf<OtherDocumentsTaggedC>;
export type OtherDocuments = OtherDocumentsTagged & typeof otherDocuments;
export const otherDocumentsC = pipe(otherDocumentsTaggedC, c => new t.Type<OtherDocuments, OtherDocumentsTagged>(
  `OtherDocuments`,
  (u: unknown): u is OtherDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OtherDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...otherDocuments }))),
  (x: OtherDocuments): OtherDocumentsTagged => ({ ...x, _tag: `OtherDocuments`}),
));
export type OtherDocumentsC = typeof otherDocumentsC;


export const rfpDocuments = {
  _tag: `RfpDocuments`,
  description: `RFP Documents`,
  id: 11
} as const;

export const rfpDocumentsTaggedC = t.type({
  _tag: t.literal(`RfpDocuments`)
});
export type RfpDocumentsTaggedC = typeof rfpDocumentsTaggedC;
export type RfpDocumentsTagged = t.TypeOf<RfpDocumentsTaggedC>;
export type RfpDocuments = RfpDocumentsTagged & typeof rfpDocuments;
export const rfpDocumentsC = pipe(rfpDocumentsTaggedC, c => new t.Type<RfpDocuments, RfpDocumentsTagged>(
  `RfpDocuments`,
  (u: unknown): u is RfpDocuments => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RfpDocuments> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfpDocuments }))),
  (x: RfpDocuments): RfpDocumentsTagged => ({ ...x, _tag: `RfpDocuments`}),
));
export type RfpDocumentsC = typeof rfpDocumentsC;


export const bondOfferings = {
  _tag: `BondOfferings`,
  description: `Bond Offerings`,
  id: 1
} as const;

export const bondOfferingsTaggedC = t.type({
  _tag: t.literal(`BondOfferings`)
});
export type BondOfferingsTaggedC = typeof bondOfferingsTaggedC;
export type BondOfferingsTagged = t.TypeOf<BondOfferingsTaggedC>;
export type BondOfferings = BondOfferingsTagged & typeof bondOfferings;
export const bondOfferingsC = pipe(bondOfferingsTaggedC, c => new t.Type<BondOfferings, BondOfferingsTagged>(
  `BondOfferings`,
  (u: unknown): u is BondOfferings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondOfferings> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondOfferings }))),
  (x: BondOfferings): BondOfferingsTagged => ({ ...x, _tag: `BondOfferings`}),
));
export type BondOfferingsC = typeof bondOfferingsC;


export const events = {
  _tag: `Events`,
  description: `Events`,
  id: 2
} as const;

export const eventsTaggedC = t.type({
  _tag: t.literal(`Events`)
});
export type EventsTaggedC = typeof eventsTaggedC;
export type EventsTagged = t.TypeOf<EventsTaggedC>;
export type Events = EventsTagged & typeof events;
export const eventsC = pipe(eventsTaggedC, c => new t.Type<Events, EventsTagged>(
  `Events`,
  (u: unknown): u is Events => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Events> => pipe(c.decode(u), E.map(x => ({ ...x, ...events }))),
  (x: Events): EventsTagged => ({ ...x, _tag: `Events`}),
));
export type EventsC = typeof eventsC;


export const newsAndPressReleases = {
  _tag: `NewsAndPressReleases`,
  description: `News & Press Releases`,
  id: 3
} as const;

export const newsAndPressReleasesTaggedC = t.type({
  _tag: t.literal(`NewsAndPressReleases`)
});
export type NewsAndPressReleasesTaggedC = typeof newsAndPressReleasesTaggedC;
export type NewsAndPressReleasesTagged = t.TypeOf<NewsAndPressReleasesTaggedC>;
export type NewsAndPressReleases = NewsAndPressReleasesTagged & typeof newsAndPressReleases;
export const newsAndPressReleasesC = pipe(newsAndPressReleasesTaggedC, c => new t.Type<NewsAndPressReleases, NewsAndPressReleasesTagged>(
  `NewsAndPressReleases`,
  (u: unknown): u is NewsAndPressReleases => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsAndPressReleases> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsAndPressReleases }))),
  (x: NewsAndPressReleases): NewsAndPressReleasesTagged => ({ ...x, _tag: `NewsAndPressReleases`}),
));
export type NewsAndPressReleasesC = typeof newsAndPressReleasesC;


export const ratings = {
  _tag: `Ratings`,
  description: `Ratings`,
  id: 4
} as const;

export const ratingsTaggedC = t.type({
  _tag: t.literal(`Ratings`)
});
export type RatingsTaggedC = typeof ratingsTaggedC;
export type RatingsTagged = t.TypeOf<RatingsTaggedC>;
export type Ratings = RatingsTagged & typeof ratings;
export const ratingsC = pipe(ratingsTaggedC, c => new t.Type<Ratings, RatingsTagged>(
  `Ratings`,
  (u: unknown): u is Ratings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ratings> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratings }))),
  (x: Ratings): RatingsTagged => ({ ...x, _tag: `Ratings`}),
));
export type RatingsC = typeof ratingsC;


export const rfps = {
  _tag: `Rfps`,
  description: `RFPs`,
  id: 5
} as const;

export const rfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTaggedC = typeof rfpsTaggedC;
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export const rfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
));
export type RfpsC = typeof rfpsC;


export const allUserContentSubscriptionTypeC = [bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC, bondOfferingsC, eventsC, newsAndPressReleasesC, ratingsC, rfpsC] as const;
export const allUserContentSubscriptionTypeNames = [`BondSaleDocuments`, `ACFRDocuments`, `FinancialStatementsDocuments`, `BudgetDocuments`, `OtherDocuments`, `RfpDocuments`, `BondOfferings`, `Events`, `NewsAndPressReleases`, `Ratings`, `Rfps`] as const;
export type UserContentSubscriptionTypeName = (typeof allUserContentSubscriptionTypeNames)[number];

export const UserContentSubscriptionTypeCU = t.union([bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC, bondOfferingsC, eventsC, newsAndPressReleasesC, ratingsC, rfpsC]);
export type UserContentSubscriptionTypeCU = typeof UserContentSubscriptionTypeCU;
export type UserContentSubscriptionTypeU = t.TypeOf<UserContentSubscriptionTypeCU>;

export const userContentSubscriptionTypeOrd: Ord.Ord<UserContentSubscriptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserContentSubscriptionType = [bondSaleDocuments, aCFRDocuments, financialStatementsDocuments, budgetDocuments, otherDocuments, rfpDocuments, bondOfferings, events, newsAndPressReleases, ratings, rfps] as const;
export type UserContentSubscriptionTypeMap<A> = { [K in UserContentSubscriptionTypeName]: A };


export const allUserDocumentSubscriptionTypeC = [bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC] as const;
export const allUserDocumentSubscriptionTypeNames = [`BondSaleDocuments`, `ACFRDocuments`, `FinancialStatementsDocuments`, `BudgetDocuments`, `OtherDocuments`, `RfpDocuments`] as const;
export type UserDocumentSubscriptionTypeName = (typeof allUserDocumentSubscriptionTypeNames)[number];

export const UserDocumentSubscriptionTypeCU = t.union([bondSaleDocumentsC, aCFRDocumentsC, financialStatementsDocumentsC, budgetDocumentsC, otherDocumentsC, rfpDocumentsC]);
export type UserDocumentSubscriptionTypeCU = typeof UserDocumentSubscriptionTypeCU;
export type UserDocumentSubscriptionTypeU = t.TypeOf<UserDocumentSubscriptionTypeCU>;

export const userDocumentSubscriptionTypeOrd: Ord.Ord<UserDocumentSubscriptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allUserDocumentSubscriptionType = [bondSaleDocuments, aCFRDocuments, financialStatementsDocuments, budgetDocuments, otherDocuments, rfpDocuments] as const;
export type UserDocumentSubscriptionTypeMap<A> = { [K in UserDocumentSubscriptionTypeName]: A };


