import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";

export const buildInfoC = t.type({
  buildId: t.string,
  builtAtMillis: t.number,
  name: t.string,
  commit: t.string,
  branch: t.string,
  version: t.string,
  builtAtString: LocalDateTimeFromIsoStringC
});
export type BuildInfoC = typeof buildInfoC;
export type BuildInfo = t.TypeOf<BuildInfoC>;


