import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bigNumberC } from "../../Big/bigNumberC";
import * as t from "io-ts";
import { either } from "io-ts-types/lib/either";

export const highestDebtLevel = {
  _tag: `HighestDebtLevel`
} as const;

export const highestDebtLevelC = t.type({
  _tag: t.literal(`HighestDebtLevel`)
});
export type HighestDebtLevelC = typeof highestDebtLevelC;
export type HighestDebtLevel = t.TypeOf<HighestDebtLevelC>;


export const customDebtLineC = t.type({
  label: t.string,
  value: bigNumberC
});
export type CustomDebtLineC = typeof customDebtLineC;
export type CustomDebtLine = t.TypeOf<CustomDebtLineC>;


export const debtComparisonLineC = t.type({
  line: either(highestDebtLevelC, customDebtLineC)
});
export type DebtComparisonLineC = typeof debtComparisonLineC;
export type DebtComparisonLine = t.TypeOf<DebtComparisonLineC>;


export const debtComparisonLinePostC = t.type({
  label: optionFromNullable(t.string),
  value: optionFromNullable(bigNumberC),
  highestDebtLevel: t.boolean,
  hideComparisonLine: t.boolean
});
export type DebtComparisonLinePostC = typeof debtComparisonLinePostC;
export type DebtComparisonLinePost = t.TypeOf<DebtComparisonLinePostC>;


