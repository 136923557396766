import { withIdC as imported280_withIdC, withIdC as imported281_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";
import { mediaC as imported72_mediaC, mediaC as imported73_mediaC } from "./media";
import { BondLinkResourceTypeCU as imported1_BondLinkResourceTypeCU } from "../domaintables/bondLinkResourceTypes";
import { LocalDateC } from "../../codecs/localDate";

export const bondLinkResourceC = t.type({
  resourceType: imported1_BondLinkResourceTypeCU,
  title: t.string,
  description: markdownC,
  resourceDate: LocalDateC,
  resource: imported280_withIdC(imported72_mediaC),
  image: imported281_withIdC(imported73_mediaC)
});
export type BondLinkResourceC = typeof bondLinkResourceC;
export type BondLinkResource = t.TypeOf<BondLinkResourceC>;


