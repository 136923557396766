import type { Dispatch } from "react";

import { RA } from "@scripts/fp-ts";
import type { NotificationAction } from "@scripts/react/state/notifications";
import { notificationRemove } from "@scripts/react/state/notifications";

import { Empty } from "../Empty";
import type { NotificationBaseProps } from "../Notification";
import { NotificationContainer } from "../Notification";
import { Portal } from "../Portal";

export const NotificationPortal = (props: {
  notifications: NotificationBaseProps[];
  dispatch: Dispatch<NotificationAction>;
}) => RA.isEmpty(props.notifications)
    ? <Empty />
    : <Portal id={`notification-portal`}>
      <NotificationContainer
        notifications={props.notifications}
        dismissAction={(key: string) => props.dispatch(notificationRemove(key))}
      />
    </Portal>;
