import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

const bankIdRegex = /^b(\d+)$/;
type BankId = `b${number}`;

export const bankIdC = new t.Type<number, BankId>(
  "bankId",
  (u: unknown): u is number => typeof u === "string" && bankIdRegex.test(u),
  (u: unknown, c: t.Context): t.Validation<number> => {
    if (typeof u === "string") {
      return NumberFromString.decode(bankIdRegex.exec(u)?.[1]);
    }
    return E.left([{ value: u, context: c, message: "Expecting string for bankId" }]);
  },
  bankId => `b${bankId}`,
);
