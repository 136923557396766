import type { JSXElementConstructor, ReactElement } from "react";
import * as b from "fp-ts/lib/boolean";
import { constNull } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

// FU React...
export const Empty: JSXElementConstructor<object> = constNull;
/** A thunk that always returns the Empty component */
export const constEmpty = () => <Empty />;

export const toEmpty: (ma: O.Option<ReactElement>) => ReactElement = O.getOrElse(constEmpty);

export const mapOrEmpty = <A,>(fa: (a: A) => ReactElement): ((ma: O.Option<A>) => ReactElement) => O.fold(constEmpty, fa);
export const noneOrEmpty = <A,>(fa: () => ReactElement): ((ma: O.Option<A>) => ReactElement) => O.fold(fa, constEmpty);
export const mapWithKeyOrEmpty = <A,>(fa: (a: A, key: string) => ReactElement, k: string): ((ma: O.Option<A>) => ReactElement) => O.fold(constEmpty, _ => fa(_, k));

export const trueOrEmpty = (component: ReactElement) => b.fold(constEmpty, () => component);
export const falseOrEmpty = (component: ReactElement) => b.fold(() => component, constEmpty);
