import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const obligor = {
  _tag: `Obligor`,
  id: 1,
  relationship: `Obligor`
} as const;

export const obligorTaggedC = t.type({
  _tag: t.literal(`Obligor`)
});
export type ObligorTaggedC = typeof obligorTaggedC;
export type ObligorTagged = t.TypeOf<ObligorTaggedC>;
export type Obligor = ObligorTagged & typeof obligor;
export const obligorC = pipe(obligorTaggedC, c => new t.Type<Obligor, ObligorTagged>(
  `Obligor`,
  (u: unknown): u is Obligor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Obligor> => pipe(c.decode(u), E.map(x => ({ ...x, ...obligor }))),
  (x: Obligor): ObligorTagged => ({ ...x, _tag: `Obligor`}),
));
export type ObligorC = typeof obligorC;


export const financialAdvisor = {
  _tag: `FinancialAdvisor`,
  id: 2,
  relationship: `Financial Advisor`
} as const;

export const financialAdvisorTaggedC = t.type({
  _tag: t.literal(`FinancialAdvisor`)
});
export type FinancialAdvisorTaggedC = typeof financialAdvisorTaggedC;
export type FinancialAdvisorTagged = t.TypeOf<FinancialAdvisorTaggedC>;
export type FinancialAdvisor = FinancialAdvisorTagged & typeof financialAdvisor;
export const financialAdvisorC = pipe(financialAdvisorTaggedC, c => new t.Type<FinancialAdvisor, FinancialAdvisorTagged>(
  `FinancialAdvisor`,
  (u: unknown): u is FinancialAdvisor => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialAdvisor> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialAdvisor }))),
  (x: FinancialAdvisor): FinancialAdvisorTagged => ({ ...x, _tag: `FinancialAdvisor`}),
));
export type FinancialAdvisorC = typeof financialAdvisorC;


export const issuer = {
  _tag: `Issuer`,
  id: 3,
  relationship: `Issuer`
} as const;

export const issuerTaggedC = t.type({
  _tag: t.literal(`Issuer`)
});
export type IssuerTaggedC = typeof issuerTaggedC;
export type IssuerTagged = t.TypeOf<IssuerTaggedC>;
export type Issuer = IssuerTagged & typeof issuer;
export const issuerC = pipe(issuerTaggedC, c => new t.Type<Issuer, IssuerTagged>(
  `Issuer`,
  (u: unknown): u is Issuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Issuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuer }))),
  (x: Issuer): IssuerTagged => ({ ...x, _tag: `Issuer`}),
));
export type IssuerC = typeof issuerC;


export const allIssuerRelationshipC = [obligorC, financialAdvisorC, issuerC] as const;
export const allIssuerRelationshipNames = [`Obligor`, `FinancialAdvisor`, `Issuer`] as const;
export type IssuerRelationshipName = (typeof allIssuerRelationshipNames)[number];

export const IssuerRelationshipCU = t.union([obligorC, financialAdvisorC, issuerC]);
export type IssuerRelationshipCU = typeof IssuerRelationshipCU;
export type IssuerRelationshipU = t.TypeOf<IssuerRelationshipCU>;

export const issuerRelationshipOrd: Ord.Ord<IssuerRelationshipU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerRelationship = [obligor, financialAdvisor, issuer] as const;
export type IssuerRelationshipMap<A> = { [K in IssuerRelationshipName]: A };


