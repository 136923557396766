import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fixedRate = {
  _tag: `FixedRate`,
  id: 1,
  name: `Fixed`
} as const;

export const fixedRateTaggedC = t.type({
  _tag: t.literal(`FixedRate`)
});
export type FixedRateTaggedC = typeof fixedRateTaggedC;
export type FixedRateTagged = t.TypeOf<FixedRateTaggedC>;
export type FixedRate = FixedRateTagged & typeof fixedRate;
export const fixedRateC = pipe(fixedRateTaggedC, c => new t.Type<FixedRate, FixedRateTagged>(
  `FixedRate`,
  (u: unknown): u is FixedRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FixedRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...fixedRate }))),
  (x: FixedRate): FixedRateTagged => ({ ...x, _tag: `FixedRate`}),
));
export type FixedRateC = typeof fixedRateC;


export const variableRate = {
  _tag: `VariableRate`,
  id: 2,
  name: `Variable`
} as const;

export const variableRateTaggedC = t.type({
  _tag: t.literal(`VariableRate`)
});
export type VariableRateTaggedC = typeof variableRateTaggedC;
export type VariableRateTagged = t.TypeOf<VariableRateTaggedC>;
export type VariableRate = VariableRateTagged & typeof variableRate;
export const variableRateC = pipe(variableRateTaggedC, c => new t.Type<VariableRate, VariableRateTagged>(
  `VariableRate`,
  (u: unknown): u is VariableRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariableRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...variableRate }))),
  (x: VariableRate): VariableRateTagged => ({ ...x, _tag: `VariableRate`}),
));
export type VariableRateC = typeof variableRateC;


export const zeroCoupon = {
  _tag: `ZeroCoupon`,
  id: 3,
  name: `Zero Coupon`
} as const;

export const zeroCouponTaggedC = t.type({
  _tag: t.literal(`ZeroCoupon`)
});
export type ZeroCouponTaggedC = typeof zeroCouponTaggedC;
export type ZeroCouponTagged = t.TypeOf<ZeroCouponTaggedC>;
export type ZeroCoupon = ZeroCouponTagged & typeof zeroCoupon;
export const zeroCouponC = pipe(zeroCouponTaggedC, c => new t.Type<ZeroCoupon, ZeroCouponTagged>(
  `ZeroCoupon`,
  (u: unknown): u is ZeroCoupon => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ZeroCoupon> => pipe(c.decode(u), E.map(x => ({ ...x, ...zeroCoupon }))),
  (x: ZeroCoupon): ZeroCouponTagged => ({ ...x, _tag: `ZeroCoupon`}),
));
export type ZeroCouponC = typeof zeroCouponC;


export const allCouponTypeC = [fixedRateC, variableRateC, zeroCouponC] as const;
export const allCouponTypeNames = [`FixedRate`, `VariableRate`, `ZeroCoupon`] as const;
export type CouponTypeName = (typeof allCouponTypeNames)[number];

export const CouponTypeCU = t.union([fixedRateC, variableRateC, zeroCouponC]);
export type CouponTypeCU = typeof CouponTypeCU;
export type CouponTypeU = t.TypeOf<CouponTypeCU>;

export const couponTypeOrd: Ord.Ord<CouponTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCouponType = [fixedRate, variableRate, zeroCoupon] as const;
export type CouponTypeMap<A> = { [K in CouponTypeName]: A };


