import * as t from "io-ts";
import { documentWithCategoryC as imported16_documentWithCategoryC, documentTypeC as imported8_documentTypeC } from "./document";
import { LocalDateC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { userC as imported12_userC } from "./user";

export const documentActivityC = t.type({
  date: LocalDateC,
  user: optionFromNullable(imported12_userC),
  totalDownloads: t.number,
  totalViews: t.number
});
export type DocumentActivityC = typeof documentActivityC;
export type DocumentActivity = t.TypeOf<DocumentActivityC>;


export const totalDocumentActivityC = t.type({
  allDownloads: t.number,
  allViews: t.number,
  documentActivity: t.readonlyArray(documentActivityC)
});
export type TotalDocumentActivityC = typeof totalDocumentActivityC;
export type TotalDocumentActivity = t.TypeOf<TotalDocumentActivityC>;


export const documentPairC = t.type({
  mediaId: t.number,
  doc: imported16_documentWithCategoryC,
  total: totalDocumentActivityC
});
export type DocumentPairC = typeof documentPairC;
export type DocumentPair = t.TypeOf<DocumentPairC>;


export const documentStatisticsC = t.type({
  docTypes: t.readonlyArray(imported8_documentTypeC),
  docs: t.readonlyArray(documentPairC)
});
export type DocumentStatisticsC = typeof documentStatisticsC;
export type DocumentStatistics = t.TypeOf<DocumentStatisticsC>;


