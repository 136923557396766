import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import { not } from "fp-ts/lib/Refinement";

/**
 * Properties that we can't muck with for security reasons
 */
const protectedProps_ = {
  "__proto__": true,
  "toString": true,
} as const;

export type ProtectedProps = keyof typeof protectedProps;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const protectedProps = Object.assign<object, typeof protectedProps_>(Object.create(null) as object, protectedProps_); // eslint-disable-line fp/no-mutating-assign
export const isProtectedProp = (k: PropertyKey): k is ProtectedProps => k in protectedProps;
export const isNotProtectedProp = not(isProtectedProp);
export const noneAreProtectedProps = (...props: PropertyKey[]) => props.every(isNotProtectedProp);


/**
 * `guardAgainstProtectedProp` will give you better type
 * inference than using `isNotProtected` alone if your
 * input is a string literal or a union of strings
 */
export const guardAgainstProtectedProp = <K extends string>(k: K): O.Option<Exclude<K, ProtectedProps>> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  pipe(k, O.fromPredicate(isNotProtectedProp), O.map(_ => _ as Exclude<K, ProtectedProps>));
