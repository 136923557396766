import type { Action } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { User, UserWithRoles } from "@scripts/generated/models/user";
import { userL } from "@scripts/syntax/user";

interface DataAction<T, U> extends Action<T> {
  user: U;
}

export const userSet = (user: User): DataAction<"USER_SET", User> => ({
  type: "USER_SET",
  user,
});

export const userUpdate = (user: Partial<User>): Action<"USER_UPDATE"> & { user: Partial<User> } => ({
  type: "USER_UPDATE",
  user,
});

export const userWithRolesSet = (user: UserWithRoles): DataAction<"USER_WITH_ROLES_SET", UserWithRoles> => ({
  type: "USER_WITH_ROLES_SET",
  user,
});

export const userReducer = (config: BLConfigWithLog) =>
  (state: UserWithRoles, action: UserAction): UserWithRoles => {
    switch (action.type) {
      case "USER_SET":
        return userL.set(action.user)(state);
      case "USER_UPDATE":
        return userL.set({ ...state.user, ...action.user })(state);
      case "USER_WITH_ROLES_SET":
        return action.user;
    }
    return config.exhaustive(action);
  };

export type UserSet = ReturnType<typeof userSet>;
export type UserUpdate = ReturnType<typeof userUpdate>;
export type UserWithRolesSet = ReturnType<typeof userWithRolesSet>;

export type UserAction = UserSet | UserWithRolesSet | UserUpdate;
