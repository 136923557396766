import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";

export const pointC = t.type({
  earlierYield: t.number,
  recentYield: t.number
});
export type PointC = typeof pointC;
export type Point = t.TypeOf<PointC>;


export const iceCurveC = t.type({
  earlierDate: LocalDateC,
  recentDate: LocalDateC,
  data: t.readonlyArray(t.tuple([t.string, pointC]))
});
export type IceCurveC = typeof iceCurveC;
export type IceCurve = t.TypeOf<IceCurveC>;


