import type { ReactNode } from "react";
import { createElement, Fragment } from "react";
import type { AnimatedComponent } from "react-spring";
import { animated, useTransition } from "react-spring";

import type { SVGString } from "*.svg";

import { Svg } from "@scripts/react/components/Svg";
import { klass } from "@scripts/react/util/classnames";
import { transitionBaseMs } from "@scripts/ui/transitions";

import { notification as notificationStyles } from "@styles/components/_notification";

import checkmark from "@svgs/checkmark.svg";
import information from "@svgs/information.svg";
import warning from "@svgs/warning.svg";

import { CloseXButton } from "./Button";

type NotificationTypes = "success" | "info" | "warning" | "danger";
type NotificationIcons = { [K in NotificationTypes]: SVGString };

type DismissAction = { dismissAction: (id: string) => void };

export type NotificationBaseProps = {
  type: NotificationTypes;
  children: ReactNode;
  id: string;
  title: string;
};

export type NotificationProps = NotificationBaseProps & DismissAction;

const notificationTypeToStyle = (type: NotificationTypes) => `notification-${type}`;

export const notificationIcons: NotificationIcons = {
  "success": checkmark,
  "info": information,
  "warning": warning,
  "danger": warning,
};

const NotificationBody = (props: NotificationProps) => (
  <Fragment>
    <div {...klass(notificationStyles[".notification"][".header"], "inverted")}>
      <div {...klass(notificationStyles[".notification"][".header"][".title"])}>
        <Svg src={notificationIcons[props.type]} />
        <h5>{props.title}</h5>
      </div>
      <CloseXButton onClick={() => props.dismissAction(props.id)} />
    </div>
    <div {...klass(notificationStyles[".notification"][".body"], notificationTypeToStyle(props.type))}>
      {props.children}
    </div>
  </Fragment>
);

export const Notification = (props: NotificationProps) =>
  <div
    role="alert"
    key={props.id}
    {...klass(notificationStyles[".notification"], notificationTypeToStyle(props.type))}
  >
    <NotificationBody
      id={props.id}
      type={props.type}
      dismissAction={props.dismissAction}
      title={props.title}
    >
      {props.children}
    </NotificationBody>
  </div>;

export const NotificationContainer = (props: { notifications: NotificationBaseProps[] } & DismissAction) => {
  const transition = useTransition(props.notifications, {
    from: { opacity: 0, marginBottom: "0", height: "0", transform: "translateY(100%)" },
    enter: { opacity: 1, marginBottom: "1rem", height: "9rem", transform: "translateY(0)" },
    leave: { opacity: 0, marginBottom: "0", height: "0" },
    config: {
      duration: transitionBaseMs,
    },
  });

  return (
    <div {...klass(notificationStyles[".notification-container"])}>
      {transition((transitionStyle, notification) =>
        createElement<Parameters<AnimatedComponent<"div">>[0]>(
          animated.div,
          {
            style: transitionStyle,
            ...klass(notificationStyles[".notification"], notificationTypeToStyle(notification.type)),
          },
          <NotificationBody
            id={notification.id}
            type={notification.type}
            title={notification.title}
            dismissAction={props.dismissAction}
          >
            {notification.children}
          </NotificationBody>
        )
      )}
    </div>
  );
};
