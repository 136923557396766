import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const callouts = {
  _tag: `Callouts`,
  name: `callouts`
} as const;

export const calloutsTaggedC = t.type({
  _tag: t.literal(`Callouts`)
});
export type CalloutsTaggedC = typeof calloutsTaggedC;
export type CalloutsTagged = t.TypeOf<CalloutsTaggedC>;
export type Callouts = CalloutsTagged & typeof callouts;
export const calloutsC = pipe(calloutsTaggedC, c => new t.Type<Callouts, CalloutsTagged>(
  `Callouts`,
  (u: unknown): u is Callouts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Callouts> => pipe(c.decode(u), E.map(x => ({ ...x, ...callouts }))),
  (x: Callouts): CalloutsTagged => ({ ...x, _tag: `Callouts`}),
));
export type CalloutsC = typeof calloutsC;


export const faqs = {
  _tag: `Faqs`,
  name: `faqs`
} as const;

export const faqsTaggedC = t.type({
  _tag: t.literal(`Faqs`)
});
export type FaqsTaggedC = typeof faqsTaggedC;
export type FaqsTagged = t.TypeOf<FaqsTaggedC>;
export type Faqs = FaqsTagged & typeof faqs;
export const faqsC = pipe(faqsTaggedC, c => new t.Type<Faqs, FaqsTagged>(
  `Faqs`,
  (u: unknown): u is Faqs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Faqs> => pipe(c.decode(u), E.map(x => ({ ...x, ...faqs }))),
  (x: Faqs): FaqsTagged => ({ ...x, _tag: `Faqs`}),
));
export type FaqsC = typeof faqsC;


export const featuredImages = {
  _tag: `FeaturedImages`,
  name: `featuredImages`
} as const;

export const featuredImagesTaggedC = t.type({
  _tag: t.literal(`FeaturedImages`)
});
export type FeaturedImagesTaggedC = typeof featuredImagesTaggedC;
export type FeaturedImagesTagged = t.TypeOf<FeaturedImagesTaggedC>;
export type FeaturedImages = FeaturedImagesTagged & typeof featuredImages;
export const featuredImagesC = pipe(featuredImagesTaggedC, c => new t.Type<FeaturedImages, FeaturedImagesTagged>(
  `FeaturedImages`,
  (u: unknown): u is FeaturedImages => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FeaturedImages> => pipe(c.decode(u), E.map(x => ({ ...x, ...featuredImages }))),
  (x: FeaturedImages): FeaturedImagesTagged => ({ ...x, _tag: `FeaturedImages`}),
));
export type FeaturedImagesC = typeof featuredImagesC;


export const esgImages = {
  _tag: `EsgImages`,
  name: `esgImages`
} as const;

export const esgImagesTaggedC = t.type({
  _tag: t.literal(`EsgImages`)
});
export type EsgImagesTaggedC = typeof esgImagesTaggedC;
export type EsgImagesTagged = t.TypeOf<EsgImagesTaggedC>;
export type EsgImages = EsgImagesTagged & typeof esgImages;
export const esgImagesC = pipe(esgImagesTaggedC, c => new t.Type<EsgImages, EsgImagesTagged>(
  `EsgImages`,
  (u: unknown): u is EsgImages => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgImages> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgImages }))),
  (x: EsgImages): EsgImagesTagged => ({ ...x, _tag: `EsgImages`}),
));
export type EsgImagesC = typeof esgImagesC;


export const projects = {
  _tag: `Projects`,
  name: `projects`
} as const;

export const projectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTaggedC = typeof projectsTaggedC;
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export const projectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
));
export type ProjectsC = typeof projectsC;


export const officers = {
  _tag: `Officers`,
  name: `officers`
} as const;

export const officersTaggedC = t.type({
  _tag: t.literal(`Officers`)
});
export type OfficersTaggedC = typeof officersTaggedC;
export type OfficersTagged = t.TypeOf<OfficersTaggedC>;
export type Officers = OfficersTagged & typeof officers;
export const officersC = pipe(officersTaggedC, c => new t.Type<Officers, OfficersTagged>(
  `Officers`,
  (u: unknown): u is Officers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Officers> => pipe(c.decode(u), E.map(x => ({ ...x, ...officers }))),
  (x: Officers): OfficersTagged => ({ ...x, _tag: `Officers`}),
));
export type OfficersC = typeof officersC;


export const offerings = {
  _tag: `Offerings`,
  name: `offerings`
} as const;

export const offeringsTaggedC = t.type({
  _tag: t.literal(`Offerings`)
});
export type OfferingsTaggedC = typeof offeringsTaggedC;
export type OfferingsTagged = t.TypeOf<OfferingsTaggedC>;
export type Offerings = OfferingsTagged & typeof offerings;
export const offeringsC = pipe(offeringsTaggedC, c => new t.Type<Offerings, OfferingsTagged>(
  `Offerings`,
  (u: unknown): u is Offerings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offerings> => pipe(c.decode(u), E.map(x => ({ ...x, ...offerings }))),
  (x: Offerings): OfferingsTagged => ({ ...x, _tag: `Offerings`}),
));
export type OfferingsC = typeof offeringsC;


export const relatedIssuers = {
  _tag: `RelatedIssuers`,
  name: `relatedIssuers`
} as const;

export const relatedIssuersTaggedC = t.type({
  _tag: t.literal(`RelatedIssuers`)
});
export type RelatedIssuersTaggedC = typeof relatedIssuersTaggedC;
export type RelatedIssuersTagged = t.TypeOf<RelatedIssuersTaggedC>;
export type RelatedIssuers = RelatedIssuersTagged & typeof relatedIssuers;
export const relatedIssuersC = pipe(relatedIssuersTaggedC, c => new t.Type<RelatedIssuers, RelatedIssuersTagged>(
  `RelatedIssuers`,
  (u: unknown): u is RelatedIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RelatedIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...relatedIssuers }))),
  (x: RelatedIssuers): RelatedIssuersTagged => ({ ...x, _tag: `RelatedIssuers`}),
));
export type RelatedIssuersC = typeof relatedIssuersC;


export const ratingAgencies = {
  _tag: `RatingAgencies`,
  name: `ratingAgencies`
} as const;

export const ratingAgenciesTaggedC = t.type({
  _tag: t.literal(`RatingAgencies`)
});
export type RatingAgenciesTaggedC = typeof ratingAgenciesTaggedC;
export type RatingAgenciesTagged = t.TypeOf<RatingAgenciesTaggedC>;
export type RatingAgencies = RatingAgenciesTagged & typeof ratingAgencies;
export const ratingAgenciesC = pipe(ratingAgenciesTaggedC, c => new t.Type<RatingAgencies, RatingAgenciesTagged>(
  `RatingAgencies`,
  (u: unknown): u is RatingAgencies => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingAgencies> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingAgencies }))),
  (x: RatingAgencies): RatingAgenciesTagged => ({ ...x, _tag: `RatingAgencies`}),
));
export type RatingAgenciesC = typeof ratingAgenciesC;


export const hrefs = {
  _tag: `Hrefs`,
  name: `hrefs`
} as const;

export const hrefsTaggedC = t.type({
  _tag: t.literal(`Hrefs`)
});
export type HrefsTaggedC = typeof hrefsTaggedC;
export type HrefsTagged = t.TypeOf<HrefsTaggedC>;
export type Hrefs = HrefsTagged & typeof hrefs;
export const hrefsC = pipe(hrefsTaggedC, c => new t.Type<Hrefs, HrefsTagged>(
  `Hrefs`,
  (u: unknown): u is Hrefs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Hrefs> => pipe(c.decode(u), E.map(x => ({ ...x, ...hrefs }))),
  (x: Hrefs): HrefsTagged => ({ ...x, _tag: `Hrefs`}),
));
export type HrefsC = typeof hrefsC;


export const participantTypes = {
  _tag: `ParticipantTypes`,
  name: `participantTypes`
} as const;

export const participantTypesTaggedC = t.type({
  _tag: t.literal(`ParticipantTypes`)
});
export type ParticipantTypesTaggedC = typeof participantTypesTaggedC;
export type ParticipantTypesTagged = t.TypeOf<ParticipantTypesTaggedC>;
export type ParticipantTypes = ParticipantTypesTagged & typeof participantTypes;
export const participantTypesC = pipe(participantTypesTaggedC, c => new t.Type<ParticipantTypes, ParticipantTypesTagged>(
  `ParticipantTypes`,
  (u: unknown): u is ParticipantTypes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParticipantTypes> => pipe(c.decode(u), E.map(x => ({ ...x, ...participantTypes }))),
  (x: ParticipantTypes): ParticipantTypesTagged => ({ ...x, _tag: `ParticipantTypes`}),
));
export type ParticipantTypesC = typeof participantTypesC;


export const roadShows = {
  _tag: `RoadShows`,
  name: `roadShows`
} as const;

export const roadShowsTaggedC = t.type({
  _tag: t.literal(`RoadShows`)
});
export type RoadShowsTaggedC = typeof roadShowsTaggedC;
export type RoadShowsTagged = t.TypeOf<RoadShowsTaggedC>;
export type RoadShows = RoadShowsTagged & typeof roadShows;
export const roadShowsC = pipe(roadShowsTaggedC, c => new t.Type<RoadShows, RoadShowsTagged>(
  `RoadShows`,
  (u: unknown): u is RoadShows => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShows> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShows }))),
  (x: RoadShows): RoadShowsTagged => ({ ...x, _tag: `RoadShows`}),
));
export type RoadShowsC = typeof roadShowsC;


export const offeringParticipants = {
  _tag: `OfferingParticipants`,
  name: `offeringParticipants`
} as const;

export const offeringParticipantsTaggedC = t.type({
  _tag: t.literal(`OfferingParticipants`)
});
export type OfferingParticipantsTaggedC = typeof offeringParticipantsTaggedC;
export type OfferingParticipantsTagged = t.TypeOf<OfferingParticipantsTaggedC>;
export type OfferingParticipants = OfferingParticipantsTagged & typeof offeringParticipants;
export const offeringParticipantsC = pipe(offeringParticipantsTaggedC, c => new t.Type<OfferingParticipants, OfferingParticipantsTagged>(
  `OfferingParticipants`,
  (u: unknown): u is OfferingParticipants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingParticipants> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringParticipants }))),
  (x: OfferingParticipants): OfferingParticipantsTagged => ({ ...x, _tag: `OfferingParticipants`}),
));
export type OfferingParticipantsC = typeof offeringParticipantsC;


export const featuredItems = {
  _tag: `FeaturedItems`,
  name: `featuredItems`
} as const;

export const featuredItemsTaggedC = t.type({
  _tag: t.literal(`FeaturedItems`)
});
export type FeaturedItemsTaggedC = typeof featuredItemsTaggedC;
export type FeaturedItemsTagged = t.TypeOf<FeaturedItemsTaggedC>;
export type FeaturedItems = FeaturedItemsTagged & typeof featuredItems;
export const featuredItemsC = pipe(featuredItemsTaggedC, c => new t.Type<FeaturedItems, FeaturedItemsTagged>(
  `FeaturedItems`,
  (u: unknown): u is FeaturedItems => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FeaturedItems> => pipe(c.decode(u), E.map(x => ({ ...x, ...featuredItems }))),
  (x: FeaturedItems): FeaturedItemsTagged => ({ ...x, _tag: `FeaturedItems`}),
));
export type FeaturedItemsC = typeof featuredItemsC;


export const issuerLinkSections = {
  _tag: `IssuerLinkSections`,
  name: `issuerLinkSections`
} as const;

export const issuerLinkSectionsTaggedC = t.type({
  _tag: t.literal(`IssuerLinkSections`)
});
export type IssuerLinkSectionsTaggedC = typeof issuerLinkSectionsTaggedC;
export type IssuerLinkSectionsTagged = t.TypeOf<IssuerLinkSectionsTaggedC>;
export type IssuerLinkSections = IssuerLinkSectionsTagged & typeof issuerLinkSections;
export const issuerLinkSectionsC = pipe(issuerLinkSectionsTaggedC, c => new t.Type<IssuerLinkSections, IssuerLinkSectionsTagged>(
  `IssuerLinkSections`,
  (u: unknown): u is IssuerLinkSections => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerLinkSections> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerLinkSections }))),
  (x: IssuerLinkSections): IssuerLinkSectionsTagged => ({ ...x, _tag: `IssuerLinkSections`}),
));
export type IssuerLinkSectionsC = typeof issuerLinkSectionsC;


export const issuerFaqSections = {
  _tag: `IssuerFaqSections`,
  name: `issuerFaqSections`
} as const;

export const issuerFaqSectionsTaggedC = t.type({
  _tag: t.literal(`IssuerFaqSections`)
});
export type IssuerFaqSectionsTaggedC = typeof issuerFaqSectionsTaggedC;
export type IssuerFaqSectionsTagged = t.TypeOf<IssuerFaqSectionsTaggedC>;
export type IssuerFaqSections = IssuerFaqSectionsTagged & typeof issuerFaqSections;
export const issuerFaqSectionsC = pipe(issuerFaqSectionsTaggedC, c => new t.Type<IssuerFaqSections, IssuerFaqSectionsTagged>(
  `IssuerFaqSections`,
  (u: unknown): u is IssuerFaqSections => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerFaqSections> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerFaqSections }))),
  (x: IssuerFaqSections): IssuerFaqSectionsTagged => ({ ...x, _tag: `IssuerFaqSections`}),
));
export type IssuerFaqSectionsC = typeof issuerFaqSectionsC;


export const links = {
  _tag: `Links`,
  name: `links`
} as const;

export const linksTaggedC = t.type({
  _tag: t.literal(`Links`)
});
export type LinksTaggedC = typeof linksTaggedC;
export type LinksTagged = t.TypeOf<LinksTaggedC>;
export type Links = LinksTagged & typeof links;
export const linksC = pipe(linksTaggedC, c => new t.Type<Links, LinksTagged>(
  `Links`,
  (u: unknown): u is Links => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Links> => pipe(c.decode(u), E.map(x => ({ ...x, ...links }))),
  (x: Links): LinksTagged => ({ ...x, _tag: `Links`}),
));
export type LinksC = typeof linksC;


export const rfps = {
  _tag: `Rfps`,
  name: `rfps`
} as const;

export const rfpsTaggedC = t.type({
  _tag: t.literal(`Rfps`)
});
export type RfpsTaggedC = typeof rfpsTaggedC;
export type RfpsTagged = t.TypeOf<RfpsTaggedC>;
export type Rfps = RfpsTagged & typeof rfps;
export const rfpsC = pipe(rfpsTaggedC, c => new t.Type<Rfps, RfpsTagged>(
  `Rfps`,
  (u: unknown): u is Rfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfps }))),
  (x: Rfps): RfpsTagged => ({ ...x, _tag: `Rfps`}),
));
export type RfpsC = typeof rfpsC;


export const bondProgram = {
  _tag: `BondProgram`,
  name: `bondProgram`
} as const;

export const bondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTaggedC = typeof bondProgramTaggedC;
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export const bondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
));
export type BondProgramC = typeof bondProgramC;


export const allSortTypeC = [calloutsC, faqsC, featuredImagesC, esgImagesC, projectsC, officersC, offeringsC, relatedIssuersC, ratingAgenciesC, hrefsC, participantTypesC, roadShowsC, offeringParticipantsC, featuredItemsC, issuerLinkSectionsC, issuerFaqSectionsC, linksC, rfpsC, bondProgramC] as const;
export const allSortTypeNames = [`Callouts`, `Faqs`, `FeaturedImages`, `EsgImages`, `Projects`, `Officers`, `Offerings`, `RelatedIssuers`, `RatingAgencies`, `Hrefs`, `ParticipantTypes`, `RoadShows`, `OfferingParticipants`, `FeaturedItems`, `IssuerLinkSections`, `IssuerFaqSections`, `Links`, `Rfps`, `BondProgram`] as const;
export type SortTypeName = (typeof allSortTypeNames)[number];

export const SortTypeCU = t.union([calloutsC, faqsC, featuredImagesC, esgImagesC, projectsC, officersC, offeringsC, relatedIssuersC, ratingAgenciesC, hrefsC, participantTypesC, roadShowsC, offeringParticipantsC, featuredItemsC, issuerLinkSectionsC, issuerFaqSectionsC, linksC, rfpsC, bondProgramC]);
export type SortTypeCU = typeof SortTypeCU;
export type SortTypeU = t.TypeOf<SortTypeCU>;

export const sortTypeOrd: Ord.Ord<SortTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortType = [callouts, faqs, featuredImages, esgImages, projects, officers, offerings, relatedIssuers, ratingAgencies, hrefs, participantTypes, roadShows, offeringParticipants, featuredItems, issuerLinkSections, issuerFaqSections, links, rfps, bondProgram] as const;
export type SortTypeMap<A> = { [K in SortTypeName]: A };


