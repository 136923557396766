import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import { IntroSentenceEditableCU as imported4_IntroSentenceEditableCU } from "../domaintables/pages";
import * as t from "io-ts";

export const postIntroSentenceC = t.type({
  page: imported4_IntroSentenceEditableCU,
  introSentence: optionFromNullable(markdownC)
});
export type PostIntroSentenceC = typeof postIntroSentenceC;
export type PostIntroSentence = t.TypeOf<PostIntroSentenceC>;


