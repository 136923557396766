export * from "fp-ts/lib/Eq";

import * as Eq from "fp-ts/lib/Eq";
import type { Predicate } from "fp-ts/lib/Predicate";
import type { Refinement } from "fp-ts/lib/Refinement";
import type { Lens } from "monocle-ts";

import { not as booleanNot } from "./boolean";
import type * as Match from "./lib/types/matchers";

/** @alias for `S.Eq` */
export { Eq as string } from "fp-ts/lib/string"; // eslint-disable-line id-blacklist
/** @alias for `N.Eq` */
export { Eq as number } from "fp-ts/lib/number"; // eslint-disable-line id-blacklist
/** @alias for `B.Eq` */
export { Eq as boolean } from "fp-ts/lib/boolean"; // eslint-disable-line id-blacklist

/** @alias for `Eq.eqStrict` */
export const strict = Eq.eqStrict;

/** @internal */
type Eq<A> = Eq.Eq<A>;
/** @internal */
type BinaryPredicate<A> = (x: A, y: A) => boolean;

export type EqUndefinable<A> = Eq<Match.Undefinable<A>>;
export type EqNullable<A> = Eq<Match.Nullable<A>>;

/** @internal */
const isUndefined = (u: unknown): u is undefined => typeof u === "undefined";
/** @internal */
const isNullOrUndefined = (u: unknown): u is Match.NullOrUndefined => u == null;
/** @internal */
const both = <A>(predicate: Predicate<A>): BinaryPredicate<A> => (x, y) => predicate(x) && predicate(y);
/** @internal, but could maybe be exported */
const union = <A, B>(eq: Eq<A>, predicate: Predicate<A | B>): Eq<A | B> => Eq.fromEquals((a, b) =>
  /** case: both pass */
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  both(predicate)(a, b) ? (eq as Eq<A | B>).equals(a, b)
    /**
     * case: only 1 passes
     * We already handled the `both pass` case above, which
     * means that if _one_ of them passes the predicate, the
     * values are by definition not equivalent.
     */
    : predicate(a) ? false
      : predicate(b) ? false
        /**
         * case: neither passes (depending on the predicate,
         * both failing might not automatically mean the
         * values aren't equivalent.
         */
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        : (eq as Eq<A | B>).equals(a, b));

/**
 * Derive an `Eq` for a structure `S` from the `Eq` of one
 * of its properties called `A` and a lens from `S` to `A`.
 *
 * Useful when you only want to trigger a re-render when a
 * particular property changes, or when a deep comparison
 * would be expensive.
 */
export const fromLens = <A>(eq: Eq<A>) =>
  <S extends Match.AnyStruct>(lens: Lens<S, A>): Eq<S> =>
    Eq.contramap(lens.get)(eq);

/**
 * @category combinators
 * `Eq.optional` is a combinator that takes an `Eq<A>` and returns an
 * `Eq<A | undefined>`. This is useful when you want to compare two values
 * that may be undefined without defining another `Eq` instance. Note that:
 *
 * 1. this combinator returns `true` when both values are `undefined`
 * 2. this combinator short-circuits when only _one_ of the values is undefined
 *
 * Number 2 means that `Eq.optional` will optimize your `Eq` to handle the less
 * expensive check at the top-level, only performing a deep comparison when
 * actually necessary.
 */
export const optional = <A>(eq: Eq<A>): EqUndefinable<A> => union(eq, isUndefined);
/**
 * @category combinators
 *
 * `Eq.nullable` is a combinator that takes an `Eq<A>` and returns an
 * `Eq<A | undefined | null>`.
 *
 * See above `Eq.optional` comment for behavioral notes.
 * */
export const nullable = <A>(eq: Eq<A>): EqNullable<A> => union(eq, isNullOrUndefined);
/**
 * @category combinators
 * `Eq.nullableStrict` is a combinator that takes an `Eq<A>` and returns an
 * `Eq<A | undefined | null>`. Unlike `Eq.optional` and `Eq.nullable` above,
 * `Eq.nullableStrict` returns `false` if both values are `undefined | null`
 *
 * Consider using `Eq.nullable` in any situations that could trigger a re-render
 * due to a comparison of `undefined` values being false.
*/
export const nullableStrict = <A>(eq: Eq<A>): EqNullable<A> => ({
  equals: (a, b) => (isNullOrUndefined(a) || isNullOrUndefined(b)) ? false : eq.equals(a, b),
});
export const not = <A>(eq: Eq<A>): Eq<A> => Eq.fromEquals((x: A, y: A) => booleanNot(eq.equals(x, y)));
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const partial = <A>(eqs: { [K in keyof A]: Eq<A[K]> }): Eq<Partial<A>> => optionals(eqs) as Eq<Partial<A>>;

export const eqUnion = <A, B>(
  isA: Refinement<A | B, A>,
  isB: Refinement<A | B, B>,
  eqA: Eq.Eq<A>,
  eqB: Eq.Eq<B>,
): Eq.Eq<A | B> => Eq.fromEquals((x, y) => {
  if (isA(x) && isA(y)) {
    return eqA.equals(x, y);
  } else if (isB(x) && isB(y)) {
    return eqB.equals(x, y);
  } else {
    return false;
  }
});

/**
 * Like `Eq.optional` except that it takes a struct of `Eq`s and returns a
 * struct of `Eq`s that each short-circuit when handling the nullable
 * case. Note that the `Eq` this function produces is different than an
 * `Eq` that operates on two partials of `A`, since the cardinality of
 * `Record<keyof A, A[keyof A]>` expands at a linear rate in proportion to
 * the size of `A`, whereas the cardinality of `Partial<A>` expands at an
 * exponential rate.
 *
 * This is why `io-ts`'s `partial` codec has historically been slow compared to
 * a record whose properties each form a union with `undefined`: because the
 * former is the set of all records containing any subset of the record's keys,
 * whereas the latter is a single record whose values each have a cardinality of
 * 2 instead of 1.
 */
export const optionals =
  <A>(eqs: { [K in keyof A]: Eq<A[K]> }): Eq<{ [K in keyof A]: A[K] | undefined }> =>
    Eq.fromEquals((x, y) => {
      for (const key in eqs) {
        if (!optional(eqs[key]).equals(y[key], x[key]))
          // short-circuit the first non-equal value we find
          return false;
      } return true;
    });

export const getEquals = <A>(eq: Eq<A>): Eq<A>["equals"] => eq.equals;
