import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as t from "io-ts";

export const tagLookupC = <C extends t.Type<t.TypeOf<C>, { _tag: string }>, I = unknown>(
  codec: C,
) => new t.Type<t.TypeOf<C>, string, I>(
  `tagLookup: ${codec.name}`,
  codec.is,
  (u, c) => {
    return pipe(
      t.string.decode(u),
      E.chain(val => codec.validate({ _tag: val }, c)),
    );
  },
  flow(codec.encode, ({ _tag }) => _tag)
);
