import type { Dispatch, SetStateAction } from "react";
import type { Lens } from "monocle-ts";

import { O } from "@scripts/fp-ts";
import * as corpRouter from "@scripts/generated/routers/corpRouter";
import { Anchor } from "@scripts/react/components/Anchor";
import { CustomIndicator } from "@scripts/react/components/form/CustomIndicator";
import { useConfig } from "@scripts/react/context/Config";
import { booleanC } from "@scripts/react/form/codecs";
import type { DataCodec, FormState, UnsafeFormData, UnsafeFormProp } from "@scripts/react/form/form";
import { absoluteUrl } from "@scripts/util/url";

export const AcceptTermsCheckbox = <PC extends DataCodec>(props: {
  formState: FormState<PC>;
  setFormState: Dispatch<SetStateAction<FormState<PC>>>;
  lens: Lens<UnsafeFormData<PC>, UnsafeFormProp<boolean>>;
}) => {
  const config = useConfig();

  return (
    <CustomIndicator
      analyticsScope={O.none}
      ariaUId={O.none}
      codec={booleanC}
      lens={props.lens}
      name={"accepted"}
      state={props.formState}
      setState={props.setFormState}
      options={[{
        label: O.some(
          <>
            I accept the{" "}
            <Anchor
              externalLinkLocation={"none"}
              klasses={[]}
              route={{
                title: "terms & conditions",
                route: absoluteUrl(config)(corpRouter.corporateStaticTerms()),
              }}
              target={"_blank"}
            />
          </>),
        value: true,
      }]}
      type={"checkbox"}
      unselectedValue={O.some(false)}
    />
  );
};
