import * as t from "io-ts";
import { ActorIdCU as imported2_ActorIdCU } from "./actor";

export const validateEmailC = t.type({
  email: t.string
});
export type ValidateEmailC = typeof validateEmailC;
export type ValidateEmail = t.TypeOf<ValidateEmailC>;


export const actorPostEmailRecipientC = t.type({
  _tag: t.literal(`ActorPostEmailRecipient`),
  id: imported2_ActorIdCU
});
export type ActorPostEmailRecipientC = typeof actorPostEmailRecipientC;
export type ActorPostEmailRecipient = t.TypeOf<ActorPostEmailRecipientC>;


export const customPostEmailRecipientC = t.type({
  _tag: t.literal(`CustomPostEmailRecipient`),
  email: t.string
});
export type CustomPostEmailRecipientC = typeof customPostEmailRecipientC;
export type CustomPostEmailRecipient = t.TypeOf<CustomPostEmailRecipientC>;


export const allPostEmailRecipientC = [actorPostEmailRecipientC, customPostEmailRecipientC] as const;
export const allPostEmailRecipientNames = [`ActorPostEmailRecipient`, `CustomPostEmailRecipient`] as const;
export type PostEmailRecipientName = (typeof allPostEmailRecipientNames)[number];

export const PostEmailRecipientCU = t.union([actorPostEmailRecipientC, customPostEmailRecipientC]);
export type PostEmailRecipientCU = typeof PostEmailRecipientCU;
export type PostEmailRecipientU = t.TypeOf<PostEmailRecipientCU>;

export type PostEmailRecipientMap<A> = { [K in PostEmailRecipientName]: A };


