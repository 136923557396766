import * as t from "io-ts";

import { NumberFromUnknown } from "@scripts/codecs/numberFromUnknown";

export const qsWithTableParams = <SB extends [t.Mixed, t.Mixed, ...t.Mixed[]], P extends t.Props>(sb: t.UnionC<SB>, p: t.TypeC<P>) =>
  t.exact(t.partial({
    page: NumberFromUnknown,
    search: t.string,
    sortBy: sb,
    ...p.props,
  }));

export const defaultTablePageNumber = 1;

export const defaultTableParams = <A extends string>(sb: A) => ({
  page: defaultTablePageNumber,
  search: "",
  sortBy: sb,
});
