import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const cusip9BondName = {
  _tag: `Cusip9BondName`,
  columnName: `bond_name`,
  humanName: `Name`
} as const;

export const cusip9BondNameTaggedC = t.type({
  _tag: t.literal(`Cusip9BondName`)
});
export type Cusip9BondNameTaggedC = typeof cusip9BondNameTaggedC;
export type Cusip9BondNameTagged = t.TypeOf<Cusip9BondNameTaggedC>;
export type Cusip9BondName = Cusip9BondNameTagged & typeof cusip9BondName;
export const cusip9BondNameC = pipe(cusip9BondNameTaggedC, c => new t.Type<Cusip9BondName, Cusip9BondNameTagged>(
  `Cusip9BondName`,
  (u: unknown): u is Cusip9BondName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9BondName> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9BondName }))),
  (x: Cusip9BondName): Cusip9BondNameTagged => ({ ...x, _tag: `Cusip9BondName`}),
));
export type Cusip9BondNameC = typeof cusip9BondNameC;


export const cusip9Cusip = {
  _tag: `Cusip9Cusip`,
  columnName: `cusip`,
  humanName: `CUSIP-9`
} as const;

export const cusip9CusipTaggedC = t.type({
  _tag: t.literal(`Cusip9Cusip`)
});
export type Cusip9CusipTaggedC = typeof cusip9CusipTaggedC;
export type Cusip9CusipTagged = t.TypeOf<Cusip9CusipTaggedC>;
export type Cusip9Cusip = Cusip9CusipTagged & typeof cusip9Cusip;
export const cusip9CusipC = pipe(cusip9CusipTaggedC, c => new t.Type<Cusip9Cusip, Cusip9CusipTagged>(
  `Cusip9Cusip`,
  (u: unknown): u is Cusip9Cusip => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9Cusip> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9Cusip }))),
  (x: Cusip9Cusip): Cusip9CusipTagged => ({ ...x, _tag: `Cusip9Cusip`}),
));
export type Cusip9CusipC = typeof cusip9CusipC;


export const cusip9IssueDate = {
  _tag: `Cusip9IssueDate`,
  columnName: `issue_date`,
  humanName: `Issue Date`
} as const;

export const cusip9IssueDateTaggedC = t.type({
  _tag: t.literal(`Cusip9IssueDate`)
});
export type Cusip9IssueDateTaggedC = typeof cusip9IssueDateTaggedC;
export type Cusip9IssueDateTagged = t.TypeOf<Cusip9IssueDateTaggedC>;
export type Cusip9IssueDate = Cusip9IssueDateTagged & typeof cusip9IssueDate;
export const cusip9IssueDateC = pipe(cusip9IssueDateTaggedC, c => new t.Type<Cusip9IssueDate, Cusip9IssueDateTagged>(
  `Cusip9IssueDate`,
  (u: unknown): u is Cusip9IssueDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9IssueDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9IssueDate }))),
  (x: Cusip9IssueDate): Cusip9IssueDateTagged => ({ ...x, _tag: `Cusip9IssueDate`}),
));
export type Cusip9IssueDateC = typeof cusip9IssueDateC;


export const cusip9DatedDate = {
  _tag: `Cusip9DatedDate`,
  columnName: `dated_date`,
  humanName: `Dated Date`
} as const;

export const cusip9DatedDateTaggedC = t.type({
  _tag: t.literal(`Cusip9DatedDate`)
});
export type Cusip9DatedDateTaggedC = typeof cusip9DatedDateTaggedC;
export type Cusip9DatedDateTagged = t.TypeOf<Cusip9DatedDateTaggedC>;
export type Cusip9DatedDate = Cusip9DatedDateTagged & typeof cusip9DatedDate;
export const cusip9DatedDateC = pipe(cusip9DatedDateTaggedC, c => new t.Type<Cusip9DatedDate, Cusip9DatedDateTagged>(
  `Cusip9DatedDate`,
  (u: unknown): u is Cusip9DatedDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9DatedDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9DatedDate }))),
  (x: Cusip9DatedDate): Cusip9DatedDateTagged => ({ ...x, _tag: `Cusip9DatedDate`}),
));
export type Cusip9DatedDateC = typeof cusip9DatedDateC;


export const cusip9MaturityDate = {
  _tag: `Cusip9MaturityDate`,
  columnName: `maturity_date`,
  humanName: `Maturity Date`
} as const;

export const cusip9MaturityDateTaggedC = t.type({
  _tag: t.literal(`Cusip9MaturityDate`)
});
export type Cusip9MaturityDateTaggedC = typeof cusip9MaturityDateTaggedC;
export type Cusip9MaturityDateTagged = t.TypeOf<Cusip9MaturityDateTaggedC>;
export type Cusip9MaturityDate = Cusip9MaturityDateTagged & typeof cusip9MaturityDate;
export const cusip9MaturityDateC = pipe(cusip9MaturityDateTaggedC, c => new t.Type<Cusip9MaturityDate, Cusip9MaturityDateTagged>(
  `Cusip9MaturityDate`,
  (u: unknown): u is Cusip9MaturityDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip9MaturityDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip9MaturityDate }))),
  (x: Cusip9MaturityDate): Cusip9MaturityDateTagged => ({ ...x, _tag: `Cusip9MaturityDate`}),
));
export type Cusip9MaturityDateC = typeof cusip9MaturityDateC;


export const cusip6Name = {
  _tag: `Cusip6Name`,
  columnName: `name`,
  humanName: `Name`
} as const;

export const cusip6NameTaggedC = t.type({
  _tag: t.literal(`Cusip6Name`)
});
export type Cusip6NameTaggedC = typeof cusip6NameTaggedC;
export type Cusip6NameTagged = t.TypeOf<Cusip6NameTaggedC>;
export type Cusip6Name = Cusip6NameTagged & typeof cusip6Name;
export const cusip6NameC = pipe(cusip6NameTaggedC, c => new t.Type<Cusip6Name, Cusip6NameTagged>(
  `Cusip6Name`,
  (u: unknown): u is Cusip6Name => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6Name> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6Name }))),
  (x: Cusip6Name): Cusip6NameTagged => ({ ...x, _tag: `Cusip6Name`}),
));
export type Cusip6NameC = typeof cusip6NameC;


export const cusip6Cusip6 = {
  _tag: `Cusip6Cusip6`,
  columnName: `cusip6`,
  humanName: `CUSIP-6`
} as const;

export const cusip6Cusip6TaggedC = t.type({
  _tag: t.literal(`Cusip6Cusip6`)
});
export type Cusip6Cusip6TaggedC = typeof cusip6Cusip6TaggedC;
export type Cusip6Cusip6Tagged = t.TypeOf<Cusip6Cusip6TaggedC>;
export type Cusip6Cusip6 = Cusip6Cusip6Tagged & typeof cusip6Cusip6;
export const cusip6Cusip6C = pipe(cusip6Cusip6TaggedC, c => new t.Type<Cusip6Cusip6, Cusip6Cusip6Tagged>(
  `Cusip6Cusip6`,
  (u: unknown): u is Cusip6Cusip6 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Cusip6Cusip6> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusip6Cusip6 }))),
  (x: Cusip6Cusip6): Cusip6Cusip6Tagged => ({ ...x, _tag: `Cusip6Cusip6`}),
));
export type Cusip6Cusip6C = typeof cusip6Cusip6C;


export const documentMediaDate = {
  _tag: `DocumentMediaDate`,
  columnName: `media_date`,
  humanName: `Date`
} as const;

export const documentMediaDateTaggedC = t.type({
  _tag: t.literal(`DocumentMediaDate`)
});
export type DocumentMediaDateTaggedC = typeof documentMediaDateTaggedC;
export type DocumentMediaDateTagged = t.TypeOf<DocumentMediaDateTaggedC>;
export type DocumentMediaDate = DocumentMediaDateTagged & typeof documentMediaDate;
export const documentMediaDateC = pipe(documentMediaDateTaggedC, c => new t.Type<DocumentMediaDate, DocumentMediaDateTagged>(
  `DocumentMediaDate`,
  (u: unknown): u is DocumentMediaDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentMediaDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentMediaDate }))),
  (x: DocumentMediaDate): DocumentMediaDateTagged => ({ ...x, _tag: `DocumentMediaDate`}),
));
export type DocumentMediaDateC = typeof documentMediaDateC;


export const documentMediaCategoryId = {
  _tag: `DocumentMediaCategoryId`,
  columnName: `media_category_id`,
  humanName: `Category`
} as const;

export const documentMediaCategoryIdTaggedC = t.type({
  _tag: t.literal(`DocumentMediaCategoryId`)
});
export type DocumentMediaCategoryIdTaggedC = typeof documentMediaCategoryIdTaggedC;
export type DocumentMediaCategoryIdTagged = t.TypeOf<DocumentMediaCategoryIdTaggedC>;
export type DocumentMediaCategoryId = DocumentMediaCategoryIdTagged & typeof documentMediaCategoryId;
export const documentMediaCategoryIdC = pipe(documentMediaCategoryIdTaggedC, c => new t.Type<DocumentMediaCategoryId, DocumentMediaCategoryIdTagged>(
  `DocumentMediaCategoryId`,
  (u: unknown): u is DocumentMediaCategoryId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentMediaCategoryId> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentMediaCategoryId }))),
  (x: DocumentMediaCategoryId): DocumentMediaCategoryIdTagged => ({ ...x, _tag: `DocumentMediaCategoryId`}),
));
export type DocumentMediaCategoryIdC = typeof documentMediaCategoryIdC;


export const documentCaption = {
  _tag: `DocumentCaption`,
  columnName: `caption`,
  humanName: `Caption`
} as const;

export const documentCaptionTaggedC = t.type({
  _tag: t.literal(`DocumentCaption`)
});
export type DocumentCaptionTaggedC = typeof documentCaptionTaggedC;
export type DocumentCaptionTagged = t.TypeOf<DocumentCaptionTaggedC>;
export type DocumentCaption = DocumentCaptionTagged & typeof documentCaption;
export const documentCaptionC = pipe(documentCaptionTaggedC, c => new t.Type<DocumentCaption, DocumentCaptionTagged>(
  `DocumentCaption`,
  (u: unknown): u is DocumentCaption => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentCaption> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentCaption }))),
  (x: DocumentCaption): DocumentCaptionTagged => ({ ...x, _tag: `DocumentCaption`}),
));
export type DocumentCaptionC = typeof documentCaptionC;


export const ratingRating = {
  _tag: `RatingRating`,
  columnName: `rating`,
  humanName: `Rating`
} as const;

export const ratingRatingTaggedC = t.type({
  _tag: t.literal(`RatingRating`)
});
export type RatingRatingTaggedC = typeof ratingRatingTaggedC;
export type RatingRatingTagged = t.TypeOf<RatingRatingTaggedC>;
export type RatingRating = RatingRatingTagged & typeof ratingRating;
export const ratingRatingC = pipe(ratingRatingTaggedC, c => new t.Type<RatingRating, RatingRatingTagged>(
  `RatingRating`,
  (u: unknown): u is RatingRating => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingRating> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingRating }))),
  (x: RatingRating): RatingRatingTagged => ({ ...x, _tag: `RatingRating`}),
));
export type RatingRatingC = typeof ratingRatingC;


export const ratingRatingOutlookId = {
  _tag: `RatingRatingOutlookId`,
  columnName: `rating_outlook_id`,
  humanName: `Outlook`
} as const;

export const ratingRatingOutlookIdTaggedC = t.type({
  _tag: t.literal(`RatingRatingOutlookId`)
});
export type RatingRatingOutlookIdTaggedC = typeof ratingRatingOutlookIdTaggedC;
export type RatingRatingOutlookIdTagged = t.TypeOf<RatingRatingOutlookIdTaggedC>;
export type RatingRatingOutlookId = RatingRatingOutlookIdTagged & typeof ratingRatingOutlookId;
export const ratingRatingOutlookIdC = pipe(ratingRatingOutlookIdTaggedC, c => new t.Type<RatingRatingOutlookId, RatingRatingOutlookIdTagged>(
  `RatingRatingOutlookId`,
  (u: unknown): u is RatingRatingOutlookId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingRatingOutlookId> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingRatingOutlookId }))),
  (x: RatingRatingOutlookId): RatingRatingOutlookIdTagged => ({ ...x, _tag: `RatingRatingOutlookId`}),
));
export type RatingRatingOutlookIdC = typeof ratingRatingOutlookIdC;


export const ratingRatingDate = {
  _tag: `RatingRatingDate`,
  columnName: `rating_date`,
  humanName: `Date`
} as const;

export const ratingRatingDateTaggedC = t.type({
  _tag: t.literal(`RatingRatingDate`)
});
export type RatingRatingDateTaggedC = typeof ratingRatingDateTaggedC;
export type RatingRatingDateTagged = t.TypeOf<RatingRatingDateTaggedC>;
export type RatingRatingDate = RatingRatingDateTagged & typeof ratingRatingDate;
export const ratingRatingDateC = pipe(ratingRatingDateTaggedC, c => new t.Type<RatingRatingDate, RatingRatingDateTagged>(
  `RatingRatingDate`,
  (u: unknown): u is RatingRatingDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RatingRatingDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratingRatingDate }))),
  (x: RatingRatingDate): RatingRatingDateTagged => ({ ...x, _tag: `RatingRatingDate`}),
));
export type RatingRatingDateC = typeof ratingRatingDateC;


export const userId = {
  _tag: `UserId`,
  columnName: `id`,
  humanName: `ID`
} as const;

export const userIdTaggedC = t.type({
  _tag: t.literal(`UserId`)
});
export type UserIdTaggedC = typeof userIdTaggedC;
export type UserIdTagged = t.TypeOf<UserIdTaggedC>;
export type UserId = UserIdTagged & typeof userId;
export const userIdC = pipe(userIdTaggedC, c => new t.Type<UserId, UserIdTagged>(
  `UserId`,
  (u: unknown): u is UserId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserId> => pipe(c.decode(u), E.map(x => ({ ...x, ...userId }))),
  (x: UserId): UserIdTagged => ({ ...x, _tag: `UserId`}),
));
export type UserIdC = typeof userIdC;


export const userName = {
  _tag: `UserName`,
  columnName: `name`,
  humanName: `Name`
} as const;

export const userNameTaggedC = t.type({
  _tag: t.literal(`UserName`)
});
export type UserNameTaggedC = typeof userNameTaggedC;
export type UserNameTagged = t.TypeOf<UserNameTaggedC>;
export type UserName = UserNameTagged & typeof userName;
export const userNameC = pipe(userNameTaggedC, c => new t.Type<UserName, UserNameTagged>(
  `UserName`,
  (u: unknown): u is UserName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserName> => pipe(c.decode(u), E.map(x => ({ ...x, ...userName }))),
  (x: UserName): UserNameTagged => ({ ...x, _tag: `UserName`}),
));
export type UserNameC = typeof userNameC;


export const userEmail = {
  _tag: `UserEmail`,
  columnName: `email`,
  humanName: `Email`
} as const;

export const userEmailTaggedC = t.type({
  _tag: t.literal(`UserEmail`)
});
export type UserEmailTaggedC = typeof userEmailTaggedC;
export type UserEmailTagged = t.TypeOf<UserEmailTaggedC>;
export type UserEmail = UserEmailTagged & typeof userEmail;
export const userEmailC = pipe(userEmailTaggedC, c => new t.Type<UserEmail, UserEmailTagged>(
  `UserEmail`,
  (u: unknown): u is UserEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...userEmail }))),
  (x: UserEmail): UserEmailTagged => ({ ...x, _tag: `UserEmail`}),
));
export type UserEmailC = typeof userEmailC;


export const userCompanyName = {
  _tag: `UserCompanyName`,
  columnName: `name`,
  humanName: `Name`
} as const;

export const userCompanyNameTaggedC = t.type({
  _tag: t.literal(`UserCompanyName`)
});
export type UserCompanyNameTaggedC = typeof userCompanyNameTaggedC;
export type UserCompanyNameTagged = t.TypeOf<UserCompanyNameTaggedC>;
export type UserCompanyName = UserCompanyNameTagged & typeof userCompanyName;
export const userCompanyNameC = pipe(userCompanyNameTaggedC, c => new t.Type<UserCompanyName, UserCompanyNameTagged>(
  `UserCompanyName`,
  (u: unknown): u is UserCompanyName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyName> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyName }))),
  (x: UserCompanyName): UserCompanyNameTagged => ({ ...x, _tag: `UserCompanyName`}),
));
export type UserCompanyNameC = typeof userCompanyNameC;


export const userCompanyCount = {
  _tag: `UserCompanyCount`,
  columnName: `count`,
  humanName: `Count`
} as const;

export const userCompanyCountTaggedC = t.type({
  _tag: t.literal(`UserCompanyCount`)
});
export type UserCompanyCountTaggedC = typeof userCompanyCountTaggedC;
export type UserCompanyCountTagged = t.TypeOf<UserCompanyCountTaggedC>;
export type UserCompanyCount = UserCompanyCountTagged & typeof userCompanyCount;
export const userCompanyCountC = pipe(userCompanyCountTaggedC, c => new t.Type<UserCompanyCount, UserCompanyCountTagged>(
  `UserCompanyCount`,
  (u: unknown): u is UserCompanyCount => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyCount> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyCount }))),
  (x: UserCompanyCount): UserCompanyCountTagged => ({ ...x, _tag: `UserCompanyCount`}),
));
export type UserCompanyCountC = typeof userCompanyCountC;


export const userCompanyCanned = {
  _tag: `UserCompanyCanned`,
  columnName: `canned`,
  humanName: `Included in autocomplete?`
} as const;

export const userCompanyCannedTaggedC = t.type({
  _tag: t.literal(`UserCompanyCanned`)
});
export type UserCompanyCannedTaggedC = typeof userCompanyCannedTaggedC;
export type UserCompanyCannedTagged = t.TypeOf<UserCompanyCannedTaggedC>;
export type UserCompanyCanned = UserCompanyCannedTagged & typeof userCompanyCanned;
export const userCompanyCannedC = pipe(userCompanyCannedTaggedC, c => new t.Type<UserCompanyCanned, UserCompanyCannedTagged>(
  `UserCompanyCanned`,
  (u: unknown): u is UserCompanyCanned => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserCompanyCanned> => pipe(c.decode(u), E.map(x => ({ ...x, ...userCompanyCanned }))),
  (x: UserCompanyCanned): UserCompanyCannedTagged => ({ ...x, _tag: `UserCompanyCanned`}),
));
export type UserCompanyCannedC = typeof userCompanyCannedC;


export const emailSubject = {
  _tag: `EmailSubject`,
  columnName: `subject`,
  humanName: `Subject`
} as const;

export const emailSubjectTaggedC = t.type({
  _tag: t.literal(`EmailSubject`)
});
export type EmailSubjectTaggedC = typeof emailSubjectTaggedC;
export type EmailSubjectTagged = t.TypeOf<EmailSubjectTaggedC>;
export type EmailSubject = EmailSubjectTagged & typeof emailSubject;
export const emailSubjectC = pipe(emailSubjectTaggedC, c => new t.Type<EmailSubject, EmailSubjectTagged>(
  `EmailSubject`,
  (u: unknown): u is EmailSubject => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailSubject> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailSubject }))),
  (x: EmailSubject): EmailSubjectTagged => ({ ...x, _tag: `EmailSubject`}),
));
export type EmailSubjectC = typeof emailSubjectC;


export const emailName = {
  _tag: `EmailName`,
  columnName: `name`,
  humanName: `Name`
} as const;

export const emailNameTaggedC = t.type({
  _tag: t.literal(`EmailName`)
});
export type EmailNameTaggedC = typeof emailNameTaggedC;
export type EmailNameTagged = t.TypeOf<EmailNameTaggedC>;
export type EmailName = EmailNameTagged & typeof emailName;
export const emailNameC = pipe(emailNameTaggedC, c => new t.Type<EmailName, EmailNameTagged>(
  `EmailName`,
  (u: unknown): u is EmailName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailName> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailName }))),
  (x: EmailName): EmailNameTagged => ({ ...x, _tag: `EmailName`}),
));
export type EmailNameC = typeof emailNameC;


export const emailEmail = {
  _tag: `EmailEmail`,
  columnName: `email`,
  humanName: `Email`
} as const;

export const emailEmailTaggedC = t.type({
  _tag: t.literal(`EmailEmail`)
});
export type EmailEmailTaggedC = typeof emailEmailTaggedC;
export type EmailEmailTagged = t.TypeOf<EmailEmailTaggedC>;
export type EmailEmail = EmailEmailTagged & typeof emailEmail;
export const emailEmailC = pipe(emailEmailTaggedC, c => new t.Type<EmailEmail, EmailEmailTagged>(
  `EmailEmail`,
  (u: unknown): u is EmailEmail => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailEmail> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailEmail }))),
  (x: EmailEmail): EmailEmailTagged => ({ ...x, _tag: `EmailEmail`}),
));
export type EmailEmailC = typeof emailEmailC;


export const emailSent = {
  _tag: `EmailSent`,
  columnName: `sent`,
  humanName: `Sent`
} as const;

export const emailSentTaggedC = t.type({
  _tag: t.literal(`EmailSent`)
});
export type EmailSentTaggedC = typeof emailSentTaggedC;
export type EmailSentTagged = t.TypeOf<EmailSentTaggedC>;
export type EmailSent = EmailSentTagged & typeof emailSent;
export const emailSentC = pipe(emailSentTaggedC, c => new t.Type<EmailSent, EmailSentTagged>(
  `EmailSent`,
  (u: unknown): u is EmailSent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailSent> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailSent }))),
  (x: EmailSent): EmailSentTagged => ({ ...x, _tag: `EmailSent`}),
));
export type EmailSentC = typeof emailSentC;


export const issuerName = {
  _tag: `IssuerName`,
  columnName: `issuer`,
  humanName: `Issuer`
} as const;

export const issuerNameTaggedC = t.type({
  _tag: t.literal(`IssuerName`)
});
export type IssuerNameTaggedC = typeof issuerNameTaggedC;
export type IssuerNameTagged = t.TypeOf<IssuerNameTaggedC>;
export type IssuerName = IssuerNameTagged & typeof issuerName;
export const issuerNameC = pipe(issuerNameTaggedC, c => new t.Type<IssuerName, IssuerNameTagged>(
  `IssuerName`,
  (u: unknown): u is IssuerName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerName> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerName }))),
  (x: IssuerName): IssuerNameTagged => ({ ...x, _tag: `IssuerName`}),
));
export type IssuerNameC = typeof issuerNameC;


export const issuerBank = {
  _tag: `IssuerBank`,
  columnName: `bank`,
  humanName: `Bank`
} as const;

export const issuerBankTaggedC = t.type({
  _tag: t.literal(`IssuerBank`)
});
export type IssuerBankTaggedC = typeof issuerBankTaggedC;
export type IssuerBankTagged = t.TypeOf<IssuerBankTaggedC>;
export type IssuerBank = IssuerBankTagged & typeof issuerBank;
export const issuerBankC = pipe(issuerBankTaggedC, c => new t.Type<IssuerBank, IssuerBankTagged>(
  `IssuerBank`,
  (u: unknown): u is IssuerBank => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerBank> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerBank }))),
  (x: IssuerBank): IssuerBankTagged => ({ ...x, _tag: `IssuerBank`}),
));
export type IssuerBankC = typeof issuerBankC;


export const issuerStatus = {
  _tag: `IssuerStatus`,
  columnName: `status`,
  humanName: `Status`
} as const;

export const issuerStatusTaggedC = t.type({
  _tag: t.literal(`IssuerStatus`)
});
export type IssuerStatusTaggedC = typeof issuerStatusTaggedC;
export type IssuerStatusTagged = t.TypeOf<IssuerStatusTaggedC>;
export type IssuerStatus = IssuerStatusTagged & typeof issuerStatus;
export const issuerStatusC = pipe(issuerStatusTaggedC, c => new t.Type<IssuerStatus, IssuerStatusTagged>(
  `IssuerStatus`,
  (u: unknown): u is IssuerStatus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerStatus> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerStatus }))),
  (x: IssuerStatus): IssuerStatusTagged => ({ ...x, _tag: `IssuerStatus`}),
));
export type IssuerStatusC = typeof issuerStatusC;


export const issuerPublishDate = {
  _tag: `IssuerPublishDate`,
  columnName: `publish-date`,
  humanName: `Publish Date`
} as const;

export const issuerPublishDateTaggedC = t.type({
  _tag: t.literal(`IssuerPublishDate`)
});
export type IssuerPublishDateTaggedC = typeof issuerPublishDateTaggedC;
export type IssuerPublishDateTagged = t.TypeOf<IssuerPublishDateTaggedC>;
export type IssuerPublishDate = IssuerPublishDateTagged & typeof issuerPublishDate;
export const issuerPublishDateC = pipe(issuerPublishDateTaggedC, c => new t.Type<IssuerPublishDate, IssuerPublishDateTagged>(
  `IssuerPublishDate`,
  (u: unknown): u is IssuerPublishDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPublishDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPublishDate }))),
  (x: IssuerPublishDate): IssuerPublishDateTagged => ({ ...x, _tag: `IssuerPublishDate`}),
));
export type IssuerPublishDateC = typeof issuerPublishDateC;


export const marketInsightTitle = {
  _tag: `MarketInsightTitle`,
  columnName: `title`,
  humanName: `Title`
} as const;

export const marketInsightTitleTaggedC = t.type({
  _tag: t.literal(`MarketInsightTitle`)
});
export type MarketInsightTitleTaggedC = typeof marketInsightTitleTaggedC;
export type MarketInsightTitleTagged = t.TypeOf<MarketInsightTitleTaggedC>;
export type MarketInsightTitle = MarketInsightTitleTagged & typeof marketInsightTitle;
export const marketInsightTitleC = pipe(marketInsightTitleTaggedC, c => new t.Type<MarketInsightTitle, MarketInsightTitleTagged>(
  `MarketInsightTitle`,
  (u: unknown): u is MarketInsightTitle => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightTitle> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightTitle }))),
  (x: MarketInsightTitle): MarketInsightTitleTagged => ({ ...x, _tag: `MarketInsightTitle`}),
));
export type MarketInsightTitleC = typeof marketInsightTitleC;


export const marketInsightDescription = {
  _tag: `MarketInsightDescription`,
  columnName: `description`,
  humanName: `Description`
} as const;

export const marketInsightDescriptionTaggedC = t.type({
  _tag: t.literal(`MarketInsightDescription`)
});
export type MarketInsightDescriptionTaggedC = typeof marketInsightDescriptionTaggedC;
export type MarketInsightDescriptionTagged = t.TypeOf<MarketInsightDescriptionTaggedC>;
export type MarketInsightDescription = MarketInsightDescriptionTagged & typeof marketInsightDescription;
export const marketInsightDescriptionC = pipe(marketInsightDescriptionTaggedC, c => new t.Type<MarketInsightDescription, MarketInsightDescriptionTagged>(
  `MarketInsightDescription`,
  (u: unknown): u is MarketInsightDescription => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightDescription> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightDescription }))),
  (x: MarketInsightDescription): MarketInsightDescriptionTagged => ({ ...x, _tag: `MarketInsightDescription`}),
));
export type MarketInsightDescriptionC = typeof marketInsightDescriptionC;


export const marketInsightDate = {
  _tag: `MarketInsightDate`,
  columnName: `date`,
  humanName: `Date`
} as const;

export const marketInsightDateTaggedC = t.type({
  _tag: t.literal(`MarketInsightDate`)
});
export type MarketInsightDateTaggedC = typeof marketInsightDateTaggedC;
export type MarketInsightDateTagged = t.TypeOf<MarketInsightDateTaggedC>;
export type MarketInsightDate = MarketInsightDateTagged & typeof marketInsightDate;
export const marketInsightDateC = pipe(marketInsightDateTaggedC, c => new t.Type<MarketInsightDate, MarketInsightDateTagged>(
  `MarketInsightDate`,
  (u: unknown): u is MarketInsightDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightDate }))),
  (x: MarketInsightDate): MarketInsightDateTagged => ({ ...x, _tag: `MarketInsightDate`}),
));
export type MarketInsightDateC = typeof marketInsightDateC;


export const marketInsightPublication = {
  _tag: `MarketInsightPublication`,
  columnName: `publication`,
  humanName: `Publication`
} as const;

export const marketInsightPublicationTaggedC = t.type({
  _tag: t.literal(`MarketInsightPublication`)
});
export type MarketInsightPublicationTaggedC = typeof marketInsightPublicationTaggedC;
export type MarketInsightPublicationTagged = t.TypeOf<MarketInsightPublicationTaggedC>;
export type MarketInsightPublication = MarketInsightPublicationTagged & typeof marketInsightPublication;
export const marketInsightPublicationC = pipe(marketInsightPublicationTaggedC, c => new t.Type<MarketInsightPublication, MarketInsightPublicationTagged>(
  `MarketInsightPublication`,
  (u: unknown): u is MarketInsightPublication => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketInsightPublication> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketInsightPublication }))),
  (x: MarketInsightPublication): MarketInsightPublicationTagged => ({ ...x, _tag: `MarketInsightPublication`}),
));
export type MarketInsightPublicationC = typeof marketInsightPublicationC;


export const allSortColumnC = [cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC, cusip6NameC, cusip6Cusip6C, documentMediaDateC, documentMediaCategoryIdC, documentCaptionC, ratingRatingC, ratingRatingOutlookIdC, ratingRatingDateC, userIdC, userNameC, userEmailC, userCompanyNameC, userCompanyCountC, userCompanyCannedC, emailSubjectC, emailNameC, emailEmailC, emailSentC, issuerNameC, issuerBankC, issuerStatusC, issuerPublishDateC, marketInsightTitleC, marketInsightDescriptionC, marketInsightDateC, marketInsightPublicationC] as const;
export const allSortColumnNames = [`Cusip9BondName`, `Cusip9Cusip`, `Cusip9IssueDate`, `Cusip9DatedDate`, `Cusip9MaturityDate`, `Cusip6Name`, `Cusip6Cusip6`, `DocumentMediaDate`, `DocumentMediaCategoryId`, `DocumentCaption`, `RatingRating`, `RatingRatingOutlookId`, `RatingRatingDate`, `UserId`, `UserName`, `UserEmail`, `UserCompanyName`, `UserCompanyCount`, `UserCompanyCanned`, `EmailSubject`, `EmailName`, `EmailEmail`, `EmailSent`, `IssuerName`, `IssuerBank`, `IssuerStatus`, `IssuerPublishDate`, `MarketInsightTitle`, `MarketInsightDescription`, `MarketInsightDate`, `MarketInsightPublication`] as const;
export type SortColumnName = (typeof allSortColumnNames)[number];

export const SortColumnCU = t.union([cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC, cusip6NameC, cusip6Cusip6C, documentMediaDateC, documentMediaCategoryIdC, documentCaptionC, ratingRatingC, ratingRatingOutlookIdC, ratingRatingDateC, userIdC, userNameC, userEmailC, userCompanyNameC, userCompanyCountC, userCompanyCannedC, emailSubjectC, emailNameC, emailEmailC, emailSentC, issuerNameC, issuerBankC, issuerStatusC, issuerPublishDateC, marketInsightTitleC, marketInsightDescriptionC, marketInsightDateC, marketInsightPublicationC]);
export type SortColumnCU = typeof SortColumnCU;
export type SortColumnU = t.TypeOf<SortColumnCU>;

export const sortColumnOrd: Ord.Ord<SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortColumn = [cusip9BondName, cusip9Cusip, cusip9IssueDate, cusip9DatedDate, cusip9MaturityDate, cusip6Name, cusip6Cusip6, documentMediaDate, documentMediaCategoryId, documentCaption, ratingRating, ratingRatingOutlookId, ratingRatingDate, userId, userName, userEmail, userCompanyName, userCompanyCount, userCompanyCanned, emailSubject, emailName, emailEmail, emailSent, issuerName, issuerBank, issuerStatus, issuerPublishDate, marketInsightTitle, marketInsightDescription, marketInsightDate, marketInsightPublication] as const;
export type SortColumnMap<A> = { [K in SortColumnName]: A };


export const allCusip9SortColumnC = [cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC] as const;
export const allCusip9SortColumnNames = [`Cusip9BondName`, `Cusip9Cusip`, `Cusip9IssueDate`, `Cusip9DatedDate`, `Cusip9MaturityDate`] as const;
export type Cusip9SortColumnName = (typeof allCusip9SortColumnNames)[number];

export const Cusip9SortColumnCU = t.union([cusip9BondNameC, cusip9CusipC, cusip9IssueDateC, cusip9DatedDateC, cusip9MaturityDateC]);
export type Cusip9SortColumnCU = typeof Cusip9SortColumnCU;
export type Cusip9SortColumnU = t.TypeOf<Cusip9SortColumnCU>;

export const cusip9SortColumnOrd: Ord.Ord<Cusip9SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCusip9SortColumn = [cusip9BondName, cusip9Cusip, cusip9IssueDate, cusip9DatedDate, cusip9MaturityDate] as const;
export type Cusip9SortColumnMap<A> = { [K in Cusip9SortColumnName]: A };


export const allCusip6SortColumnC = [cusip6NameC, cusip6Cusip6C] as const;
export const allCusip6SortColumnNames = [`Cusip6Name`, `Cusip6Cusip6`] as const;
export type Cusip6SortColumnName = (typeof allCusip6SortColumnNames)[number];

export const Cusip6SortColumnCU = t.union([cusip6NameC, cusip6Cusip6C]);
export type Cusip6SortColumnCU = typeof Cusip6SortColumnCU;
export type Cusip6SortColumnU = t.TypeOf<Cusip6SortColumnCU>;

export const cusip6SortColumnOrd: Ord.Ord<Cusip6SortColumnU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCusip6SortColumn = [cusip6Name, cusip6Cusip6] as const;
export type Cusip6SortColumnMap<A> = { [K in Cusip6SortColumnName]: A };


