import type * as Th from "fp-ts/lib/These";
import * as t from "io-ts";

export function theseC<LI, LO, RI, RO>(lCodec: t.Type<LI, LO>, rCodec: t.Type<RI, RO>): t.Type<Th.These<LI, RI>, Th.These<LO, RO>> {
  const c = t.union([
    t.strict({ _tag: t.literal("Left"), left: lCodec }),
    t.strict({ _tag: t.literal("Right"), right: rCodec }),
    t.strict({ _tag: t.literal("Both"), left: lCodec, right: rCodec }),
  ]);
  return new t.Type<Th.These<LI, RI>, Th.These<LO, RO>>(`These<${lCodec.name}, ${rCodec.name}>`, c.is, c.validate, c.encode);
}
