import { WithStatusCU as imported356_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { bondOfferingC as imported28_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported62_rfpC } from "./rfpBase";

export const contentNoteC = t.type({
  content: t.string
});
export type ContentNoteC = typeof contentNoteC;
export type ContentNote = t.TypeOf<ContentNoteC>;


export const offeringContentNotePostC = t.type({
  content: t.string,
  offeringId: t.number,
  id: optionFromNullable(t.number)
});
export type OfferingContentNotePostC = typeof offeringContentNotePostC;
export type OfferingContentNotePost = t.TypeOf<OfferingContentNotePostC>;


export const rfpContentNotePostC = t.type({
  content: t.string,
  rfpId: t.number,
  id: optionFromNullable(t.number)
});
export type RfpContentNotePostC = typeof rfpContentNotePostC;
export type RfpContentNotePost = t.TypeOf<RfpContentNotePostC>;


export const contentNoteForContactSubmissionPostC = t.type({
  content: t.string,
  contactSubmissionId: t.number
});
export type ContentNoteForContactSubmissionPostC = typeof contentNoteForContactSubmissionPostC;
export type ContentNoteForContactSubmissionPost = t.TypeOf<ContentNoteForContactSubmissionPostC>;


export class activeDealInfoRequestCountCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  deal: imported356_WithStatusCU(A1),
  count: t.number,
  mostRecent: LocalDateTimeFromIsoStringC
})}
export const activeDealInfoRequestCountC = <A1 extends t.Mixed>(A1: A1) => new activeDealInfoRequestCountCC<A1>().codec(A1);
export type ActiveDealInfoRequestCountC<A1 extends t.Mixed> = ReturnType<activeDealInfoRequestCountCC<A1>["codec"]>;
export type ActiveDealInfoRequestCount<A1> = t.TypeOf<ActiveDealInfoRequestCountC<t.Type<A1>>>;

export const activeDealsInfoRequestCountC = t.type({
  offerings: t.readonlyArray(activeDealInfoRequestCountC(imported28_bondOfferingC)),
  rfps: t.readonlyArray(activeDealInfoRequestCountC(imported62_rfpC))
});
export type ActiveDealsInfoRequestCountC = typeof activeDealsInfoRequestCountC;
export type ActiveDealsInfoRequestCount = t.TypeOf<ActiveDealsInfoRequestCountC>;


