import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { SortTypeCU as imported1_SortTypeCU } from "../domaintables/sortTypes";

export const sortItemsC = t.type({
  sortType: imported1_SortTypeCU,
  sortIds: readonlyNonEmptyArrayC(t.number)
});
export type SortItemsC = typeof sortItemsC;
export type SortItems = t.TypeOf<SortItemsC>;


