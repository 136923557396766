import * as RA from "fp-ts/lib/ReadonlyArray";
import type { Action } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { pipe } from "@scripts/fp-ts/function";
import type { AnyPageMeta, PageRouting } from "@scripts/routes/routing/base";

import type { PageHistoryOldToNew } from "./store";

export const routerSetPageMeta = <T extends PageRouting>(page: T): Action<"ROUTER_SET_PAGEMETA"> & { page: T } => ({
  type: "ROUTER_SET_PAGEMETA",
  page,
});

type RouterSetPageMeta = ReturnType<typeof routerSetPageMeta>;

export const routerSetPageLastMeta = <T extends PageRouting>(page: T): Action<"ROUTER_SET_PAGE_LAST_META"> & { page: T } => ({
  type: "ROUTER_SET_PAGE_LAST_META",
  page,
});

type RouterSetPageLastMeta = ReturnType<typeof routerSetPageLastMeta>;

export type RouterAction = RouterSetPageMeta | RouterSetPageLastMeta;

export const routerReducer = (config: BLConfigWithLog) =>
  <T extends PageRouting>(pageCurrent: T, action: RouterAction, pageHistoryOldToNew: PageHistoryOldToNew): { page: T, pageHistoryOldToNew: PageHistoryOldToNew } => {
    const pageHistoryLength = pageHistoryOldToNew.length;
    switch (action.type) {
      case "ROUTER_SET_PAGEMETA": {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const actionPage = action.page as T;

        return {
          page: actionPage,
          pageHistoryOldToNew: pageCurrent.title() === action.page.title()
            ? pageHistoryOldToNew
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            : pipe(pageHistoryOldToNew, RA.append(actionPage as unknown as AnyPageMeta), RA.takeRight(5)),
        };
      }
      case "ROUTER_SET_PAGE_LAST_META": {
        const newPageHistory = pageHistoryOldToNew.slice(0, pageHistoryLength - 1);
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return { page: action.page as T, pageHistoryOldToNew: newPageHistory };
      }
    }
    return config.exhaustive(action);
  };
