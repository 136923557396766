export * from "fp-ts/lib/Array";
import * as A from "fp-ts/lib/Array";

type Enumerate<N extends number, Acc extends number[] = []> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

export type IntRangeExcl<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;
export type IntRangeIncl<F extends number, T extends number> = IntRangeExcl<F, T> | T;

type ExtraArrayFields<A> = Omit<A[], number | "length">;

/**
 * MaxNArray<A, N> is an Array of A with length <= N
 */
export type ArrayMaxN<A, N extends number> = { [i in IntRangeExcl<0, N>]?: A } & {
  length: IntRangeExcl<0, N> | N;
} & ExtraArrayFields<A>;

/**
 * mapMaxN is the same functionality as MapWithIndex, except it only deals with MaxNArray types
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mapWithIndexMaxN = A.mapWithIndex as <A, B, N extends number>(fn: (idx: IntRangeExcl<0, N>, item: A) => B) => (arr: ArrayMaxN<A, N>) => ArrayMaxN<B, N>;

/**
 * firstN<A, N>(n)(arr) returns the first n elements of arr as a MaxNArray<A, N>
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const firstN = <N extends number>(n: N) => <A>(arr: A[]): ArrayMaxN<A, N> => arr.slice(0, n) as unknown as ArrayMaxN<A, N>;

