import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const callable = {
  _tag: `Callable`,
  id: 1,
  name: `Callable`
} as const;

export const callableTaggedC = t.type({
  _tag: t.literal(`Callable`)
});
export type CallableTaggedC = typeof callableTaggedC;
export type CallableTagged = t.TypeOf<CallableTaggedC>;
export type Callable = CallableTagged & typeof callable;
export const callableC = pipe(callableTaggedC, c => new t.Type<Callable, CallableTagged>(
  `Callable`,
  (u: unknown): u is Callable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Callable> => pipe(c.decode(u), E.map(x => ({ ...x, ...callable }))),
  (x: Callable): CallableTagged => ({ ...x, _tag: `Callable`}),
));
export type CallableC = typeof callableC;


export const nonCallable = {
  _tag: `NonCallable`,
  id: 2,
  name: `Non-Callable`
} as const;

export const nonCallableTaggedC = t.type({
  _tag: t.literal(`NonCallable`)
});
export type NonCallableTaggedC = typeof nonCallableTaggedC;
export type NonCallableTagged = t.TypeOf<NonCallableTaggedC>;
export type NonCallable = NonCallableTagged & typeof nonCallable;
export const nonCallableC = pipe(nonCallableTaggedC, c => new t.Type<NonCallable, NonCallableTagged>(
  `NonCallable`,
  (u: unknown): u is NonCallable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonCallable> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonCallable }))),
  (x: NonCallable): NonCallableTagged => ({ ...x, _tag: `NonCallable`}),
));
export type NonCallableC = typeof nonCallableC;


export const allCallStatusC = [callableC, nonCallableC] as const;
export const allCallStatusNames = [`Callable`, `NonCallable`] as const;
export type CallStatusName = (typeof allCallStatusNames)[number];

export const CallStatusCU = t.union([callableC, nonCallableC]);
export type CallStatusCU = typeof CallStatusCU;
export type CallStatusU = t.TypeOf<CallStatusCU>;

export const callStatusOrd: Ord.Ord<CallStatusU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCallStatus = [callable, nonCallable] as const;
export type CallStatusMap<A> = { [K in CallStatusName]: A };


