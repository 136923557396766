import type { Big } from "big.js";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import type { Eq } from "fp-ts/lib/Eq";
import { eqStrict, fromEquals, struct } from "fp-ts/lib/Eq";
import { flow, pipe } from "fp-ts/lib/function";
import type { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import * as t from "io-ts";
import { nonEmptyArray, option } from "io-ts-types";
import { BooleanFromString } from "io-ts-types/lib/BooleanFromString";

import { actorIdUEq, recipientEq, stringToActorIdC, stringToRecipientC } from "@scripts/api/email";
import { bigNumberC } from "@scripts/Big";
import type { Html } from "@scripts/codecs/html";
import { htmlC as htmlTagC } from "@scripts/codecs/html";
import { LocalDateC } from "@scripts/codecs/localDate";
import type { Markdown } from "@scripts/codecs/markdown";
import { markdownC as markdownTagC } from "@scripts/codecs/markdown";
import { NumberFromUnknown } from "@scripts/codecs/numberFromUnknown";
import { arrayElementSeparator, QueryStringArray } from "@scripts/codecs/routing";
import { N, O, RA, RNEA, s as Str } from "@scripts/fp-ts";
import { nullable, nullableStrict } from "@scripts/fp-ts/Eq";
import { isNonEmpty } from "@scripts/fp-ts/string";
import type { SectorU } from "@scripts/generated/domaintables/sectors";
import { SectorCU } from "@scripts/generated/domaintables/sectors";
import type { StateInfoU } from "@scripts/generated/domaintables/states";
import { StateInfoCU } from "@scripts/generated/domaintables/states";
import { ActorIdCU, type ActorIdU } from "@scripts/generated/models/actor";
import { PostEmailRecipientCU, type PostEmailRecipientU } from "@scripts/generated/models/email";
import type { Time } from "@scripts/generated/models/rfpBase";
import { timeC as TimeC } from "@scripts/generated/models/rfpBase";
import type { Joda } from "@scripts/syntax/date/joda";
import { LocalDateEq } from "@scripts/syntax/date/jodaSyntax";
import { coerceHtmlAsString } from "@scripts/syntax/html";
import { formatTime } from "@scripts/syntax/timeWithZone";
import type { DeepPartialWithOptions } from "@scripts/types";
import { fromNullableOrOption } from "@scripts/util/fromNullableOrOption";

import { type UnsafeFormDataNoCodec, type UnsafeFormProp } from "./form";

export interface Codec<A, KV extends UnsafeFormProp<A> | null = UnsafeFormProp<A> | null> {
  kind: NonEmptyArray<string>;
  decode: (s: unknown) => t.Validation<NonNullable<KV>>;
  encode: (k: KV) => O.Option<string>;
  eq: Eq<KV>;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const codecToUnsafeCodec = <A>(codec: Codec<A>) => codec as Codec<UnsafeFormDataNoCodec<A>>;

export const codecToFormCodec = <A>(c: t.Type<NonNullable<A>, string>): Codec<A> => ({
  kind: ["mixedToFormCodec", c.name],
  decode: c.decode,
  encode: flow(O.fromNullable, O.map(c.encode)),
  eq: eqStrict,
});

export const stringC: Codec<string> = {
  kind: ["stringC"],
  decode: t.string.decode,
  encode: flow(O.fromNullable, O.filter(isNonEmpty)),
  eq: eqStrict,
};

export const numberC: Codec<number> = {
  kind: ["numberC"],
  decode: NumberFromUnknown.decode,
  encode: flow(O.fromNullable, O.map(n => n.toString())),
  eq: eqStrict,
};

const numericFormatMatchComma = /,/g;
export const numericFormatMatchDecimal = /\.\d*/g;

const decodeTwoDecimals = <A>(a: Pick<Codec<A>, "decode">) => flow(
  t.string.decode,
  E.map(s => s.replace(numericFormatMatchComma, "")),
  E.chain(a.decode),
);

export const numericFormatC: Codec<number> = {
  kind: ["numericFormatC"],
  decode: decodeTwoDecimals(numberC),
  encode: flow(
    O.fromNullable,
    O.map(_ => _.toString()),
  ),
  eq: eqStrict,
};

export const bigNumericFormatC: Codec<Big> = {
  kind: ["bigNumericFormatC"],
  decode: decodeTwoDecimals(bigNumberC),
  encode: flow(
    O.fromNullable,
    O.map(_ => _.toString()),
  ),
  eq: eqStrict,
};

export const booleanC: Codec<boolean> = {
  kind: ["booleanC"],
  decode: (v: unknown) => pipe(
    t.boolean.decode(v),
    E.orElse(() => BooleanFromString.decode(v))),
  encode: flow(O.fromNullable, O.map(n => n.toString())),
  eq: eqStrict,
};

export const optionCUniqueKind = "optionC";

export const optionC = <KV>(codec: Codec<KV>): Codec<O.Option<KV>> => ({
  kind: [optionCUniqueKind, ...codec.kind],
  decode: flow(
    fromNullableOrOption,
    O.filter(s => Str.is(s) ? isNonEmpty(s) : true),
    O.fold(
      (): t.Validation<O.Option<KV>> => E.right(O.none),
      (v: unknown): t.Validation<O.Option<KV>> => pipe(codec.decode(v), E.map((k: KV) => O.some(k)))
    )
  ),
  encode: flow(fromNullableOrOption, O.chain(codec.encode)),
  eq: fromEquals((a: O.Option<KV> | null | undefined, b: O.Option<KV> | null | undefined) =>
    (O.is(a) && O.is(b)) ? O.getEq(codec.eq).equals(a, b) : eqStrict.equals(a, b)),
});

export const arrayFromStringC = <KV>(codec: Codec<KV>): Codec<KV[]> => ({
  kind: [optionCUniqueKind, ...codec.kind],
  decode: flow(
    QueryStringArray(t.unknown).decode,
    E.chain(
      RA.reduce(t.success([]), (acc: t.Validation<Array<NonNullable<KV>>>, curr) => pipe(
        codec.decode(curr),
        E.chain(
          kv => pipe(
            acc,
            E.map(A.append(kv))
          )
        )
      ))
    )
  ),
  encode: flow(fromNullableOrOption, O.map(arr => arr.map(codec.encode).join(arrayElementSeparator))),
  eq: fromEquals((a: KV[] | null | undefined, b: KV[] | null | undefined) =>
    (O.is(a) && O.is(b)) ? O.getEq(codec.eq).equals(a, b) : eqStrict.equals(a, b)),
});


export const localDateC: Codec<Joda.LocalDate> = {
  kind: ["localDateC"],
  decode: LocalDateC.decode,
  encode: flow(O.fromNullable, O.map(LocalDateC.encode)),
  eq: nullableStrict(LocalDateEq),
};
export const markdownFailureMessage = "Markdown must be non-empty";
export const markdownC: Codec<Markdown> = {
  kind: ["markdownC"],
  decode: markdownTagC.decode,
  encode: flow(O.fromNullable, O.map(markdownTagC.encode)),
  eq: eqStrict,
};

export const htmlC: Codec<Html> = {
  kind: ["htmlC"],
  decode: htmlTagC.decode,
  encode: flow(O.fromNullable, O.map(coerceHtmlAsString)),
  eq: eqStrict,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCodecKind = <A>(c: Codec<A>, ...compareAgainst: Array<Codec<any>>): boolean => A.exists(
  (codecKind: string) => A.exists(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (compareCodec: Codec<any>) => A.exists(
      (compareKind: string) => compareKind === codecKind
    )(compareCodec.kind)
  )(compareAgainst)
)(c.kind);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumberC = <A>(c: Codec<A>, ...compareAgainst: Array<Codec<any>>) => isCodecKind(c, numberC, ...compareAgainst);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumericC = <A>(c: Codec<A>, ...compareAgainst: Array<Codec<any>>) => isCodecKind(c, numericFormatC, bigNumericFormatC, ...compareAgainst) || isNumberC(c, ...compareAgainst);
export const isOptionC = <A>(c: Codec<A>): boolean => c.kind.includes(optionCUniqueKind);
export const isRequiredC = <A>(c: Codec<A>): boolean => !isOptionC(c);

export const stateC: Codec<DeepPartialWithOptions<StateInfoU>> = {
  kind: ["stateC"],
  decode: StateInfoCU.decode,
  encode: flow(O.fromNullable, O.chain(s => O.fromNullable(s.name))),
  eq: { equals: (a, b) => (a == null || b == null) ? eqStrict.equals(a, b) : a.id === b.id },
};

export const issuerTypeC: Codec<DeepPartialWithOptions<SectorU>> = {
  kind: ["issuerTypeC"],
  decode: SectorCU.decode,
  encode: flow(O.fromNullable, O.chain(i => O.fromNullable(i.name))),
  eq: { equals: (a, b) => (a == null || b == null) ? eqStrict.equals(a, b) : a.id === b.id },
};

export const timeC: Codec<Time> = {
  kind: ["timeC"],
  decode: TimeC.decode,
  encode: flow(O.fromNullable, O.map(_ => formatTime(_))),
  eq: { equals: (a, b) => (a == null || b == null) ? eqStrict.equals(a, b) : a.hour === b.hour && a.minute === b.minute },
};

export const rneaKind = "rnea";

const splitIfString = (u: unknown): unknown => t.string.is(u) ? u.split(arrayElementSeparator) : u;
export const rneaC = <A>(inner: Codec<A>): Codec<RNEA.ReadonlyNonEmptyArray<A>> => ({
  kind: [rneaKind, ...inner.kind],
  decode: flow(splitIfString, nonEmptyArray(t.unknown).decode, E.chain(RNEA.traverse(E.Applicative)(inner.decode))),
  encode: (b) => pipe(b, O.fromNullable, O.map(flow(RA.filterMap(inner.encode), (a) => a.join(arrayElementSeparator)))),
  eq: fromEquals((a, b) => (a != null && b != null) ? RNEA.getEq(inner.eq).equals(a, b) : false),
});

export const readonlyArrayC = <A>(inner: Codec<A>): Codec<ReadonlyArray<A>> => ({
  kind: ["readonlyArray", ...inner.kind],
  decode: flow(splitIfString, t.readonlyArray(t.unknown).decode, E.chain(RA.traverse(E.Applicative)(inner.decode))),
  encode: flow(O.fromNullable, O.chain(O.fromPredicate(RA.isNonEmpty)), O.map(flow(RA.filterMap(inner.encode), (a) => a.join(arrayElementSeparator)))),
  eq: fromEquals((a, b) => (a != null && b != null) ? RA.getEq(inner.eq).equals(a, b) : false),
});

export const actorIdC: Codec<ActorIdU> = {
  kind: ["actorIdC"],
  decode: (u: unknown) => t.string.is(u) ? stringToActorIdC.decode(u) : ActorIdCU.decode(u),
  encode: flow(O.fromNullable, O.map(stringToActorIdC.encode)),
  eq: fromEquals((a, b) => (a != null && b != null) ? actorIdUEq.equals(a, b) : false),
};

export const emailRecipientC: Codec<Partial<PostEmailRecipientU>> = {
  kind: ["emailRecipientC"],
  decode: (u: unknown) => t.string.is(u) ? stringToRecipientC.decode(u) : PostEmailRecipientCU.decode(u),
  encode: flow(O.fromNullable, O.chain(flow(PostEmailRecipientCU.decode, O.fromEither)), O.map(stringToRecipientC.encode)),
  eq: fromEquals((a, b) => (PostEmailRecipientCU.is(a) && PostEmailRecipientCU.is(b)) ? recipientEq.equals(a, b) : false),
};

const eitherSeriesIdPartialC = t.partial({
  iceSeriesId: option(t.number),
  nonIceDebtInstrumentId: option(t.number),
});
type EitherSeriesIdPartial = t.TypeOf<typeof eitherSeriesIdPartialC>;
export const eitherSeriesIdFC: Codec<EitherSeriesIdPartial> = {
  kind: ["eitherSeriesId"],
  decode: (u: unknown) => eitherSeriesIdPartialC.decode(u),
  encode: flow(O.fromNullable, O.map(JSON.stringify)),
  eq: fromEquals((a, b) => (eitherSeriesIdPartialC.is(a) && eitherSeriesIdPartialC.is(b)) && struct<EitherSeriesIdPartial>({
    iceSeriesId: nullable(O.getEq(N.Eq)),
    nonIceDebtInstrumentId: nullable(O.getEq(N.Eq)),
  }).equals(a, b)),
};
