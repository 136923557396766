import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const generalObligation = {
  _tag: `GeneralObligation`,
  id: 1,
  name: `General Obligation`
} as const;

export const generalObligationTaggedC = t.type({
  _tag: t.literal(`GeneralObligation`)
});
export type GeneralObligationTaggedC = typeof generalObligationTaggedC;
export type GeneralObligationTagged = t.TypeOf<GeneralObligationTaggedC>;
export type GeneralObligation = GeneralObligationTagged & typeof generalObligation;
export const generalObligationC = pipe(generalObligationTaggedC, c => new t.Type<GeneralObligation, GeneralObligationTagged>(
  `GeneralObligation`,
  (u: unknown): u is GeneralObligation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralObligation> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalObligation }))),
  (x: GeneralObligation): GeneralObligationTagged => ({ ...x, _tag: `GeneralObligation`}),
));
export type GeneralObligationC = typeof generalObligationC;


export const revenue = {
  _tag: `Revenue`,
  id: 2,
  name: `Revenue`
} as const;

export const revenueTaggedC = t.type({
  _tag: t.literal(`Revenue`)
});
export type RevenueTaggedC = typeof revenueTaggedC;
export type RevenueTagged = t.TypeOf<RevenueTaggedC>;
export type Revenue = RevenueTagged & typeof revenue;
export const revenueC = pipe(revenueTaggedC, c => new t.Type<Revenue, RevenueTagged>(
  `Revenue`,
  (u: unknown): u is Revenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Revenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...revenue }))),
  (x: Revenue): RevenueTagged => ({ ...x, _tag: `Revenue`}),
));
export type RevenueC = typeof revenueC;


export const allRepaymentSourceC = [generalObligationC, revenueC] as const;
export const allRepaymentSourceNames = [`GeneralObligation`, `Revenue`] as const;
export type RepaymentSourceName = (typeof allRepaymentSourceNames)[number];

export const RepaymentSourceCU = t.union([generalObligationC, revenueC]);
export type RepaymentSourceCU = typeof RepaymentSourceCU;
export type RepaymentSourceU = t.TypeOf<RepaymentSourceCU>;

export const repaymentSourceOrd: Ord.Ord<RepaymentSourceU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRepaymentSource = [generalObligation, revenue] as const;
export type RepaymentSourceMap<A> = { [K in RepaymentSourceName]: A };


