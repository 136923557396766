import type { Dispatch, SetStateAction } from "react";
import { constVoid } from "fp-ts/lib/function";

import { E, O } from "@scripts/fp-ts";
import type { MfaInitiated } from "@scripts/generated/domaintables/loginStatus";
import type { LoginPostC } from "@scripts/generated/models/login";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonLink, ButtonSubmit } from "@scripts/react/components/Button";
import { ForgotPasswordModal, Form } from "@scripts/react/components/form/Form";
import { CurrentPasswordInput } from "@scripts/react/components/form/PasswordInput";
import { EmailInput } from "@scripts/react/components/form/TextInput";
import { useConfig } from "@scripts/react/context/Config";
import { stringC } from "@scripts/react/form/codecs";
import type { FormState } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { exhaustive } from "@scripts/util/exhaustive";

import { loginFormL, loginSignUpRedirect } from "./loginPageSyntax";

const ForgotPasswordCallout = () => {
  const [modalOpen, openModal, closeModal] = useModal("Forgot Password Modal");

  return (
    <>
      <ButtonLink
        {...klass("mt-2")}
        onClick={openModal}
      >
        Forgot your password?
      </ButtonLink>
      <ForgotPasswordModal modalOpen={modalOpen} dismissAction={closeModal} />
    </>
  );
};

export const LoginForm = (props: {
  state: FormState<LoginPostC>;
  setState: Dispatch<SetStateAction<FormState<LoginPostC>>>;
  setMfaInitiated: Dispatch<SetStateAction<O.Option<MfaInitiated>>>;
}) => {
  const config = useConfig();
  return (
    <Form
      url={V2Router.baseAuthControllerLoginPost()}
      state={props.state}
      setState={props.setState}
      onSuccess={(_, resp) => {
        switch (resp.data._tag) {
          case "Redirect": return loginSignUpRedirect(resp.data.redirect);
          case "MfaInitiated": return props.setMfaInitiated(O.some(resp.data));
        }
        return exhaustive(config.log)(resp.data);
      }}
      onFailure={O.none}
      headers={O.none}
    >
      <EmailInput
        autoComplete="username"
        codec={stringC}
        labelOrAriaLabel={E.left("Email address")}
        lens={loginFormL("email")}
        state={props.state}
        setState={props.setState}
      />
      <CurrentPasswordInput
        codec={stringC}
        labelOrAriaLabel={E.left("Password")}
        lens={loginFormL("password")}
        placeholder={O.none}
        state={props.state}
        setState={props.setState}
      />
      <div {...klass("form-footer-container")}>
        <ButtonSubmit
          {...klass("w-100")}
          loading={props.state.loading}
          loadingText={"Logging In"}
          onClick={constVoid}
          text={"Log In"}
        />
        <ForgotPasswordCallout />
      </div>
    </Form>
  );
};
