import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const DUPLICATE_UNIQUE_VALUE = {
  _tag: `DUPLICATE_UNIQUE_VALUE`,
  error: `DUPLICATE_UNIQUE_VALUE`
} as const;

export const DUPLICATE_UNIQUE_VALUETaggedC = t.type({
  _tag: t.literal(`DUPLICATE_UNIQUE_VALUE`)
});
export type DUPLICATE_UNIQUE_VALUETaggedC = typeof DUPLICATE_UNIQUE_VALUETaggedC;
export type DUPLICATE_UNIQUE_VALUETagged = t.TypeOf<DUPLICATE_UNIQUE_VALUETaggedC>;
export type DUPLICATE_UNIQUE_VALUE = DUPLICATE_UNIQUE_VALUETagged & typeof DUPLICATE_UNIQUE_VALUE;
export const DUPLICATE_UNIQUE_VALUEC = pipe(DUPLICATE_UNIQUE_VALUETaggedC, c => new t.Type<DUPLICATE_UNIQUE_VALUE, DUPLICATE_UNIQUE_VALUETagged>(
  `DUPLICATE_UNIQUE_VALUE`,
  (u: unknown): u is DUPLICATE_UNIQUE_VALUE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DUPLICATE_UNIQUE_VALUE> => pipe(c.decode(u), E.map(x => ({ ...x, ...DUPLICATE_UNIQUE_VALUE }))),
  (x: DUPLICATE_UNIQUE_VALUE): DUPLICATE_UNIQUE_VALUETagged => ({ ...x, _tag: `DUPLICATE_UNIQUE_VALUE`}),
));
export type DUPLICATE_UNIQUE_VALUEC = typeof DUPLICATE_UNIQUE_VALUEC;


export const DUPLICATE_USER_EMAIL = {
  _tag: `DUPLICATE_USER_EMAIL`,
  error: `DUPLICATE_USER_EMAIL`
} as const;

export const DUPLICATE_USER_EMAILTaggedC = t.type({
  _tag: t.literal(`DUPLICATE_USER_EMAIL`)
});
export type DUPLICATE_USER_EMAILTaggedC = typeof DUPLICATE_USER_EMAILTaggedC;
export type DUPLICATE_USER_EMAILTagged = t.TypeOf<DUPLICATE_USER_EMAILTaggedC>;
export type DUPLICATE_USER_EMAIL = DUPLICATE_USER_EMAILTagged & typeof DUPLICATE_USER_EMAIL;
export const DUPLICATE_USER_EMAILC = pipe(DUPLICATE_USER_EMAILTaggedC, c => new t.Type<DUPLICATE_USER_EMAIL, DUPLICATE_USER_EMAILTagged>(
  `DUPLICATE_USER_EMAIL`,
  (u: unknown): u is DUPLICATE_USER_EMAIL => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DUPLICATE_USER_EMAIL> => pipe(c.decode(u), E.map(x => ({ ...x, ...DUPLICATE_USER_EMAIL }))),
  (x: DUPLICATE_USER_EMAIL): DUPLICATE_USER_EMAILTagged => ({ ...x, _tag: `DUPLICATE_USER_EMAIL`}),
));
export type DUPLICATE_USER_EMAILC = typeof DUPLICATE_USER_EMAILC;


export const DOES_NOT_MATCH = {
  _tag: `DOES_NOT_MATCH`,
  error: `DOES_NOT_MATCH`
} as const;

export const DOES_NOT_MATCHTaggedC = t.type({
  _tag: t.literal(`DOES_NOT_MATCH`)
});
export type DOES_NOT_MATCHTaggedC = typeof DOES_NOT_MATCHTaggedC;
export type DOES_NOT_MATCHTagged = t.TypeOf<DOES_NOT_MATCHTaggedC>;
export type DOES_NOT_MATCH = DOES_NOT_MATCHTagged & typeof DOES_NOT_MATCH;
export const DOES_NOT_MATCHC = pipe(DOES_NOT_MATCHTaggedC, c => new t.Type<DOES_NOT_MATCH, DOES_NOT_MATCHTagged>(
  `DOES_NOT_MATCH`,
  (u: unknown): u is DOES_NOT_MATCH => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DOES_NOT_MATCH> => pipe(c.decode(u), E.map(x => ({ ...x, ...DOES_NOT_MATCH }))),
  (x: DOES_NOT_MATCH): DOES_NOT_MATCHTagged => ({ ...x, _tag: `DOES_NOT_MATCH`}),
));
export type DOES_NOT_MATCHC = typeof DOES_NOT_MATCHC;


export const EMPTY_FIELD = {
  _tag: `EMPTY_FIELD`,
  error: `EMPTY_FIELD`
} as const;

export const EMPTY_FIELDTaggedC = t.type({
  _tag: t.literal(`EMPTY_FIELD`)
});
export type EMPTY_FIELDTaggedC = typeof EMPTY_FIELDTaggedC;
export type EMPTY_FIELDTagged = t.TypeOf<EMPTY_FIELDTaggedC>;
export type EMPTY_FIELD = EMPTY_FIELDTagged & typeof EMPTY_FIELD;
export const EMPTY_FIELDC = pipe(EMPTY_FIELDTaggedC, c => new t.Type<EMPTY_FIELD, EMPTY_FIELDTagged>(
  `EMPTY_FIELD`,
  (u: unknown): u is EMPTY_FIELD => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EMPTY_FIELD> => pipe(c.decode(u), E.map(x => ({ ...x, ...EMPTY_FIELD }))),
  (x: EMPTY_FIELD): EMPTY_FIELDTagged => ({ ...x, _tag: `EMPTY_FIELD`}),
));
export type EMPTY_FIELDC = typeof EMPTY_FIELDC;


export const FIELD_MAX_LENGTH_VIOLATION = {
  _tag: `FIELD_MAX_LENGTH_VIOLATION`,
  error: `FIELD_MAX_LENGTH_VIOLATION`
} as const;

export const FIELD_MAX_LENGTH_VIOLATIONTaggedC = t.type({
  _tag: t.literal(`FIELD_MAX_LENGTH_VIOLATION`)
});
export type FIELD_MAX_LENGTH_VIOLATIONTaggedC = typeof FIELD_MAX_LENGTH_VIOLATIONTaggedC;
export type FIELD_MAX_LENGTH_VIOLATIONTagged = t.TypeOf<FIELD_MAX_LENGTH_VIOLATIONTaggedC>;
export type FIELD_MAX_LENGTH_VIOLATION = FIELD_MAX_LENGTH_VIOLATIONTagged & typeof FIELD_MAX_LENGTH_VIOLATION;
export const FIELD_MAX_LENGTH_VIOLATIONC = pipe(FIELD_MAX_LENGTH_VIOLATIONTaggedC, c => new t.Type<FIELD_MAX_LENGTH_VIOLATION, FIELD_MAX_LENGTH_VIOLATIONTagged>(
  `FIELD_MAX_LENGTH_VIOLATION`,
  (u: unknown): u is FIELD_MAX_LENGTH_VIOLATION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FIELD_MAX_LENGTH_VIOLATION> => pipe(c.decode(u), E.map(x => ({ ...x, ...FIELD_MAX_LENGTH_VIOLATION }))),
  (x: FIELD_MAX_LENGTH_VIOLATION): FIELD_MAX_LENGTH_VIOLATIONTagged => ({ ...x, _tag: `FIELD_MAX_LENGTH_VIOLATION`}),
));
export type FIELD_MAX_LENGTH_VIOLATIONC = typeof FIELD_MAX_LENGTH_VIOLATIONC;


export const FIELD_MIN_LENGTH_VIOLATION = {
  _tag: `FIELD_MIN_LENGTH_VIOLATION`,
  error: `FIELD_MIN_LENGTH_VIOLATION`
} as const;

export const FIELD_MIN_LENGTH_VIOLATIONTaggedC = t.type({
  _tag: t.literal(`FIELD_MIN_LENGTH_VIOLATION`)
});
export type FIELD_MIN_LENGTH_VIOLATIONTaggedC = typeof FIELD_MIN_LENGTH_VIOLATIONTaggedC;
export type FIELD_MIN_LENGTH_VIOLATIONTagged = t.TypeOf<FIELD_MIN_LENGTH_VIOLATIONTaggedC>;
export type FIELD_MIN_LENGTH_VIOLATION = FIELD_MIN_LENGTH_VIOLATIONTagged & typeof FIELD_MIN_LENGTH_VIOLATION;
export const FIELD_MIN_LENGTH_VIOLATIONC = pipe(FIELD_MIN_LENGTH_VIOLATIONTaggedC, c => new t.Type<FIELD_MIN_LENGTH_VIOLATION, FIELD_MIN_LENGTH_VIOLATIONTagged>(
  `FIELD_MIN_LENGTH_VIOLATION`,
  (u: unknown): u is FIELD_MIN_LENGTH_VIOLATION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FIELD_MIN_LENGTH_VIOLATION> => pipe(c.decode(u), E.map(x => ({ ...x, ...FIELD_MIN_LENGTH_VIOLATION }))),
  (x: FIELD_MIN_LENGTH_VIOLATION): FIELD_MIN_LENGTH_VIOLATIONTagged => ({ ...x, _tag: `FIELD_MIN_LENGTH_VIOLATION`}),
));
export type FIELD_MIN_LENGTH_VIOLATIONC = typeof FIELD_MIN_LENGTH_VIOLATIONC;


export const ID_NOT_FOUND = {
  _tag: `ID_NOT_FOUND`,
  error: `ID_NOT_FOUND`
} as const;

export const ID_NOT_FOUNDTaggedC = t.type({
  _tag: t.literal(`ID_NOT_FOUND`)
});
export type ID_NOT_FOUNDTaggedC = typeof ID_NOT_FOUNDTaggedC;
export type ID_NOT_FOUNDTagged = t.TypeOf<ID_NOT_FOUNDTaggedC>;
export type ID_NOT_FOUND = ID_NOT_FOUNDTagged & typeof ID_NOT_FOUND;
export const ID_NOT_FOUNDC = pipe(ID_NOT_FOUNDTaggedC, c => new t.Type<ID_NOT_FOUND, ID_NOT_FOUNDTagged>(
  `ID_NOT_FOUND`,
  (u: unknown): u is ID_NOT_FOUND => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ID_NOT_FOUND> => pipe(c.decode(u), E.map(x => ({ ...x, ...ID_NOT_FOUND }))),
  (x: ID_NOT_FOUND): ID_NOT_FOUNDTagged => ({ ...x, _tag: `ID_NOT_FOUND`}),
));
export type ID_NOT_FOUNDC = typeof ID_NOT_FOUNDC;


export const INVALID_CUSIP = {
  _tag: `INVALID_CUSIP`,
  error: `INVALID_CUSIP`
} as const;

export const INVALID_CUSIPTaggedC = t.type({
  _tag: t.literal(`INVALID_CUSIP`)
});
export type INVALID_CUSIPTaggedC = typeof INVALID_CUSIPTaggedC;
export type INVALID_CUSIPTagged = t.TypeOf<INVALID_CUSIPTaggedC>;
export type INVALID_CUSIP = INVALID_CUSIPTagged & typeof INVALID_CUSIP;
export const INVALID_CUSIPC = pipe(INVALID_CUSIPTaggedC, c => new t.Type<INVALID_CUSIP, INVALID_CUSIPTagged>(
  `INVALID_CUSIP`,
  (u: unknown): u is INVALID_CUSIP => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_CUSIP> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_CUSIP }))),
  (x: INVALID_CUSIP): INVALID_CUSIPTagged => ({ ...x, _tag: `INVALID_CUSIP`}),
));
export type INVALID_CUSIPC = typeof INVALID_CUSIPC;


export const INVALID_DATE = {
  _tag: `INVALID_DATE`,
  error: `INVALID_DATE`
} as const;

export const INVALID_DATETaggedC = t.type({
  _tag: t.literal(`INVALID_DATE`)
});
export type INVALID_DATETaggedC = typeof INVALID_DATETaggedC;
export type INVALID_DATETagged = t.TypeOf<INVALID_DATETaggedC>;
export type INVALID_DATE = INVALID_DATETagged & typeof INVALID_DATE;
export const INVALID_DATEC = pipe(INVALID_DATETaggedC, c => new t.Type<INVALID_DATE, INVALID_DATETagged>(
  `INVALID_DATE`,
  (u: unknown): u is INVALID_DATE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_DATE> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_DATE }))),
  (x: INVALID_DATE): INVALID_DATETagged => ({ ...x, _tag: `INVALID_DATE`}),
));
export type INVALID_DATEC = typeof INVALID_DATEC;


export const INVALID_PAST_DATE = {
  _tag: `INVALID_PAST_DATE`,
  error: `INVALID_PAST_DATE`
} as const;

export const INVALID_PAST_DATETaggedC = t.type({
  _tag: t.literal(`INVALID_PAST_DATE`)
});
export type INVALID_PAST_DATETaggedC = typeof INVALID_PAST_DATETaggedC;
export type INVALID_PAST_DATETagged = t.TypeOf<INVALID_PAST_DATETaggedC>;
export type INVALID_PAST_DATE = INVALID_PAST_DATETagged & typeof INVALID_PAST_DATE;
export const INVALID_PAST_DATEC = pipe(INVALID_PAST_DATETaggedC, c => new t.Type<INVALID_PAST_DATE, INVALID_PAST_DATETagged>(
  `INVALID_PAST_DATE`,
  (u: unknown): u is INVALID_PAST_DATE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_PAST_DATE> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_PAST_DATE }))),
  (x: INVALID_PAST_DATE): INVALID_PAST_DATETagged => ({ ...x, _tag: `INVALID_PAST_DATE`}),
));
export type INVALID_PAST_DATEC = typeof INVALID_PAST_DATEC;


export const INVALID_DATE_ORDER = {
  _tag: `INVALID_DATE_ORDER`,
  error: `INVALID_DATE_ORDER`
} as const;

export const INVALID_DATE_ORDERTaggedC = t.type({
  _tag: t.literal(`INVALID_DATE_ORDER`)
});
export type INVALID_DATE_ORDERTaggedC = typeof INVALID_DATE_ORDERTaggedC;
export type INVALID_DATE_ORDERTagged = t.TypeOf<INVALID_DATE_ORDERTaggedC>;
export type INVALID_DATE_ORDER = INVALID_DATE_ORDERTagged & typeof INVALID_DATE_ORDER;
export const INVALID_DATE_ORDERC = pipe(INVALID_DATE_ORDERTaggedC, c => new t.Type<INVALID_DATE_ORDER, INVALID_DATE_ORDERTagged>(
  `INVALID_DATE_ORDER`,
  (u: unknown): u is INVALID_DATE_ORDER => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_DATE_ORDER> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_DATE_ORDER }))),
  (x: INVALID_DATE_ORDER): INVALID_DATE_ORDERTagged => ({ ...x, _tag: `INVALID_DATE_ORDER`}),
));
export type INVALID_DATE_ORDERC = typeof INVALID_DATE_ORDERC;


export const INVALID_EMAIL = {
  _tag: `INVALID_EMAIL`,
  error: `INVALID_EMAIL`
} as const;

export const INVALID_EMAILTaggedC = t.type({
  _tag: t.literal(`INVALID_EMAIL`)
});
export type INVALID_EMAILTaggedC = typeof INVALID_EMAILTaggedC;
export type INVALID_EMAILTagged = t.TypeOf<INVALID_EMAILTaggedC>;
export type INVALID_EMAIL = INVALID_EMAILTagged & typeof INVALID_EMAIL;
export const INVALID_EMAILC = pipe(INVALID_EMAILTaggedC, c => new t.Type<INVALID_EMAIL, INVALID_EMAILTagged>(
  `INVALID_EMAIL`,
  (u: unknown): u is INVALID_EMAIL => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_EMAIL> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_EMAIL }))),
  (x: INVALID_EMAIL): INVALID_EMAILTagged => ({ ...x, _tag: `INVALID_EMAIL`}),
));
export type INVALID_EMAILC = typeof INVALID_EMAILC;


export const INVALID_EXTENSION = {
  _tag: `INVALID_EXTENSION`,
  error: `INVALID_EXTENSION`
} as const;

export const INVALID_EXTENSIONTaggedC = t.type({
  _tag: t.literal(`INVALID_EXTENSION`)
});
export type INVALID_EXTENSIONTaggedC = typeof INVALID_EXTENSIONTaggedC;
export type INVALID_EXTENSIONTagged = t.TypeOf<INVALID_EXTENSIONTaggedC>;
export type INVALID_EXTENSION = INVALID_EXTENSIONTagged & typeof INVALID_EXTENSION;
export const INVALID_EXTENSIONC = pipe(INVALID_EXTENSIONTaggedC, c => new t.Type<INVALID_EXTENSION, INVALID_EXTENSIONTagged>(
  `INVALID_EXTENSION`,
  (u: unknown): u is INVALID_EXTENSION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_EXTENSION> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_EXTENSION }))),
  (x: INVALID_EXTENSION): INVALID_EXTENSIONTagged => ({ ...x, _tag: `INVALID_EXTENSION`}),
));
export type INVALID_EXTENSIONC = typeof INVALID_EXTENSIONC;


export const INVALID_HEX_COLOR = {
  _tag: `INVALID_HEX_COLOR`,
  error: `INVALID_HEX_COLOR`
} as const;

export const INVALID_HEX_COLORTaggedC = t.type({
  _tag: t.literal(`INVALID_HEX_COLOR`)
});
export type INVALID_HEX_COLORTaggedC = typeof INVALID_HEX_COLORTaggedC;
export type INVALID_HEX_COLORTagged = t.TypeOf<INVALID_HEX_COLORTaggedC>;
export type INVALID_HEX_COLOR = INVALID_HEX_COLORTagged & typeof INVALID_HEX_COLOR;
export const INVALID_HEX_COLORC = pipe(INVALID_HEX_COLORTaggedC, c => new t.Type<INVALID_HEX_COLOR, INVALID_HEX_COLORTagged>(
  `INVALID_HEX_COLOR`,
  (u: unknown): u is INVALID_HEX_COLOR => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_HEX_COLOR> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_HEX_COLOR }))),
  (x: INVALID_HEX_COLOR): INVALID_HEX_COLORTagged => ({ ...x, _tag: `INVALID_HEX_COLOR`}),
));
export type INVALID_HEX_COLORC = typeof INVALID_HEX_COLORC;


export const INVALID_PASSWORD_REQUIREMENTS = {
  _tag: `INVALID_PASSWORD_REQUIREMENTS`,
  error: `INVALID_PASSWORD_REQUIREMENTS`
} as const;

export const INVALID_PASSWORD_REQUIREMENTSTaggedC = t.type({
  _tag: t.literal(`INVALID_PASSWORD_REQUIREMENTS`)
});
export type INVALID_PASSWORD_REQUIREMENTSTaggedC = typeof INVALID_PASSWORD_REQUIREMENTSTaggedC;
export type INVALID_PASSWORD_REQUIREMENTSTagged = t.TypeOf<INVALID_PASSWORD_REQUIREMENTSTaggedC>;
export type INVALID_PASSWORD_REQUIREMENTS = INVALID_PASSWORD_REQUIREMENTSTagged & typeof INVALID_PASSWORD_REQUIREMENTS;
export const INVALID_PASSWORD_REQUIREMENTSC = pipe(INVALID_PASSWORD_REQUIREMENTSTaggedC, c => new t.Type<INVALID_PASSWORD_REQUIREMENTS, INVALID_PASSWORD_REQUIREMENTSTagged>(
  `INVALID_PASSWORD_REQUIREMENTS`,
  (u: unknown): u is INVALID_PASSWORD_REQUIREMENTS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_PASSWORD_REQUIREMENTS> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_PASSWORD_REQUIREMENTS }))),
  (x: INVALID_PASSWORD_REQUIREMENTS): INVALID_PASSWORD_REQUIREMENTSTagged => ({ ...x, _tag: `INVALID_PASSWORD_REQUIREMENTS`}),
));
export type INVALID_PASSWORD_REQUIREMENTSC = typeof INVALID_PASSWORD_REQUIREMENTSC;


export const INVALID_PHONE_NUMBER = {
  _tag: `INVALID_PHONE_NUMBER`,
  error: `INVALID_PHONE_NUMBER`
} as const;

export const INVALID_PHONE_NUMBERTaggedC = t.type({
  _tag: t.literal(`INVALID_PHONE_NUMBER`)
});
export type INVALID_PHONE_NUMBERTaggedC = typeof INVALID_PHONE_NUMBERTaggedC;
export type INVALID_PHONE_NUMBERTagged = t.TypeOf<INVALID_PHONE_NUMBERTaggedC>;
export type INVALID_PHONE_NUMBER = INVALID_PHONE_NUMBERTagged & typeof INVALID_PHONE_NUMBER;
export const INVALID_PHONE_NUMBERC = pipe(INVALID_PHONE_NUMBERTaggedC, c => new t.Type<INVALID_PHONE_NUMBER, INVALID_PHONE_NUMBERTagged>(
  `INVALID_PHONE_NUMBER`,
  (u: unknown): u is INVALID_PHONE_NUMBER => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_PHONE_NUMBER> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_PHONE_NUMBER }))),
  (x: INVALID_PHONE_NUMBER): INVALID_PHONE_NUMBERTagged => ({ ...x, _tag: `INVALID_PHONE_NUMBER`}),
));
export type INVALID_PHONE_NUMBERC = typeof INVALID_PHONE_NUMBERC;


export const INVALID_SPACE_PREFIX = {
  _tag: `INVALID_SPACE_PREFIX`,
  error: `INVALID_SPACE_PREFIX`
} as const;

export const INVALID_SPACE_PREFIXTaggedC = t.type({
  _tag: t.literal(`INVALID_SPACE_PREFIX`)
});
export type INVALID_SPACE_PREFIXTaggedC = typeof INVALID_SPACE_PREFIXTaggedC;
export type INVALID_SPACE_PREFIXTagged = t.TypeOf<INVALID_SPACE_PREFIXTaggedC>;
export type INVALID_SPACE_PREFIX = INVALID_SPACE_PREFIXTagged & typeof INVALID_SPACE_PREFIX;
export const INVALID_SPACE_PREFIXC = pipe(INVALID_SPACE_PREFIXTaggedC, c => new t.Type<INVALID_SPACE_PREFIX, INVALID_SPACE_PREFIXTagged>(
  `INVALID_SPACE_PREFIX`,
  (u: unknown): u is INVALID_SPACE_PREFIX => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_SPACE_PREFIX> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_SPACE_PREFIX }))),
  (x: INVALID_SPACE_PREFIX): INVALID_SPACE_PREFIXTagged => ({ ...x, _tag: `INVALID_SPACE_PREFIX`}),
));
export type INVALID_SPACE_PREFIXC = typeof INVALID_SPACE_PREFIXC;


export const INVALID_SPACE_SUFFIX = {
  _tag: `INVALID_SPACE_SUFFIX`,
  error: `INVALID_SPACE_SUFFIX`
} as const;

export const INVALID_SPACE_SUFFIXTaggedC = t.type({
  _tag: t.literal(`INVALID_SPACE_SUFFIX`)
});
export type INVALID_SPACE_SUFFIXTaggedC = typeof INVALID_SPACE_SUFFIXTaggedC;
export type INVALID_SPACE_SUFFIXTagged = t.TypeOf<INVALID_SPACE_SUFFIXTaggedC>;
export type INVALID_SPACE_SUFFIX = INVALID_SPACE_SUFFIXTagged & typeof INVALID_SPACE_SUFFIX;
export const INVALID_SPACE_SUFFIXC = pipe(INVALID_SPACE_SUFFIXTaggedC, c => new t.Type<INVALID_SPACE_SUFFIX, INVALID_SPACE_SUFFIXTagged>(
  `INVALID_SPACE_SUFFIX`,
  (u: unknown): u is INVALID_SPACE_SUFFIX => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_SPACE_SUFFIX> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_SPACE_SUFFIX }))),
  (x: INVALID_SPACE_SUFFIX): INVALID_SPACE_SUFFIXTagged => ({ ...x, _tag: `INVALID_SPACE_SUFFIX`}),
));
export type INVALID_SPACE_SUFFIXC = typeof INVALID_SPACE_SUFFIXC;


export const INVALID_URL = {
  _tag: `INVALID_URL`,
  error: `INVALID_URL`
} as const;

export const INVALID_URLTaggedC = t.type({
  _tag: t.literal(`INVALID_URL`)
});
export type INVALID_URLTaggedC = typeof INVALID_URLTaggedC;
export type INVALID_URLTagged = t.TypeOf<INVALID_URLTaggedC>;
export type INVALID_URL = INVALID_URLTagged & typeof INVALID_URL;
export const INVALID_URLC = pipe(INVALID_URLTaggedC, c => new t.Type<INVALID_URL, INVALID_URLTagged>(
  `INVALID_URL`,
  (u: unknown): u is INVALID_URL => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_URL> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_URL }))),
  (x: INVALID_URL): INVALID_URLTagged => ({ ...x, _tag: `INVALID_URL`}),
));
export type INVALID_URLC = typeof INVALID_URLC;


export const URL_NOT_ALLOWED = {
  _tag: `URL_NOT_ALLOWED`,
  error: `URL_NOT_ALLOWED`
} as const;

export const URL_NOT_ALLOWEDTaggedC = t.type({
  _tag: t.literal(`URL_NOT_ALLOWED`)
});
export type URL_NOT_ALLOWEDTaggedC = typeof URL_NOT_ALLOWEDTaggedC;
export type URL_NOT_ALLOWEDTagged = t.TypeOf<URL_NOT_ALLOWEDTaggedC>;
export type URL_NOT_ALLOWED = URL_NOT_ALLOWEDTagged & typeof URL_NOT_ALLOWED;
export const URL_NOT_ALLOWEDC = pipe(URL_NOT_ALLOWEDTaggedC, c => new t.Type<URL_NOT_ALLOWED, URL_NOT_ALLOWEDTagged>(
  `URL_NOT_ALLOWED`,
  (u: unknown): u is URL_NOT_ALLOWED => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, URL_NOT_ALLOWED> => pipe(c.decode(u), E.map(x => ({ ...x, ...URL_NOT_ALLOWED }))),
  (x: URL_NOT_ALLOWED): URL_NOT_ALLOWEDTagged => ({ ...x, _tag: `URL_NOT_ALLOWED`}),
));
export type URL_NOT_ALLOWEDC = typeof URL_NOT_ALLOWEDC;


export const INVALID_VALUE = {
  _tag: `INVALID_VALUE`,
  error: `INVALID_VALUE`
} as const;

export const INVALID_VALUETaggedC = t.type({
  _tag: t.literal(`INVALID_VALUE`)
});
export type INVALID_VALUETaggedC = typeof INVALID_VALUETaggedC;
export type INVALID_VALUETagged = t.TypeOf<INVALID_VALUETaggedC>;
export type INVALID_VALUE = INVALID_VALUETagged & typeof INVALID_VALUE;
export const INVALID_VALUEC = pipe(INVALID_VALUETaggedC, c => new t.Type<INVALID_VALUE, INVALID_VALUETagged>(
  `INVALID_VALUE`,
  (u: unknown): u is INVALID_VALUE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INVALID_VALUE> => pipe(c.decode(u), E.map(x => ({ ...x, ...INVALID_VALUE }))),
  (x: INVALID_VALUE): INVALID_VALUETagged => ({ ...x, _tag: `INVALID_VALUE`}),
));
export type INVALID_VALUEC = typeof INVALID_VALUEC;


export const MISSING_REQUIRED_FIELD = {
  _tag: `MISSING_REQUIRED_FIELD`,
  error: `MISSING_REQUIRED_FIELD`
} as const;

export const MISSING_REQUIRED_FIELDTaggedC = t.type({
  _tag: t.literal(`MISSING_REQUIRED_FIELD`)
});
export type MISSING_REQUIRED_FIELDTaggedC = typeof MISSING_REQUIRED_FIELDTaggedC;
export type MISSING_REQUIRED_FIELDTagged = t.TypeOf<MISSING_REQUIRED_FIELDTaggedC>;
export type MISSING_REQUIRED_FIELD = MISSING_REQUIRED_FIELDTagged & typeof MISSING_REQUIRED_FIELD;
export const MISSING_REQUIRED_FIELDC = pipe(MISSING_REQUIRED_FIELDTaggedC, c => new t.Type<MISSING_REQUIRED_FIELD, MISSING_REQUIRED_FIELDTagged>(
  `MISSING_REQUIRED_FIELD`,
  (u: unknown): u is MISSING_REQUIRED_FIELD => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MISSING_REQUIRED_FIELD> => pipe(c.decode(u), E.map(x => ({ ...x, ...MISSING_REQUIRED_FIELD }))),
  (x: MISSING_REQUIRED_FIELD): MISSING_REQUIRED_FIELDTagged => ({ ...x, _tag: `MISSING_REQUIRED_FIELD`}),
));
export type MISSING_REQUIRED_FIELDC = typeof MISSING_REQUIRED_FIELDC;


export const MALFORMED_INPUT = {
  _tag: `MALFORMED_INPUT`,
  error: `MALFORMED_INPUT`
} as const;

export const MALFORMED_INPUTTaggedC = t.type({
  _tag: t.literal(`MALFORMED_INPUT`)
});
export type MALFORMED_INPUTTaggedC = typeof MALFORMED_INPUTTaggedC;
export type MALFORMED_INPUTTagged = t.TypeOf<MALFORMED_INPUTTaggedC>;
export type MALFORMED_INPUT = MALFORMED_INPUTTagged & typeof MALFORMED_INPUT;
export const MALFORMED_INPUTC = pipe(MALFORMED_INPUTTaggedC, c => new t.Type<MALFORMED_INPUT, MALFORMED_INPUTTagged>(
  `MALFORMED_INPUT`,
  (u: unknown): u is MALFORMED_INPUT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MALFORMED_INPUT> => pipe(c.decode(u), E.map(x => ({ ...x, ...MALFORMED_INPUT }))),
  (x: MALFORMED_INPUT): MALFORMED_INPUTTagged => ({ ...x, _tag: `MALFORMED_INPUT`}),
));
export type MALFORMED_INPUTC = typeof MALFORMED_INPUTC;


export const PASSWORD_EXPIRED = {
  _tag: `PASSWORD_EXPIRED`,
  error: `PASSWORD_EXPIRED`
} as const;

export const PASSWORD_EXPIREDTaggedC = t.type({
  _tag: t.literal(`PASSWORD_EXPIRED`)
});
export type PASSWORD_EXPIREDTaggedC = typeof PASSWORD_EXPIREDTaggedC;
export type PASSWORD_EXPIREDTagged = t.TypeOf<PASSWORD_EXPIREDTaggedC>;
export type PASSWORD_EXPIRED = PASSWORD_EXPIREDTagged & typeof PASSWORD_EXPIRED;
export const PASSWORD_EXPIREDC = pipe(PASSWORD_EXPIREDTaggedC, c => new t.Type<PASSWORD_EXPIRED, PASSWORD_EXPIREDTagged>(
  `PASSWORD_EXPIRED`,
  (u: unknown): u is PASSWORD_EXPIRED => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PASSWORD_EXPIRED> => pipe(c.decode(u), E.map(x => ({ ...x, ...PASSWORD_EXPIRED }))),
  (x: PASSWORD_EXPIRED): PASSWORD_EXPIREDTagged => ({ ...x, _tag: `PASSWORD_EXPIRED`}),
));
export type PASSWORD_EXPIREDC = typeof PASSWORD_EXPIREDC;


export const CUSTOM = {
  _tag: `CUSTOM`,
  error: `CUSTOM`
} as const;

export const CUSTOMTaggedC = t.type({
  _tag: t.literal(`CUSTOM`)
});
export type CUSTOMTaggedC = typeof CUSTOMTaggedC;
export type CUSTOMTagged = t.TypeOf<CUSTOMTaggedC>;
export type CUSTOM = CUSTOMTagged & typeof CUSTOM;
export const CUSTOMC = pipe(CUSTOMTaggedC, c => new t.Type<CUSTOM, CUSTOMTagged>(
  `CUSTOM`,
  (u: unknown): u is CUSTOM => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CUSTOM> => pipe(c.decode(u), E.map(x => ({ ...x, ...CUSTOM }))),
  (x: CUSTOM): CUSTOMTagged => ({ ...x, _tag: `CUSTOM`}),
));
export type CUSTOMC = typeof CUSTOMC;


export const allErrorCodeC = [DUPLICATE_UNIQUE_VALUEC, DUPLICATE_USER_EMAILC, DOES_NOT_MATCHC, EMPTY_FIELDC, FIELD_MAX_LENGTH_VIOLATIONC, FIELD_MIN_LENGTH_VIOLATIONC, ID_NOT_FOUNDC, INVALID_CUSIPC, INVALID_DATEC, INVALID_PAST_DATEC, INVALID_DATE_ORDERC, INVALID_EMAILC, INVALID_EXTENSIONC, INVALID_HEX_COLORC, INVALID_PASSWORD_REQUIREMENTSC, INVALID_PHONE_NUMBERC, INVALID_SPACE_PREFIXC, INVALID_SPACE_SUFFIXC, INVALID_URLC, URL_NOT_ALLOWEDC, INVALID_VALUEC, MISSING_REQUIRED_FIELDC, MALFORMED_INPUTC, PASSWORD_EXPIREDC, CUSTOMC] as const;
export const allErrorCodeNames = [`DUPLICATE_UNIQUE_VALUE`, `DUPLICATE_USER_EMAIL`, `DOES_NOT_MATCH`, `EMPTY_FIELD`, `FIELD_MAX_LENGTH_VIOLATION`, `FIELD_MIN_LENGTH_VIOLATION`, `ID_NOT_FOUND`, `INVALID_CUSIP`, `INVALID_DATE`, `INVALID_PAST_DATE`, `INVALID_DATE_ORDER`, `INVALID_EMAIL`, `INVALID_EXTENSION`, `INVALID_HEX_COLOR`, `INVALID_PASSWORD_REQUIREMENTS`, `INVALID_PHONE_NUMBER`, `INVALID_SPACE_PREFIX`, `INVALID_SPACE_SUFFIX`, `INVALID_URL`, `URL_NOT_ALLOWED`, `INVALID_VALUE`, `MISSING_REQUIRED_FIELD`, `MALFORMED_INPUT`, `PASSWORD_EXPIRED`, `CUSTOM`] as const;
export type ErrorCodeName = (typeof allErrorCodeNames)[number];

export const ErrorCodeCU = t.union([DUPLICATE_UNIQUE_VALUEC, DUPLICATE_USER_EMAILC, DOES_NOT_MATCHC, EMPTY_FIELDC, FIELD_MAX_LENGTH_VIOLATIONC, FIELD_MIN_LENGTH_VIOLATIONC, ID_NOT_FOUNDC, INVALID_CUSIPC, INVALID_DATEC, INVALID_PAST_DATEC, INVALID_DATE_ORDERC, INVALID_EMAILC, INVALID_EXTENSIONC, INVALID_HEX_COLORC, INVALID_PASSWORD_REQUIREMENTSC, INVALID_PHONE_NUMBERC, INVALID_SPACE_PREFIXC, INVALID_SPACE_SUFFIXC, INVALID_URLC, URL_NOT_ALLOWEDC, INVALID_VALUEC, MISSING_REQUIRED_FIELDC, MALFORMED_INPUTC, PASSWORD_EXPIREDC, CUSTOMC]);
export type ErrorCodeCU = typeof ErrorCodeCU;
export type ErrorCodeU = t.TypeOf<ErrorCodeCU>;

export const errorCodeOrd: Ord.Ord<ErrorCodeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allErrorCode = [DUPLICATE_UNIQUE_VALUE, DUPLICATE_USER_EMAIL, DOES_NOT_MATCH, EMPTY_FIELD, FIELD_MAX_LENGTH_VIOLATION, FIELD_MIN_LENGTH_VIOLATION, ID_NOT_FOUND, INVALID_CUSIP, INVALID_DATE, INVALID_PAST_DATE, INVALID_DATE_ORDER, INVALID_EMAIL, INVALID_EXTENSION, INVALID_HEX_COLOR, INVALID_PASSWORD_REQUIREMENTS, INVALID_PHONE_NUMBER, INVALID_SPACE_PREFIX, INVALID_SPACE_SUFFIX, INVALID_URL, URL_NOT_ALLOWED, INVALID_VALUE, MISSING_REQUIRED_FIELD, MALFORMED_INPUT, PASSWORD_EXPIRED, CUSTOM] as const;
export type ErrorCodeMap<A> = { [K in ErrorCodeName]: A };


