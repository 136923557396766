import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const daily = {
  _tag: `daily`
} as const;

export const dailyTaggedC = t.type({
  _tag: t.literal(`daily`)
});
export type DailyTaggedC = typeof dailyTaggedC;
export type DailyTagged = t.TypeOf<DailyTaggedC>;
export type Daily = DailyTagged & typeof daily;
export const dailyC = pipe(dailyTaggedC, c => new t.Type<Daily, DailyTagged>(
  `daily`,
  (u: unknown): u is Daily => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Daily> => pipe(c.decode(u), E.map(x => ({ ...x, ...daily }))),
  (x: Daily): DailyTagged => ({ ...x, _tag: `daily`}),
));
export type DailyC = typeof dailyC;


export const weekly = {
  _tag: `weekly`
} as const;

export const weeklyTaggedC = t.type({
  _tag: t.literal(`weekly`)
});
export type WeeklyTaggedC = typeof weeklyTaggedC;
export type WeeklyTagged = t.TypeOf<WeeklyTaggedC>;
export type Weekly = WeeklyTagged & typeof weekly;
export const weeklyC = pipe(weeklyTaggedC, c => new t.Type<Weekly, WeeklyTagged>(
  `weekly`,
  (u: unknown): u is Weekly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Weekly> => pipe(c.decode(u), E.map(x => ({ ...x, ...weekly }))),
  (x: Weekly): WeeklyTagged => ({ ...x, _tag: `weekly`}),
));
export type WeeklyC = typeof weeklyC;


export const monthly = {
  _tag: `monthly`
} as const;

export const monthlyTaggedC = t.type({
  _tag: t.literal(`monthly`)
});
export type MonthlyTaggedC = typeof monthlyTaggedC;
export type MonthlyTagged = t.TypeOf<MonthlyTaggedC>;
export type Monthly = MonthlyTagged & typeof monthly;
export const monthlyC = pipe(monthlyTaggedC, c => new t.Type<Monthly, MonthlyTagged>(
  `monthly`,
  (u: unknown): u is Monthly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Monthly> => pipe(c.decode(u), E.map(x => ({ ...x, ...monthly }))),
  (x: Monthly): MonthlyTagged => ({ ...x, _tag: `monthly`}),
));
export type MonthlyC = typeof monthlyC;


export const allAnalyticsIntervalC = [dailyC, weeklyC, monthlyC] as const;
export const allAnalyticsIntervalNames = [`daily`, `weekly`, `monthly`] as const;
export type AnalyticsIntervalName = (typeof allAnalyticsIntervalNames)[number];

export const AnalyticsIntervalCU = t.union([dailyC, weeklyC, monthlyC]);
export type AnalyticsIntervalCU = typeof AnalyticsIntervalCU;
export type AnalyticsIntervalU = t.TypeOf<AnalyticsIntervalCU>;

export const analyticsIntervalOrd: Ord.Ord<AnalyticsIntervalU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allAnalyticsInterval = [daily, weekly, monthly] as const;
export type AnalyticsIntervalMap<A> = { [K in AnalyticsIntervalName]: A };


