import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const sub = {
  _tag: `Sub`,
  name: `Sub`
} as const;

export const subTaggedC = t.type({
  _tag: t.literal(`Sub`)
});
export type SubTaggedC = typeof subTaggedC;
export type SubTagged = t.TypeOf<SubTaggedC>;
export type Sub = SubTagged & typeof sub;
export const subC = pipe(subTaggedC, c => new t.Type<Sub, SubTagged>(
  `Sub`,
  (u: unknown): u is Sub => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Sub> => pipe(c.decode(u), E.map(x => ({ ...x, ...sub }))),
  (x: Sub): SubTagged => ({ ...x, _tag: `Sub`}),
));
export type SubC = typeof subC;


export const unsub = {
  _tag: `Unsub`,
  name: `Unsub`
} as const;

export const unsubTaggedC = t.type({
  _tag: t.literal(`Unsub`)
});
export type UnsubTaggedC = typeof unsubTaggedC;
export type UnsubTagged = t.TypeOf<UnsubTaggedC>;
export type Unsub = UnsubTagged & typeof unsub;
export const unsubC = pipe(unsubTaggedC, c => new t.Type<Unsub, UnsubTagged>(
  `Unsub`,
  (u: unknown): u is Unsub => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Unsub> => pipe(c.decode(u), E.map(x => ({ ...x, ...unsub }))),
  (x: Unsub): UnsubTagged => ({ ...x, _tag: `Unsub`}),
));
export type UnsubC = typeof unsubC;


export const allSubscriptionChangeC = [subC, unsubC] as const;
export const allSubscriptionChangeNames = [`Sub`, `Unsub`] as const;
export type SubscriptionChangeName = (typeof allSubscriptionChangeNames)[number];

export const SubscriptionChangeCU = t.union([subC, unsubC]);
export type SubscriptionChangeCU = typeof SubscriptionChangeCU;
export type SubscriptionChangeU = t.TypeOf<SubscriptionChangeCU>;

export const subscriptionChangeOrd: Ord.Ord<SubscriptionChangeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSubscriptionChange = [sub, unsub] as const;
export type SubscriptionChangeMap<A> = { [K in SubscriptionChangeName]: A };


