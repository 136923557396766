import { lit, Match, query, end } from "fp-ts-routing/lib";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as t from "io-ts";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";


export const dashboard = {
  _tag: `Dashboard`
} as const;

export const dashboardTaggedC = t.type({
  _tag: t.literal(`Dashboard`)
});
export type DashboardTaggedC = typeof dashboardTaggedC;
export type DashboardTagged = t.TypeOf<DashboardTaggedC>;
export type Dashboard = DashboardTagged & typeof dashboard;
export const dashboardC = pipe(dashboardTaggedC, c => new t.Type<Dashboard, DashboardTagged>(
  `Dashboard`,
  (u: unknown): u is Dashboard => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Dashboard> => pipe(c.decode(u), E.map(x => ({ ...x, ...dashboard }))),
  (x: Dashboard): DashboardTagged => ({ ...x, _tag: `Dashboard`}),
));
export type DashboardC = typeof dashboardC;


export const issuerFeed = {
  _tag: `IssuerFeed`
} as const;

export const issuerFeedTaggedC = t.type({
  _tag: t.literal(`IssuerFeed`)
});
export type IssuerFeedTaggedC = typeof issuerFeedTaggedC;
export type IssuerFeedTagged = t.TypeOf<IssuerFeedTaggedC>;
export type IssuerFeed = IssuerFeedTagged & typeof issuerFeed;
export const issuerFeedC = pipe(issuerFeedTaggedC, c => new t.Type<IssuerFeed, IssuerFeedTagged>(
  `IssuerFeed`,
  (u: unknown): u is IssuerFeed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerFeed> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerFeed }))),
  (x: IssuerFeed): IssuerFeedTagged => ({ ...x, _tag: `IssuerFeed`}),
));
export type IssuerFeedC = typeof issuerFeedC;


export const bondSaleCalendar = {
  _tag: `BondSaleCalendar`
} as const;

export const bondSaleCalendarTaggedC = t.type({
  _tag: t.literal(`BondSaleCalendar`)
});
export type BondSaleCalendarTaggedC = typeof bondSaleCalendarTaggedC;
export type BondSaleCalendarTagged = t.TypeOf<BondSaleCalendarTaggedC>;
export type BondSaleCalendar = BondSaleCalendarTagged & typeof bondSaleCalendar;
export const bondSaleCalendarC = pipe(bondSaleCalendarTaggedC, c => new t.Type<BondSaleCalendar, BondSaleCalendarTagged>(
  `BondSaleCalendar`,
  (u: unknown): u is BondSaleCalendar => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSaleCalendar> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSaleCalendar }))),
  (x: BondSaleCalendar): BondSaleCalendarTagged => ({ ...x, _tag: `BondSaleCalendar`}),
));
export type BondSaleCalendarC = typeof bondSaleCalendarC;


export const watchlistBonds = {
  _tag: `WatchlistBonds`
} as const;

export const watchlistBondsTaggedC = t.type({
  _tag: t.literal(`WatchlistBonds`)
});
export type WatchlistBondsTaggedC = typeof watchlistBondsTaggedC;
export type WatchlistBondsTagged = t.TypeOf<WatchlistBondsTaggedC>;
export type WatchlistBonds = WatchlistBondsTagged & typeof watchlistBonds;
export const watchlistBondsC = pipe(watchlistBondsTaggedC, c => new t.Type<WatchlistBonds, WatchlistBondsTagged>(
  `WatchlistBonds`,
  (u: unknown): u is WatchlistBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistBonds }))),
  (x: WatchlistBonds): WatchlistBondsTagged => ({ ...x, _tag: `WatchlistBonds`}),
));
export type WatchlistBondsC = typeof watchlistBondsC;


export const watchlistRfps = {
  _tag: `WatchlistRfps`
} as const;

export const watchlistRfpsTaggedC = t.type({
  _tag: t.literal(`WatchlistRfps`)
});
export type WatchlistRfpsTaggedC = typeof watchlistRfpsTaggedC;
export type WatchlistRfpsTagged = t.TypeOf<WatchlistRfpsTaggedC>;
export type WatchlistRfps = WatchlistRfpsTagged & typeof watchlistRfps;
export const watchlistRfpsC = pipe(watchlistRfpsTaggedC, c => new t.Type<WatchlistRfps, WatchlistRfpsTagged>(
  `WatchlistRfps`,
  (u: unknown): u is WatchlistRfps => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistRfps> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistRfps }))),
  (x: WatchlistRfps): WatchlistRfpsTagged => ({ ...x, _tag: `WatchlistRfps`}),
));
export type WatchlistRfpsC = typeof watchlistRfpsC;


export const watchlistIssuers = {
  _tag: `WatchlistIssuers`
} as const;

export const watchlistIssuersTaggedC = t.type({
  _tag: t.literal(`WatchlistIssuers`)
});
export type WatchlistIssuersTaggedC = typeof watchlistIssuersTaggedC;
export type WatchlistIssuersTagged = t.TypeOf<WatchlistIssuersTaggedC>;
export type WatchlistIssuers = WatchlistIssuersTagged & typeof watchlistIssuers;
export const watchlistIssuersC = pipe(watchlistIssuersTaggedC, c => new t.Type<WatchlistIssuers, WatchlistIssuersTagged>(
  `WatchlistIssuers`,
  (u: unknown): u is WatchlistIssuers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistIssuers> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistIssuers }))),
  (x: WatchlistIssuers): WatchlistIssuersTagged => ({ ...x, _tag: `WatchlistIssuers`}),
));
export type WatchlistIssuersC = typeof watchlistIssuersC;


export const watchlistSectors = {
  _tag: `WatchlistSectors`
} as const;

export const watchlistSectorsTaggedC = t.type({
  _tag: t.literal(`WatchlistSectors`)
});
export type WatchlistSectorsTaggedC = typeof watchlistSectorsTaggedC;
export type WatchlistSectorsTagged = t.TypeOf<WatchlistSectorsTaggedC>;
export type WatchlistSectors = WatchlistSectorsTagged & typeof watchlistSectors;
export const watchlistSectorsC = pipe(watchlistSectorsTaggedC, c => new t.Type<WatchlistSectors, WatchlistSectorsTagged>(
  `WatchlistSectors`,
  (u: unknown): u is WatchlistSectors => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistSectors> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistSectors }))),
  (x: WatchlistSectors): WatchlistSectorsTagged => ({ ...x, _tag: `WatchlistSectors`}),
));
export type WatchlistSectorsC = typeof watchlistSectorsC;


export const watchlistStates = {
  _tag: `WatchlistStates`
} as const;

export const watchlistStatesTaggedC = t.type({
  _tag: t.literal(`WatchlistStates`)
});
export type WatchlistStatesTaggedC = typeof watchlistStatesTaggedC;
export type WatchlistStatesTagged = t.TypeOf<WatchlistStatesTaggedC>;
export type WatchlistStates = WatchlistStatesTagged & typeof watchlistStates;
export const watchlistStatesC = pipe(watchlistStatesTaggedC, c => new t.Type<WatchlistStates, WatchlistStatesTagged>(
  `WatchlistStates`,
  (u: unknown): u is WatchlistStates => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WatchlistStates> => pipe(c.decode(u), E.map(x => ({ ...x, ...watchlistStates }))),
  (x: WatchlistStates): WatchlistStatesTagged => ({ ...x, _tag: `WatchlistStates`}),
));
export type WatchlistStatesC = typeof watchlistStatesC;


export const allInvestorPortalPageC = [dashboardC, issuerFeedC, bondSaleCalendarC, watchlistBondsC, watchlistRfpsC, watchlistIssuersC, watchlistSectorsC, watchlistStatesC] as const;
export const allInvestorPortalPageNames = [`Dashboard`, `IssuerFeed`, `BondSaleCalendar`, `WatchlistBonds`, `WatchlistRfps`, `WatchlistIssuers`, `WatchlistSectors`, `WatchlistStates`] as const;
export type InvestorPortalPageName = (typeof allInvestorPortalPageNames)[number];

export const InvestorPortalPageCU = t.union([dashboardC, issuerFeedC, bondSaleCalendarC, watchlistBondsC, watchlistRfpsC, watchlistIssuersC, watchlistSectorsC, watchlistStatesC]);
export type InvestorPortalPageCU = typeof InvestorPortalPageCU;
export type InvestorPortalPageU = t.TypeOf<InvestorPortalPageCU>;

export const investorPortalPageOrd: Ord.Ord<InvestorPortalPageU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInvestorPortalPage = [dashboard, issuerFeed, bondSaleCalendar, watchlistBonds, watchlistRfps, watchlistIssuers, watchlistSectors, watchlistStates] as const;
export type InvestorPortalPageMap<A> = { [K in InvestorPortalPageName]: A };





const bondSaleCalendarPath = lit("investor-portal").then(lit("bond-sale-calendar"));
const bondSaleCalendarPathParts = ["bond-sale-calendar"] as const;
const bondSaleCalendarQuery = t.strict({});
export type BondSaleCalendarParams = Describe<typeof bondSaleCalendarPath._A & typeof bondSaleCalendarQuery._A>;

export function bondSaleCalendarRoute(): { match: Match<BondSaleCalendarParams>; pathParts: typeof bondSaleCalendarPathParts };
export function bondSaleCalendarRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, BondSaleCalendarParams>): { match: Match<BondSaleCalendarParams & t.TypeOf<A>>; pathParts: typeof bondSaleCalendarPathParts };
export function bondSaleCalendarRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, BondSaleCalendarParams>) {
  return {
    match: (q ? bondSaleCalendarPath.then(query(t.intersection([bondSaleCalendarQuery, q]))) : bondSaleCalendarPath.then(query(bondSaleCalendarQuery))).then(end),
    pathParts: bondSaleCalendarPathParts,
  };
}

const dashboardPath = lit("investor-portal").then(lit("dashboard"));
const dashboardPathParts = ["dashboard"] as const;
const dashboardQuery = t.strict({});
export type DashboardParams = Describe<typeof dashboardPath._A & typeof dashboardQuery._A>;

export function dashboardRoute(): { match: Match<DashboardParams>; pathParts: typeof dashboardPathParts };
export function dashboardRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, DashboardParams>): { match: Match<DashboardParams & t.TypeOf<A>>; pathParts: typeof dashboardPathParts };
export function dashboardRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, DashboardParams>) {
  return {
    match: (q ? dashboardPath.then(query(t.intersection([dashboardQuery, q]))) : dashboardPath.then(query(dashboardQuery))).then(end),
    pathParts: dashboardPathParts,
  };
}

const issuerFeedPath = lit("investor-portal").then(lit("issuer-feed"));
const issuerFeedPathParts = ["issuer-feed"] as const;
const issuerFeedQuery = t.strict({});
export type IssuerFeedParams = Describe<typeof issuerFeedPath._A & typeof issuerFeedQuery._A>;

export function issuerFeedRoute(): { match: Match<IssuerFeedParams>; pathParts: typeof issuerFeedPathParts };
export function issuerFeedRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, IssuerFeedParams>): { match: Match<IssuerFeedParams & t.TypeOf<A>>; pathParts: typeof issuerFeedPathParts };
export function issuerFeedRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, IssuerFeedParams>) {
  return {
    match: (q ? issuerFeedPath.then(query(t.intersection([issuerFeedQuery, q]))) : issuerFeedPath.then(query(issuerFeedQuery))).then(end),
    pathParts: issuerFeedPathParts,
  };
}

const watchlistBondsPath = lit("investor-portal").then(lit("watchlist")).then(lit("bonds"));
const watchlistBondsPathParts = ["watchlist", "bonds"] as const;
const watchlistBondsQuery = t.strict({});
export type WatchlistBondsParams = Describe<typeof watchlistBondsPath._A & typeof watchlistBondsQuery._A>;

export function watchlistBondsRoute(): { match: Match<WatchlistBondsParams>; pathParts: typeof watchlistBondsPathParts };
export function watchlistBondsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistBondsParams>): { match: Match<WatchlistBondsParams & t.TypeOf<A>>; pathParts: typeof watchlistBondsPathParts };
export function watchlistBondsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistBondsParams>) {
  return {
    match: (q ? watchlistBondsPath.then(query(t.intersection([watchlistBondsQuery, q]))) : watchlistBondsPath.then(query(watchlistBondsQuery))).then(end),
    pathParts: watchlistBondsPathParts,
  };
}

const watchlistIssuersPath = lit("investor-portal").then(lit("watchlist")).then(lit("issuers"));
const watchlistIssuersPathParts = ["watchlist", "issuers"] as const;
const watchlistIssuersQuery = t.strict({});
export type WatchlistIssuersParams = Describe<typeof watchlistIssuersPath._A & typeof watchlistIssuersQuery._A>;

export function watchlistIssuersRoute(): { match: Match<WatchlistIssuersParams>; pathParts: typeof watchlistIssuersPathParts };
export function watchlistIssuersRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistIssuersParams>): { match: Match<WatchlistIssuersParams & t.TypeOf<A>>; pathParts: typeof watchlistIssuersPathParts };
export function watchlistIssuersRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistIssuersParams>) {
  return {
    match: (q ? watchlistIssuersPath.then(query(t.intersection([watchlistIssuersQuery, q]))) : watchlistIssuersPath.then(query(watchlistIssuersQuery))).then(end),
    pathParts: watchlistIssuersPathParts,
  };
}

const watchlistRfpsPath = lit("investor-portal").then(lit("watchlist")).then(lit("rfps"));
const watchlistRfpsPathParts = ["watchlist", "rfps"] as const;
const watchlistRfpsQuery = t.strict({});
export type WatchlistRfpsParams = Describe<typeof watchlistRfpsPath._A & typeof watchlistRfpsQuery._A>;

export function watchlistRfpsRoute(): { match: Match<WatchlistRfpsParams>; pathParts: typeof watchlistRfpsPathParts };
export function watchlistRfpsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistRfpsParams>): { match: Match<WatchlistRfpsParams & t.TypeOf<A>>; pathParts: typeof watchlistRfpsPathParts };
export function watchlistRfpsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistRfpsParams>) {
  return {
    match: (q ? watchlistRfpsPath.then(query(t.intersection([watchlistRfpsQuery, q]))) : watchlistRfpsPath.then(query(watchlistRfpsQuery))).then(end),
    pathParts: watchlistRfpsPathParts,
  };
}

const watchlistSectorsPath = lit("investor-portal").then(lit("watchlist")).then(lit("sectors"));
const watchlistSectorsPathParts = ["watchlist", "sectors"] as const;
const watchlistSectorsQuery = t.strict({});
export type WatchlistSectorsParams = Describe<typeof watchlistSectorsPath._A & typeof watchlistSectorsQuery._A>;

export function watchlistSectorsRoute(): { match: Match<WatchlistSectorsParams>; pathParts: typeof watchlistSectorsPathParts };
export function watchlistSectorsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistSectorsParams>): { match: Match<WatchlistSectorsParams & t.TypeOf<A>>; pathParts: typeof watchlistSectorsPathParts };
export function watchlistSectorsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistSectorsParams>) {
  return {
    match: (q ? watchlistSectorsPath.then(query(t.intersection([watchlistSectorsQuery, q]))) : watchlistSectorsPath.then(query(watchlistSectorsQuery))).then(end),
    pathParts: watchlistSectorsPathParts,
  };
}

const watchlistStatesPath = lit("investor-portal").then(lit("watchlist")).then(lit("states"));
const watchlistStatesPathParts = ["watchlist", "states"] as const;
const watchlistStatesQuery = t.strict({});
export type WatchlistStatesParams = Describe<typeof watchlistStatesPath._A & typeof watchlistStatesQuery._A>;

export function watchlistStatesRoute(): { match: Match<WatchlistStatesParams>; pathParts: typeof watchlistStatesPathParts };
export function watchlistStatesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, WatchlistStatesParams>): { match: Match<WatchlistStatesParams & t.TypeOf<A>>; pathParts: typeof watchlistStatesPathParts };
export function watchlistStatesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, WatchlistStatesParams>) {
  return {
    match: (q ? watchlistStatesPath.then(query(t.intersection([watchlistStatesQuery, q]))) : watchlistStatesPath.then(query(watchlistStatesQuery))).then(end),
    pathParts: watchlistStatesPathParts,
  };
}

export const allRoutes = [bondSaleCalendarRoute, dashboardRoute, issuerFeedRoute, watchlistBondsRoute, watchlistIssuersRoute, watchlistRfpsRoute, watchlistSectorsRoute, watchlistStatesRoute] as const;