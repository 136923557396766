import { Route, query, lit, end, Match } from "fp-ts-routing/lib";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

import { NoKeyOverlapC } from "../../codecs/noKeyOverlap";
import { Describe } from "../../fp-ts/lib/types/describe";
import { NullableToOptionalFields, optionalFieldsToNullable } from "../../fp-ts/Record";
import { urlInterfaceIO, urlInterface } from "../../routes/urlInterface";
import { corpDemoPostC as imported0_corpDemoPostC, corpContactPostC as imported0_corpContactPostC, corpEmailSignupPostC as imported0_corpEmailSignupPostC } from "../models/corp";
import { emptyObjectC } from "../models/emptyObject";
import { issuerSearchRequestPostC as imported0_issuerSearchRequestPostC } from "../models/issuerSearch";

const corporateDemoRequestPath = lit("corporate").then(lit("demo")).then(lit("request"));
const corporateDemoRequestPathParts = ["corporate", "demo", "request"] as const;
const corporateDemoRequestQuery = t.strict({});
export type CorporateDemoRequestParams = Describe<typeof corporateDemoRequestPath._A & typeof corporateDemoRequestQuery._A>;

export function corporateDemoRequestRoute(): { match: Match<CorporateDemoRequestParams>; pathParts: typeof corporateDemoRequestPathParts };
export function corporateDemoRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateDemoRequestParams>): { match: Match<CorporateDemoRequestParams & t.TypeOf<A>>; pathParts: typeof corporateDemoRequestPathParts };
export function corporateDemoRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateDemoRequestParams>) {
  return {
    match: (q ? corporateDemoRequestPath.then(query(t.intersection([corporateDemoRequestQuery, q]))) : corporateDemoRequestPath.then(query(corporateDemoRequestQuery))).then(end),
    pathParts: corporateDemoRequestPathParts,
  };
}

export const corporateDemoRequestCodecs = { input: imported0_corpDemoPostC, output: emptyObjectC, };

export const corporateDemoRequest = () =>
  urlInterfaceIO("POST", corporateDemoRequestRoute().match.formatter.run(Route.empty, {}).toString(true), corporateDemoRequestCodecs);

const corporateContactRequestPath = lit("corporate").then(lit("contact")).then(lit("request"));
const corporateContactRequestPathParts = ["corporate", "contact", "request"] as const;
const corporateContactRequestQuery = t.strict({});
export type CorporateContactRequestParams = Describe<typeof corporateContactRequestPath._A & typeof corporateContactRequestQuery._A>;

export function corporateContactRequestRoute(): { match: Match<CorporateContactRequestParams>; pathParts: typeof corporateContactRequestPathParts };
export function corporateContactRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateContactRequestParams>): { match: Match<CorporateContactRequestParams & t.TypeOf<A>>; pathParts: typeof corporateContactRequestPathParts };
export function corporateContactRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateContactRequestParams>) {
  return {
    match: (q ? corporateContactRequestPath.then(query(t.intersection([corporateContactRequestQuery, q]))) : corporateContactRequestPath.then(query(corporateContactRequestQuery))).then(end),
    pathParts: corporateContactRequestPathParts,
  };
}

export const corporateContactRequestCodecs = { input: imported0_corpContactPostC, output: emptyObjectC, };

export const corporateContactRequest = () =>
  urlInterfaceIO("POST", corporateContactRequestRoute().match.formatter.run(Route.empty, {}).toString(true), corporateContactRequestCodecs);

const corporateIssuerRequestPath = lit("corporate").then(lit("search")).then(lit("issuer")).then(lit("request"));
const corporateIssuerRequestPathParts = ["corporate", "search", "issuer", "request"] as const;
const corporateIssuerRequestQuery = t.strict({});
export type CorporateIssuerRequestParams = Describe<typeof corporateIssuerRequestPath._A & typeof corporateIssuerRequestQuery._A>;

export function corporateIssuerRequestRoute(): { match: Match<CorporateIssuerRequestParams>; pathParts: typeof corporateIssuerRequestPathParts };
export function corporateIssuerRequestRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateIssuerRequestParams>): { match: Match<CorporateIssuerRequestParams & t.TypeOf<A>>; pathParts: typeof corporateIssuerRequestPathParts };
export function corporateIssuerRequestRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateIssuerRequestParams>) {
  return {
    match: (q ? corporateIssuerRequestPath.then(query(t.intersection([corporateIssuerRequestQuery, q]))) : corporateIssuerRequestPath.then(query(corporateIssuerRequestQuery))).then(end),
    pathParts: corporateIssuerRequestPathParts,
  };
}

export const corporateIssuerRequestCodecs = { input: imported0_issuerSearchRequestPostC, output: emptyObjectC, };

export const corporateIssuerRequest = () =>
  urlInterfaceIO("POST", corporateIssuerRequestRoute().match.formatter.run(Route.empty, {}).toString(true), corporateIssuerRequestCodecs);

const corporateEmailSignupPath = lit("corporate").then(lit("hs-subscribe"));
const corporateEmailSignupPathParts = ["corporate", "hs-subscribe"] as const;
const corporateEmailSignupQuery = t.strict({});
export type CorporateEmailSignupParams = Describe<typeof corporateEmailSignupPath._A & typeof corporateEmailSignupQuery._A>;

export function corporateEmailSignupRoute(): { match: Match<CorporateEmailSignupParams>; pathParts: typeof corporateEmailSignupPathParts };
export function corporateEmailSignupRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateEmailSignupParams>): { match: Match<CorporateEmailSignupParams & t.TypeOf<A>>; pathParts: typeof corporateEmailSignupPathParts };
export function corporateEmailSignupRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateEmailSignupParams>) {
  return {
    match: (q ? corporateEmailSignupPath.then(query(t.intersection([corporateEmailSignupQuery, q]))) : corporateEmailSignupPath.then(query(corporateEmailSignupQuery))).then(end),
    pathParts: corporateEmailSignupPathParts,
  };
}

export const corporateEmailSignupCodecs = { input: imported0_corpEmailSignupPostC, output: emptyObjectC, };

export const corporateEmailSignup = () =>
  urlInterfaceIO("POST", corporateEmailSignupRoute().match.formatter.run(Route.empty, {}).toString(true), corporateEmailSignupCodecs);


const corporateStaticIndexPathParts = [] as const;
const corporateStaticIndexQuery = t.strict({});
export type CorporateStaticIndexParams = Describe<typeof corporateStaticIndexQuery._A>;

export function corporateStaticIndexRoute(): { match: Match<CorporateStaticIndexParams>; pathParts: typeof corporateStaticIndexPathParts };
export function corporateStaticIndexRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticIndexParams>): { match: Match<CorporateStaticIndexParams & t.TypeOf<A>>; pathParts: typeof corporateStaticIndexPathParts };
export function corporateStaticIndexRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticIndexParams>) {
  return {
    match: (q ? query(t.intersection([corporateStaticIndexQuery, q])) : query(corporateStaticIndexQuery)).then(end),
    pathParts: corporateStaticIndexPathParts,
  };
}

export const corporateStaticIndex = () =>
  urlInterface("GET", corporateStaticIndexRoute().match.formatter.run(Route.empty, {}).toString(true));

const corporateStaticTermsPath = lit("terms");
const corporateStaticTermsPathParts = ["terms"] as const;
const corporateStaticTermsQuery = t.strict({});
export type CorporateStaticTermsParams = Describe<typeof corporateStaticTermsPath._A & typeof corporateStaticTermsQuery._A>;

export function corporateStaticTermsRoute(): { match: Match<CorporateStaticTermsParams>; pathParts: typeof corporateStaticTermsPathParts };
export function corporateStaticTermsRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticTermsParams>): { match: Match<CorporateStaticTermsParams & t.TypeOf<A>>; pathParts: typeof corporateStaticTermsPathParts };
export function corporateStaticTermsRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticTermsParams>) {
  return {
    match: (q ? corporateStaticTermsPath.then(query(t.intersection([corporateStaticTermsQuery, q]))) : corporateStaticTermsPath.then(query(corporateStaticTermsQuery))).then(end),
    pathParts: corporateStaticTermsPathParts,
  };
}

export const corporateStaticTerms = () =>
  urlInterface("GET", corporateStaticTermsRoute().match.formatter.run(Route.empty, {}).toString(true));

const corporateStaticPrivacyPath = lit("privacy");
const corporateStaticPrivacyPathParts = ["privacy"] as const;
const corporateStaticPrivacyQuery = t.strict({});
export type CorporateStaticPrivacyParams = Describe<typeof corporateStaticPrivacyPath._A & typeof corporateStaticPrivacyQuery._A>;

export function corporateStaticPrivacyRoute(): { match: Match<CorporateStaticPrivacyParams>; pathParts: typeof corporateStaticPrivacyPathParts };
export function corporateStaticPrivacyRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticPrivacyParams>): { match: Match<CorporateStaticPrivacyParams & t.TypeOf<A>>; pathParts: typeof corporateStaticPrivacyPathParts };
export function corporateStaticPrivacyRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticPrivacyParams>) {
  return {
    match: (q ? corporateStaticPrivacyPath.then(query(t.intersection([corporateStaticPrivacyQuery, q]))) : corporateStaticPrivacyPath.then(query(corporateStaticPrivacyQuery))).then(end),
    pathParts: corporateStaticPrivacyPathParts,
  };
}

export const corporateStaticPrivacy = () =>
  urlInterface("GET", corporateStaticPrivacyRoute().match.formatter.run(Route.empty, {}).toString(true));

const corporateStaticDisclaimerPath = lit("disclaimer");
const corporateStaticDisclaimerPathParts = ["disclaimer"] as const;
const corporateStaticDisclaimerQuery = t.strict({});
export type CorporateStaticDisclaimerParams = Describe<typeof corporateStaticDisclaimerPath._A & typeof corporateStaticDisclaimerQuery._A>;

export function corporateStaticDisclaimerRoute(): { match: Match<CorporateStaticDisclaimerParams>; pathParts: typeof corporateStaticDisclaimerPathParts };
export function corporateStaticDisclaimerRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticDisclaimerParams>): { match: Match<CorporateStaticDisclaimerParams & t.TypeOf<A>>; pathParts: typeof corporateStaticDisclaimerPathParts };
export function corporateStaticDisclaimerRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticDisclaimerParams>) {
  return {
    match: (q ? corporateStaticDisclaimerPath.then(query(t.intersection([corporateStaticDisclaimerQuery, q]))) : corporateStaticDisclaimerPath.then(query(corporateStaticDisclaimerQuery))).then(end),
    pathParts: corporateStaticDisclaimerPathParts,
  };
}

export const corporateStaticDisclaimer = () =>
  urlInterface("GET", corporateStaticDisclaimerRoute().match.formatter.run(Route.empty, {}).toString(true));

const corporateStaticHubspotLandingPagesPath = lit("corporate").then(lit("hs-landing-pages"));
const corporateStaticHubspotLandingPagesPathParts = ["corporate", "hs-landing-pages"] as const;
const corporateStaticHubspotLandingPagesQuery = t.strict({});
export type CorporateStaticHubspotLandingPagesParams = Describe<typeof corporateStaticHubspotLandingPagesPath._A & typeof corporateStaticHubspotLandingPagesQuery._A>;

export function corporateStaticHubspotLandingPagesRoute(): { match: Match<CorporateStaticHubspotLandingPagesParams>; pathParts: typeof corporateStaticHubspotLandingPagesPathParts };
export function corporateStaticHubspotLandingPagesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticHubspotLandingPagesParams>): { match: Match<CorporateStaticHubspotLandingPagesParams & t.TypeOf<A>>; pathParts: typeof corporateStaticHubspotLandingPagesPathParts };
export function corporateStaticHubspotLandingPagesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticHubspotLandingPagesParams>) {
  return {
    match: (q ? corporateStaticHubspotLandingPagesPath.then(query(t.intersection([corporateStaticHubspotLandingPagesQuery, q]))) : corporateStaticHubspotLandingPagesPath.then(query(corporateStaticHubspotLandingPagesQuery))).then(end),
    pathParts: corporateStaticHubspotLandingPagesPathParts,
  };
}

export const corporateStaticHubspotLandingPages = () =>
  urlInterface("GET", corporateStaticHubspotLandingPagesRoute().match.formatter.run(Route.empty, {}).toString(true));

const corporateStaticHubspotBlogPagesPath = lit("corporate").then(lit("hs-blog-pages"));
const corporateStaticHubspotBlogPagesPathParts = ["corporate", "hs-blog-pages"] as const;
const corporateStaticHubspotBlogPagesQuery = t.intersection([  t.exact(t.type({
                                                                 "limit": NumberFromString
                                                               })), t.exact(t.partial({
                                                                 "after": t.string,
                                                                 "state": t.string
                                                               }))]);;
export type CorporateStaticHubspotBlogPagesParamsRaw = Describe<typeof corporateStaticHubspotBlogPagesPath._A & typeof corporateStaticHubspotBlogPagesQuery._A>;
export type CorporateStaticHubspotBlogPagesParams = Describe<NullableToOptionalFields<CorporateStaticHubspotBlogPagesParamsRaw>>;

export function corporateStaticHubspotBlogPagesRoute(): { match: Match<CorporateStaticHubspotBlogPagesParamsRaw>; pathParts: typeof corporateStaticHubspotBlogPagesPathParts };
export function corporateStaticHubspotBlogPagesRoute<A extends t.Mixed>(q: NoKeyOverlapC<A, CorporateStaticHubspotBlogPagesParamsRaw>): { match: Match<CorporateStaticHubspotBlogPagesParamsRaw & t.TypeOf<A>>; pathParts: typeof corporateStaticHubspotBlogPagesPathParts };
export function corporateStaticHubspotBlogPagesRoute<A extends t.Mixed>(q?: NoKeyOverlapC<A, CorporateStaticHubspotBlogPagesParamsRaw>) {
  return {
    match: (q ? corporateStaticHubspotBlogPagesPath.then(query(t.intersection([corporateStaticHubspotBlogPagesQuery, q]))) : corporateStaticHubspotBlogPagesPath.then(query(corporateStaticHubspotBlogPagesQuery))).then(end),
    pathParts: corporateStaticHubspotBlogPagesPathParts,
  };
}

export const corporateStaticHubspotBlogPages = (p: CorporateStaticHubspotBlogPagesParams) =>
  urlInterface("GET", corporateStaticHubspotBlogPagesRoute().match.formatter.run(Route.empty, optionalFieldsToNullable(p)).toString(true));