export * from "fp-ts/lib/Ord";

import { Ord as boolean } from "fp-ts/lib/boolean"; // eslint-disable-line id-blacklist
import { Ord as number } from "fp-ts/lib/number"; // eslint-disable-line id-blacklist
import * as Ord from "fp-ts/lib/Ord";
import { Ord as string } from "fp-ts/lib/string"; // eslint-disable-line id-blacklist

export { string, number, boolean }; // eslint-disable-line id-blacklist


/**
 * Preserves the original order of elements.
 *
 * @example
 * import { preserve } from '@scripts/fp-ts/Ord'
 * import { sort } from 'fp-ts/ReadonlyArray'
 *
 * assert.deepStrictEqual(sort(preserve())([9, 2, 2, 6, 9, 3, 5]), [9, 2, 2, 6, 9, 3, 5])
 * assert.deepStrictEqual(sort(preserve())(["l", "e", "v", "g"]), ["l", "e", "v", "g"])
 */
export const preserve = <T>(): Ord.Ord<T> => Ord.fromCompare<T>(() => 0);
