import * as t from "io-ts";

import { dateRangeC } from "@scripts/codecs/routing";
import type { DateRangeU } from "@scripts/generated/domaintables/dateRange";
import { localDateNow } from "@scripts/syntax/date/joda";

import { defaultDateRange } from "../base";


export const analyticsDateRangeValues = [14, 30, 84, 365] as const;
export type AnalyticsDateRange = typeof analyticsDateRangeValues[number];
export const defaultAnalyticsDateRange = analyticsDateRangeValues[3];

export const dealPortalAnalyticsDefaultDateRange = analyticsDateRangeValues[1];

export const issuerFeedDateRangeValues = [7, 30, 60, 90] as const;
export type IssuerFeedDateRange = typeof issuerFeedDateRangeValues[number];
export const issuerFeedDaysToLoad = 90;

export const defaultStartDate =
  (dateRange: DateRangeU["days"] | AnalyticsDateRange = defaultDateRange) =>
    localDateNow().minusDays(dateRange);

export const defaultStartEndDates =
  (dateRange: DateRangeU["days"] | AnalyticsDateRange = defaultDateRange) => ({
    startDate: defaultStartDate(dateRange),
    endDate: localDateNow(),
  });

export const dateRangeParamC = t.type({ dateRange: dateRangeC() });
type DateRangeParamC = typeof dateRangeParamC;
export type DateRangeParam = t.TypeOf<DateRangeParamC>;
export const dateRangeParamDefault = (dateRange: DateRangeU["days"] | AnalyticsDateRange = defaultDateRange): DateRangeParam => ({ dateRange: { type: "standard", start: defaultStartDate(dateRange), end: localDateNow() } });
export type DateRangeKeys = keyof typeof dateRangeParamDefault;
