import type { ReactElement } from "react";
import { fold as foldE } from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { fold, none } from "fp-ts/lib/Option";

import type { TEWithEffect } from "@scripts/api/methods";
import { useDataLoader } from "@scripts/react/util/useDataLoader";

import type { TimeStamp } from "../util/useReload";
import type { ApiLoaderErrorElementProps } from "./api-loader/ApiLoader";
import { ApiLoaderErrorElement } from "./api-loader/ApiLoader";

type Props<E, A> = {
  taskEither: TEWithEffect<E, A>;
  loading: () => ReactElement;
  children: (data: A) => ReactElement;
  ts?: TimeStamp;
} & ApiLoaderErrorElementProps;

export function DataLoader<E, A>(p: Props<E, A>): ReactElement {
  const [isLoading, data] = useDataLoader<E, A>(p.taskEither, none, p.ts);

  return isLoading ? p.loading() : pipe(
    data,
    fold(
      () => <ApiLoaderErrorElement {...p} />,
      foldE(
        () => <ApiLoaderErrorElement {...p} />,
        d => p.children(d)
      )
    )
  );
}

