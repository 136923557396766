import { isNotProtectedProp } from "@scripts/fp-ts/lib/_protectedProps";

export function delProp<A, K extends keyof A>(obj: A, ...ks: K[]): Omit<A, K> {
  const o2: A = Object.assign(  // eslint-disable-line fp/no-mutating-assign
    /**
     * `Object.create(null)` creates an object without a prototype, a precautionary step
     * taken to guard against prototype pollution (see also `ProtectedProp`, Object poisoning)
     */
    Object.create(null),
    obj,
  );

  ks.forEach(k => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    if (isNotProtectedProp(k)) delete o2[k];
  });

  return o2;
}
