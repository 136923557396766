import * as t from "io-ts";
import { theseC } from "../../codecs/these";
import { LocalDateC } from "../../codecs/localDate";
import { PublicationCU as imported1_PublicationCU } from "../domaintables/publications";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";

export const recentInsightUrlC = t.type({
  url: t.string
});
export type RecentInsightUrlC = typeof recentInsightUrlC;
export type RecentInsightUrl = t.TypeOf<RecentInsightUrlC>;


export const recentInsightContentC = t.type({
  content: markdownC
});
export type RecentInsightContentC = typeof recentInsightContentC;
export type RecentInsightContent = t.TypeOf<RecentInsightContentC>;


export const recentInsightC = t.type({
  publication: imported1_PublicationCU,
  title: t.string,
  description: optionFromNullable(markdownC),
  urlAndContent: theseC(recentInsightUrlC, recentInsightContentC),
  date: LocalDateC,
  imgSrc: optionFromNullable(t.string),
  mediaId: optionFromNullable(t.number)
});
export type RecentInsightC = typeof recentInsightC;
export type RecentInsight = t.TypeOf<RecentInsightC>;


