/* eslint-disable @typescript-eslint/no-explicit-any */
export function updProp<K extends PropertyKey>(k: K): <A extends Record<K, any>, B>(f: (v: A[K]) => B) => (a: A) => A & Record<K, B> {
  return <A extends Record<K, any>, B>(f: (v: A[K]) => B) => (a: A): A & Record<K, B> =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    Object.assign( // eslint-disable-line fp/no-mutating-assign
      /**
       * `Object.create(null)` creates an object without a prototype, a precautionary step
       * taken to guard against prototype pollution (see also `ProtectedProp`, Object poisoning)
       */
      Object.create(null),
      a,
      { [k]: f(a[k]) },
    );
}
