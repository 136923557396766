import type { RefObject } from "react";
import { useMemo, useRef, useState } from "react";
import type { SpringRef } from "react-spring";
import { animated, useSpring } from "react-spring";
import * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import { useStableCallback, useStableEffect, useStableO } from "fp-ts-react-stable-hooks";

import type { SVGString } from "*.svg";

import { Static } from "@scripts/bondlinkStatic";
import { b, constFalse, constVoid, Eq, O, pipe, RA, s } from "@scripts/fp-ts";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klass, klassConditional, klassPropO } from "@scripts/react/util/classnames";
import { delProp } from "@scripts/util/delProp";

import chevronNext from "@svgs/chevron-next.svg";
import chevronPrevious from "@svgs/chevron-previous.svg";

import { foldRef, foldVoidRef } from "../syntax/ref";
import { ButtonLink } from "./Button";
import { Empty, mapOrEmpty } from "./Empty";
import { Svg } from "./Svg";

export type TabRowItem<A extends PropertyKey> = {
  title: string;
  subtitle?: string;
  value: A;
  onClick: () => void;
  id?: string;
  icon: O.Option<SVGString>;
};

export type TabRowItems<A extends PropertyKey> = RNA.ReadonlyNonEmptyArray<TabRowItem<A>>;

type TabRowOrientation = "horizontal" | "vertical";
export type TabRowProps<A extends PropertyKey> = {
  items: TabRowItems<A>;
  current: A;
  klasses?: KlassProp;
} & ({ variant: "mini" | "login" } | {
  variant: "nav" | "default";
  orientation: TabRowOrientation;
  isSm: boolean;
});

type SnapNavButtonProps = {
  direction: "chev-left" | "chev-right";
  defaultVisible: boolean;
  onClick: () => void;
  parentRef: RefObject<HTMLDivElement>;
};

export const scrollNavTo = (position: number) => foldVoidRef((e) => e.scrollTo({ left: position }));

export const SnapNavButton = (props: SnapNavButtonProps) => {
  const isLeft = props.direction === "chev-left";
  return (
    <div
      {...klassConditional("d-none", ["snap-nav-button", props.direction])(!props.defaultVisible)}
      ref={props.parentRef}
    >
      <button
        {...klass("btn")}
        aria-label={isLeft ? "Previous" : "Next"}
        onClick={props.onClick}
        type="button"
      >
        <Svg src={isLeft ? chevronPrevious : chevronNext} />
      </button>
    </div>
  );
};

export const changeSnapVisibility = (selectors: ReadonlyArray<RefObject<HTMLDivElement>>, visible: boolean) => pipe(
  selectors,
  RA.map(foldVoidRef((e) => visible
    ? e.classList.remove("d-none")
    : e.classList.add("d-none"),
  ))
);

export const TabRow = <A extends PropertyKey>(props: TabRowProps<A>) => {
  const isHorizontal = (props.variant === "default" || props.variant === "nav")
    ? props.orientation === "horizontal"
      ? props.isSm
      : false
    : true;
  const orientation: TabRowOrientation = isHorizontal ? "horizontal" : "vertical";
  const [scrollable, setScrollable] = useState(false);

  const subNav: RefObject<HTMLDivElement> = useRef(null);
  const rightSelector: RefObject<HTMLDivElement> = useRef(null);
  const leftSelector: RefObject<HTMLDivElement> = useRef(null);

  const isScrollable = () => foldRef(constFalse)((e: HTMLDivElement) => (e.scrollWidth > e.clientWidth))(subNav);

  const getNavWidth = (n: RefObject<HTMLDivElement>) => foldRef(() => 0)((e: HTMLDivElement) => e.scrollWidth)(n);

  const renderSnapNav = (e: HTMLDivElement) =>
    e.scrollLeft === 0
      ? changeSnapVisibility([leftSelector], false)
      : e.scrollLeft + e.clientWidth + 1 >= e.scrollWidth
        ? changeSnapVisibility([rightSelector], false)
        : changeSnapVisibility([leftSelector, rightSelector], true);

  const onNavScroll = () => foldVoidRef(renderSnapNav)(subNav);

  useStableEffect(
    () => {

      const observer = new ResizeObserver((entries) => O.fold(
        constVoid,
        () => { setScrollable(isScrollable()); onNavScroll(); }
      )(RA.head(entries)));

      pipe(
        subNav.current,
        O.fromNullable,
        O.fold(
          constVoid,
          (n) => observer.observe(n)
        )
      );

      return () => observer.disconnect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subNav],
    Eq.tuple(Eq.eqStrict)
  );

  const [styles, api] = useSpring(
    () => ({
      from: { x: 0, width: 0, y: 0, height: 0 },
      config: { duration: Static.baseTransitionDelay },
    }),
    []
  );

  const tabTitles = useMemo(() =>
    RNA.map((item: TabRowItem<A>) => item.title)(props.items), [props.items]);

  return (
    <div {...klassPropO(["tab-row-container", props.variant, orientation])(props.klasses)}>
      <div {...klassConditional("animated", ["tab-row", orientation])(props.variant !== "nav")} onScroll={onNavScroll} ref={subNav}>
        {props.variant !== "nav" ? <animated.div
          {...klass("tab-indicator")}
          style={{ ...delProp(styles, isHorizontal ? "height" : "width"), ...(isHorizontal ? { y: "-50%" } : { x: "-50%" }) }}
        /> : <Empty />}
        <div {...klass("tabs-container")}>
          {RNA.mapWithIndex((i, _: TabRowItem<A>) =>
            <Tab
              item={_}
              key={`${_.title}-${i}`}
              current={props.current}
              api={api}
              tabTitles={tabTitles}
              icon={_.icon}
              useHorizontalAnimation={isHorizontal}
            />
          )(props.items)}
        </div>
      </div>
      {orientation === "horizontal" && <>
        <SnapNavButton
          direction={"chev-left"}
          defaultVisible={false}
          onClick={() => { changeSnapVisibility([rightSelector], true); scrollNavTo(0)(subNav); }}
          parentRef={leftSelector}
        />
        <SnapNavButton
          direction={"chev-right"}
          defaultVisible={scrollable}
          onClick={() => { changeSnapVisibility([leftSelector], true); scrollNavTo(getNavWidth(subNav))(subNav); }}
          parentRef={rightSelector}
        />
      </>}
    </div>
  );
};

type TabProps<A extends PropertyKey> = {
  item: TabRowItem<A>;
  current: A;
  api: SpringRef<{ x: number, width: number, y: number, height: number }>;
  icon: O.Option<SVGString>;
  tabTitles: RNA.ReadonlyNonEmptyArray<string>;
  useHorizontalAnimation: boolean;
};

const callSpringApi = <A extends PropertyKey>(
  apiFn: SpringRef<{ x: number, width: number, y: number, height: number }>["set" | "start"],
  tab: O.Option<HTMLDivElement>,
  props: {
    current: A;
    item: TabRowItem<A>;
    useHorizontalAnimation: boolean;
  }
) => {
  O.map((node: HTMLDivElement) => {
    if (props.current === props.item.value) {
      if (props.useHorizontalAnimation) {
        apiFn({
          x: node.offsetLeft,
          width: node.offsetWidth,
          y: 0,
          height: 1,
        });
      } else {
        apiFn({
          y: node.offsetTop,
          height: node.offsetHeight,
          x: 0,
          width: 1,
        });
      }
    }
  })(tab);
};

const Tab = <A extends PropertyKey>(props: TabProps<A>) => {

  const [tab, setTab] = useStableO<HTMLDivElement>(O.none);

  const tabRef = useStableCallback((node: HTMLDivElement | null) => {
    const nodeO = O.fromNullable(node);
    setTab(nodeO);
    callSpringApi<A>(props.api.set.bind(props.api), nodeO, props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.useHorizontalAnimation, tab], Eq.tuple(b.Eq, O.getEq(Eq.eqStrict)));

  useStableEffect(
    () => {
      callSpringApi<A>(props.api.start.bind(props.api), tab, props);

      const observer = new ResizeObserver((entries) => O.fold(
        constVoid,
        () => callSpringApi<A>(props.api.start.bind(props.api), tab, props)
      )(RA.head(entries)));

      pipe(
        tab,
        O.fold(
          constVoid,
          (n) => observer.observe(n)
        )
      );

      return () => observer.disconnect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab, props.current, props.item.value, props.useHorizontalAnimation, props.tabTitles],
    Eq.tuple(O.getEq(Eq.eqStrict), Eq.eqStrict, Eq.eqStrict, b.Eq, RNA.getEq(s.Eq))
  );

  return (
    <div ref={tabRef} {...klass("tab-container")}>
      <ButtonLink
        id={props.item.id || `tab-${props.item.value.toString()}`}
        onClick={props.item.onClick}
        {...klassConditional("current", ["tab", "no-focus"])(props.item.value === props.current)}
        data-text={props.item.title}
      >
        <div {...klass("tab-content", "d-flex", "flex-col", "h-100")}>
          <div {...klass("d-flex", "w-100", "h-100", "align-items-center")}>{mapOrEmpty((svg: SVGString) => <Svg src={svg} />)(props.icon)}{props.item.title}</div>
          {pipe(props.item.subtitle, O.fromNullable, mapOrEmpty((subtitle: string) => <div {...klass("font-size-small")}>{subtitle}</div>))}
        </div>
      </ButtonLink>
    </div>
  );
};
