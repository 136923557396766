// This file was generated by postcss-transpiler. Do not edit by hand.

export const footer = {
  ".footer": {
    css: ".footer",
    html: "footer",
    "[class^=\"footer-\"]": {
      css: "[class^=\"footer-\"]",
      html: "[class^=\"footer-\"]"
    },
    ".footer-logo": {
      css: ".footer-logo",
      html: "footer-logo"
    },
    ".bondlink-logo": {
      css: ".bondlink-logo",
      html: "bondlink-logo",
      "svg": {
        css: "svg",
        html: "svg"
      }
    },
    ".btn-link": {
      css: ".btn-link",
      html: "btn-link"
    },
    ".issuer-watchlist-actions": {
      css: ".issuer-watchlist-actions",
      html: "issuer-watchlist-actions",
      ".icon-toggle-subscription": {
        css: ".icon-toggle-subscription",
        html: "icon-toggle-subscription",
        ".bl-tooltip-content": {
          css: ".bl-tooltip-content",
          html: "bl-tooltip-content"
        }
      }
    },
    ".btn-social": {
      css: ".btn-social",
      html: "btn-social",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".footer-links": {
      css: ".footer-links",
      html: "footer-links"
    },
    ".footer-copyright": {
      css: ".footer-copyright",
      html: "footer-copyright"
    },
    ".footer-social": {
      css: ".footer-social",
      html: "footer-social"
    }
  },
  ".issuer-watchlist-actions": {
    css: ".issuer-watchlist-actions",
    html: "issuer-watchlist-actions",
    ".icon-toggle-subscription": {
      css: ".icon-toggle-subscription",
      html: "icon-toggle-subscription",
      ".footer": {
        css: ".footer",
        html: "footer",
        ".bl-tooltip-content": {
          css: ".bl-tooltip-content",
          html: "bl-tooltip-content"
        }
      }
    }
  }
};
