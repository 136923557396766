import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export class isAttachedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  data: A1,
  attached: t.boolean
})}
export const isAttachedC = <A1 extends t.Mixed>(A1: A1) => new isAttachedCC<A1>().codec(A1);
export type IsAttachedC<A1 extends t.Mixed> = ReturnType<isAttachedCC<A1>["codec"]>;
export type IsAttached<A1> = t.TypeOf<IsAttachedC<t.Type<A1>>>;

export class dataAndIdCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  id: optionFromNullable(t.number),
  data: A1
})}
export const dataAndIdC = <A1 extends t.Mixed>(A1: A1) => new dataAndIdCC<A1>().codec(A1);
export type DataAndIdC<A1 extends t.Mixed> = ReturnType<dataAndIdCC<A1>["codec"]>;
export type DataAndId<A1> = t.TypeOf<DataAndIdC<t.Type<A1>>>;

export class dataAndMetaCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  id: optionFromNullable(t.number),
  data: A1,
  meta: A2
})}
export const dataAndMetaC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new dataAndMetaCC<A1,A2>().codec(A1,A2);
export type DataAndMetaC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<dataAndMetaCC<A1,A2>["codec"]>;
export type DataAndMeta<A1,A2> = t.TypeOf<DataAndMetaC<t.Type<A1>,t.Type<A2>>>;

export class postAttachedCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  attached: t.readonlyArray(dataAndMetaC(A1, A2))
})}
export const postAttachedC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new postAttachedCC<A1,A2>().codec(A1,A2);
export type PostAttachedC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<postAttachedCC<A1,A2>["codec"]>;
export type PostAttached<A1,A2> = t.TypeOf<PostAttachedC<t.Type<A1>,t.Type<A2>>>;

export class postRelatedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  related: t.readonlyArray(A1)
})}
export const postRelatedC = <A1 extends t.Mixed>(A1: A1) => new postRelatedCC<A1>().codec(A1);
export type PostRelatedC<A1 extends t.Mixed> = ReturnType<postRelatedCC<A1>["codec"]>;
export type PostRelated<A1> = t.TypeOf<PostRelatedC<t.Type<A1>>>;

export class postRelatedAndAttachedCC<A1 extends t.Mixed, A2 extends t.Mixed, A3 extends t.Mixed>{ codec = (A1: A1, A2: A2, A3: A3) => t.type({
  related: t.readonlyArray(A1),
  attached: t.readonlyArray(dataAndMetaC(A2, A3))
})}
export const postRelatedAndAttachedC = <A1 extends t.Mixed, A2 extends t.Mixed, A3 extends t.Mixed>(A1: A1, A2: A2, A3: A3) => new postRelatedAndAttachedCC<A1,A2,A3>().codec(A1,A2,A3);
export type PostRelatedAndAttachedC<A1 extends t.Mixed, A2 extends t.Mixed, A3 extends t.Mixed> = ReturnType<postRelatedAndAttachedCC<A1,A2,A3>["codec"]>;
export type PostRelatedAndAttached<A1,A2,A3> = t.TypeOf<PostRelatedAndAttachedC<t.Type<A1>,t.Type<A2>,t.Type<A3>>>;

export const relatedAnalystLinkC = t.type({
  analystId: t.number,
  sortOrder: t.number
});
export type RelatedAnalystLinkC = typeof relatedAnalystLinkC;
export type RelatedAnalystLink = t.TypeOf<RelatedAnalystLinkC>;


export const relatedCusip9LinkC = t.type({
  cusip9Id: t.number
});
export type RelatedCusip9LinkC = typeof relatedCusip9LinkC;
export type RelatedCusip9Link = t.TypeOf<RelatedCusip9LinkC>;


export const relatedDocumentLinkC = t.type({
  documentId: t.number,
  sortOrder: t.number
});
export type RelatedDocumentLinkC = typeof relatedDocumentLinkC;
export type RelatedDocumentLink = t.TypeOf<RelatedDocumentLinkC>;


export const relatedEventLinkC = t.type({
  eventId: t.number
});
export type RelatedEventLinkC = typeof relatedEventLinkC;
export type RelatedEventLink = t.TypeOf<RelatedEventLinkC>;


export const relatedExternalLinkLinkC = t.type({
  externalLinkId: t.number,
  sortOrder: t.number
});
export type RelatedExternalLinkLinkC = typeof relatedExternalLinkLinkC;
export type RelatedExternalLinkLink = t.TypeOf<RelatedExternalLinkLinkC>;


export const relatedFaqLinkC = t.type({
  faqId: t.number,
  sortOrder: t.number
});
export type RelatedFaqLinkC = typeof relatedFaqLinkC;
export type RelatedFaqLink = t.TypeOf<RelatedFaqLinkC>;


export const relatedNewsLinkC = t.type({
  newsId: t.number
});
export type RelatedNewsLinkC = typeof relatedNewsLinkC;
export type RelatedNewsLink = t.TypeOf<RelatedNewsLinkC>;


export const relatedOfferingLinkC = t.type({
  offeringId: t.number
});
export type RelatedOfferingLinkC = typeof relatedOfferingLinkC;
export type RelatedOfferingLink = t.TypeOf<RelatedOfferingLinkC>;


export const relatedOfferingParticipantLinkC = t.type({
  offeringParticipantId: t.number,
  participantTypeId: t.number,
  sortOrder: t.number
});
export type RelatedOfferingParticipantLinkC = typeof relatedOfferingParticipantLinkC;
export type RelatedOfferingParticipantLink = t.TypeOf<RelatedOfferingParticipantLinkC>;


export const relatedPhotoLinkC = t.type({
  photoId: t.number,
  sortOrder: t.number
});
export type RelatedPhotoLinkC = typeof relatedPhotoLinkC;
export type RelatedPhotoLink = t.TypeOf<RelatedPhotoLinkC>;


export const relatedPrivateDocumentLinkC = t.type({
  documentId: t.number
});
export type RelatedPrivateDocumentLinkC = typeof relatedPrivateDocumentLinkC;
export type RelatedPrivateDocumentLink = t.TypeOf<RelatedPrivateDocumentLinkC>;


export const relatedContentNoteLinkC = t.type({
  contentNoteId: t.number
});
export type RelatedContentNoteLinkC = typeof relatedContentNoteLinkC;
export type RelatedContentNoteLink = t.TypeOf<RelatedContentNoteLinkC>;


export const relatedProgramLinkC = t.type({
  programId: t.number
});
export type RelatedProgramLinkC = typeof relatedProgramLinkC;
export type RelatedProgramLink = t.TypeOf<RelatedProgramLinkC>;


export const relatedProjectLinkC = t.type({
  projectId: t.number
});
export type RelatedProjectLinkC = typeof relatedProjectLinkC;
export type RelatedProjectLink = t.TypeOf<RelatedProjectLinkC>;


export const relatedQuickFactLinkC = t.type({
  quickFactId: t.number,
  sortOrder: t.number
});
export type RelatedQuickFactLinkC = typeof relatedQuickFactLinkC;
export type RelatedQuickFactLink = t.TypeOf<RelatedQuickFactLinkC>;


export const relatedRatingLinkC = t.type({
  ratingId: t.number
});
export type RelatedRatingLinkC = typeof relatedRatingLinkC;
export type RelatedRatingLink = t.TypeOf<RelatedRatingLinkC>;


export const relatedReminderLinkC = t.type({
  reminderId: t.number
});
export type RelatedReminderLinkC = typeof relatedReminderLinkC;
export type RelatedReminderLink = t.TypeOf<RelatedReminderLinkC>;


export const relatedRfpLinkC = t.type({
  rfpId: t.number
});
export type RelatedRfpLinkC = typeof relatedRfpLinkC;
export type RelatedRfpLink = t.TypeOf<RelatedRfpLinkC>;


export const relatedRoadShowLinkC = t.type({
  roadShowId: t.number
});
export type RelatedRoadShowLinkC = typeof relatedRoadShowLinkC;
export type RelatedRoadShowLink = t.TypeOf<RelatedRoadShowLinkC>;


