import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const news = {
  _tag: `News`,
  id: 1,
  name: `News`
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const pressRelease = {
  _tag: `PressRelease`,
  id: 2,
  name: `Press Release`
} as const;

export const pressReleaseTaggedC = t.type({
  _tag: t.literal(`PressRelease`)
});
export type PressReleaseTaggedC = typeof pressReleaseTaggedC;
export type PressReleaseTagged = t.TypeOf<PressReleaseTaggedC>;
export type PressRelease = PressReleaseTagged & typeof pressRelease;
export const pressReleaseC = pipe(pressReleaseTaggedC, c => new t.Type<PressRelease, PressReleaseTagged>(
  `PressRelease`,
  (u: unknown): u is PressRelease => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PressRelease> => pipe(c.decode(u), E.map(x => ({ ...x, ...pressRelease }))),
  (x: PressRelease): PressReleaseTagged => ({ ...x, _tag: `PressRelease`}),
));
export type PressReleaseC = typeof pressReleaseC;


export const allPostTypeC = [newsC, pressReleaseC] as const;
export const allPostTypeNames = [`News`, `PressRelease`] as const;
export type PostTypeName = (typeof allPostTypeNames)[number];

export const PostTypeCU = t.union([newsC, pressReleaseC]);
export type PostTypeCU = typeof PostTypeCU;
export type PostTypeU = t.TypeOf<PostTypeCU>;

export const postTypeOrd: Ord.Ord<PostTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPostType = [news, pressRelease] as const;
export type PostTypeMap<A> = { [K in PostTypeName]: A };


