import { identity } from "fp-ts/lib/function";
import type { ReadonlyNonEmptyArray } from "fp-ts/ReadonlyNonEmptyArray";
import type { Newtype } from "newtype-ts";
import { iso } from "newtype-ts";

type Expr<A> = {
	tag: "expr";
	value: A;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type Constant<_A> = {
	tag: "constant";
	value: boolean;
};

type Not<A> = {
	tag: "not";
	value: _Blang<A>;
};

type And<A> = {
	tag: "and";
	value: ReadonlyNonEmptyArray<_Blang<A>>;
};

type Or<A> = {
	tag: "or";
	value: ReadonlyNonEmptyArray<_Blang<A>>;
};

type Xor<A> = {
	tag: "xor";
	value: ReadonlyNonEmptyArray<_Blang<A>>;
};

type _Blang<A> = Expr<A> | Constant<A> | Not<A> | And<A> | Or<A> | Xor<A>;

export type Blang<A> = Newtype<{ readonly Blang: unique symbol }, _Blang<A>>;
const getIsoBlang = <A>() => iso<Blang<A>>();

export const expr = <A>(value: A): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	return isoBlang.wrap({ tag: "expr", value });
};

export const constant = <A>(value: boolean): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	return isoBlang.wrap({ tag: "constant", value });
};

export const not = <A>(value: Blang<A>): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	return isoBlang.wrap({ tag: "not", value: isoBlang.unwrap(value) });
};

/*
 * [AD 2022/08/11] Just using this function so we don't have to map over
 * a ReadonlyNonEmptyArray to unwrap the inner newtype.
 */
const unsafeUnwrapInnerBlang: <A>(arr: ReadonlyNonEmptyArray<Blang<A>>) => ReadonlyNonEmptyArray<_Blang<A>> =
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  identity as <A>(arr: ReadonlyNonEmptyArray<Blang<A>>) => ReadonlyNonEmptyArray<_Blang<A>>;

export const and = <A>(value: ReadonlyNonEmptyArray<Blang<A>>): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	if (value.length === 1) {
		return value[0];
	} else {
		return isoBlang.wrap({ tag: "and", value: unsafeUnwrapInnerBlang(value) });
	}
};

export const or = <A>(value: ReadonlyNonEmptyArray<Blang<A>>): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	if (value.length === 1) {
		return value[0];
	} else {
		return isoBlang.wrap({ tag: "or", value: unsafeUnwrapInnerBlang(value) });
	}
};

export const xor = <A>(value: ReadonlyNonEmptyArray<Blang<A>>): Blang<A> => {
	const isoBlang = getIsoBlang<A>();
	if (value.length === 1) {
		return value[0];
	} else {
		return isoBlang.wrap({ tag: "xor", value: unsafeUnwrapInnerBlang(value) });
	}
};

/**
 * [AD 2022/08/09] Importing `exhaustive` from `exhaustive.ts` introduces a circular
 * dependency when used in 'sentry.ts' (and possibly other modules). Using this
 * one-off function will at least enforce type safety here.
 */
function exhaustive(a: never): never {
	// eslint-disable-next-line no-console
	console.error("Exhaustive Match Reached:", a);
	return a;
}

export const interpretWith = <A>(evalExpr: (value: A) => boolean) => {
	const _interpret = (blang: _Blang<A>): boolean => {
		const { tag, value } = blang;
		switch (tag) {
			case "expr": {
				return evalExpr(value);
			}
			case "constant": {
				return value;
			}
			case "not": {
				return !_interpret(value);
			}
			case "and": {
				return value.every(_interpret);
			}
			case "or": {
				return value.some(_interpret);
			}
			case "xor": {
				return value.filter(_interpret).length === 1;
			}
			default:
				return exhaustive(tag);
		}
	};
	return (spec: Blang<A>): boolean =>
		_interpret(getIsoBlang<A>().unwrap(spec));
};
