import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const bankC = t.type({
  id: t.number,
  clientId: t.number,
  slug: t.string,
  name: t.string,
  email: t.string,
  logoUrl: optionFromNullable(t.string)
});
export type BankC = typeof bankC;
export type Bank = t.TypeOf<BankC>;


export const bankPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  email: t.string
});
export type BankPostC = typeof bankPostC;
export type BankPost = t.TypeOf<BankPostC>;


