import { withIdC as imported191_withIdC } from "./threadThrough";
import * as t from "io-ts";
import { documentWithCategoryC as imported13_documentWithCategoryC } from "./document";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { activitiesC as imported2_activitiesC } from "./dealPortalActivity";
import { actorC as imported6_actorC } from "./actor";

export const investorActivityRowC = t.type({
  actor: imported6_actorC,
  activities: imported2_activitiesC,
  lastActive: LocalDateTimeFromIsoStringC
});
export type InvestorActivityRowC = typeof investorActivityRowC;
export type InvestorActivityRow = t.TypeOf<InvestorActivityRowC>;


export const investorActivityTableC = t.type({
  investorActivityRows: t.readonlyArray(investorActivityRowC),
  documents: t.readonlyArray(imported191_withIdC(imported13_documentWithCategoryC))
});
export type InvestorActivityTableC = typeof investorActivityTableC;
export type InvestorActivityTable = t.TypeOf<InvestorActivityTableC>;


