// eslint-disable-next-line import-x/export
export * from "fp-ts/lib/Option";

import type { Option } from "fp-ts/lib/Option";
import * as O from "fp-ts/lib/Option";
import type { Ord } from "fp-ts/lib/Ord";

import { fromCodec, optionCodec } from "./lib/_internal";
import type { Match } from "./lib/types";

export type Optionalize<A> = { [K in keyof A]-?: Option<NonNullable<A[K]>> };
export type UnOptionalize<A> = { [K in keyof A]: A[K] extends Option<infer V> ? V : A[K] };

export type Any = Option<unknown>;
export type Infer<O extends Match.AnyOption> = [O] extends [Option<infer A>] ? A : never;

export const is: (u: unknown) => u is Match.AnyOption = fromCodec(optionCodec);

/**
 * @description Like `O.getOrElseW`, but accepts a value to
 * fall back on (as opposed to being instantiated lazily).
 *
 * NOTE: If your fallback value is expensive to compute,
 * or might change over time, use `O.getOrElseW` instead.
 * That way you don't run the computation unnecessarily,
 * or capture the wrong value because it ran prematurely.
 *
 * @see also {@link orElse}
 * @see also {@link https://gcanti.github.io/fp-ts/modules/Option.ts.html#getorelsew}
 */
export const orElseW =
  <B>(onNone: B) =>
    <A>(ma: Option<A>): A | B =>
      O.isNone(ma) ? onNone : ma.value;

/**
 * @description Like `O.getOrElse`, but accepts a value to
 * fall back on (as opposed to being instantiated lazily).
 *
 * NOTE: If your fallback value is expensive to compute,
 * or might change over time, use `O.getOrElse` instead.
 * That way you don't run the computation unnecessarily,
 * or capture the wrong value because it ran prematurely.
 *
 * @see also {@link orElseW}
 * @see also {@link https://gcanti.github.io/fp-ts/modules/Option.ts.html#getorelse}
 */
// eslint-disable-next-line import-x/export
export const orElse: <A>(onNone: A) => (ma: Option<A>) => A = orElseW;

/**
 * @category constructors
 * The use case for `noneOf` is when you need to manually
 * construct a `none` but don't want the inferred type to
 * be `Option<unknown>` (you want to retain the `A` without
 * casting).
 *
 * @example
 * ```typescript
 *  const numberOption = noneOf<number>();
 *  // ExpectType: Option<number>
 * ```
 */
export const noneOf = <A = never>(): Option<A> => O.none;

/**
 * @description Like `O.getOrd` but `None` is considered to be greater than any `Some` value.
 */
export const getOrdNoneMax = <A>(ord: Ord<A>) => ({
  equals: O.getEq(ord).equals,
  compare: (x: O.Option<A>, y: O.Option<A>) =>
    (x === y ? 0 : O.isSome(x) ? (O.isSome(y) ? ord.compare(x.value, y.value) : -1) : 1),
});
