import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";

export class rateLimitedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  remainingRequests: t.number,
  data: A1
})}
export const rateLimitedC = <A1 extends t.Mixed>(A1: A1) => new rateLimitedCC<A1>().codec(A1);
export type RateLimitedC<A1 extends t.Mixed> = ReturnType<rateLimitedCC<A1>["codec"]>;
export type RateLimited<A1> = t.TypeOf<RateLimitedC<t.Type<A1>>>;

export class withIdCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  record: A1,
  id: t.number
})}
export const withIdC = <A1 extends t.Mixed>(A1: A1) => new withIdCC<A1>().codec(A1);
export type WithIdC<A1 extends t.Mixed> = ReturnType<withIdCC<A1>["codec"]>;
export type WithId<A1> = t.TypeOf<WithIdC<t.Type<A1>>>;

export class hasManyLinkCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  data: A1,
  link: withIdC(A2)
})}
export const hasManyLinkC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new hasManyLinkCC<A1,A2>().codec(A1,A2);
export type HasManyLinkC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<hasManyLinkCC<A1,A2>["codec"]>;
export type HasManyLink<A1,A2> = t.TypeOf<HasManyLinkC<t.Type<A1>,t.Type<A2>>>;

export class withModInfoCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  issuerId: t.number,
  userIdModified: t.number,
  created: LocalDateTimeFromIsoStringC,
  modified: optionFromNullable(LocalDateTimeFromIsoStringC),
  published: optionFromNullable(LocalDateTimeFromIsoStringC),
  data: A1
})}
export const withModInfoC = <A1 extends t.Mixed>(A1: A1) => new withModInfoCC<A1>().codec(A1);
export type WithModInfoC<A1 extends t.Mixed> = ReturnType<withModInfoCC<A1>["codec"]>;
export type WithModInfo<A1> = t.TypeOf<WithModInfoC<t.Type<A1>>>;

export class withModUserNameCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  modifyingUserName: t.string,
  data: A1
})}
export const withModUserNameC = <A1 extends t.Mixed>(A1: A1) => new withModUserNameCC<A1>().codec(A1);
export type WithModUserNameC<A1 extends t.Mixed> = ReturnType<withModUserNameCC<A1>["codec"]>;
export type WithModUserName<A1> = t.TypeOf<WithModUserNameC<t.Type<A1>>>;

export class publishedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  _tag: t.literal(`Published`),
  data: withIdC(A1)
})}
export const publishedC = <A1 extends t.Mixed>(A1: A1) => new publishedCC<A1>().codec(A1);
export type PublishedC<A1 extends t.Mixed> = ReturnType<publishedCC<A1>["codec"]>;
export type Published<A1> = t.TypeOf<PublishedC<t.Type<A1>>>;

export class draftedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  _tag: t.literal(`Drafted`),
  data: withIdC(A1),
  parentId: optionFromNullable(t.number)
})}
export const draftedC = <A1 extends t.Mixed>(A1: A1) => new draftedCC<A1>().codec(A1);
export type DraftedC<A1 extends t.Mixed> = ReturnType<draftedCC<A1>["codec"]>;
export type Drafted<A1> = t.TypeOf<DraftedC<t.Type<A1>>>;

export const allWithStatusC = <A1 extends t.Mixed>(A1: A1) => [publishedC(A1), draftedC(A1)] as const;
export const allWithStatusNames = [`Published`, `Drafted`] as const;
export type WithStatusName = (typeof allWithStatusNames)[number];

export class WithStatusCUC<A1 extends t.Mixed>{ codec = (A1: A1) => t.union([publishedC(A1), draftedC(A1)])}
export const WithStatusCU = <A1 extends t.Mixed>(A1: A1) => new WithStatusCUC<A1>().codec(A1);
export type WithStatusCU<A1 extends t.Mixed> = ReturnType<WithStatusCUC<A1>["codec"]>;
export type WithStatusU<A1> = t.TypeOf<WithStatusCU<t.Type<A1>>>;


export class deletedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  _tag: t.literal(`Deleted`),
  data: withIdC(A1),
  parentId: optionFromNullable(t.number),
  deleted: LocalDateTimeFromIsoStringC
})}
export const deletedC = <A1 extends t.Mixed>(A1: A1) => new deletedCC<A1>().codec(A1);
export type DeletedC<A1 extends t.Mixed> = ReturnType<deletedCC<A1>["codec"]>;
export type Deleted<A1> = t.TypeOf<DeletedC<t.Type<A1>>>;

export const allWithStatusIncludingDeletedC = <A1 extends t.Mixed>(A1: A1) => [publishedC(A1), draftedC(A1), deletedC(A1)] as const;
export const allWithStatusIncludingDeletedNames = [`Published`, `Drafted`, `Deleted`] as const;
export type WithStatusIncludingDeletedName = (typeof allWithStatusIncludingDeletedNames)[number];

export class WithStatusIncludingDeletedCUC<A1 extends t.Mixed>{ codec = (A1: A1) => t.union([publishedC(A1), draftedC(A1), deletedC(A1)])}
export const WithStatusIncludingDeletedCU = <A1 extends t.Mixed>(A1: A1) => new WithStatusIncludingDeletedCUC<A1>().codec(A1);
export type WithStatusIncludingDeletedCU<A1 extends t.Mixed> = ReturnType<WithStatusIncludingDeletedCUC<A1>["codec"]>;
export type WithStatusIncludingDeletedU<A1> = t.TypeOf<WithStatusIncludingDeletedCU<t.Type<A1>>>;


export class toManyCC<A1 extends t.Mixed, A2 extends t.Mixed>{ codec = (A1: A1, A2: A2) => t.type({
  one: A1,
  many: t.readonlyArray(A2)
})}
export const toManyC = <A1 extends t.Mixed, A2 extends t.Mixed>(A1: A1, A2: A2) => new toManyCC<A1,A2>().codec(A1,A2);
export type ToManyC<A1 extends t.Mixed, A2 extends t.Mixed> = ReturnType<toManyCC<A1,A2>["codec"]>;
export type ToMany<A1,A2> = t.TypeOf<ToManyC<t.Type<A1>,t.Type<A2>>>;

