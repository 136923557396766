import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const signupPostC = t.type({
  redirect: optionFromNullable(t.string),
  issuerId: optionFromNullable(t.number),
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number),
  termsAccepted: t.boolean,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  password: t.string,
  passwordConfirm: t.string,
  company: t.string
});
export type SignupPostC = typeof signupPostC;
export type SignupPost = t.TypeOf<SignupPostC>;


