import type { KlassProp } from "../react/util/classnames";
import { emptyKlasses, klassConditional } from "../react/util/classnames";

export const rotateElement = (isRotated: boolean): { className: string } => {
  return klassConditional("rotate", "rotate-ccw-180")(isRotated);
};

export const rotateSVG = (isRotated: boolean): { className: string } => {
  return klassConditional("rotate", "rotate-ccw-180-svg")(isRotated);
};

export const rotateWithDefaults = (defaultKp: KlassProp) => (isRotated: boolean): { className: string } => {
  return klassConditional("rotate", (emptyKlasses).concat(defaultKp, "rotate-ccw-180"))(isRotated);
};

export const rotateSVGWithDefaults = (defaultKp: KlassProp) => (isRotated: boolean): { className: string } => {
  return klassConditional("rotate", (emptyKlasses).concat(defaultKp, "rotate-ccw-180-svg"))(isRotated);
};
