import { useCallback, useMemo, useState } from "react";
import uFuzzy from "@leeoniya/ufuzzy";
import { sequenceS } from "fp-ts/lib/Apply";
import type { Eq } from "fp-ts/lib/Eq";
import { flow, pipe } from "fp-ts/lib/function";

import { N, O, RA } from "@scripts/fp-ts";

export type SearchFilterFn<A> = (needle: string) => (haystack: ReadonlyArray<A>) => ReadonlyArray<A>;

// Example data
// haystack: original array ["hi", "good", "bye"] => idxs [0, 1, 2]
// info.idx [0, 1] => filtered result indexes
// infoIdxOrder: [1, 0] => Order to show filtered indexes
// Reconstituted result from original haystack: ["good", "hi"]


type RankedResult = {
  haystackIdxs: uFuzzy.HaystackIdxs;
  info: uFuzzy.Info;
  infoIdxOrder: uFuzzy.InfoIdxOrder;
};

type ResultInfo = {
  idx: number;
  highlightRanges: number[];
};

const isRankedResult = (r: uFuzzy.SearchResult): r is uFuzzy.RankedResult =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  r[0] != null && r[1] != null && r[2] != null;

const parseRankedResult = (r: uFuzzy.RankedResult): RankedResult => ({
  haystackIdxs: r[0],
  info: r[1],
  infoIdxOrder: r[2],
});

const getRankedResults = (r: RankedResult): ReadonlyArray<ResultInfo> =>
  RA.filterMap((i: number) => sequenceS(O.Apply)({
    idx: O.fromNullable(r.info.idx[i]),
    highlightRanges: O.fromNullable(r.info.ranges[i]),
  }))(r.infoIdxOrder);

const retrieveOriginalValues = <A>(haystack: ReadonlyArray<A>) =>
  RA.filterMap((info: ResultInfo) => pipe(
    O.fromNullable(haystack[info.idx]),
    O.map((a: A): FuzzyResult<A> => ({ item: a, highlightRanges: info.highlightRanges }))
  ));

const gatherRankedResults = flow(
  O.filter(isRankedResult),
  O.map(parseRankedResult),
  O.map(getRankedResults)
);

const getResults = <A>(haystack: ReadonlyArray<A>) => (searchResult: uFuzzy.SearchResult) => pipe(
  O.fromNullable(searchResult),
  gatherRankedResults,
  O.map(retrieveOriginalValues(haystack)),
);

const defaultUFuzzyConfig: uFuzzy.Options = {
  // Max number of extra chars allowed between each char within a term
  intraIns: 4,
  // Partial regexp for allowed insert chars between each char within a term
  intraChars: ".",
  // Determines allowable term left boundary
  interLft: 0,
};
export type FuzzyResult<A> = {
  item: A;
  highlightRanges: uFuzzy.Info["ranges"][number];
};

export const useFuzzySearch = <A>(
  haystack: ReadonlyArray<A>,
  getSearchableValue: (a: A) => string,
  defaultResult: ReadonlyArray<FuzzyResult<A>> = [],
  config: uFuzzy.Options = defaultUFuzzyConfig,
) => {
  const fuzzy = new uFuzzy(config);
  const [fuzzyResult, setFuzzyResult] = useState<ReadonlyArray<FuzzyResult<A>>>([]);
  const searchable: string[] = haystack.map(getSearchableValue);

  const fuzzySearch = (searchTerm: string) => pipe(
    fuzzy.search(searchable, searchTerm),
    getResults(haystack),
    O.getOrElse((): ReadonlyArray<FuzzyResult<A>> => defaultResult),
    setFuzzyResult
  );

  return {
    fuzzyResult,
    fuzzySearch,
  };
};

const defaultMark = (part: string, matched: boolean) => matched
  // eslint-disable-next-line curly-quotes/no-straight-quotes
  ? `<span class="font-sans-normal-700">${part}</span>`
  : part;

export const fuzzyHighlight = (
  match: string,
  ranges: number[],
  mark: (part: string, matched: boolean) => string = defaultMark,
) => uFuzzy.highlight(match, ranges, mark);

const ResultInfoEq: Eq<ResultInfo> = {
  equals: (res1, res2) => N.Eq.equals(res1.idx, res2.idx),
};

const makeSearchableValues = <A>(getSearchableValues: ReadonlyArray<(i: A) => string>) => (haystack: ReadonlyArray<A>) => pipe(
  getSearchableValues,
  RA.map(getter => RA.map(getter)(haystack)),
  RA.map(RA.toArray)
);

const fuzzyMultiSearch = (fuzzy: uFuzzy) => <A>(
  searchableValues: ReadonlyArray<string[]>,
  needle: string,
  haystack: ReadonlyArray<A>,
): ReadonlyArray<A> => pipe(
  searchableValues,
  RA.map((s) => pipe(
    fuzzy.search(s, needle),
    O.fromNullable,
    gatherRankedResults,
    O.getOrElse((): ReadonlyArray<ResultInfo> => [])
  )),
  RA.reduce<ReadonlyArray<ResultInfo>, ReadonlyArray<ResultInfo>>(
    [],
    (acc, curr) => RA.union(ResultInfoEq)(acc)(curr)
  ),
  retrieveOriginalValues(haystack),
  RA.map(i => i.item),
  );

export const useFuzzyMultiSearch = <A>(
  haystack: ReadonlyArray<A>,
  getSearchableValues: ReadonlyArray<(i: A) => string>,
  config: uFuzzy.Options = defaultUFuzzyConfig,
) => {
  const [fuzzyResult, setFuzzyResult] = useState<ReadonlyArray<A>>(haystack);

  const searchableValues = useMemo(() => makeSearchableValues(getSearchableValues)(haystack), [haystack, getSearchableValues]);

  const fuzzySearch = useCallback((needle: string) => {
    const fuzzy = new uFuzzy(config);

    return pipe(
      fuzzyMultiSearch(fuzzy)(searchableValues, needle, haystack),
      setFuzzyResult
    );
  }, [haystack, searchableValues, config]);

  return { fuzzyResult, fuzzySearch };
};

export const fuzzyMultiSearchFilter = <A>(
  getSearchableValues: ReadonlyArray<(i: A) => string>,
  config: uFuzzy.Options = defaultUFuzzyConfig,
): SearchFilterFn<A> => {
  return (needle: string) => (haystack: ReadonlyArray<A>) => {
    const fuzzy = new uFuzzy(config);
    const searchableValues = makeSearchableValues(getSearchableValues)(haystack);
    return fuzzyMultiSearch(fuzzy)(searchableValues, needle, haystack);
  };
};
