import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { ContentTagTypeCU as imported3_ContentTagTypeCU, contentTagTypeOrd as imported3_contentTagTypeOrd } from "../domaintables/contentTagTypes";
import * as t from "io-ts";

export class taggedContentCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  data: A1,
  tags: readonlySetFromArray(imported3_ContentTagTypeCU, imported3_contentTagTypeOrd)
})}
export const taggedContentC = <A1 extends t.Mixed>(A1: A1) => new taggedContentCC<A1>().codec(A1);
export type TaggedContentC<A1 extends t.Mixed> = ReturnType<taggedContentCC<A1>["codec"]>;
export type TaggedContent<A1> = t.TypeOf<TaggedContentC<t.Type<A1>>>;

