import type { ReactElement } from "react";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import type { Action, Store } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { ErrorBoundary } from "@scripts/react/components/error/ErrorBoundary";
import { ConfigProvider } from "@scripts/react/context/Config";
import { SentryTransactionBoundary } from "@scripts/sentry-components";

import { PageWithProfiler } from "./Page";


type PageLoaderProps<State, Actions extends Action<unknown>> = {
  config: BLConfigWithLog;
  render: () => ReactElement;
  pageTitle: string;
  store: Store<State, Actions>;
};

export const PageLoader = <State, Actions extends Action<unknown>>(
  props: PageLoaderProps<State, Actions>
) => {
  return (
    <StrictMode>
      <ErrorBoundary config={props.config}>
        <SentryTransactionBoundary
          transactionName={"SsrPage"}
          appName={"Pages"}
          pageTitle={props.pageTitle}
        >
          <ConfigProvider value={props.config}>
            <Provider store={props.store}>
              <PageWithProfiler>
                {props.render()}
              </PageWithProfiler>
            </Provider>
          </ConfigProvider>
        </SentryTransactionBoundary>
      </ErrorBoundary>
    </StrictMode>
  );
};
