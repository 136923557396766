import type { PropsWithChildren, ReactElement } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { SVGString } from "*.svg";

import type { ModalDismissable } from "@scripts/react/components/modal/Modal";
import { Modal } from "@scripts/react/components/modal/Modal";
import type { KlassProp } from "@scripts/react/util/classnames";
import { klassPropO } from "@scripts/react/util/classnames";
import type { ModalOpen } from "@scripts/react/util/useModal";
import { closeModalFn } from "@scripts/react/util/useModal";

type AlertProps = {
  title: string;
  open: ModalOpen;
  icon: SVGString;
  body: ReactElement;
  klass?: KlassProp;
} & Omit<ModalDismissable, "dismissAction"> & {
  "dismissAction": O.Option<ModalDismissable["dismissAction"]>;
};

export const AlertModal = (props: PropsWithChildren<AlertProps>) => {
  const escapable = O.isNone(props.dismissAction) ? { escapable: false as const } : {};

  return <Modal
    {...klassPropO([])(props.klass)}
    {...escapable}
    id={`${props.title}-confirmation`}
    size="modal-sm"
    type="primary"
    title={props.title}
    icon={O.some(props.icon)}
    open={props.open}
    dismissAction={pipe(props.dismissAction, O.getOrElse(() => constVoid), closeModalFn)}
    body={props.body}
  />;
};


