import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const buildAmericaBondsDirectPay = {
  _tag: `BuildAmericaBondsDirectPay`,
  id: 1,
  name: `Build America Bonds Direct Pay`
} as const;

export const buildAmericaBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`BuildAmericaBondsDirectPay`)
});
export type BuildAmericaBondsDirectPayTaggedC = typeof buildAmericaBondsDirectPayTaggedC;
export type BuildAmericaBondsDirectPayTagged = t.TypeOf<BuildAmericaBondsDirectPayTaggedC>;
export type BuildAmericaBondsDirectPay = BuildAmericaBondsDirectPayTagged & typeof buildAmericaBondsDirectPay;
export const buildAmericaBondsDirectPayC = pipe(buildAmericaBondsDirectPayTaggedC, c => new t.Type<BuildAmericaBondsDirectPay, BuildAmericaBondsDirectPayTagged>(
  `BuildAmericaBondsDirectPay`,
  (u: unknown): u is BuildAmericaBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildAmericaBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildAmericaBondsDirectPay }))),
  (x: BuildAmericaBondsDirectPay): BuildAmericaBondsDirectPayTagged => ({ ...x, _tag: `BuildAmericaBondsDirectPay`}),
));
export type BuildAmericaBondsDirectPayC = typeof buildAmericaBondsDirectPayC;


export const buildAmericaBondsTaxCredit = {
  _tag: `BuildAmericaBondsTaxCredit`,
  id: 2,
  name: `Build America Bonds Tax Credit`
} as const;

export const buildAmericaBondsTaxCreditTaggedC = t.type({
  _tag: t.literal(`BuildAmericaBondsTaxCredit`)
});
export type BuildAmericaBondsTaxCreditTaggedC = typeof buildAmericaBondsTaxCreditTaggedC;
export type BuildAmericaBondsTaxCreditTagged = t.TypeOf<BuildAmericaBondsTaxCreditTaggedC>;
export type BuildAmericaBondsTaxCredit = BuildAmericaBondsTaxCreditTagged & typeof buildAmericaBondsTaxCredit;
export const buildAmericaBondsTaxCreditC = pipe(buildAmericaBondsTaxCreditTaggedC, c => new t.Type<BuildAmericaBondsTaxCredit, BuildAmericaBondsTaxCreditTagged>(
  `BuildAmericaBondsTaxCredit`,
  (u: unknown): u is BuildAmericaBondsTaxCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildAmericaBondsTaxCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildAmericaBondsTaxCredit }))),
  (x: BuildAmericaBondsTaxCredit): BuildAmericaBondsTaxCreditTagged => ({ ...x, _tag: `BuildAmericaBondsTaxCredit`}),
));
export type BuildAmericaBondsTaxCreditC = typeof buildAmericaBondsTaxCreditC;


export const newCleanRenewableEnergyBondsTaxCredit = {
  _tag: `NewCleanRenewableEnergyBondsTaxCredit`,
  id: 3,
  name: `New Clean Renewable Energy Bonds Tax Credit`
} as const;

export const newCleanRenewableEnergyBondsTaxCreditTaggedC = t.type({
  _tag: t.literal(`NewCleanRenewableEnergyBondsTaxCredit`)
});
export type NewCleanRenewableEnergyBondsTaxCreditTaggedC = typeof newCleanRenewableEnergyBondsTaxCreditTaggedC;
export type NewCleanRenewableEnergyBondsTaxCreditTagged = t.TypeOf<NewCleanRenewableEnergyBondsTaxCreditTaggedC>;
export type NewCleanRenewableEnergyBondsTaxCredit = NewCleanRenewableEnergyBondsTaxCreditTagged & typeof newCleanRenewableEnergyBondsTaxCredit;
export const newCleanRenewableEnergyBondsTaxCreditC = pipe(newCleanRenewableEnergyBondsTaxCreditTaggedC, c => new t.Type<NewCleanRenewableEnergyBondsTaxCredit, NewCleanRenewableEnergyBondsTaxCreditTagged>(
  `NewCleanRenewableEnergyBondsTaxCredit`,
  (u: unknown): u is NewCleanRenewableEnergyBondsTaxCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewCleanRenewableEnergyBondsTaxCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...newCleanRenewableEnergyBondsTaxCredit }))),
  (x: NewCleanRenewableEnergyBondsTaxCredit): NewCleanRenewableEnergyBondsTaxCreditTagged => ({ ...x, _tag: `NewCleanRenewableEnergyBondsTaxCredit`}),
));
export type NewCleanRenewableEnergyBondsTaxCreditC = typeof newCleanRenewableEnergyBondsTaxCreditC;


export const newCleanRenewableEnergyBondsDirectPay = {
  _tag: `NewCleanRenewableEnergyBondsDirectPay`,
  id: 4,
  name: `New Clean Renewable Energy Bonds Direct Pay`
} as const;

export const newCleanRenewableEnergyBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`NewCleanRenewableEnergyBondsDirectPay`)
});
export type NewCleanRenewableEnergyBondsDirectPayTaggedC = typeof newCleanRenewableEnergyBondsDirectPayTaggedC;
export type NewCleanRenewableEnergyBondsDirectPayTagged = t.TypeOf<NewCleanRenewableEnergyBondsDirectPayTaggedC>;
export type NewCleanRenewableEnergyBondsDirectPay = NewCleanRenewableEnergyBondsDirectPayTagged & typeof newCleanRenewableEnergyBondsDirectPay;
export const newCleanRenewableEnergyBondsDirectPayC = pipe(newCleanRenewableEnergyBondsDirectPayTaggedC, c => new t.Type<NewCleanRenewableEnergyBondsDirectPay, NewCleanRenewableEnergyBondsDirectPayTagged>(
  `NewCleanRenewableEnergyBondsDirectPay`,
  (u: unknown): u is NewCleanRenewableEnergyBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewCleanRenewableEnergyBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...newCleanRenewableEnergyBondsDirectPay }))),
  (x: NewCleanRenewableEnergyBondsDirectPay): NewCleanRenewableEnergyBondsDirectPayTagged => ({ ...x, _tag: `NewCleanRenewableEnergyBondsDirectPay`}),
));
export type NewCleanRenewableEnergyBondsDirectPayC = typeof newCleanRenewableEnergyBondsDirectPayC;


export const qualifiedEnergyConservationBondsTaxCredit = {
  _tag: `QualifiedEnergyConservationBondsTaxCredit`,
  id: 5,
  name: `Qualified Energy Conservation Bonds Tax Credit`
} as const;

export const qualifiedEnergyConservationBondsTaxCreditTaggedC = t.type({
  _tag: t.literal(`QualifiedEnergyConservationBondsTaxCredit`)
});
export type QualifiedEnergyConservationBondsTaxCreditTaggedC = typeof qualifiedEnergyConservationBondsTaxCreditTaggedC;
export type QualifiedEnergyConservationBondsTaxCreditTagged = t.TypeOf<QualifiedEnergyConservationBondsTaxCreditTaggedC>;
export type QualifiedEnergyConservationBondsTaxCredit = QualifiedEnergyConservationBondsTaxCreditTagged & typeof qualifiedEnergyConservationBondsTaxCredit;
export const qualifiedEnergyConservationBondsTaxCreditC = pipe(qualifiedEnergyConservationBondsTaxCreditTaggedC, c => new t.Type<QualifiedEnergyConservationBondsTaxCredit, QualifiedEnergyConservationBondsTaxCreditTagged>(
  `QualifiedEnergyConservationBondsTaxCredit`,
  (u: unknown): u is QualifiedEnergyConservationBondsTaxCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedEnergyConservationBondsTaxCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedEnergyConservationBondsTaxCredit }))),
  (x: QualifiedEnergyConservationBondsTaxCredit): QualifiedEnergyConservationBondsTaxCreditTagged => ({ ...x, _tag: `QualifiedEnergyConservationBondsTaxCredit`}),
));
export type QualifiedEnergyConservationBondsTaxCreditC = typeof qualifiedEnergyConservationBondsTaxCreditC;


export const qualifiedEnergyConservationBondsDirectPay = {
  _tag: `QualifiedEnergyConservationBondsDirectPay`,
  id: 6,
  name: `Qualified Energy Conservation Bonds Direct Pay`
} as const;

export const qualifiedEnergyConservationBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`QualifiedEnergyConservationBondsDirectPay`)
});
export type QualifiedEnergyConservationBondsDirectPayTaggedC = typeof qualifiedEnergyConservationBondsDirectPayTaggedC;
export type QualifiedEnergyConservationBondsDirectPayTagged = t.TypeOf<QualifiedEnergyConservationBondsDirectPayTaggedC>;
export type QualifiedEnergyConservationBondsDirectPay = QualifiedEnergyConservationBondsDirectPayTagged & typeof qualifiedEnergyConservationBondsDirectPay;
export const qualifiedEnergyConservationBondsDirectPayC = pipe(qualifiedEnergyConservationBondsDirectPayTaggedC, c => new t.Type<QualifiedEnergyConservationBondsDirectPay, QualifiedEnergyConservationBondsDirectPayTagged>(
  `QualifiedEnergyConservationBondsDirectPay`,
  (u: unknown): u is QualifiedEnergyConservationBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedEnergyConservationBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedEnergyConservationBondsDirectPay }))),
  (x: QualifiedEnergyConservationBondsDirectPay): QualifiedEnergyConservationBondsDirectPayTagged => ({ ...x, _tag: `QualifiedEnergyConservationBondsDirectPay`}),
));
export type QualifiedEnergyConservationBondsDirectPayC = typeof qualifiedEnergyConservationBondsDirectPayC;


export const qualifiedSchoolConstructionBondsTaxCredit = {
  _tag: `QualifiedSchoolConstructionBondsTaxCredit`,
  id: 7,
  name: `Qualified School Construction Bonds Tax Credit`
} as const;

export const qualifiedSchoolConstructionBondsTaxCreditTaggedC = t.type({
  _tag: t.literal(`QualifiedSchoolConstructionBondsTaxCredit`)
});
export type QualifiedSchoolConstructionBondsTaxCreditTaggedC = typeof qualifiedSchoolConstructionBondsTaxCreditTaggedC;
export type QualifiedSchoolConstructionBondsTaxCreditTagged = t.TypeOf<QualifiedSchoolConstructionBondsTaxCreditTaggedC>;
export type QualifiedSchoolConstructionBondsTaxCredit = QualifiedSchoolConstructionBondsTaxCreditTagged & typeof qualifiedSchoolConstructionBondsTaxCredit;
export const qualifiedSchoolConstructionBondsTaxCreditC = pipe(qualifiedSchoolConstructionBondsTaxCreditTaggedC, c => new t.Type<QualifiedSchoolConstructionBondsTaxCredit, QualifiedSchoolConstructionBondsTaxCreditTagged>(
  `QualifiedSchoolConstructionBondsTaxCredit`,
  (u: unknown): u is QualifiedSchoolConstructionBondsTaxCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedSchoolConstructionBondsTaxCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedSchoolConstructionBondsTaxCredit }))),
  (x: QualifiedSchoolConstructionBondsTaxCredit): QualifiedSchoolConstructionBondsTaxCreditTagged => ({ ...x, _tag: `QualifiedSchoolConstructionBondsTaxCredit`}),
));
export type QualifiedSchoolConstructionBondsTaxCreditC = typeof qualifiedSchoolConstructionBondsTaxCreditC;


export const qualifiedSchoolConstructionBondsDirectPay = {
  _tag: `QualifiedSchoolConstructionBondsDirectPay`,
  id: 8,
  name: `Qualified  School Construction Bonds Direct Pay`
} as const;

export const qualifiedSchoolConstructionBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`QualifiedSchoolConstructionBondsDirectPay`)
});
export type QualifiedSchoolConstructionBondsDirectPayTaggedC = typeof qualifiedSchoolConstructionBondsDirectPayTaggedC;
export type QualifiedSchoolConstructionBondsDirectPayTagged = t.TypeOf<QualifiedSchoolConstructionBondsDirectPayTaggedC>;
export type QualifiedSchoolConstructionBondsDirectPay = QualifiedSchoolConstructionBondsDirectPayTagged & typeof qualifiedSchoolConstructionBondsDirectPay;
export const qualifiedSchoolConstructionBondsDirectPayC = pipe(qualifiedSchoolConstructionBondsDirectPayTaggedC, c => new t.Type<QualifiedSchoolConstructionBondsDirectPay, QualifiedSchoolConstructionBondsDirectPayTagged>(
  `QualifiedSchoolConstructionBondsDirectPay`,
  (u: unknown): u is QualifiedSchoolConstructionBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedSchoolConstructionBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedSchoolConstructionBondsDirectPay }))),
  (x: QualifiedSchoolConstructionBondsDirectPay): QualifiedSchoolConstructionBondsDirectPayTagged => ({ ...x, _tag: `QualifiedSchoolConstructionBondsDirectPay`}),
));
export type QualifiedSchoolConstructionBondsDirectPayC = typeof qualifiedSchoolConstructionBondsDirectPayC;


export const qualifiedZoneAcademyBondsTaxCredit = {
  _tag: `QualifiedZoneAcademyBondsTaxCredit`,
  id: 9,
  name: `Qualified Zone Academy Bonds Tax Credit`
} as const;

export const qualifiedZoneAcademyBondsTaxCreditTaggedC = t.type({
  _tag: t.literal(`QualifiedZoneAcademyBondsTaxCredit`)
});
export type QualifiedZoneAcademyBondsTaxCreditTaggedC = typeof qualifiedZoneAcademyBondsTaxCreditTaggedC;
export type QualifiedZoneAcademyBondsTaxCreditTagged = t.TypeOf<QualifiedZoneAcademyBondsTaxCreditTaggedC>;
export type QualifiedZoneAcademyBondsTaxCredit = QualifiedZoneAcademyBondsTaxCreditTagged & typeof qualifiedZoneAcademyBondsTaxCredit;
export const qualifiedZoneAcademyBondsTaxCreditC = pipe(qualifiedZoneAcademyBondsTaxCreditTaggedC, c => new t.Type<QualifiedZoneAcademyBondsTaxCredit, QualifiedZoneAcademyBondsTaxCreditTagged>(
  `QualifiedZoneAcademyBondsTaxCredit`,
  (u: unknown): u is QualifiedZoneAcademyBondsTaxCredit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedZoneAcademyBondsTaxCredit> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedZoneAcademyBondsTaxCredit }))),
  (x: QualifiedZoneAcademyBondsTaxCredit): QualifiedZoneAcademyBondsTaxCreditTagged => ({ ...x, _tag: `QualifiedZoneAcademyBondsTaxCredit`}),
));
export type QualifiedZoneAcademyBondsTaxCreditC = typeof qualifiedZoneAcademyBondsTaxCreditC;


export const qualifiedZoneAcademyBondsDirectPay = {
  _tag: `QualifiedZoneAcademyBondsDirectPay`,
  id: 10,
  name: `Qualified Zone Academy Bonds Direct Pay`
} as const;

export const qualifiedZoneAcademyBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`QualifiedZoneAcademyBondsDirectPay`)
});
export type QualifiedZoneAcademyBondsDirectPayTaggedC = typeof qualifiedZoneAcademyBondsDirectPayTaggedC;
export type QualifiedZoneAcademyBondsDirectPayTagged = t.TypeOf<QualifiedZoneAcademyBondsDirectPayTaggedC>;
export type QualifiedZoneAcademyBondsDirectPay = QualifiedZoneAcademyBondsDirectPayTagged & typeof qualifiedZoneAcademyBondsDirectPay;
export const qualifiedZoneAcademyBondsDirectPayC = pipe(qualifiedZoneAcademyBondsDirectPayTaggedC, c => new t.Type<QualifiedZoneAcademyBondsDirectPay, QualifiedZoneAcademyBondsDirectPayTagged>(
  `QualifiedZoneAcademyBondsDirectPay`,
  (u: unknown): u is QualifiedZoneAcademyBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QualifiedZoneAcademyBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...qualifiedZoneAcademyBondsDirectPay }))),
  (x: QualifiedZoneAcademyBondsDirectPay): QualifiedZoneAcademyBondsDirectPayTagged => ({ ...x, _tag: `QualifiedZoneAcademyBondsDirectPay`}),
));
export type QualifiedZoneAcademyBondsDirectPayC = typeof qualifiedZoneAcademyBondsDirectPayC;


export const recoveryZoneEconDevBuildAmericaBondsDirectPay = {
  _tag: `RecoveryZoneEconDevBuildAmericaBondsDirectPay`,
  id: 11,
  name: `Recovery Zone Econ Dev Build America Bonds Direct Pay`
} as const;

export const recoveryZoneEconDevBuildAmericaBondsDirectPayTaggedC = t.type({
  _tag: t.literal(`RecoveryZoneEconDevBuildAmericaBondsDirectPay`)
});
export type RecoveryZoneEconDevBuildAmericaBondsDirectPayTaggedC = typeof recoveryZoneEconDevBuildAmericaBondsDirectPayTaggedC;
export type RecoveryZoneEconDevBuildAmericaBondsDirectPayTagged = t.TypeOf<RecoveryZoneEconDevBuildAmericaBondsDirectPayTaggedC>;
export type RecoveryZoneEconDevBuildAmericaBondsDirectPay = RecoveryZoneEconDevBuildAmericaBondsDirectPayTagged & typeof recoveryZoneEconDevBuildAmericaBondsDirectPay;
export const recoveryZoneEconDevBuildAmericaBondsDirectPayC = pipe(recoveryZoneEconDevBuildAmericaBondsDirectPayTaggedC, c => new t.Type<RecoveryZoneEconDevBuildAmericaBondsDirectPay, RecoveryZoneEconDevBuildAmericaBondsDirectPayTagged>(
  `RecoveryZoneEconDevBuildAmericaBondsDirectPay`,
  (u: unknown): u is RecoveryZoneEconDevBuildAmericaBondsDirectPay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RecoveryZoneEconDevBuildAmericaBondsDirectPay> => pipe(c.decode(u), E.map(x => ({ ...x, ...recoveryZoneEconDevBuildAmericaBondsDirectPay }))),
  (x: RecoveryZoneEconDevBuildAmericaBondsDirectPay): RecoveryZoneEconDevBuildAmericaBondsDirectPayTagged => ({ ...x, _tag: `RecoveryZoneEconDevBuildAmericaBondsDirectPay`}),
));
export type RecoveryZoneEconDevBuildAmericaBondsDirectPayC = typeof recoveryZoneEconDevBuildAmericaBondsDirectPayC;


export const recoveryZoneFacilitiesBonds = {
  _tag: `RecoveryZoneFacilitiesBonds`,
  id: 12,
  name: `Recovery Zone Facilities Bonds`
} as const;

export const recoveryZoneFacilitiesBondsTaggedC = t.type({
  _tag: t.literal(`RecoveryZoneFacilitiesBonds`)
});
export type RecoveryZoneFacilitiesBondsTaggedC = typeof recoveryZoneFacilitiesBondsTaggedC;
export type RecoveryZoneFacilitiesBondsTagged = t.TypeOf<RecoveryZoneFacilitiesBondsTaggedC>;
export type RecoveryZoneFacilitiesBonds = RecoveryZoneFacilitiesBondsTagged & typeof recoveryZoneFacilitiesBonds;
export const recoveryZoneFacilitiesBondsC = pipe(recoveryZoneFacilitiesBondsTaggedC, c => new t.Type<RecoveryZoneFacilitiesBonds, RecoveryZoneFacilitiesBondsTagged>(
  `RecoveryZoneFacilitiesBonds`,
  (u: unknown): u is RecoveryZoneFacilitiesBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RecoveryZoneFacilitiesBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...recoveryZoneFacilitiesBonds }))),
  (x: RecoveryZoneFacilitiesBonds): RecoveryZoneFacilitiesBondsTagged => ({ ...x, _tag: `RecoveryZoneFacilitiesBonds`}),
));
export type RecoveryZoneFacilitiesBondsC = typeof recoveryZoneFacilitiesBondsC;


export const tribalEconomicDevelopmentBonds = {
  _tag: `TribalEconomicDevelopmentBonds`,
  id: 13,
  name: `Tribal Economic Development Bonds`
} as const;

export const tribalEconomicDevelopmentBondsTaggedC = t.type({
  _tag: t.literal(`TribalEconomicDevelopmentBonds`)
});
export type TribalEconomicDevelopmentBondsTaggedC = typeof tribalEconomicDevelopmentBondsTaggedC;
export type TribalEconomicDevelopmentBondsTagged = t.TypeOf<TribalEconomicDevelopmentBondsTaggedC>;
export type TribalEconomicDevelopmentBonds = TribalEconomicDevelopmentBondsTagged & typeof tribalEconomicDevelopmentBonds;
export const tribalEconomicDevelopmentBondsC = pipe(tribalEconomicDevelopmentBondsTaggedC, c => new t.Type<TribalEconomicDevelopmentBonds, TribalEconomicDevelopmentBondsTagged>(
  `TribalEconomicDevelopmentBonds`,
  (u: unknown): u is TribalEconomicDevelopmentBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TribalEconomicDevelopmentBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...tribalEconomicDevelopmentBonds }))),
  (x: TribalEconomicDevelopmentBonds): TribalEconomicDevelopmentBondsTagged => ({ ...x, _tag: `TribalEconomicDevelopmentBonds`}),
));
export type TribalEconomicDevelopmentBondsC = typeof tribalEconomicDevelopmentBondsC;


export const greenBonds = {
  _tag: `GreenBonds`,
  id: 14,
  name: `Green Bonds`
} as const;

export const greenBondsTaggedC = t.type({
  _tag: t.literal(`GreenBonds`)
});
export type GreenBondsTaggedC = typeof greenBondsTaggedC;
export type GreenBondsTagged = t.TypeOf<GreenBondsTaggedC>;
export type GreenBonds = GreenBondsTagged & typeof greenBonds;
export const greenBondsC = pipe(greenBondsTaggedC, c => new t.Type<GreenBonds, GreenBondsTagged>(
  `GreenBonds`,
  (u: unknown): u is GreenBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GreenBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...greenBonds }))),
  (x: GreenBonds): GreenBondsTagged => ({ ...x, _tag: `GreenBonds`}),
));
export type GreenBondsC = typeof greenBondsC;


export const municipalLiquidityFacility = {
  _tag: `MunicipalLiquidityFacility`,
  id: 15,
  name: `Municipal Liquidity Facility`
} as const;

export const municipalLiquidityFacilityTaggedC = t.type({
  _tag: t.literal(`MunicipalLiquidityFacility`)
});
export type MunicipalLiquidityFacilityTaggedC = typeof municipalLiquidityFacilityTaggedC;
export type MunicipalLiquidityFacilityTagged = t.TypeOf<MunicipalLiquidityFacilityTaggedC>;
export type MunicipalLiquidityFacility = MunicipalLiquidityFacilityTagged & typeof municipalLiquidityFacility;
export const municipalLiquidityFacilityC = pipe(municipalLiquidityFacilityTaggedC, c => new t.Type<MunicipalLiquidityFacility, MunicipalLiquidityFacilityTagged>(
  `MunicipalLiquidityFacility`,
  (u: unknown): u is MunicipalLiquidityFacility => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalLiquidityFacility> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalLiquidityFacility }))),
  (x: MunicipalLiquidityFacility): MunicipalLiquidityFacilityTagged => ({ ...x, _tag: `MunicipalLiquidityFacility`}),
));
export type MunicipalLiquidityFacilityC = typeof municipalLiquidityFacilityC;


export const propertyAssessedCleanEnergy = {
  _tag: `PropertyAssessedCleanEnergy`,
  id: 16,
  name: `Property Assessed Clean Energy`
} as const;

export const propertyAssessedCleanEnergyTaggedC = t.type({
  _tag: t.literal(`PropertyAssessedCleanEnergy`)
});
export type PropertyAssessedCleanEnergyTaggedC = typeof propertyAssessedCleanEnergyTaggedC;
export type PropertyAssessedCleanEnergyTagged = t.TypeOf<PropertyAssessedCleanEnergyTaggedC>;
export type PropertyAssessedCleanEnergy = PropertyAssessedCleanEnergyTagged & typeof propertyAssessedCleanEnergy;
export const propertyAssessedCleanEnergyC = pipe(propertyAssessedCleanEnergyTaggedC, c => new t.Type<PropertyAssessedCleanEnergy, PropertyAssessedCleanEnergyTagged>(
  `PropertyAssessedCleanEnergy`,
  (u: unknown): u is PropertyAssessedCleanEnergy => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PropertyAssessedCleanEnergy> => pipe(c.decode(u), E.map(x => ({ ...x, ...propertyAssessedCleanEnergy }))),
  (x: PropertyAssessedCleanEnergy): PropertyAssessedCleanEnergyTagged => ({ ...x, _tag: `PropertyAssessedCleanEnergy`}),
));
export type PropertyAssessedCleanEnergyC = typeof propertyAssessedCleanEnergyC;


export const allMuniTaxCreditCategoryC = [buildAmericaBondsDirectPayC, buildAmericaBondsTaxCreditC, newCleanRenewableEnergyBondsTaxCreditC, newCleanRenewableEnergyBondsDirectPayC, qualifiedEnergyConservationBondsTaxCreditC, qualifiedEnergyConservationBondsDirectPayC, qualifiedSchoolConstructionBondsTaxCreditC, qualifiedSchoolConstructionBondsDirectPayC, qualifiedZoneAcademyBondsTaxCreditC, qualifiedZoneAcademyBondsDirectPayC, recoveryZoneEconDevBuildAmericaBondsDirectPayC, recoveryZoneFacilitiesBondsC, tribalEconomicDevelopmentBondsC, greenBondsC, municipalLiquidityFacilityC, propertyAssessedCleanEnergyC] as const;
export const allMuniTaxCreditCategoryNames = [`BuildAmericaBondsDirectPay`, `BuildAmericaBondsTaxCredit`, `NewCleanRenewableEnergyBondsTaxCredit`, `NewCleanRenewableEnergyBondsDirectPay`, `QualifiedEnergyConservationBondsTaxCredit`, `QualifiedEnergyConservationBondsDirectPay`, `QualifiedSchoolConstructionBondsTaxCredit`, `QualifiedSchoolConstructionBondsDirectPay`, `QualifiedZoneAcademyBondsTaxCredit`, `QualifiedZoneAcademyBondsDirectPay`, `RecoveryZoneEconDevBuildAmericaBondsDirectPay`, `RecoveryZoneFacilitiesBonds`, `TribalEconomicDevelopmentBonds`, `GreenBonds`, `MunicipalLiquidityFacility`, `PropertyAssessedCleanEnergy`] as const;
export type MuniTaxCreditCategoryName = (typeof allMuniTaxCreditCategoryNames)[number];

export const MuniTaxCreditCategoryCU = t.union([buildAmericaBondsDirectPayC, buildAmericaBondsTaxCreditC, newCleanRenewableEnergyBondsTaxCreditC, newCleanRenewableEnergyBondsDirectPayC, qualifiedEnergyConservationBondsTaxCreditC, qualifiedEnergyConservationBondsDirectPayC, qualifiedSchoolConstructionBondsTaxCreditC, qualifiedSchoolConstructionBondsDirectPayC, qualifiedZoneAcademyBondsTaxCreditC, qualifiedZoneAcademyBondsDirectPayC, recoveryZoneEconDevBuildAmericaBondsDirectPayC, recoveryZoneFacilitiesBondsC, tribalEconomicDevelopmentBondsC, greenBondsC, municipalLiquidityFacilityC, propertyAssessedCleanEnergyC]);
export type MuniTaxCreditCategoryCU = typeof MuniTaxCreditCategoryCU;
export type MuniTaxCreditCategoryU = t.TypeOf<MuniTaxCreditCategoryCU>;

export const muniTaxCreditCategoryOrd: Ord.Ord<MuniTaxCreditCategoryU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniTaxCreditCategory = [buildAmericaBondsDirectPay, buildAmericaBondsTaxCredit, newCleanRenewableEnergyBondsTaxCredit, newCleanRenewableEnergyBondsDirectPay, qualifiedEnergyConservationBondsTaxCredit, qualifiedEnergyConservationBondsDirectPay, qualifiedSchoolConstructionBondsTaxCredit, qualifiedSchoolConstructionBondsDirectPay, qualifiedZoneAcademyBondsTaxCredit, qualifiedZoneAcademyBondsDirectPay, recoveryZoneEconDevBuildAmericaBondsDirectPay, recoveryZoneFacilitiesBonds, tribalEconomicDevelopmentBonds, greenBonds, municipalLiquidityFacility, propertyAssessedCleanEnergy] as const;
export type MuniTaxCreditCategoryMap<A> = { [K in MuniTaxCreditCategoryName]: A };


