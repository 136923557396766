import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const researchSlashAnalyst = {
  _tag: `ResearchSlashAnalyst`,
  id: 1,
  name: `Research / Analyst`
} as const;

export const researchSlashAnalystTaggedC = t.type({
  _tag: t.literal(`ResearchSlashAnalyst`)
});
export type ResearchSlashAnalystTaggedC = typeof researchSlashAnalystTaggedC;
export type ResearchSlashAnalystTagged = t.TypeOf<ResearchSlashAnalystTaggedC>;
export type ResearchSlashAnalyst = ResearchSlashAnalystTagged & typeof researchSlashAnalyst;
export const researchSlashAnalystC = pipe(researchSlashAnalystTaggedC, c => new t.Type<ResearchSlashAnalyst, ResearchSlashAnalystTagged>(
  `ResearchSlashAnalyst`,
  (u: unknown): u is ResearchSlashAnalyst => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ResearchSlashAnalyst> => pipe(c.decode(u), E.map(x => ({ ...x, ...researchSlashAnalyst }))),
  (x: ResearchSlashAnalyst): ResearchSlashAnalystTagged => ({ ...x, _tag: `ResearchSlashAnalyst`}),
));
export type ResearchSlashAnalystC = typeof researchSlashAnalystC;


export const portfolioManagerSlashTrading = {
  _tag: `PortfolioManagerSlashTrading`,
  id: 2,
  name: `Portfolio Manager / Trading`
} as const;

export const portfolioManagerSlashTradingTaggedC = t.type({
  _tag: t.literal(`PortfolioManagerSlashTrading`)
});
export type PortfolioManagerSlashTradingTaggedC = typeof portfolioManagerSlashTradingTaggedC;
export type PortfolioManagerSlashTradingTagged = t.TypeOf<PortfolioManagerSlashTradingTaggedC>;
export type PortfolioManagerSlashTrading = PortfolioManagerSlashTradingTagged & typeof portfolioManagerSlashTrading;
export const portfolioManagerSlashTradingC = pipe(portfolioManagerSlashTradingTaggedC, c => new t.Type<PortfolioManagerSlashTrading, PortfolioManagerSlashTradingTagged>(
  `PortfolioManagerSlashTrading`,
  (u: unknown): u is PortfolioManagerSlashTrading => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PortfolioManagerSlashTrading> => pipe(c.decode(u), E.map(x => ({ ...x, ...portfolioManagerSlashTrading }))),
  (x: PortfolioManagerSlashTrading): PortfolioManagerSlashTradingTagged => ({ ...x, _tag: `PortfolioManagerSlashTrading`}),
));
export type PortfolioManagerSlashTradingC = typeof portfolioManagerSlashTradingC;


export const registeredInvestmentAdvisorSlashWealthManagement = {
  _tag: `RegisteredInvestmentAdvisorSlashWealthManagement`,
  id: 3,
  name: `Registered Investment Advisor / Wealth Management`
} as const;

export const registeredInvestmentAdvisorSlashWealthManagementTaggedC = t.type({
  _tag: t.literal(`RegisteredInvestmentAdvisorSlashWealthManagement`)
});
export type RegisteredInvestmentAdvisorSlashWealthManagementTaggedC = typeof registeredInvestmentAdvisorSlashWealthManagementTaggedC;
export type RegisteredInvestmentAdvisorSlashWealthManagementTagged = t.TypeOf<RegisteredInvestmentAdvisorSlashWealthManagementTaggedC>;
export type RegisteredInvestmentAdvisorSlashWealthManagement = RegisteredInvestmentAdvisorSlashWealthManagementTagged & typeof registeredInvestmentAdvisorSlashWealthManagement;
export const registeredInvestmentAdvisorSlashWealthManagementC = pipe(registeredInvestmentAdvisorSlashWealthManagementTaggedC, c => new t.Type<RegisteredInvestmentAdvisorSlashWealthManagement, RegisteredInvestmentAdvisorSlashWealthManagementTagged>(
  `RegisteredInvestmentAdvisorSlashWealthManagement`,
  (u: unknown): u is RegisteredInvestmentAdvisorSlashWealthManagement => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RegisteredInvestmentAdvisorSlashWealthManagement> => pipe(c.decode(u), E.map(x => ({ ...x, ...registeredInvestmentAdvisorSlashWealthManagement }))),
  (x: RegisteredInvestmentAdvisorSlashWealthManagement): RegisteredInvestmentAdvisorSlashWealthManagementTagged => ({ ...x, _tag: `RegisteredInvestmentAdvisorSlashWealthManagement`}),
));
export type RegisteredInvestmentAdvisorSlashWealthManagementC = typeof registeredInvestmentAdvisorSlashWealthManagementC;


export const allInvestorTypeC = [researchSlashAnalystC, portfolioManagerSlashTradingC, registeredInvestmentAdvisorSlashWealthManagementC] as const;
export const allInvestorTypeNames = [`ResearchSlashAnalyst`, `PortfolioManagerSlashTrading`, `RegisteredInvestmentAdvisorSlashWealthManagement`] as const;
export type InvestorTypeName = (typeof allInvestorTypeNames)[number];

export const InvestorTypeCU = t.union([researchSlashAnalystC, portfolioManagerSlashTradingC, registeredInvestmentAdvisorSlashWealthManagementC]);
export type InvestorTypeCU = typeof InvestorTypeCU;
export type InvestorTypeU = t.TypeOf<InvestorTypeCU>;

export const investorTypeOrd: Ord.Ord<InvestorTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allInvestorType = [researchSlashAnalyst, portfolioManagerSlashTrading, registeredInvestmentAdvisorSlashWealthManagement] as const;
export type InvestorTypeMap<A> = { [K in InvestorTypeName]: A };


