export type { Lazy, FunctionN } from "fp-ts/lib/function";
export {
  apply,
  constant,
  constFalse,
  constNull,
  constTrue,
  constUndefined,
  constVoid,
  flow,
  identity,
  pipe,
  tuple,
  untupled,
} from "fp-ts/lib/function";

import * as Fn from "fp-ts/lib/function";

import { refinementFor } from "./lib/_internal";

export type Any = (...args: ReadonlyArray<unknown>) => unknown;

/**
 * A more pipe-friendly version of `unsafeCoerce` from `fp-ts/function`
 *
 * @example
 * ```typescript
 *  import { unsafeCoerce } from "@scripts/fp-ts"
 *  import type { LocalDate } from "@scripts/codecs/localDate"
 *
 * const myLocalDate = pipe(
 *   new Date("1987-12-06"),
 *   unsafeCoerce<LocalDate>(),
 * )
 * // ExpectType: t.Branded<string, LocalDateBrand>
 * ```
 *
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export const unsafeCoerce: <B>() => <A>(a: A) => B = Fn.constant(Fn.unsafeCoerce);

/**
 * The fp-ts version of `tupled` takes a function whose
 * arguments are readonly, and returns a function whose
 * arguments are mutable.
 *
 * Since `Array` is a sub-type of `ReadonlyArray`, the
 * function returned from `tupled` no longer accepts
 * accepts readonly data as input -- which breaks
 * composition.
 *
 * This version returns a function whose arguments are
 * readonly, does not break composition.
 */

export const tupled = <A extends ReadonlyArray<unknown>, B>(f: (...a: A) => B): (a: Readonly<A>) => B =>
  a => f(...a);

/** @category refinements */
export const is = refinementFor.function;
