import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import pluralize, { singular } from "pluralize";

import type { SVGString } from "*.svg";

import * as SR from "@scripts/generated/routers/sitesRouter";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import { type DeepLink } from "@scripts/util/url";

import issuer from "@svgs/issuer.svg";
import leafIcon from "@svgs/leaf.svg";
import linkIcon from "@svgs/link.svg";
import noteIcon from "@svgs/note.svg";
import programTreeIcon from "@svgs/program-tree.svg";
import requestIcon from "@svgs/request.svg";
import switchIcon from "@svgs/switch.svg";
import tableIcon from "@svgs/table.svg";

export type DataMetaBase<A extends string> = {
  type: A;
  customDisplayName: O.Option<string>;
};

export type DataMetaSvg = {
  svg: SVGString;
};

export type UnsafeDataMetaSvg = {
  svg?: SVGString; // This is a nullable field instead of Option<SVGString> because the TS compiler was having difficulties with the underlying union of None | Some<SVGString>.
};

export type DataMetaViewable<A, SiteLinkType extends "SiteLink" | "DeepLink"> = SiteLinkType extends "DeepLink"
  ? { deepLink: DeepLink<A> }
  : { siteLink: (a: A) => UrlInterface<"GET"> };

export type DataMetaDraftable = {
  draftable: true;
};

export type DataMetaCallout = {
  calloutTitle: string;
};

export type UnsafeDataMetaIgnoreTitleCase = {
  ignoreTitleCase?: boolean;
};

export const displayName = (d: DataMetaBase<string>): string => O.getOrElse(() => d.type)(d.customDisplayName);
export const singularDisplayName = flow(displayName, singular);
export const pluralDisplayName = flow(displayName, pluralize);

export const noteMeta: DataMetaBase<"Note"> & DataMetaSvg = {
  type: "Note",
  svg: noteIcon,
  customDisplayName: O.none,
};

export const notificationsMeta: DataMetaBase<"Notifications"> & DataMetaSvg = {
  type: "Notifications",
  customDisplayName: O.some("Issuer Subscription Settings"),
  svg: issuer,
};

export const nonDraftableLinkMeta: DataMetaBase<"Link"> & DataMetaSvg = {
  type: "Link",
  customDisplayName: O.none,
  svg: linkIcon,
};

export const switchAccountsMeta: DataMetaBase<"Switch Accounts"> & DataMetaSvg = {
  type: "Switch Accounts",
  customDisplayName: O.none,
  svg: switchIcon,
};

export const logoAndColorsMeta: DataMetaBase<"Logo & Colors"> = {
  type: "Logo & Colors",
  customDisplayName: O.none,
};

export const tableMeta: DataMetaBase<"Table"> & DataMetaSvg = {
  type: "Table",
  customDisplayName: O.none,
  svg: tableIcon,
};

export const rfpsMeta: DataMetaBase<"RFPs"> & DataMetaSvg & DataMetaDraftable & UnsafeDataMetaIgnoreTitleCase & DataMetaViewable<SR.IssuersitesRfpsControllerRfpsParams, "DeepLink"> & DataMetaCallout = {
  type: "RFPs",
  customDisplayName: O.none,
  svg: requestIcon,
  draftable: true,
  deepLink: {
    type: "Basic",
    route: SR.issuersitesRfpsControllerRfps,
    selector: "anchor-rfps",
  },
  ignoreTitleCase: true,
  calloutTitle: "RFP",
};

export const rfpMeta: DataMetaBase<"RFP"> & DataMetaSvg & DataMetaDraftable & UnsafeDataMetaIgnoreTitleCase & DataMetaViewable<SR.IssuersitesRfpsControllerRfpParams, "DeepLink"> = {
  type: "RFP",
  customDisplayName: O.none,
  svg: requestIcon,
  draftable: true,
  deepLink: {
    type: "Basic",
    route: SR.issuersitesRfpsControllerRfp,
    selector: "anchor-rfps",
  },
  ignoreTitleCase: true,
};

export const programsMeta: DataMetaBase<"Programs"> & DataMetaSvg & DataMetaViewable<SR.IssuersitesBondProgramsControllerBondProgramsParams, "SiteLink"> & DataMetaDraftable & DataMetaCallout = {
  type: "Programs",
  customDisplayName: O.none,
  siteLink: SR.issuersitesBondProgramsControllerBondPrograms,
  svg: programTreeIcon,
  draftable: true,
  calloutTitle: "Program",
};

export const programMeta: DataMetaBase<"Program"> & DataMetaSvg & DataMetaViewable<SR.IssuersitesBondProgramsControllerBondProgramParams, "SiteLink"> & DataMetaDraftable = {
  type: "Program",
  customDisplayName: O.none,
  siteLink: SR.issuersitesBondProgramsControllerBondProgram,
  svg: programTreeIcon,
  draftable: true,
};

export const esgProgramMeta: DataMetaBase<"ESG Program"> & DataMetaSvg & DataMetaViewable<SR.IssuersitesAboutControllerEsgProgramParams, "SiteLink"> = {
  type: "ESG Program",
  customDisplayName: O.none,
  siteLink: SR.issuersitesAboutControllerEsgProgram,
  svg: leafIcon,
};

export const relatedIssuersMeta: DataMetaBase<"Related Issuers"> = {
  type: "Related Issuers",
  customDisplayName: O.none,
};

export const aboutMeta: DataMetaBase<"About"> = {
  type: "About",
  customDisplayName: O.none,
};
