import * as t from "io-ts";


export const allC = t.literal("all");
export const anyC = t.literal("any");
export const noneC = t.literal("none");

export const filterOperationTypeC = t.union([allC, anyC, noneC]);
export type FilterOperationType = t.TypeOf<typeof filterOperationTypeC>;


export const defaultOperationType = anyC.value;
