import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";
import * as t from "io-ts";

import type { LogErrors, LogLevel, LogValidation } from "./log";
import { errorsToLogErrors, toLogErrors } from "./log";

export const parseJson = (s: string): LogValidation<unknown> =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  E.tryCatch(() => JSON.parse(s), toLogErrors("fatal", "JSON.parse failed", s));

export const parseAsJsonAndDecode = <A, O>(decoder: ((u: unknown) => t.Validation<A>) | t.Type<A, O>, level: LogLevel, message: string) => (s: string): LogValidation<A> =>
  pipe(
    E.of<LogErrors, string>(s),
    E.filterOrElse(_ => _.length > 0, _ => toLogErrors("info", `Attempted to parse an empty JSON string -- ${message}`, _)()),
    E.chain(parseJson),
    E.chain(flow(
      decoder instanceof t.Type ? decoder.decode : decoder,
      E.mapLeft(errorsToLogErrors(level, `Attempted to decode JSON -- ${message}`))
    ))
  );
