import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const day = {
  _tag: `Day`,
  id: 1
} as const;

export const dayTaggedC = t.type({
  _tag: t.literal(`Day`)
});
export type DayTaggedC = typeof dayTaggedC;
export type DayTagged = t.TypeOf<DayTaggedC>;
export type Day = DayTagged & typeof day;
export const dayC = pipe(dayTaggedC, c => new t.Type<Day, DayTagged>(
  `Day`,
  (u: unknown): u is Day => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Day> => pipe(c.decode(u), E.map(x => ({ ...x, ...day }))),
  (x: Day): DayTagged => ({ ...x, _tag: `Day`}),
));
export type DayC = typeof dayC;


export const quarter = {
  _tag: `Quarter`,
  id: 2
} as const;

export const quarterTaggedC = t.type({
  _tag: t.literal(`Quarter`)
});
export type QuarterTaggedC = typeof quarterTaggedC;
export type QuarterTagged = t.TypeOf<QuarterTaggedC>;
export type Quarter = QuarterTagged & typeof quarter;
export const quarterC = pipe(quarterTaggedC, c => new t.Type<Quarter, QuarterTagged>(
  `Quarter`,
  (u: unknown): u is Quarter => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Quarter> => pipe(c.decode(u), E.map(x => ({ ...x, ...quarter }))),
  (x: Quarter): QuarterTagged => ({ ...x, _tag: `Quarter`}),
));
export type QuarterC = typeof quarterC;


export const month = {
  _tag: `Month`,
  id: 3
} as const;

export const monthTaggedC = t.type({
  _tag: t.literal(`Month`)
});
export type MonthTaggedC = typeof monthTaggedC;
export type MonthTagged = t.TypeOf<MonthTaggedC>;
export type Month = MonthTagged & typeof month;
export const monthC = pipe(monthTaggedC, c => new t.Type<Month, MonthTagged>(
  `Month`,
  (u: unknown): u is Month => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Month> => pipe(c.decode(u), E.map(x => ({ ...x, ...month }))),
  (x: Month): MonthTagged => ({ ...x, _tag: `Month`}),
));
export type MonthC = typeof monthC;


export const year = {
  _tag: `Year`,
  id: 4
} as const;

export const yearTaggedC = t.type({
  _tag: t.literal(`Year`)
});
export type YearTaggedC = typeof yearTaggedC;
export type YearTagged = t.TypeOf<YearTaggedC>;
export type Year = YearTagged & typeof year;
export const yearC = pipe(yearTaggedC, c => new t.Type<Year, YearTagged>(
  `Year`,
  (u: unknown): u is Year => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Year> => pipe(c.decode(u), E.map(x => ({ ...x, ...year }))),
  (x: Year): YearTagged => ({ ...x, _tag: `Year`}),
));
export type YearC = typeof yearC;


export const week = {
  _tag: `Week`,
  id: 5
} as const;

export const weekTaggedC = t.type({
  _tag: t.literal(`Week`)
});
export type WeekTaggedC = typeof weekTaggedC;
export type WeekTagged = t.TypeOf<WeekTaggedC>;
export type Week = WeekTagged & typeof week;
export const weekC = pipe(weekTaggedC, c => new t.Type<Week, WeekTagged>(
  `Week`,
  (u: unknown): u is Week => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Week> => pipe(c.decode(u), E.map(x => ({ ...x, ...week }))),
  (x: Week): WeekTagged => ({ ...x, _tag: `Week`}),
));
export type WeekC = typeof weekC;


export const allDateQualifierC = [dayC, quarterC, monthC, yearC, weekC] as const;
export const allDateQualifierNames = [`Day`, `Quarter`, `Month`, `Year`, `Week`] as const;
export type DateQualifierName = (typeof allDateQualifierNames)[number];

export const DateQualifierCU = t.union([dayC, quarterC, monthC, yearC, weekC]);
export type DateQualifierCU = typeof DateQualifierCU;
export type DateQualifierU = t.TypeOf<DateQualifierCU>;

export const dateQualifierOrd: Ord.Ord<DateQualifierU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateQualifier = [day, quarter, month, year, week] as const;
export type DateQualifierMap<A> = { [K in DateQualifierName]: A };


export const dayToDay = {
  _tag: `DayToDay`,
  format: `Day to Day`,
  name: `dayToDay`
} as const;

export const dayToDayTaggedC = t.type({
  _tag: t.literal(`DayToDay`)
});
export type DayToDayTaggedC = typeof dayToDayTaggedC;
export type DayToDayTagged = t.TypeOf<DayToDayTaggedC>;
export type DayToDay = DayToDayTagged & typeof dayToDay;
export const dayToDayC = pipe(dayToDayTaggedC, c => new t.Type<DayToDay, DayToDayTagged>(
  `DayToDay`,
  (u: unknown): u is DayToDay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DayToDay> => pipe(c.decode(u), E.map(x => ({ ...x, ...dayToDay }))),
  (x: DayToDay): DayToDayTagged => ({ ...x, _tag: `DayToDay`}),
));
export type DayToDayC = typeof dayToDayC;


export const ongoing = {
  _tag: `Ongoing`,
  format: `Ongoing`,
  name: `ongoing`
} as const;

export const ongoingTaggedC = t.type({
  _tag: t.literal(`Ongoing`)
});
export type OngoingTaggedC = typeof ongoingTaggedC;
export type OngoingTagged = t.TypeOf<OngoingTaggedC>;
export type Ongoing = OngoingTagged & typeof ongoing;
export const ongoingC = pipe(ongoingTaggedC, c => new t.Type<Ongoing, OngoingTagged>(
  `Ongoing`,
  (u: unknown): u is Ongoing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ongoing> => pipe(c.decode(u), E.map(x => ({ ...x, ...ongoing }))),
  (x: Ongoing): OngoingTagged => ({ ...x, _tag: `Ongoing`}),
));
export type OngoingC = typeof ongoingC;


export const allDateQualifierNDC = [dayToDayC, ongoingC] as const;
export const allDateQualifierNDNames = [`DayToDay`, `Ongoing`] as const;
export type DateQualifierNDName = (typeof allDateQualifierNDNames)[number];

export const DateQualifierNDCU = t.union([dayToDayC, ongoingC]);
export type DateQualifierNDCU = typeof DateQualifierNDCU;
export type DateQualifierNDU = t.TypeOf<DateQualifierNDCU>;

export const dateQualifierNDOrd: Ord.Ord<DateQualifierNDU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateQualifierND = [dayToDay, ongoing] as const;
export type DateQualifierNDMap<A> = { [K in DateQualifierNDName]: A };


