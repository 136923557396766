import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const privateLoan = {
  _tag: `PrivateLoan`,
  id: 1,
  name: `Private Loan`
} as const;

export const privateLoanTaggedC = t.type({
  _tag: t.literal(`PrivateLoan`)
});
export type PrivateLoanTaggedC = typeof privateLoanTaggedC;
export type PrivateLoanTagged = t.TypeOf<PrivateLoanTaggedC>;
export type PrivateLoan = PrivateLoanTagged & typeof privateLoan;
export const privateLoanC = pipe(privateLoanTaggedC, c => new t.Type<PrivateLoan, PrivateLoanTagged>(
  `PrivateLoan`,
  (u: unknown): u is PrivateLoan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateLoan> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateLoan }))),
  (x: PrivateLoan): PrivateLoanTagged => ({ ...x, _tag: `PrivateLoan`}),
));
export type PrivateLoanC = typeof privateLoanC;


export const creditFacilities = {
  _tag: `CreditFacilities`,
  id: 2,
  name: `Credit Facilities`
} as const;

export const creditFacilitiesTaggedC = t.type({
  _tag: t.literal(`CreditFacilities`)
});
export type CreditFacilitiesTaggedC = typeof creditFacilitiesTaggedC;
export type CreditFacilitiesTagged = t.TypeOf<CreditFacilitiesTaggedC>;
export type CreditFacilities = CreditFacilitiesTagged & typeof creditFacilities;
export const creditFacilitiesC = pipe(creditFacilitiesTaggedC, c => new t.Type<CreditFacilities, CreditFacilitiesTagged>(
  `CreditFacilities`,
  (u: unknown): u is CreditFacilities => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CreditFacilities> => pipe(c.decode(u), E.map(x => ({ ...x, ...creditFacilities }))),
  (x: CreditFacilities): CreditFacilitiesTagged => ({ ...x, _tag: `CreditFacilities`}),
));
export type CreditFacilitiesC = typeof creditFacilitiesC;


export const underwriterServices = {
  _tag: `UnderwriterServices`,
  id: 3,
  name: `Underwriter Services`
} as const;

export const underwriterServicesTaggedC = t.type({
  _tag: t.literal(`UnderwriterServices`)
});
export type UnderwriterServicesTaggedC = typeof underwriterServicesTaggedC;
export type UnderwriterServicesTagged = t.TypeOf<UnderwriterServicesTaggedC>;
export type UnderwriterServices = UnderwriterServicesTagged & typeof underwriterServices;
export const underwriterServicesC = pipe(underwriterServicesTaggedC, c => new t.Type<UnderwriterServices, UnderwriterServicesTagged>(
  `UnderwriterServices`,
  (u: unknown): u is UnderwriterServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnderwriterServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...underwriterServices }))),
  (x: UnderwriterServices): UnderwriterServicesTagged => ({ ...x, _tag: `UnderwriterServices`}),
));
export type UnderwriterServicesC = typeof underwriterServicesC;


export const financialAdvisoryServices = {
  _tag: `FinancialAdvisoryServices`,
  id: 4,
  name: `Financial Advisory Services`
} as const;

export const financialAdvisoryServicesTaggedC = t.type({
  _tag: t.literal(`FinancialAdvisoryServices`)
});
export type FinancialAdvisoryServicesTaggedC = typeof financialAdvisoryServicesTaggedC;
export type FinancialAdvisoryServicesTagged = t.TypeOf<FinancialAdvisoryServicesTaggedC>;
export type FinancialAdvisoryServices = FinancialAdvisoryServicesTagged & typeof financialAdvisoryServices;
export const financialAdvisoryServicesC = pipe(financialAdvisoryServicesTaggedC, c => new t.Type<FinancialAdvisoryServices, FinancialAdvisoryServicesTagged>(
  `FinancialAdvisoryServices`,
  (u: unknown): u is FinancialAdvisoryServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FinancialAdvisoryServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...financialAdvisoryServices }))),
  (x: FinancialAdvisoryServices): FinancialAdvisoryServicesTagged => ({ ...x, _tag: `FinancialAdvisoryServices`}),
));
export type FinancialAdvisoryServicesC = typeof financialAdvisoryServicesC;


export const bankingServices = {
  _tag: `BankingServices`,
  id: 5,
  name: `Banking Services`
} as const;

export const bankingServicesTaggedC = t.type({
  _tag: t.literal(`BankingServices`)
});
export type BankingServicesTaggedC = typeof bankingServicesTaggedC;
export type BankingServicesTagged = t.TypeOf<BankingServicesTaggedC>;
export type BankingServices = BankingServicesTagged & typeof bankingServices;
export const bankingServicesC = pipe(bankingServicesTaggedC, c => new t.Type<BankingServices, BankingServicesTagged>(
  `BankingServices`,
  (u: unknown): u is BankingServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankingServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankingServices }))),
  (x: BankingServices): BankingServicesTagged => ({ ...x, _tag: `BankingServices`}),
));
export type BankingServicesC = typeof bankingServicesC;


export const bondCounselServices = {
  _tag: `BondCounselServices`,
  id: 6,
  name: `Bond Counsel Services`
} as const;

export const bondCounselServicesTaggedC = t.type({
  _tag: t.literal(`BondCounselServices`)
});
export type BondCounselServicesTaggedC = typeof bondCounselServicesTaggedC;
export type BondCounselServicesTagged = t.TypeOf<BondCounselServicesTaggedC>;
export type BondCounselServices = BondCounselServicesTagged & typeof bondCounselServices;
export const bondCounselServicesC = pipe(bondCounselServicesTaggedC, c => new t.Type<BondCounselServices, BondCounselServicesTagged>(
  `BondCounselServices`,
  (u: unknown): u is BondCounselServices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondCounselServices> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondCounselServices }))),
  (x: BondCounselServices): BondCounselServicesTagged => ({ ...x, _tag: `BondCounselServices`}),
));
export type BondCounselServicesC = typeof bondCounselServicesC;


export const other = {
  _tag: `Other`,
  id: 7,
  name: `Other`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const allTypeOfServicingC = [privateLoanC, creditFacilitiesC, underwriterServicesC, financialAdvisoryServicesC, bankingServicesC, bondCounselServicesC, otherC] as const;
export const allTypeOfServicingNames = [`PrivateLoan`, `CreditFacilities`, `UnderwriterServices`, `FinancialAdvisoryServices`, `BankingServices`, `BondCounselServices`, `Other`] as const;
export type TypeOfServicingName = (typeof allTypeOfServicingNames)[number];

export const TypeOfServicingCU = t.union([privateLoanC, creditFacilitiesC, underwriterServicesC, financialAdvisoryServicesC, bankingServicesC, bondCounselServicesC, otherC]);
export type TypeOfServicingCU = typeof TypeOfServicingCU;
export type TypeOfServicingU = t.TypeOf<TypeOfServicingCU>;

export const typeOfServicingOrd: Ord.Ord<TypeOfServicingU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTypeOfServicing = [privateLoan, creditFacilities, underwriterServices, financialAdvisoryServices, bankingServices, bondCounselServices, other] as const;
export type TypeOfServicingMap<A> = { [K in TypeOfServicingName]: A };


