import type { ReactNode } from "react";
import { createPortal } from "react-dom";

import { isServerSide } from "@scripts/util/ssr";

import { Empty } from "./Empty";

interface PortalProps {
  children: ReactNode;
  id: string;
}

export const Portal = (props: PortalProps) => isServerSide()
  ? <Empty />
  : createPortal(props.children, document.body, props.id);
