import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const stable = {
  _tag: `stable`,
  id: 1,
  ratingOutlook: `Stable`
} as const;

export const stableTaggedC = t.type({
  _tag: t.literal(`stable`)
});
export type StableTaggedC = typeof stableTaggedC;
export type StableTagged = t.TypeOf<StableTaggedC>;
export type Stable = StableTagged & typeof stable;
export const stableC = pipe(stableTaggedC, c => new t.Type<Stable, StableTagged>(
  `stable`,
  (u: unknown): u is Stable => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Stable> => pipe(c.decode(u), E.map(x => ({ ...x, ...stable }))),
  (x: Stable): StableTagged => ({ ...x, _tag: `stable`}),
));
export type StableC = typeof stableC;


export const positive = {
  _tag: `positive`,
  id: 2,
  ratingOutlook: `Positive`
} as const;

export const positiveTaggedC = t.type({
  _tag: t.literal(`positive`)
});
export type PositiveTaggedC = typeof positiveTaggedC;
export type PositiveTagged = t.TypeOf<PositiveTaggedC>;
export type Positive = PositiveTagged & typeof positive;
export const positiveC = pipe(positiveTaggedC, c => new t.Type<Positive, PositiveTagged>(
  `positive`,
  (u: unknown): u is Positive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Positive> => pipe(c.decode(u), E.map(x => ({ ...x, ...positive }))),
  (x: Positive): PositiveTagged => ({ ...x, _tag: `positive`}),
));
export type PositiveC = typeof positiveC;


export const negative = {
  _tag: `negative`,
  id: 3,
  ratingOutlook: `Negative`
} as const;

export const negativeTaggedC = t.type({
  _tag: t.literal(`negative`)
});
export type NegativeTaggedC = typeof negativeTaggedC;
export type NegativeTagged = t.TypeOf<NegativeTaggedC>;
export type Negative = NegativeTagged & typeof negative;
export const negativeC = pipe(negativeTaggedC, c => new t.Type<Negative, NegativeTagged>(
  `negative`,
  (u: unknown): u is Negative => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Negative> => pipe(c.decode(u), E.map(x => ({ ...x, ...negative }))),
  (x: Negative): NegativeTagged => ({ ...x, _tag: `negative`}),
));
export type NegativeC = typeof negativeC;


export const notAssigned = {
  _tag: `notAssigned`,
  id: 4,
  ratingOutlook: `Not Assigned`
} as const;

export const notAssignedTaggedC = t.type({
  _tag: t.literal(`notAssigned`)
});
export type NotAssignedTaggedC = typeof notAssignedTaggedC;
export type NotAssignedTagged = t.TypeOf<NotAssignedTaggedC>;
export type NotAssigned = NotAssignedTagged & typeof notAssigned;
export const notAssignedC = pipe(notAssignedTaggedC, c => new t.Type<NotAssigned, NotAssignedTagged>(
  `notAssigned`,
  (u: unknown): u is NotAssigned => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NotAssigned> => pipe(c.decode(u), E.map(x => ({ ...x, ...notAssigned }))),
  (x: NotAssigned): NotAssignedTagged => ({ ...x, _tag: `notAssigned`}),
));
export type NotAssignedC = typeof notAssignedC;


export const developing = {
  _tag: `developing`,
  id: 5,
  ratingOutlook: `Developing`
} as const;

export const developingTaggedC = t.type({
  _tag: t.literal(`developing`)
});
export type DevelopingTaggedC = typeof developingTaggedC;
export type DevelopingTagged = t.TypeOf<DevelopingTaggedC>;
export type Developing = DevelopingTagged & typeof developing;
export const developingC = pipe(developingTaggedC, c => new t.Type<Developing, DevelopingTagged>(
  `developing`,
  (u: unknown): u is Developing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Developing> => pipe(c.decode(u), E.map(x => ({ ...x, ...developing }))),
  (x: Developing): DevelopingTagged => ({ ...x, _tag: `developing`}),
));
export type DevelopingC = typeof developingC;


export const allRatingOutlookC = [stableC, positiveC, negativeC, notAssignedC, developingC] as const;
export const allRatingOutlookNames = [`stable`, `positive`, `negative`, `notAssigned`, `developing`] as const;
export type RatingOutlookName = (typeof allRatingOutlookNames)[number];

export const RatingOutlookCU = t.union([stableC, positiveC, negativeC, notAssignedC, developingC]);
export type RatingOutlookCU = typeof RatingOutlookCU;
export type RatingOutlookU = t.TypeOf<RatingOutlookCU>;

export const ratingOutlookOrd: Ord.Ord<RatingOutlookU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allRatingOutlook = [stable, positive, negative, notAssigned, developing] as const;
export type RatingOutlookMap<A> = { [K in RatingOutlookName]: A };


