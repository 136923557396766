import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const project = {
  _tag: `Project`,
  id: 1,
  name: `Project`
} as const;

export const projectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTaggedC = typeof projectTaggedC;
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export const projectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
));
export type ProjectC = typeof projectC;


export const program = {
  _tag: `Program`,
  id: 2,
  name: `Program`
} as const;

export const programTaggedC = t.type({
  _tag: t.literal(`Program`)
});
export type ProgramTaggedC = typeof programTaggedC;
export type ProgramTagged = t.TypeOf<ProgramTaggedC>;
export type Program = ProgramTagged & typeof program;
export const programC = pipe(programTaggedC, c => new t.Type<Program, ProgramTagged>(
  `Program`,
  (u: unknown): u is Program => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Program> => pipe(c.decode(u), E.map(x => ({ ...x, ...program }))),
  (x: Program): ProgramTagged => ({ ...x, _tag: `Program`}),
));
export type ProgramC = typeof programC;


export const other = {
  _tag: `Other`,
  id: 3,
  name: `Other`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const allIssuerSeriesTagCategoryC = [projectC, programC, otherC] as const;
export const allIssuerSeriesTagCategoryNames = [`Project`, `Program`, `Other`] as const;
export type IssuerSeriesTagCategoryName = (typeof allIssuerSeriesTagCategoryNames)[number];

export const IssuerSeriesTagCategoryCU = t.union([projectC, programC, otherC]);
export type IssuerSeriesTagCategoryCU = typeof IssuerSeriesTagCategoryCU;
export type IssuerSeriesTagCategoryU = t.TypeOf<IssuerSeriesTagCategoryCU>;

export const issuerSeriesTagCategoryOrd: Ord.Ord<IssuerSeriesTagCategoryU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allIssuerSeriesTagCategory = [project, program, other] as const;
export type IssuerSeriesTagCategoryMap<A> = { [K in IssuerSeriesTagCategoryName]: A };


