export * from "fp-ts/lib/struct";

import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RR from "fp-ts/lib/ReadonlyRecord";
import type { Refinement } from "fp-ts/lib/Refinement";
import { Ord } from "fp-ts/lib/string";

import type { PrefixWith, StripPrefix } from "./lib/_internal";
import * as I from "./lib/_internal";
import { isNotProtectedProp, isProtectedProp } from "./lib/_protectedProps";
import type { Describe, Match, Merge } from "./lib/types";

type ChooseNarrowerOf<A, B> = B extends A ? B : A extends B ? A : never;

export type Any = { [K in PropertyKey]: unknown };

export type InferKeys<S extends Match.AnyStruct>
  = S extends { readonly [_ in infer K]: any } ? K // eslint-disable-line @typescript-eslint/no-explicit-any
  : never;
export type InferValues<S extends Match.AnyStruct>
  = S extends { readonly [_ in PropertyKey]: infer V } ? V
  : never;
export type Infer<S extends Match.AnyStruct>
  = S extends { readonly [_ in infer K]: unknown } ? { [P in K]: S[P] }
  : never;
export type Without<T, K> = {
  [L in Exclude<keyof T, K>]: T[L]
};
/** @category refinements */
export const is = I.refinementFor.struct;

/**
 * When a type parameter is used to constrain a function parameter,
 * the type parameter is said to be in "contravariant position".
 *
 * When this is the case, what we're trying to determine is whether a
 * type function, let's call it `f`, is a subtype of the function argument
 * that is (eventually) provided -- let's call that function `g`.
 *
 * Somewhat counter-intuitively, the subtype/supertype relationship
 * between `f` and `g` in this case is inverted. That is to say:
 *
 * In a given context, for all `f`, `f` extends `g` if `f`:
 *
 *   1. accepts a more _general_ type of argument than `g`; and,
 *   2. returns a more _specific_ type than `g`
 *
 * In other words, a type parameter must be _wider_ than the concrete
 * argument that we give it later, and is why here we intersect `A`
 * with the widest possible struct type.
 */
export type Contravariant<A extends Match.AnyStruct> = A & Match.AnyStruct;

/** Extracts from `A` those keys which are strings */
export type PropsOf<A> = Extract<keyof A, string>;

/** @internal */
const reduceWithIndex = RR.reduceWithIndex(Ord);

/** @category constructors */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export const emptyOf = <S extends Match.AnyStruct = never>() =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  RR.empty as S;

/**
 * @category constructors
 *
 * `Struct.unit` is similar to `RR.singleton` in that it creates a record containing
 * a single key/value pair, but unlike `singleton`, `unit` returns a record whose
 * key is the string literal that is passed in (rather than widening to `string`).
 *
 * @example
 * ```typescript
 *  import { Struct, RR } from "@scripts/fp-ts/ReadonlyRecord"
 *
 *  const hasFoo = Struct.unit("foo")(1)  //=> ExpectType: { foo: number; }
 *  const b = RR.singleton("foo", 1) // => ExpectType: { [key: string]: number; }
 * ```
 */
export const unit = <K extends PropertyKey>(key: K) =>
  <V>(value: V): { [P in K]: V } =>
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    ({ [key]: value } as { [P in K]: V });

export const keys = <S extends Match.AnyStruct, K extends keyof S>(struct: S): K[] =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  Object.keys(struct).filter(k => Object.hasOwn(struct, k)) as K[];

export const values = <S extends Match.AnyStruct, K extends keyof S>(struct: S): Array<S[K]> =>
  Object.values<S[K]>(struct);

export const pick_ = <K extends PropertyKey, S extends { [k in K]: unknown }>(s: S, ...ks: ReadonlyArray<K>): Describe<Pick<S, K>> => pipe(
  ks,
  RA.filter(k => !isProtectedProp(k)),
  RA.reduce(
    emptyOf<Pick<S, K>>(),
    (acc, k) => ({
      ...acc,
      ...(k in s ? unit(k)(s[k]) : {}),
    })
  )
);

export const pick = <T extends Match.AnyStruct, P extends keyof T>(...ks: ReadonlyArray<P>) =>
  <S extends T, K extends P>(s: S): Pick<S, K> =>
    pipe(
      ks,
      RA.filter(k => !isProtectedProp(k)),
      RA.reduce(
        emptyOf<Pick<S, K>>(),
        (acc, k) => ({ ...acc, ...(k in s ? { [k]: s[k] } : {}) }),
      )
    );

export const omit_ = <K extends keyof S, S extends Any>(s: S, ...ks: ReadonlyArray<K>): Describe<Omit<S, K>> => pipe(
  keys<S, K>(s),
  RA.reduce(
    emptyOf<Omit<S, K>>(),
    (acc, k: K) => ({
      ...acc,
      ...(ks.includes(k) ? {} : { [k]: s[k] }),
    })
  )
);

export const omit = <K extends PropertyKey>(...ks: ReadonlyArray<K>) =>
  <S extends { [k in K]: unknown }>(s: S): Describe<Omit<S, K>> => omit_(s, ...ks);

export function hasKey<K extends PropertyKey>(key: K) {
  return <S extends Match.AnyStruct>(
    struct: S
  ): struct is Describe<S & { [P in K]: P extends keyof S ? S[P] : unknown }> => {
    return isNotProtectedProp(key) && is(struct) && key in struct;
  };
}

export const hasKeyOf = <V>(refinement: Refinement<unknown, V>) =>
  <K extends PropertyKey>(key: K) =>
    <S extends Match.AnyStruct>(struct: S):
      struct is Describe<S & { [P in K]: P extends keyof S ? ChooseNarrowerOf<S[P], V> : never }> =>
      hasKey(key)(struct) ? refinement(struct[key]) : false;

export type UpsertAt =
  <V, K extends string>(k: K, v: V) =>
    <S extends Match.AnyStruct>(s: S) =>
      Merge<
        { [P in keyof S]: S[P] },
        { [P in K]: V }
      >;
export const upsertAt: UpsertAt =
  (k, v) =>
    struct =>
      ({ ...struct, [k]: v });

export const curriedUpsertAt: CurriedUpsertAt = k => v => upsertAt(k, v);
export type CurriedUpsertAt =
  <K extends string>(k: K) =>
    <V>(v: V) =>
      <S extends Match.AnyStruct>(s: S) =>
        Merge<
          { [P in keyof S]: S[P] },
          { [P in K]: V }
        >;

export const merge = <B extends Any>(b: B) => <A extends Any>(a: A): A & B => ({ ...a, ...b });

/** @internal */
const mapKeys =
  <
    A extends O extends { readonly [K in keyof O]: O[K] }
    ? { readonly [K in keyof O]: O[K] }
    : never,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
    K extends keyof A & string,
    O extends object,
  >(
    // TODO: see if it's possible to infer the return type as a string literal
    fn: (k: K) => string
  ) =>
    (obj: A) =>
      pipe(
        obj,
        reduceWithIndex({}, (k: K, acc, curr: A[keyof A]) => {
          return { ...acc, ...{ [fn(k)]: curr } };
        })
      );

export type StripPrefixKeys<Pre extends string, O> = Describe<{
  readonly [K in keyof O as StripPrefix<Pre, string & K>]: O[K]
}>;
export const stripPrefixFromKeys =
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <Pre extends string>(pre: Pre) =>
    <O extends object>(obj: O): StripPrefixKeys<Pre, O> =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      pipe(obj, mapKeys(I.stripPrefix(pre))) as StripPrefixKeys<Pre, O>;

export type PrefixKeys<Pre extends string, A> = Describe<{
  readonly [K in keyof A as PrefixWith<Pre, K & string>]: A[K]
}>;
export const prefixKeys =
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
  <Pre extends string>(pre: Pre) =>
    <O extends object>(obj: O): PrefixKeys<Pre, O> =>
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      pipe(obj, mapKeys(I.prefix(pre))) as PrefixKeys<Pre, O>;
