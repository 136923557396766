import { contramap } from "fp-ts/lib/Ord";
import * as RA from "fp-ts/lib/ReadonlyArray";
import { sort } from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as s from "fp-ts/lib/string";

import type { SectorU } from "@scripts/generated/domaintables/sectors";
import { allSector } from "@scripts/generated/domaintables/sectors";

export const sectorAlphabeticalOrd = contramap((_: SectorU) => _.name)(s.Ord);

export const allSectorsAlphabetical = sort(sectorAlphabeticalOrd)(allSector);

export const allSectorsSelectOption = { label: "All Sectors", value: 0 };

export const allSectorsOptionsAlphabetical = RA.map((sector: SectorU) =>
  ({ value: sector.id, label: sector.name }))(allSectorsAlphabetical);
