import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { LocalDateC } from "../../codecs/localDate";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const reminderC = t.type({
  notifyDate: LocalDateC
});
export type ReminderC = typeof reminderC;
export type Reminder = t.TypeOf<ReminderC>;


export const document = {
  _tag: `Document`,
  name: `Document`
} as const;

export const documentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTaggedC = typeof documentTaggedC;
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export const documentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
));
export type DocumentC = typeof documentC;


export const offering = {
  _tag: `Offering`,
  name: `Offering`
} as const;

export const offeringTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTaggedC = typeof offeringTaggedC;
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export const offeringC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
));
export type OfferingC = typeof offeringC;


export const allReminderTypeC = [documentC, offeringC] as const;
export const allReminderTypeNames = [`Document`, `Offering`] as const;
export type ReminderTypeName = (typeof allReminderTypeNames)[number];

export const ReminderTypeCU = t.union([documentC, offeringC]);
export type ReminderTypeCU = typeof ReminderTypeCU;
export type ReminderTypeU = t.TypeOf<ReminderTypeCU>;

export const reminderTypeOrd: Ord.Ord<ReminderTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allReminderType = [document, offering] as const;
export type ReminderTypeMap<A> = { [K in ReminderTypeName]: A };


export const nextReminderDataC = t.type({
  notifyDate: LocalDateC,
  reminderType: ReminderTypeCU,
  displayName: t.string
});
export type NextReminderDataC = typeof nextReminderDataC;
export type NextReminderData = t.TypeOf<NextReminderDataC>;


