import { withIdC as imported288_withIdC, withIdC as imported292_withIdC, withIdC as imported287_withIdC, withIdC as imported295_withIdC, withIdC as imported291_withIdC, withIdC as imported290_withIdC, withIdC as imported293_withIdC, withIdC as imported294_withIdC, withIdC as imported289_withIdC } from "./threadThrough";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { DebtTypeCU as imported2_DebtTypeCU, DebtTypeCU as imported3_DebtTypeCU } from "../domaintables/debtTypes";
import { RepaymentSourceCU as imported1_RepaymentSourceCU } from "../domaintables/repaymentSources";
import { LocalDateTimeFromIsoStringC, LocalDateC } from "../../codecs/localDate";
import { debtComparisonLineC as imported3_debtComparisonLineC, debtComparisonLineC as imported4_debtComparisonLineC, debtComparisonLineC as imported5_debtComparisonLineC } from "./debtComparisonLine";
import { bigNumberC } from "../../Big/bigNumberC";
import { CouponTypeCU as imported1_CouponTypeCU } from "../domaintables/couponTypes";
import { either } from "io-ts-types/lib/either";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { MuniTaxCreditCategoryCU as imported2_MuniTaxCreditCategoryCU, MuniTaxCreditCategoryCU as imported3_MuniTaxCreditCategoryCU } from "../domaintables/muniTaxCreditCategories";
import { MuniIssueTypeCU as imported2_MuniIssueTypeCU, MuniIssueTypeCU as imported3_MuniIssueTypeCU } from "../domaintables/muniIssueTypes";
import { InterestPaymentFrequencyCU as imported1_InterestPaymentFrequencyCU } from "../domaintables/interestPaymentFrequencies";
import { MuniPurposeClassTypeCU as imported2_MuniPurposeClassTypeCU, MuniPurposeClassTypeCU as imported3_MuniPurposeClassTypeCU } from "../domaintables/muniPurposeClassTypes";
import { CallStatusCU as imported1_CallStatusCU } from "../domaintables/callStatuses";
import * as t from "io-ts";
import { MuniEnhancementTypeCU as imported1_MuniEnhancementTypeCU } from "../domaintables/muniEnhancementTypes";
import { MuniRedemptionTypeCU as imported1_MuniRedemptionTypeCU } from "../domaintables/muniRedemptionTypes";
import { MethodOfSaleCU as imported3_MethodOfSaleCU } from "../domaintables/methodsOfSale";
import { IssuerSeriesTagCategoryCU as imported1_IssuerSeriesTagCategoryCU } from "../domaintables/issuerSeriesTagCategories";
import { TaxStatusCU as imported1_TaxStatusCU } from "../domaintables/taxStatuses";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const iceSeriesIssuerDataC = t.type({
  seriesId: t.number,
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tagIds: t.readonlyArray(t.number)
});
export type IceSeriesIssuerDataC = typeof iceSeriesIssuerDataC;
export type IceSeriesIssuerData = t.TypeOf<IceSeriesIssuerDataC>;


export const iceSeriesIssuerDataPostC = t.type({
  seriesId: t.number,
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tagIds: t.readonlyArray(t.number)
});
export type IceSeriesIssuerDataPostC = typeof iceSeriesIssuerDataPostC;
export type IceSeriesIssuerDataPost = t.TypeOf<IceSeriesIssuerDataPostC>;


export const iceCusip6IssuerDataC = t.type({
  cusip6: t.string,
  name: optionFromNullable(t.string),
  esgRelated: t.boolean,
  notes: optionFromNullable(t.string)
});
export type IceCusip6IssuerDataC = typeof iceCusip6IssuerDataC;
export type IceCusip6IssuerData = t.TypeOf<IceCusip6IssuerDataC>;


export const iceCusip6IssuerDataPostC = t.type({
  cusip: t.string,
  name: optionFromNullable(t.string),
  esgRelated: t.boolean,
  notes: optionFromNullable(t.string)
});
export type IceCusip6IssuerDataPostC = typeof iceCusip6IssuerDataPostC;
export type IceCusip6IssuerDataPost = t.TypeOf<IceCusip6IssuerDataPostC>;


export const issuerSeriesTagC = t.type({
  name: t.string,
  category: imported1_IssuerSeriesTagCategoryCU,
  notes: optionFromNullable(t.string),
  created: LocalDateTimeFromIsoStringC
});
export type IssuerSeriesTagC = typeof issuerSeriesTagC;
export type IssuerSeriesTag = t.TypeOf<IssuerSeriesTagC>;


export const iceSeriesIdC = t.type({
  iceSeriesId: t.number
});
export type IceSeriesIdC = typeof iceSeriesIdC;
export type IceSeriesId = t.TypeOf<IceSeriesIdC>;


export const nonIceSeriesIdC = t.type({
  nonIceDebtInstrumentId: t.number
});
export type NonIceSeriesIdC = typeof nonIceSeriesIdC;
export type NonIceSeriesId = t.TypeOf<NonIceSeriesIdC>;


export const eitherSeriesIdC = t.type({
  iceSeriesId: optionFromNullable(t.number),
  nonIceDebtInstrumentId: optionFromNullable(t.number)
});
export type EitherSeriesIdC = typeof eitherSeriesIdC;
export type EitherSeriesId = t.TypeOf<EitherSeriesIdC>;


export const issuerSeriesTagPostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  categoryId: t.number,
  notes: optionFromNullable(t.string),
  seriesIds: t.readonlyArray(eitherSeriesIdC)
});
export type IssuerSeriesTagPostC = typeof issuerSeriesTagPostC;
export type IssuerSeriesTagPost = t.TypeOf<IssuerSeriesTagPostC>;


export const pendingCusip9PostC = t.type({
  seriesData: iceSeriesIssuerDataPostC,
  acceptedCusip9s: t.readonlyArray(t.string),
  rejectedCusip9s: t.readonlyArray(t.string),
  rejectionNotes: optionFromNullable(t.string)
});
export type PendingCusip9PostC = typeof pendingCusip9PostC;
export type PendingCusip9Post = t.TypeOf<PendingCusip9PostC>;


export const paymentC = t.type({
  date: LocalDateC,
  principal: bigNumberC,
  interest: bigNumberC
});
export type PaymentC = typeof paymentC;
export type Payment = t.TypeOf<PaymentC>;


export const cusipC = t.type({
  cusip: t.string,
  originalPrincipal: optionFromNullable(bigNumberC),
  couponRate: optionFromNullable(bigNumberC),
  variableCouponRate: optionFromNullable(bigNumberC),
  issueDate: LocalDateC,
  maturityDate: LocalDateC,
  callStatus: imported1_CallStatusCU,
  firstCallDate: optionFromNullable(LocalDateC),
  couponType: optionFromNullable(imported1_CouponTypeCU),
  interestPaymentFrequency: optionFromNullable(imported1_InterestPaymentFrequencyCU),
  repaymentSource: imported1_RepaymentSourceCU,
  saleType: optionFromNullable(imported3_MethodOfSaleCU),
  debtType: imported2_DebtTypeCU,
  taxType: optionFromNullable(imported1_TaxStatusCU),
  issueType: optionFromNullable(imported2_MuniIssueTypeCU),
  taxCreditCategory: optionFromNullable(imported2_MuniTaxCreditCategoryCU),
  purposeClassType: optionFromNullable(imported2_MuniPurposeClassTypeCU),
  redemptionType: optionFromNullable(imported1_MuniRedemptionTypeCU),
  enhancementType: optionFromNullable(imported1_MuniEnhancementTypeCU),
  payments: t.readonlyArray(paymentC),
  escrowAgent: optionFromNullable(t.string),
  defeased: t.boolean,
  refundDate: optionFromNullable(LocalDateC),
  children: t.readonlyArray(t.number)
});
export type CusipC = typeof cusipC;
export type Cusip = t.TypeOf<CusipC>;


export const pendingCusipC = t.type({
  cusip: cusipC,
  created: LocalDateTimeFromIsoStringC
});
export type PendingCusipC = typeof pendingCusipC;
export type PendingCusip = t.TypeOf<PendingCusipC>;


export const acceptedCusipC = t.type({
  cusip: cusipC,
  accepted: LocalDateTimeFromIsoStringC,
  userIdModified: t.number
});
export type AcceptedCusipC = typeof acceptedCusipC;
export type AcceptedCusip = t.TypeOf<AcceptedCusipC>;


export const rejectedCusipC = t.type({
  cusip: cusipC,
  rejected: LocalDateTimeFromIsoStringC,
  userIdModified: t.number,
  notes: optionFromNullable(t.string)
});
export type RejectedCusipC = typeof rejectedCusipC;
export type RejectedCusip = t.TypeOf<RejectedCusipC>;


export class seriesCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  name: t.string,
  issueKey: t.number,
  originalPrincipal: bigNumberC,
  maturityDate: LocalDateC,
  cusips: t.readonlyArray(imported287_withIdC(A1)),
  showInCharts: t.boolean,
  customName: optionFromNullable(t.string),
  notes: optionFromNullable(t.string),
  tags: t.readonlyArray(imported288_withIdC(issuerSeriesTagC))
})}
export const seriesC = <A1 extends t.Mixed>(A1: A1) => new seriesCC<A1>().codec(A1);
export type SeriesC<A1 extends t.Mixed> = ReturnType<seriesCC<A1>["codec"]>;
export type Series<A1> = t.TypeOf<SeriesC<t.Type<A1>>>;

export const regularPaymentsScheduleC = t.type({
  principal: bigNumberC,
  interest: bigNumberC,
  startingDate: LocalDateC,
  monthsPerInterval: t.number,
  numberOfPayments: t.number,
  finalPaymentAltPrincipal: optionFromNullable(bigNumberC),
  finalPaymentAltInterest: optionFromNullable(bigNumberC)
});
export type RegularPaymentsScheduleC = typeof regularPaymentsScheduleC;
export type RegularPaymentsSchedule = t.TypeOf<RegularPaymentsScheduleC>;


export const manualDatesPaymentC = t.type({
  principal: bigNumberC,
  interest: bigNumberC,
  date: LocalDateC
});
export type ManualDatesPaymentC = typeof manualDatesPaymentC;
export type ManualDatesPayment = t.TypeOf<ManualDatesPaymentC>;


export const instrumentC = t.type({
  name: t.string,
  debtTypeId: imported3_DebtTypeCU,
  issueType: optionFromNullable(imported3_MuniIssueTypeCU),
  taxCreditCategory: optionFromNullable(imported3_MuniTaxCreditCategoryCU),
  purposeClassType: optionFromNullable(imported3_MuniPurposeClassTypeCU),
  showInCharts: t.boolean,
  originalPrincipal: bigNumberC,
  issueDate: optionFromNullable(LocalDateC),
  additionalInfo: optionFromNullable(t.string),
  paySchedule: either(regularPaymentsScheduleC, readonlyNonEmptyArrayC(manualDatesPaymentC)),
  tags: t.readonlyArray(imported289_withIdC(issuerSeriesTagC)),
  created: LocalDateTimeFromIsoStringC,
  modified: optionFromNullable(LocalDateTimeFromIsoStringC),
  userIdModified: t.number
});
export type InstrumentC = typeof instrumentC;
export type Instrument = t.TypeOf<InstrumentC>;


export const instrumentPostBodyC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  debtTypeId: t.number,
  issueTypeId: optionFromNullable(t.number),
  taxCreditCategoryId: optionFromNullable(t.number),
  purposeClassTypeId: optionFromNullable(t.number),
  showInCharts: t.boolean,
  originalPrincipal: bigNumberC,
  issueDate: optionFromNullable(LocalDateC),
  additionalInfo: optionFromNullable(t.string),
  regularPaymentsSchedule: optionFromNullable(regularPaymentsScheduleC),
  manualDatesPayments: optionFromNullable(readonlyNonEmptyArrayC(manualDatesPaymentC)),
  tagIds: t.readonlyArray(t.number)
});
export type InstrumentPostBodyC = typeof instrumentPostBodyC;
export type InstrumentPostBody = t.TypeOf<InstrumentPostBodyC>;


export const customFiscalYearC = t.type({
  month: t.number
});
export type CustomFiscalYearC = typeof customFiscalYearC;
export type CustomFiscalYear = t.TypeOf<CustomFiscalYearC>;


export const allDebtListC = t.type({
  instruments: t.readonlyArray(either(imported290_withIdC(seriesC(acceptedCusipC)), imported291_withIdC(instrumentC))),
  comparisonLine: optionFromNullable(imported3_debtComparisonLineC),
  customFiscalYear: customFiscalYearC,
  lastUpdated: optionFromNullable(LocalDateTimeFromIsoStringC),
  cusip6IssuerData: t.record(t.string, iceCusip6IssuerDataC),
  tags: readonlyMapFromEntries(t.number, numberOrd, issuerSeriesTagC),
  userNames: readonlyMapFromEntries(t.number, numberOrd, t.string)
});
export type AllDebtListC = typeof allDebtListC;
export type AllDebtList = t.TypeOf<AllDebtListC>;


export const iceDebtListC = t.type({
  series: t.readonlyArray(imported292_withIdC(seriesC(acceptedCusipC))),
  comparisonLine: optionFromNullable(imported4_debtComparisonLineC),
  customFiscalYear: customFiscalYearC,
  lastUpdated: optionFromNullable(LocalDateTimeFromIsoStringC),
  cusip6IssuerData: t.record(t.string, iceCusip6IssuerDataC)
});
export type IceDebtListC = typeof iceDebtListC;
export type IceDebtList = t.TypeOf<IceDebtListC>;


export const nonIceDebtListC = t.type({
  instruments: t.readonlyArray(imported293_withIdC(instrumentC)),
  comparisonLine: optionFromNullable(imported5_debtComparisonLineC),
  customFiscalYear: customFiscalYearC
});
export type NonIceDebtListC = typeof nonIceDebtListC;
export type NonIceDebtList = t.TypeOf<NonIceDebtListC>;


export const rejectedCusip9sC = t.type({
  data: t.readonlyArray(imported294_withIdC(seriesC(rejectedCusipC))),
  userNames: readonlyMapFromEntries(t.number, numberOrd, t.string)
});
export type RejectedCusip9sC = typeof rejectedCusip9sC;
export type RejectedCusip9s = t.TypeOf<RejectedCusip9sC>;


export const allStatusesDebtDataC = t.type({
  accepted: allDebtListC,
  pending: t.readonlyArray(imported295_withIdC(seriesC(pendingCusipC))),
  rejected: rejectedCusip9sC
});
export type AllStatusesDebtDataC = typeof allStatusesDebtDataC;
export type AllStatusesDebtData = t.TypeOf<AllStatusesDebtDataC>;


export const seriesNamePostC = t.type({
  seriesId: t.number,
  name: optionFromNullable(t.string)
});
export type SeriesNamePostC = typeof seriesNamePostC;
export type SeriesNamePost = t.TypeOf<SeriesNamePostC>;


export const toggleDebtVisibilityPostBodyC = t.type({
  id: t.number,
  showInCharts: t.boolean
});
export type ToggleDebtVisibilityPostBodyC = typeof toggleDebtVisibilityPostBodyC;
export type ToggleDebtVisibilityPostBody = t.TypeOf<ToggleDebtVisibilityPostBodyC>;


