import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const authority = {
  _tag: `Authority`,
  id: 1,
  name: `Authority`
} as const;

export const authorityTaggedC = t.type({
  _tag: t.literal(`Authority`)
});
export type AuthorityTaggedC = typeof authorityTaggedC;
export type AuthorityTagged = t.TypeOf<AuthorityTaggedC>;
export type Authority = AuthorityTagged & typeof authority;
export const authorityC = pipe(authorityTaggedC, c => new t.Type<Authority, AuthorityTagged>(
  `Authority`,
  (u: unknown): u is Authority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Authority> => pipe(c.decode(u), E.map(x => ({ ...x, ...authority }))),
  (x: Authority): AuthorityTagged => ({ ...x, _tag: `Authority`}),
));
export type AuthorityC = typeof authorityC;


export const bondAnticipationNote = {
  _tag: `BondAnticipationNote`,
  id: 2,
  name: `Bond Anticipation Note`
} as const;

export const bondAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`BondAnticipationNote`)
});
export type BondAnticipationNoteTaggedC = typeof bondAnticipationNoteTaggedC;
export type BondAnticipationNoteTagged = t.TypeOf<BondAnticipationNoteTaggedC>;
export type BondAnticipationNote = BondAnticipationNoteTagged & typeof bondAnticipationNote;
export const bondAnticipationNoteC = pipe(bondAnticipationNoteTaggedC, c => new t.Type<BondAnticipationNote, BondAnticipationNoteTagged>(
  `BondAnticipationNote`,
  (u: unknown): u is BondAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondAnticipationNote }))),
  (x: BondAnticipationNote): BondAnticipationNoteTagged => ({ ...x, _tag: `BondAnticipationNote`}),
));
export type BondAnticipationNoteC = typeof bondAnticipationNoteC;


export const building = {
  _tag: `Building`,
  id: 3,
  name: `Building`
} as const;

export const buildingTaggedC = t.type({
  _tag: t.literal(`Building`)
});
export type BuildingTaggedC = typeof buildingTaggedC;
export type BuildingTagged = t.TypeOf<BuildingTaggedC>;
export type Building = BuildingTagged & typeof building;
export const buildingC = pipe(buildingTaggedC, c => new t.Type<Building, BuildingTagged>(
  `Building`,
  (u: unknown): u is Building => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Building> => pipe(c.decode(u), E.map(x => ({ ...x, ...building }))),
  (x: Building): BuildingTagged => ({ ...x, _tag: `Building`}),
));
export type BuildingC = typeof buildingC;


export const capitalLoanNotes = {
  _tag: `CapitalLoanNotes`,
  id: 4,
  name: `Capital Loan Notes`
} as const;

export const capitalLoanNotesTaggedC = t.type({
  _tag: t.literal(`CapitalLoanNotes`)
});
export type CapitalLoanNotesTaggedC = typeof capitalLoanNotesTaggedC;
export type CapitalLoanNotesTagged = t.TypeOf<CapitalLoanNotesTaggedC>;
export type CapitalLoanNotes = CapitalLoanNotesTagged & typeof capitalLoanNotes;
export const capitalLoanNotesC = pipe(capitalLoanNotesTaggedC, c => new t.Type<CapitalLoanNotes, CapitalLoanNotesTagged>(
  `CapitalLoanNotes`,
  (u: unknown): u is CapitalLoanNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CapitalLoanNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...capitalLoanNotes }))),
  (x: CapitalLoanNotes): CapitalLoanNotesTagged => ({ ...x, _tag: `CapitalLoanNotes`}),
));
export type CapitalLoanNotesC = typeof capitalLoanNotesC;


export const certificatesOfParticipation = {
  _tag: `CertificatesOfParticipation`,
  id: 5,
  name: `Certificates Of Participation`
} as const;

export const certificatesOfParticipationTaggedC = t.type({
  _tag: t.literal(`CertificatesOfParticipation`)
});
export type CertificatesOfParticipationTaggedC = typeof certificatesOfParticipationTaggedC;
export type CertificatesOfParticipationTagged = t.TypeOf<CertificatesOfParticipationTaggedC>;
export type CertificatesOfParticipation = CertificatesOfParticipationTagged & typeof certificatesOfParticipation;
export const certificatesOfParticipationC = pipe(certificatesOfParticipationTaggedC, c => new t.Type<CertificatesOfParticipation, CertificatesOfParticipationTagged>(
  `CertificatesOfParticipation`,
  (u: unknown): u is CertificatesOfParticipation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CertificatesOfParticipation> => pipe(c.decode(u), E.map(x => ({ ...x, ...certificatesOfParticipation }))),
  (x: CertificatesOfParticipation): CertificatesOfParticipationTagged => ({ ...x, _tag: `CertificatesOfParticipation`}),
));
export type CertificatesOfParticipationC = typeof certificatesOfParticipationC;


export const corporatePurpose = {
  _tag: `CorporatePurpose`,
  id: 6,
  name: `Corporate Purpose`
} as const;

export const corporatePurposeTaggedC = t.type({
  _tag: t.literal(`CorporatePurpose`)
});
export type CorporatePurposeTaggedC = typeof corporatePurposeTaggedC;
export type CorporatePurposeTagged = t.TypeOf<CorporatePurposeTaggedC>;
export type CorporatePurpose = CorporatePurposeTagged & typeof corporatePurpose;
export const corporatePurposeC = pipe(corporatePurposeTaggedC, c => new t.Type<CorporatePurpose, CorporatePurposeTagged>(
  `CorporatePurpose`,
  (u: unknown): u is CorporatePurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CorporatePurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...corporatePurpose }))),
  (x: CorporatePurpose): CorporatePurposeTagged => ({ ...x, _tag: `CorporatePurpose`}),
));
export type CorporatePurposeC = typeof corporatePurposeC;


export const drainageDistrict = {
  _tag: `DrainageDistrict`,
  id: 7,
  name: `Drainage District`
} as const;

export const drainageDistrictTaggedC = t.type({
  _tag: t.literal(`DrainageDistrict`)
});
export type DrainageDistrictTaggedC = typeof drainageDistrictTaggedC;
export type DrainageDistrictTagged = t.TypeOf<DrainageDistrictTaggedC>;
export type DrainageDistrict = DrainageDistrictTagged & typeof drainageDistrict;
export const drainageDistrictC = pipe(drainageDistrictTaggedC, c => new t.Type<DrainageDistrict, DrainageDistrictTagged>(
  `DrainageDistrict`,
  (u: unknown): u is DrainageDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DrainageDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...drainageDistrict }))),
  (x: DrainageDistrict): DrainageDistrictTagged => ({ ...x, _tag: `DrainageDistrict`}),
));
export type DrainageDistrictC = typeof drainageDistrictC;


export const economicDevelopmentRevenue = {
  _tag: `EconomicDevelopmentRevenue`,
  id: 8,
  name: `Economic Development Revenue`
} as const;

export const economicDevelopmentRevenueTaggedC = t.type({
  _tag: t.literal(`EconomicDevelopmentRevenue`)
});
export type EconomicDevelopmentRevenueTaggedC = typeof economicDevelopmentRevenueTaggedC;
export type EconomicDevelopmentRevenueTagged = t.TypeOf<EconomicDevelopmentRevenueTaggedC>;
export type EconomicDevelopmentRevenue = EconomicDevelopmentRevenueTagged & typeof economicDevelopmentRevenue;
export const economicDevelopmentRevenueC = pipe(economicDevelopmentRevenueTaggedC, c => new t.Type<EconomicDevelopmentRevenue, EconomicDevelopmentRevenueTagged>(
  `EconomicDevelopmentRevenue`,
  (u: unknown): u is EconomicDevelopmentRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EconomicDevelopmentRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...economicDevelopmentRevenue }))),
  (x: EconomicDevelopmentRevenue): EconomicDevelopmentRevenueTagged => ({ ...x, _tag: `EconomicDevelopmentRevenue`}),
));
export type EconomicDevelopmentRevenueC = typeof economicDevelopmentRevenueC;


export const education = {
  _tag: `Education`,
  id: 9,
  name: `Education`
} as const;

export const educationTaggedC = t.type({
  _tag: t.literal(`Education`)
});
export type EducationTaggedC = typeof educationTaggedC;
export type EducationTagged = t.TypeOf<EducationTaggedC>;
export type Education = EducationTagged & typeof education;
export const educationC = pipe(educationTaggedC, c => new t.Type<Education, EducationTagged>(
  `Education`,
  (u: unknown): u is Education => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Education> => pipe(c.decode(u), E.map(x => ({ ...x, ...education }))),
  (x: Education): EducationTagged => ({ ...x, _tag: `Education`}),
));
export type EducationC = typeof educationC;


export const environment = {
  _tag: `Environment`,
  id: 10,
  name: `Environment`
} as const;

export const environmentTaggedC = t.type({
  _tag: t.literal(`Environment`)
});
export type EnvironmentTaggedC = typeof environmentTaggedC;
export type EnvironmentTagged = t.TypeOf<EnvironmentTaggedC>;
export type Environment = EnvironmentTagged & typeof environment;
export const environmentC = pipe(environmentTaggedC, c => new t.Type<Environment, EnvironmentTagged>(
  `Environment`,
  (u: unknown): u is Environment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Environment> => pipe(c.decode(u), E.map(x => ({ ...x, ...environment }))),
  (x: Environment): EnvironmentTagged => ({ ...x, _tag: `Environment`}),
));
export type EnvironmentC = typeof environmentC;


export const equipment = {
  _tag: `Equipment`,
  id: 11,
  name: `Equipment`
} as const;

export const equipmentTaggedC = t.type({
  _tag: t.literal(`Equipment`)
});
export type EquipmentTaggedC = typeof equipmentTaggedC;
export type EquipmentTagged = t.TypeOf<EquipmentTaggedC>;
export type Equipment = EquipmentTagged & typeof equipment;
export const equipmentC = pipe(equipmentTaggedC, c => new t.Type<Equipment, EquipmentTagged>(
  `Equipment`,
  (u: unknown): u is Equipment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Equipment> => pipe(c.decode(u), E.map(x => ({ ...x, ...equipment }))),
  (x: Equipment): EquipmentTagged => ({ ...x, _tag: `Equipment`}),
));
export type EquipmentC = typeof equipmentC;


export const federalAidNote = {
  _tag: `FederalAidNote`,
  id: 12,
  name: `Federal Aid Note`
} as const;

export const federalAidNoteTaggedC = t.type({
  _tag: t.literal(`FederalAidNote`)
});
export type FederalAidNoteTaggedC = typeof federalAidNoteTaggedC;
export type FederalAidNoteTagged = t.TypeOf<FederalAidNoteTaggedC>;
export type FederalAidNote = FederalAidNoteTagged & typeof federalAidNote;
export const federalAidNoteC = pipe(federalAidNoteTaggedC, c => new t.Type<FederalAidNote, FederalAidNoteTagged>(
  `FederalAidNote`,
  (u: unknown): u is FederalAidNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FederalAidNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...federalAidNote }))),
  (x: FederalAidNote): FederalAidNoteTagged => ({ ...x, _tag: `FederalAidNote`}),
));
export type FederalAidNoteC = typeof federalAidNoteC;


export const fireDistrict = {
  _tag: `FireDistrict`,
  id: 13,
  name: `Fire District`
} as const;

export const fireDistrictTaggedC = t.type({
  _tag: t.literal(`FireDistrict`)
});
export type FireDistrictTaggedC = typeof fireDistrictTaggedC;
export type FireDistrictTagged = t.TypeOf<FireDistrictTaggedC>;
export type FireDistrict = FireDistrictTagged & typeof fireDistrict;
export const fireDistrictC = pipe(fireDistrictTaggedC, c => new t.Type<FireDistrict, FireDistrictTagged>(
  `FireDistrict`,
  (u: unknown): u is FireDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FireDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...fireDistrict }))),
  (x: FireDistrict): FireDistrictTagged => ({ ...x, _tag: `FireDistrict`}),
));
export type FireDistrictC = typeof fireDistrictC;


export const floodWaterSewerDisposal = {
  _tag: `FloodWaterSewerDisposal`,
  id: 14,
  name: `Flood Water Sewer Disposal`
} as const;

export const floodWaterSewerDisposalTaggedC = t.type({
  _tag: t.literal(`FloodWaterSewerDisposal`)
});
export type FloodWaterSewerDisposalTaggedC = typeof floodWaterSewerDisposalTaggedC;
export type FloodWaterSewerDisposalTagged = t.TypeOf<FloodWaterSewerDisposalTaggedC>;
export type FloodWaterSewerDisposal = FloodWaterSewerDisposalTagged & typeof floodWaterSewerDisposal;
export const floodWaterSewerDisposalC = pipe(floodWaterSewerDisposalTaggedC, c => new t.Type<FloodWaterSewerDisposal, FloodWaterSewerDisposalTagged>(
  `FloodWaterSewerDisposal`,
  (u: unknown): u is FloodWaterSewerDisposal => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FloodWaterSewerDisposal> => pipe(c.decode(u), E.map(x => ({ ...x, ...floodWaterSewerDisposal }))),
  (x: FloodWaterSewerDisposal): FloodWaterSewerDisposalTagged => ({ ...x, _tag: `FloodWaterSewerDisposal`}),
));
export type FloodWaterSewerDisposalC = typeof floodWaterSewerDisposalC;


export const generalPurpose = {
  _tag: `GeneralPurpose`,
  id: 15,
  name: `General Purpose`
} as const;

export const generalPurposeTaggedC = t.type({
  _tag: t.literal(`GeneralPurpose`)
});
export type GeneralPurposeTaggedC = typeof generalPurposeTaggedC;
export type GeneralPurposeTagged = t.TypeOf<GeneralPurposeTaggedC>;
export type GeneralPurpose = GeneralPurposeTagged & typeof generalPurpose;
export const generalPurposeC = pipe(generalPurposeTaggedC, c => new t.Type<GeneralPurpose, GeneralPurposeTagged>(
  `GeneralPurpose`,
  (u: unknown): u is GeneralPurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralPurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalPurpose }))),
  (x: GeneralPurpose): GeneralPurposeTagged => ({ ...x, _tag: `GeneralPurpose`}),
));
export type GeneralPurposeC = typeof generalPurposeC;


export const grantAnticipationNote = {
  _tag: `GrantAnticipationNote`,
  id: 16,
  name: `Grant Anticipation Note`
} as const;

export const grantAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`GrantAnticipationNote`)
});
export type GrantAnticipationNoteTaggedC = typeof grantAnticipationNoteTaggedC;
export type GrantAnticipationNoteTagged = t.TypeOf<GrantAnticipationNoteTaggedC>;
export type GrantAnticipationNote = GrantAnticipationNoteTagged & typeof grantAnticipationNote;
export const grantAnticipationNoteC = pipe(grantAnticipationNoteTaggedC, c => new t.Type<GrantAnticipationNote, GrantAnticipationNoteTagged>(
  `GrantAnticipationNote`,
  (u: unknown): u is GrantAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GrantAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...grantAnticipationNote }))),
  (x: GrantAnticipationNote): GrantAnticipationNoteTagged => ({ ...x, _tag: `GrantAnticipationNote`}),
));
export type GrantAnticipationNoteC = typeof grantAnticipationNoteC;


export const health = {
  _tag: `Health`,
  id: 17,
  name: `Health`
} as const;

export const healthTaggedC = t.type({
  _tag: t.literal(`Health`)
});
export type HealthTaggedC = typeof healthTaggedC;
export type HealthTagged = t.TypeOf<HealthTaggedC>;
export type Health = HealthTagged & typeof health;
export const healthC = pipe(healthTaggedC, c => new t.Type<Health, HealthTagged>(
  `Health`,
  (u: unknown): u is Health => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Health> => pipe(c.decode(u), E.map(x => ({ ...x, ...health }))),
  (x: Health): HealthTagged => ({ ...x, _tag: `Health`}),
));
export type HealthC = typeof healthC;


export const housing = {
  _tag: `Housing`,
  id: 18,
  name: `Housing`
} as const;

export const housingTaggedC = t.type({
  _tag: t.literal(`Housing`)
});
export type HousingTaggedC = typeof housingTaggedC;
export type HousingTagged = t.TypeOf<HousingTaggedC>;
export type Housing = HousingTagged & typeof housing;
export const housingC = pipe(housingTaggedC, c => new t.Type<Housing, HousingTagged>(
  `Housing`,
  (u: unknown): u is Housing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Housing> => pipe(c.decode(u), E.map(x => ({ ...x, ...housing }))),
  (x: Housing): HousingTagged => ({ ...x, _tag: `Housing`}),
));
export type HousingC = typeof housingC;


export const improvement = {
  _tag: `Improvement`,
  id: 19,
  name: `Improvement`
} as const;

export const improvementTaggedC = t.type({
  _tag: t.literal(`Improvement`)
});
export type ImprovementTaggedC = typeof improvementTaggedC;
export type ImprovementTagged = t.TypeOf<ImprovementTaggedC>;
export type Improvement = ImprovementTagged & typeof improvement;
export const improvementC = pipe(improvementTaggedC, c => new t.Type<Improvement, ImprovementTagged>(
  `Improvement`,
  (u: unknown): u is Improvement => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Improvement> => pipe(c.decode(u), E.map(x => ({ ...x, ...improvement }))),
  (x: Improvement): ImprovementTagged => ({ ...x, _tag: `Improvement`}),
));
export type ImprovementC = typeof improvementC;


export const industrialDevelopmentRevenue = {
  _tag: `IndustrialDevelopmentRevenue`,
  id: 20,
  name: `Industrial Development Revenue`
} as const;

export const industrialDevelopmentRevenueTaggedC = t.type({
  _tag: t.literal(`IndustrialDevelopmentRevenue`)
});
export type IndustrialDevelopmentRevenueTaggedC = typeof industrialDevelopmentRevenueTaggedC;
export type IndustrialDevelopmentRevenueTagged = t.TypeOf<IndustrialDevelopmentRevenueTaggedC>;
export type IndustrialDevelopmentRevenue = IndustrialDevelopmentRevenueTagged & typeof industrialDevelopmentRevenue;
export const industrialDevelopmentRevenueC = pipe(industrialDevelopmentRevenueTaggedC, c => new t.Type<IndustrialDevelopmentRevenue, IndustrialDevelopmentRevenueTagged>(
  `IndustrialDevelopmentRevenue`,
  (u: unknown): u is IndustrialDevelopmentRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IndustrialDevelopmentRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...industrialDevelopmentRevenue }))),
  (x: IndustrialDevelopmentRevenue): IndustrialDevelopmentRevenueTagged => ({ ...x, _tag: `IndustrialDevelopmentRevenue`}),
));
export type IndustrialDevelopmentRevenueC = typeof industrialDevelopmentRevenueC;


export const libraryDistrict = {
  _tag: `LibraryDistrict`,
  id: 21,
  name: `Library District`
} as const;

export const libraryDistrictTaggedC = t.type({
  _tag: t.literal(`LibraryDistrict`)
});
export type LibraryDistrictTaggedC = typeof libraryDistrictTaggedC;
export type LibraryDistrictTagged = t.TypeOf<LibraryDistrictTaggedC>;
export type LibraryDistrict = LibraryDistrictTagged & typeof libraryDistrict;
export const libraryDistrictC = pipe(libraryDistrictTaggedC, c => new t.Type<LibraryDistrict, LibraryDistrictTagged>(
  `LibraryDistrict`,
  (u: unknown): u is LibraryDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LibraryDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...libraryDistrict }))),
  (x: LibraryDistrict): LibraryDistrictTagged => ({ ...x, _tag: `LibraryDistrict`}),
));
export type LibraryDistrictC = typeof libraryDistrictC;


export const metropolitanDistrict = {
  _tag: `MetropolitanDistrict`,
  id: 22,
  name: `Metropolitan District`
} as const;

export const metropolitanDistrictTaggedC = t.type({
  _tag: t.literal(`MetropolitanDistrict`)
});
export type MetropolitanDistrictTaggedC = typeof metropolitanDistrictTaggedC;
export type MetropolitanDistrictTagged = t.TypeOf<MetropolitanDistrictTaggedC>;
export type MetropolitanDistrict = MetropolitanDistrictTagged & typeof metropolitanDistrict;
export const metropolitanDistrictC = pipe(metropolitanDistrictTaggedC, c => new t.Type<MetropolitanDistrict, MetropolitanDistrictTagged>(
  `MetropolitanDistrict`,
  (u: unknown): u is MetropolitanDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MetropolitanDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...metropolitanDistrict }))),
  (x: MetropolitanDistrict): MetropolitanDistrictTagged => ({ ...x, _tag: `MetropolitanDistrict`}),
));
export type MetropolitanDistrictC = typeof metropolitanDistrictC;


export const miscellaneous = {
  _tag: `Miscellaneous`,
  id: 23,
  name: `Miscellaneous`
} as const;

export const miscellaneousTaggedC = t.type({
  _tag: t.literal(`Miscellaneous`)
});
export type MiscellaneousTaggedC = typeof miscellaneousTaggedC;
export type MiscellaneousTagged = t.TypeOf<MiscellaneousTaggedC>;
export type Miscellaneous = MiscellaneousTagged & typeof miscellaneous;
export const miscellaneousC = pipe(miscellaneousTaggedC, c => new t.Type<Miscellaneous, MiscellaneousTagged>(
  `Miscellaneous`,
  (u: unknown): u is Miscellaneous => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Miscellaneous> => pipe(c.decode(u), E.map(x => ({ ...x, ...miscellaneous }))),
  (x: Miscellaneous): MiscellaneousTagged => ({ ...x, _tag: `Miscellaneous`}),
));
export type MiscellaneousC = typeof miscellaneousC;


export const municipalBondBank = {
  _tag: `MunicipalBondBank`,
  id: 24,
  name: `Municipal Bond Bank`
} as const;

export const municipalBondBankTaggedC = t.type({
  _tag: t.literal(`MunicipalBondBank`)
});
export type MunicipalBondBankTaggedC = typeof municipalBondBankTaggedC;
export type MunicipalBondBankTagged = t.TypeOf<MunicipalBondBankTaggedC>;
export type MunicipalBondBank = MunicipalBondBankTagged & typeof municipalBondBank;
export const municipalBondBankC = pipe(municipalBondBankTaggedC, c => new t.Type<MunicipalBondBank, MunicipalBondBankTagged>(
  `MunicipalBondBank`,
  (u: unknown): u is MunicipalBondBank => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalBondBank> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalBondBank }))),
  (x: MunicipalBondBank): MunicipalBondBankTagged => ({ ...x, _tag: `MunicipalBondBank`}),
));
export type MunicipalBondBankC = typeof municipalBondBankC;


export const municipalUtilitiesDistrict = {
  _tag: `MunicipalUtilitiesDistrict`,
  id: 25,
  name: `Municipal Utilities District`
} as const;

export const municipalUtilitiesDistrictTaggedC = t.type({
  _tag: t.literal(`MunicipalUtilitiesDistrict`)
});
export type MunicipalUtilitiesDistrictTaggedC = typeof municipalUtilitiesDistrictTaggedC;
export type MunicipalUtilitiesDistrictTagged = t.TypeOf<MunicipalUtilitiesDistrictTaggedC>;
export type MunicipalUtilitiesDistrict = MunicipalUtilitiesDistrictTagged & typeof municipalUtilitiesDistrict;
export const municipalUtilitiesDistrictC = pipe(municipalUtilitiesDistrictTaggedC, c => new t.Type<MunicipalUtilitiesDistrict, MunicipalUtilitiesDistrictTagged>(
  `MunicipalUtilitiesDistrict`,
  (u: unknown): u is MunicipalUtilitiesDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalUtilitiesDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalUtilitiesDistrict }))),
  (x: MunicipalUtilitiesDistrict): MunicipalUtilitiesDistrictTagged => ({ ...x, _tag: `MunicipalUtilitiesDistrict`}),
));
export type MunicipalUtilitiesDistrictC = typeof municipalUtilitiesDistrictC;


export const parkDistrict = {
  _tag: `ParkDistrict`,
  id: 26,
  name: `Park District`
} as const;

export const parkDistrictTaggedC = t.type({
  _tag: t.literal(`ParkDistrict`)
});
export type ParkDistrictTaggedC = typeof parkDistrictTaggedC;
export type ParkDistrictTagged = t.TypeOf<ParkDistrictTaggedC>;
export type ParkDistrict = ParkDistrictTagged & typeof parkDistrict;
export const parkDistrictC = pipe(parkDistrictTaggedC, c => new t.Type<ParkDistrict, ParkDistrictTagged>(
  `ParkDistrict`,
  (u: unknown): u is ParkDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParkDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...parkDistrict }))),
  (x: ParkDistrict): ParkDistrictTagged => ({ ...x, _tag: `ParkDistrict`}),
));
export type ParkDistrictC = typeof parkDistrictC;


export const parkingAuthority = {
  _tag: `ParkingAuthority`,
  id: 27,
  name: `Parking Authority`
} as const;

export const parkingAuthorityTaggedC = t.type({
  _tag: t.literal(`ParkingAuthority`)
});
export type ParkingAuthorityTaggedC = typeof parkingAuthorityTaggedC;
export type ParkingAuthorityTagged = t.TypeOf<ParkingAuthorityTaggedC>;
export type ParkingAuthority = ParkingAuthorityTagged & typeof parkingAuthority;
export const parkingAuthorityC = pipe(parkingAuthorityTaggedC, c => new t.Type<ParkingAuthority, ParkingAuthorityTagged>(
  `ParkingAuthority`,
  (u: unknown): u is ParkingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ParkingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...parkingAuthority }))),
  (x: ParkingAuthority): ParkingAuthorityTagged => ({ ...x, _tag: `ParkingAuthority`}),
));
export type ParkingAuthorityC = typeof parkingAuthorityC;


export const pollutionControlRevenue = {
  _tag: `PollutionControlRevenue`,
  id: 28,
  name: `Pollution Control Revenue`
} as const;

export const pollutionControlRevenueTaggedC = t.type({
  _tag: t.literal(`PollutionControlRevenue`)
});
export type PollutionControlRevenueTaggedC = typeof pollutionControlRevenueTaggedC;
export type PollutionControlRevenueTagged = t.TypeOf<PollutionControlRevenueTaggedC>;
export type PollutionControlRevenue = PollutionControlRevenueTagged & typeof pollutionControlRevenue;
export const pollutionControlRevenueC = pipe(pollutionControlRevenueTaggedC, c => new t.Type<PollutionControlRevenue, PollutionControlRevenueTagged>(
  `PollutionControlRevenue`,
  (u: unknown): u is PollutionControlRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PollutionControlRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...pollutionControlRevenue }))),
  (x: PollutionControlRevenue): PollutionControlRevenueTagged => ({ ...x, _tag: `PollutionControlRevenue`}),
));
export type PollutionControlRevenueC = typeof pollutionControlRevenueC;


export const poolFinancingAuthority = {
  _tag: `PoolFinancingAuthority`,
  id: 29,
  name: `Pool Financing Authority`
} as const;

export const poolFinancingAuthorityTaggedC = t.type({
  _tag: t.literal(`PoolFinancingAuthority`)
});
export type PoolFinancingAuthorityTaggedC = typeof poolFinancingAuthorityTaggedC;
export type PoolFinancingAuthorityTagged = t.TypeOf<PoolFinancingAuthorityTaggedC>;
export type PoolFinancingAuthority = PoolFinancingAuthorityTagged & typeof poolFinancingAuthority;
export const poolFinancingAuthorityC = pipe(poolFinancingAuthorityTaggedC, c => new t.Type<PoolFinancingAuthority, PoolFinancingAuthorityTagged>(
  `PoolFinancingAuthority`,
  (u: unknown): u is PoolFinancingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PoolFinancingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...poolFinancingAuthority }))),
  (x: PoolFinancingAuthority): PoolFinancingAuthorityTagged => ({ ...x, _tag: `PoolFinancingAuthority`}),
));
export type PoolFinancingAuthorityC = typeof poolFinancingAuthorityC;


export const promissoryNote = {
  _tag: `PromissoryNote`,
  id: 30,
  name: `Promissory Note`
} as const;

export const promissoryNoteTaggedC = t.type({
  _tag: t.literal(`PromissoryNote`)
});
export type PromissoryNoteTaggedC = typeof promissoryNoteTaggedC;
export type PromissoryNoteTagged = t.TypeOf<PromissoryNoteTaggedC>;
export type PromissoryNote = PromissoryNoteTagged & typeof promissoryNote;
export const promissoryNoteC = pipe(promissoryNoteTaggedC, c => new t.Type<PromissoryNote, PromissoryNoteTagged>(
  `PromissoryNote`,
  (u: unknown): u is PromissoryNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PromissoryNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...promissoryNote }))),
  (x: PromissoryNote): PromissoryNoteTagged => ({ ...x, _tag: `PromissoryNote`}),
));
export type PromissoryNoteC = typeof promissoryNoteC;


export const publicHousingAuthority = {
  _tag: `PublicHousingAuthority`,
  id: 31,
  name: `Public Housing Authority`
} as const;

export const publicHousingAuthorityTaggedC = t.type({
  _tag: t.literal(`PublicHousingAuthority`)
});
export type PublicHousingAuthorityTaggedC = typeof publicHousingAuthorityTaggedC;
export type PublicHousingAuthorityTagged = t.TypeOf<PublicHousingAuthorityTaggedC>;
export type PublicHousingAuthority = PublicHousingAuthorityTagged & typeof publicHousingAuthority;
export const publicHousingAuthorityC = pipe(publicHousingAuthorityTaggedC, c => new t.Type<PublicHousingAuthority, PublicHousingAuthorityTagged>(
  `PublicHousingAuthority`,
  (u: unknown): u is PublicHousingAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublicHousingAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...publicHousingAuthority }))),
  (x: PublicHousingAuthority): PublicHousingAuthorityTagged => ({ ...x, _tag: `PublicHousingAuthority`}),
));
export type PublicHousingAuthorityC = typeof publicHousingAuthorityC;


export const utility = {
  _tag: `Utility`,
  id: 50,
  name: `Utility`
} as const;

export const utilityTaggedC = t.type({
  _tag: t.literal(`Utility`)
});
export type UtilityTaggedC = typeof utilityTaggedC;
export type UtilityTagged = t.TypeOf<UtilityTaggedC>;
export type Utility = UtilityTagged & typeof utility;
export const utilityC = pipe(utilityTaggedC, c => new t.Type<Utility, UtilityTagged>(
  `Utility`,
  (u: unknown): u is Utility => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Utility> => pipe(c.decode(u), E.map(x => ({ ...x, ...utility }))),
  (x: Utility): UtilityTagged => ({ ...x, _tag: `Utility`}),
));
export type UtilityC = typeof utilityC;


export const publicUtilityDistrict = {
  _tag: `PublicUtilityDistrict`,
  id: 32,
  name: `Public Utility District`
} as const;

export const publicUtilityDistrictTaggedC = t.type({
  _tag: t.literal(`PublicUtilityDistrict`)
});
export type PublicUtilityDistrictTaggedC = typeof publicUtilityDistrictTaggedC;
export type PublicUtilityDistrictTagged = t.TypeOf<PublicUtilityDistrictTaggedC>;
export type PublicUtilityDistrict = PublicUtilityDistrictTagged & typeof publicUtilityDistrict;
export const publicUtilityDistrictC = pipe(publicUtilityDistrictTaggedC, c => new t.Type<PublicUtilityDistrict, PublicUtilityDistrictTagged>(
  `PublicUtilityDistrict`,
  (u: unknown): u is PublicUtilityDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublicUtilityDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...publicUtilityDistrict }))),
  (x: PublicUtilityDistrict): PublicUtilityDistrictTagged => ({ ...x, _tag: `PublicUtilityDistrict`}),
));
export type PublicUtilityDistrictC = typeof publicUtilityDistrictC;


export const racetrackSlashCasino = {
  _tag: `RacetrackSlashCasino`,
  id: 33,
  name: `Racetrack/Casino`
} as const;

export const racetrackSlashCasinoTaggedC = t.type({
  _tag: t.literal(`RacetrackSlashCasino`)
});
export type RacetrackSlashCasinoTaggedC = typeof racetrackSlashCasinoTaggedC;
export type RacetrackSlashCasinoTagged = t.TypeOf<RacetrackSlashCasinoTaggedC>;
export type RacetrackSlashCasino = RacetrackSlashCasinoTagged & typeof racetrackSlashCasino;
export const racetrackSlashCasinoC = pipe(racetrackSlashCasinoTaggedC, c => new t.Type<RacetrackSlashCasino, RacetrackSlashCasinoTagged>(
  `RacetrackSlashCasino`,
  (u: unknown): u is RacetrackSlashCasino => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RacetrackSlashCasino> => pipe(c.decode(u), E.map(x => ({ ...x, ...racetrackSlashCasino }))),
  (x: RacetrackSlashCasino): RacetrackSlashCasinoTagged => ({ ...x, _tag: `RacetrackSlashCasino`}),
));
export type RacetrackSlashCasinoC = typeof racetrackSlashCasinoC;


export const recreationAuthority = {
  _tag: `RecreationAuthority`,
  id: 34,
  name: `Recreation Authority`
} as const;

export const recreationAuthorityTaggedC = t.type({
  _tag: t.literal(`RecreationAuthority`)
});
export type RecreationAuthorityTaggedC = typeof recreationAuthorityTaggedC;
export type RecreationAuthorityTagged = t.TypeOf<RecreationAuthorityTaggedC>;
export type RecreationAuthority = RecreationAuthorityTagged & typeof recreationAuthority;
export const recreationAuthorityC = pipe(recreationAuthorityTaggedC, c => new t.Type<RecreationAuthority, RecreationAuthorityTagged>(
  `RecreationAuthority`,
  (u: unknown): u is RecreationAuthority => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RecreationAuthority> => pipe(c.decode(u), E.map(x => ({ ...x, ...recreationAuthority }))),
  (x: RecreationAuthority): RecreationAuthorityTagged => ({ ...x, _tag: `RecreationAuthority`}),
));
export type RecreationAuthorityC = typeof recreationAuthorityC;


export const revenueAnticipationNote = {
  _tag: `RevenueAnticipationNote`,
  id: 35,
  name: `Revenue Anticipation Note`
} as const;

export const revenueAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`RevenueAnticipationNote`)
});
export type RevenueAnticipationNoteTaggedC = typeof revenueAnticipationNoteTaggedC;
export type RevenueAnticipationNoteTagged = t.TypeOf<RevenueAnticipationNoteTaggedC>;
export type RevenueAnticipationNote = RevenueAnticipationNoteTagged & typeof revenueAnticipationNote;
export const revenueAnticipationNoteC = pipe(revenueAnticipationNoteTaggedC, c => new t.Type<RevenueAnticipationNote, RevenueAnticipationNoteTagged>(
  `RevenueAnticipationNote`,
  (u: unknown): u is RevenueAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RevenueAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...revenueAnticipationNote }))),
  (x: RevenueAnticipationNote): RevenueAnticipationNoteTagged => ({ ...x, _tag: `RevenueAnticipationNote`}),
));
export type RevenueAnticipationNoteC = typeof revenueAnticipationNoteC;


export const sanitationDistrict = {
  _tag: `SanitationDistrict`,
  id: 36,
  name: `Sanitation District`
} as const;

export const sanitationDistrictTaggedC = t.type({
  _tag: t.literal(`SanitationDistrict`)
});
export type SanitationDistrictTaggedC = typeof sanitationDistrictTaggedC;
export type SanitationDistrictTagged = t.TypeOf<SanitationDistrictTaggedC>;
export type SanitationDistrict = SanitationDistrictTagged & typeof sanitationDistrict;
export const sanitationDistrictC = pipe(sanitationDistrictTaggedC, c => new t.Type<SanitationDistrict, SanitationDistrictTagged>(
  `SanitationDistrict`,
  (u: unknown): u is SanitationDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SanitationDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...sanitationDistrict }))),
  (x: SanitationDistrict): SanitationDistrictTagged => ({ ...x, _tag: `SanitationDistrict`}),
));
export type SanitationDistrictC = typeof sanitationDistrictC;


export const schoolDistrict = {
  _tag: `SchoolDistrict`,
  id: 37,
  name: `School District`
} as const;

export const schoolDistrictTaggedC = t.type({
  _tag: t.literal(`SchoolDistrict`)
});
export type SchoolDistrictTaggedC = typeof schoolDistrictTaggedC;
export type SchoolDistrictTagged = t.TypeOf<SchoolDistrictTaggedC>;
export type SchoolDistrict = SchoolDistrictTagged & typeof schoolDistrict;
export const schoolDistrictC = pipe(schoolDistrictTaggedC, c => new t.Type<SchoolDistrict, SchoolDistrictTagged>(
  `SchoolDistrict`,
  (u: unknown): u is SchoolDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SchoolDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...schoolDistrict }))),
  (x: SchoolDistrict): SchoolDistrictTagged => ({ ...x, _tag: `SchoolDistrict`}),
));
export type SchoolDistrictC = typeof schoolDistrictC;


export const specialAssessment = {
  _tag: `SpecialAssessment`,
  id: 38,
  name: `Special Assessment`
} as const;

export const specialAssessmentTaggedC = t.type({
  _tag: t.literal(`SpecialAssessment`)
});
export type SpecialAssessmentTaggedC = typeof specialAssessmentTaggedC;
export type SpecialAssessmentTagged = t.TypeOf<SpecialAssessmentTaggedC>;
export type SpecialAssessment = SpecialAssessmentTagged & typeof specialAssessment;
export const specialAssessmentC = pipe(specialAssessmentTaggedC, c => new t.Type<SpecialAssessment, SpecialAssessmentTagged>(
  `SpecialAssessment`,
  (u: unknown): u is SpecialAssessment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialAssessment> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialAssessment }))),
  (x: SpecialAssessment): SpecialAssessmentTagged => ({ ...x, _tag: `SpecialAssessment`}),
));
export type SpecialAssessmentC = typeof specialAssessmentC;


export const specialObligation = {
  _tag: `SpecialObligation`,
  id: 39,
  name: `Special Obligation`
} as const;

export const specialObligationTaggedC = t.type({
  _tag: t.literal(`SpecialObligation`)
});
export type SpecialObligationTaggedC = typeof specialObligationTaggedC;
export type SpecialObligationTagged = t.TypeOf<SpecialObligationTaggedC>;
export type SpecialObligation = SpecialObligationTagged & typeof specialObligation;
export const specialObligationC = pipe(specialObligationTaggedC, c => new t.Type<SpecialObligation, SpecialObligationTagged>(
  `SpecialObligation`,
  (u: unknown): u is SpecialObligation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialObligation> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialObligation }))),
  (x: SpecialObligation): SpecialObligationTagged => ({ ...x, _tag: `SpecialObligation`}),
));
export type SpecialObligationC = typeof specialObligationC;


export const stateAndFederalAnticipationNote = {
  _tag: `StateAndFederalAnticipationNote`,
  id: 40,
  name: `State and Federal Anticipation Note`
} as const;

export const stateAndFederalAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`StateAndFederalAnticipationNote`)
});
export type StateAndFederalAnticipationNoteTaggedC = typeof stateAndFederalAnticipationNoteTaggedC;
export type StateAndFederalAnticipationNoteTagged = t.TypeOf<StateAndFederalAnticipationNoteTaggedC>;
export type StateAndFederalAnticipationNote = StateAndFederalAnticipationNoteTagged & typeof stateAndFederalAnticipationNote;
export const stateAndFederalAnticipationNoteC = pipe(stateAndFederalAnticipationNoteTaggedC, c => new t.Type<StateAndFederalAnticipationNote, StateAndFederalAnticipationNoteTagged>(
  `StateAndFederalAnticipationNote`,
  (u: unknown): u is StateAndFederalAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAndFederalAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAndFederalAnticipationNote }))),
  (x: StateAndFederalAnticipationNote): StateAndFederalAnticipationNoteTagged => ({ ...x, _tag: `StateAndFederalAnticipationNote`}),
));
export type StateAndFederalAnticipationNoteC = typeof stateAndFederalAnticipationNoteC;


export const stateAndGrantAnticipationNote = {
  _tag: `StateAndGrantAnticipationNote`,
  id: 41,
  name: `State and Grant Anticipation Note`
} as const;

export const stateAndGrantAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`StateAndGrantAnticipationNote`)
});
export type StateAndGrantAnticipationNoteTaggedC = typeof stateAndGrantAnticipationNoteTaggedC;
export type StateAndGrantAnticipationNoteTagged = t.TypeOf<StateAndGrantAnticipationNoteTaggedC>;
export type StateAndGrantAnticipationNote = StateAndGrantAnticipationNoteTagged & typeof stateAndGrantAnticipationNote;
export const stateAndGrantAnticipationNoteC = pipe(stateAndGrantAnticipationNoteTaggedC, c => new t.Type<StateAndGrantAnticipationNote, StateAndGrantAnticipationNoteTagged>(
  `StateAndGrantAnticipationNote`,
  (u: unknown): u is StateAndGrantAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAndGrantAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAndGrantAnticipationNote }))),
  (x: StateAndGrantAnticipationNote): StateAndGrantAnticipationNoteTagged => ({ ...x, _tag: `StateAndGrantAnticipationNote`}),
));
export type StateAndGrantAnticipationNoteC = typeof stateAndGrantAnticipationNoteC;


export const stateAnticipationNotes = {
  _tag: `StateAnticipationNotes`,
  id: 42,
  name: `State Anticipation Notes`
} as const;

export const stateAnticipationNotesTaggedC = t.type({
  _tag: t.literal(`StateAnticipationNotes`)
});
export type StateAnticipationNotesTaggedC = typeof stateAnticipationNotesTaggedC;
export type StateAnticipationNotesTagged = t.TypeOf<StateAnticipationNotesTaggedC>;
export type StateAnticipationNotes = StateAnticipationNotesTagged & typeof stateAnticipationNotes;
export const stateAnticipationNotesC = pipe(stateAnticipationNotesTaggedC, c => new t.Type<StateAnticipationNotes, StateAnticipationNotesTagged>(
  `StateAnticipationNotes`,
  (u: unknown): u is StateAnticipationNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateAnticipationNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateAnticipationNotes }))),
  (x: StateAnticipationNotes): StateAnticipationNotesTagged => ({ ...x, _tag: `StateAnticipationNotes`}),
));
export type StateAnticipationNotesC = typeof stateAnticipationNotesC;


export const stateStore = {
  _tag: `StateStore`,
  id: 43,
  name: `State Store`
} as const;

export const stateStoreTaggedC = t.type({
  _tag: t.literal(`StateStore`)
});
export type StateStoreTaggedC = typeof stateStoreTaggedC;
export type StateStoreTagged = t.TypeOf<StateStoreTaggedC>;
export type StateStore = StateStoreTagged & typeof stateStore;
export const stateStoreC = pipe(stateStoreTaggedC, c => new t.Type<StateStore, StateStoreTagged>(
  `StateStore`,
  (u: unknown): u is StateStore => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateStore> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateStore }))),
  (x: StateStore): StateStoreTagged => ({ ...x, _tag: `StateStore`}),
));
export type StateStoreC = typeof stateStoreC;


export const taxAndRevenueAnticipation = {
  _tag: `TaxAndRevenueAnticipation`,
  id: 44,
  name: `Tax and Revenue Anticipation`
} as const;

export const taxAndRevenueAnticipationTaggedC = t.type({
  _tag: t.literal(`TaxAndRevenueAnticipation`)
});
export type TaxAndRevenueAnticipationTaggedC = typeof taxAndRevenueAnticipationTaggedC;
export type TaxAndRevenueAnticipationTagged = t.TypeOf<TaxAndRevenueAnticipationTaggedC>;
export type TaxAndRevenueAnticipation = TaxAndRevenueAnticipationTagged & typeof taxAndRevenueAnticipation;
export const taxAndRevenueAnticipationC = pipe(taxAndRevenueAnticipationTaggedC, c => new t.Type<TaxAndRevenueAnticipation, TaxAndRevenueAnticipationTagged>(
  `TaxAndRevenueAnticipation`,
  (u: unknown): u is TaxAndRevenueAnticipation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxAndRevenueAnticipation> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxAndRevenueAnticipation }))),
  (x: TaxAndRevenueAnticipation): TaxAndRevenueAnticipationTagged => ({ ...x, _tag: `TaxAndRevenueAnticipation`}),
));
export type TaxAndRevenueAnticipationC = typeof taxAndRevenueAnticipationC;


export const taxAnticipationNote = {
  _tag: `TaxAnticipationNote`,
  id: 45,
  name: `Tax Anticipation Note`
} as const;

export const taxAnticipationNoteTaggedC = t.type({
  _tag: t.literal(`TaxAnticipationNote`)
});
export type TaxAnticipationNoteTaggedC = typeof taxAnticipationNoteTaggedC;
export type TaxAnticipationNoteTagged = t.TypeOf<TaxAnticipationNoteTaggedC>;
export type TaxAnticipationNote = TaxAnticipationNoteTagged & typeof taxAnticipationNote;
export const taxAnticipationNoteC = pipe(taxAnticipationNoteTaggedC, c => new t.Type<TaxAnticipationNote, TaxAnticipationNoteTagged>(
  `TaxAnticipationNote`,
  (u: unknown): u is TaxAnticipationNote => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxAnticipationNote> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxAnticipationNote }))),
  (x: TaxAnticipationNote): TaxAnticipationNoteTagged => ({ ...x, _tag: `TaxAnticipationNote`}),
));
export type TaxAnticipationNoteC = typeof taxAnticipationNoteC;


export const taxRevenue = {
  _tag: `TaxRevenue`,
  id: 46,
  name: `Tax Revenue`
} as const;

export const taxRevenueTaggedC = t.type({
  _tag: t.literal(`TaxRevenue`)
});
export type TaxRevenueTaggedC = typeof taxRevenueTaggedC;
export type TaxRevenueTagged = t.TypeOf<TaxRevenueTaggedC>;
export type TaxRevenue = TaxRevenueTagged & typeof taxRevenue;
export const taxRevenueC = pipe(taxRevenueTaggedC, c => new t.Type<TaxRevenue, TaxRevenueTagged>(
  `TaxRevenue`,
  (u: unknown): u is TaxRevenue => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TaxRevenue> => pipe(c.decode(u), E.map(x => ({ ...x, ...taxRevenue }))),
  (x: TaxRevenue): TaxRevenueTagged => ({ ...x, _tag: `TaxRevenue`}),
));
export type TaxRevenueC = typeof taxRevenueC;


export const temporaryNotes = {
  _tag: `TemporaryNotes`,
  id: 47,
  name: `Temporary Notes`
} as const;

export const temporaryNotesTaggedC = t.type({
  _tag: t.literal(`TemporaryNotes`)
});
export type TemporaryNotesTaggedC = typeof temporaryNotesTaggedC;
export type TemporaryNotesTagged = t.TypeOf<TemporaryNotesTaggedC>;
export type TemporaryNotes = TemporaryNotesTagged & typeof temporaryNotes;
export const temporaryNotesC = pipe(temporaryNotesTaggedC, c => new t.Type<TemporaryNotes, TemporaryNotesTagged>(
  `TemporaryNotes`,
  (u: unknown): u is TemporaryNotes => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TemporaryNotes> => pipe(c.decode(u), E.map(x => ({ ...x, ...temporaryNotes }))),
  (x: TemporaryNotes): TemporaryNotesTagged => ({ ...x, _tag: `TemporaryNotes`}),
));
export type TemporaryNotesC = typeof temporaryNotesC;


export const transportation = {
  _tag: `Transportation`,
  id: 48,
  name: `Transportation`
} as const;

export const transportationTaggedC = t.type({
  _tag: t.literal(`Transportation`)
});
export type TransportationTaggedC = typeof transportationTaggedC;
export type TransportationTagged = t.TypeOf<TransportationTaggedC>;
export type Transportation = TransportationTagged & typeof transportation;
export const transportationC = pipe(transportationTaggedC, c => new t.Type<Transportation, TransportationTagged>(
  `Transportation`,
  (u: unknown): u is Transportation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Transportation> => pipe(c.decode(u), E.map(x => ({ ...x, ...transportation }))),
  (x: Transportation): TransportationTagged => ({ ...x, _tag: `Transportation`}),
));
export type TransportationC = typeof transportationC;


export const tribalBonds = {
  _tag: `TribalBonds`,
  id: 49,
  name: `Tribal Bonds`
} as const;

export const tribalBondsTaggedC = t.type({
  _tag: t.literal(`TribalBonds`)
});
export type TribalBondsTaggedC = typeof tribalBondsTaggedC;
export type TribalBondsTagged = t.TypeOf<TribalBondsTaggedC>;
export type TribalBonds = TribalBondsTagged & typeof tribalBonds;
export const tribalBondsC = pipe(tribalBondsTaggedC, c => new t.Type<TribalBonds, TribalBondsTagged>(
  `TribalBonds`,
  (u: unknown): u is TribalBonds => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TribalBonds> => pipe(c.decode(u), E.map(x => ({ ...x, ...tribalBonds }))),
  (x: TribalBonds): TribalBondsTagged => ({ ...x, _tag: `TribalBonds`}),
));
export type TribalBondsC = typeof tribalBondsC;


export const variousPurpose = {
  _tag: `VariousPurpose`,
  id: 51,
  name: `Various Purpose`
} as const;

export const variousPurposeTaggedC = t.type({
  _tag: t.literal(`VariousPurpose`)
});
export type VariousPurposeTaggedC = typeof variousPurposeTaggedC;
export type VariousPurposeTagged = t.TypeOf<VariousPurposeTaggedC>;
export type VariousPurpose = VariousPurposeTagged & typeof variousPurpose;
export const variousPurposeC = pipe(variousPurposeTaggedC, c => new t.Type<VariousPurpose, VariousPurposeTagged>(
  `VariousPurpose`,
  (u: unknown): u is VariousPurpose => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariousPurpose> => pipe(c.decode(u), E.map(x => ({ ...x, ...variousPurpose }))),
  (x: VariousPurpose): VariousPurposeTagged => ({ ...x, _tag: `VariousPurpose`}),
));
export type VariousPurposeC = typeof variousPurposeC;


export const veterans = {
  _tag: `Veterans`,
  id: 52,
  name: `Veterans`
} as const;

export const veteransTaggedC = t.type({
  _tag: t.literal(`Veterans`)
});
export type VeteransTaggedC = typeof veteransTaggedC;
export type VeteransTagged = t.TypeOf<VeteransTaggedC>;
export type Veterans = VeteransTagged & typeof veterans;
export const veteransC = pipe(veteransTaggedC, c => new t.Type<Veterans, VeteransTagged>(
  `Veterans`,
  (u: unknown): u is Veterans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Veterans> => pipe(c.decode(u), E.map(x => ({ ...x, ...veterans }))),
  (x: Veterans): VeteransTagged => ({ ...x, _tag: `Veterans`}),
));
export type VeteransC = typeof veteransC;


export const warrants = {
  _tag: `Warrants`,
  id: 53,
  name: `Warrants`
} as const;

export const warrantsTaggedC = t.type({
  _tag: t.literal(`Warrants`)
});
export type WarrantsTaggedC = typeof warrantsTaggedC;
export type WarrantsTagged = t.TypeOf<WarrantsTaggedC>;
export type Warrants = WarrantsTagged & typeof warrants;
export const warrantsC = pipe(warrantsTaggedC, c => new t.Type<Warrants, WarrantsTagged>(
  `Warrants`,
  (u: unknown): u is Warrants => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Warrants> => pipe(c.decode(u), E.map(x => ({ ...x, ...warrants }))),
  (x: Warrants): WarrantsTagged => ({ ...x, _tag: `Warrants`}),
));
export type WarrantsC = typeof warrantsC;


export const allMuniPurposeClassTypeC = [authorityC, bondAnticipationNoteC, buildingC, capitalLoanNotesC, certificatesOfParticipationC, corporatePurposeC, drainageDistrictC, economicDevelopmentRevenueC, educationC, environmentC, equipmentC, federalAidNoteC, fireDistrictC, floodWaterSewerDisposalC, generalPurposeC, grantAnticipationNoteC, healthC, housingC, improvementC, industrialDevelopmentRevenueC, libraryDistrictC, metropolitanDistrictC, miscellaneousC, municipalBondBankC, municipalUtilitiesDistrictC, parkDistrictC, parkingAuthorityC, pollutionControlRevenueC, poolFinancingAuthorityC, promissoryNoteC, publicHousingAuthorityC, publicUtilityDistrictC, racetrackSlashCasinoC, recreationAuthorityC, revenueAnticipationNoteC, sanitationDistrictC, schoolDistrictC, specialAssessmentC, specialObligationC, stateAndFederalAnticipationNoteC, stateAndGrantAnticipationNoteC, stateAnticipationNotesC, stateStoreC, taxAndRevenueAnticipationC, taxAnticipationNoteC, taxRevenueC, temporaryNotesC, transportationC, tribalBondsC, utilityC, variousPurposeC, veteransC, warrantsC] as const;
export const allMuniPurposeClassTypeNames = [`Authority`, `BondAnticipationNote`, `Building`, `CapitalLoanNotes`, `CertificatesOfParticipation`, `CorporatePurpose`, `DrainageDistrict`, `EconomicDevelopmentRevenue`, `Education`, `Environment`, `Equipment`, `FederalAidNote`, `FireDistrict`, `FloodWaterSewerDisposal`, `GeneralPurpose`, `GrantAnticipationNote`, `Health`, `Housing`, `Improvement`, `IndustrialDevelopmentRevenue`, `LibraryDistrict`, `MetropolitanDistrict`, `Miscellaneous`, `MunicipalBondBank`, `MunicipalUtilitiesDistrict`, `ParkDistrict`, `ParkingAuthority`, `PollutionControlRevenue`, `PoolFinancingAuthority`, `PromissoryNote`, `PublicHousingAuthority`, `PublicUtilityDistrict`, `RacetrackSlashCasino`, `RecreationAuthority`, `RevenueAnticipationNote`, `SanitationDistrict`, `SchoolDistrict`, `SpecialAssessment`, `SpecialObligation`, `StateAndFederalAnticipationNote`, `StateAndGrantAnticipationNote`, `StateAnticipationNotes`, `StateStore`, `TaxAndRevenueAnticipation`, `TaxAnticipationNote`, `TaxRevenue`, `TemporaryNotes`, `Transportation`, `TribalBonds`, `Utility`, `VariousPurpose`, `Veterans`, `Warrants`] as const;
export type MuniPurposeClassTypeName = (typeof allMuniPurposeClassTypeNames)[number];

export const MuniPurposeClassTypeCU = t.union([authorityC, bondAnticipationNoteC, buildingC, capitalLoanNotesC, certificatesOfParticipationC, corporatePurposeC, drainageDistrictC, economicDevelopmentRevenueC, educationC, environmentC, equipmentC, federalAidNoteC, fireDistrictC, floodWaterSewerDisposalC, generalPurposeC, grantAnticipationNoteC, healthC, housingC, improvementC, industrialDevelopmentRevenueC, libraryDistrictC, metropolitanDistrictC, miscellaneousC, municipalBondBankC, municipalUtilitiesDistrictC, parkDistrictC, parkingAuthorityC, pollutionControlRevenueC, poolFinancingAuthorityC, promissoryNoteC, publicHousingAuthorityC, publicUtilityDistrictC, racetrackSlashCasinoC, recreationAuthorityC, revenueAnticipationNoteC, sanitationDistrictC, schoolDistrictC, specialAssessmentC, specialObligationC, stateAndFederalAnticipationNoteC, stateAndGrantAnticipationNoteC, stateAnticipationNotesC, stateStoreC, taxAndRevenueAnticipationC, taxAnticipationNoteC, taxRevenueC, temporaryNotesC, transportationC, tribalBondsC, utilityC, variousPurposeC, veteransC, warrantsC]);
export type MuniPurposeClassTypeCU = typeof MuniPurposeClassTypeCU;
export type MuniPurposeClassTypeU = t.TypeOf<MuniPurposeClassTypeCU>;

export const muniPurposeClassTypeOrd: Ord.Ord<MuniPurposeClassTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniPurposeClassType = [authority, bondAnticipationNote, building, capitalLoanNotes, certificatesOfParticipation, corporatePurpose, drainageDistrict, economicDevelopmentRevenue, education, environment, equipment, federalAidNote, fireDistrict, floodWaterSewerDisposal, generalPurpose, grantAnticipationNote, health, housing, improvement, industrialDevelopmentRevenue, libraryDistrict, metropolitanDistrict, miscellaneous, municipalBondBank, municipalUtilitiesDistrict, parkDistrict, parkingAuthority, pollutionControlRevenue, poolFinancingAuthority, promissoryNote, publicHousingAuthority, publicUtilityDistrict, racetrackSlashCasino, recreationAuthority, revenueAnticipationNote, sanitationDistrict, schoolDistrict, specialAssessment, specialObligation, stateAndFederalAnticipationNote, stateAndGrantAnticipationNote, stateAnticipationNotes, stateStore, taxAndRevenueAnticipation, taxAnticipationNote, taxRevenue, temporaryNotes, transportation, tribalBonds, utility, variousPurpose, veterans, warrants] as const;
export type MuniPurposeClassTypeMap<A> = { [K in MuniPurposeClassTypeName]: A };


