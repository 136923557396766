import { withIdC as imported192_withIdC, withModInfoC as imported153_withModInfoC } from "./threadThrough";
import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { contentNoteC as imported3_contentNoteC } from "./contentNote";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const contactPostC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  body: t.string,
  captcha: t.string,
  bondOfferingId: optionFromNullable(t.number),
  rfpId: optionFromNullable(t.number)
});
export type ContactPostC = typeof contactPostC;
export type ContactPost = t.TypeOf<ContactPostC>;


export const contactFormSubmissionC = t.type({
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  firstName: t.string,
  lastName: t.string,
  subject: t.string,
  body: t.string,
  company: optionFromNullable(t.string),
  title: optionFromNullable(t.string),
  userId: optionFromNullable(t.number),
  sent: LocalDateTimeFromIsoStringC
});
export type ContactFormSubmissionC = typeof contactFormSubmissionC;
export type ContactFormSubmission = t.TypeOf<ContactFormSubmissionC>;


export const contactFormSubmissionWithNoteC = t.type({
  contactFormSubmission: contactFormSubmissionC,
  contentNote: optionFromNullable(imported192_withIdC(imported153_withModInfoC(imported3_contentNoteC)))
});
export type ContactFormSubmissionWithNoteC = typeof contactFormSubmissionWithNoteC;
export type ContactFormSubmissionWithNote = t.TypeOf<ContactFormSubmissionWithNoteC>;


