import type { PropsWithChildren } from "react";

import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import * as AssetsRouter from "@scripts/generated/routers/assetsRouter";
import * as corpRouter from "@scripts/generated/routers/corpRouter";
import { Anchor, AnchorWithChildren } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { useConfig } from "@scripts/react/context/Config";
import type { KlassList } from "@scripts/react/util/classnames";
import { klass, klassPropO } from "@scripts/react/util/classnames";
import { useModal } from "@scripts/react/util/useModal";
import { urlInterface } from "@scripts/routes/urlInterface";
import { absoluteUrl } from "@scripts/util/url";

import { DisclaimerModal } from "@scripts-ssr/components/DisclaimerModal";

export const MinimalHeader = (props: { text: string }) =>
  <h2 {...klass("minimal-layout-header")}>{props.text}</h2>;

export const MinimalFormContainer = (props: PropsWithChildren<{ klasses?: KlassList }>) =>
  <div {...klassPropO("d-flex flex-col w-100")(props.klasses)}>
    {props.children}
  </div>;

const MinimalFooter = () => {
  const config = useConfig();
  const [modalOpen, openModal, closeModal] = useModal("Disclaimer Modal");

  return (
    <div {...klass("page-footer")}>
      <ButtonLink {...klass("no-decoration footer-item")} onClick={openModal}>
        Disclaimer
      </ButtonLink>
      <Anchor
        externalLinkLocation={"none"}
        klasses={"no-decoration footer-item"}
        route={{
          title: "Terms",
          route: absoluteUrl(config)(corpRouter.corporateStaticTerms()),
        }}
        target={"_blank"}
      />
      <Anchor
        externalLinkLocation={"none"}
        klasses={"no-decoration footer-item"}
        route={{
          title: "Privacy",
          route: absoluteUrl(config)(corpRouter.corporateStaticPrivacy()),
        }}
        target={"_blank"}
      />
      <span {...klass("bl-copyright footer-item")}>
        &copy;{new Date().getFullYear()} BondLink, Inc.
      </span>
      <DisclaimerModal
        disclaimerType="general"
        dismissAction={closeModal}
        modalOpen={modalOpen}
      />
    </div>
  );
};

export const BLLogoContainer = () => {
  const config = useConfig();
  return <div {...klass("bl-logo-container")}>
    <AnchorWithChildren
      aria-label="BondLink Home Page"
      externalLinkLocation="none"
      klasses={"no-decoration flex-grow"}
      route={{
        title: "BondLink Home Page",
        route: urlInterface("GET", config.baseUrl),
      }}
      target={"_self"}
    >
      <img
        src={AssetsRouter.assetImgLogosBondlink2019Svg().url}
        alt="BondLink Logo"
        {...klass("bl-logo")}
      />
    </AnchorWithChildren>
  </div>;
};

export const MinimalContainer = (props: PropsWithChildren<EmptyObject>) => {
  const config = useConfig();
  return (
    <div {...klass("content-container minimal-container login")}>
      <div {...klass("minimal-form-container d-flex")}>
        <div {...klass("bl-logo-container")}>
          <AnchorWithChildren
            aria-label="BondLink Home Page"
            externalLinkLocation="none"
            klasses={"no-decoration flex-grow"}
            route={{
              title: "BondLink Home Page",
              route: urlInterface("GET", config.baseUrl),
            }}
            target={"_self"}
          >
            <img
              src={AssetsRouter.assetImgLogosBondlink2019Svg().url}
              alt="BondLink Logo"
              {...klass("bl-logo")}
            />
          </AnchorWithChildren>
        </div>
        {props.children}
      </div>
      <MinimalFooter />
    </div>
  );
};
