import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import * as t from "io-ts";
import { SectorCU as imported15_SectorCU } from "../domaintables/sectors";

export const bondProgramC = t.type({
  name: t.string,
  overview: optionFromNullable(markdownC),
  cusip6: optionFromNullable(t.string),
  sector: optionFromNullable(imported15_SectorCU),
  sortOrder: t.number
});
export type BondProgramC = typeof bondProgramC;
export type BondProgram = t.TypeOf<BondProgramC>;


