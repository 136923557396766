import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as NEA from "fp-ts/lib/NonEmptyArray";
import * as t from "io-ts";

const isNonEmptyArray = <C extends t.Mixed>(item: C, i: unknown, context: t.Context) => pipe(
  i,
  t.array(item).decode,
  E.filterOrElse(
    (_): _ is NEA.NonEmptyArray<t.TypeOf<C>> => A.isNonEmpty(_),
    (): t.Errors => [{ value: i, context, message: "NonEmptyArray is invalid, it has no elements" }]
  ),
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class NonEmptyArrayType<C extends t.Mixed, A = any, O = A, I = unknown> extends t.Type<A, O, I> {
  readonly type: C;
  readonly _tag: "NonEmptyArrayType";

  constructor(
    name: string,
    is: NonEmptyArrayType<C, A, O, I>["is"],
    validate: NonEmptyArrayType<C, A, O, I>["validate"],
    encode: NonEmptyArrayType<C, A, O, I>["encode"],
    type: C
  ) {
    super(
      name,
      is,
      validate,
      encode
    );
    this.type = type;
    this._tag = "NonEmptyArrayType";
  }
}

export const nonEmptyArrayC = <C extends t.Mixed>(item: C) => new NonEmptyArrayType<C, NEA.NonEmptyArray<t.TypeOf<C>>, NEA.NonEmptyArray<t.OutputOf<C>>, unknown>(
  `NonEmptyArray<${item.name}>`,
  (i: unknown): i is NEA.NonEmptyArray<t.TypeOf<C>> => t.array(item).is(i) && A.isNonEmpty(i),
  (i: unknown, c: t.Context): t.Validation<NEA.NonEmptyArray<t.TypeOf<C>>> => isNonEmptyArray(item, i, c),
  NEA.map(item.encode),
  item
);
