import type { ReactElement } from "react";
import { createElement } from "react";
import * as O from "fp-ts/lib/Option";
import type * as RNA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as str from "fp-ts/lib/string";
import type { Match, Parser } from "fp-ts-routing/lib";
import { lit, parse, Route } from "fp-ts-routing/lib";
import type * as t from "io-ts";
import { capitalize } from "voca";

import { RA, Struct } from "@scripts/fp-ts";
import { fromNativeReadonlyNonEmptyArray } from "@scripts/fp-ts/ReadonlyNonEmptyArray";
import { last90Days } from "@scripts/generated/domaintables/dateRange";
import type { FeatureU } from "@scripts/generated/domaintables/featureFlags";
import type { RoleU } from "@scripts/generated/domaintables/roles";
import { allRole } from "@scripts/generated/domaintables/roles";
import type { DataMetaBase, UnsafeDataMetaSvg } from "@scripts/meta/dataMeta";
import type { UrlInterface } from "@scripts/routes/urlInterface";
import type { DeepPartialWithOptions } from "@scripts/types/deepPartialWithOptions";
import { tagEq } from "@scripts/util/compare";


export const defaultDateRange = last90Days.days;

export const issuerPortalBase = lit("issuer-portal");

export const investorPortalBase = lit("investor-portal");

export const bankerPortalBase = lit("deal-portal");

export type RolesAllowed = RNA.ReadonlyNonEmptyArray<RoleU>;

export type RouteAuth = {
  banksAllowed: boolean;
  rolesAllowed: RolesAllowed;
  featuresRequired: FeatureU[];
};

export type PageRouting = {
  readonly _tag: string;
  readonly title: () => string;
  readonly url: () => string;
};

export type PageMeta<M> = PageRouting & { authDetails: RouteAuth } & {
  readonly dataMeta: O.Option<DataMetaBase<string> & UnsafeDataMetaSvg>;
  readonly route: Match<M>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyPageMeta = PageMeta<any> | PageMeta<never>;

export const makeDeepPartialParams = <A, O>(codec: t.Type<A, O>, defaults: Required<A>) => (p: O.Option<DeepPartialWithOptions<A>>) =>
  makeParams<A>(defaults)(O.map(a => codec.is(a) ? a : defaults)(p));

export const allowAllAuth: RouteAuth = {
  banksAllowed: true,
  featuresRequired: [],
  rolesAllowed: fromNativeReadonlyNonEmptyArray<RoleU>(allRole),
};

export const renderWithAuthPage = <T extends object>(
  authComponent: (props: {
    authDetails: RouteAuth;
    children: ReactElement;
  }) => React.ReactElement
) => (
  render: (p: T) => ReactElement,
  params: T,
  authDetails: RouteAuth
) => createElement(
  authComponent,
  ({
    children: createElement(render, params),
    authDetails,
  })
    ); export type PageRoutingWithRender = PageRouting & {
      readonly render: () => ReactElement;
    };
type PageRouteBasic = { title: string, route: UrlInterface<"GET"> };
export type PageRouteLink = PageRouting | PageRouteBasic;

export type NavigateFn = (route: PageRouteLink) => void;

export const formatRoute = <A>(match: Match<A>, a: A): Route => match.formatter.run(Route.empty, a);

export function format<A>(match: Match<A>, a: A, encode: boolean = true): string {
  return formatRoute(match, a).toString(encode);
}
function parseLocation<A extends PageRoutingWithRender>(s: string, parser: Parser<A>, notFound: () => A): A {
  return parse(parser, Route.parse(s), notFound());
}

export function getCurrentPage<P extends PageRoutingWithRender>(r: Parser<P>, notFound: () => P): P {
  return parseLocation<P>(window.location.pathname + window.location.search, r, notFound);
}

export const makeTag = (pathParts: ReadonlyArray<string>): string => pathParts.join("-");
export const isPage = tagEq().equals;
export const isSubpage = (subRoutes: ReadonlyArray<PageRouting>, currentPage: PageRouting) => RA.reduce(false, (a: boolean, b: PageRouting) => tagEq().equals(b, currentPage) || a)(subRoutes);
export const isChildPageOf = (ma: PageRouting) => (mb: PageRouting): boolean => str.startsWith(ma._tag, 0)(mb._tag);
export const isExactPage = (ma: PageRouting, mb: PageRouting): boolean => new URL(ma.url(), window.location.origin).toString() === new URL(mb.url(), window.location.origin).toString();

export const renderStrictEquality = (next: PageRouting, current: PageRouting): boolean => isPage(current, next) && isExactPage(current, next);

export function isPageRouting(obj: unknown): obj is PageRouting {
  return Struct.is(obj) && typeof obj["_tag"] === "string" && typeof obj["title"] === "function" && typeof obj["url"] === "function";
}

export function getRouteInfo(rl: PageRouteLink): { url: string, title: string } {
  return isPageRouting(rl) ? { url: rl.url(), title: rl.title() } : { url: rl.route.url, title: rl.title };
}

export const makeParams = <A>(defaults: Required<A>): (p: O.Option<Partial<A>>) => Required<A> =>
  O.fold(
    () => defaults,
    (p1: Partial<A>) => ({ ...defaults, ...p1 })
  );

export const removeDashes = (k: string) => k.split("-").map(_ => capitalize(_)).join(" ");
