import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const about = {
  _tag: `About`,
  id: 1,
  name: `About`
} as const;

export const aboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTaggedC = typeof aboutTaggedC;
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export const aboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
));
export type AboutC = typeof aboutC;


export const project = {
  _tag: `Project`,
  id: 2,
  name: `Project`
} as const;

export const projectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTaggedC = typeof projectTaggedC;
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export const projectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
));
export type ProjectC = typeof projectC;


export const esg = {
  _tag: `Esg`,
  id: 3,
  name: `ESG`
} as const;

export const esgTaggedC = t.type({
  _tag: t.literal(`Esg`)
});
export type EsgTaggedC = typeof esgTaggedC;
export type EsgTagged = t.TypeOf<EsgTaggedC>;
export type Esg = EsgTagged & typeof esg;
export const esgC = pipe(esgTaggedC, c => new t.Type<Esg, EsgTagged>(
  `Esg`,
  (u: unknown): u is Esg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Esg> => pipe(c.decode(u), E.map(x => ({ ...x, ...esg }))),
  (x: Esg): EsgTagged => ({ ...x, _tag: `Esg`}),
));
export type EsgC = typeof esgC;


export const bondProgram = {
  _tag: `BondProgram`,
  id: 4,
  name: `Bond Program`
} as const;

export const bondProgramTaggedC = t.type({
  _tag: t.literal(`BondProgram`)
});
export type BondProgramTaggedC = typeof bondProgramTaggedC;
export type BondProgramTagged = t.TypeOf<BondProgramTaggedC>;
export type BondProgram = BondProgramTagged & typeof bondProgram;
export const bondProgramC = pipe(bondProgramTaggedC, c => new t.Type<BondProgram, BondProgramTagged>(
  `BondProgram`,
  (u: unknown): u is BondProgram => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondProgram> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondProgram }))),
  (x: BondProgram): BondProgramTagged => ({ ...x, _tag: `BondProgram`}),
));
export type BondProgramC = typeof bondProgramC;


export const allCalloutLocationC = [aboutC, projectC, esgC, bondProgramC] as const;
export const allCalloutLocationNames = [`About`, `Project`, `Esg`, `BondProgram`] as const;
export type CalloutLocationName = (typeof allCalloutLocationNames)[number];

export const CalloutLocationCU = t.union([aboutC, projectC, esgC, bondProgramC]);
export type CalloutLocationCU = typeof CalloutLocationCU;
export type CalloutLocationU = t.TypeOf<CalloutLocationCU>;

export const calloutLocationOrd: Ord.Ord<CalloutLocationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCalloutLocation = [about, project, esg, bondProgram] as const;
export type CalloutLocationMap<A> = { [K in CalloutLocationName]: A };


