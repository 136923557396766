import type { O } from "@scripts/fp-ts";
import { AnchorButtonWithChildren, ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { klass } from "@scripts/react/util/classnames";
import type { PageRouting } from "@scripts/routes/routing/base";

import { mapOrEmpty } from "../Empty";

export const NotFound = (props: { route: O.Option<PageRouting> }) =>
  <div {...klass("d-flex", "justify-content-center", "mt-3")}>
    <div {...klass("not-found-content", "d-flex", "flex-col", "align-items-center", "text-center")}>
      <h2 {...klass("mt-05")}>Sorry, we couldn’t find the page you’re looking for.</h2>
      <p {...klass("large", "mb-0")}>
        We got a 404 error, which means this page has been moved or deleted, or
        the URL was mistyped. Double-check to make sure you have the correct URL,
        or <ContactBondLinkAnchor title="email us" /> for help if the problem persists.
      </p>
      {mapOrEmpty((route: PageRouting) =>
        <AnchorButtonWithChildren
          target={"_self"}
          route={route}
          variant="primary"
          externalLinkLocation="none"
        >
          Go to {route.title()}
        </AnchorButtonWithChildren>
      )(props.route)}
    </div>
  </div>;
