import * as t from "io-ts";
import { rfpC as imported64_rfpC } from "./rfpBase";
import { SectorCU as imported21_SectorCU } from "../domaintables/sectors";
import { bondOfferingDetailsC as imported40_bondOfferingDetailsC } from "./bondOffering";
import { withIdC as imported282_withIdC, withIdC as imported283_withIdC } from "./threadThrough";
import { withIssuerC as imported13_withIssuerC, withIssuerC as imported12_withIssuerC, issuerC as imported52_issuerC } from "./issuer";

export const sectorDataC = t.type({
  sector: imported21_SectorCU,
  issuers: t.readonlyArray(imported52_issuerC),
  offerings: t.readonlyArray(imported12_withIssuerC(imported282_withIdC(imported40_bondOfferingDetailsC))),
  rfps: t.readonlyArray(imported13_withIssuerC(imported283_withIdC(imported64_rfpC)))
});
export type SectorDataC = typeof sectorDataC;
export type SectorData = t.TypeOf<SectorDataC>;


