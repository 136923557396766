import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const downloadDoc = {
  _tag: `DownloadDoc`,
  machineReadable: `download-doc`
} as const;

export const downloadDocTaggedC = t.type({
  _tag: t.literal(`DownloadDoc`)
});
export type DownloadDocTaggedC = typeof downloadDocTaggedC;
export type DownloadDocTagged = t.TypeOf<DownloadDocTaggedC>;
export type DownloadDoc = DownloadDocTagged & typeof downloadDoc;
export const downloadDocC = pipe(downloadDocTaggedC, c => new t.Type<DownloadDoc, DownloadDocTagged>(
  `DownloadDoc`,
  (u: unknown): u is DownloadDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DownloadDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...downloadDoc }))),
  (x: DownloadDoc): DownloadDocTagged => ({ ...x, _tag: `DownloadDoc`}),
));
export type DownloadDocC = typeof downloadDocC;


export const viewDoc = {
  _tag: `ViewDoc`,
  machineReadable: `view-doc`
} as const;

export const viewDocTaggedC = t.type({
  _tag: t.literal(`ViewDoc`)
});
export type ViewDocTaggedC = typeof viewDocTaggedC;
export type ViewDocTagged = t.TypeOf<ViewDocTaggedC>;
export type ViewDoc = ViewDocTagged & typeof viewDoc;
export const viewDocC = pipe(viewDocTaggedC, c => new t.Type<ViewDoc, ViewDocTagged>(
  `ViewDoc`,
  (u: unknown): u is ViewDoc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ViewDoc> => pipe(c.decode(u), E.map(x => ({ ...x, ...viewDoc }))),
  (x: ViewDoc): ViewDocTagged => ({ ...x, _tag: `ViewDoc`}),
));
export type ViewDocC = typeof viewDocC;


export const viewBond = {
  _tag: `ViewBond`,
  machineReadable: `view-bond`
} as const;

export const viewBondTaggedC = t.type({
  _tag: t.literal(`ViewBond`)
});
export type ViewBondTaggedC = typeof viewBondTaggedC;
export type ViewBondTagged = t.TypeOf<ViewBondTaggedC>;
export type ViewBond = ViewBondTagged & typeof viewBond;
export const viewBondC = pipe(viewBondTaggedC, c => new t.Type<ViewBond, ViewBondTagged>(
  `ViewBond`,
  (u: unknown): u is ViewBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ViewBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...viewBond }))),
  (x: ViewBond): ViewBondTagged => ({ ...x, _tag: `ViewBond`}),
));
export type ViewBondC = typeof viewBondC;


export const bookmarkBond = {
  _tag: `BookmarkBond`,
  machineReadable: `bookmark-bond`
} as const;

export const bookmarkBondTaggedC = t.type({
  _tag: t.literal(`BookmarkBond`)
});
export type BookmarkBondTaggedC = typeof bookmarkBondTaggedC;
export type BookmarkBondTagged = t.TypeOf<BookmarkBondTaggedC>;
export type BookmarkBond = BookmarkBondTagged & typeof bookmarkBond;
export const bookmarkBondC = pipe(bookmarkBondTaggedC, c => new t.Type<BookmarkBond, BookmarkBondTagged>(
  `BookmarkBond`,
  (u: unknown): u is BookmarkBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BookmarkBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...bookmarkBond }))),
  (x: BookmarkBond): BookmarkBondTagged => ({ ...x, _tag: `BookmarkBond`}),
));
export type BookmarkBondC = typeof bookmarkBondC;


export const bookmarkRfp = {
  _tag: `BookmarkRfp`,
  machineReadable: `bookmark-rfp`
} as const;

export const bookmarkRfpTaggedC = t.type({
  _tag: t.literal(`BookmarkRfp`)
});
export type BookmarkRfpTaggedC = typeof bookmarkRfpTaggedC;
export type BookmarkRfpTagged = t.TypeOf<BookmarkRfpTaggedC>;
export type BookmarkRfp = BookmarkRfpTagged & typeof bookmarkRfp;
export const bookmarkRfpC = pipe(bookmarkRfpTaggedC, c => new t.Type<BookmarkRfp, BookmarkRfpTagged>(
  `BookmarkRfp`,
  (u: unknown): u is BookmarkRfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BookmarkRfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...bookmarkRfp }))),
  (x: BookmarkRfp): BookmarkRfpTagged => ({ ...x, _tag: `BookmarkRfp`}),
));
export type BookmarkRfpC = typeof bookmarkRfpC;


export const roadshow = {
  _tag: `Roadshow`,
  machineReadable: `roadshow`
} as const;

export const roadshowTaggedC = t.type({
  _tag: t.literal(`Roadshow`)
});
export type RoadshowTaggedC = typeof roadshowTaggedC;
export type RoadshowTagged = t.TypeOf<RoadshowTaggedC>;
export type Roadshow = RoadshowTagged & typeof roadshow;
export const roadshowC = pipe(roadshowTaggedC, c => new t.Type<Roadshow, RoadshowTagged>(
  `Roadshow`,
  (u: unknown): u is Roadshow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Roadshow> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshow }))),
  (x: Roadshow): RoadshowTagged => ({ ...x, _tag: `Roadshow`}),
));
export type RoadshowC = typeof roadshowC;


export const notifications = {
  _tag: `Notifications`,
  machineReadable: `notifications`
} as const;

export const notificationsTaggedC = t.type({
  _tag: t.literal(`Notifications`)
});
export type NotificationsTaggedC = typeof notificationsTaggedC;
export type NotificationsTagged = t.TypeOf<NotificationsTaggedC>;
export type Notifications = NotificationsTagged & typeof notifications;
export const notificationsC = pipe(notificationsTaggedC, c => new t.Type<Notifications, NotificationsTagged>(
  `Notifications`,
  (u: unknown): u is Notifications => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Notifications> => pipe(c.decode(u), E.map(x => ({ ...x, ...notifications }))),
  (x: Notifications): NotificationsTagged => ({ ...x, _tag: `Notifications`}),
));
export type NotificationsC = typeof notificationsC;


export const submitRfpBid = {
  _tag: `SubmitRfpBid`,
  machineReadable: `submit-rfp-bid`
} as const;

export const submitRfpBidTaggedC = t.type({
  _tag: t.literal(`SubmitRfpBid`)
});
export type SubmitRfpBidTaggedC = typeof submitRfpBidTaggedC;
export type SubmitRfpBidTagged = t.TypeOf<SubmitRfpBidTaggedC>;
export type SubmitRfpBid = SubmitRfpBidTagged & typeof submitRfpBid;
export const submitRfpBidC = pipe(submitRfpBidTaggedC, c => new t.Type<SubmitRfpBid, SubmitRfpBidTagged>(
  `SubmitRfpBid`,
  (u: unknown): u is SubmitRfpBid => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SubmitRfpBid> => pipe(c.decode(u), E.map(x => ({ ...x, ...submitRfpBid }))),
  (x: SubmitRfpBid): SubmitRfpBidTagged => ({ ...x, _tag: `SubmitRfpBid`}),
));
export type SubmitRfpBidC = typeof submitRfpBidC;


export const allLoginReasonC = [downloadDocC, viewDocC, viewBondC, bookmarkBondC, bookmarkRfpC, roadshowC, notificationsC, submitRfpBidC] as const;
export const allLoginReasonNames = [`DownloadDoc`, `ViewDoc`, `ViewBond`, `BookmarkBond`, `BookmarkRfp`, `Roadshow`, `Notifications`, `SubmitRfpBid`] as const;
export type LoginReasonName = (typeof allLoginReasonNames)[number];

export const LoginReasonCU = t.union([downloadDocC, viewDocC, viewBondC, bookmarkBondC, bookmarkRfpC, roadshowC, notificationsC, submitRfpBidC]);
export type LoginReasonCU = typeof LoginReasonCU;
export type LoginReasonU = t.TypeOf<LoginReasonCU>;

export const loginReasonOrd: Ord.Ord<LoginReasonU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allLoginReason = [downloadDoc, viewDoc, viewBond, bookmarkBond, bookmarkRfp, roadshow, notifications, submitRfpBid] as const;
export type LoginReasonMap<A> = { [K in LoginReasonName]: A };


