import { WithStatusCU as imported247_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { investorPortalOfferingC as imported1_investorPortalOfferingC } from "./investorPortalOffering";
import { clientTextItemC as imported17_clientTextItemC } from "./clientTextItem";

export const bankCalendarDataC = t.type({
  offerings: t.readonlyArray(imported1_investorPortalOfferingC),
  about: optionFromNullable(imported247_WithStatusCU(imported17_clientTextItemC))
});
export type BankCalendarDataC = typeof bankCalendarDataC;
export type BankCalendarData = t.TypeOf<BankCalendarDataC>;


