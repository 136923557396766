import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const postSaleDebrief = {
  _tag: `PostSaleDebrief`,
  id: 1,
  name: `Post-Sale Debrief`
} as const;

export const postSaleDebriefTaggedC = t.type({
  _tag: t.literal(`PostSaleDebrief`)
});
export type PostSaleDebriefTaggedC = typeof postSaleDebriefTaggedC;
export type PostSaleDebriefTagged = t.TypeOf<PostSaleDebriefTaggedC>;
export type PostSaleDebrief = PostSaleDebriefTagged & typeof postSaleDebrief;
export const postSaleDebriefC = pipe(postSaleDebriefTaggedC, c => new t.Type<PostSaleDebrief, PostSaleDebriefTagged>(
  `PostSaleDebrief`,
  (u: unknown): u is PostSaleDebrief => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PostSaleDebrief> => pipe(c.decode(u), E.map(x => ({ ...x, ...postSaleDebrief }))),
  (x: PostSaleDebrief): PostSaleDebriefTagged => ({ ...x, _tag: `PostSaleDebrief`}),
));
export type PostSaleDebriefC = typeof postSaleDebriefC;


export const investorHoldingsReport = {
  _tag: `InvestorHoldingsReport`,
  id: 2,
  name: `Investor Holding Report`
} as const;

export const investorHoldingsReportTaggedC = t.type({
  _tag: t.literal(`InvestorHoldingsReport`)
});
export type InvestorHoldingsReportTaggedC = typeof investorHoldingsReportTaggedC;
export type InvestorHoldingsReportTagged = t.TypeOf<InvestorHoldingsReportTaggedC>;
export type InvestorHoldingsReport = InvestorHoldingsReportTagged & typeof investorHoldingsReport;
export const investorHoldingsReportC = pipe(investorHoldingsReportTaggedC, c => new t.Type<InvestorHoldingsReport, InvestorHoldingsReportTagged>(
  `InvestorHoldingsReport`,
  (u: unknown): u is InvestorHoldingsReport => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InvestorHoldingsReport> => pipe(c.decode(u), E.map(x => ({ ...x, ...investorHoldingsReport }))),
  (x: InvestorHoldingsReport): InvestorHoldingsReportTagged => ({ ...x, _tag: `InvestorHoldingsReport`}),
));
export type InvestorHoldingsReportC = typeof investorHoldingsReportC;


export const buildoutMaterials = {
  _tag: `BuildoutMaterials`,
  id: 3,
  name: `Buildout Materials`
} as const;

export const buildoutMaterialsTaggedC = t.type({
  _tag: t.literal(`BuildoutMaterials`)
});
export type BuildoutMaterialsTaggedC = typeof buildoutMaterialsTaggedC;
export type BuildoutMaterialsTagged = t.TypeOf<BuildoutMaterialsTaggedC>;
export type BuildoutMaterials = BuildoutMaterialsTagged & typeof buildoutMaterials;
export const buildoutMaterialsC = pipe(buildoutMaterialsTaggedC, c => new t.Type<BuildoutMaterials, BuildoutMaterialsTagged>(
  `BuildoutMaterials`,
  (u: unknown): u is BuildoutMaterials => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BuildoutMaterials> => pipe(c.decode(u), E.map(x => ({ ...x, ...buildoutMaterials }))),
  (x: BuildoutMaterials): BuildoutMaterialsTagged => ({ ...x, _tag: `BuildoutMaterials`}),
));
export type BuildoutMaterialsC = typeof buildoutMaterialsC;


export const other = {
  _tag: `Other`,
  id: 4,
  name: `Other`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const allCustomReportTypeC = [postSaleDebriefC, investorHoldingsReportC, buildoutMaterialsC, otherC] as const;
export const allCustomReportTypeNames = [`PostSaleDebrief`, `InvestorHoldingsReport`, `BuildoutMaterials`, `Other`] as const;
export type CustomReportTypeName = (typeof allCustomReportTypeNames)[number];

export const CustomReportTypeCU = t.union([postSaleDebriefC, investorHoldingsReportC, buildoutMaterialsC, otherC]);
export type CustomReportTypeCU = typeof CustomReportTypeCU;
export type CustomReportTypeU = t.TypeOf<CustomReportTypeCU>;

export const customReportTypeOrd: Ord.Ord<CustomReportTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCustomReportType = [postSaleDebrief, investorHoldingsReport, buildoutMaterials, other] as const;
export type CustomReportTypeMap<A> = { [K in CustomReportTypeName]: A };


