import * as t from "io-ts";

export class subscribedCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  data: A1,
  subscribed: t.boolean
})}
export const subscribedC = <A1 extends t.Mixed>(A1: A1) => new subscribedCC<A1>().codec(A1);
export type SubscribedC<A1 extends t.Mixed> = ReturnType<subscribedCC<A1>["codec"]>;
export type Subscribed<A1> = t.TypeOf<SubscribedC<t.Type<A1>>>;

