// This file was generated by postcss-transpiler. Do not edit by hand.

export const loader = {
  ".loader-pill-container": {
    css: ".loader-pill-container",
    html: "loader-pill-container",
    ".loader-pill": {
      css: ".loader-pill",
      html: "loader-pill"
    },
    ".loader-copy": {
      css: ".loader-copy",
      html: "loader-copy"
    },
    ".loader-icon": {
      css: ".loader-icon",
      html: "loader-icon"
    }
  },
  ".modal-body": {
    css: ".modal-body",
    html: "modal-body",
    ".loader-pill-container": {
      css: ".loader-pill-container",
      html: "loader-pill-container"
    },
    ".portal-loading": {
      css: ".portal-loading",
      html: "portal-loading"
    }
  },
  ".loader-bars-container": {
    css: ".loader-bars-container",
    html: "loader-bars-container"
  },
  ".loader-bars": {
    css: ".loader-bars",
    html: "loader-bars",
    ".loader-bar": {
      css: ".loader-bar",
      html: "loader-bar"
    }
  },
  ".btn": {
    css: ".btn",
    html: "btn",
    ".loader-bars": {
      css: ".loader-bars",
      html: "loader-bars",
      ".loader-bar": {
        css: ".loader-bar",
        html: "loader-bar"
      }
    }
  },
  "0%": {
    css: "0%",
    html: "0%"
  },
  "20%": {
    css: "20%",
    html: "20%"
  },
  "40%": {
    css: "40%",
    html: "40%"
  },
  "60%": {
    css: "60%",
    html: "60%"
  },
  "80%": {
    css: "80%",
    html: "80%"
  },
  ".portal-loading": {
    css: ".portal-loading",
    html: "portal-loading",
    ".btn-callout": {
      css: ".btn-callout",
      html: "btn-callout"
    },
    ".chart-root": {
      css: ".chart-root",
      html: "chart-root"
    },
    ".table-root": {
      css: ".table-root",
      html: "table-root"
    }
  },
  ".form-input": {
    css: ".form-input",
    html: "form-input",
    ".portal-loading": {
      css: ".portal-loading",
      html: "portal-loading"
    },
    ".loader-pill-container": {
      css: ".loader-pill-container",
      html: "loader-pill-container"
    }
  }
};
