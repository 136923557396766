import type { IsAny, IsUnknown } from "./lib/types";

export * from "fp-ts/lib/boolean";
import { refinementFor } from "./lib/_internal";

type Not<B extends boolean>
  = IsAny<B> extends true ? never
  : IsUnknown<B> extends true ? never
  : [boolean] extends [B] ? boolean
  : [true] extends [B] ? false
  : [false] extends [B] ? true
  : never;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const not = <B extends boolean>(b: B): Not<B> => !b as Not<B>;

/** @category refinements */
export const is = refinementFor.boolean;
