import * as E from "fp-ts/lib/Either";
import { flow } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import md5 from "md5";

import type { ReactChild } from "@scripts/react/syntax/react";

export type LabelOrAriaLabel = E.Either<string, string>;
export type ReactChildOrAriaLabel = E.Either<ReactChild, string>;

export const uniqueAriaId = (message: Parameters<typeof md5>[0]) => md5(message, { encoding: "string" });
export const idFromLabelOrAriaLabel = (label: LabelOrAriaLabel): string => E.fold(
  uniqueAriaId,
  () => ""
)(label);

export const toLabelString: (label: LabelOrAriaLabel) => string = E.toUnion;
export const getLabel: (label: LabelOrAriaLabel) => O.Option<string> = O.getLeft;
export const getLabelIdO: (label: LabelOrAriaLabel) => O.Option<string> = flow(getLabel, O.map(uniqueAriaId));
