import type { PropsWithChildren } from "react";

import { ConfigO, isProd } from "@scripts/bondlink";
import { pipe } from "@scripts/fp-ts";
import { BondLinkHelpPageAnchor } from "@scripts/react/components/Anchor";
import { ButtonLink } from "@scripts/react/components/Button";
import { Empty, mapOrEmpty } from "@scripts/react/components/Empty";
import { klass } from "@scripts/react/util/classnames";

type ErrorContentProps = PropsWithChildren<{
  resetErrorState: () => void;
}>;

export const ErrorContent = (props: ErrorContentProps) => {
  return <main {...klass("container", "error-page")}>
    <section>
      <div {...klass("d-flex", "justify-content-center")}>
        <div {...klass("d-flex", "flex-col", "align-items-center", "text-center", "error-text", "my-2")}>
          <h2>Apologies, we experienced an error.</h2>
          <p>
            Something on our end is temporarily not working. You can try again later,
            or <BondLinkHelpPageAnchor title="visit our help page" /> to contact us if the problem persists.
          </p>

          <ButtonLink  {...klass("mt-2")} onClick={() => {
            window.history.back();
            props.resetErrorState();
          }}
          >
            &larr; Back to Previous Page
          </ButtonLink>
        </div>
      </div>
      {pipe(
        ConfigO(),
        // eslint-disable-next-line react/jsx-no-useless-fragment
        mapOrEmpty(config => isProd(config) ? <Empty /> : <>{props.children}</>),
      )}
    </section>
  </main>;
};
