import type { PropsWithChildren } from "react";
import { StrictMode } from "react";

import { klass } from "@scripts/react/util/classnames";

import { Empty } from "../Empty";
import { BondlinkBarBody } from "../layout/BondLinkBarBody";
import { Footer } from "../layout/Footer";

type ServerErrorProps = PropsWithChildren<object>;

export const ServerError = (props: ServerErrorProps) => {
  return (
    <StrictMode>
      <div {...klass("portal-header-top-nav")}>
        <BondlinkBarBody
          logoHref="https://www.bondlink.com"
          portalLink={<Empty />}
        />
      </div>
      {props.children}
      <Footer showSupportLink={false} />
    </StrictMode>
  );
};
