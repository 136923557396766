import { Eq, N, O } from "@scripts/fp-ts";
import type { WithIssuer } from "@scripts/generated/models/issuer";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import { allWithStatusNames, type Drafted, type HasManyLink, type Published, type WithId, type WithModInfo, type WithStatusU } from "@scripts/generated/models/threadThrough";

export const statusNames = {
  published: allWithStatusNames[0],
  drafted: allWithStatusNames[1],
};

export function isDrafted<A>(obj: WithStatusU<A>): obj is Drafted<A> {
  return obj._tag === "Drafted";
}

export function isPublished<A>(obj: WithStatusU<A>): obj is Published<A> {
  return obj._tag === "Published";
}

export const parentIdOrId = <A>(a: WithStatusU<A>): number =>
  isDrafted(a) ? O.getOrElse(() => a.data.id)(a.parentId) : a.data.id;

export const parentIdOrIdEq = <A>() => Eq.contramap<number, WithStatusU<A>>(parentIdOrId)(N.Eq);

export const equalsParentIdOrId = (n: number) => <A>(a: WithStatusU<A>): boolean =>
  a.data.id === n || parentIdOrId(a) === n;

export const hasBeenPublished = <A>(a: WithStatusU<A>) => a._tag === "Published" || O.isSome(a.parentId);

export const withStatusFold = <A, B extends WithStatusU<A>, C>(onPublished: (a: B) => C, onDrafted: (a: B) => C, onNeverPublished: (a: B) => C) => (a: B) => {
  if (hasBeenPublished(a)) {
    return onPublished(a);
  } else if (isDrafted(a)) {
    return onDrafted(a);
  } else {
    return onNeverPublished(a);
  }
};

export const getHasManyLinkDraftId = <R, L>(a: HasManyLink<WithStatusU<R>, L>): number => parentIdOrId(a.data);

export const modifyWithId = <A, B>(f: (a: A) => B) => (w: WithId<A>): WithId<B> =>
  ({ ...w, record: f(w.record) });

export const modifyWithStatus = <A, B>(f: (a: A) => B) => (w: WithStatusU<A>): WithStatusU<B> =>
  ({ ...w, data: modifyWithId(f)(w.data) });

export const modifyWithModInfo = <A, B>(f: (a: A) => B) => (w: WithModInfo<A>): WithModInfo<B> =>
  ({ ...w, data: f(w.data) });

export const modifyWithIssuer = <A, B>(f: (a: A) => B) => (w: WithIssuer<A>): WithIssuer<B> =>
  ({ ...w, unWithIssuer: f(w.unWithIssuer) });

export const withStatusValueEq = Eq.contramap(
  <A>(_: WithStatusU<A>) => _.data.record
);

export const withModInfoValueEq = Eq.contramap(
  <A>(_: WithModInfo<A>) => _.data
);

export const hasManyLinkDataEq = Eq.contramap(
  <A1, A2>(_: HasManyLink<A1, A2>) => _.data
);

export const removeModInfo =
  <A>(x: WithStatusU<WithModInfo<A>>): WithStatusU<A> => ({
    ...x,
    data: {
      ...x.data,
      record: x.data.record.data,
    },
  });

export const taggedContentDataEq = Eq.contramap(
  <A>(_: TaggedContent<A>) => _.data
);

export const withIdValueEq = Eq.contramap(
  <A>(_: WithId<A>) => _.record
);
