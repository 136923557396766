/// <reference types="@types/gtag.js" />
import * as A from "fp-ts/lib/Array";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as R from "fp-ts/lib/Record";
import V from "voca";

import type { BLConfigWithLog } from "./bondlink";
import type { PortalTypeU } from "./bootstrap";
import { fetchUnsafeResp } from "./fetch";
import { allGACustomDimension } from "./generated/models/analytics";
import type { UrlInterface } from "./routes/urlInterface";

// Real-time reporting is delayed. Attempts to test with it will result in inconclusive results.
// First add "debug_mode": true, to the config object
// Go to Analytics > Admin > DebugView
// This shows what is being sent to GA in real-time
// You can also get an overview of all tag activity through Google Tag Manager's Preview mode
// If you need to test a specific property modify dev config in slim-shared/src/main/scala/bondlink/shared/env/Config.scala
// This data is true to how it is sent to GA, but it is not aggregated in the same way
// The true data is aggregated whenever GA feels like it, so easiest to test and make sure it is working in the real-time view, then wait until tomorrow to see if it is aggregated correctly

type Fields = {
  trackingId: string;
};

type GAProps = {
  GA: {
    fields: Fields;
    dimensions: Record<string, string>;
    sendPageView: boolean;
  };
};

type GAWindow = Window & GAProps;

export const gtag: Gtag.Gtag = (...args: ReadonlyArray<unknown>) => {
  if (typeof window.gtag === "function") {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
    (window.gtag as any)(...args);
  }
};

export type ContentGroupU = "Portals" | "Issuer Sites" | "Bank Sites" | "Admin";
export type ContentSubGroup = PortalTypeU
  | "BLP Offering Page"
  | "Calendar Page"
  | "Direct Offering Page"
  | "RFP Page"
  | "IR Page"
  | "User"
  | "Deal View"
  | "Admin"
  | "Not Found";

const createTracker = (GA: GAWindow["GA"], contentGroup: ContentGroupU, contentSubGroup: ContentSubGroup) => {
  type CustomMapDimension = typeof allGACustomDimension[number];
  type CustomMapTag<A extends CustomMapDimension> = A["name"];
  type CustomMap<A extends CustomMapDimension> = { [K in CustomMapTag<A>]: A["_tag"] };

  // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter, @typescript-eslint/consistent-type-assertions
  const customMap = allGACustomDimension.reduce<CustomMap<typeof allGACustomDimension[number]>>((acc, d) => ({ ...acc, [d.name]: V.snakeCase(d._tag) }), {} as unknown as CustomMap<typeof allGACustomDimension[number]>);
  const customMapVals = R.keys(customMap).reduce((acc, k) =>
    GA.dimensions[k] ? { ...acc, [customMap[k]]: GA.dimensions[k] } : acc
    // eslint-disable-next-line camelcase
    , { user_id: GA.dimensions["dimension2"] });

  gtag("config", GA.fields.trackingId, {
    "send_page_view": GA.sendPageView,
    "custom_map": customMap,
    "cookie_flags": "SameSite=None; Secure",
    "site_speed_sample_rate": 100,
    "user_properties": customMapVals,
    "content_group": contentGroup,
    "content_group2": contentSubGroup,
  });
};

export const gtagPageView = (pageTitle: string, url: string): void =>
  gtag("event", "page_view", {
    "page_path": url,
    "page_title": pageTitle,
  });

export const blPageView = (config: BLConfigWithLog, url: string, trackPageview: (p: { url: string }) => UrlInterface<"GET">): void => {
  fetchUnsafeResp(config)(trackPageview({ url }))();
};

type EventCategories = "button" | "checkbox" | "link" | "card" | "download" | "video-play";
export const gtagClickEvent = (category: EventCategories) => (label: string): void =>
  gtag("event", "click", {
    "event_category": category,
    "event_label": label,
  });

export const gtagModalEvent = (label: string, open: boolean): void =>
  gtag("event", "modal", {
    "event_category": open ? "modal-opened" : "modal-closed",
    "event_label": label,
  });

export const analytics = (contentGroup: ContentGroupU, contentSubGroup: ContentSubGroup) => pipe(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  O.fromNullable((globalThis as unknown as GAWindow).GA),
  O.map((GA: GAWindow["GA"]) => createTracker(GA, contentGroup, contentSubGroup))
);

const videoEngagementEventHandler = (src: string) => (ev: Event) => {
  if (ev.target instanceof HTMLVideoElement) {
    gtag("event", "video_engagement", {
      action: ev.type,
      src: src,
      currentTime: ev.target.currentTime,
      muted: ev.target.muted,
    });
  } else {
    gtag("event", "video_engagement", {
      action: ev.type,
      src: src,
    });
  }
};

const getVideoSrcsFromChildNodes = (childNodes: NodeListOf<ChildNode>) => pipe(
  childNodes,
  Array.from,
  A.filterMap((node) => node instanceof HTMLSourceElement ? O.some(node.src.substring(node.src.lastIndexOf("/") + 1)) : O.none),
).join(", ");

const onVideoBeforeUnload = (videos: HTMLVideoElement[]) => () => {
  videos.forEach((video) => {
    gtag("event", "video_engagement", {
      action: "page_unload",
      src: getVideoSrcsFromChildNodes(video.childNodes),
      currentTime: video.currentTime,
      muted: video.muted,
    });
  });
};

export const analyticsVideoTracker = () => {
  const videos = Array.from(document.getElementsByTagName("video"));
  videos.forEach((video) => {
    const videoEventHandler = videoEngagementEventHandler(getVideoSrcsFromChildNodes(video.childNodes));
    window.onbeforeunload = onVideoBeforeUnload(videos);

    video.addEventListener("play", videoEventHandler, { capture: true });
    video.addEventListener("pause", videoEventHandler, { capture: true });
    video.addEventListener("ended", videoEventHandler, { capture: true });
  });
};
