import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const alabama = {
  _tag: `Alabama`,
  abbrev: `AL`,
  id: 1,
  name: `Alabama`
} as const;

export const alabamaTaggedC = t.type({
  _tag: t.literal(`Alabama`)
});
export type AlabamaTaggedC = typeof alabamaTaggedC;
export type AlabamaTagged = t.TypeOf<AlabamaTaggedC>;
export type Alabama = AlabamaTagged & typeof alabama;
export const alabamaC = pipe(alabamaTaggedC, c => new t.Type<Alabama, AlabamaTagged>(
  `Alabama`,
  (u: unknown): u is Alabama => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Alabama> => pipe(c.decode(u), E.map(x => ({ ...x, ...alabama }))),
  (x: Alabama): AlabamaTagged => ({ ...x, _tag: `Alabama`}),
));
export type AlabamaC = typeof alabamaC;


export const alaska = {
  _tag: `Alaska`,
  abbrev: `AK`,
  id: 2,
  name: `Alaska`
} as const;

export const alaskaTaggedC = t.type({
  _tag: t.literal(`Alaska`)
});
export type AlaskaTaggedC = typeof alaskaTaggedC;
export type AlaskaTagged = t.TypeOf<AlaskaTaggedC>;
export type Alaska = AlaskaTagged & typeof alaska;
export const alaskaC = pipe(alaskaTaggedC, c => new t.Type<Alaska, AlaskaTagged>(
  `Alaska`,
  (u: unknown): u is Alaska => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Alaska> => pipe(c.decode(u), E.map(x => ({ ...x, ...alaska }))),
  (x: Alaska): AlaskaTagged => ({ ...x, _tag: `Alaska`}),
));
export type AlaskaC = typeof alaskaC;


export const alberta = {
  _tag: `Alberta`,
  abbrev: `AB`,
  id: 3,
  name: `Alberta`
} as const;

export const albertaTaggedC = t.type({
  _tag: t.literal(`Alberta`)
});
export type AlbertaTaggedC = typeof albertaTaggedC;
export type AlbertaTagged = t.TypeOf<AlbertaTaggedC>;
export type Alberta = AlbertaTagged & typeof alberta;
export const albertaC = pipe(albertaTaggedC, c => new t.Type<Alberta, AlbertaTagged>(
  `Alberta`,
  (u: unknown): u is Alberta => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Alberta> => pipe(c.decode(u), E.map(x => ({ ...x, ...alberta }))),
  (x: Alberta): AlbertaTagged => ({ ...x, _tag: `Alberta`}),
));
export type AlbertaC = typeof albertaC;


export const arizona = {
  _tag: `Arizona`,
  abbrev: `AZ`,
  id: 4,
  name: `Arizona`
} as const;

export const arizonaTaggedC = t.type({
  _tag: t.literal(`Arizona`)
});
export type ArizonaTaggedC = typeof arizonaTaggedC;
export type ArizonaTagged = t.TypeOf<ArizonaTaggedC>;
export type Arizona = ArizonaTagged & typeof arizona;
export const arizonaC = pipe(arizonaTaggedC, c => new t.Type<Arizona, ArizonaTagged>(
  `Arizona`,
  (u: unknown): u is Arizona => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Arizona> => pipe(c.decode(u), E.map(x => ({ ...x, ...arizona }))),
  (x: Arizona): ArizonaTagged => ({ ...x, _tag: `Arizona`}),
));
export type ArizonaC = typeof arizonaC;


export const arkansas = {
  _tag: `Arkansas`,
  abbrev: `AR`,
  id: 5,
  name: `Arkansas`
} as const;

export const arkansasTaggedC = t.type({
  _tag: t.literal(`Arkansas`)
});
export type ArkansasTaggedC = typeof arkansasTaggedC;
export type ArkansasTagged = t.TypeOf<ArkansasTaggedC>;
export type Arkansas = ArkansasTagged & typeof arkansas;
export const arkansasC = pipe(arkansasTaggedC, c => new t.Type<Arkansas, ArkansasTagged>(
  `Arkansas`,
  (u: unknown): u is Arkansas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Arkansas> => pipe(c.decode(u), E.map(x => ({ ...x, ...arkansas }))),
  (x: Arkansas): ArkansasTagged => ({ ...x, _tag: `Arkansas`}),
));
export type ArkansasC = typeof arkansasC;


export const britishColumbia = {
  _tag: `BritishColumbia`,
  abbrev: `BC`,
  id: 6,
  name: `British Columbia`
} as const;

export const britishColumbiaTaggedC = t.type({
  _tag: t.literal(`BritishColumbia`)
});
export type BritishColumbiaTaggedC = typeof britishColumbiaTaggedC;
export type BritishColumbiaTagged = t.TypeOf<BritishColumbiaTaggedC>;
export type BritishColumbia = BritishColumbiaTagged & typeof britishColumbia;
export const britishColumbiaC = pipe(britishColumbiaTaggedC, c => new t.Type<BritishColumbia, BritishColumbiaTagged>(
  `BritishColumbia`,
  (u: unknown): u is BritishColumbia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BritishColumbia> => pipe(c.decode(u), E.map(x => ({ ...x, ...britishColumbia }))),
  (x: BritishColumbia): BritishColumbiaTagged => ({ ...x, _tag: `BritishColumbia`}),
));
export type BritishColumbiaC = typeof britishColumbiaC;


export const california = {
  _tag: `California`,
  abbrev: `CA`,
  id: 7,
  name: `California`
} as const;

export const californiaTaggedC = t.type({
  _tag: t.literal(`California`)
});
export type CaliforniaTaggedC = typeof californiaTaggedC;
export type CaliforniaTagged = t.TypeOf<CaliforniaTaggedC>;
export type California = CaliforniaTagged & typeof california;
export const californiaC = pipe(californiaTaggedC, c => new t.Type<California, CaliforniaTagged>(
  `California`,
  (u: unknown): u is California => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, California> => pipe(c.decode(u), E.map(x => ({ ...x, ...california }))),
  (x: California): CaliforniaTagged => ({ ...x, _tag: `California`}),
));
export type CaliforniaC = typeof californiaC;


export const colorado = {
  _tag: `Colorado`,
  abbrev: `CO`,
  id: 8,
  name: `Colorado`
} as const;

export const coloradoTaggedC = t.type({
  _tag: t.literal(`Colorado`)
});
export type ColoradoTaggedC = typeof coloradoTaggedC;
export type ColoradoTagged = t.TypeOf<ColoradoTaggedC>;
export type Colorado = ColoradoTagged & typeof colorado;
export const coloradoC = pipe(coloradoTaggedC, c => new t.Type<Colorado, ColoradoTagged>(
  `Colorado`,
  (u: unknown): u is Colorado => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Colorado> => pipe(c.decode(u), E.map(x => ({ ...x, ...colorado }))),
  (x: Colorado): ColoradoTagged => ({ ...x, _tag: `Colorado`}),
));
export type ColoradoC = typeof coloradoC;


export const connecticut = {
  _tag: `Connecticut`,
  abbrev: `CT`,
  id: 9,
  name: `Connecticut`
} as const;

export const connecticutTaggedC = t.type({
  _tag: t.literal(`Connecticut`)
});
export type ConnecticutTaggedC = typeof connecticutTaggedC;
export type ConnecticutTagged = t.TypeOf<ConnecticutTaggedC>;
export type Connecticut = ConnecticutTagged & typeof connecticut;
export const connecticutC = pipe(connecticutTaggedC, c => new t.Type<Connecticut, ConnecticutTagged>(
  `Connecticut`,
  (u: unknown): u is Connecticut => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Connecticut> => pipe(c.decode(u), E.map(x => ({ ...x, ...connecticut }))),
  (x: Connecticut): ConnecticutTagged => ({ ...x, _tag: `Connecticut`}),
));
export type ConnecticutC = typeof connecticutC;


export const delaware = {
  _tag: `Delaware`,
  abbrev: `DE`,
  id: 10,
  name: `Delaware`
} as const;

export const delawareTaggedC = t.type({
  _tag: t.literal(`Delaware`)
});
export type DelawareTaggedC = typeof delawareTaggedC;
export type DelawareTagged = t.TypeOf<DelawareTaggedC>;
export type Delaware = DelawareTagged & typeof delaware;
export const delawareC = pipe(delawareTaggedC, c => new t.Type<Delaware, DelawareTagged>(
  `Delaware`,
  (u: unknown): u is Delaware => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Delaware> => pipe(c.decode(u), E.map(x => ({ ...x, ...delaware }))),
  (x: Delaware): DelawareTagged => ({ ...x, _tag: `Delaware`}),
));
export type DelawareC = typeof delawareC;


export const districtOfColumbia = {
  _tag: `DistrictOfColumbia`,
  abbrev: `DC`,
  id: 11,
  name: `District of Columbia`
} as const;

export const districtOfColumbiaTaggedC = t.type({
  _tag: t.literal(`DistrictOfColumbia`)
});
export type DistrictOfColumbiaTaggedC = typeof districtOfColumbiaTaggedC;
export type DistrictOfColumbiaTagged = t.TypeOf<DistrictOfColumbiaTaggedC>;
export type DistrictOfColumbia = DistrictOfColumbiaTagged & typeof districtOfColumbia;
export const districtOfColumbiaC = pipe(districtOfColumbiaTaggedC, c => new t.Type<DistrictOfColumbia, DistrictOfColumbiaTagged>(
  `DistrictOfColumbia`,
  (u: unknown): u is DistrictOfColumbia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DistrictOfColumbia> => pipe(c.decode(u), E.map(x => ({ ...x, ...districtOfColumbia }))),
  (x: DistrictOfColumbia): DistrictOfColumbiaTagged => ({ ...x, _tag: `DistrictOfColumbia`}),
));
export type DistrictOfColumbiaC = typeof districtOfColumbiaC;


export const florida = {
  _tag: `Florida`,
  abbrev: `FL`,
  id: 12,
  name: `Florida`
} as const;

export const floridaTaggedC = t.type({
  _tag: t.literal(`Florida`)
});
export type FloridaTaggedC = typeof floridaTaggedC;
export type FloridaTagged = t.TypeOf<FloridaTaggedC>;
export type Florida = FloridaTagged & typeof florida;
export const floridaC = pipe(floridaTaggedC, c => new t.Type<Florida, FloridaTagged>(
  `Florida`,
  (u: unknown): u is Florida => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Florida> => pipe(c.decode(u), E.map(x => ({ ...x, ...florida }))),
  (x: Florida): FloridaTagged => ({ ...x, _tag: `Florida`}),
));
export type FloridaC = typeof floridaC;


export const georgia = {
  _tag: `Georgia`,
  abbrev: `GA`,
  id: 13,
  name: `Georgia`
} as const;

export const georgiaTaggedC = t.type({
  _tag: t.literal(`Georgia`)
});
export type GeorgiaTaggedC = typeof georgiaTaggedC;
export type GeorgiaTagged = t.TypeOf<GeorgiaTaggedC>;
export type Georgia = GeorgiaTagged & typeof georgia;
export const georgiaC = pipe(georgiaTaggedC, c => new t.Type<Georgia, GeorgiaTagged>(
  `Georgia`,
  (u: unknown): u is Georgia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Georgia> => pipe(c.decode(u), E.map(x => ({ ...x, ...georgia }))),
  (x: Georgia): GeorgiaTagged => ({ ...x, _tag: `Georgia`}),
));
export type GeorgiaC = typeof georgiaC;


export const hawaii = {
  _tag: `Hawaii`,
  abbrev: `HI`,
  id: 14,
  name: `Hawaii`
} as const;

export const hawaiiTaggedC = t.type({
  _tag: t.literal(`Hawaii`)
});
export type HawaiiTaggedC = typeof hawaiiTaggedC;
export type HawaiiTagged = t.TypeOf<HawaiiTaggedC>;
export type Hawaii = HawaiiTagged & typeof hawaii;
export const hawaiiC = pipe(hawaiiTaggedC, c => new t.Type<Hawaii, HawaiiTagged>(
  `Hawaii`,
  (u: unknown): u is Hawaii => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Hawaii> => pipe(c.decode(u), E.map(x => ({ ...x, ...hawaii }))),
  (x: Hawaii): HawaiiTagged => ({ ...x, _tag: `Hawaii`}),
));
export type HawaiiC = typeof hawaiiC;


export const idaho = {
  _tag: `Idaho`,
  abbrev: `ID`,
  id: 15,
  name: `Idaho`
} as const;

export const idahoTaggedC = t.type({
  _tag: t.literal(`Idaho`)
});
export type IdahoTaggedC = typeof idahoTaggedC;
export type IdahoTagged = t.TypeOf<IdahoTaggedC>;
export type Idaho = IdahoTagged & typeof idaho;
export const idahoC = pipe(idahoTaggedC, c => new t.Type<Idaho, IdahoTagged>(
  `Idaho`,
  (u: unknown): u is Idaho => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Idaho> => pipe(c.decode(u), E.map(x => ({ ...x, ...idaho }))),
  (x: Idaho): IdahoTagged => ({ ...x, _tag: `Idaho`}),
));
export type IdahoC = typeof idahoC;


export const illinois = {
  _tag: `Illinois`,
  abbrev: `IL`,
  id: 16,
  name: `Illinois`
} as const;

export const illinoisTaggedC = t.type({
  _tag: t.literal(`Illinois`)
});
export type IllinoisTaggedC = typeof illinoisTaggedC;
export type IllinoisTagged = t.TypeOf<IllinoisTaggedC>;
export type Illinois = IllinoisTagged & typeof illinois;
export const illinoisC = pipe(illinoisTaggedC, c => new t.Type<Illinois, IllinoisTagged>(
  `Illinois`,
  (u: unknown): u is Illinois => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Illinois> => pipe(c.decode(u), E.map(x => ({ ...x, ...illinois }))),
  (x: Illinois): IllinoisTagged => ({ ...x, _tag: `Illinois`}),
));
export type IllinoisC = typeof illinoisC;


export const indiana = {
  _tag: `Indiana`,
  abbrev: `IN`,
  id: 17,
  name: `Indiana`
} as const;

export const indianaTaggedC = t.type({
  _tag: t.literal(`Indiana`)
});
export type IndianaTaggedC = typeof indianaTaggedC;
export type IndianaTagged = t.TypeOf<IndianaTaggedC>;
export type Indiana = IndianaTagged & typeof indiana;
export const indianaC = pipe(indianaTaggedC, c => new t.Type<Indiana, IndianaTagged>(
  `Indiana`,
  (u: unknown): u is Indiana => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Indiana> => pipe(c.decode(u), E.map(x => ({ ...x, ...indiana }))),
  (x: Indiana): IndianaTagged => ({ ...x, _tag: `Indiana`}),
));
export type IndianaC = typeof indianaC;


export const iowa = {
  _tag: `Iowa`,
  abbrev: `IA`,
  id: 18,
  name: `Iowa`
} as const;

export const iowaTaggedC = t.type({
  _tag: t.literal(`Iowa`)
});
export type IowaTaggedC = typeof iowaTaggedC;
export type IowaTagged = t.TypeOf<IowaTaggedC>;
export type Iowa = IowaTagged & typeof iowa;
export const iowaC = pipe(iowaTaggedC, c => new t.Type<Iowa, IowaTagged>(
  `Iowa`,
  (u: unknown): u is Iowa => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Iowa> => pipe(c.decode(u), E.map(x => ({ ...x, ...iowa }))),
  (x: Iowa): IowaTagged => ({ ...x, _tag: `Iowa`}),
));
export type IowaC = typeof iowaC;


export const kansas = {
  _tag: `Kansas`,
  abbrev: `KS`,
  id: 19,
  name: `Kansas`
} as const;

export const kansasTaggedC = t.type({
  _tag: t.literal(`Kansas`)
});
export type KansasTaggedC = typeof kansasTaggedC;
export type KansasTagged = t.TypeOf<KansasTaggedC>;
export type Kansas = KansasTagged & typeof kansas;
export const kansasC = pipe(kansasTaggedC, c => new t.Type<Kansas, KansasTagged>(
  `Kansas`,
  (u: unknown): u is Kansas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Kansas> => pipe(c.decode(u), E.map(x => ({ ...x, ...kansas }))),
  (x: Kansas): KansasTagged => ({ ...x, _tag: `Kansas`}),
));
export type KansasC = typeof kansasC;


export const kentucky = {
  _tag: `Kentucky`,
  abbrev: `KY`,
  id: 20,
  name: `Kentucky`
} as const;

export const kentuckyTaggedC = t.type({
  _tag: t.literal(`Kentucky`)
});
export type KentuckyTaggedC = typeof kentuckyTaggedC;
export type KentuckyTagged = t.TypeOf<KentuckyTaggedC>;
export type Kentucky = KentuckyTagged & typeof kentucky;
export const kentuckyC = pipe(kentuckyTaggedC, c => new t.Type<Kentucky, KentuckyTagged>(
  `Kentucky`,
  (u: unknown): u is Kentucky => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Kentucky> => pipe(c.decode(u), E.map(x => ({ ...x, ...kentucky }))),
  (x: Kentucky): KentuckyTagged => ({ ...x, _tag: `Kentucky`}),
));
export type KentuckyC = typeof kentuckyC;


export const louisiana = {
  _tag: `Louisiana`,
  abbrev: `LA`,
  id: 21,
  name: `Louisiana`
} as const;

export const louisianaTaggedC = t.type({
  _tag: t.literal(`Louisiana`)
});
export type LouisianaTaggedC = typeof louisianaTaggedC;
export type LouisianaTagged = t.TypeOf<LouisianaTaggedC>;
export type Louisiana = LouisianaTagged & typeof louisiana;
export const louisianaC = pipe(louisianaTaggedC, c => new t.Type<Louisiana, LouisianaTagged>(
  `Louisiana`,
  (u: unknown): u is Louisiana => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Louisiana> => pipe(c.decode(u), E.map(x => ({ ...x, ...louisiana }))),
  (x: Louisiana): LouisianaTagged => ({ ...x, _tag: `Louisiana`}),
));
export type LouisianaC = typeof louisianaC;


export const maine = {
  _tag: `Maine`,
  abbrev: `ME`,
  id: 22,
  name: `Maine`
} as const;

export const maineTaggedC = t.type({
  _tag: t.literal(`Maine`)
});
export type MaineTaggedC = typeof maineTaggedC;
export type MaineTagged = t.TypeOf<MaineTaggedC>;
export type Maine = MaineTagged & typeof maine;
export const maineC = pipe(maineTaggedC, c => new t.Type<Maine, MaineTagged>(
  `Maine`,
  (u: unknown): u is Maine => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Maine> => pipe(c.decode(u), E.map(x => ({ ...x, ...maine }))),
  (x: Maine): MaineTagged => ({ ...x, _tag: `Maine`}),
));
export type MaineC = typeof maineC;


export const manitoba = {
  _tag: `Manitoba`,
  abbrev: `MB`,
  id: 23,
  name: `Manitoba`
} as const;

export const manitobaTaggedC = t.type({
  _tag: t.literal(`Manitoba`)
});
export type ManitobaTaggedC = typeof manitobaTaggedC;
export type ManitobaTagged = t.TypeOf<ManitobaTaggedC>;
export type Manitoba = ManitobaTagged & typeof manitoba;
export const manitobaC = pipe(manitobaTaggedC, c => new t.Type<Manitoba, ManitobaTagged>(
  `Manitoba`,
  (u: unknown): u is Manitoba => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Manitoba> => pipe(c.decode(u), E.map(x => ({ ...x, ...manitoba }))),
  (x: Manitoba): ManitobaTagged => ({ ...x, _tag: `Manitoba`}),
));
export type ManitobaC = typeof manitobaC;


export const maryland = {
  _tag: `Maryland`,
  abbrev: `MD`,
  id: 24,
  name: `Maryland`
} as const;

export const marylandTaggedC = t.type({
  _tag: t.literal(`Maryland`)
});
export type MarylandTaggedC = typeof marylandTaggedC;
export type MarylandTagged = t.TypeOf<MarylandTaggedC>;
export type Maryland = MarylandTagged & typeof maryland;
export const marylandC = pipe(marylandTaggedC, c => new t.Type<Maryland, MarylandTagged>(
  `Maryland`,
  (u: unknown): u is Maryland => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Maryland> => pipe(c.decode(u), E.map(x => ({ ...x, ...maryland }))),
  (x: Maryland): MarylandTagged => ({ ...x, _tag: `Maryland`}),
));
export type MarylandC = typeof marylandC;


export const massachusetts = {
  _tag: `Massachusetts`,
  abbrev: `MA`,
  id: 25,
  name: `Massachusetts`
} as const;

export const massachusettsTaggedC = t.type({
  _tag: t.literal(`Massachusetts`)
});
export type MassachusettsTaggedC = typeof massachusettsTaggedC;
export type MassachusettsTagged = t.TypeOf<MassachusettsTaggedC>;
export type Massachusetts = MassachusettsTagged & typeof massachusetts;
export const massachusettsC = pipe(massachusettsTaggedC, c => new t.Type<Massachusetts, MassachusettsTagged>(
  `Massachusetts`,
  (u: unknown): u is Massachusetts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Massachusetts> => pipe(c.decode(u), E.map(x => ({ ...x, ...massachusetts }))),
  (x: Massachusetts): MassachusettsTagged => ({ ...x, _tag: `Massachusetts`}),
));
export type MassachusettsC = typeof massachusettsC;


export const michigan = {
  _tag: `Michigan`,
  abbrev: `MI`,
  id: 26,
  name: `Michigan`
} as const;

export const michiganTaggedC = t.type({
  _tag: t.literal(`Michigan`)
});
export type MichiganTaggedC = typeof michiganTaggedC;
export type MichiganTagged = t.TypeOf<MichiganTaggedC>;
export type Michigan = MichiganTagged & typeof michigan;
export const michiganC = pipe(michiganTaggedC, c => new t.Type<Michigan, MichiganTagged>(
  `Michigan`,
  (u: unknown): u is Michigan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Michigan> => pipe(c.decode(u), E.map(x => ({ ...x, ...michigan }))),
  (x: Michigan): MichiganTagged => ({ ...x, _tag: `Michigan`}),
));
export type MichiganC = typeof michiganC;


export const minnesota = {
  _tag: `Minnesota`,
  abbrev: `MN`,
  id: 27,
  name: `Minnesota`
} as const;

export const minnesotaTaggedC = t.type({
  _tag: t.literal(`Minnesota`)
});
export type MinnesotaTaggedC = typeof minnesotaTaggedC;
export type MinnesotaTagged = t.TypeOf<MinnesotaTaggedC>;
export type Minnesota = MinnesotaTagged & typeof minnesota;
export const minnesotaC = pipe(minnesotaTaggedC, c => new t.Type<Minnesota, MinnesotaTagged>(
  `Minnesota`,
  (u: unknown): u is Minnesota => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Minnesota> => pipe(c.decode(u), E.map(x => ({ ...x, ...minnesota }))),
  (x: Minnesota): MinnesotaTagged => ({ ...x, _tag: `Minnesota`}),
));
export type MinnesotaC = typeof minnesotaC;


export const mississippi = {
  _tag: `Mississippi`,
  abbrev: `MS`,
  id: 28,
  name: `Mississippi`
} as const;

export const mississippiTaggedC = t.type({
  _tag: t.literal(`Mississippi`)
});
export type MississippiTaggedC = typeof mississippiTaggedC;
export type MississippiTagged = t.TypeOf<MississippiTaggedC>;
export type Mississippi = MississippiTagged & typeof mississippi;
export const mississippiC = pipe(mississippiTaggedC, c => new t.Type<Mississippi, MississippiTagged>(
  `Mississippi`,
  (u: unknown): u is Mississippi => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Mississippi> => pipe(c.decode(u), E.map(x => ({ ...x, ...mississippi }))),
  (x: Mississippi): MississippiTagged => ({ ...x, _tag: `Mississippi`}),
));
export type MississippiC = typeof mississippiC;


export const missouri = {
  _tag: `Missouri`,
  abbrev: `MO`,
  id: 29,
  name: `Missouri`
} as const;

export const missouriTaggedC = t.type({
  _tag: t.literal(`Missouri`)
});
export type MissouriTaggedC = typeof missouriTaggedC;
export type MissouriTagged = t.TypeOf<MissouriTaggedC>;
export type Missouri = MissouriTagged & typeof missouri;
export const missouriC = pipe(missouriTaggedC, c => new t.Type<Missouri, MissouriTagged>(
  `Missouri`,
  (u: unknown): u is Missouri => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Missouri> => pipe(c.decode(u), E.map(x => ({ ...x, ...missouri }))),
  (x: Missouri): MissouriTagged => ({ ...x, _tag: `Missouri`}),
));
export type MissouriC = typeof missouriC;


export const montana = {
  _tag: `Montana`,
  abbrev: `MT`,
  id: 30,
  name: `Montana`
} as const;

export const montanaTaggedC = t.type({
  _tag: t.literal(`Montana`)
});
export type MontanaTaggedC = typeof montanaTaggedC;
export type MontanaTagged = t.TypeOf<MontanaTaggedC>;
export type Montana = MontanaTagged & typeof montana;
export const montanaC = pipe(montanaTaggedC, c => new t.Type<Montana, MontanaTagged>(
  `Montana`,
  (u: unknown): u is Montana => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Montana> => pipe(c.decode(u), E.map(x => ({ ...x, ...montana }))),
  (x: Montana): MontanaTagged => ({ ...x, _tag: `Montana`}),
));
export type MontanaC = typeof montanaC;


export const nebraska = {
  _tag: `Nebraska`,
  abbrev: `NE`,
  id: 31,
  name: `Nebraska`
} as const;

export const nebraskaTaggedC = t.type({
  _tag: t.literal(`Nebraska`)
});
export type NebraskaTaggedC = typeof nebraskaTaggedC;
export type NebraskaTagged = t.TypeOf<NebraskaTaggedC>;
export type Nebraska = NebraskaTagged & typeof nebraska;
export const nebraskaC = pipe(nebraskaTaggedC, c => new t.Type<Nebraska, NebraskaTagged>(
  `Nebraska`,
  (u: unknown): u is Nebraska => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Nebraska> => pipe(c.decode(u), E.map(x => ({ ...x, ...nebraska }))),
  (x: Nebraska): NebraskaTagged => ({ ...x, _tag: `Nebraska`}),
));
export type NebraskaC = typeof nebraskaC;


export const nevada = {
  _tag: `Nevada`,
  abbrev: `NV`,
  id: 32,
  name: `Nevada`
} as const;

export const nevadaTaggedC = t.type({
  _tag: t.literal(`Nevada`)
});
export type NevadaTaggedC = typeof nevadaTaggedC;
export type NevadaTagged = t.TypeOf<NevadaTaggedC>;
export type Nevada = NevadaTagged & typeof nevada;
export const nevadaC = pipe(nevadaTaggedC, c => new t.Type<Nevada, NevadaTagged>(
  `Nevada`,
  (u: unknown): u is Nevada => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Nevada> => pipe(c.decode(u), E.map(x => ({ ...x, ...nevada }))),
  (x: Nevada): NevadaTagged => ({ ...x, _tag: `Nevada`}),
));
export type NevadaC = typeof nevadaC;


export const newBrunswick = {
  _tag: `NewBrunswick`,
  abbrev: `NB`,
  id: 33,
  name: `New Brunswick`
} as const;

export const newBrunswickTaggedC = t.type({
  _tag: t.literal(`NewBrunswick`)
});
export type NewBrunswickTaggedC = typeof newBrunswickTaggedC;
export type NewBrunswickTagged = t.TypeOf<NewBrunswickTaggedC>;
export type NewBrunswick = NewBrunswickTagged & typeof newBrunswick;
export const newBrunswickC = pipe(newBrunswickTaggedC, c => new t.Type<NewBrunswick, NewBrunswickTagged>(
  `NewBrunswick`,
  (u: unknown): u is NewBrunswick => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewBrunswick> => pipe(c.decode(u), E.map(x => ({ ...x, ...newBrunswick }))),
  (x: NewBrunswick): NewBrunswickTagged => ({ ...x, _tag: `NewBrunswick`}),
));
export type NewBrunswickC = typeof newBrunswickC;


export const newHampshire = {
  _tag: `NewHampshire`,
  abbrev: `NH`,
  id: 34,
  name: `New Hampshire`
} as const;

export const newHampshireTaggedC = t.type({
  _tag: t.literal(`NewHampshire`)
});
export type NewHampshireTaggedC = typeof newHampshireTaggedC;
export type NewHampshireTagged = t.TypeOf<NewHampshireTaggedC>;
export type NewHampshire = NewHampshireTagged & typeof newHampshire;
export const newHampshireC = pipe(newHampshireTaggedC, c => new t.Type<NewHampshire, NewHampshireTagged>(
  `NewHampshire`,
  (u: unknown): u is NewHampshire => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewHampshire> => pipe(c.decode(u), E.map(x => ({ ...x, ...newHampshire }))),
  (x: NewHampshire): NewHampshireTagged => ({ ...x, _tag: `NewHampshire`}),
));
export type NewHampshireC = typeof newHampshireC;


export const newJersey = {
  _tag: `NewJersey`,
  abbrev: `NJ`,
  id: 35,
  name: `New Jersey`
} as const;

export const newJerseyTaggedC = t.type({
  _tag: t.literal(`NewJersey`)
});
export type NewJerseyTaggedC = typeof newJerseyTaggedC;
export type NewJerseyTagged = t.TypeOf<NewJerseyTaggedC>;
export type NewJersey = NewJerseyTagged & typeof newJersey;
export const newJerseyC = pipe(newJerseyTaggedC, c => new t.Type<NewJersey, NewJerseyTagged>(
  `NewJersey`,
  (u: unknown): u is NewJersey => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewJersey> => pipe(c.decode(u), E.map(x => ({ ...x, ...newJersey }))),
  (x: NewJersey): NewJerseyTagged => ({ ...x, _tag: `NewJersey`}),
));
export type NewJerseyC = typeof newJerseyC;


export const newMexico = {
  _tag: `NewMexico`,
  abbrev: `NM`,
  id: 36,
  name: `New Mexico`
} as const;

export const newMexicoTaggedC = t.type({
  _tag: t.literal(`NewMexico`)
});
export type NewMexicoTaggedC = typeof newMexicoTaggedC;
export type NewMexicoTagged = t.TypeOf<NewMexicoTaggedC>;
export type NewMexico = NewMexicoTagged & typeof newMexico;
export const newMexicoC = pipe(newMexicoTaggedC, c => new t.Type<NewMexico, NewMexicoTagged>(
  `NewMexico`,
  (u: unknown): u is NewMexico => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewMexico> => pipe(c.decode(u), E.map(x => ({ ...x, ...newMexico }))),
  (x: NewMexico): NewMexicoTagged => ({ ...x, _tag: `NewMexico`}),
));
export type NewMexicoC = typeof newMexicoC;


export const newYork = {
  _tag: `NewYork`,
  abbrev: `NY`,
  id: 37,
  name: `New York`
} as const;

export const newYorkTaggedC = t.type({
  _tag: t.literal(`NewYork`)
});
export type NewYorkTaggedC = typeof newYorkTaggedC;
export type NewYorkTagged = t.TypeOf<NewYorkTaggedC>;
export type NewYork = NewYorkTagged & typeof newYork;
export const newYorkC = pipe(newYorkTaggedC, c => new t.Type<NewYork, NewYorkTagged>(
  `NewYork`,
  (u: unknown): u is NewYork => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewYork> => pipe(c.decode(u), E.map(x => ({ ...x, ...newYork }))),
  (x: NewYork): NewYorkTagged => ({ ...x, _tag: `NewYork`}),
));
export type NewYorkC = typeof newYorkC;


export const newfoundlandAndLabrador = {
  _tag: `NewfoundlandAndLabrador`,
  abbrev: `NL`,
  id: 38,
  name: `Newfoundland and Labrador`
} as const;

export const newfoundlandAndLabradorTaggedC = t.type({
  _tag: t.literal(`NewfoundlandAndLabrador`)
});
export type NewfoundlandAndLabradorTaggedC = typeof newfoundlandAndLabradorTaggedC;
export type NewfoundlandAndLabradorTagged = t.TypeOf<NewfoundlandAndLabradorTaggedC>;
export type NewfoundlandAndLabrador = NewfoundlandAndLabradorTagged & typeof newfoundlandAndLabrador;
export const newfoundlandAndLabradorC = pipe(newfoundlandAndLabradorTaggedC, c => new t.Type<NewfoundlandAndLabrador, NewfoundlandAndLabradorTagged>(
  `NewfoundlandAndLabrador`,
  (u: unknown): u is NewfoundlandAndLabrador => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewfoundlandAndLabrador> => pipe(c.decode(u), E.map(x => ({ ...x, ...newfoundlandAndLabrador }))),
  (x: NewfoundlandAndLabrador): NewfoundlandAndLabradorTagged => ({ ...x, _tag: `NewfoundlandAndLabrador`}),
));
export type NewfoundlandAndLabradorC = typeof newfoundlandAndLabradorC;


export const northCarolina = {
  _tag: `NorthCarolina`,
  abbrev: `NC`,
  id: 39,
  name: `North Carolina`
} as const;

export const northCarolinaTaggedC = t.type({
  _tag: t.literal(`NorthCarolina`)
});
export type NorthCarolinaTaggedC = typeof northCarolinaTaggedC;
export type NorthCarolinaTagged = t.TypeOf<NorthCarolinaTaggedC>;
export type NorthCarolina = NorthCarolinaTagged & typeof northCarolina;
export const northCarolinaC = pipe(northCarolinaTaggedC, c => new t.Type<NorthCarolina, NorthCarolinaTagged>(
  `NorthCarolina`,
  (u: unknown): u is NorthCarolina => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NorthCarolina> => pipe(c.decode(u), E.map(x => ({ ...x, ...northCarolina }))),
  (x: NorthCarolina): NorthCarolinaTagged => ({ ...x, _tag: `NorthCarolina`}),
));
export type NorthCarolinaC = typeof northCarolinaC;


export const northDakota = {
  _tag: `NorthDakota`,
  abbrev: `ND`,
  id: 40,
  name: `North Dakota`
} as const;

export const northDakotaTaggedC = t.type({
  _tag: t.literal(`NorthDakota`)
});
export type NorthDakotaTaggedC = typeof northDakotaTaggedC;
export type NorthDakotaTagged = t.TypeOf<NorthDakotaTaggedC>;
export type NorthDakota = NorthDakotaTagged & typeof northDakota;
export const northDakotaC = pipe(northDakotaTaggedC, c => new t.Type<NorthDakota, NorthDakotaTagged>(
  `NorthDakota`,
  (u: unknown): u is NorthDakota => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NorthDakota> => pipe(c.decode(u), E.map(x => ({ ...x, ...northDakota }))),
  (x: NorthDakota): NorthDakotaTagged => ({ ...x, _tag: `NorthDakota`}),
));
export type NorthDakotaC = typeof northDakotaC;


export const northwestTerritories = {
  _tag: `NorthwestTerritories`,
  abbrev: `NT`,
  id: 41,
  name: `Northwest Territories`
} as const;

export const northwestTerritoriesTaggedC = t.type({
  _tag: t.literal(`NorthwestTerritories`)
});
export type NorthwestTerritoriesTaggedC = typeof northwestTerritoriesTaggedC;
export type NorthwestTerritoriesTagged = t.TypeOf<NorthwestTerritoriesTaggedC>;
export type NorthwestTerritories = NorthwestTerritoriesTagged & typeof northwestTerritories;
export const northwestTerritoriesC = pipe(northwestTerritoriesTaggedC, c => new t.Type<NorthwestTerritories, NorthwestTerritoriesTagged>(
  `NorthwestTerritories`,
  (u: unknown): u is NorthwestTerritories => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NorthwestTerritories> => pipe(c.decode(u), E.map(x => ({ ...x, ...northwestTerritories }))),
  (x: NorthwestTerritories): NorthwestTerritoriesTagged => ({ ...x, _tag: `NorthwestTerritories`}),
));
export type NorthwestTerritoriesC = typeof northwestTerritoriesC;


export const novaScotia = {
  _tag: `NovaScotia`,
  abbrev: `NS`,
  id: 42,
  name: `Nova Scotia`
} as const;

export const novaScotiaTaggedC = t.type({
  _tag: t.literal(`NovaScotia`)
});
export type NovaScotiaTaggedC = typeof novaScotiaTaggedC;
export type NovaScotiaTagged = t.TypeOf<NovaScotiaTaggedC>;
export type NovaScotia = NovaScotiaTagged & typeof novaScotia;
export const novaScotiaC = pipe(novaScotiaTaggedC, c => new t.Type<NovaScotia, NovaScotiaTagged>(
  `NovaScotia`,
  (u: unknown): u is NovaScotia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NovaScotia> => pipe(c.decode(u), E.map(x => ({ ...x, ...novaScotia }))),
  (x: NovaScotia): NovaScotiaTagged => ({ ...x, _tag: `NovaScotia`}),
));
export type NovaScotiaC = typeof novaScotiaC;


export const nunavut = {
  _tag: `Nunavut`,
  abbrev: `NU`,
  id: 43,
  name: `Nunavut`
} as const;

export const nunavutTaggedC = t.type({
  _tag: t.literal(`Nunavut`)
});
export type NunavutTaggedC = typeof nunavutTaggedC;
export type NunavutTagged = t.TypeOf<NunavutTaggedC>;
export type Nunavut = NunavutTagged & typeof nunavut;
export const nunavutC = pipe(nunavutTaggedC, c => new t.Type<Nunavut, NunavutTagged>(
  `Nunavut`,
  (u: unknown): u is Nunavut => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Nunavut> => pipe(c.decode(u), E.map(x => ({ ...x, ...nunavut }))),
  (x: Nunavut): NunavutTagged => ({ ...x, _tag: `Nunavut`}),
));
export type NunavutC = typeof nunavutC;


export const ohio = {
  _tag: `Ohio`,
  abbrev: `OH`,
  id: 44,
  name: `Ohio`
} as const;

export const ohioTaggedC = t.type({
  _tag: t.literal(`Ohio`)
});
export type OhioTaggedC = typeof ohioTaggedC;
export type OhioTagged = t.TypeOf<OhioTaggedC>;
export type Ohio = OhioTagged & typeof ohio;
export const ohioC = pipe(ohioTaggedC, c => new t.Type<Ohio, OhioTagged>(
  `Ohio`,
  (u: unknown): u is Ohio => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ohio> => pipe(c.decode(u), E.map(x => ({ ...x, ...ohio }))),
  (x: Ohio): OhioTagged => ({ ...x, _tag: `Ohio`}),
));
export type OhioC = typeof ohioC;


export const oklahoma = {
  _tag: `Oklahoma`,
  abbrev: `OK`,
  id: 45,
  name: `Oklahoma`
} as const;

export const oklahomaTaggedC = t.type({
  _tag: t.literal(`Oklahoma`)
});
export type OklahomaTaggedC = typeof oklahomaTaggedC;
export type OklahomaTagged = t.TypeOf<OklahomaTaggedC>;
export type Oklahoma = OklahomaTagged & typeof oklahoma;
export const oklahomaC = pipe(oklahomaTaggedC, c => new t.Type<Oklahoma, OklahomaTagged>(
  `Oklahoma`,
  (u: unknown): u is Oklahoma => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Oklahoma> => pipe(c.decode(u), E.map(x => ({ ...x, ...oklahoma }))),
  (x: Oklahoma): OklahomaTagged => ({ ...x, _tag: `Oklahoma`}),
));
export type OklahomaC = typeof oklahomaC;


export const ontario = {
  _tag: `Ontario`,
  abbrev: `ON`,
  id: 46,
  name: `Ontario`
} as const;

export const ontarioTaggedC = t.type({
  _tag: t.literal(`Ontario`)
});
export type OntarioTaggedC = typeof ontarioTaggedC;
export type OntarioTagged = t.TypeOf<OntarioTaggedC>;
export type Ontario = OntarioTagged & typeof ontario;
export const ontarioC = pipe(ontarioTaggedC, c => new t.Type<Ontario, OntarioTagged>(
  `Ontario`,
  (u: unknown): u is Ontario => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ontario> => pipe(c.decode(u), E.map(x => ({ ...x, ...ontario }))),
  (x: Ontario): OntarioTagged => ({ ...x, _tag: `Ontario`}),
));
export type OntarioC = typeof ontarioC;


export const oregon = {
  _tag: `Oregon`,
  abbrev: `OR`,
  id: 47,
  name: `Oregon`
} as const;

export const oregonTaggedC = t.type({
  _tag: t.literal(`Oregon`)
});
export type OregonTaggedC = typeof oregonTaggedC;
export type OregonTagged = t.TypeOf<OregonTaggedC>;
export type Oregon = OregonTagged & typeof oregon;
export const oregonC = pipe(oregonTaggedC, c => new t.Type<Oregon, OregonTagged>(
  `Oregon`,
  (u: unknown): u is Oregon => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Oregon> => pipe(c.decode(u), E.map(x => ({ ...x, ...oregon }))),
  (x: Oregon): OregonTagged => ({ ...x, _tag: `Oregon`}),
));
export type OregonC = typeof oregonC;


export const pennsylvania = {
  _tag: `Pennsylvania`,
  abbrev: `PA`,
  id: 48,
  name: `Pennsylvania`
} as const;

export const pennsylvaniaTaggedC = t.type({
  _tag: t.literal(`Pennsylvania`)
});
export type PennsylvaniaTaggedC = typeof pennsylvaniaTaggedC;
export type PennsylvaniaTagged = t.TypeOf<PennsylvaniaTaggedC>;
export type Pennsylvania = PennsylvaniaTagged & typeof pennsylvania;
export const pennsylvaniaC = pipe(pennsylvaniaTaggedC, c => new t.Type<Pennsylvania, PennsylvaniaTagged>(
  `Pennsylvania`,
  (u: unknown): u is Pennsylvania => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Pennsylvania> => pipe(c.decode(u), E.map(x => ({ ...x, ...pennsylvania }))),
  (x: Pennsylvania): PennsylvaniaTagged => ({ ...x, _tag: `Pennsylvania`}),
));
export type PennsylvaniaC = typeof pennsylvaniaC;


export const princeEdwardIsland = {
  _tag: `PrinceEdwardIsland`,
  abbrev: `PE`,
  id: 49,
  name: `Prince Edward Island`
} as const;

export const princeEdwardIslandTaggedC = t.type({
  _tag: t.literal(`PrinceEdwardIsland`)
});
export type PrinceEdwardIslandTaggedC = typeof princeEdwardIslandTaggedC;
export type PrinceEdwardIslandTagged = t.TypeOf<PrinceEdwardIslandTaggedC>;
export type PrinceEdwardIsland = PrinceEdwardIslandTagged & typeof princeEdwardIsland;
export const princeEdwardIslandC = pipe(princeEdwardIslandTaggedC, c => new t.Type<PrinceEdwardIsland, PrinceEdwardIslandTagged>(
  `PrinceEdwardIsland`,
  (u: unknown): u is PrinceEdwardIsland => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrinceEdwardIsland> => pipe(c.decode(u), E.map(x => ({ ...x, ...princeEdwardIsland }))),
  (x: PrinceEdwardIsland): PrinceEdwardIslandTagged => ({ ...x, _tag: `PrinceEdwardIsland`}),
));
export type PrinceEdwardIslandC = typeof princeEdwardIslandC;


export const puertoRico = {
  _tag: `PuertoRico`,
  abbrev: `PR`,
  id: 50,
  name: `Puerto Rico`
} as const;

export const puertoRicoTaggedC = t.type({
  _tag: t.literal(`PuertoRico`)
});
export type PuertoRicoTaggedC = typeof puertoRicoTaggedC;
export type PuertoRicoTagged = t.TypeOf<PuertoRicoTaggedC>;
export type PuertoRico = PuertoRicoTagged & typeof puertoRico;
export const puertoRicoC = pipe(puertoRicoTaggedC, c => new t.Type<PuertoRico, PuertoRicoTagged>(
  `PuertoRico`,
  (u: unknown): u is PuertoRico => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PuertoRico> => pipe(c.decode(u), E.map(x => ({ ...x, ...puertoRico }))),
  (x: PuertoRico): PuertoRicoTagged => ({ ...x, _tag: `PuertoRico`}),
));
export type PuertoRicoC = typeof puertoRicoC;


export const quebec = {
  _tag: `Quebec`,
  abbrev: `QC`,
  id: 51,
  name: `Québec`
} as const;

export const quebecTaggedC = t.type({
  _tag: t.literal(`Quebec`)
});
export type QuebecTaggedC = typeof quebecTaggedC;
export type QuebecTagged = t.TypeOf<QuebecTaggedC>;
export type Quebec = QuebecTagged & typeof quebec;
export const quebecC = pipe(quebecTaggedC, c => new t.Type<Quebec, QuebecTagged>(
  `Quebec`,
  (u: unknown): u is Quebec => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Quebec> => pipe(c.decode(u), E.map(x => ({ ...x, ...quebec }))),
  (x: Quebec): QuebecTagged => ({ ...x, _tag: `Quebec`}),
));
export type QuebecC = typeof quebecC;


export const rhodeIsland = {
  _tag: `RhodeIsland`,
  abbrev: `RI`,
  id: 52,
  name: `Rhode Island`
} as const;

export const rhodeIslandTaggedC = t.type({
  _tag: t.literal(`RhodeIsland`)
});
export type RhodeIslandTaggedC = typeof rhodeIslandTaggedC;
export type RhodeIslandTagged = t.TypeOf<RhodeIslandTaggedC>;
export type RhodeIsland = RhodeIslandTagged & typeof rhodeIsland;
export const rhodeIslandC = pipe(rhodeIslandTaggedC, c => new t.Type<RhodeIsland, RhodeIslandTagged>(
  `RhodeIsland`,
  (u: unknown): u is RhodeIsland => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RhodeIsland> => pipe(c.decode(u), E.map(x => ({ ...x, ...rhodeIsland }))),
  (x: RhodeIsland): RhodeIslandTagged => ({ ...x, _tag: `RhodeIsland`}),
));
export type RhodeIslandC = typeof rhodeIslandC;


export const saskatchewan = {
  _tag: `Saskatchewan`,
  abbrev: `SK`,
  id: 53,
  name: `Saskatchewan`
} as const;

export const saskatchewanTaggedC = t.type({
  _tag: t.literal(`Saskatchewan`)
});
export type SaskatchewanTaggedC = typeof saskatchewanTaggedC;
export type SaskatchewanTagged = t.TypeOf<SaskatchewanTaggedC>;
export type Saskatchewan = SaskatchewanTagged & typeof saskatchewan;
export const saskatchewanC = pipe(saskatchewanTaggedC, c => new t.Type<Saskatchewan, SaskatchewanTagged>(
  `Saskatchewan`,
  (u: unknown): u is Saskatchewan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Saskatchewan> => pipe(c.decode(u), E.map(x => ({ ...x, ...saskatchewan }))),
  (x: Saskatchewan): SaskatchewanTagged => ({ ...x, _tag: `Saskatchewan`}),
));
export type SaskatchewanC = typeof saskatchewanC;


export const southCarolina = {
  _tag: `SouthCarolina`,
  abbrev: `SC`,
  id: 54,
  name: `South Carolina`
} as const;

export const southCarolinaTaggedC = t.type({
  _tag: t.literal(`SouthCarolina`)
});
export type SouthCarolinaTaggedC = typeof southCarolinaTaggedC;
export type SouthCarolinaTagged = t.TypeOf<SouthCarolinaTaggedC>;
export type SouthCarolina = SouthCarolinaTagged & typeof southCarolina;
export const southCarolinaC = pipe(southCarolinaTaggedC, c => new t.Type<SouthCarolina, SouthCarolinaTagged>(
  `SouthCarolina`,
  (u: unknown): u is SouthCarolina => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SouthCarolina> => pipe(c.decode(u), E.map(x => ({ ...x, ...southCarolina }))),
  (x: SouthCarolina): SouthCarolinaTagged => ({ ...x, _tag: `SouthCarolina`}),
));
export type SouthCarolinaC = typeof southCarolinaC;


export const southDakota = {
  _tag: `SouthDakota`,
  abbrev: `SD`,
  id: 55,
  name: `South Dakota`
} as const;

export const southDakotaTaggedC = t.type({
  _tag: t.literal(`SouthDakota`)
});
export type SouthDakotaTaggedC = typeof southDakotaTaggedC;
export type SouthDakotaTagged = t.TypeOf<SouthDakotaTaggedC>;
export type SouthDakota = SouthDakotaTagged & typeof southDakota;
export const southDakotaC = pipe(southDakotaTaggedC, c => new t.Type<SouthDakota, SouthDakotaTagged>(
  `SouthDakota`,
  (u: unknown): u is SouthDakota => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SouthDakota> => pipe(c.decode(u), E.map(x => ({ ...x, ...southDakota }))),
  (x: SouthDakota): SouthDakotaTagged => ({ ...x, _tag: `SouthDakota`}),
));
export type SouthDakotaC = typeof southDakotaC;


export const tennessee = {
  _tag: `Tennessee`,
  abbrev: `TN`,
  id: 56,
  name: `Tennessee`
} as const;

export const tennesseeTaggedC = t.type({
  _tag: t.literal(`Tennessee`)
});
export type TennesseeTaggedC = typeof tennesseeTaggedC;
export type TennesseeTagged = t.TypeOf<TennesseeTaggedC>;
export type Tennessee = TennesseeTagged & typeof tennessee;
export const tennesseeC = pipe(tennesseeTaggedC, c => new t.Type<Tennessee, TennesseeTagged>(
  `Tennessee`,
  (u: unknown): u is Tennessee => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Tennessee> => pipe(c.decode(u), E.map(x => ({ ...x, ...tennessee }))),
  (x: Tennessee): TennesseeTagged => ({ ...x, _tag: `Tennessee`}),
));
export type TennesseeC = typeof tennesseeC;


export const texas = {
  _tag: `Texas`,
  abbrev: `TX`,
  id: 57,
  name: `Texas`
} as const;

export const texasTaggedC = t.type({
  _tag: t.literal(`Texas`)
});
export type TexasTaggedC = typeof texasTaggedC;
export type TexasTagged = t.TypeOf<TexasTaggedC>;
export type Texas = TexasTagged & typeof texas;
export const texasC = pipe(texasTaggedC, c => new t.Type<Texas, TexasTagged>(
  `Texas`,
  (u: unknown): u is Texas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Texas> => pipe(c.decode(u), E.map(x => ({ ...x, ...texas }))),
  (x: Texas): TexasTagged => ({ ...x, _tag: `Texas`}),
));
export type TexasC = typeof texasC;


export const uSVirginIslands = {
  _tag: `USVirginIslands`,
  abbrev: `VI`,
  id: 58,
  name: `US Virgin Islands`
} as const;

export const uSVirginIslandsTaggedC = t.type({
  _tag: t.literal(`USVirginIslands`)
});
export type USVirginIslandsTaggedC = typeof uSVirginIslandsTaggedC;
export type USVirginIslandsTagged = t.TypeOf<USVirginIslandsTaggedC>;
export type USVirginIslands = USVirginIslandsTagged & typeof uSVirginIslands;
export const uSVirginIslandsC = pipe(uSVirginIslandsTaggedC, c => new t.Type<USVirginIslands, USVirginIslandsTagged>(
  `USVirginIslands`,
  (u: unknown): u is USVirginIslands => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, USVirginIslands> => pipe(c.decode(u), E.map(x => ({ ...x, ...uSVirginIslands }))),
  (x: USVirginIslands): USVirginIslandsTagged => ({ ...x, _tag: `USVirginIslands`}),
));
export type USVirginIslandsC = typeof uSVirginIslandsC;


export const utah = {
  _tag: `Utah`,
  abbrev: `UT`,
  id: 59,
  name: `Utah`
} as const;

export const utahTaggedC = t.type({
  _tag: t.literal(`Utah`)
});
export type UtahTaggedC = typeof utahTaggedC;
export type UtahTagged = t.TypeOf<UtahTaggedC>;
export type Utah = UtahTagged & typeof utah;
export const utahC = pipe(utahTaggedC, c => new t.Type<Utah, UtahTagged>(
  `Utah`,
  (u: unknown): u is Utah => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Utah> => pipe(c.decode(u), E.map(x => ({ ...x, ...utah }))),
  (x: Utah): UtahTagged => ({ ...x, _tag: `Utah`}),
));
export type UtahC = typeof utahC;


export const vermont = {
  _tag: `Vermont`,
  abbrev: `VT`,
  id: 60,
  name: `Vermont`
} as const;

export const vermontTaggedC = t.type({
  _tag: t.literal(`Vermont`)
});
export type VermontTaggedC = typeof vermontTaggedC;
export type VermontTagged = t.TypeOf<VermontTaggedC>;
export type Vermont = VermontTagged & typeof vermont;
export const vermontC = pipe(vermontTaggedC, c => new t.Type<Vermont, VermontTagged>(
  `Vermont`,
  (u: unknown): u is Vermont => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Vermont> => pipe(c.decode(u), E.map(x => ({ ...x, ...vermont }))),
  (x: Vermont): VermontTagged => ({ ...x, _tag: `Vermont`}),
));
export type VermontC = typeof vermontC;


export const virginia = {
  _tag: `Virginia`,
  abbrev: `VA`,
  id: 61,
  name: `Virginia`
} as const;

export const virginiaTaggedC = t.type({
  _tag: t.literal(`Virginia`)
});
export type VirginiaTaggedC = typeof virginiaTaggedC;
export type VirginiaTagged = t.TypeOf<VirginiaTaggedC>;
export type Virginia = VirginiaTagged & typeof virginia;
export const virginiaC = pipe(virginiaTaggedC, c => new t.Type<Virginia, VirginiaTagged>(
  `Virginia`,
  (u: unknown): u is Virginia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Virginia> => pipe(c.decode(u), E.map(x => ({ ...x, ...virginia }))),
  (x: Virginia): VirginiaTagged => ({ ...x, _tag: `Virginia`}),
));
export type VirginiaC = typeof virginiaC;


export const washington = {
  _tag: `Washington`,
  abbrev: `WA`,
  id: 62,
  name: `Washington`
} as const;

export const washingtonTaggedC = t.type({
  _tag: t.literal(`Washington`)
});
export type WashingtonTaggedC = typeof washingtonTaggedC;
export type WashingtonTagged = t.TypeOf<WashingtonTaggedC>;
export type Washington = WashingtonTagged & typeof washington;
export const washingtonC = pipe(washingtonTaggedC, c => new t.Type<Washington, WashingtonTagged>(
  `Washington`,
  (u: unknown): u is Washington => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Washington> => pipe(c.decode(u), E.map(x => ({ ...x, ...washington }))),
  (x: Washington): WashingtonTagged => ({ ...x, _tag: `Washington`}),
));
export type WashingtonC = typeof washingtonC;


export const westVirginia = {
  _tag: `WestVirginia`,
  abbrev: `WV`,
  id: 63,
  name: `West Virginia`
} as const;

export const westVirginiaTaggedC = t.type({
  _tag: t.literal(`WestVirginia`)
});
export type WestVirginiaTaggedC = typeof westVirginiaTaggedC;
export type WestVirginiaTagged = t.TypeOf<WestVirginiaTaggedC>;
export type WestVirginia = WestVirginiaTagged & typeof westVirginia;
export const westVirginiaC = pipe(westVirginiaTaggedC, c => new t.Type<WestVirginia, WestVirginiaTagged>(
  `WestVirginia`,
  (u: unknown): u is WestVirginia => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WestVirginia> => pipe(c.decode(u), E.map(x => ({ ...x, ...westVirginia }))),
  (x: WestVirginia): WestVirginiaTagged => ({ ...x, _tag: `WestVirginia`}),
));
export type WestVirginiaC = typeof westVirginiaC;


export const wisconsin = {
  _tag: `Wisconsin`,
  abbrev: `WI`,
  id: 64,
  name: `Wisconsin`
} as const;

export const wisconsinTaggedC = t.type({
  _tag: t.literal(`Wisconsin`)
});
export type WisconsinTaggedC = typeof wisconsinTaggedC;
export type WisconsinTagged = t.TypeOf<WisconsinTaggedC>;
export type Wisconsin = WisconsinTagged & typeof wisconsin;
export const wisconsinC = pipe(wisconsinTaggedC, c => new t.Type<Wisconsin, WisconsinTagged>(
  `Wisconsin`,
  (u: unknown): u is Wisconsin => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Wisconsin> => pipe(c.decode(u), E.map(x => ({ ...x, ...wisconsin }))),
  (x: Wisconsin): WisconsinTagged => ({ ...x, _tag: `Wisconsin`}),
));
export type WisconsinC = typeof wisconsinC;


export const wyoming = {
  _tag: `Wyoming`,
  abbrev: `WY`,
  id: 65,
  name: `Wyoming`
} as const;

export const wyomingTaggedC = t.type({
  _tag: t.literal(`Wyoming`)
});
export type WyomingTaggedC = typeof wyomingTaggedC;
export type WyomingTagged = t.TypeOf<WyomingTaggedC>;
export type Wyoming = WyomingTagged & typeof wyoming;
export const wyomingC = pipe(wyomingTaggedC, c => new t.Type<Wyoming, WyomingTagged>(
  `Wyoming`,
  (u: unknown): u is Wyoming => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Wyoming> => pipe(c.decode(u), E.map(x => ({ ...x, ...wyoming }))),
  (x: Wyoming): WyomingTagged => ({ ...x, _tag: `Wyoming`}),
));
export type WyomingC = typeof wyomingC;


export const yukon = {
  _tag: `Yukon`,
  abbrev: `YT`,
  id: 66,
  name: `Yukon`
} as const;

export const yukonTaggedC = t.type({
  _tag: t.literal(`Yukon`)
});
export type YukonTaggedC = typeof yukonTaggedC;
export type YukonTagged = t.TypeOf<YukonTaggedC>;
export type Yukon = YukonTagged & typeof yukon;
export const yukonC = pipe(yukonTaggedC, c => new t.Type<Yukon, YukonTagged>(
  `Yukon`,
  (u: unknown): u is Yukon => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Yukon> => pipe(c.decode(u), E.map(x => ({ ...x, ...yukon }))),
  (x: Yukon): YukonTagged => ({ ...x, _tag: `Yukon`}),
));
export type YukonC = typeof yukonC;


export const bahamas = {
  _tag: `Bahamas`,
  abbrev: `Bahamas`,
  id: 67,
  name: `Bahamas`
} as const;

export const bahamasTaggedC = t.type({
  _tag: t.literal(`Bahamas`)
});
export type BahamasTaggedC = typeof bahamasTaggedC;
export type BahamasTagged = t.TypeOf<BahamasTaggedC>;
export type Bahamas = BahamasTagged & typeof bahamas;
export const bahamasC = pipe(bahamasTaggedC, c => new t.Type<Bahamas, BahamasTagged>(
  `Bahamas`,
  (u: unknown): u is Bahamas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Bahamas> => pipe(c.decode(u), E.map(x => ({ ...x, ...bahamas }))),
  (x: Bahamas): BahamasTagged => ({ ...x, _tag: `Bahamas`}),
));
export type BahamasC = typeof bahamasC;


export const allStateInfoC = [alabamaC, alaskaC, albertaC, arizonaC, arkansasC, britishColumbiaC, californiaC, coloradoC, connecticutC, delawareC, districtOfColumbiaC, floridaC, georgiaC, hawaiiC, idahoC, illinoisC, indianaC, iowaC, kansasC, kentuckyC, louisianaC, maineC, manitobaC, marylandC, massachusettsC, michiganC, minnesotaC, mississippiC, missouriC, montanaC, nebraskaC, nevadaC, newBrunswickC, newHampshireC, newJerseyC, newMexicoC, newYorkC, newfoundlandAndLabradorC, northCarolinaC, northDakotaC, northwestTerritoriesC, novaScotiaC, nunavutC, ohioC, oklahomaC, ontarioC, oregonC, pennsylvaniaC, princeEdwardIslandC, puertoRicoC, quebecC, rhodeIslandC, saskatchewanC, southCarolinaC, southDakotaC, tennesseeC, texasC, uSVirginIslandsC, utahC, vermontC, virginiaC, washingtonC, westVirginiaC, wisconsinC, wyomingC, yukonC, bahamasC] as const;
export const allStateInfoNames = [`Alabama`, `Alaska`, `Alberta`, `Arizona`, `Arkansas`, `BritishColumbia`, `California`, `Colorado`, `Connecticut`, `Delaware`, `DistrictOfColumbia`, `Florida`, `Georgia`, `Hawaii`, `Idaho`, `Illinois`, `Indiana`, `Iowa`, `Kansas`, `Kentucky`, `Louisiana`, `Maine`, `Manitoba`, `Maryland`, `Massachusetts`, `Michigan`, `Minnesota`, `Mississippi`, `Missouri`, `Montana`, `Nebraska`, `Nevada`, `NewBrunswick`, `NewHampshire`, `NewJersey`, `NewMexico`, `NewYork`, `NewfoundlandAndLabrador`, `NorthCarolina`, `NorthDakota`, `NorthwestTerritories`, `NovaScotia`, `Nunavut`, `Ohio`, `Oklahoma`, `Ontario`, `Oregon`, `Pennsylvania`, `PrinceEdwardIsland`, `PuertoRico`, `Quebec`, `RhodeIsland`, `Saskatchewan`, `SouthCarolina`, `SouthDakota`, `Tennessee`, `Texas`, `USVirginIslands`, `Utah`, `Vermont`, `Virginia`, `Washington`, `WestVirginia`, `Wisconsin`, `Wyoming`, `Yukon`, `Bahamas`] as const;
export type StateInfoName = (typeof allStateInfoNames)[number];

export const StateInfoCU = t.union([alabamaC, alaskaC, albertaC, arizonaC, arkansasC, britishColumbiaC, californiaC, coloradoC, connecticutC, delawareC, districtOfColumbiaC, floridaC, georgiaC, hawaiiC, idahoC, illinoisC, indianaC, iowaC, kansasC, kentuckyC, louisianaC, maineC, manitobaC, marylandC, massachusettsC, michiganC, minnesotaC, mississippiC, missouriC, montanaC, nebraskaC, nevadaC, newBrunswickC, newHampshireC, newJerseyC, newMexicoC, newYorkC, newfoundlandAndLabradorC, northCarolinaC, northDakotaC, northwestTerritoriesC, novaScotiaC, nunavutC, ohioC, oklahomaC, ontarioC, oregonC, pennsylvaniaC, princeEdwardIslandC, puertoRicoC, quebecC, rhodeIslandC, saskatchewanC, southCarolinaC, southDakotaC, tennesseeC, texasC, uSVirginIslandsC, utahC, vermontC, virginiaC, washingtonC, westVirginiaC, wisconsinC, wyomingC, yukonC, bahamasC]);
export type StateInfoCU = typeof StateInfoCU;
export type StateInfoU = t.TypeOf<StateInfoCU>;

export const stateInfoOrd: Ord.Ord<StateInfoU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allStateInfo = [alabama, alaska, alberta, arizona, arkansas, britishColumbia, california, colorado, connecticut, delaware, districtOfColumbia, florida, georgia, hawaii, idaho, illinois, indiana, iowa, kansas, kentucky, louisiana, maine, manitoba, maryland, massachusetts, michigan, minnesota, mississippi, missouri, montana, nebraska, nevada, newBrunswick, newHampshire, newJersey, newMexico, newYork, newfoundlandAndLabrador, northCarolina, northDakota, northwestTerritories, novaScotia, nunavut, ohio, oklahoma, ontario, oregon, pennsylvania, princeEdwardIsland, puertoRico, quebec, rhodeIsland, saskatchewan, southCarolina, southDakota, tennessee, texas, uSVirginIslands, utah, vermont, virginia, washington, westVirginia, wisconsin, wyoming, yukon, bahamas] as const;
export type StateInfoMap<A> = { [K in StateInfoName]: A };


