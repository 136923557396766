import { useState } from "react";

import { constVoid, Eq, flow, O, pipe, RA } from "@scripts/fp-ts";
import type { Bank } from "@scripts/generated/models/bank";
import type { EmailUnsubscribeData, EmailUnsubscribePostC, UnsubscribeAll, UnsubscribeBank, UnsubscribeIssuer, UnsubscribeTypeU } from "@scripts/generated/models/emailCampaign";
import { unsubscribeAll, UnsubscribeTypeCU } from "@scripts/generated/models/emailCampaign";
import { type Issuer } from "@scripts/generated/models/issuer";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { ButtonSubmit } from "@scripts/react/components/Button";
import type { IndicatorOption, Label } from "@scripts/react/components/form/CustomIndicator";
import { CustomIndicator } from "@scripts/react/components/form/CustomIndicator";
import { Form } from "@scripts/react/components/form/Form";
import type { Codec } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import type { DeepPartialWithOptions } from "@scripts/types/deepPartialWithOptions";
import { tagEqPartial } from "@scripts/util/compare";

import { MinimalContainer, MinimalFormContainer } from "@scripts-ssr/components/MinimalLayout";


const emailUnsubscribeL = formLens<EmailUnsubscribePostC>();
const emailUnsubscribeDL = formDataLens<EmailUnsubscribePostC>();

const makeInitState = (data: EmailUnsubscribeData) => pipe(
  emptyFormState<EmailUnsubscribePostC>(),
  emailUnsubscribeDL.compose(emailUnsubscribeL("email")).set(data.emailAddress)
);

const unsubscribeTypeC: Codec<DeepPartialWithOptions<UnsubscribeTypeU>> = ({
  kind: [UnsubscribeTypeCU.name],
  decode: UnsubscribeTypeCU.decode,
  encode: flow(O.fromNullable, O.chain(u => O.fromNullable(u._tag))),
  eq: {
    equals: (a, b) =>
      (a == null || b == null)
        ? Eq.eqStrict.equals(a, b)
        : tagEqPartial().equals(a, b),
  },
});

const allOption: O.Option<IndicatorOption<UnsubscribeAll>> = O.some({
  label: O.some("Unsubscribe from all email updates"),
  value: unsubscribeAll,
});

const makeLabel = (d: Issuer | Bank): Label =>
  O.some(`Unsubscribe from all email updates for ${d.name}`);

const makeIssuerOption = O.map((i: Issuer): IndicatorOption<UnsubscribeIssuer> => ({
  label: makeLabel(i),
  value: { _tag: "UnsubscribeIssuer", id: i.id },
}));

const makeBankOption = O.map((b: Bank): IndicatorOption<UnsubscribeBank> => ({
  label: makeLabel(b),
  value: { _tag: "UnsubscribeBank", id: b.id },
}));

const Unsubscribed = () =>
  <p {...klass("mb-0")}>
    Your subscription preferences have been updated. If you need further help,
    you can <ContactBondLinkAnchor title="send an email to BondLink support" />.
  </p>;

export const EmailUnsubscribePage = (props: EmailUnsubscribeData) => {
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);
  const [formState, setFormState] = useState(makeInitState(props));

  const options: ReadonlyArray<O.Option<IndicatorOption<UnsubscribeTypeU>>> = [
    makeIssuerOption(props.issuer),
    makeBankOption(props.bank),
    allOption,
  ];

  return (
    <MinimalContainer>
      <MinimalFormContainer>
        {unsubscribed
          ? <Unsubscribed />
          : (
            <Form
              url={V2Router.baseEmailUnsubscribePost()}
              state={formState}
              setState={setFormState}
              onSuccess={() => setUnsubscribed(true)}
              onFailure={O.none}
              headers={O.none}
            >
              <p>
                Choose what you would like to unsubscribe from using the options below.
                Note that by unsubscribing, you are acknowledging that you will not be
                informed when changes are made to any bond offerings you previously viewed.
              </p>
              <p>
                You will still receive emails related to managing your account (such as password reset requests).
              </p>
              <CustomIndicator
                analyticsScope={O.none}
                codec={unsubscribeTypeC}
                lens={emailUnsubscribeL("type")}
                name={"unsubscribeAll"}
                options={RA.compact(options)}
                setState={setFormState}
                state={formState}
                type={"radio"}
                unselectedValue={O.none}
                ariaUId={O.none}
              />
              <ButtonSubmit
                {...klass("w-100")}
                loading={formState.loading}
                loadingText={"Unsubscribing"}
                onClick={constVoid}
                text={"Unsubscribe"}
              />
            </Form>
          )
        }
      </MinimalFormContainer>
    </MinimalContainer>
  );
};
