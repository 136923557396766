import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { TitleEditableCU as imported4_TitleEditableCU, TitleEditableCU as imported5_TitleEditableCU } from "../domaintables/pages";

export const pageTitlesC = t.readonlyArray(t.tuple([imported4_TitleEditableCU, t.string]));
export type PageTitlesC = typeof pageTitlesC;
export type PageTitles = t.TypeOf<PageTitlesC>;


export const postTitleC = t.type({
  page: imported5_TitleEditableCU,
  title: optionFromNullable(t.string)
});
export type PostTitleC = typeof postTitleC;
export type PostTitle = t.TypeOf<PostTitleC>;


