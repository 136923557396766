import { ResponseCodeCU as imported2_ResponseCodeCU, ResponseCodeCU as imported3_ResponseCodeCU } from "../domaintables/responseCodes";
import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";

export const apiErrorC = t.type({
  field: t.string,
  error: imported2_ResponseCodeCU,
  extra: t.string
});
export type ApiErrorC = typeof apiErrorC;
export type ApiError = t.TypeOf<ApiErrorC>;


export const apiErrorsC = t.type({
  error: imported3_ResponseCodeCU,
  errors: readonlyNonEmptyArrayC(apiErrorC)
});
export type ApiErrorsC = typeof apiErrorsC;
export type ApiErrors = t.TypeOf<ApiErrorsC>;


