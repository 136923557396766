import * as t from "io-ts";
import { TypeOfServicingCU as imported1_TypeOfServicingCU } from "../domaintables/typesOfServicing";
import { LocalDateC, LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { ongoingC as imported2_ongoingC, ongoingC as imported3_ongoingC } from "../domaintables/dateQualifiers";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BiddingStateCU as imported1_BiddingStateCU } from "../domaintables/biddingStates";
import { StateInfoCU as imported5_StateInfoCU } from "../domaintables/states";
import { SectorCU as imported11_SectorCU } from "../domaintables/sectors";
import { markdownC } from "../../codecs/markdown";
import { either } from "io-ts-types/lib/either";
import { TimeZoneCU as imported2_TimeZoneCU, TimeZoneCU as imported3_TimeZoneCU } from "../domaintables/timeZones";

export const timeC = t.type({
  hour: t.number,
  minute: t.number
});
export type TimeC = typeof timeC;
export type Time = t.TypeOf<TimeC>;


export class timeWithZoneCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  time: timeC,
  timezone: A1
})}
export const timeWithZoneC = <A1 extends t.Mixed>(A1: A1) => new timeWithZoneCC<A1>().codec(A1);
export type TimeWithZoneC<A1 extends t.Mixed> = ReturnType<timeWithZoneCC<A1>["codec"]>;
export type TimeWithZone<A1> = t.TypeOf<TimeWithZoneC<t.Type<A1>>>;

export class dateWithOptionalTimeCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  date: LocalDateC,
  time: optionFromNullable(timeWithZoneC(A1))
})}
export const dateWithOptionalTimeC = <A1 extends t.Mixed>(A1: A1) => new dateWithOptionalTimeCC<A1>().codec(A1);
export type DateWithOptionalTimeC<A1 extends t.Mixed> = ReturnType<dateWithOptionalTimeCC<A1>["codec"]>;
export type DateWithOptionalTime<A1> = t.TypeOf<DateWithOptionalTimeC<t.Type<A1>>>;

export const rfpC = t.type({
  order: t.number,
  name: t.string,
  hidden: t.boolean,
  bondProgramId: optionFromNullable(t.number),
  sector: imported11_SectorCU,
  state: imported5_StateInfoCU,
  loanAmount: optionFromNullable(t.number),
  typeOfServicing: optionFromNullable(imported1_TypeOfServicingCU),
  projectDescription: optionFromNullable(markdownC),
  submissionRequirements: optionFromNullable(markdownC),
  allowQuestions: t.boolean,
  contactEmail: optionFromNullable(t.string),
  dateListed: optionFromNullable(LocalDateC),
  deadlineForQuestions: optionFromNullable(dateWithOptionalTimeC(imported2_TimeZoneCU)),
  biddingState: imported1_BiddingStateCU,
  bidsDue: optionFromNullable(either(dateWithOptionalTimeC(imported3_TimeZoneCU), imported2_ongoingC)),
  bidsDueDateTime: optionFromNullable(either(LocalDateTimeFromIsoStringC, imported3_ongoingC)),
  acceptBidsOnSite: t.boolean,
  bidSubmissionTemplateId: optionFromNullable(t.number),
  bidAwarded: t.boolean,
  awardDate: optionFromNullable(LocalDateC),
  awardDateTentative: t.boolean,
  contractStartDate: optionFromNullable(LocalDateC),
  contractStartDateTentative: t.boolean,
  showWinningBid: t.boolean,
  winningBidCompany: optionFromNullable(t.string),
  winningBidRate: optionFromNullable(t.number),
  winningBidFees: optionFromNullable(t.number),
  winningBidReceived: optionFromNullable(LocalDateC),
  winningBidAdditionalInfo: optionFromNullable(markdownC)
});
export type RfpC = typeof rfpC;
export type Rfp = t.TypeOf<RfpC>;


