export * from "fp-ts/lib/number";

import { constant } from "fp-ts/lib/function";

import { refinementFor } from "./lib/_internal";

export const constZero = constant<0>(0);
export const constOne = constant<1>(1);
export const constNegativeOne = constant<-1>(-1);

/** @category refinements */
export const is = refinementFor.number;
