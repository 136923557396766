import * as t from "io-ts";
import { LocalDateTimeFromIsoStringC } from "../../codecs/localDate";
import { rankedMetricC as imported4_rankedMetricC } from "./profileMetrics";
import { either } from "io-ts-types/lib/either";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { nextReminderDataC as imported1_nextReminderDataC } from "./reminders";
import { markdownC } from "../../codecs/markdown";

export const portalTipC = t.type({
  tip: markdownC
});
export type PortalTipC = typeof portalTipC;
export type PortalTip = t.TypeOf<PortalTipC>;


export const recentEngagementC = t.type({
  engagements: t.number,
  investors: t.number,
  time: either(t.number, LocalDateTimeFromIsoStringC)
});
export type RecentEngagementC = typeof recentEngagementC;
export type RecentEngagement = t.TypeOf<RecentEngagementC>;


export const welcomeBoxDataC = t.type({
  recentEngagement: optionFromNullable(recentEngagementC),
  portalTip: optionFromNullable(portalTipC),
  issuerPortalTip: optionFromNullable(portalTipC),
  personalizedPortalTip: optionFromNullable(imported4_rankedMetricC),
  nextReminder: optionFromNullable(imported1_nextReminderDataC),
  openDraftCount: optionFromNullable(t.number),
  pendingCusipCount: optionFromNullable(t.number)
});
export type WelcomeBoxDataC = typeof welcomeBoxDataC;
export type WelcomeBoxData = t.TypeOf<WelcomeBoxDataC>;


