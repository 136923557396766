import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const about = {
  _tag: `About`,
  id: 1,
  machineName: `about`,
  typeName: `about`
} as const;

export const aboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTaggedC = typeof aboutTaggedC;
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export const aboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
));
export type AboutC = typeof aboutC;


export const welcome = {
  _tag: `Welcome`,
  id: 3,
  machineName: `welcome`,
  typeName: `welcome`
} as const;

export const welcomeTaggedC = t.type({
  _tag: t.literal(`Welcome`)
});
export type WelcomeTaggedC = typeof welcomeTaggedC;
export type WelcomeTagged = t.TypeOf<WelcomeTaggedC>;
export type Welcome = WelcomeTagged & typeof welcome;
export const welcomeC = pipe(welcomeTaggedC, c => new t.Type<Welcome, WelcomeTagged>(
  `Welcome`,
  (u: unknown): u is Welcome => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Welcome> => pipe(c.decode(u), E.map(x => ({ ...x, ...welcome }))),
  (x: Welcome): WelcomeTagged => ({ ...x, _tag: `Welcome`}),
));
export type WelcomeC = typeof welcomeC;


export const offerInfo = {
  _tag: `OfferInfo`,
  id: 6,
  machineName: `offering-info`,
  typeName: `offering_info`
} as const;

export const offerInfoTaggedC = t.type({
  _tag: t.literal(`OfferInfo`)
});
export type OfferInfoTaggedC = typeof offerInfoTaggedC;
export type OfferInfoTagged = t.TypeOf<OfferInfoTaggedC>;
export type OfferInfo = OfferInfoTagged & typeof offerInfo;
export const offerInfoC = pipe(offerInfoTaggedC, c => new t.Type<OfferInfo, OfferInfoTagged>(
  `OfferInfo`,
  (u: unknown): u is OfferInfo => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferInfo> => pipe(c.decode(u), E.map(x => ({ ...x, ...offerInfo }))),
  (x: OfferInfo): OfferInfoTagged => ({ ...x, _tag: `OfferInfo`}),
));
export type OfferInfoC = typeof offerInfoC;


export const contactUs = {
  _tag: `ContactUs`,
  id: 7,
  machineName: `contact-us`,
  typeName: `contact_us`
} as const;

export const contactUsTaggedC = t.type({
  _tag: t.literal(`ContactUs`)
});
export type ContactUsTaggedC = typeof contactUsTaggedC;
export type ContactUsTagged = t.TypeOf<ContactUsTaggedC>;
export type ContactUs = ContactUsTagged & typeof contactUs;
export const contactUsC = pipe(contactUsTaggedC, c => new t.Type<ContactUs, ContactUsTagged>(
  `ContactUs`,
  (u: unknown): u is ContactUs => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ContactUs> => pipe(c.decode(u), E.map(x => ({ ...x, ...contactUs }))),
  (x: ContactUs): ContactUsTagged => ({ ...x, _tag: `ContactUs`}),
));
export type ContactUsC = typeof contactUsC;


export const customPage1 = {
  _tag: `CustomPage1`,
  id: 10,
  machineName: `custom-page-1`,
  typeName: `custom_page_1`
} as const;

export const customPage1TaggedC = t.type({
  _tag: t.literal(`CustomPage1`)
});
export type CustomPage1TaggedC = typeof customPage1TaggedC;
export type CustomPage1Tagged = t.TypeOf<CustomPage1TaggedC>;
export type CustomPage1 = CustomPage1Tagged & typeof customPage1;
export const customPage1C = pipe(customPage1TaggedC, c => new t.Type<CustomPage1, CustomPage1Tagged>(
  `CustomPage1`,
  (u: unknown): u is CustomPage1 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage1> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage1 }))),
  (x: CustomPage1): CustomPage1Tagged => ({ ...x, _tag: `CustomPage1`}),
));
export type CustomPage1C = typeof customPage1C;


export const customPage2 = {
  _tag: `CustomPage2`,
  id: 11,
  machineName: `custom-page-2`,
  typeName: `custom_page_2`
} as const;

export const customPage2TaggedC = t.type({
  _tag: t.literal(`CustomPage2`)
});
export type CustomPage2TaggedC = typeof customPage2TaggedC;
export type CustomPage2Tagged = t.TypeOf<CustomPage2TaggedC>;
export type CustomPage2 = CustomPage2Tagged & typeof customPage2;
export const customPage2C = pipe(customPage2TaggedC, c => new t.Type<CustomPage2, CustomPage2Tagged>(
  `CustomPage2`,
  (u: unknown): u is CustomPage2 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage2> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage2 }))),
  (x: CustomPage2): CustomPage2Tagged => ({ ...x, _tag: `CustomPage2`}),
));
export type CustomPage2C = typeof customPage2C;


export const customPage3 = {
  _tag: `CustomPage3`,
  id: 12,
  machineName: `custom-page-3`,
  typeName: `custom_page_3`
} as const;

export const customPage3TaggedC = t.type({
  _tag: t.literal(`CustomPage3`)
});
export type CustomPage3TaggedC = typeof customPage3TaggedC;
export type CustomPage3Tagged = t.TypeOf<CustomPage3TaggedC>;
export type CustomPage3 = CustomPage3Tagged & typeof customPage3;
export const customPage3C = pipe(customPage3TaggedC, c => new t.Type<CustomPage3, CustomPage3Tagged>(
  `CustomPage3`,
  (u: unknown): u is CustomPage3 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage3> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage3 }))),
  (x: CustomPage3): CustomPage3Tagged => ({ ...x, _tag: `CustomPage3`}),
));
export type CustomPage3C = typeof customPage3C;


export const customPage4 = {
  _tag: `CustomPage4`,
  id: 13,
  machineName: `custom-page-4`,
  typeName: `custom_page_4`
} as const;

export const customPage4TaggedC = t.type({
  _tag: t.literal(`CustomPage4`)
});
export type CustomPage4TaggedC = typeof customPage4TaggedC;
export type CustomPage4Tagged = t.TypeOf<CustomPage4TaggedC>;
export type CustomPage4 = CustomPage4Tagged & typeof customPage4;
export const customPage4C = pipe(customPage4TaggedC, c => new t.Type<CustomPage4, CustomPage4Tagged>(
  `CustomPage4`,
  (u: unknown): u is CustomPage4 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage4> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage4 }))),
  (x: CustomPage4): CustomPage4Tagged => ({ ...x, _tag: `CustomPage4`}),
));
export type CustomPage4C = typeof customPage4C;


export const customPage5 = {
  _tag: `CustomPage5`,
  id: 14,
  machineName: `custom-page-5`,
  typeName: `custom_page_5`
} as const;

export const customPage5TaggedC = t.type({
  _tag: t.literal(`CustomPage5`)
});
export type CustomPage5TaggedC = typeof customPage5TaggedC;
export type CustomPage5Tagged = t.TypeOf<CustomPage5TaggedC>;
export type CustomPage5 = CustomPage5Tagged & typeof customPage5;
export const customPage5C = pipe(customPage5TaggedC, c => new t.Type<CustomPage5, CustomPage5Tagged>(
  `CustomPage5`,
  (u: unknown): u is CustomPage5 => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustomPage5> => pipe(c.decode(u), E.map(x => ({ ...x, ...customPage5 }))),
  (x: CustomPage5): CustomPage5Tagged => ({ ...x, _tag: `CustomPage5`}),
));
export type CustomPage5C = typeof customPage5C;


export const issuerPortalTip = {
  _tag: `IssuerPortalTip`,
  id: 15,
  machineName: `portal-tip`,
  typeName: `portal_tip`
} as const;

export const issuerPortalTipTaggedC = t.type({
  _tag: t.literal(`IssuerPortalTip`)
});
export type IssuerPortalTipTaggedC = typeof issuerPortalTipTaggedC;
export type IssuerPortalTipTagged = t.TypeOf<IssuerPortalTipTaggedC>;
export type IssuerPortalTip = IssuerPortalTipTagged & typeof issuerPortalTip;
export const issuerPortalTipC = pipe(issuerPortalTipTaggedC, c => new t.Type<IssuerPortalTip, IssuerPortalTipTagged>(
  `IssuerPortalTip`,
  (u: unknown): u is IssuerPortalTip => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerPortalTip> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerPortalTip }))),
  (x: IssuerPortalTip): IssuerPortalTipTagged => ({ ...x, _tag: `IssuerPortalTip`}),
));
export type IssuerPortalTipC = typeof issuerPortalTipC;


export const esgOverview = {
  _tag: `EsgOverview`,
  id: 16,
  machineName: `esg-overview`,
  typeName: `esg_overview`
} as const;

export const esgOverviewTaggedC = t.type({
  _tag: t.literal(`EsgOverview`)
});
export type EsgOverviewTaggedC = typeof esgOverviewTaggedC;
export type EsgOverviewTagged = t.TypeOf<EsgOverviewTaggedC>;
export type EsgOverview = EsgOverviewTagged & typeof esgOverview;
export const esgOverviewC = pipe(esgOverviewTaggedC, c => new t.Type<EsgOverview, EsgOverviewTagged>(
  `EsgOverview`,
  (u: unknown): u is EsgOverview => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EsgOverview> => pipe(c.decode(u), E.map(x => ({ ...x, ...esgOverview }))),
  (x: EsgOverview): EsgOverviewTagged => ({ ...x, _tag: `EsgOverview`}),
));
export type EsgOverviewC = typeof esgOverviewC;


export const allClientTextTypeC = [aboutC, welcomeC, offerInfoC, contactUsC, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, issuerPortalTipC, esgOverviewC] as const;
export const allClientTextTypeNames = [`About`, `Welcome`, `OfferInfo`, `ContactUs`, `CustomPage1`, `CustomPage2`, `CustomPage3`, `CustomPage4`, `CustomPage5`, `IssuerPortalTip`, `EsgOverview`] as const;
export type ClientTextTypeName = (typeof allClientTextTypeNames)[number];

export const ClientTextTypeCU = t.union([aboutC, welcomeC, offerInfoC, contactUsC, customPage1C, customPage2C, customPage3C, customPage4C, customPage5C, issuerPortalTipC, esgOverviewC]);
export type ClientTextTypeCU = typeof ClientTextTypeCU;
export type ClientTextTypeU = t.TypeOf<ClientTextTypeCU>;

export const clientTextTypeOrd: Ord.Ord<ClientTextTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allClientTextType = [about, welcome, offerInfo, contactUs, customPage1, customPage2, customPage3, customPage4, customPage5, issuerPortalTip, esgOverview] as const;
export type ClientTextTypeMap<A> = { [K in ClientTextTypeName]: A };


