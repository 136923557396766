import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const lastDay = {
  _tag: `LastDay`,
  days: 1,
  months: 1
} as const;

export const lastDayTaggedC = t.type({
  _tag: t.literal(`LastDay`)
});
export type LastDayTaggedC = typeof lastDayTaggedC;
export type LastDayTagged = t.TypeOf<LastDayTaggedC>;
export type LastDay = LastDayTagged & typeof lastDay;
export const lastDayC = pipe(lastDayTaggedC, c => new t.Type<LastDay, LastDayTagged>(
  `LastDay`,
  (u: unknown): u is LastDay => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastDay> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastDay }))),
  (x: LastDay): LastDayTagged => ({ ...x, _tag: `LastDay`}),
));
export type LastDayC = typeof lastDayC;


export const lastWeek = {
  _tag: `LastWeek`,
  days: 7,
  months: 1
} as const;

export const lastWeekTaggedC = t.type({
  _tag: t.literal(`LastWeek`)
});
export type LastWeekTaggedC = typeof lastWeekTaggedC;
export type LastWeekTagged = t.TypeOf<LastWeekTaggedC>;
export type LastWeek = LastWeekTagged & typeof lastWeek;
export const lastWeekC = pipe(lastWeekTaggedC, c => new t.Type<LastWeek, LastWeekTagged>(
  `LastWeek`,
  (u: unknown): u is LastWeek => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastWeek> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastWeek }))),
  (x: LastWeek): LastWeekTagged => ({ ...x, _tag: `LastWeek`}),
));
export type LastWeekC = typeof lastWeekC;


export const last30Days = {
  _tag: `Last30Days`,
  days: 30,
  months: 1
} as const;

export const last30DaysTaggedC = t.type({
  _tag: t.literal(`Last30Days`)
});
export type Last30DaysTaggedC = typeof last30DaysTaggedC;
export type Last30DaysTagged = t.TypeOf<Last30DaysTaggedC>;
export type Last30Days = Last30DaysTagged & typeof last30Days;
export const last30DaysC = pipe(last30DaysTaggedC, c => new t.Type<Last30Days, Last30DaysTagged>(
  `Last30Days`,
  (u: unknown): u is Last30Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Last30Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...last30Days }))),
  (x: Last30Days): Last30DaysTagged => ({ ...x, _tag: `Last30Days`}),
));
export type Last30DaysC = typeof last30DaysC;


export const last90Days = {
  _tag: `Last90Days`,
  days: 90,
  months: 3
} as const;

export const last90DaysTaggedC = t.type({
  _tag: t.literal(`Last90Days`)
});
export type Last90DaysTaggedC = typeof last90DaysTaggedC;
export type Last90DaysTagged = t.TypeOf<Last90DaysTaggedC>;
export type Last90Days = Last90DaysTagged & typeof last90Days;
export const last90DaysC = pipe(last90DaysTaggedC, c => new t.Type<Last90Days, Last90DaysTagged>(
  `Last90Days`,
  (u: unknown): u is Last90Days => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Last90Days> => pipe(c.decode(u), E.map(x => ({ ...x, ...last90Days }))),
  (x: Last90Days): Last90DaysTagged => ({ ...x, _tag: `Last90Days`}),
));
export type Last90DaysC = typeof last90DaysC;


export const lastYear = {
  _tag: `LastYear`,
  days: 365,
  months: 12
} as const;

export const lastYearTaggedC = t.type({
  _tag: t.literal(`LastYear`)
});
export type LastYearTaggedC = typeof lastYearTaggedC;
export type LastYearTagged = t.TypeOf<LastYearTaggedC>;
export type LastYear = LastYearTagged & typeof lastYear;
export const lastYearC = pipe(lastYearTaggedC, c => new t.Type<LastYear, LastYearTagged>(
  `LastYear`,
  (u: unknown): u is LastYear => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastYear> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastYear }))),
  (x: LastYear): LastYearTagged => ({ ...x, _tag: `LastYear`}),
));
export type LastYearC = typeof lastYearC;


export const allDateRangeC = [lastDayC, lastWeekC, last30DaysC, last90DaysC, lastYearC] as const;
export const allDateRangeNames = [`LastDay`, `LastWeek`, `Last30Days`, `Last90Days`, `LastYear`] as const;
export type DateRangeName = (typeof allDateRangeNames)[number];

export const DateRangeCU = t.union([lastDayC, lastWeekC, last30DaysC, last90DaysC, lastYearC]);
export type DateRangeCU = typeof DateRangeCU;
export type DateRangeU = t.TypeOf<DateRangeCU>;

export const dateRangeOrd: Ord.Ord<DateRangeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDateRange = [lastDay, lastWeek, last30Days, last90Days, lastYear] as const;
export type DateRangeMap<A> = { [K in DateRangeName]: A };


