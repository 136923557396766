/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */

import { Static } from "./bondlinkStatic";

export class Scrub {
  static readonly scrubbed = "Option[2]";

  static scrubData(data: Record<string, any>): any {
    return Object.keys(data).reduce<Record<string, any>>((acc: Record<string, any>, key: string) => {
      acc[key] = Static.sensitiveFields.includes(key) ? Scrub.scrubbed : Scrub.scrubA(data[key]);
      return acc;
    }, {});
  }

  private static scrubA(a: any): any {
    switch (Object.prototype.toString.call(a)) {
      case "[object Object]":
        return Scrub.scrubData(<Record<string, any>>a); // eslint-disable-line @typescript-eslint/consistent-type-assertions
      case "[object Map]":
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return Scrub.scrubData([...a].reduce((acc: any, arr: any[]) => {
          acc[arr[0]] = arr[1];
          return acc;
        }, {}));
      case "[object Array]":
        return <any[]>a.map(Scrub.scrubA); // eslint-disable-line @typescript-eslint/consistent-type-assertions
      case "[object Set]":
        return [...a].map(Scrub.scrubA);
      case "[object String]":
        return Scrub.scrubString(<string>a); // eslint-disable-line @typescript-eslint/consistent-type-assertions
      case "[object Int8Array]":
      case "[object Uint8Array]":
      case "[object Uint8ClampedArray]":
      case "[object Int16Array]":
      case "[object Uint16Array]":
      case "[object Int32Array]":
      case "[object Uint32Array]":
      case "[object Float32Array]":
      case "[object Float64Array]":
      case "[object Boolean]":
      case "[object Number]":
      case "[object Date]":
      case "[object WeakMap]":
      case "[object WeakSet]":
      case "[object Function]":
      case "[object Undefined]":
      case "[object Null]":
        return a;
      default:
        return Scrub.scrubbed;
    }
  }

  private static scrubString(s: string): string {
    try {
      return JSON.stringify(Scrub.scrubA(JSON.parse(s)));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      return s;
    }
  }
}
