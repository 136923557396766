import * as E from "fp-ts/lib/Either";
import { flow, pipe } from "fp-ts/lib/function";

import type { BLConfigWithLog, BLWindow, Logger } from "@scripts/bondlink";
import type { BLConfig } from "@scripts/generated/models/blConfig";
import { bLConfigC } from "@scripts/generated/models/blConfig";
import { exhaustive } from "@scripts/util/exhaustive";

import { errorsToLogErrors, logErrors } from "../util/log";

let config: BLConfig | null;

export const Config = (log: Logger): BLConfigWithLog => {
  if (!config) {
    config = pipe(
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      (globalThis as unknown as BLWindow).BLConfig,
      bLConfigC.decode,
      E.mapLeft(flow(errorsToLogErrors("fatal", "Failure to decode blWindowBaseConfig"), logErrors)),
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      E.getOrElse(() => ({ ...((globalThis as unknown as BLWindow).BLConfig) })),
    );
  }

  return { ...config, exhaustive: exhaustive(log), log };
};
