import { flow, untupled } from "fp-ts/lib/function";
import * as Mn from "fp-ts/lib/Monoid";

export * from "fp-ts/lib/Monoid";

/**
 * Variadic version of `Monoid#concatAll`.
 *
 * Given a monoid of `A`, `concatAll_` returns a function
 * expecting any number of `A` and returns a single `A`
 * representing their concatenation.
 *
 * @example
 * ```typescript
 *  import { Mn, n } from "@scripts/fp-ts"
 *
 *  const sum = Mn.concatAll_(n.MonoidSum);
 *
 *  sum(1, 2, 3) // => 6
 * ```
 */
export const concatAll_ = flow(Mn.concatAll, untupled);

