import { Static } from "@scripts/bondlinkStatic";
import * as CorpRouter from "@scripts/generated/routers/corpRouter";
import { Svg } from "@scripts/react/components/Svg";
import { absoluteUrl } from "@scripts/util/url";

import { buttons } from "@styles/components/_buttons";
import { footer } from "@styles/components/_footer";

import blCorpLogo from "../../../../../assets/img/logos/bondlink-2019.svg";
import { Anchor, AnchorUnsafeWithChildren, BondLinkHelpPageAnchor, BondLinkLinkedInAnchor, BondLinkTwitterAnchor } from "../Anchor";

const invFooterContainer = footer[".footer"].html;
const footerLogo = footer[".footer"][".footer-logo"].html;
const bondlinkLogo = footer[".footer"][".bondlink-logo"].html;
const footerLinks = footer[".footer"][".footer-links"].html;
const footerCopyright = footer[".footer"][".footer-copyright"].html;
const footerSocial = footer[".footer"][".footer-social"].html;
const btnLink = buttons[".btn-link"].html;


const baseUrl = { baseUrl: Static.bondlinkUrl };

export const Footer = (props: { showSupportLink: boolean }) => {
  return (
    <footer>
      <div className={invFooterContainer}>
        <div className={footerLogo}>
          <div className={bondlinkLogo}>
            <AnchorUnsafeWithChildren
              externalLinkLocation="none"
              aria-label="BondLink Homepage"
              href={Static.bondlinkUrl}
              klasses={["no-decoration", "d-inline-block"]}
              target="_blank"
            >
              <Svg src={blCorpLogo} />
            </AnchorUnsafeWithChildren>
          </div>
        </div>
        <div className={footerLinks}>
          <Anchor
            klasses={btnLink}
            route={{ title: "Terms", route: absoluteUrl(baseUrl)(CorpRouter.corporateStaticTerms()) }}
            target={"_blank"}
            externalLinkLocation={"none"}
          />
          <Anchor
            klasses={btnLink}
            route={{ title: "Privacy", route: absoluteUrl(baseUrl)(CorpRouter.corporateStaticPrivacy()) }}
            target={"_blank"}
            externalLinkLocation={"none"}
          />
          {props.showSupportLink && <BondLinkHelpPageAnchor title="Support" klasses={btnLink} />}
        </div>
        <div className={footerCopyright}>
          <span>&copy;{new Date().getFullYear()} BondLink, Inc.</span>
        </div>
        <div className={footerSocial}>
          <BondLinkTwitterAnchor />
          <BondLinkLinkedInAnchor />
        </div>
      </div>
    </footer>
  );
};
