import * as t from "io-ts";
import { PostTypeCU as imported1_PostTypeCU } from "../domaintables/postTypes";
import { LocalDateC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";

export const issuerNewsC = t.type({
  newsTitle: t.string,
  newsDate: optionFromNullable(LocalDateC),
  newsBlurb: markdownC,
  postType: imported1_PostTypeCU,
  newsOrder: t.number
});
export type IssuerNewsC = typeof issuerNewsC;
export type IssuerNews = t.TypeOf<IssuerNewsC>;


