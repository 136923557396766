import * as O from "fp-ts/lib/Option";

import bondIcon from "@svgs/bond.svg";
import categories from "@svgs/categories.svg";
import issuerIcon from "@svgs/issuer.svg";
import stateIcon from "@svgs/map-pin.svg";
import rfpIcon from "@svgs/rfp.svg";

import type { DataMetaBase, DataMetaSvg } from "./../../../meta/dataMeta";

export const dashboardMeta: DataMetaBase<"Dashboard"> = {
  type: "Dashboard",
  customDisplayName: O.none,
};

export const issuerFeedMeta: DataMetaBase<"Issuer Feed"> = {
  type: "Issuer Feed",
  customDisplayName: O.none,
};
export const bondSaleCalendarMeta: DataMetaBase<"Bond Sale Calendar"> = {
  type: "Bond Sale Calendar",
  customDisplayName: O.none,
};

export const watchlistMeta: DataMetaBase<"Watchlist"> = {
  type: "Watchlist",
  customDisplayName: O.none,
};

export const bondsWatchlistMeta: DataMetaBase<"Bonds"> & DataMetaSvg = {
  type: "Bonds",
  customDisplayName: O.none,
  svg: bondIcon,
};

export const rfpWatchlistMeta: DataMetaBase<"RFPs"> & DataMetaSvg = {
  type: "RFPs",
  customDisplayName: O.none,
  svg: rfpIcon,
};

export const issuerWatchlistMeta: DataMetaBase<"Issuers"> & DataMetaSvg = {
  type: "Issuers",
  customDisplayName: O.none,
  svg: issuerIcon,
};

export const sectorsWatchlistMeta: DataMetaBase<"Sectors"> & DataMetaSvg = {
  type: "Sectors",
  customDisplayName: O.none,
  svg: categories,
};

export const stateWatchlistMeta: DataMetaBase<"States"> & DataMetaSvg = {
  type: "States",
  customDisplayName: O.none,
  svg: stateIcon,
};
