import * as t from "io-ts";

import type { RNEA } from "@scripts/fp-ts";
import { E, flow, O, pipe, RA } from "@scripts/fp-ts";
import { SectorCU } from "@scripts/generated/domaintables/sectors";
import { allSectorsAlphabetical } from "@scripts/syntax/sectors";
import type { DeepPartialWithOptions } from "@scripts/types";

import { noProductFocus } from "./productFocus";
import { tagLookupC } from "./tagLookup";

export const noSectorOption = {
  id: noProductFocus.id,
  name: "No Sector Listed",
  _tag: "noSector",
} as const;

const noSectorOptionTaggedC = t.type({
  _tag: t.literal(`noSector`),
});
type NoSectorOptionTaggedC = typeof noSectorOptionTaggedC;
type NoSectorOptionTagged = t.TypeOf<NoSectorOptionTaggedC>;
type NoSectorOption = NoSectorOptionTagged & typeof noSectorOption;
const noSectorOptionC = pipe(noSectorOptionTaggedC, c => new t.Type<NoSectorOption, NoSectorOptionTagged>(
  "BondOfferingSector noSector",
  (u: unknown): u is NoSectorOption => E.isRight(c.decode(u)),
  flow(c.decode, E.map(x => ({ ...x, ...noSectorOption }))),
  (x: NoSectorOption): NoSectorOptionTagged => ({ ...x, _tag: "noSector" })
));

const allSectorOptionCU = t.union([SectorCU, noSectorOptionC]);
type AllSectorOptionCU = typeof allSectorOptionCU;
export type AllSectorOptionU = t.TypeOf<AllSectorOptionCU>;

export const allSectorOptions: RNEA.ReadonlyNonEmptyArray<AllSectorOptionU> = RA.append<AllSectorOptionU>(noSectorOption)(allSectorsAlphabetical);

export const sectorLookupC = tagLookupC(allSectorOptionCU);

export const sectorsOrAll = (sectors?: DeepPartialWithOptions<ReadonlyArray<AllSectorOptionU>>): ReadonlyArray<AllSectorOptionU> => pipe(
  O.fromNullable(sectors),
  O.map(RA.filter(sectorLookupC.is)),
  O.getOrElse((): ReadonlyArray<AllSectorOptionU> => [])
);
