import { useEffect, useState } from "react";
import Cookie from "js-cookie";
import type { Dispatch } from "redux";

import { O, s } from "@scripts/fp-ts";
import { warning } from "@scripts/generated/domaintables/alertTypes";

import { ReloadAnchor } from "../components/Anchor";
import type { FlashAction } from "../state/flash";
import { flashAdd } from "../state/flash";

const SESSION_COOKIE = "JS_SESSION";

export const useSessionChange = (dispatch: Dispatch<FlashAction>) => {
  const [initialSession] = useState(O.fromNullable(Cookie.get(SESSION_COOKIE)));

  useEffect(() => {
    const setInvalidSessionAlert = () => {
      const newSession = O.fromNullable(Cookie.get(SESSION_COOKIE));
      if (!O.getEq(s.Eq).equals(newSession, initialSession)) {
        dispatch(flashAdd({
          type: warning,
          safe: true,
          msg: <>
            You have {
              O.isNone(initialSession)
                ? "logged in"
                : O.isNone(newSession)
                  ? "logged out"
                  : "switched accounts"
            } from another tab or window. <ReloadAnchor title="Refresh" /> the page to reload your session.
          </>,
        }));
        clearInterval(intervalId);
      }
    };
    const intervalId = window.setInterval(setInvalidSessionAlert, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
