import type * as t from "io-ts";

import type { ReadonlyNonEmptyArrayType } from "@scripts/codecs/readonlyNonEmptyArray";
import { Struct } from "@scripts/fp-ts";
import type { Match } from "@scripts/fp-ts/lib/types";

import { isNonNullable } from "./refinements";

export type HasProps<A extends t.Props> = { props: NonNullable<A> };

export function hasProps<A extends t.Props>(a: Match.AnyStruct): a is HasProps<A> {
  return "props" in a && isNonNullable(a["props"]);
}

export type HasName = { name: string };

export function hasName(a: Match.AnyStruct): a is HasName {
  return "name" in a && typeof a["name"] === "string";
}
export type HasUnion = { types: [t.Mixed, t.Mixed, ...t.Mixed[]] };

export function hasUnion(a: Match.AnyStruct): a is HasUnion {
  return "types" in a && Array.isArray(a["types"]) && a["types"].length >= 2;
}

export type HasType<A extends t.Mixed> = { type: A };

export function hasType<A extends t.Mixed>(a: Match.AnyStruct): a is HasType<A> {
  return "type" in a;
}

export type HasTag = { _tag: string };

export function hasTag(a: Match.AnyStruct): a is HasTag {
  return "_tag" in a && typeof a["name"] === "string";
}

export function isIotsCodec(a: unknown): a is t.Type<unknown> {
  return Struct.is(a)
    && hasName(a)
    && "is" in a
    && "validate" in a
    && "encode" in a
    && typeof a.is === "function"
    && typeof a.encode === "function"
    && typeof a.validate === "function";
}

export function isPartialC<A extends t.Props>(a: t.Mixed): a is t.PartialC<A> {
  return a.name.startsWith("Partial<");
}

export function isArrayOrReadonlyArrayC<A extends t.Mixed>(a: t.Mixed): a is t.ArrayC<A> | t.ReadonlyArrayC<A> {
  return hasTag(a) && (a._tag === "ArrayType" || a._tag === "ReadonlyArrayType");
}

export function isNonEmptyArrayTypeC<A extends t.Mixed>(a: t.Mixed): a is ReadonlyNonEmptyArrayType<A> {
  return hasTag(a) && a._tag === "NonEmptyArrayType";
}

export function isReadonlyNonEmptyArrayTypeC<A extends t.Mixed>(a: t.Mixed): a is ReadonlyNonEmptyArrayType<A> {
  return hasTag(a) && a._tag === "ReadonlyNonEmptyArrayType";
}

export function isAnyArrayC<A extends t.Mixed>(a: t.Mixed): a is t.ArrayC<A> | t.ReadonlyArrayC<A> {
  return (hasTag(a) && (a._tag === "ArrayType" || a._tag === "ReadonlyArrayType" || a._tag === "ReadonlyNonEmptyArrayType"));
}

export function isReadonlyTypeC(a: t.Mixed): a is t.ReadonlyType<t.Mixed, t.Mixed> {
  return hasTag(a) && a._tag === "ReadonlyType";
}

export function isUnionTypeC(a: t.Mixed): a is t.UnionType<[t.Mixed, t.Mixed, ...t.Mixed[]]> {
  return hasTag(a) && a._tag === "UnionType" && hasUnion(a);
}

export function isOptionC(a: t.Mixed): a is t.UnionType<[never, t.ExactType<t.InterfaceType<{ value: t.Mixed }>>]> {
  return isUnionTypeC(a) && a.name.startsWith("Option<");
}

export function isRefinementC(a: t.Mixed): a is t.RefinementType<t.Mixed> {
  return hasType(a) && hasTag(a) && a._tag === "RefinementType" && "predicate" in a;
}

export function isExactTypeC(a: t.Mixed): a is t.ExactType<t.Mixed> {
  return hasType(a) && hasTag(a) && a._tag === "ExactType";
}

export function isInterfaceTypeC(a: t.Mixed): a is t.TypeC<t.Props> {
  return hasTag(a) && hasProps(a) && a._tag === "InterfaceType";
}

export function isEitherTypeC(a: t.Mixed): a is t.UnionType<[t.ExactType<t.InterfaceType<{ _tag: t.LiteralType<"Left">, left: t.Mixed }>>, t.ExactType<t.InterfaceType<{ _tag: t.LiteralType<"Right">, right: t.Mixed }>>]> {
  return a.name.startsWith("Either<");
}

export function isDictionaryC(a: t.Mixed): a is t.DictionaryType<t.Mixed, t.Mixed> {
  return hasTag(a) && a._tag === "DictionaryType" && "codomain" in a && "domain" in a;
}

