import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const noContent = {
  _tag: `NoContent`,
  name: `NoContent`
} as const;

export const noContentTaggedC = t.type({
  _tag: t.literal(`NoContent`)
});
export type NoContentTaggedC = typeof noContentTaggedC;
export type NoContentTagged = t.TypeOf<NoContentTaggedC>;
export type NoContent = NoContentTagged & typeof noContent;
export const noContentC = pipe(noContentTaggedC, c => new t.Type<NoContent, NoContentTagged>(
  `NoContent`,
  (u: unknown): u is NoContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NoContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...noContent }))),
  (x: NoContent): NoContentTagged => ({ ...x, _tag: `NoContent`}),
));
export type NoContentC = typeof noContentC;


export const someContent = {
  _tag: `SomeContent`,
  name: `SomeContent`
} as const;

export const someContentTaggedC = t.type({
  _tag: t.literal(`SomeContent`)
});
export type SomeContentTaggedC = typeof someContentTaggedC;
export type SomeContentTagged = t.TypeOf<SomeContentTaggedC>;
export type SomeContent = SomeContentTagged & typeof someContent;
export const someContentC = pipe(someContentTaggedC, c => new t.Type<SomeContent, SomeContentTagged>(
  `SomeContent`,
  (u: unknown): u is SomeContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SomeContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...someContent }))),
  (x: SomeContent): SomeContentTagged => ({ ...x, _tag: `SomeContent`}),
));
export type SomeContentC = typeof someContentC;


export const strongContent = {
  _tag: `StrongContent`,
  name: `StrongContent`
} as const;

export const strongContentTaggedC = t.type({
  _tag: t.literal(`StrongContent`)
});
export type StrongContentTaggedC = typeof strongContentTaggedC;
export type StrongContentTagged = t.TypeOf<StrongContentTaggedC>;
export type StrongContent = StrongContentTagged & typeof strongContent;
export const strongContentC = pipe(strongContentTaggedC, c => new t.Type<StrongContent, StrongContentTagged>(
  `StrongContent`,
  (u: unknown): u is StrongContent => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StrongContent> => pipe(c.decode(u), E.map(x => ({ ...x, ...strongContent }))),
  (x: StrongContent): StrongContentTagged => ({ ...x, _tag: `StrongContent`}),
));
export type StrongContentC = typeof strongContentC;


export const allContentLevelC = [noContentC, someContentC, strongContentC] as const;
export const allContentLevelNames = [`NoContent`, `SomeContent`, `StrongContent`] as const;
export type ContentLevelName = (typeof allContentLevelNames)[number];

export const ContentLevelCU = t.union([noContentC, someContentC, strongContentC]);
export type ContentLevelCU = typeof ContentLevelCU;
export type ContentLevelU = t.TypeOf<ContentLevelCU>;

export const contentLevelOrd: Ord.Ord<ContentLevelU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allContentLevel = [noContent, someContent, strongContent] as const;
export type ContentLevelMap<A> = { [K in ContentLevelName]: A };


export const about = {
  _tag: `About`,
  name: `About`
} as const;

export const aboutTaggedC = t.type({
  _tag: t.literal(`About`)
});
export type AboutTaggedC = typeof aboutTaggedC;
export type AboutTagged = t.TypeOf<AboutTaggedC>;
export type About = AboutTagged & typeof about;
export const aboutC = pipe(aboutTaggedC, c => new t.Type<About, AboutTagged>(
  `About`,
  (u: unknown): u is About => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, About> => pipe(c.decode(u), E.map(x => ({ ...x, ...about }))),
  (x: About): AboutTagged => ({ ...x, _tag: `About`}),
));
export type AboutC = typeof aboutC;


export const ratings = {
  _tag: `Ratings`,
  name: `Ratings`
} as const;

export const ratingsTaggedC = t.type({
  _tag: t.literal(`Ratings`)
});
export type RatingsTaggedC = typeof ratingsTaggedC;
export type RatingsTagged = t.TypeOf<RatingsTaggedC>;
export type Ratings = RatingsTagged & typeof ratings;
export const ratingsC = pipe(ratingsTaggedC, c => new t.Type<Ratings, RatingsTagged>(
  `Ratings`,
  (u: unknown): u is Ratings => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ratings> => pipe(c.decode(u), E.map(x => ({ ...x, ...ratings }))),
  (x: Ratings): RatingsTagged => ({ ...x, _tag: `Ratings`}),
));
export type RatingsC = typeof ratingsC;


export const cusipLinks = {
  _tag: `CusipLinks`,
  name: `CusipLinks`
} as const;

export const cusipLinksTaggedC = t.type({
  _tag: t.literal(`CusipLinks`)
});
export type CusipLinksTaggedC = typeof cusipLinksTaggedC;
export type CusipLinksTagged = t.TypeOf<CusipLinksTaggedC>;
export type CusipLinks = CusipLinksTagged & typeof cusipLinks;
export const cusipLinksC = pipe(cusipLinksTaggedC, c => new t.Type<CusipLinks, CusipLinksTagged>(
  `CusipLinks`,
  (u: unknown): u is CusipLinks => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CusipLinks> => pipe(c.decode(u), E.map(x => ({ ...x, ...cusipLinks }))),
  (x: CusipLinks): CusipLinksTagged => ({ ...x, _tag: `CusipLinks`}),
));
export type CusipLinksC = typeof cusipLinksC;


export const documentUploads = {
  _tag: `DocumentUploads`,
  name: `DocumentUploads`
} as const;

export const documentUploadsTaggedC = t.type({
  _tag: t.literal(`DocumentUploads`)
});
export type DocumentUploadsTaggedC = typeof documentUploadsTaggedC;
export type DocumentUploadsTagged = t.TypeOf<DocumentUploadsTaggedC>;
export type DocumentUploads = DocumentUploadsTagged & typeof documentUploads;
export const documentUploadsC = pipe(documentUploadsTaggedC, c => new t.Type<DocumentUploads, DocumentUploadsTagged>(
  `DocumentUploads`,
  (u: unknown): u is DocumentUploads => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentUploads> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentUploads }))),
  (x: DocumentUploads): DocumentUploadsTagged => ({ ...x, _tag: `DocumentUploads`}),
));
export type DocumentUploadsC = typeof documentUploadsC;


export const issuerBios = {
  _tag: `IssuerBios`,
  name: `IssuerBios`
} as const;

export const issuerBiosTaggedC = t.type({
  _tag: t.literal(`IssuerBios`)
});
export type IssuerBiosTaggedC = typeof issuerBiosTaggedC;
export type IssuerBiosTagged = t.TypeOf<IssuerBiosTaggedC>;
export type IssuerBios = IssuerBiosTagged & typeof issuerBios;
export const issuerBiosC = pipe(issuerBiosTaggedC, c => new t.Type<IssuerBios, IssuerBiosTagged>(
  `IssuerBios`,
  (u: unknown): u is IssuerBios => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerBios> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerBios }))),
  (x: IssuerBios): IssuerBiosTagged => ({ ...x, _tag: `IssuerBios`}),
));
export type IssuerBiosC = typeof issuerBiosC;


export const newsUpdates = {
  _tag: `NewsUpdates`,
  name: `NewsUpdates`
} as const;

export const newsUpdatesTaggedC = t.type({
  _tag: t.literal(`NewsUpdates`)
});
export type NewsUpdatesTaggedC = typeof newsUpdatesTaggedC;
export type NewsUpdatesTagged = t.TypeOf<NewsUpdatesTaggedC>;
export type NewsUpdates = NewsUpdatesTagged & typeof newsUpdates;
export const newsUpdatesC = pipe(newsUpdatesTaggedC, c => new t.Type<NewsUpdates, NewsUpdatesTagged>(
  `NewsUpdates`,
  (u: unknown): u is NewsUpdates => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewsUpdates> => pipe(c.decode(u), E.map(x => ({ ...x, ...newsUpdates }))),
  (x: NewsUpdates): NewsUpdatesTagged => ({ ...x, _tag: `NewsUpdates`}),
));
export type NewsUpdatesC = typeof newsUpdatesC;


export const projects = {
  _tag: `Projects`,
  name: `Projects`
} as const;

export const projectsTaggedC = t.type({
  _tag: t.literal(`Projects`)
});
export type ProjectsTaggedC = typeof projectsTaggedC;
export type ProjectsTagged = t.TypeOf<ProjectsTaggedC>;
export type Projects = ProjectsTagged & typeof projects;
export const projectsC = pipe(projectsTaggedC, c => new t.Type<Projects, ProjectsTagged>(
  `Projects`,
  (u: unknown): u is Projects => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Projects> => pipe(c.decode(u), E.map(x => ({ ...x, ...projects }))),
  (x: Projects): ProjectsTagged => ({ ...x, _tag: `Projects`}),
));
export type ProjectsC = typeof projectsC;


export const quickFacts = {
  _tag: `QuickFacts`,
  name: `QuickFacts`
} as const;

export const quickFactsTaggedC = t.type({
  _tag: t.literal(`QuickFacts`)
});
export type QuickFactsTaggedC = typeof quickFactsTaggedC;
export type QuickFactsTagged = t.TypeOf<QuickFactsTaggedC>;
export type QuickFacts = QuickFactsTagged & typeof quickFacts;
export const quickFactsC = pipe(quickFactsTaggedC, c => new t.Type<QuickFacts, QuickFactsTagged>(
  `QuickFacts`,
  (u: unknown): u is QuickFacts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, QuickFacts> => pipe(c.decode(u), E.map(x => ({ ...x, ...quickFacts }))),
  (x: QuickFacts): QuickFactsTagged => ({ ...x, _tag: `QuickFacts`}),
));
export type QuickFactsC = typeof quickFactsC;


export const upcomingEvents = {
  _tag: `UpcomingEvents`,
  name: `UpcomingEvents`
} as const;

export const upcomingEventsTaggedC = t.type({
  _tag: t.literal(`UpcomingEvents`)
});
export type UpcomingEventsTaggedC = typeof upcomingEventsTaggedC;
export type UpcomingEventsTagged = t.TypeOf<UpcomingEventsTaggedC>;
export type UpcomingEvents = UpcomingEventsTagged & typeof upcomingEvents;
export const upcomingEventsC = pipe(upcomingEventsTaggedC, c => new t.Type<UpcomingEvents, UpcomingEventsTagged>(
  `UpcomingEvents`,
  (u: unknown): u is UpcomingEvents => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UpcomingEvents> => pipe(c.decode(u), E.map(x => ({ ...x, ...upcomingEvents }))),
  (x: UpcomingEvents): UpcomingEventsTagged => ({ ...x, _tag: `UpcomingEvents`}),
));
export type UpcomingEventsC = typeof upcomingEventsC;


export const allProfileSectionC = [aboutC, ratingsC, cusipLinksC, documentUploadsC, issuerBiosC, newsUpdatesC, projectsC, quickFactsC, upcomingEventsC] as const;
export const allProfileSectionNames = [`About`, `Ratings`, `CusipLinks`, `DocumentUploads`, `IssuerBios`, `NewsUpdates`, `Projects`, `QuickFacts`, `UpcomingEvents`] as const;
export type ProfileSectionName = (typeof allProfileSectionNames)[number];

export const ProfileSectionCU = t.union([aboutC, ratingsC, cusipLinksC, documentUploadsC, issuerBiosC, newsUpdatesC, projectsC, quickFactsC, upcomingEventsC]);
export type ProfileSectionCU = typeof ProfileSectionCU;
export type ProfileSectionU = t.TypeOf<ProfileSectionCU>;

export const profileSectionOrd: Ord.Ord<ProfileSectionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allProfileSection = [about, ratings, cusipLinks, documentUploads, issuerBios, newsUpdates, projects, quickFacts, upcomingEvents] as const;
export type ProfileSectionMap<A> = { [K in ProfileSectionName]: A };


export const rankedMetricC = t.type({
  section: ProfileSectionCU,
  level: ContentLevelCU
});
export type RankedMetricC = typeof rankedMetricC;
export type RankedMetric = t.TypeOf<RankedMetricC>;


export const profileMetricsC = t.type({
  noContent: t.readonlyArray(rankedMetricC),
  someContent: t.readonlyArray(rankedMetricC),
  strongContent: t.readonlyArray(rankedMetricC),
  disabledSections: t.readonlyArray(ProfileSectionCU)
});
export type ProfileMetricsC = typeof profileMetricsC;
export type ProfileMetrics = t.TypeOf<ProfileMetricsC>;


