import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";
import { DisclaimerTypeCU as imported3_DisclaimerTypeCU } from "../domaintables/disclaimerTypes";

export const disclaimerC = t.type({
  disclaimerType: imported3_DisclaimerTypeCU,
  data: markdownC,
  sessionLength: optionFromNullable(t.number)
});
export type DisclaimerC = typeof disclaimerC;
export type Disclaimer = t.TypeOf<DisclaimerC>;


export const disclaimerPostC = t.type({
  disclaimerTypeId: t.number,
  data: markdownC,
  sessionLength: optionFromNullable(t.number)
});
export type DisclaimerPostC = typeof disclaimerPostC;
export type DisclaimerPost = t.TypeOf<DisclaimerPostC>;


export const acceptDisclaimerPostC = t.type({
  disclaimerCookieNames: t.readonlyArray(t.string)
});
export type AcceptDisclaimerPostC = typeof acceptDisclaimerPostC;
export type AcceptDisclaimerPost = t.TypeOf<AcceptDisclaimerPostC>;


