import type { Dispatch, SetStateAction } from "react";

import { constVoid, E, O } from "@scripts/fp-ts";
import type { ForgotPasswordPostC } from "@scripts/generated/models/login";
import { useConfig } from "@scripts/react/context/Config";
import { stringC } from "@scripts/react/form/codecs";
import type { FormState } from "@scripts/react/form/form";
import { formLens } from "@scripts/react/form/form";
import type { CloseModalFn } from "@scripts/react/util/useModal";

import { ContactBondLinkAnchor } from "../Anchor";
import { ButtonPrimary } from "../Button";
import { ActionButtons } from "./ActionButtons";
import { EmailInput } from "./TextInput";

export const Submitted = (props: { dismissAction: CloseModalFn }) => {
  const config = useConfig();
  return (
    <>
      <h3>Check your email for password instructions</h3>
      <p>If you have an account registered to this email address,
        you’ll receive an email with instructions on how to reset
        your password. If you do not receive an email, you can try
        registering as a new user, or you can contact us for help at{" "}
        <ContactBondLinkAnchor title={config.customerSuccessEmail} />{" "}
        and we’ll be happy to assist you.
      </p>
      <ButtonPrimary onClick={props.dismissAction}>OK</ButtonPrimary>
    </>
  );
};

const forgotPasswordFormL = formLens<ForgotPasswordPostC>();

export const ForgotPasswordFormBody = (props: {
  dismissAction: CloseModalFn;
  formState: FormState<ForgotPasswordPostC>;
  setFormState: Dispatch<SetStateAction<FormState<ForgotPasswordPostC>>>;
}) => {
  const config = useConfig();
  return (
    <>
      <p>
        Enter your email address below and we’ll send you a password reset link. If you need additional
        help, you can contact us at: <ContactBondLinkAnchor title={config.customerSuccessEmail} />
      </p>
      <EmailInput
        autoComplete="username"
        codec={stringC}
        labelOrAriaLabel={E.left("Email address")}
        lens={forgotPasswordFormL("email")}
        state={props.formState}
        setState={props.setFormState}
      />
      <ActionButtons
        noDivider
        deleteButton={O.none}
        dismissAction={props.dismissAction}
        loading={props.formState.loading}
        onSubmit={constVoid}
        submitText="submit"
      />
    </>
  );
};
