import { O } from "@scripts/fp-ts";
import { ButtonsContainer, ButtonSecondary } from "@scripts/react/components/Button";
import type { ModalDismissable, ModalOpenable } from "@scripts/react/components/modal/Modal";
import { Modal } from "@scripts/react/components/modal/Modal";
import { klass } from "@scripts/react/util/classnames";

export const BLPDisclaimer = (props: { header: boolean }) =>
  <>
    {props.header && <h2>BondLink Primary Disclaimer</h2>}
    <p {...klass("mb-1", "font-sans-italic-400")}>
      UNDER NO CIRCUMSTANCES SHALL THE INFORMATION ON THE SITE CONSTITUTE AN OFFER TO SELL OR THE
      SOLICITATION OF AN OFFER TO BUY, NOR SHALL THERE BE ANY SALE OF SECURITIES IN ANY JURISDICTION
      IN WHICH SUCH OFFER, SOLICITATION OR SALE WOULD BE UNLAWFUL PRIOR TO REGISTRATION OR QUALIFICATION
      UNDER THE SECURITIES LAWS OF ANY SUCH JURISDICTION. OFFERS TO PURCHASE SECURITIES MAY BE MADE ONLY
      THROUGH A REGISTERED BROKER-DEALER AND THROUGH AN OFFICIAL STATEMENT.
    </p>
    <p {...klass("mb-1")}>
      The Content on this Site with respect to any issuer, borrower or securities was specifically
      authorized by an underwriter for the securities to be communicated through this Site to,
      among other things, electronically distribute offering materials and other information regarding
      securities. You should consult a qualified professional for investment, business, tax, and
      legal advice regarding any securities or other investment, and you should contact the
      underwriters/initial purchasers with any questions about the offer to which offering materials relate.
    </p>
    <p {...klass("mb-1")}>
      The Content is included for general informational purposes only and no person
      should make any investment decision in reliance upon the Content contained on the Site.
    </p>
    <p {...klass("mb-1")}>
      The Content presented on the Site speaks only as of the date the Content was prepared by the
      issuer or other third party and has not been updated since that date and as a result,
      may not address all factors which may be material to an investor and may contain omissions of fact
      or statements that are not accurate because of the passage of time or changes in facts or
      circumstance subsequent to the date of such Content.
    </p>
    <p {...klass("mb-1")}>
      This website contains certain forward-looking statements, including projections and estimates,
      that are subject to a variety of risks and uncertainties that could cause actual results to
      differ from the projected results, including without limitation general economic and business
      conditions, conditions in the financial markets, the financial condition of the issuer or borrower.
      Because of the inability to predict all factors that may affect future decisions, actions,
      events or financial circumstances, including, in particular, current adverse global financial
      market and economic conditions, what actually happens may be different from what is set
      forth in such forward-looking statements.
    </p>
    <p {...klass("mb-1")}>
      BondLink has not prepared or developed any of the Content on the Site and the Content
      available on the Site with respect to any issuer, borrower or securities is not maintained
      by or under the control of BondLink.  BondLink has not reviewed such information in any respect
      and has not checked such information for accuracy, and in some cases, the information provided
      may not have been viewed by BondLink.  BondLink is not registered as a broker or dealer nor is
      it a member of a self-regulatory organization. Nothing herein is intended to or shall require
      BondLink to engage in any activities that would cause BondLink to be required to register as
      a broker or dealer in any jurisdiction in which Site is provided. See “Terms” for the full
      terms of use of this website including your release of any and all claims against BondLink
      for use of this website.
    </p>
  </>;

export const BondLinkDisclaimer = (props: { header: boolean }) =>
  <>
    {props.header && <h2>BondLink Disclaimer</h2>}
    <p {...klass("font-sans-italic-400")}>
      UNDER NO CIRCUMSTANCES SHALL THE INFORMATION ON THE SITE CONSTITUTE AN OFFER TO SELL OR THE SOLICITATION OF AN
      OFFER TO BUY, NOR SHALL THERE BE ANY SALE OF SECURITIES IN ANY JURISDICTION IN WHICH SUCH OFFER, SOLICITATION
      OR SALE WOULD BE UNLAWFUL PRIOR TO REGISTRATION OR QUALIFICATION UNDER THE SECURITIES LAWS OF ANY SUCH JURISDICTION.
      OFFERS TO PURCHASE SECURITIES MAY BE MADE ONLY THROUGH A REGISTERED BROKER-DEALER AND THROUGH AN OFFICIAL STATEMENT.
    </p>
    <p>
      BondLink has not prepared or developed any of the Content on the Site and the Content available on the Site with
      respect to any issuer is not maintained by or under the control of BondLink. The content on this Site with respect
      to any issuer was specifically authorized by that issuer to be communicated through this Site. BondLink has not
      reviewed such information in any respect and has not checked such information for accuracy, and in some cases, the
      information provided may not have been viewed by BondLink. The Content contained on the Site has been provided by
      the applicable issuer and is included for general informational purposes only and is provided without warranty of
      any kind and, in particular, no representation or warranty, express or implied, is made or is to be inferred as to
      the accuracy, reliability, timeliness or completeness of any such information. The Content presented on the Site
      speaks only as of the date the Content was prepared by the issuer or other third party and has not been updated
      since that date and as a result, may not address all factors which may be material to an investor and may contain
      omissions of fact or statements that are not accurate because of the passage of time or changes in facts or
      circumstance subsequent to the date of such Content. Consequently, no person should make any investment decision
      in reliance upon the Content contained on the Site. See “Terms” for the full terms of use of this website including
      your release of any and all claims against BondLink for use of this website.
    </p>
  </>;

export type DisclaimerType = "BLP" | "general";

export const DisclaimerModal = (props: ModalOpenable & ModalDismissable & { disclaimerType: DisclaimerType }) =>
  <Modal
    dismissAction={props.dismissAction}
    id="disclaimerModal"
    title={"BondLink Disclaimer"}
    icon={O.none}
    type={"primary"}
    open={props.modalOpen}
    size="modal-lg"
    body={
      <>
        {props.disclaimerType === "BLP"
          ? <BLPDisclaimer header={false} />
          : <BondLinkDisclaimer header={false} />
        }
        <ButtonsContainer klasses={O.none} divider>
          <ButtonSecondary onClick={props.dismissAction}>
            Close
          </ButtonSecondary>
        </ButtonsContainer>
      </>
    }
  />;
