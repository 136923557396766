import type { EqualityFn, NoInfer, TypedUseSelectorHook } from "react-redux";
import { useDispatch, useSelector } from "react-redux"; // eslint-disable-line @typescript-eslint/no-restricted-imports
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import type { Dispatch, Reducer, Store } from "redux";
import { legacy_createStore as createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import type { BLConfigWithLog } from "@scripts/bondlink";
import type { EmptyObject } from "@scripts/generated/models/emptyObject";
import { flashC } from "@scripts/generated/models/flash";
import { userWithRolesC } from "@scripts/generated/models/user";
import { type FlashAction, flashReducer } from "@scripts/react/state/flash";
import type { NotificationAction, NotificationStore } from "@scripts/react/state/notifications";
import { emptyNotificationStore, notificationReducer } from "@scripts/react/state/notifications";
import { isDevtoolAction, isFlashAction, isNotificationAction, isReduxAction, isUserAction } from "@scripts/react/state/store";
import type { UserAction } from "@scripts/react/state/user";
import { userReducer } from "@scripts/react/state/user";


export type PagesActions = NotificationAction | UserAction | FlashAction;

export const pagesInitialStateC = t.type({
  flash: t.readonlyArray(flashC),
  user: optionFromNullable(userWithRolesC),
});
export type PagesInitialStateC = typeof pagesInitialStateC;
export type PagesInitialState = t.TypeOf<PagesInitialStateC>;

export type PagesState = PagesInitialState & {
  notifications: NotificationStore;
};

const emptyState = (initialState: PagesInitialState): PagesState => ({
  ...initialState,
  notifications: emptyNotificationStore,
});

const reducers = (config: BLConfigWithLog) =>
  (initialState: PagesInitialState): Reducer<PagesState, PagesActions> =>
    (state: PagesState = emptyState(initialState), action: PagesActions): PagesState => {
      if (isFlashAction(action)) {
        return {
          ...state,
          flash: flashReducer(config)(state.flash, action),
        };
      } else if (isNotificationAction(action)) {
        return {
          ...state,
          notifications: notificationReducer(config)(state.notifications, action),
        };
      } else if (isUserAction(action)) {
        return {
          ...state,
          user: pipe(state.user, O.map(u => userReducer(config)(u, action))),
        };
      } else if (isReduxAction(action) || isDevtoolAction(action)) {
        return state;
      }
      return config.exhaustive(action);
    };

export type PagesStore = Store<PagesState, PagesActions>;

export const createPagesStore = (config: BLConfigWithLog) =>
  (initialState: PagesInitialState): PagesStore =>
    createStore<PagesState, PagesActions, EmptyObject, unknown>(reducers(config)(initialState), emptyState(initialState), devToolsEnhancer({}));

export const usePagesSelectorUnsafe: TypedUseSelectorHook<PagesState> = useSelector;
export const usePagesSelector = <K extends keyof PagesState>(
  k: K,
  equalityFn?: EqualityFn<NoInfer<PagesState[K]>>
): PagesState[K] => usePagesSelectorUnsafe((_): PagesState[K] => _[k], equalityFn);

export type PagesDispatch = Dispatch<PagesActions>;
export const usePagesDispatch = useDispatch<PagesDispatch>;
