import { DateQualifierCU as imported2_DateQualifierCU, DateQualifierCU as imported3_DateQualifierCU } from "../domaintables/dateQualifiers";
import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";

export const dateQualifierC = t.type({
  qualifier: imported2_DateQualifierCU,
  date: LocalDateC
});
export type DateQualifierC = typeof dateQualifierC;
export type DateQualifier = t.TypeOf<DateQualifierC>;


export const dateQualifierRangeC = t.type({
  qualifier: imported3_DateQualifierCU,
  date: LocalDateC,
  endDate: LocalDateC
});
export type DateQualifierRangeC = typeof dateQualifierRangeC;
export type DateQualifierRange = t.TypeOf<DateQualifierRangeC>;


