import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const competitive = {
  _tag: `Competitive`,
  id: 1,
  name: `Competitive`
} as const;

export const competitiveTaggedC = t.type({
  _tag: t.literal(`Competitive`)
});
export type CompetitiveTaggedC = typeof competitiveTaggedC;
export type CompetitiveTagged = t.TypeOf<CompetitiveTaggedC>;
export type Competitive = CompetitiveTagged & typeof competitive;
export const competitiveC = pipe(competitiveTaggedC, c => new t.Type<Competitive, CompetitiveTagged>(
  `Competitive`,
  (u: unknown): u is Competitive => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Competitive> => pipe(c.decode(u), E.map(x => ({ ...x, ...competitive }))),
  (x: Competitive): CompetitiveTagged => ({ ...x, _tag: `Competitive`}),
));
export type CompetitiveC = typeof competitiveC;


export const negotiated = {
  _tag: `Negotiated`,
  id: 2,
  name: `Negotiated`
} as const;

export const negotiatedTaggedC = t.type({
  _tag: t.literal(`Negotiated`)
});
export type NegotiatedTaggedC = typeof negotiatedTaggedC;
export type NegotiatedTagged = t.TypeOf<NegotiatedTaggedC>;
export type Negotiated = NegotiatedTagged & typeof negotiated;
export const negotiatedC = pipe(negotiatedTaggedC, c => new t.Type<Negotiated, NegotiatedTagged>(
  `Negotiated`,
  (u: unknown): u is Negotiated => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Negotiated> => pipe(c.decode(u), E.map(x => ({ ...x, ...negotiated }))),
  (x: Negotiated): NegotiatedTagged => ({ ...x, _tag: `Negotiated`}),
));
export type NegotiatedC = typeof negotiatedC;


export const allMethodOfSaleC = [competitiveC, negotiatedC] as const;
export const allMethodOfSaleNames = [`Competitive`, `Negotiated`] as const;
export type MethodOfSaleName = (typeof allMethodOfSaleNames)[number];

export const MethodOfSaleCU = t.union([competitiveC, negotiatedC]);
export type MethodOfSaleCU = typeof MethodOfSaleCU;
export type MethodOfSaleU = t.TypeOf<MethodOfSaleCU>;

export const methodOfSaleOrd: Ord.Ord<MethodOfSaleU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMethodOfSale = [competitive, negotiated] as const;
export type MethodOfSaleMap<A> = { [K in MethodOfSaleName]: A };


