import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/Option";

import checkmark from "@svgs/checkmark.svg";

import { Svg } from "./Svg";

type PasswordStrengthProps = {
  score: O.Option<number>;
};

export const PasswordStrength = (props: PasswordStrengthProps) => {
  const attr = pipe(props.score, O.fold(() => ({}), s => ({ "data-strength": s })));

  return <div className="pw-strength mb-1" {...attr} >
    <div className="pw-strength-indicators">
      <div className="pw-strength-bar" />
    </div>
    <div className="small d-flex align-items-center">
      <div className="tooltip-target nohover">
        <div className="pw-strength-text" />
      </div>
      <Svg src={checkmark} className="checkmark" />
    </div>
  </div>;
};
