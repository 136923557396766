import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const ET = {
  _tag: `ET`,
  id: 1,
  name: `ET`
} as const;

export const ETTaggedC = t.type({
  _tag: t.literal(`ET`)
});
export type ETTaggedC = typeof ETTaggedC;
export type ETTagged = t.TypeOf<ETTaggedC>;
export type ET = ETTagged & typeof ET;
export const ETC = pipe(ETTaggedC, c => new t.Type<ET, ETTagged>(
  `ET`,
  (u: unknown): u is ET => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ET> => pipe(c.decode(u), E.map(x => ({ ...x, ...ET }))),
  (x: ET): ETTagged => ({ ...x, _tag: `ET`}),
));
export type ETC = typeof ETC;


export const CT = {
  _tag: `CT`,
  id: 2,
  name: `CT`
} as const;

export const CTTaggedC = t.type({
  _tag: t.literal(`CT`)
});
export type CTTaggedC = typeof CTTaggedC;
export type CTTagged = t.TypeOf<CTTaggedC>;
export type CT = CTTagged & typeof CT;
export const CTC = pipe(CTTaggedC, c => new t.Type<CT, CTTagged>(
  `CT`,
  (u: unknown): u is CT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CT> => pipe(c.decode(u), E.map(x => ({ ...x, ...CT }))),
  (x: CT): CTTagged => ({ ...x, _tag: `CT`}),
));
export type CTC = typeof CTC;


export const MT = {
  _tag: `MT`,
  id: 3,
  name: `MT`
} as const;

export const MTTaggedC = t.type({
  _tag: t.literal(`MT`)
});
export type MTTaggedC = typeof MTTaggedC;
export type MTTagged = t.TypeOf<MTTaggedC>;
export type MT = MTTagged & typeof MT;
export const MTC = pipe(MTTaggedC, c => new t.Type<MT, MTTagged>(
  `MT`,
  (u: unknown): u is MT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MT> => pipe(c.decode(u), E.map(x => ({ ...x, ...MT }))),
  (x: MT): MTTagged => ({ ...x, _tag: `MT`}),
));
export type MTC = typeof MTC;


export const PT = {
  _tag: `PT`,
  id: 4,
  name: `PT`
} as const;

export const PTTaggedC = t.type({
  _tag: t.literal(`PT`)
});
export type PTTaggedC = typeof PTTaggedC;
export type PTTagged = t.TypeOf<PTTaggedC>;
export type PT = PTTagged & typeof PT;
export const PTC = pipe(PTTaggedC, c => new t.Type<PT, PTTagged>(
  `PT`,
  (u: unknown): u is PT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PT> => pipe(c.decode(u), E.map(x => ({ ...x, ...PT }))),
  (x: PT): PTTagged => ({ ...x, _tag: `PT`}),
));
export type PTC = typeof PTC;


export const AKT = {
  _tag: `AKT`,
  id: 5,
  name: `AKT`
} as const;

export const AKTTaggedC = t.type({
  _tag: t.literal(`AKT`)
});
export type AKTTaggedC = typeof AKTTaggedC;
export type AKTTagged = t.TypeOf<AKTTaggedC>;
export type AKT = AKTTagged & typeof AKT;
export const AKTC = pipe(AKTTaggedC, c => new t.Type<AKT, AKTTagged>(
  `AKT`,
  (u: unknown): u is AKT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AKT> => pipe(c.decode(u), E.map(x => ({ ...x, ...AKT }))),
  (x: AKT): AKTTagged => ({ ...x, _tag: `AKT`}),
));
export type AKTC = typeof AKTC;


export const HT = {
  _tag: `HT`,
  id: 6,
  name: `HT`
} as const;

export const HTTaggedC = t.type({
  _tag: t.literal(`HT`)
});
export type HTTaggedC = typeof HTTaggedC;
export type HTTagged = t.TypeOf<HTTaggedC>;
export type HT = HTTagged & typeof HT;
export const HTC = pipe(HTTaggedC, c => new t.Type<HT, HTTagged>(
  `HT`,
  (u: unknown): u is HT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HT> => pipe(c.decode(u), E.map(x => ({ ...x, ...HT }))),
  (x: HT): HTTagged => ({ ...x, _tag: `HT`}),
));
export type HTC = typeof HTC;


export const AT = {
  _tag: `AT`,
  id: 7,
  name: `AT`
} as const;

export const ATTaggedC = t.type({
  _tag: t.literal(`AT`)
});
export type ATTaggedC = typeof ATTaggedC;
export type ATTagged = t.TypeOf<ATTaggedC>;
export type AT = ATTagged & typeof AT;
export const ATC = pipe(ATTaggedC, c => new t.Type<AT, ATTagged>(
  `AT`,
  (u: unknown): u is AT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AT> => pipe(c.decode(u), E.map(x => ({ ...x, ...AT }))),
  (x: AT): ATTagged => ({ ...x, _tag: `AT`}),
));
export type ATC = typeof ATC;


export const allTimeZoneC = [ETC, CTC, MTC, PTC, AKTC, HTC, ATC] as const;
export const allTimeZoneNames = [`ET`, `CT`, `MT`, `PT`, `AKT`, `HT`, `AT`] as const;
export type TimeZoneName = (typeof allTimeZoneNames)[number];

export const TimeZoneCU = t.union([ETC, CTC, MTC, PTC, AKTC, HTC, ATC]);
export type TimeZoneCU = typeof TimeZoneCU;
export type TimeZoneU = t.TypeOf<TimeZoneCU>;

export const timeZoneOrd: Ord.Ord<TimeZoneU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allTimeZone = [ET, CT, MT, PT, AKT, HT, AT] as const;
export type TimeZoneMap<A> = { [K in TimeZoneName]: A };


