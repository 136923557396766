import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const EMPTY_RESULT = {
  _tag: `EMPTY_RESULT`,
  error: `EMPTY_RESULT`
} as const;

export const EMPTY_RESULTTaggedC = t.type({
  _tag: t.literal(`EMPTY_RESULT`)
});
export type EMPTY_RESULTTaggedC = typeof EMPTY_RESULTTaggedC;
export type EMPTY_RESULTTagged = t.TypeOf<EMPTY_RESULTTaggedC>;
export type EMPTY_RESULT = EMPTY_RESULTTagged & typeof EMPTY_RESULT;
export const EMPTY_RESULTC = pipe(EMPTY_RESULTTaggedC, c => new t.Type<EMPTY_RESULT, EMPTY_RESULTTagged>(
  `EMPTY_RESULT`,
  (u: unknown): u is EMPTY_RESULT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EMPTY_RESULT> => pipe(c.decode(u), E.map(x => ({ ...x, ...EMPTY_RESULT }))),
  (x: EMPTY_RESULT): EMPTY_RESULTTagged => ({ ...x, _tag: `EMPTY_RESULT`}),
));
export type EMPTY_RESULTC = typeof EMPTY_RESULTC;


export const RESOURCE_NOT_FOUND = {
  _tag: `RESOURCE_NOT_FOUND`,
  error: `RESOURCE_NOT_FOUND`
} as const;

export const RESOURCE_NOT_FOUNDTaggedC = t.type({
  _tag: t.literal(`RESOURCE_NOT_FOUND`)
});
export type RESOURCE_NOT_FOUNDTaggedC = typeof RESOURCE_NOT_FOUNDTaggedC;
export type RESOURCE_NOT_FOUNDTagged = t.TypeOf<RESOURCE_NOT_FOUNDTaggedC>;
export type RESOURCE_NOT_FOUND = RESOURCE_NOT_FOUNDTagged & typeof RESOURCE_NOT_FOUND;
export const RESOURCE_NOT_FOUNDC = pipe(RESOURCE_NOT_FOUNDTaggedC, c => new t.Type<RESOURCE_NOT_FOUND, RESOURCE_NOT_FOUNDTagged>(
  `RESOURCE_NOT_FOUND`,
  (u: unknown): u is RESOURCE_NOT_FOUND => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RESOURCE_NOT_FOUND> => pipe(c.decode(u), E.map(x => ({ ...x, ...RESOURCE_NOT_FOUND }))),
  (x: RESOURCE_NOT_FOUND): RESOURCE_NOT_FOUNDTagged => ({ ...x, _tag: `RESOURCE_NOT_FOUND`}),
));
export type RESOURCE_NOT_FOUNDC = typeof RESOURCE_NOT_FOUNDC;


export const ID_NOT_FOUND = {
  _tag: `ID_NOT_FOUND`,
  error: `ID_NOT_FOUND`
} as const;

export const ID_NOT_FOUNDTaggedC = t.type({
  _tag: t.literal(`ID_NOT_FOUND`)
});
export type ID_NOT_FOUNDTaggedC = typeof ID_NOT_FOUNDTaggedC;
export type ID_NOT_FOUNDTagged = t.TypeOf<ID_NOT_FOUNDTaggedC>;
export type ID_NOT_FOUND = ID_NOT_FOUNDTagged & typeof ID_NOT_FOUND;
export const ID_NOT_FOUNDC = pipe(ID_NOT_FOUNDTaggedC, c => new t.Type<ID_NOT_FOUND, ID_NOT_FOUNDTagged>(
  `ID_NOT_FOUND`,
  (u: unknown): u is ID_NOT_FOUND => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ID_NOT_FOUND> => pipe(c.decode(u), E.map(x => ({ ...x, ...ID_NOT_FOUND }))),
  (x: ID_NOT_FOUND): ID_NOT_FOUNDTagged => ({ ...x, _tag: `ID_NOT_FOUND`}),
));
export type ID_NOT_FOUNDC = typeof ID_NOT_FOUNDC;


export const MISSING_REQUIRED_FIELD = {
  _tag: `MISSING_REQUIRED_FIELD`,
  error: `MISSING_REQUIRED_FIELD`
} as const;

export const MISSING_REQUIRED_FIELDTaggedC = t.type({
  _tag: t.literal(`MISSING_REQUIRED_FIELD`)
});
export type MISSING_REQUIRED_FIELDTaggedC = typeof MISSING_REQUIRED_FIELDTaggedC;
export type MISSING_REQUIRED_FIELDTagged = t.TypeOf<MISSING_REQUIRED_FIELDTaggedC>;
export type MISSING_REQUIRED_FIELD = MISSING_REQUIRED_FIELDTagged & typeof MISSING_REQUIRED_FIELD;
export const MISSING_REQUIRED_FIELDC = pipe(MISSING_REQUIRED_FIELDTaggedC, c => new t.Type<MISSING_REQUIRED_FIELD, MISSING_REQUIRED_FIELDTagged>(
  `MISSING_REQUIRED_FIELD`,
  (u: unknown): u is MISSING_REQUIRED_FIELD => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MISSING_REQUIRED_FIELD> => pipe(c.decode(u), E.map(x => ({ ...x, ...MISSING_REQUIRED_FIELD }))),
  (x: MISSING_REQUIRED_FIELD): MISSING_REQUIRED_FIELDTagged => ({ ...x, _tag: `MISSING_REQUIRED_FIELD`}),
));
export type MISSING_REQUIRED_FIELDC = typeof MISSING_REQUIRED_FIELDC;


export const CUSTOM = {
  _tag: `CUSTOM`,
  error: `CUSTOM`
} as const;

export const CUSTOMTaggedC = t.type({
  _tag: t.literal(`CUSTOM`)
});
export type CUSTOMTaggedC = typeof CUSTOMTaggedC;
export type CUSTOMTagged = t.TypeOf<CUSTOMTaggedC>;
export type CUSTOM = CUSTOMTagged & typeof CUSTOM;
export const CUSTOMC = pipe(CUSTOMTaggedC, c => new t.Type<CUSTOM, CUSTOMTagged>(
  `CUSTOM`,
  (u: unknown): u is CUSTOM => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CUSTOM> => pipe(c.decode(u), E.map(x => ({ ...x, ...CUSTOM }))),
  (x: CUSTOM): CUSTOMTagged => ({ ...x, _tag: `CUSTOM`}),
));
export type CUSTOMC = typeof CUSTOMC;


export const TOO_MANY_RESULTS = {
  _tag: `TOO_MANY_RESULTS`,
  error: `TOO_MANY_RESULTS`
} as const;

export const TOO_MANY_RESULTSTaggedC = t.type({
  _tag: t.literal(`TOO_MANY_RESULTS`)
});
export type TOO_MANY_RESULTSTaggedC = typeof TOO_MANY_RESULTSTaggedC;
export type TOO_MANY_RESULTSTagged = t.TypeOf<TOO_MANY_RESULTSTaggedC>;
export type TOO_MANY_RESULTS = TOO_MANY_RESULTSTagged & typeof TOO_MANY_RESULTS;
export const TOO_MANY_RESULTSC = pipe(TOO_MANY_RESULTSTaggedC, c => new t.Type<TOO_MANY_RESULTS, TOO_MANY_RESULTSTagged>(
  `TOO_MANY_RESULTS`,
  (u: unknown): u is TOO_MANY_RESULTS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TOO_MANY_RESULTS> => pipe(c.decode(u), E.map(x => ({ ...x, ...TOO_MANY_RESULTS }))),
  (x: TOO_MANY_RESULTS): TOO_MANY_RESULTSTagged => ({ ...x, _tag: `TOO_MANY_RESULTS`}),
));
export type TOO_MANY_RESULTSC = typeof TOO_MANY_RESULTSC;


export const TOO_MANY_REQUESTS = {
  _tag: `TOO_MANY_REQUESTS`,
  error: `TOO_MANY_REQUESTS`
} as const;

export const TOO_MANY_REQUESTSTaggedC = t.type({
  _tag: t.literal(`TOO_MANY_REQUESTS`)
});
export type TOO_MANY_REQUESTSTaggedC = typeof TOO_MANY_REQUESTSTaggedC;
export type TOO_MANY_REQUESTSTagged = t.TypeOf<TOO_MANY_REQUESTSTaggedC>;
export type TOO_MANY_REQUESTS = TOO_MANY_REQUESTSTagged & typeof TOO_MANY_REQUESTS;
export const TOO_MANY_REQUESTSC = pipe(TOO_MANY_REQUESTSTaggedC, c => new t.Type<TOO_MANY_REQUESTS, TOO_MANY_REQUESTSTagged>(
  `TOO_MANY_REQUESTS`,
  (u: unknown): u is TOO_MANY_REQUESTS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TOO_MANY_REQUESTS> => pipe(c.decode(u), E.map(x => ({ ...x, ...TOO_MANY_REQUESTS }))),
  (x: TOO_MANY_REQUESTS): TOO_MANY_REQUESTSTagged => ({ ...x, _tag: `TOO_MANY_REQUESTS`}),
));
export type TOO_MANY_REQUESTSC = typeof TOO_MANY_REQUESTSC;


export const FOREIGN_KEY_CONSTRAINT = {
  _tag: `FOREIGN_KEY_CONSTRAINT`,
  error: `FOREIGN_KEY_CONSTRAINT`
} as const;

export const FOREIGN_KEY_CONSTRAINTTaggedC = t.type({
  _tag: t.literal(`FOREIGN_KEY_CONSTRAINT`)
});
export type FOREIGN_KEY_CONSTRAINTTaggedC = typeof FOREIGN_KEY_CONSTRAINTTaggedC;
export type FOREIGN_KEY_CONSTRAINTTagged = t.TypeOf<FOREIGN_KEY_CONSTRAINTTaggedC>;
export type FOREIGN_KEY_CONSTRAINT = FOREIGN_KEY_CONSTRAINTTagged & typeof FOREIGN_KEY_CONSTRAINT;
export const FOREIGN_KEY_CONSTRAINTC = pipe(FOREIGN_KEY_CONSTRAINTTaggedC, c => new t.Type<FOREIGN_KEY_CONSTRAINT, FOREIGN_KEY_CONSTRAINTTagged>(
  `FOREIGN_KEY_CONSTRAINT`,
  (u: unknown): u is FOREIGN_KEY_CONSTRAINT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FOREIGN_KEY_CONSTRAINT> => pipe(c.decode(u), E.map(x => ({ ...x, ...FOREIGN_KEY_CONSTRAINT }))),
  (x: FOREIGN_KEY_CONSTRAINT): FOREIGN_KEY_CONSTRAINTTagged => ({ ...x, _tag: `FOREIGN_KEY_CONSTRAINT`}),
));
export type FOREIGN_KEY_CONSTRAINTC = typeof FOREIGN_KEY_CONSTRAINTC;


export const UNIQUE_CONSTRAINT = {
  _tag: `UNIQUE_CONSTRAINT`,
  error: `UNIQUE_CONSTRAINT`
} as const;

export const UNIQUE_CONSTRAINTTaggedC = t.type({
  _tag: t.literal(`UNIQUE_CONSTRAINT`)
});
export type UNIQUE_CONSTRAINTTaggedC = typeof UNIQUE_CONSTRAINTTaggedC;
export type UNIQUE_CONSTRAINTTagged = t.TypeOf<UNIQUE_CONSTRAINTTaggedC>;
export type UNIQUE_CONSTRAINT = UNIQUE_CONSTRAINTTagged & typeof UNIQUE_CONSTRAINT;
export const UNIQUE_CONSTRAINTC = pipe(UNIQUE_CONSTRAINTTaggedC, c => new t.Type<UNIQUE_CONSTRAINT, UNIQUE_CONSTRAINTTagged>(
  `UNIQUE_CONSTRAINT`,
  (u: unknown): u is UNIQUE_CONSTRAINT => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UNIQUE_CONSTRAINT> => pipe(c.decode(u), E.map(x => ({ ...x, ...UNIQUE_CONSTRAINT }))),
  (x: UNIQUE_CONSTRAINT): UNIQUE_CONSTRAINTTagged => ({ ...x, _tag: `UNIQUE_CONSTRAINT`}),
));
export type UNIQUE_CONSTRAINTC = typeof UNIQUE_CONSTRAINTC;


export const INTERNAL_SERVER_ERROR = {
  _tag: `INTERNAL_SERVER_ERROR`,
  error: `INTERNAL_SERVER_ERROR`
} as const;

export const INTERNAL_SERVER_ERRORTaggedC = t.type({
  _tag: t.literal(`INTERNAL_SERVER_ERROR`)
});
export type INTERNAL_SERVER_ERRORTaggedC = typeof INTERNAL_SERVER_ERRORTaggedC;
export type INTERNAL_SERVER_ERRORTagged = t.TypeOf<INTERNAL_SERVER_ERRORTaggedC>;
export type INTERNAL_SERVER_ERROR = INTERNAL_SERVER_ERRORTagged & typeof INTERNAL_SERVER_ERROR;
export const INTERNAL_SERVER_ERRORC = pipe(INTERNAL_SERVER_ERRORTaggedC, c => new t.Type<INTERNAL_SERVER_ERROR, INTERNAL_SERVER_ERRORTagged>(
  `INTERNAL_SERVER_ERROR`,
  (u: unknown): u is INTERNAL_SERVER_ERROR => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, INTERNAL_SERVER_ERROR> => pipe(c.decode(u), E.map(x => ({ ...x, ...INTERNAL_SERVER_ERROR }))),
  (x: INTERNAL_SERVER_ERROR): INTERNAL_SERVER_ERRORTagged => ({ ...x, _tag: `INTERNAL_SERVER_ERROR`}),
));
export type INTERNAL_SERVER_ERRORC = typeof INTERNAL_SERVER_ERRORC;


export const SESSION_ERROR = {
  _tag: `SESSION_ERROR`,
  error: `SESSION_ERROR`
} as const;

export const SESSION_ERRORTaggedC = t.type({
  _tag: t.literal(`SESSION_ERROR`)
});
export type SESSION_ERRORTaggedC = typeof SESSION_ERRORTaggedC;
export type SESSION_ERRORTagged = t.TypeOf<SESSION_ERRORTaggedC>;
export type SESSION_ERROR = SESSION_ERRORTagged & typeof SESSION_ERROR;
export const SESSION_ERRORC = pipe(SESSION_ERRORTaggedC, c => new t.Type<SESSION_ERROR, SESSION_ERRORTagged>(
  `SESSION_ERROR`,
  (u: unknown): u is SESSION_ERROR => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SESSION_ERROR> => pipe(c.decode(u), E.map(x => ({ ...x, ...SESSION_ERROR }))),
  (x: SESSION_ERROR): SESSION_ERRORTagged => ({ ...x, _tag: `SESSION_ERROR`}),
));
export type SESSION_ERRORC = typeof SESSION_ERRORC;


export const CLEAR_SESSION = {
  _tag: `CLEAR_SESSION`,
  error: `CLEAR_SESSION`
} as const;

export const CLEAR_SESSIONTaggedC = t.type({
  _tag: t.literal(`CLEAR_SESSION`)
});
export type CLEAR_SESSIONTaggedC = typeof CLEAR_SESSIONTaggedC;
export type CLEAR_SESSIONTagged = t.TypeOf<CLEAR_SESSIONTaggedC>;
export type CLEAR_SESSION = CLEAR_SESSIONTagged & typeof CLEAR_SESSION;
export const CLEAR_SESSIONC = pipe(CLEAR_SESSIONTaggedC, c => new t.Type<CLEAR_SESSION, CLEAR_SESSIONTagged>(
  `CLEAR_SESSION`,
  (u: unknown): u is CLEAR_SESSION => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CLEAR_SESSION> => pipe(c.decode(u), E.map(x => ({ ...x, ...CLEAR_SESSION }))),
  (x: CLEAR_SESSION): CLEAR_SESSIONTagged => ({ ...x, _tag: `CLEAR_SESSION`}),
));
export type CLEAR_SESSIONC = typeof CLEAR_SESSIONC;


export const FIELD_ERRORS = {
  _tag: `FIELD_ERRORS`,
  error: `FIELD_ERRORS`
} as const;

export const FIELD_ERRORSTaggedC = t.type({
  _tag: t.literal(`FIELD_ERRORS`)
});
export type FIELD_ERRORSTaggedC = typeof FIELD_ERRORSTaggedC;
export type FIELD_ERRORSTagged = t.TypeOf<FIELD_ERRORSTaggedC>;
export type FIELD_ERRORS = FIELD_ERRORSTagged & typeof FIELD_ERRORS;
export const FIELD_ERRORSC = pipe(FIELD_ERRORSTaggedC, c => new t.Type<FIELD_ERRORS, FIELD_ERRORSTagged>(
  `FIELD_ERRORS`,
  (u: unknown): u is FIELD_ERRORS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FIELD_ERRORS> => pipe(c.decode(u), E.map(x => ({ ...x, ...FIELD_ERRORS }))),
  (x: FIELD_ERRORS): FIELD_ERRORSTagged => ({ ...x, _tag: `FIELD_ERRORS`}),
));
export type FIELD_ERRORSC = typeof FIELD_ERRORSC;


export const PASSWORD_EXPIRED = {
  _tag: `PASSWORD_EXPIRED`,
  error: `PASSWORD_EXPIRED`
} as const;

export const PASSWORD_EXPIREDTaggedC = t.type({
  _tag: t.literal(`PASSWORD_EXPIRED`)
});
export type PASSWORD_EXPIREDTaggedC = typeof PASSWORD_EXPIREDTaggedC;
export type PASSWORD_EXPIREDTagged = t.TypeOf<PASSWORD_EXPIREDTaggedC>;
export type PASSWORD_EXPIRED = PASSWORD_EXPIREDTagged & typeof PASSWORD_EXPIRED;
export const PASSWORD_EXPIREDC = pipe(PASSWORD_EXPIREDTaggedC, c => new t.Type<PASSWORD_EXPIRED, PASSWORD_EXPIREDTagged>(
  `PASSWORD_EXPIRED`,
  (u: unknown): u is PASSWORD_EXPIRED => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PASSWORD_EXPIRED> => pipe(c.decode(u), E.map(x => ({ ...x, ...PASSWORD_EXPIRED }))),
  (x: PASSWORD_EXPIRED): PASSWORD_EXPIREDTagged => ({ ...x, _tag: `PASSWORD_EXPIRED`}),
));
export type PASSWORD_EXPIREDC = typeof PASSWORD_EXPIREDC;


export const CREDENTIALS_INVALID = {
  _tag: `CREDENTIALS_INVALID`,
  error: `CREDENTIALS_INVALID`
} as const;

export const CREDENTIALS_INVALIDTaggedC = t.type({
  _tag: t.literal(`CREDENTIALS_INVALID`)
});
export type CREDENTIALS_INVALIDTaggedC = typeof CREDENTIALS_INVALIDTaggedC;
export type CREDENTIALS_INVALIDTagged = t.TypeOf<CREDENTIALS_INVALIDTaggedC>;
export type CREDENTIALS_INVALID = CREDENTIALS_INVALIDTagged & typeof CREDENTIALS_INVALID;
export const CREDENTIALS_INVALIDC = pipe(CREDENTIALS_INVALIDTaggedC, c => new t.Type<CREDENTIALS_INVALID, CREDENTIALS_INVALIDTagged>(
  `CREDENTIALS_INVALID`,
  (u: unknown): u is CREDENTIALS_INVALID => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CREDENTIALS_INVALID> => pipe(c.decode(u), E.map(x => ({ ...x, ...CREDENTIALS_INVALID }))),
  (x: CREDENTIALS_INVALID): CREDENTIALS_INVALIDTagged => ({ ...x, _tag: `CREDENTIALS_INVALID`}),
));
export type CREDENTIALS_INVALIDC = typeof CREDENTIALS_INVALIDC;


export const allResponseCodeC = [EMPTY_RESULTC, RESOURCE_NOT_FOUNDC, ID_NOT_FOUNDC, MISSING_REQUIRED_FIELDC, CUSTOMC, TOO_MANY_RESULTSC, TOO_MANY_REQUESTSC, FOREIGN_KEY_CONSTRAINTC, UNIQUE_CONSTRAINTC, INTERNAL_SERVER_ERRORC, SESSION_ERRORC, CLEAR_SESSIONC, FIELD_ERRORSC, PASSWORD_EXPIREDC, CREDENTIALS_INVALIDC] as const;
export const allResponseCodeNames = [`EMPTY_RESULT`, `RESOURCE_NOT_FOUND`, `ID_NOT_FOUND`, `MISSING_REQUIRED_FIELD`, `CUSTOM`, `TOO_MANY_RESULTS`, `TOO_MANY_REQUESTS`, `FOREIGN_KEY_CONSTRAINT`, `UNIQUE_CONSTRAINT`, `INTERNAL_SERVER_ERROR`, `SESSION_ERROR`, `CLEAR_SESSION`, `FIELD_ERRORS`, `PASSWORD_EXPIRED`, `CREDENTIALS_INVALID`] as const;
export type ResponseCodeName = (typeof allResponseCodeNames)[number];

export const ResponseCodeCU = t.union([EMPTY_RESULTC, RESOURCE_NOT_FOUNDC, ID_NOT_FOUNDC, MISSING_REQUIRED_FIELDC, CUSTOMC, TOO_MANY_RESULTSC, TOO_MANY_REQUESTSC, FOREIGN_KEY_CONSTRAINTC, UNIQUE_CONSTRAINTC, INTERNAL_SERVER_ERRORC, SESSION_ERRORC, CLEAR_SESSIONC, FIELD_ERRORSC, PASSWORD_EXPIREDC, CREDENTIALS_INVALIDC]);
export type ResponseCodeCU = typeof ResponseCodeCU;
export type ResponseCodeU = t.TypeOf<ResponseCodeCU>;

export const responseCodeOrd: Ord.Ord<ResponseCodeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allResponseCode = [EMPTY_RESULT, RESOURCE_NOT_FOUND, ID_NOT_FOUND, MISSING_REQUIRED_FIELD, CUSTOM, TOO_MANY_RESULTS, TOO_MANY_REQUESTS, FOREIGN_KEY_CONSTRAINT, UNIQUE_CONSTRAINT, INTERNAL_SERVER_ERROR, SESSION_ERROR, CLEAR_SESSION, FIELD_ERRORS, PASSWORD_EXPIRED, CREDENTIALS_INVALID] as const;
export type ResponseCodeMap<A> = { [K in ResponseCodeName]: A };


