import type { SVGString } from "*.svg";

import { delProp } from "@scripts/util/delProp";

import { klass, type KlassList, klassPropO } from "../util/classnames";

export const SvgWithText = (props: {
  prefixIcon?: SVGString;
  postfixIcon?: SVGString;
  text: string;
  textKlasses?: KlassList;
  containerKlasses?: KlassList;
}) =>
  <div {...klassPropO("d-flex")(props.containerKlasses)}>
    {props.prefixIcon && (
      <div>
        <Svg src={props.prefixIcon} />
      </div>
    )}
    <div
      {...klass(
        "text-left",
        props.prefixIcon ? "ml-025" : "",
        props.postfixIcon ? "mr-025" : "",
      )}
    >
      <span {...klassPropO([])(props.textKlasses)}>
        {props.text}
      </span>
    </div>
    {props.postfixIcon && (
      <div>
        <Svg src={props.postfixIcon} />
      </div>
    )}
  </div>;

export const Svg = (props: { src: React.FC<React.SVGProps<SVGSVGElement>> } & React.SVGProps<SVGSVGElement>) => {
  const propsWithoutClassname = delProp(props, "src", "className");
  // eslint-disable-next-line react/forbid-elements
  return <i className={props.className}><props.src {...propsWithoutClassname} /></i>;
};
