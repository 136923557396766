import * as t from "io-ts";

export const bondLinkClientId = {
  _tag: `BondLinkClientId`,
  id: 1
} as const;

export const bondLinkClientIdC = t.type({
  _tag: t.literal(`BondLinkClientId`),
  id: t.literal(1)
});
export type BondLinkClientIdC = typeof bondLinkClientIdC;
export type BondLinkClientId = t.TypeOf<BondLinkClientIdC>;


