// This file was generated by postcss-transpiler. Do not edit by hand.

export const alerts = {
  ".alert": {
    css: ".alert",
    html: "alert",
    attrs: {
      ".pill": {
        css: ".pill",
        html: "pill"
      },
      ".show": {
        css: ".show",
        html: "show"
      },
      ".alert-danger": {
        css: ".alert-danger",
        html: "alert-danger"
      },
      ".alert-info": {
        css: ".alert-info",
        html: "alert-info"
      },
      ".alert-success": {
        css: ".alert-success",
        html: "alert-success"
      }
    },
    ".icon-button": {
      css: ".icon-button",
      html: "icon-button",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".alert-icon": {
      css: ".alert-icon",
      html: "alert-icon",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".alert-body": {
      css: ".alert-body",
      html: "alert-body",
      ":last-child": {
        css: ":last-child",
        html: "last-child"
      }
    }
  },
  ".alert-subtle": {
    css: ".alert-subtle",
    html: "alert-subtle",
    ".title": {
      css: ".title",
      html: "title",
      ".icon": {
        css: ".icon",
        html: "icon"
      }
    },
    ".body": {
      css: ".body",
      html: "body"
    }
  },
  ".alert-pushdown": {
    css: ".alert-pushdown",
    html: "alert-pushdown"
  },
  ".alert-warning": {
    css: ".alert-warning",
    html: "alert-warning"
  },
  ".alert-no-border": {
    css: ".alert-no-border",
    html: "alert-no-border"
  }
};
