import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";

export const redirectC = t.type({
  _tag: t.literal(`Redirect`),
  redirect: t.string
});
export type RedirectC = typeof redirectC;
export type Redirect = t.TypeOf<RedirectC>;


export const mfaInitiatedC = t.type({
  _tag: t.literal(`MfaInitiated`),
  phoneLineNumber: t.string,
  redirect: optionFromNullable(t.string),
  userId: t.number,
  verificationId: t.string
});
export type MfaInitiatedC = typeof mfaInitiatedC;
export type MfaInitiated = t.TypeOf<MfaInitiatedC>;


export const allLoginStatusC = [redirectC, mfaInitiatedC] as const;
export const allLoginStatusNames = [`Redirect`, `MfaInitiated`] as const;
export type LoginStatusName = (typeof allLoginStatusNames)[number];

export const LoginStatusCU = t.union([redirectC, mfaInitiatedC]);
export type LoginStatusCU = typeof LoginStatusCU;
export type LoginStatusU = t.TypeOf<LoginStatusCU>;

export type LoginStatusMap<A> = { [K in LoginStatusName]: A };


