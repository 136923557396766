import { Fragment } from "react";
import loaderIcon from "@imgs/bondlink-loader.svg";

import { Svg } from "@scripts/react/components/Svg";

import { loader } from "@styles/components/_loader";
import { pageLoader as l } from "@styles/components/_page-loader";

import { klass } from "../util/classnames";
import { LoadingCircle } from "./LoadingCircle";


export const LoaderBLIcon = () => (<Svg src={loaderIcon} />);

export const LoaderBL = () => (
  <Fragment>
    <div {...klass(l[".spinner-overlay"], "fade", "show")} />
    <div {...klass(l[".icon-bondlink-loader-container"], "fade", "show")}>
      <LoaderBLIcon />
      <div {...klass(l[".icon-bondlink-loader-container"][".bondlink-loading-text"])}>Loading…</div>
    </div>
  </Fragment>
);


export const LoaderBars = () => (
  <div {...klass(loader[".loader-bars-container"])}>
    <div {...klass(loader[".loader-bars"])}>
      <div {...klass(loader[".loader-bars"][".loader-bar"])} />
    </div>
  </div>
);

export const LoaderBarsPill = () => (
  <div {...klass(loader[".loader-pill-container"])}>
    <div {...klass(loader[".loader-pill-container"][".loader-pill"])}>
      <LoaderBars />
      <div {...klass(loader[".loader-pill-container"][".loader-copy"])}>Loading...</div>
    </div>
  </div>
);

export const LoaderFindIssuers = () => <div {...klass("d-flex", "align-self-center")}>
  <LoadingCircle loadingMsg={"Loading Issuers..."} />
</div>;
