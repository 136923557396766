import * as t from "io-ts";

export const usersIssuerC = t.type({
  clientId: t.number,
  name: t.string,
  slug: t.string
});
export type UsersIssuerC = typeof usersIssuerC;
export type UsersIssuer = t.TypeOf<UsersIssuerC>;


