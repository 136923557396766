// eslint-disable simple-import-sort/imports
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { E, Eq } from "@scripts/fp-ts";
import { ActorIdCU, type ActorIdU, emailContactIdC, userIdC } from "@scripts/generated/models/actor";
import type { PostEmailRecipientU } from "@scripts/generated/models/email";
import { actorPostEmailRecipientC, customPostEmailRecipientC, PostEmailRecipientCU } from "@scripts/generated/models/email";
import { exhaustiveNoLog } from "@scripts/util/exhaustive";

export const actorIdKey = (config: BLConfigWithLog) => (aId: ActorIdU): string => {
  switch (aId._tag) {
    case "EmailContactId":
      return `ec=${aId.emailContactId}`;
    case "UserId":
      return `u=${aId.userId}`;
  }

  return config.exhaustive(aId);
};

type ActorIdKey = `${ActorIdU["_tag"]}=${string}`;

const actorIdTagsCU = t.union([ActorIdCU.types[0].props._tag, ActorIdCU.types[1].props._tag, ...ActorIdCU.types.slice(2).map(a => a.props._tag)]);

export const stringToActorIdC = new t.Type<ActorIdU, ActorIdKey>(
  "actorIdC",
  ActorIdCU.is,
  (u: unknown, context) => {
    return pipe(
      t.string.decode(u),
      E.chain((s) => t.tuple([actorIdTagsCU, NumberFromString]).decode(s.split("="))),
      E.chain(([tag, id]) => {
        return pipe(
          id,
          NumberFromString.decode,
          E.chain((n: number): t.Validation<ActorIdU> => {
            switch (tag) {
              case "EmailContactId":
                return t.success({
                  _tag: "EmailContactId",
                  emailContactId: n,
                });
              case "UserId":
                return t.success({
                  _tag: "UserId",
                  userId: n,
                });
            }

            return t.failure(tag, context, "ActorIdKey tag not in union");
          })
        );
      })
    );
  },
  (aId: ActorIdU): ActorIdKey => {
    switch (aId._tag) {
      case "EmailContactId":
        return `${aId._tag}=${aId.emailContactId}`;
      case "UserId":
        return `${aId._tag}=${aId.userId}`;
    }

    return exhaustiveNoLog(aId);
  }
);

export const actorIdUEq: Eq.Eq<ActorIdU> = Eq.fromEquals((a, b) => {
  if (userIdC.is(a) && userIdC.is(b)) return a.userId === b.userId;
  if (emailContactIdC.is(a) && emailContactIdC.is(b)) return a.emailContactId === b.emailContactId;
  return false;
});

export const stringToRecipientC = new t.Type<PostEmailRecipientU, string>(
  "actorIdC",
  PostEmailRecipientCU.is,
  (u: unknown) => {
    return pipe(
      t.string.decode(u),
      E.chain((s: string): t.Validation<PostEmailRecipientU> => pipe(
        stringToActorIdC.decode(s),
        E.fold(
          (): t.Validation<PostEmailRecipientU> => t.success({
            _tag: "CustomPostEmailRecipient",
            email: s,
          }),
          (id): t.Validation<PostEmailRecipientU> => t.success({
            _tag: "ActorPostEmailRecipient",
            id,
          })
        )
      ))
    );
  },
  (recipient: PostEmailRecipientU): string => {
    switch (recipient._tag) {
      case "ActorPostEmailRecipient":
        return stringToActorIdC.encode(recipient.id);
      case "CustomPostEmailRecipient":
        return recipient.email;
    }

    return exhaustiveNoLog(recipient);
  }
);

export const recipientEq: Eq.Eq<PostEmailRecipientU> = Eq.fromEquals((a, b) => {
  if (customPostEmailRecipientC.is(a) && customPostEmailRecipientC.is(b)) return a.email === b.email;
  if (actorPostEmailRecipientC.is(a) && actorPostEmailRecipientC.is(b)) return actorIdUEq.equals(a.id, b.id);
  return false;
});
