import { useState } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";

import { E, O } from "@scripts/fp-ts";
import type { ExpiredPassword, ResetForgottenPasswordPost, ResetForgottenPasswordPostC, ResetPasswordData } from "@scripts/generated/models/login";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { mapOrEmpty } from "@scripts/react/components/Empty";
import { Form } from "@scripts/react/components/form/Form";
import { NewPasswordWithConfirmInput } from "@scripts/react/components/form/PasswordInput";
import { stringC } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";

import { MinimalContainer, MinimalFormContainer, MinimalHeader } from "@scripts-ssr/components/MinimalLayout";

import { loginSignUpRedirect } from "../components/loginPage/loginPageSyntax";

const resetPasswordL = formLens<ResetForgottenPasswordPostC>();
const resetPasswordDL = formDataLens<ResetForgottenPasswordPostC>();

type ResetPasswordPageProps = ResetPasswordData & Pick<ResetForgottenPasswordPost, "verificationId">;

const makeResetPasswordInitState = (data: ResetPasswordPageProps) => pipe(
  emptyFormState<ResetForgottenPasswordPostC>(),
  resetPasswordDL.compose(resetPasswordL("email")).set(data.reset.email),
  resetPasswordDL.compose(resetPasswordL("hash")).set(data.reset.hash),
  resetPasswordDL.compose(resetPasswordL("verificationId")).set(data.verificationId),
);

export const ResetPasswordBody = (props: ResetPasswordPageProps) => {
  const [formState, setFormState] = useState(makeResetPasswordInitState(props));
  return (
    <>
      <MinimalHeader text="Reset your password" />
      {mapOrEmpty((expired: ExpiredPassword) =>
        <p>
          Your password has not been updated for at least {expired.expirationDuration}.
          Please choose a new password to access the BondLink platform.
        </p>
      )(props.expired)}
      <MinimalFormContainer>
        <Form
          url={V2Router.baseAuthControllerResetForgottenPassword()}
          state={formState}
          setState={setFormState}
          onSuccess={(_, r) => loginSignUpRedirect(r.data.redirect)}
          onFailure={O.none}
          headers={O.none}
        >
          <NewPasswordWithConfirmInput
            codec={stringC}
            confirmProps={O.some({
              labelOrAriaLabel: E.left("Confirm Password"),
              lens: resetPasswordL("confirmPassword"),
            })}
            labelOrAriaLabel={E.left("Password")}
            lens={resetPasswordL("password")}
            placeholder={O.none}
            state={formState}
            setState={setFormState}
          />
          <ButtonSubmit
            {...klass("w-100")}
            loading={formState.loading}
            loadingText={"Updating Password"}
            onClick={constVoid}
            text={"Update Password"}
          />
        </Form>
      </MinimalFormContainer>
    </>
  );
};

export const ResetPasswordPage = (props: ResetPasswordPageProps) =>
  <MinimalContainer>
    <ResetPasswordBody {...props} />
  </MinimalContainer>;
