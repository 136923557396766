import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import * as t from "io-ts";
import { CalloutLocationCU as imported2_CalloutLocationCU, CalloutLocationCU as imported3_CalloutLocationCU } from "../domaintables/calloutLocations";

export const quickFactC = t.type({
  fieldHeader: t.string,
  fieldValue: t.string,
  fieldUrl: optionFromNullable(t.string),
  linkText: optionFromNullable(t.string),
  fieldOrder: t.number,
  location: imported2_CalloutLocationCU
});
export type QuickFactC = typeof quickFactC;
export type QuickFact = t.TypeOf<QuickFactC>;


export const quickFactPostBodyC = t.type({
  id: optionFromNullable(t.number),
  fieldHeader: t.string,
  fieldValue: t.string,
  fieldUrl: optionFromNullable(t.string),
  linkText: optionFromNullable(t.string),
  fieldOrder: t.number,
  location: imported3_CalloutLocationCU
});
export type QuickFactPostBodyC = typeof quickFactPostBodyC;
export type QuickFactPostBody = t.TypeOf<QuickFactPostBodyC>;


