import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const nav = {
  _tag: `Nav`,
  id: 1,
  name: `Navigation Menu`
} as const;

export const navTaggedC = t.type({
  _tag: t.literal(`Nav`)
});
export type NavTaggedC = typeof navTaggedC;
export type NavTagged = t.TypeOf<NavTaggedC>;
export type Nav = NavTagged & typeof nav;
export const navC = pipe(navTaggedC, c => new t.Type<Nav, NavTagged>(
  `Nav`,
  (u: unknown): u is Nav => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Nav> => pipe(c.decode(u), E.map(x => ({ ...x, ...nav }))),
  (x: Nav): NavTagged => ({ ...x, _tag: `Nav`}),
));
export type NavC = typeof navC;


export const footer = {
  _tag: `Footer`,
  id: 2,
  name: `Footer`
} as const;

export const footerTaggedC = t.type({
  _tag: t.literal(`Footer`)
});
export type FooterTaggedC = typeof footerTaggedC;
export type FooterTagged = t.TypeOf<FooterTaggedC>;
export type Footer = FooterTagged & typeof footer;
export const footerC = pipe(footerTaggedC, c => new t.Type<Footer, FooterTagged>(
  `Footer`,
  (u: unknown): u is Footer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Footer> => pipe(c.decode(u), E.map(x => ({ ...x, ...footer }))),
  (x: Footer): FooterTagged => ({ ...x, _tag: `Footer`}),
));
export type FooterC = typeof footerC;


export const allHrefLocationC = [navC, footerC] as const;
export const allHrefLocationNames = [`Nav`, `Footer`] as const;
export type HrefLocationName = (typeof allHrefLocationNames)[number];

export const HrefLocationCU = t.union([navC, footerC]);
export type HrefLocationCU = typeof HrefLocationCU;
export type HrefLocationU = t.TypeOf<HrefLocationCU>;

export const hrefLocationOrd: Ord.Ord<HrefLocationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allHrefLocation = [nav, footer] as const;
export type HrefLocationMap<A> = { [K in HrefLocationName]: A };


