
import { flow, pipe } from "fp-ts/function";
import * as t from "io-ts";

import { A, E } from "@scripts/fp-ts";

export type ArrayFromEntriesC<V extends t.Mixed> = t.Type<Array<t.TypeOf<V>>, Array<readonly [number, t.OutputOf<V>]>, unknown>;

export const arrayFromEntries = <V extends t.Mixed>(
  valueCodec: V,
): ArrayFromEntriesC<V> => {
  const arr = t.array(t.tuple([t.number, valueCodec]));
  return new t.Type(
    `${valueCodec.name}[]`,
    t.array(valueCodec).is,
    (u, c) =>
      pipe(
        arr.validate(u, c),
        E.chain(flow(
          A.reduce([], (acc: Array<t.TypeOf<V>>, [idx, val]) => { acc[idx] = val; return acc; }),
          t.success
        )),
      ),
    flow(A.mapWithIndex((i, val) => [i, val] as const), A.filter(Boolean)),
  );
};
