import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const password = {
  _tag: `password`,
  field: `password`
} as const;

export const passwordTaggedC = t.type({
  _tag: t.literal(`password`)
});
export type PasswordTaggedC = typeof passwordTaggedC;
export type PasswordTagged = t.TypeOf<PasswordTaggedC>;
export type Password = PasswordTagged & typeof password;
export const passwordC = pipe(passwordTaggedC, c => new t.Type<Password, PasswordTagged>(
  `password`,
  (u: unknown): u is Password => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Password> => pipe(c.decode(u), E.map(x => ({ ...x, ...password }))),
  (x: Password): PasswordTagged => ({ ...x, _tag: `password`}),
));
export type PasswordC = typeof passwordC;


export const passwordConfirm = {
  _tag: `passwordConfirm`,
  field: `passwordConfirm`
} as const;

export const passwordConfirmTaggedC = t.type({
  _tag: t.literal(`passwordConfirm`)
});
export type PasswordConfirmTaggedC = typeof passwordConfirmTaggedC;
export type PasswordConfirmTagged = t.TypeOf<PasswordConfirmTaggedC>;
export type PasswordConfirm = PasswordConfirmTagged & typeof passwordConfirm;
export const passwordConfirmC = pipe(passwordConfirmTaggedC, c => new t.Type<PasswordConfirm, PasswordConfirmTagged>(
  `passwordConfirm`,
  (u: unknown): u is PasswordConfirm => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PasswordConfirm> => pipe(c.decode(u), E.map(x => ({ ...x, ...passwordConfirm }))),
  (x: PasswordConfirm): PasswordConfirmTagged => ({ ...x, _tag: `passwordConfirm`}),
));
export type PasswordConfirmC = typeof passwordConfirmC;


export const newPassword = {
  _tag: `newPassword`,
  field: `newPassword`
} as const;

export const newPasswordTaggedC = t.type({
  _tag: t.literal(`newPassword`)
});
export type NewPasswordTaggedC = typeof newPasswordTaggedC;
export type NewPasswordTagged = t.TypeOf<NewPasswordTaggedC>;
export type NewPassword = NewPasswordTagged & typeof newPassword;
export const newPasswordC = pipe(newPasswordTaggedC, c => new t.Type<NewPassword, NewPasswordTagged>(
  `newPassword`,
  (u: unknown): u is NewPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...newPassword }))),
  (x: NewPassword): NewPasswordTagged => ({ ...x, _tag: `newPassword`}),
));
export type NewPasswordC = typeof newPasswordC;


export const newPasswordConfirm = {
  _tag: `newPasswordConfirm`,
  field: `newPasswordConfirm`
} as const;

export const newPasswordConfirmTaggedC = t.type({
  _tag: t.literal(`newPasswordConfirm`)
});
export type NewPasswordConfirmTaggedC = typeof newPasswordConfirmTaggedC;
export type NewPasswordConfirmTagged = t.TypeOf<NewPasswordConfirmTaggedC>;
export type NewPasswordConfirm = NewPasswordConfirmTagged & typeof newPasswordConfirm;
export const newPasswordConfirmC = pipe(newPasswordConfirmTaggedC, c => new t.Type<NewPasswordConfirm, NewPasswordConfirmTagged>(
  `newPasswordConfirm`,
  (u: unknown): u is NewPasswordConfirm => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NewPasswordConfirm> => pipe(c.decode(u), E.map(x => ({ ...x, ...newPasswordConfirm }))),
  (x: NewPasswordConfirm): NewPasswordConfirmTagged => ({ ...x, _tag: `newPasswordConfirm`}),
));
export type NewPasswordConfirmC = typeof newPasswordConfirmC;


export const salt = {
  _tag: `salt`,
  field: `salt`
} as const;

export const saltTaggedC = t.type({
  _tag: t.literal(`salt`)
});
export type SaltTaggedC = typeof saltTaggedC;
export type SaltTagged = t.TypeOf<SaltTaggedC>;
export type Salt = SaltTagged & typeof salt;
export const saltC = pipe(saltTaggedC, c => new t.Type<Salt, SaltTagged>(
  `salt`,
  (u: unknown): u is Salt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Salt> => pipe(c.decode(u), E.map(x => ({ ...x, ...salt }))),
  (x: Salt): SaltTagged => ({ ...x, _tag: `salt`}),
));
export type SaltC = typeof saltC;


export const publishPassword = {
  _tag: `publishPassword`,
  field: `publishPassword`
} as const;

export const publishPasswordTaggedC = t.type({
  _tag: t.literal(`publishPassword`)
});
export type PublishPasswordTaggedC = typeof publishPasswordTaggedC;
export type PublishPasswordTagged = t.TypeOf<PublishPasswordTaggedC>;
export type PublishPassword = PublishPasswordTagged & typeof publishPassword;
export const publishPasswordC = pipe(publishPasswordTaggedC, c => new t.Type<PublishPassword, PublishPasswordTagged>(
  `publishPassword`,
  (u: unknown): u is PublishPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublishPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...publishPassword }))),
  (x: PublishPassword): PublishPasswordTagged => ({ ...x, _tag: `publishPassword`}),
));
export type PublishPasswordC = typeof publishPasswordC;


export const publishSalt = {
  _tag: `publishSalt`,
  field: `publishSalt`
} as const;

export const publishSaltTaggedC = t.type({
  _tag: t.literal(`publishSalt`)
});
export type PublishSaltTaggedC = typeof publishSaltTaggedC;
export type PublishSaltTagged = t.TypeOf<PublishSaltTaggedC>;
export type PublishSalt = PublishSaltTagged & typeof publishSalt;
export const publishSaltC = pipe(publishSaltTaggedC, c => new t.Type<PublishSalt, PublishSaltTagged>(
  `publishSalt`,
  (u: unknown): u is PublishSalt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PublishSalt> => pipe(c.decode(u), E.map(x => ({ ...x, ...publishSalt }))),
  (x: PublishSalt): PublishSaltTagged => ({ ...x, _tag: `publishSalt`}),
));
export type PublishSaltC = typeof publishSaltC;


export const confirmPassword = {
  _tag: `confirmPassword`,
  field: `confirmPassword`
} as const;

export const confirmPasswordTaggedC = t.type({
  _tag: t.literal(`confirmPassword`)
});
export type ConfirmPasswordTaggedC = typeof confirmPasswordTaggedC;
export type ConfirmPasswordTagged = t.TypeOf<ConfirmPasswordTaggedC>;
export type ConfirmPassword = ConfirmPasswordTagged & typeof confirmPassword;
export const confirmPasswordC = pipe(confirmPasswordTaggedC, c => new t.Type<ConfirmPassword, ConfirmPasswordTagged>(
  `confirmPassword`,
  (u: unknown): u is ConfirmPassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ConfirmPassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...confirmPassword }))),
  (x: ConfirmPassword): ConfirmPasswordTagged => ({ ...x, _tag: `confirmPassword`}),
));
export type ConfirmPasswordC = typeof confirmPasswordC;


export const paymentToken = {
  _tag: `paymentToken`,
  field: `paymentToken`
} as const;

export const paymentTokenTaggedC = t.type({
  _tag: t.literal(`paymentToken`)
});
export type PaymentTokenTaggedC = typeof paymentTokenTaggedC;
export type PaymentTokenTagged = t.TypeOf<PaymentTokenTaggedC>;
export type PaymentToken = PaymentTokenTagged & typeof paymentToken;
export const paymentTokenC = pipe(paymentTokenTaggedC, c => new t.Type<PaymentToken, PaymentTokenTagged>(
  `paymentToken`,
  (u: unknown): u is PaymentToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PaymentToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...paymentToken }))),
  (x: PaymentToken): PaymentTokenTagged => ({ ...x, _tag: `paymentToken`}),
));
export type PaymentTokenC = typeof paymentTokenC;


export const emails_body = {
  _tag: `Emails_body`,
  field: `Emails.body`
} as const;

export const emails_bodyTaggedC = t.type({
  _tag: t.literal(`Emails_body`)
});
export type Emails_bodyTaggedC = typeof emails_bodyTaggedC;
export type Emails_bodyTagged = t.TypeOf<Emails_bodyTaggedC>;
export type Emails_body = Emails_bodyTagged & typeof emails_body;
export const emails_bodyC = pipe(emails_bodyTaggedC, c => new t.Type<Emails_body, Emails_bodyTagged>(
  `Emails_body`,
  (u: unknown): u is Emails_body => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Emails_body> => pipe(c.decode(u), E.map(x => ({ ...x, ...emails_body }))),
  (x: Emails_body): Emails_bodyTagged => ({ ...x, _tag: `Emails_body`}),
));
export type Emails_bodyC = typeof emails_bodyC;


export const sitePassword = {
  _tag: `sitePassword`,
  field: `sitePassword`
} as const;

export const sitePasswordTaggedC = t.type({
  _tag: t.literal(`sitePassword`)
});
export type SitePasswordTaggedC = typeof sitePasswordTaggedC;
export type SitePasswordTagged = t.TypeOf<SitePasswordTaggedC>;
export type SitePassword = SitePasswordTagged & typeof sitePassword;
export const sitePasswordC = pipe(sitePasswordTaggedC, c => new t.Type<SitePassword, SitePasswordTagged>(
  `sitePassword`,
  (u: unknown): u is SitePassword => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SitePassword> => pipe(c.decode(u), E.map(x => ({ ...x, ...sitePassword }))),
  (x: SitePassword): SitePasswordTagged => ({ ...x, _tag: `sitePassword`}),
));
export type SitePasswordC = typeof sitePasswordC;


export const authorization = {
  _tag: `Authorization`,
  field: `Authorization`
} as const;

export const authorizationTaggedC = t.type({
  _tag: t.literal(`Authorization`)
});
export type AuthorizationTaggedC = typeof authorizationTaggedC;
export type AuthorizationTagged = t.TypeOf<AuthorizationTaggedC>;
export type Authorization = AuthorizationTagged & typeof authorization;
export const authorizationC = pipe(authorizationTaggedC, c => new t.Type<Authorization, AuthorizationTagged>(
  `Authorization`,
  (u: unknown): u is Authorization => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Authorization> => pipe(c.decode(u), E.map(x => ({ ...x, ...authorization }))),
  (x: Authorization): AuthorizationTagged => ({ ...x, _tag: `Authorization`}),
));
export type AuthorizationC = typeof authorizationC;


export const apiToken = {
  _tag: `ApiToken`,
  field: `Api-Token`
} as const;

export const apiTokenTaggedC = t.type({
  _tag: t.literal(`ApiToken`)
});
export type ApiTokenTaggedC = typeof apiTokenTaggedC;
export type ApiTokenTagged = t.TypeOf<ApiTokenTaggedC>;
export type ApiToken = ApiTokenTagged & typeof apiToken;
export const apiTokenC = pipe(apiTokenTaggedC, c => new t.Type<ApiToken, ApiTokenTagged>(
  `ApiToken`,
  (u: unknown): u is ApiToken => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ApiToken> => pipe(c.decode(u), E.map(x => ({ ...x, ...apiToken }))),
  (x: ApiToken): ApiTokenTagged => ({ ...x, _tag: `ApiToken`}),
));
export type ApiTokenC = typeof apiTokenC;


export const client_secret = {
  _tag: `client_secret`,
  field: `client_secret`
} as const;

export const client_secretTaggedC = t.type({
  _tag: t.literal(`client_secret`)
});
export type Client_secretTaggedC = typeof client_secretTaggedC;
export type Client_secretTagged = t.TypeOf<Client_secretTaggedC>;
export type Client_secret = Client_secretTagged & typeof client_secret;
export const client_secretC = pipe(client_secretTaggedC, c => new t.Type<Client_secret, Client_secretTagged>(
  `client_secret`,
  (u: unknown): u is Client_secret => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Client_secret> => pipe(c.decode(u), E.map(x => ({ ...x, ...client_secret }))),
  (x: Client_secret): Client_secretTagged => ({ ...x, _tag: `client_secret`}),
));
export type Client_secretC = typeof client_secretC;


export const access_token = {
  _tag: `access_token`,
  field: `access_token`
} as const;

export const access_tokenTaggedC = t.type({
  _tag: t.literal(`access_token`)
});
export type Access_tokenTaggedC = typeof access_tokenTaggedC;
export type Access_tokenTagged = t.TypeOf<Access_tokenTaggedC>;
export type Access_token = Access_tokenTagged & typeof access_token;
export const access_tokenC = pipe(access_tokenTaggedC, c => new t.Type<Access_token, Access_tokenTagged>(
  `access_token`,
  (u: unknown): u is Access_token => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Access_token> => pipe(c.decode(u), E.map(x => ({ ...x, ...access_token }))),
  (x: Access_token): Access_tokenTagged => ({ ...x, _tag: `access_token`}),
));
export type Access_tokenC = typeof access_tokenC;


export const hapikey = {
  _tag: `hapikey`,
  field: `hapikey`
} as const;

export const hapikeyTaggedC = t.type({
  _tag: t.literal(`hapikey`)
});
export type HapikeyTaggedC = typeof hapikeyTaggedC;
export type HapikeyTagged = t.TypeOf<HapikeyTaggedC>;
export type Hapikey = HapikeyTagged & typeof hapikey;
export const hapikeyC = pipe(hapikeyTaggedC, c => new t.Type<Hapikey, HapikeyTagged>(
  `hapikey`,
  (u: unknown): u is Hapikey => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Hapikey> => pipe(c.decode(u), E.map(x => ({ ...x, ...hapikey }))),
  (x: Hapikey): HapikeyTagged => ({ ...x, _tag: `hapikey`}),
));
export type HapikeyC = typeof hapikeyC;


export const allSensitiveFieldC = [passwordC, passwordConfirmC, newPasswordC, newPasswordConfirmC, saltC, publishPasswordC, publishSaltC, confirmPasswordC, paymentTokenC, emails_bodyC, sitePasswordC, authorizationC, apiTokenC, client_secretC, access_tokenC, hapikeyC] as const;
export const allSensitiveFieldNames = [`password`, `passwordConfirm`, `newPassword`, `newPasswordConfirm`, `salt`, `publishPassword`, `publishSalt`, `confirmPassword`, `paymentToken`, `Emails_body`, `sitePassword`, `Authorization`, `ApiToken`, `client_secret`, `access_token`, `hapikey`] as const;
export type SensitiveFieldName = (typeof allSensitiveFieldNames)[number];

export const SensitiveFieldCU = t.union([passwordC, passwordConfirmC, newPasswordC, newPasswordConfirmC, saltC, publishPasswordC, publishSaltC, confirmPasswordC, paymentTokenC, emails_bodyC, sitePasswordC, authorizationC, apiTokenC, client_secretC, access_tokenC, hapikeyC]);
export type SensitiveFieldCU = typeof SensitiveFieldCU;
export type SensitiveFieldU = t.TypeOf<SensitiveFieldCU>;

export const sensitiveFieldOrd: Ord.Ord<SensitiveFieldU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSensitiveField = [password, passwordConfirm, newPassword, newPasswordConfirm, salt, publishPassword, publishSalt, confirmPassword, paymentToken, emails_body, sitePassword, authorization, apiToken, client_secret, access_token, hapikey] as const;
export type SensitiveFieldMap<A> = { [K in SensitiveFieldName]: A };


