import { WithStatusCU as imported391_WithStatusCU } from "./threadThrough";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { markdownC } from "../../codecs/markdown";
import { ClientTextTypeCU as imported1_ClientTextTypeCU } from "../domaintables/clientTextTypes";
import * as t from "io-ts";

export const clientTextItemC = t.type({
  textType: imported1_ClientTextTypeCU,
  data: markdownC
});
export type ClientTextItemC = typeof clientTextItemC;
export type ClientTextItem = t.TypeOf<ClientTextItemC>;


export const clientTextItemOC = optionFromNullable(imported391_WithStatusCU(clientTextItemC));
export type ClientTextItemOC = typeof clientTextItemOC;
export type ClientTextItemO = t.TypeOf<ClientTextItemOC>;


