import type { ErrorInfo, PropsWithChildren, ReactNode } from "react";
import { Component } from "react";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { fold, none, some } from "fp-ts/lib/Option";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { mapOrEmpty } from "@scripts/react/components/Empty";

import { ErrorContent } from "./ErrorContent";
import { ServerError } from "./ServerError";

type ErrorBoundaryState = {
  error: Option<Error>;
  errorInfo: Option<ErrorInfo>;
};

type ErrorBoundaryProps = PropsWithChildren<{
  config: BLConfigWithLog;
}>;

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: none, errorInfo: none };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: some(error),
      errorInfo: some(errorInfo),
    });
    this.props.config.log.fatal(`ChromeErrorBoundary: ${error.message}`, error, errorInfo);
  }

  render(): ReactNode {
    return pipe(
      this.state.errorInfo,
      fold(
        () => this.props.children,
        (errInfo: ErrorInfo) => (
          <ServerError>
            <ErrorContent resetErrorState={() => this.setState({ error: none, errorInfo: none })}>
              <details style={{ whiteSpace: "pre-wrap" }}>
                {pipe(
                  this.state.error,
                  mapOrEmpty(
                    (e: Error) => <strong>{e.message}</strong>
                  )
                )}
                <br />
                {errInfo.componentStack}
              </details>
            </ErrorContent>
          </ServerError>
        )
      )
    );
  }
}
