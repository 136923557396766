import type { Monoid } from "fp-ts/lib/Monoid";
import { concatAll as _concatAll } from "fp-ts/lib/Monoid";

import type { ReadonlyBig } from "./bigNumberC";
import { one, zero } from "./bigNumberC";

export const monoidSum: Monoid<ReadonlyBig> = {
  empty: zero,
  concat: (x, y) => x.add(y),
};

export const monoidProduct: Monoid<ReadonlyBig> = {
  empty: one,
  concat: (x, y) => x.mul(y),
};

export const concatAll = _concatAll(monoidSum);
