import * as O from "fp-ts/lib/Option";

import type { WithHTMLAttrs } from "@scripts/react/util/dom";

import { klass } from "../util/classnames";

export const UnsafeContent = (props: WithHTMLAttrs<{ content: string }>) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: props.content }} {...klass(O.fromNullable(props.className))} />
);
