import * as E from "fp-ts/lib/Either";
import { identity, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import * as TE from "fp-ts/lib/TaskEither";

import type { LogErrors } from "@scripts/util/log";
import { parseJson } from "@scripts/util/parseJson";

export const responseErrorHandler = (or: O.Option<Response>) => (
  onNetworkError: () => void,
  onResponseTextError: () => void,
  onParsingJsonError: (e: LogErrors, s: string) => void,
  handleErrorResponse: (a: unknown) => void
) => pipe(
  or,
  O.fold(
    onNetworkError,
    (r: Response) => pipe(
      TE.tryCatch(() => r.text(), identity),
      TE.bimap(
        onResponseTextError,
        (s: string) => pipe(parseJson(s), E.bimap((e: LogErrors) => onParsingJsonError(e, s), handleErrorResponse))
      )
    )()
  )
);
