import * as t from "io-ts";

export const pageLinkC = t.type({
  _tag: t.literal(`PageLink`),
  displayName: t.string,
  machineName: t.string,
  url: t.string,
  description: t.string,
  enabled: t.boolean
});
export type PageLinkC = typeof pageLinkC;
export type PageLink = t.TypeOf<PageLinkC>;


export const externalLinkC = t.type({
  _tag: t.literal(`ExternalLink`),
  displayName: t.string,
  url: t.string
});
export type ExternalLinkC = typeof externalLinkC;
export type ExternalLink = t.TypeOf<ExternalLinkC>;


export const downloadLinkC = t.type({
  _tag: t.literal(`DownloadLink`),
  displayName: t.string,
  downloadUrl: t.string,
  mediaId: t.number
});
export type DownloadLinkC = typeof downloadLinkC;
export type DownloadLink = t.TypeOf<DownloadLinkC>;


export const allLinkableC = [pageLinkC, externalLinkC, downloadLinkC] as const;
export const allLinkableNames = [`PageLink`, `ExternalLink`, `DownloadLink`] as const;
export type LinkableName = (typeof allLinkableNames)[number];

export const LinkableCU = t.union([pageLinkC, externalLinkC, downloadLinkC]);
export type LinkableCU = typeof LinkableCU;
export type LinkableU = t.TypeOf<LinkableCU>;

export type LinkableMap<A> = { [K in LinkableName]: A };


