import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { getOrElse, isNone, isSome, map } from "fp-ts/lib/Option";
import * as RA from "fp-ts/lib/ReadonlyArray";

import { O } from "@scripts/fp-ts";
import { exhaustiveNoLog } from "@scripts/util/exhaustive";
import { prop } from "@scripts/util/prop";

export type CSSType = Readonly<{
  css: string;
  html: string;
}>;

export type KlassBase = string | CSSType;
export type Klass = KlassBase | Option<KlassBase>;
export type KlassList = Klass[] | ReadonlyArray<Klass>;
export type KlassProp = Klass | KlassList;

export const emptyKlasses: Klass[] = [];

export const isKlassesList = (kp: KlassProp): kp is KlassList => Array.isArray(kp);

export const klassNullableToList = (kp?: KlassProp): KlassList => (emptyKlasses).concat(kp == null ? [] : kp);

export const klassNullableProp = (kp?: KlassProp): { className: string } => {
  return klass(...klassNullableToList(kp));
};

export const klassProp = (defaultKp: KlassProp) => (kp: KlassProp): { className: string } => {
  return klass(...(emptyKlasses).concat(defaultKp, kp));
};

export const klassPropO = (defaultKp: KlassProp) => (kp?: KlassProp): { className: string } => {
  return klass(...(emptyKlasses).concat(defaultKp, klassNullableToList(kp)));
};

export const klassConditional = (conditionalKp: KlassBase, defaultKp: KlassProp) => (condition: boolean): { className: string } => {
  return klass(...(emptyKlasses).concat(defaultKp, condition ? conditionalKp : []));
};

const klassReducer = (conditionalKp: KlassList) => (i: number, acc: Klass[], curr: boolean): Klass[] => {
  const conditionalKlass = conditionalKp[i];
  if (conditionalKlass && curr) {
    return acc.concat(conditionalKlass);
  }
  return acc;
};

export const klassConditionalArr = <T extends string[]>(conditionalKp: T, defaultKp: KlassProp) => (conditions: { [k in keyof T]: boolean }): { className: string } => {
  const conditionsArr = Object.values(conditions);
  return klass(...(pipe(conditionsArr, RA.reduceWithIndex((emptyKlasses).concat(defaultKp), klassReducer(conditionalKp)))));
};

export function klass(...cs: Klass[]): { className: string } {
  return {
    className: classNames(...cs),
  };
}

export function classNames(...cs: Klass[]): string {
  return cs.map((c) => {
    if (typeof c === "string") {
      return c;
    }
    if (isCssType(c)) {
      return c.html;
    }
    if (isSome(c) || isNone(c)) {
      return pipe(
        c,
        map((x: CSSType | string) => isCssType(x) ? prop("html")(x) : x),
        getOrElse(() => "")
      );
    }
    return exhaustiveNoLog(c);
  }).filter(cn => cn !== "").join(" ");
}

export const mergeKlassPropO = (newKp: KlassProp) => (klasses?: KlassProp) => pipe(klasses, O.fromNullable, O.fold(
  () => newKp,
  (existing: KlassProp) => emptyKlasses.concat(existing, newKp)
));

function isMaybeCSSType(o: unknown): o is Partial<CSSType> {
  return typeof o === "object";
}

function isCssType(c: unknown): c is CSSType {
  return isMaybeCSSType(c) && typeof c.html === "string" && typeof c.css === "string";
}
