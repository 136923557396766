import * as t from "io-ts";

export interface HtmlTag { readonly HtmlTag: unique symbol }
const htmlCUnderlying = t.string;

export const htmlC = new t.Type<HtmlTag, string, unknown>(
  "HtmlTypeC",
  (u: unknown): u is HtmlTag => htmlCUnderlying.is(u),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (i: unknown, c: t.Context) => htmlCUnderlying.validate(i, c) as t.Validation<HtmlTag>,
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (m): string => m as unknown as t.TypeOf<typeof htmlCUnderlying>,
);

export type HtmlC = typeof htmlC;
export type Html = t.TypeOf<HtmlC>;
