import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import { ActivityReportDataPointCU as imported1_ActivityReportDataPointCU } from "../domaintables/activityReportDataPoints";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const activityReportTemplateC = t.type({
  name: t.string,
  description: optionFromNullable(t.string),
  dataPoints: readonlyNonEmptyArrayC(imported1_ActivityReportDataPointCU)
});
export type ActivityReportTemplateC = typeof activityReportTemplateC;
export type ActivityReportTemplate = t.TypeOf<ActivityReportTemplateC>;


export const activityReportTemplatePostC = t.type({
  id: optionFromNullable(t.number),
  name: t.string,
  description: optionFromNullable(t.string),
  dataPoints: readonlyNonEmptyArrayC(t.number)
});
export type ActivityReportTemplatePostC = typeof activityReportTemplatePostC;
export type ActivityReportTemplatePost = t.TypeOf<ActivityReportTemplatePostC>;


