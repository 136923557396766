import { constVoid, pipe } from "fp-ts/lib/function";
import type { Dispatch } from "redux";

import { apiFetch } from "@scripts/api/methods";
import type { BLConfigWithLog } from "@scripts/bondlink";
import * as V2Router from "@scripts/generated/routers/v2Router";
import type { NotificationAction } from "@scripts/react/state/notifications";
import { notificationAdd } from "@scripts/react/state/notifications";

import { ReloadAnchor } from "../components/Anchor";

const throttleTimeMs = 60 * 1000;
let refreshTimeMs = 0;

export const inactiveTimeMs = 60 * 60 * 1000;

export const testCommitHash = (config: BLConfigWithLog) => (dispatch: Dispatch<NotificationAction>) => () => {
  const commitHash = config.commit;
  pipe(
    apiFetch(config)(V2Router.baseBuildInfo())(
      constVoid,
      (data) => {
      if (commitHash.length && data.data.commit !== commitHash) {
        dispatch(notificationAdd({
          id: "test-commit-hash",
          title: "Reload to update version",
          type: "warning",
          children: <p>We’ve made some updates to the BondLink platform that may affect this page. Please <ReloadAnchor title="reload the page" /> to ensure you’re up-to-date.</p>,
        }));
      }
    })
  )();
};

export const throttleTestCommitHash = (config: BLConfigWithLog) => (dispatch: Dispatch<NotificationAction>) => () => {
  if (refreshTimeMs + throttleTimeMs < Date.now()) {
    testCommitHash(config)(dispatch)();
    refreshTimeMs = Date.now();
  }
};

export const createListeners = (config: BLConfigWithLog) => (dispatch: Dispatch<NotificationAction>) => {
  window.addEventListener("click", throttleTestCommitHash(config)(dispatch));
  window.addEventListener("keypress", throttleTestCommitHash(config)(dispatch));
  window.addEventListener("scroll", throttleTestCommitHash(config)(dispatch));
  const commitHashInterval = window.setInterval(testCommitHash(config)(dispatch), inactiveTimeMs);
  return commitHashInterval;
};

export const cleanupListeners = (config: BLConfigWithLog) => (dispatch: Dispatch<NotificationAction>, commitHashInterval: number) => {
  window.removeEventListener("click", throttleTestCommitHash(config)(dispatch));
  window.removeEventListener("keypress", throttleTestCommitHash(config)(dispatch));
  window.removeEventListener("scroll", throttleTestCommitHash(config)(dispatch));
  window.clearInterval(commitHashInterval);
};
