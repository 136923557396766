import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const generalQuestions = {
  _tag: `GeneralQuestions`,
  text: `General Questions`
} as const;

export const generalQuestionsTaggedC = t.type({
  _tag: t.literal(`GeneralQuestions`)
});
export type GeneralQuestionsTaggedC = typeof generalQuestionsTaggedC;
export type GeneralQuestionsTagged = t.TypeOf<GeneralQuestionsTaggedC>;
export type GeneralQuestions = GeneralQuestionsTagged & typeof generalQuestions;
export const generalQuestionsC = pipe(generalQuestionsTaggedC, c => new t.Type<GeneralQuestions, GeneralQuestionsTagged>(
  `GeneralQuestions`,
  (u: unknown): u is GeneralQuestions => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, GeneralQuestions> => pipe(c.decode(u), E.map(x => ({ ...x, ...generalQuestions }))),
  (x: GeneralQuestions): GeneralQuestionsTagged => ({ ...x, _tag: `GeneralQuestions`}),
));
export type GeneralQuestionsC = typeof generalQuestionsC;


export const pressInquiries = {
  _tag: `PressInquiries`,
  text: `Press Inquiries`
} as const;

export const pressInquiriesTaggedC = t.type({
  _tag: t.literal(`PressInquiries`)
});
export type PressInquiriesTaggedC = typeof pressInquiriesTaggedC;
export type PressInquiriesTagged = t.TypeOf<PressInquiriesTaggedC>;
export type PressInquiries = PressInquiriesTagged & typeof pressInquiries;
export const pressInquiriesC = pipe(pressInquiriesTaggedC, c => new t.Type<PressInquiries, PressInquiriesTagged>(
  `PressInquiries`,
  (u: unknown): u is PressInquiries => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PressInquiries> => pipe(c.decode(u), E.map(x => ({ ...x, ...pressInquiries }))),
  (x: PressInquiries): PressInquiriesTagged => ({ ...x, _tag: `PressInquiries`}),
));
export type PressInquiriesC = typeof pressInquiriesC;


export const careers = {
  _tag: `Careers`,
  text: `Careers`
} as const;

export const careersTaggedC = t.type({
  _tag: t.literal(`Careers`)
});
export type CareersTaggedC = typeof careersTaggedC;
export type CareersTagged = t.TypeOf<CareersTaggedC>;
export type Careers = CareersTagged & typeof careers;
export const careersC = pipe(careersTaggedC, c => new t.Type<Careers, CareersTagged>(
  `Careers`,
  (u: unknown): u is Careers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Careers> => pipe(c.decode(u), E.map(x => ({ ...x, ...careers }))),
  (x: Careers): CareersTagged => ({ ...x, _tag: `Careers`}),
));
export type CareersC = typeof careersC;


export const productQuestions = {
  _tag: `ProductQuestions`,
  text: `Product Questions`
} as const;

export const productQuestionsTaggedC = t.type({
  _tag: t.literal(`ProductQuestions`)
});
export type ProductQuestionsTaggedC = typeof productQuestionsTaggedC;
export type ProductQuestionsTagged = t.TypeOf<ProductQuestionsTaggedC>;
export type ProductQuestions = ProductQuestionsTagged & typeof productQuestions;
export const productQuestionsC = pipe(productQuestionsTaggedC, c => new t.Type<ProductQuestions, ProductQuestionsTagged>(
  `ProductQuestions`,
  (u: unknown): u is ProductQuestions => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProductQuestions> => pipe(c.decode(u), E.map(x => ({ ...x, ...productQuestions }))),
  (x: ProductQuestions): ProductQuestionsTagged => ({ ...x, _tag: `ProductQuestions`}),
));
export type ProductQuestionsC = typeof productQuestionsC;


export const sales = {
  _tag: `Sales`,
  text: `Sales`
} as const;

export const salesTaggedC = t.type({
  _tag: t.literal(`Sales`)
});
export type SalesTaggedC = typeof salesTaggedC;
export type SalesTagged = t.TypeOf<SalesTaggedC>;
export type Sales = SalesTagged & typeof sales;
export const salesC = pipe(salesTaggedC, c => new t.Type<Sales, SalesTagged>(
  `Sales`,
  (u: unknown): u is Sales => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Sales> => pipe(c.decode(u), E.map(x => ({ ...x, ...sales }))),
  (x: Sales): SalesTagged => ({ ...x, _tag: `Sales`}),
));
export type SalesC = typeof salesC;


export const helpAndSupport = {
  _tag: `HelpAndSupport`,
  text: `Help & Support`
} as const;

export const helpAndSupportTaggedC = t.type({
  _tag: t.literal(`HelpAndSupport`)
});
export type HelpAndSupportTaggedC = typeof helpAndSupportTaggedC;
export type HelpAndSupportTagged = t.TypeOf<HelpAndSupportTaggedC>;
export type HelpAndSupport = HelpAndSupportTagged & typeof helpAndSupport;
export const helpAndSupportC = pipe(helpAndSupportTaggedC, c => new t.Type<HelpAndSupport, HelpAndSupportTagged>(
  `HelpAndSupport`,
  (u: unknown): u is HelpAndSupport => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HelpAndSupport> => pipe(c.decode(u), E.map(x => ({ ...x, ...helpAndSupport }))),
  (x: HelpAndSupport): HelpAndSupportTagged => ({ ...x, _tag: `HelpAndSupport`}),
));
export type HelpAndSupportC = typeof helpAndSupportC;


export const allCorpContactSubjectC = [generalQuestionsC, pressInquiriesC, careersC, productQuestionsC, salesC, helpAndSupportC] as const;
export const allCorpContactSubjectNames = [`GeneralQuestions`, `PressInquiries`, `Careers`, `ProductQuestions`, `Sales`, `HelpAndSupport`] as const;
export type CorpContactSubjectName = (typeof allCorpContactSubjectNames)[number];

export const CorpContactSubjectCU = t.union([generalQuestionsC, pressInquiriesC, careersC, productQuestionsC, salesC, helpAndSupportC]);
export type CorpContactSubjectCU = typeof CorpContactSubjectCU;
export type CorpContactSubjectU = t.TypeOf<CorpContactSubjectCU>;

export const corpContactSubjectOrd: Ord.Ord<CorpContactSubjectU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allCorpContactSubject = [generalQuestions, pressInquiries, careers, productQuestions, sales, helpAndSupport] as const;
export type CorpContactSubjectMap<A> = { [K in CorpContactSubjectName]: A };


export const corpContactPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  subject: CorpContactSubjectCU,
  body: t.string,
  captcha: t.string
});
export type CorpContactPostC = typeof corpContactPostC;
export type CorpContactPost = t.TypeOf<CorpContactPostC>;


export const corpDemoPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  organization: t.string,
  title: optionFromNullable(t.string),
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  captcha: t.string
});
export type CorpDemoPostC = typeof corpDemoPostC;
export type CorpDemoPost = t.TypeOf<CorpDemoPostC>;


export const corpEmailSignupPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string
});
export type CorpEmailSignupPostC = typeof corpEmailSignupPostC;
export type CorpEmailSignupPost = t.TypeOf<CorpEmailSignupPostC>;


