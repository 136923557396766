export * from "fp-ts/lib/ReadonlyNonEmptyArray";

import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as Refine from "fp-ts/lib/Refinement";

import type { IntRangeExcl } from "./Array";
import { firstN as arrayFirstN } from "./Array";
import { refinementFor } from "./lib/_internal";
import { fromNonEmptyRecord as fromNEA } from "./NonEmptyArray";
import type { NonEmptyRecord } from "./Record";

export type Any = RNEA.ReadonlyNonEmptyArray<unknown>;

type ExtraReadonlyNonEmptyArrayFields<A> = Omit<RNEA.ReadonlyNonEmptyArray<A>, number | "length">;

export type ReadonlyNonEmptyArrayMaxN<A, N extends number> = { 0: A } & { [i in IntRangeExcl<1, N>]?: A } & {
  length: IntRangeExcl<1, N> | N;
} & ExtraReadonlyNonEmptyArrayFields<A>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mapMaxN = RNEA.mapWithIndex as unknown as <A, B, N extends number>(fn: (idx: IntRangeExcl<0, N>, item: A) => B) => (arr: ReadonlyNonEmptyArrayMaxN<A, N>) => ReadonlyNonEmptyArrayMaxN<B, N>;

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const firstN = arrayFirstN as unknown as <N extends number>(n: N) => <A>(arr: RNEA.ReadonlyNonEmptyArray<A>) => ReadonlyNonEmptyArrayMaxN<A, N>;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const firstNRaw = arrayFirstN as unknown as (n: number) => <A>(arr: RNEA.ReadonlyNonEmptyArray<A>) => RNEA.ReadonlyNonEmptyArray<A>;

/** @category refinements */
export const is = pipe(
  refinementFor.readonlyArray,
  Refine.compose(
    RA.isNonEmpty
  )
);

export const fromNativeReadonlyNonEmptyArray = <A>(a: readonly [A, ...A[]]): RNEA.ReadonlyNonEmptyArray<A> => {
  const [head, ...tail] = a;
  return RA.prepend(head)(tail);
};

export const fromNonEmptyRecord = <A, K extends keyof A = keyof A>(r: NonEmptyRecord<A, K>): RNEA.ReadonlyNonEmptyArray<A[K]> => fromNEA(r);
