import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const custodialReceipt = {
  _tag: `CustodialReceipt`,
  id: 1,
  name: `Custodial receipt`
} as const;

export const custodialReceiptTaggedC = t.type({
  _tag: t.literal(`CustodialReceipt`)
});
export type CustodialReceiptTaggedC = typeof custodialReceiptTaggedC;
export type CustodialReceiptTagged = t.TypeOf<CustodialReceiptTaggedC>;
export type CustodialReceipt = CustodialReceiptTagged & typeof custodialReceipt;
export const custodialReceiptC = pipe(custodialReceiptTaggedC, c => new t.Type<CustodialReceipt, CustodialReceiptTagged>(
  `CustodialReceipt`,
  (u: unknown): u is CustodialReceipt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CustodialReceipt> => pipe(c.decode(u), E.map(x => ({ ...x, ...custodialReceipt }))),
  (x: CustodialReceipt): CustodialReceiptTagged => ({ ...x, _tag: `CustodialReceipt`}),
));
export type CustodialReceiptC = typeof custodialReceiptC;


export const periodicAuctionSlashInverseFloater = {
  _tag: `PeriodicAuctionSlashInverseFloater`,
  id: 2,
  name: `Periodic auction/inverse floater`
} as const;

export const periodicAuctionSlashInverseFloaterTaggedC = t.type({
  _tag: t.literal(`PeriodicAuctionSlashInverseFloater`)
});
export type PeriodicAuctionSlashInverseFloaterTaggedC = typeof periodicAuctionSlashInverseFloaterTaggedC;
export type PeriodicAuctionSlashInverseFloaterTagged = t.TypeOf<PeriodicAuctionSlashInverseFloaterTaggedC>;
export type PeriodicAuctionSlashInverseFloater = PeriodicAuctionSlashInverseFloaterTagged & typeof periodicAuctionSlashInverseFloater;
export const periodicAuctionSlashInverseFloaterC = pipe(periodicAuctionSlashInverseFloaterTaggedC, c => new t.Type<PeriodicAuctionSlashInverseFloater, PeriodicAuctionSlashInverseFloaterTagged>(
  `PeriodicAuctionSlashInverseFloater`,
  (u: unknown): u is PeriodicAuctionSlashInverseFloater => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PeriodicAuctionSlashInverseFloater> => pipe(c.decode(u), E.map(x => ({ ...x, ...periodicAuctionSlashInverseFloater }))),
  (x: PeriodicAuctionSlashInverseFloater): PeriodicAuctionSlashInverseFloaterTagged => ({ ...x, _tag: `PeriodicAuctionSlashInverseFloater`}),
));
export type PeriodicAuctionSlashInverseFloaterC = typeof periodicAuctionSlashInverseFloaterC;


export const premMunicipalBondReceipt = {
  _tag: `PremMunicipalBondReceipt`,
  id: 3,
  name: `Prem municipal bond receipt`
} as const;

export const premMunicipalBondReceiptTaggedC = t.type({
  _tag: t.literal(`PremMunicipalBondReceipt`)
});
export type PremMunicipalBondReceiptTaggedC = typeof premMunicipalBondReceiptTaggedC;
export type PremMunicipalBondReceiptTagged = t.TypeOf<PremMunicipalBondReceiptTaggedC>;
export type PremMunicipalBondReceipt = PremMunicipalBondReceiptTagged & typeof premMunicipalBondReceipt;
export const premMunicipalBondReceiptC = pipe(premMunicipalBondReceiptTaggedC, c => new t.Type<PremMunicipalBondReceipt, PremMunicipalBondReceiptTagged>(
  `PremMunicipalBondReceipt`,
  (u: unknown): u is PremMunicipalBondReceipt => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PremMunicipalBondReceipt> => pipe(c.decode(u), E.map(x => ({ ...x, ...premMunicipalBondReceipt }))),
  (x: PremMunicipalBondReceipt): PremMunicipalBondReceiptTagged => ({ ...x, _tag: `PremMunicipalBondReceipt`}),
));
export type PremMunicipalBondReceiptC = typeof premMunicipalBondReceiptC;


export const preRefunded = {
  _tag: `PreRefunded`,
  id: 4,
  name: `Pre-refunded`
} as const;

export const preRefundedTaggedC = t.type({
  _tag: t.literal(`PreRefunded`)
});
export type PreRefundedTaggedC = typeof preRefundedTaggedC;
export type PreRefundedTagged = t.TypeOf<PreRefundedTaggedC>;
export type PreRefunded = PreRefundedTagged & typeof preRefunded;
export const preRefundedC = pipe(preRefundedTaggedC, c => new t.Type<PreRefunded, PreRefundedTagged>(
  `PreRefunded`,
  (u: unknown): u is PreRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefunded }))),
  (x: PreRefunded): PreRefundedTagged => ({ ...x, _tag: `PreRefunded`}),
));
export type PreRefundedC = typeof preRefundedC;


export const thirdPartyPutSlashTenderOptionBond = {
  _tag: `ThirdPartyPutSlashTenderOptionBond`,
  id: 5,
  name: `Third party put/tender option bond`
} as const;

export const thirdPartyPutSlashTenderOptionBondTaggedC = t.type({
  _tag: t.literal(`ThirdPartyPutSlashTenderOptionBond`)
});
export type ThirdPartyPutSlashTenderOptionBondTaggedC = typeof thirdPartyPutSlashTenderOptionBondTaggedC;
export type ThirdPartyPutSlashTenderOptionBondTagged = t.TypeOf<ThirdPartyPutSlashTenderOptionBondTaggedC>;
export type ThirdPartyPutSlashTenderOptionBond = ThirdPartyPutSlashTenderOptionBondTagged & typeof thirdPartyPutSlashTenderOptionBond;
export const thirdPartyPutSlashTenderOptionBondC = pipe(thirdPartyPutSlashTenderOptionBondTaggedC, c => new t.Type<ThirdPartyPutSlashTenderOptionBond, ThirdPartyPutSlashTenderOptionBondTagged>(
  `ThirdPartyPutSlashTenderOptionBond`,
  (u: unknown): u is ThirdPartyPutSlashTenderOptionBond => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ThirdPartyPutSlashTenderOptionBond> => pipe(c.decode(u), E.map(x => ({ ...x, ...thirdPartyPutSlashTenderOptionBond }))),
  (x: ThirdPartyPutSlashTenderOptionBond): ThirdPartyPutSlashTenderOptionBondTagged => ({ ...x, _tag: `ThirdPartyPutSlashTenderOptionBond`}),
));
export type ThirdPartyPutSlashTenderOptionBondC = typeof thirdPartyPutSlashTenderOptionBondC;


export const remarketing = {
  _tag: `Remarketing`,
  id: 6,
  name: `Remarketing`
} as const;

export const remarketingTaggedC = t.type({
  _tag: t.literal(`Remarketing`)
});
export type RemarketingTaggedC = typeof remarketingTaggedC;
export type RemarketingTagged = t.TypeOf<RemarketingTaggedC>;
export type Remarketing = RemarketingTagged & typeof remarketing;
export const remarketingC = pipe(remarketingTaggedC, c => new t.Type<Remarketing, RemarketingTagged>(
  `Remarketing`,
  (u: unknown): u is Remarketing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Remarketing> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketing }))),
  (x: Remarketing): RemarketingTagged => ({ ...x, _tag: `Remarketing`}),
));
export type RemarketingC = typeof remarketingC;


export const secondarilyInsured = {
  _tag: `SecondarilyInsured`,
  id: 7,
  name: `Secondarily insured`
} as const;

export const secondarilyInsuredTaggedC = t.type({
  _tag: t.literal(`SecondarilyInsured`)
});
export type SecondarilyInsuredTaggedC = typeof secondarilyInsuredTaggedC;
export type SecondarilyInsuredTagged = t.TypeOf<SecondarilyInsuredTaggedC>;
export type SecondarilyInsured = SecondarilyInsuredTagged & typeof secondarilyInsured;
export const secondarilyInsuredC = pipe(secondarilyInsuredTaggedC, c => new t.Type<SecondarilyInsured, SecondarilyInsuredTagged>(
  `SecondarilyInsured`,
  (u: unknown): u is SecondarilyInsured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SecondarilyInsured> => pipe(c.decode(u), E.map(x => ({ ...x, ...secondarilyInsured }))),
  (x: SecondarilyInsured): SecondarilyInsuredTagged => ({ ...x, _tag: `SecondarilyInsured`}),
));
export type SecondarilyInsuredC = typeof secondarilyInsuredC;


export const unitPutAndSecondarilyInsured = {
  _tag: `UnitPutAndSecondarilyInsured`,
  id: 8,
  name: `Unit put & secondarily insured`
} as const;

export const unitPutAndSecondarilyInsuredTaggedC = t.type({
  _tag: t.literal(`UnitPutAndSecondarilyInsured`)
});
export type UnitPutAndSecondarilyInsuredTaggedC = typeof unitPutAndSecondarilyInsuredTaggedC;
export type UnitPutAndSecondarilyInsuredTagged = t.TypeOf<UnitPutAndSecondarilyInsuredTaggedC>;
export type UnitPutAndSecondarilyInsured = UnitPutAndSecondarilyInsuredTagged & typeof unitPutAndSecondarilyInsured;
export const unitPutAndSecondarilyInsuredC = pipe(unitPutAndSecondarilyInsuredTaggedC, c => new t.Type<UnitPutAndSecondarilyInsured, UnitPutAndSecondarilyInsuredTagged>(
  `UnitPutAndSecondarilyInsured`,
  (u: unknown): u is UnitPutAndSecondarilyInsured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnitPutAndSecondarilyInsured> => pipe(c.decode(u), E.map(x => ({ ...x, ...unitPutAndSecondarilyInsured }))),
  (x: UnitPutAndSecondarilyInsured): UnitPutAndSecondarilyInsuredTagged => ({ ...x, _tag: `UnitPutAndSecondarilyInsured`}),
));
export type UnitPutAndSecondarilyInsuredC = typeof unitPutAndSecondarilyInsuredC;


export const thirdPartyPutUnit = {
  _tag: `ThirdPartyPutUnit`,
  id: 9,
  name: `Third party put unit`
} as const;

export const thirdPartyPutUnitTaggedC = t.type({
  _tag: t.literal(`ThirdPartyPutUnit`)
});
export type ThirdPartyPutUnitTaggedC = typeof thirdPartyPutUnitTaggedC;
export type ThirdPartyPutUnitTagged = t.TypeOf<ThirdPartyPutUnitTaggedC>;
export type ThirdPartyPutUnit = ThirdPartyPutUnitTagged & typeof thirdPartyPutUnit;
export const thirdPartyPutUnitC = pipe(thirdPartyPutUnitTaggedC, c => new t.Type<ThirdPartyPutUnit, ThirdPartyPutUnitTagged>(
  `ThirdPartyPutUnit`,
  (u: unknown): u is ThirdPartyPutUnit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ThirdPartyPutUnit> => pipe(c.decode(u), E.map(x => ({ ...x, ...thirdPartyPutUnit }))),
  (x: ThirdPartyPutUnit): ThirdPartyPutUnitTagged => ({ ...x, _tag: `ThirdPartyPutUnit`}),
));
export type ThirdPartyPutUnitC = typeof thirdPartyPutUnitC;


export const unrefundedBalance = {
  _tag: `UnrefundedBalance`,
  id: 10,
  name: `Unrefunded balance`
} as const;

export const unrefundedBalanceTaggedC = t.type({
  _tag: t.literal(`UnrefundedBalance`)
});
export type UnrefundedBalanceTaggedC = typeof unrefundedBalanceTaggedC;
export type UnrefundedBalanceTagged = t.TypeOf<UnrefundedBalanceTaggedC>;
export type UnrefundedBalance = UnrefundedBalanceTagged & typeof unrefundedBalance;
export const unrefundedBalanceC = pipe(unrefundedBalanceTaggedC, c => new t.Type<UnrefundedBalance, UnrefundedBalanceTagged>(
  `UnrefundedBalance`,
  (u: unknown): u is UnrefundedBalance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UnrefundedBalance> => pipe(c.decode(u), E.map(x => ({ ...x, ...unrefundedBalance }))),
  (x: UnrefundedBalance): UnrefundedBalanceTagged => ({ ...x, _tag: `UnrefundedBalance`}),
));
export type UnrefundedBalanceC = typeof unrefundedBalanceC;


export const allMuniEnhancementTypeC = [custodialReceiptC, periodicAuctionSlashInverseFloaterC, premMunicipalBondReceiptC, preRefundedC, thirdPartyPutSlashTenderOptionBondC, remarketingC, secondarilyInsuredC, unitPutAndSecondarilyInsuredC, thirdPartyPutUnitC, unrefundedBalanceC] as const;
export const allMuniEnhancementTypeNames = [`CustodialReceipt`, `PeriodicAuctionSlashInverseFloater`, `PremMunicipalBondReceipt`, `PreRefunded`, `ThirdPartyPutSlashTenderOptionBond`, `Remarketing`, `SecondarilyInsured`, `UnitPutAndSecondarilyInsured`, `ThirdPartyPutUnit`, `UnrefundedBalance`] as const;
export type MuniEnhancementTypeName = (typeof allMuniEnhancementTypeNames)[number];

export const MuniEnhancementTypeCU = t.union([custodialReceiptC, periodicAuctionSlashInverseFloaterC, premMunicipalBondReceiptC, preRefundedC, thirdPartyPutSlashTenderOptionBondC, remarketingC, secondarilyInsuredC, unitPutAndSecondarilyInsuredC, thirdPartyPutUnitC, unrefundedBalanceC]);
export type MuniEnhancementTypeCU = typeof MuniEnhancementTypeCU;
export type MuniEnhancementTypeU = t.TypeOf<MuniEnhancementTypeCU>;

export const muniEnhancementTypeOrd: Ord.Ord<MuniEnhancementTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniEnhancementType = [custodialReceipt, periodicAuctionSlashInverseFloater, premMunicipalBondReceipt, preRefunded, thirdPartyPutSlashTenderOptionBond, remarketing, secondarilyInsured, unitPutAndSecondarilyInsured, thirdPartyPutUnit, unrefundedBalance] as const;
export type MuniEnhancementTypeMap<A> = { [K in MuniEnhancementTypeName]: A };


