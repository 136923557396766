import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const eTMByMuniForward = {
  _tag: `ETMByMuniForward`,
  id: 1,
  name: `ETM by muni-forward`
} as const;

export const eTMByMuniForwardTaggedC = t.type({
  _tag: t.literal(`ETMByMuniForward`)
});
export type ETMByMuniForwardTaggedC = typeof eTMByMuniForwardTaggedC;
export type ETMByMuniForwardTagged = t.TypeOf<ETMByMuniForwardTaggedC>;
export type ETMByMuniForward = ETMByMuniForwardTagged & typeof eTMByMuniForward;
export const eTMByMuniForwardC = pipe(eTMByMuniForwardTaggedC, c => new t.Type<ETMByMuniForward, ETMByMuniForwardTagged>(
  `ETMByMuniForward`,
  (u: unknown): u is ETMByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ETMByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...eTMByMuniForward }))),
  (x: ETMByMuniForward): ETMByMuniForwardTagged => ({ ...x, _tag: `ETMByMuniForward`}),
));
export type ETMByMuniForwardC = typeof eTMByMuniForwardC;


export const preRefundedByMuniForward = {
  _tag: `PreRefundedByMuniForward`,
  id: 2,
  name: `Pre-refunded by muni-forward`
} as const;

export const preRefundedByMuniForwardTaggedC = t.type({
  _tag: t.literal(`PreRefundedByMuniForward`)
});
export type PreRefundedByMuniForwardTaggedC = typeof preRefundedByMuniForwardTaggedC;
export type PreRefundedByMuniForwardTagged = t.TypeOf<PreRefundedByMuniForwardTaggedC>;
export type PreRefundedByMuniForward = PreRefundedByMuniForwardTagged & typeof preRefundedByMuniForward;
export const preRefundedByMuniForwardC = pipe(preRefundedByMuniForwardTaggedC, c => new t.Type<PreRefundedByMuniForward, PreRefundedByMuniForwardTagged>(
  `PreRefundedByMuniForward`,
  (u: unknown): u is PreRefundedByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefundedByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefundedByMuniForward }))),
  (x: PreRefundedByMuniForward): PreRefundedByMuniForwardTagged => ({ ...x, _tag: `PreRefundedByMuniForward`}),
));
export type PreRefundedByMuniForwardC = typeof preRefundedByMuniForwardC;


export const called = {
  _tag: `Called`,
  id: 3,
  name: `Called`
} as const;

export const calledTaggedC = t.type({
  _tag: t.literal(`Called`)
});
export type CalledTaggedC = typeof calledTaggedC;
export type CalledTagged = t.TypeOf<CalledTaggedC>;
export type Called = CalledTagged & typeof called;
export const calledC = pipe(calledTaggedC, c => new t.Type<Called, CalledTagged>(
  `Called`,
  (u: unknown): u is Called => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Called> => pipe(c.decode(u), E.map(x => ({ ...x, ...called }))),
  (x: Called): CalledTagged => ({ ...x, _tag: `Called`}),
));
export type CalledC = typeof calledC;


export const calledDueToDefault = {
  _tag: `CalledDueToDefault`,
  id: 4,
  name: `Called due to default`
} as const;

export const calledDueToDefaultTaggedC = t.type({
  _tag: t.literal(`CalledDueToDefault`)
});
export type CalledDueToDefaultTaggedC = typeof calledDueToDefaultTaggedC;
export type CalledDueToDefaultTagged = t.TypeOf<CalledDueToDefaultTaggedC>;
export type CalledDueToDefault = CalledDueToDefaultTagged & typeof calledDueToDefault;
export const calledDueToDefaultC = pipe(calledDueToDefaultTaggedC, c => new t.Type<CalledDueToDefault, CalledDueToDefaultTagged>(
  `CalledDueToDefault`,
  (u: unknown): u is CalledDueToDefault => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalledDueToDefault> => pipe(c.decode(u), E.map(x => ({ ...x, ...calledDueToDefault }))),
  (x: CalledDueToDefault): CalledDueToDefaultTagged => ({ ...x, _tag: `CalledDueToDefault`}),
));
export type CalledDueToDefaultC = typeof calledDueToDefaultC;


export const escrowedToMaturity = {
  _tag: `EscrowedToMaturity`,
  id: 5,
  name: `Escrowed to maturity`
} as const;

export const escrowedToMaturityTaggedC = t.type({
  _tag: t.literal(`EscrowedToMaturity`)
});
export type EscrowedToMaturityTaggedC = typeof escrowedToMaturityTaggedC;
export type EscrowedToMaturityTagged = t.TypeOf<EscrowedToMaturityTaggedC>;
export type EscrowedToMaturity = EscrowedToMaturityTagged & typeof escrowedToMaturity;
export const escrowedToMaturityC = pipe(escrowedToMaturityTaggedC, c => new t.Type<EscrowedToMaturity, EscrowedToMaturityTagged>(
  `EscrowedToMaturity`,
  (u: unknown): u is EscrowedToMaturity => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedToMaturity> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedToMaturity }))),
  (x: EscrowedToMaturity): EscrowedToMaturityTagged => ({ ...x, _tag: `EscrowedToMaturity`}),
));
export type EscrowedToMaturityC = typeof escrowedToMaturityC;


export const calledByMuniForward = {
  _tag: `CalledByMuniForward`,
  id: 6,
  name: `Called by muni-forward`
} as const;

export const calledByMuniForwardTaggedC = t.type({
  _tag: t.literal(`CalledByMuniForward`)
});
export type CalledByMuniForwardTaggedC = typeof calledByMuniForwardTaggedC;
export type CalledByMuniForwardTagged = t.TypeOf<CalledByMuniForwardTaggedC>;
export type CalledByMuniForward = CalledByMuniForwardTagged & typeof calledByMuniForward;
export const calledByMuniForwardC = pipe(calledByMuniForwardTaggedC, c => new t.Type<CalledByMuniForward, CalledByMuniForwardTagged>(
  `CalledByMuniForward`,
  (u: unknown): u is CalledByMuniForward => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CalledByMuniForward> => pipe(c.decode(u), E.map(x => ({ ...x, ...calledByMuniForward }))),
  (x: CalledByMuniForward): CalledByMuniForwardTagged => ({ ...x, _tag: `CalledByMuniForward`}),
));
export type CalledByMuniForwardC = typeof calledByMuniForwardC;


export const repayment = {
  _tag: `Repayment`,
  id: 7,
  name: `Repayment`
} as const;

export const repaymentTaggedC = t.type({
  _tag: t.literal(`Repayment`)
});
export type RepaymentTaggedC = typeof repaymentTaggedC;
export type RepaymentTagged = t.TypeOf<RepaymentTaggedC>;
export type Repayment = RepaymentTagged & typeof repayment;
export const repaymentC = pipe(repaymentTaggedC, c => new t.Type<Repayment, RepaymentTagged>(
  `Repayment`,
  (u: unknown): u is Repayment => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Repayment> => pipe(c.decode(u), E.map(x => ({ ...x, ...repayment }))),
  (x: Repayment): RepaymentTagged => ({ ...x, _tag: `Repayment`}),
));
export type RepaymentC = typeof repaymentC;


export const escrowedAsToInterestOnly = {
  _tag: `EscrowedAsToInterestOnly`,
  id: 8,
  name: `Escrowed as to interest only`
} as const;

export const escrowedAsToInterestOnlyTaggedC = t.type({
  _tag: t.literal(`EscrowedAsToInterestOnly`)
});
export type EscrowedAsToInterestOnlyTaggedC = typeof escrowedAsToInterestOnlyTaggedC;
export type EscrowedAsToInterestOnlyTagged = t.TypeOf<EscrowedAsToInterestOnlyTaggedC>;
export type EscrowedAsToInterestOnly = EscrowedAsToInterestOnlyTagged & typeof escrowedAsToInterestOnly;
export const escrowedAsToInterestOnlyC = pipe(escrowedAsToInterestOnlyTaggedC, c => new t.Type<EscrowedAsToInterestOnly, EscrowedAsToInterestOnlyTagged>(
  `EscrowedAsToInterestOnly`,
  (u: unknown): u is EscrowedAsToInterestOnly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedAsToInterestOnly> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedAsToInterestOnly }))),
  (x: EscrowedAsToInterestOnly): EscrowedAsToInterestOnlyTagged => ({ ...x, _tag: `EscrowedAsToInterestOnly`}),
));
export type EscrowedAsToInterestOnlyC = typeof escrowedAsToInterestOnlyC;


export const escrowedAsToPrincipalOnly = {
  _tag: `EscrowedAsToPrincipalOnly`,
  id: 9,
  name: `Escrowed as to principal only`
} as const;

export const escrowedAsToPrincipalOnlyTaggedC = t.type({
  _tag: t.literal(`EscrowedAsToPrincipalOnly`)
});
export type EscrowedAsToPrincipalOnlyTaggedC = typeof escrowedAsToPrincipalOnlyTaggedC;
export type EscrowedAsToPrincipalOnlyTagged = t.TypeOf<EscrowedAsToPrincipalOnlyTaggedC>;
export type EscrowedAsToPrincipalOnly = EscrowedAsToPrincipalOnlyTagged & typeof escrowedAsToPrincipalOnly;
export const escrowedAsToPrincipalOnlyC = pipe(escrowedAsToPrincipalOnlyTaggedC, c => new t.Type<EscrowedAsToPrincipalOnly, EscrowedAsToPrincipalOnlyTagged>(
  `EscrowedAsToPrincipalOnly`,
  (u: unknown): u is EscrowedAsToPrincipalOnly => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedAsToPrincipalOnly> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedAsToPrincipalOnly }))),
  (x: EscrowedAsToPrincipalOnly): EscrowedAsToPrincipalOnlyTagged => ({ ...x, _tag: `EscrowedAsToPrincipalOnly`}),
));
export type EscrowedAsToPrincipalOnlyC = typeof escrowedAsToPrincipalOnlyC;


export const preRefundedPendingSale = {
  _tag: `PreRefundedPendingSale`,
  id: 10,
  name: `Pre-refunded pending sale`
} as const;

export const preRefundedPendingSaleTaggedC = t.type({
  _tag: t.literal(`PreRefundedPendingSale`)
});
export type PreRefundedPendingSaleTaggedC = typeof preRefundedPendingSaleTaggedC;
export type PreRefundedPendingSaleTagged = t.TypeOf<PreRefundedPendingSaleTaggedC>;
export type PreRefundedPendingSale = PreRefundedPendingSaleTagged & typeof preRefundedPendingSale;
export const preRefundedPendingSaleC = pipe(preRefundedPendingSaleTaggedC, c => new t.Type<PreRefundedPendingSale, PreRefundedPendingSaleTagged>(
  `PreRefundedPendingSale`,
  (u: unknown): u is PreRefundedPendingSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefundedPendingSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefundedPendingSale }))),
  (x: PreRefundedPendingSale): PreRefundedPendingSaleTagged => ({ ...x, _tag: `PreRefundedPendingSale`}),
));
export type PreRefundedPendingSaleC = typeof preRefundedPendingSaleC;


export const eTMPendingSale = {
  _tag: `ETMPendingSale`,
  id: 11,
  name: `ETM pending sale`
} as const;

export const eTMPendingSaleTaggedC = t.type({
  _tag: t.literal(`ETMPendingSale`)
});
export type ETMPendingSaleTaggedC = typeof eTMPendingSaleTaggedC;
export type ETMPendingSaleTagged = t.TypeOf<ETMPendingSaleTaggedC>;
export type ETMPendingSale = ETMPendingSaleTagged & typeof eTMPendingSale;
export const eTMPendingSaleC = pipe(eTMPendingSaleTaggedC, c => new t.Type<ETMPendingSale, ETMPendingSaleTagged>(
  `ETMPendingSale`,
  (u: unknown): u is ETMPendingSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ETMPendingSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...eTMPendingSale }))),
  (x: ETMPendingSale): ETMPendingSaleTagged => ({ ...x, _tag: `ETMPendingSale`}),
));
export type ETMPendingSaleC = typeof eTMPendingSaleC;


export const PARTIALCALLCODENOLONGERINUSE = {
  _tag: `PARTIALCALLCODENOLONGERINUSE`,
  id: 12,
  name: `PARTIAL CALL - CODE NO LONGER IN USE`
} as const;

export const PARTIALCALLCODENOLONGERINUSETaggedC = t.type({
  _tag: t.literal(`PARTIALCALLCODENOLONGERINUSE`)
});
export type PARTIALCALLCODENOLONGERINUSETaggedC = typeof PARTIALCALLCODENOLONGERINUSETaggedC;
export type PARTIALCALLCODENOLONGERINUSETagged = t.TypeOf<PARTIALCALLCODENOLONGERINUSETaggedC>;
export type PARTIALCALLCODENOLONGERINUSE = PARTIALCALLCODENOLONGERINUSETagged & typeof PARTIALCALLCODENOLONGERINUSE;
export const PARTIALCALLCODENOLONGERINUSEC = pipe(PARTIALCALLCODENOLONGERINUSETaggedC, c => new t.Type<PARTIALCALLCODENOLONGERINUSE, PARTIALCALLCODENOLONGERINUSETagged>(
  `PARTIALCALLCODENOLONGERINUSE`,
  (u: unknown): u is PARTIALCALLCODENOLONGERINUSE => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PARTIALCALLCODENOLONGERINUSE> => pipe(c.decode(u), E.map(x => ({ ...x, ...PARTIALCALLCODENOLONGERINUSE }))),
  (x: PARTIALCALLCODENOLONGERINUSE): PARTIALCALLCODENOLONGERINUSETagged => ({ ...x, _tag: `PARTIALCALLCODENOLONGERINUSE`}),
));
export type PARTIALCALLCODENOLONGERINUSEC = typeof PARTIALCALLCODENOLONGERINUSEC;


export const preRefunded = {
  _tag: `PreRefunded`,
  id: 13,
  name: `Pre-refunded`
} as const;

export const preRefundedTaggedC = t.type({
  _tag: t.literal(`PreRefunded`)
});
export type PreRefundedTaggedC = typeof preRefundedTaggedC;
export type PreRefundedTagged = t.TypeOf<PreRefundedTaggedC>;
export type PreRefunded = PreRefundedTagged & typeof preRefunded;
export const preRefundedC = pipe(preRefundedTaggedC, c => new t.Type<PreRefunded, PreRefundedTagged>(
  `PreRefunded`,
  (u: unknown): u is PreRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PreRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...preRefunded }))),
  (x: PreRefunded): PreRefundedTagged => ({ ...x, _tag: `PreRefunded`}),
));
export type PreRefundedC = typeof preRefundedC;


export const remarketed = {
  _tag: `Remarketed`,
  id: 14,
  name: `Remarketed`
} as const;

export const remarketedTaggedC = t.type({
  _tag: t.literal(`Remarketed`)
});
export type RemarketedTaggedC = typeof remarketedTaggedC;
export type RemarketedTagged = t.TypeOf<RemarketedTaggedC>;
export type Remarketed = RemarketedTagged & typeof remarketed;
export const remarketedC = pipe(remarketedTaggedC, c => new t.Type<Remarketed, RemarketedTagged>(
  `Remarketed`,
  (u: unknown): u is Remarketed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Remarketed> => pipe(c.decode(u), E.map(x => ({ ...x, ...remarketed }))),
  (x: Remarketed): RemarketedTagged => ({ ...x, _tag: `Remarketed`}),
));
export type RemarketedC = typeof remarketedC;


export const tendered = {
  _tag: `Tendered`,
  id: 15,
  name: `Tendered`
} as const;

export const tenderedTaggedC = t.type({
  _tag: t.literal(`Tendered`)
});
export type TenderedTaggedC = typeof tenderedTaggedC;
export type TenderedTagged = t.TypeOf<TenderedTaggedC>;
export type Tendered = TenderedTagged & typeof tendered;
export const tenderedC = pipe(tenderedTaggedC, c => new t.Type<Tendered, TenderedTagged>(
  `Tendered`,
  (u: unknown): u is Tendered => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Tendered> => pipe(c.decode(u), E.map(x => ({ ...x, ...tendered }))),
  (x: Tendered): TenderedTagged => ({ ...x, _tag: `Tendered`}),
));
export type TenderedC = typeof tenderedC;


export const escrowedToConversionDate = {
  _tag: `EscrowedToConversionDate`,
  id: 16,
  name: `Escrowed to conversion date`
} as const;

export const escrowedToConversionDateTaggedC = t.type({
  _tag: t.literal(`EscrowedToConversionDate`)
});
export type EscrowedToConversionDateTaggedC = typeof escrowedToConversionDateTaggedC;
export type EscrowedToConversionDateTagged = t.TypeOf<EscrowedToConversionDateTaggedC>;
export type EscrowedToConversionDate = EscrowedToConversionDateTagged & typeof escrowedToConversionDate;
export const escrowedToConversionDateC = pipe(escrowedToConversionDateTaggedC, c => new t.Type<EscrowedToConversionDate, EscrowedToConversionDateTagged>(
  `EscrowedToConversionDate`,
  (u: unknown): u is EscrowedToConversionDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EscrowedToConversionDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...escrowedToConversionDate }))),
  (x: EscrowedToConversionDate): EscrowedToConversionDateTagged => ({ ...x, _tag: `EscrowedToConversionDate`}),
));
export type EscrowedToConversionDateC = typeof escrowedToConversionDateC;


export const crossOverRefunded = {
  _tag: `CrossOverRefunded`,
  id: 17,
  name: `Cross-over refunded`
} as const;

export const crossOverRefundedTaggedC = t.type({
  _tag: t.literal(`CrossOverRefunded`)
});
export type CrossOverRefundedTaggedC = typeof crossOverRefundedTaggedC;
export type CrossOverRefundedTagged = t.TypeOf<CrossOverRefundedTaggedC>;
export type CrossOverRefunded = CrossOverRefundedTagged & typeof crossOverRefunded;
export const crossOverRefundedC = pipe(crossOverRefundedTaggedC, c => new t.Type<CrossOverRefunded, CrossOverRefundedTagged>(
  `CrossOverRefunded`,
  (u: unknown): u is CrossOverRefunded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CrossOverRefunded> => pipe(c.decode(u), E.map(x => ({ ...x, ...crossOverRefunded }))),
  (x: CrossOverRefunded): CrossOverRefundedTagged => ({ ...x, _tag: `CrossOverRefunded`}),
));
export type CrossOverRefundedC = typeof crossOverRefundedC;


export const partiallyPreRefundedSeeNewCUSIPS = {
  _tag: `PartiallyPreRefundedSeeNewCUSIPS`,
  id: 18,
  name: `Partially pre-refunded-see new CUSIPS`
} as const;

export const partiallyPreRefundedSeeNewCUSIPSTaggedC = t.type({
  _tag: t.literal(`PartiallyPreRefundedSeeNewCUSIPS`)
});
export type PartiallyPreRefundedSeeNewCUSIPSTaggedC = typeof partiallyPreRefundedSeeNewCUSIPSTaggedC;
export type PartiallyPreRefundedSeeNewCUSIPSTagged = t.TypeOf<PartiallyPreRefundedSeeNewCUSIPSTaggedC>;
export type PartiallyPreRefundedSeeNewCUSIPS = PartiallyPreRefundedSeeNewCUSIPSTagged & typeof partiallyPreRefundedSeeNewCUSIPS;
export const partiallyPreRefundedSeeNewCUSIPSC = pipe(partiallyPreRefundedSeeNewCUSIPSTaggedC, c => new t.Type<PartiallyPreRefundedSeeNewCUSIPS, PartiallyPreRefundedSeeNewCUSIPSTagged>(
  `PartiallyPreRefundedSeeNewCUSIPS`,
  (u: unknown): u is PartiallyPreRefundedSeeNewCUSIPS => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PartiallyPreRefundedSeeNewCUSIPS> => pipe(c.decode(u), E.map(x => ({ ...x, ...partiallyPreRefundedSeeNewCUSIPS }))),
  (x: PartiallyPreRefundedSeeNewCUSIPS): PartiallyPreRefundedSeeNewCUSIPSTagged => ({ ...x, _tag: `PartiallyPreRefundedSeeNewCUSIPS`}),
));
export type PartiallyPreRefundedSeeNewCUSIPSC = typeof partiallyPreRefundedSeeNewCUSIPSC;


export const crossOverRefundingEscrowedTill = {
  _tag: `CrossOverRefundingEscrowedTill`,
  id: 19,
  name: `Cross-over refunding - escrowed till`
} as const;

export const crossOverRefundingEscrowedTillTaggedC = t.type({
  _tag: t.literal(`CrossOverRefundingEscrowedTill`)
});
export type CrossOverRefundingEscrowedTillTaggedC = typeof crossOverRefundingEscrowedTillTaggedC;
export type CrossOverRefundingEscrowedTillTagged = t.TypeOf<CrossOverRefundingEscrowedTillTaggedC>;
export type CrossOverRefundingEscrowedTill = CrossOverRefundingEscrowedTillTagged & typeof crossOverRefundingEscrowedTill;
export const crossOverRefundingEscrowedTillC = pipe(crossOverRefundingEscrowedTillTaggedC, c => new t.Type<CrossOverRefundingEscrowedTill, CrossOverRefundingEscrowedTillTagged>(
  `CrossOverRefundingEscrowedTill`,
  (u: unknown): u is CrossOverRefundingEscrowedTill => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CrossOverRefundingEscrowedTill> => pipe(c.decode(u), E.map(x => ({ ...x, ...crossOverRefundingEscrowedTill }))),
  (x: CrossOverRefundingEscrowedTill): CrossOverRefundingEscrowedTillTagged => ({ ...x, _tag: `CrossOverRefundingEscrowedTill`}),
));
export type CrossOverRefundingEscrowedTillC = typeof crossOverRefundingEscrowedTillC;


export const allMuniRedemptionTypeC = [eTMByMuniForwardC, preRefundedByMuniForwardC, calledC, calledDueToDefaultC, escrowedToMaturityC, calledByMuniForwardC, repaymentC, escrowedAsToInterestOnlyC, escrowedAsToPrincipalOnlyC, preRefundedPendingSaleC, eTMPendingSaleC, PARTIALCALLCODENOLONGERINUSEC, preRefundedC, remarketedC, tenderedC, escrowedToConversionDateC, crossOverRefundedC, partiallyPreRefundedSeeNewCUSIPSC, crossOverRefundingEscrowedTillC] as const;
export const allMuniRedemptionTypeNames = [`ETMByMuniForward`, `PreRefundedByMuniForward`, `Called`, `CalledDueToDefault`, `EscrowedToMaturity`, `CalledByMuniForward`, `Repayment`, `EscrowedAsToInterestOnly`, `EscrowedAsToPrincipalOnly`, `PreRefundedPendingSale`, `ETMPendingSale`, `PARTIALCALLCODENOLONGERINUSE`, `PreRefunded`, `Remarketed`, `Tendered`, `EscrowedToConversionDate`, `CrossOverRefunded`, `PartiallyPreRefundedSeeNewCUSIPS`, `CrossOverRefundingEscrowedTill`] as const;
export type MuniRedemptionTypeName = (typeof allMuniRedemptionTypeNames)[number];

export const MuniRedemptionTypeCU = t.union([eTMByMuniForwardC, preRefundedByMuniForwardC, calledC, calledDueToDefaultC, escrowedToMaturityC, calledByMuniForwardC, repaymentC, escrowedAsToInterestOnlyC, escrowedAsToPrincipalOnlyC, preRefundedPendingSaleC, eTMPendingSaleC, PARTIALCALLCODENOLONGERINUSEC, preRefundedC, remarketedC, tenderedC, escrowedToConversionDateC, crossOverRefundedC, partiallyPreRefundedSeeNewCUSIPSC, crossOverRefundingEscrowedTillC]);
export type MuniRedemptionTypeCU = typeof MuniRedemptionTypeCU;
export type MuniRedemptionTypeU = t.TypeOf<MuniRedemptionTypeCU>;

export const muniRedemptionTypeOrd: Ord.Ord<MuniRedemptionTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allMuniRedemptionType = [eTMByMuniForward, preRefundedByMuniForward, called, calledDueToDefault, escrowedToMaturity, calledByMuniForward, repayment, escrowedAsToInterestOnly, escrowedAsToPrincipalOnly, preRefundedPendingSale, eTMPendingSale, PARTIALCALLCODENOLONGERINUSE, preRefunded, remarketed, tendered, escrowedToConversionDate, crossOverRefunded, partiallyPreRefundedSeeNewCUSIPS, crossOverRefundingEscrowedTill] as const;
export type MuniRedemptionTypeMap<A> = { [K in MuniRedemptionTypeName]: A };


