import * as t from "io-ts";

export const apiRedirectC = t.type({
  redirect: t.string
});
export type ApiRedirectC = typeof apiRedirectC;
export type ApiRedirect = t.TypeOf<ApiRedirectC>;


export const apiReload = {
  _tag: `ApiReload`
} as const;

export const apiReloadC = t.type({
  _tag: t.literal(`ApiReload`)
});
export type ApiReloadC = typeof apiReloadC;
export type ApiReload = t.TypeOf<ApiReloadC>;


