/* eslint-disable @typescript-eslint/no-deprecated */
import type { PropsWithChildren } from "react";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { constVoid, pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import { defaultSampleRate, useActiveTransactionVolatile } from "@scripts/sentry";

export type AppName = "IssuerPortal"
  | "InvestorPortal"
  | "BankerPortal"
  | "BankSites"
  | "IssuerSites"
  | "Pages"
  | "Emails";
type PageTitle = string;
export type SentryTransactionBoundaryProps = PropsWithChildren<{
  transactionName: string;
  appName: AppName;
  pageTitle: PageTitle;
  description?: string;
  sampleRate?: number;
}>;

export const SentryTransactionBoundary = (props: SentryTransactionBoundaryProps) => {
  const transaction = useActiveTransactionVolatile();

  if (O.isNone(transaction)) {
    const sentryHub = Sentry.getCurrentHub();
    const sentryScope = sentryHub.getScope();
    const newSentryTransaction = sentryHub.startTransaction({ name: props.transactionName });
    sentryScope.setSpan(newSentryTransaction);
  }

  useEffect(() => {
    pipe(
      transaction,
      O.fold(constVoid, trx => {
        trx.setName(props.transactionName);
        trx.setData("appName", props.appName);
        trx.setData("pageTitle", props.pageTitle);
        trx.setData("sampleRate", props.sampleRate ?? defaultSampleRate);
      })
    );
  }, [transaction, props.appName, props.pageTitle, props.sampleRate, props.transactionName]);
  try {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{props.children}</>;
  } catch (e) {
    const trx = O.toNullable(transaction);
    if (e !== null && typeof e === "object" && e instanceof Error) {
      trx?.setData("clientErrorMessage", e.message);
    } else if (typeof e === "string") {
      trx?.setData("clientErrorMessage", e);
    }
    trx?.finish();
    throw e;
  }
};
