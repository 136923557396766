import type { PropsWithChildren } from "react";
import { createContext, useContext } from "react";

import type { BLConfigWithLog } from "@scripts/bondlink";

// eslint-disable-next-line no-undefined
const ConfigContext = createContext<BLConfigWithLog | undefined>(undefined);

export const ConfigProvider = (props: PropsWithChildren<{
  value: BLConfigWithLog;
}>) =>
  <ConfigContext.Provider value={props.value}>
    {props.children}
  </ConfigContext.Provider>;

export const useConfig = () => {
  const context = useContext(ConfigContext);

  // eslint-disable-next-line no-undefined
  if (context === undefined) {
    throw new Error("useConfig can only be called if <ConfigProvider> is an ancestor.");
  }

  return context;
};
