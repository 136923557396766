import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const general = {
  _tag: `General`,
  id: 1,
  showName: `general-disclaimer`,
  typeName: `general`
} as const;

export const generalTaggedC = t.type({
  _tag: t.literal(`General`)
});
export type GeneralTaggedC = typeof generalTaggedC;
export type GeneralTagged = t.TypeOf<GeneralTaggedC>;
export type General = GeneralTagged & typeof general;
export const generalC = pipe(generalTaggedC, c => new t.Type<General, GeneralTagged>(
  `General`,
  (u: unknown): u is General => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, General> => pipe(c.decode(u), E.map(x => ({ ...x, ...general }))),
  (x: General): GeneralTagged => ({ ...x, _tag: `General`}),
));
export type GeneralC = typeof generalC;


export const offering = {
  _tag: `Offering`,
  id: 2,
  showName: `offering-disclaimer`,
  typeName: `offering`
} as const;

export const offeringTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTaggedC = typeof offeringTaggedC;
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export const offeringC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
));
export type OfferingC = typeof offeringC;


export const exit = {
  _tag: `Exit`,
  id: 3,
  showName: `exit-disclaimer`,
  typeName: `exit`
} as const;

export const exitTaggedC = t.type({
  _tag: t.literal(`Exit`)
});
export type ExitTaggedC = typeof exitTaggedC;
export type ExitTagged = t.TypeOf<ExitTaggedC>;
export type Exit = ExitTagged & typeof exit;
export const exitC = pipe(exitTaggedC, c => new t.Type<Exit, ExitTagged>(
  `Exit`,
  (u: unknown): u is Exit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Exit> => pipe(c.decode(u), E.map(x => ({ ...x, ...exit }))),
  (x: Exit): ExitTagged => ({ ...x, _tag: `Exit`}),
));
export type ExitC = typeof exitC;


export const document = {
  _tag: `Document`,
  id: 4,
  showName: `document-disclaimer`,
  typeName: `document`
} as const;

export const documentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTaggedC = typeof documentTaggedC;
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export const documentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
));
export type DocumentC = typeof documentC;


export const allDisclaimerTypeC = [generalC, offeringC, exitC, documentC] as const;
export const allDisclaimerTypeNames = [`General`, `Offering`, `Exit`, `Document`] as const;
export type DisclaimerTypeName = (typeof allDisclaimerTypeNames)[number];

export const DisclaimerTypeCU = t.union([generalC, offeringC, exitC, documentC]);
export type DisclaimerTypeCU = typeof DisclaimerTypeCU;
export type DisclaimerTypeU = t.TypeOf<DisclaimerTypeCU>;

export const disclaimerTypeOrd: Ord.Ord<DisclaimerTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDisclaimerType = [general, offering, exit, document] as const;
export type DisclaimerTypeMap<A> = { [K in DisclaimerTypeName]: A };


