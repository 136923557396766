import { userC as imported18_userC, userC as imported19_userC } from "./user";
import { mfaInitiatedC as imported1_mfaInitiatedC } from "../domaintables/loginStatus";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const userSettingsPostC = t.type({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  phoneNumber: optionFromNullable(t.string),
  phoneExtension: optionFromNullable(t.string),
  company: t.string,
  title: optionFromNullable(t.string),
  primaryClientId: optionFromNullable(t.number),
  personaId: t.number,
  investorTypeId: optionFromNullable(t.number),
  subscribedToHubspot: t.boolean
});
export type UserSettingsPostC = typeof userSettingsPostC;
export type UserSettingsPost = t.TypeOf<UserSettingsPostC>;


export const userSettingsResponseC = t.type({
  user: imported18_userC,
  emailChanged: t.boolean
});
export type UserSettingsResponseC = typeof userSettingsResponseC;
export type UserSettingsResponse = t.TypeOf<UserSettingsResponseC>;


export const mfaSettingsPostC = t.type({
  phoneNumber: t.string
});
export type MfaSettingsPostC = typeof mfaSettingsPostC;
export type MfaSettingsPost = t.TypeOf<MfaSettingsPostC>;


export const mfaSettingsResponseC = t.type({
  user: imported19_userC,
  verification: imported1_mfaInitiatedC
});
export type MfaSettingsResponseC = typeof mfaSettingsResponseC;
export type MfaSettingsResponse = t.TypeOf<MfaSettingsResponseC>;


export const verifyMfaPostC = t.type({
  verificationId: t.string,
  totp: t.string
});
export type VerifyMfaPostC = typeof verifyMfaPostC;
export type VerifyMfaPost = t.TypeOf<VerifyMfaPostC>;


export const updatePasswordPostC = t.type({
  password: t.string,
  newPassword: t.string,
  newPasswordConfirm: t.string
});
export type UpdatePasswordPostC = typeof updatePasswordPostC;
export type UpdatePasswordPost = t.TypeOf<UpdatePasswordPostC>;


export const verifyTotpPostC = t.type({
  verificationId: t.string,
  totp: t.string,
  userId: t.number,
  redirect: optionFromNullable(t.string),
  remember: t.boolean
});
export type VerifyTotpPostC = typeof verifyTotpPostC;
export type VerifyTotpPost = t.TypeOf<VerifyTotpPostC>;


