import { WithStatusCU as imported366_WithStatusCU, WithStatusCU as imported367_WithStatusCU, hasManyLinkC as imported170_hasManyLinkC, WithStatusCU as imported364_WithStatusCU, WithStatusCU as imported368_WithStatusCU, WithStatusCU as imported365_WithStatusCU, hasManyLinkC as imported171_hasManyLinkC, WithStatusCU as imported362_WithStatusCU, hasManyLinkC as imported173_hasManyLinkC, WithStatusCU as imported369_WithStatusCU, hasManyLinkC as imported172_hasManyLinkC, WithStatusCU as imported361_WithStatusCU, hasManyLinkC as imported174_hasManyLinkC, hasManyLinkC as imported169_hasManyLinkC, WithStatusCU as imported370_WithStatusCU, WithStatusCU as imported363_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { genRfpWithRelatedContentC as imported21_genRfpWithRelatedContentC, rfpCardRelatedContentC as imported5_rfpCardRelatedContentC } from "./rfp";
import { externalLinkC as imported24_externalLinkC, externalLinkC as imported25_externalLinkC } from "./externalLink";
import { projectC as imported27_projectC } from "./project";
import { taggedContentC as imported193_taggedContentC, taggedContentC as imported199_taggedContentC, taggedContentC as imported197_taggedContentC, taggedContentC as imported195_taggedContentC, taggedContentC as imported196_taggedContentC, taggedContentC as imported198_taggedContentC, taggedContentC as imported194_taggedContentC } from "./taggedContent";
import { bondProgramC as imported20_bondProgramC } from "./bondProgramBase";
import { subscribedC as imported37_subscribedC, subscribedC as imported36_subscribedC } from "./subscribed";
import { either } from "io-ts-types/lib/either";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { sortOrderC as imported36_sortOrderC, sortOrderC as imported37_sortOrderC } from "./sortOrder";
import { markdownC } from "../../codecs/markdown";
import { issuerNewsC as imported22_issuerNewsC, issuerNewsC as imported23_issuerNewsC } from "./issuerNewsBase";
import { eventWithRelatedContentC as imported5_eventWithRelatedContentC } from "./eventWithRelatedContent";
import { mediaC as imported74_mediaC, mediaUploadResponseWithCategoryC as imported13_mediaUploadResponseWithCategoryC } from "./media";
import { LocalDateC } from "../../codecs/localDate";
import { bondOfferingC as imported29_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported63_rfpC } from "./rfpBase";
import { bondOfferingWithRelatedContentC as imported15_bondOfferingWithRelatedContentC } from "./bondOffering";
import { relatedProjectLinkC as imported29_relatedProjectLinkC, relatedRfpLinkC as imported12_relatedRfpLinkC, relatedDocumentLinkC as imported34_relatedDocumentLinkC, postRelatedC as imported60_postRelatedC, relatedDocumentLinkC as imported35_relatedDocumentLinkC, relatedOfferingLinkC as imported13_relatedOfferingLinkC, postRelatedC as imported62_postRelatedC, isAttachedC as imported21_isAttachedC, postRelatedC as imported61_postRelatedC, relatedOfferingLinkC as imported12_relatedOfferingLinkC, relatedProgramLinkC as imported10_relatedProgramLinkC, relatedProjectLinkC as imported28_relatedProjectLinkC, postRelatedAndAttachedC as imported13_postRelatedAndAttachedC, postAttachedC as imported25_postAttachedC, relatedExternalLinkLinkC as imported13_relatedExternalLinkLinkC, relatedRfpLinkC as imported13_relatedRfpLinkC, relatedProgramLinkC as imported11_relatedProgramLinkC, postRelatedC as imported63_postRelatedC } from "./relatedContent";

export const issuerNewsRelatedContentC = t.type({
  projects: t.readonlyArray(imported169_hasManyLinkC(imported361_WithStatusCU(imported193_taggedContentC(imported27_projectC)), imported28_relatedProjectLinkC)),
  offerings: t.readonlyArray(imported170_hasManyLinkC(imported362_WithStatusCU(imported194_taggedContentC(imported29_bondOfferingC)), imported12_relatedOfferingLinkC)),
  documents: t.readonlyArray(imported171_hasManyLinkC(imported363_WithStatusCU(imported195_taggedContentC(imported74_mediaC)), imported21_isAttachedC(imported34_relatedDocumentLinkC))),
  externalLinks: t.readonlyArray(imported172_hasManyLinkC(imported364_WithStatusCU(imported24_externalLinkC), imported13_relatedExternalLinkLinkC)),
  rfps: t.readonlyArray(imported173_hasManyLinkC(imported365_WithStatusCU(imported196_taggedContentC(imported63_rfpC)), imported12_relatedRfpLinkC)),
  programs: t.readonlyArray(imported174_hasManyLinkC(imported366_WithStatusCU(imported20_bondProgramC), imported10_relatedProgramLinkC))
});
export type IssuerNewsRelatedContentC = typeof issuerNewsRelatedContentC;
export type IssuerNewsRelatedContent = t.TypeOf<IssuerNewsRelatedContentC>;


export const issuerNewsWithRelatedContentC = t.type({
  news: imported22_issuerNewsC,
  relatedContent: issuerNewsRelatedContentC
});
export type IssuerNewsWithRelatedContentC = typeof issuerNewsWithRelatedContentC;
export type IssuerNewsWithRelatedContent = t.TypeOf<IssuerNewsWithRelatedContentC>;


export const issuerNewsPostRelatedContentC = t.type({
  projects: imported60_postRelatedC(imported29_relatedProjectLinkC),
  offerings: imported61_postRelatedC(imported13_relatedOfferingLinkC),
  documents: imported13_postRelatedAndAttachedC(imported35_relatedDocumentLinkC, imported13_mediaUploadResponseWithCategoryC, imported36_sortOrderC),
  externalLinks: imported25_postAttachedC(imported25_externalLinkC, imported37_sortOrderC),
  rfps: imported62_postRelatedC(imported13_relatedRfpLinkC),
  programs: imported63_postRelatedC(imported11_relatedProgramLinkC)
});
export type IssuerNewsPostRelatedContentC = typeof issuerNewsPostRelatedContentC;
export type IssuerNewsPostRelatedContent = t.TypeOf<IssuerNewsPostRelatedContentC>;


export const issuerNewsPostBodyC = t.type({
  id: optionFromNullable(t.number),
  newsTitle: t.string,
  newsDate: optionFromNullable(LocalDateC),
  newsBlurb: markdownC,
  postTypeId: t.number,
  newsOrder: t.number,
  esgRelated: t.boolean,
  relatedContent: issuerNewsPostRelatedContentC
});
export type IssuerNewsPostBodyC = typeof issuerNewsPostBodyC;
export type IssuerNewsPostBody = t.TypeOf<IssuerNewsPostBodyC>;


export const newsAndEventsDataC = t.type({
  eventItems: t.readonlyArray(either(imported367_WithStatusCU(imported5_eventWithRelatedContentC), either(imported36_subscribedC(imported368_WithStatusCU(imported197_taggedContentC(imported15_bondOfferingWithRelatedContentC))), imported37_subscribedC(imported369_WithStatusCU(imported198_taggedContentC(imported21_genRfpWithRelatedContentC(imported5_rfpCardRelatedContentC))))))),
  news: t.readonlyArray(imported370_WithStatusCU(imported199_taggedContentC(imported23_issuerNewsC)))
});
export type NewsAndEventsDataC = typeof newsAndEventsDataC;
export type NewsAndEventsData = t.TypeOf<NewsAndEventsDataC>;


