import { useState } from "react";
import type * as t from "io-ts";

import { constVoid, O } from "@scripts/fp-ts";
import type { ApiRedirect } from "@scripts/generated/models/apiRedirect";
import * as corpRouter from "@scripts/generated/routers/corpRouter";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { AnchorWithChildren } from "@scripts/react/components/Anchor";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { Form } from "@scripts/react/components/form/Form";
import { emptyFormState } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";
import { openInSameTab } from "@scripts/routes/router";

import { MinimalContainer, MinimalFormContainer } from "@scripts-ssr/components/MinimalLayout";

export const ReviewTermsPage = (props: ApiRedirect) => {
  const [formState, setFormState] = useState(emptyFormState<t.TypeC<t.Props>>());

  return (
    <MinimalContainer>
      <p>
        Before continuing, please take a moment to review and accept BondLink’s{" "}
        <AnchorWithChildren
          route={{ title: "Terms", route: corpRouter.corporateStaticTerms() }}
          target="_self"
        >
          terms &amp; conditions
        </AnchorWithChildren>.
      </p>
      <MinimalFormContainer>
        <Form
          url={V2Router.investorPortalInvestorControllerAcceptTerms({ redirect: props.redirect })}
          state={formState}
          setState={setFormState}
          onSuccess={() => openInSameTab(props.redirect)}
          onFailure={O.none}
          headers={O.none}
        >
          <ButtonSubmit text="Accept" onClick={constVoid} loading={formState.loading} loadingText="Submitting" {...klass("w-100")} />
        </Form>
      </MinimalFormContainer>
    </MinimalContainer>
  );
};
