import { LocalDateC } from "../../codecs/localDate";
import { LocalDateOrd } from "../../syntax/date/jodaSyntax";
import * as t from "io-ts";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";

export const graphDataC = t.type({
  minY: t.number,
  maxY: t.number,
  start: LocalDateC,
  end: LocalDateC,
  avg: t.number,
  data: readonlyMapFromEntries(LocalDateC, LocalDateOrd, t.number)
});
export type GraphDataC = typeof graphDataC;
export type GraphData = t.TypeOf<GraphDataC>;


