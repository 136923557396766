import { pipe } from "fp-ts/lib/function";
import type { Dispatch } from "redux";

import type { Flash } from "@scripts/generated/models/flash";
import { flashRemove } from "@scripts/react/state/flash";
import type { BaseActions } from "@scripts/react/state/store";
import { klass } from "@scripts/react/util/classnames";

import { grid } from "@styles/utilities/_grid";

import type { CloseButton } from "../Alert";
import { alertFromFlash, AlertPushDownBase } from "../Alert";
import { CloseXButton } from "../Button";
import { Empty } from "../Empty";

export type AlertsProps = {
  flash: ReadonlyArray<Flash>;
  dispatch: Dispatch<BaseActions>;
};

export const AlertsBase = (props: AlertsProps & {
  closeButton: CloseButton;
}) => (
  <div {...klass(grid[".page-container-width"])}>
    {
      props.flash.length > 0
        ? props.flash.map(f => pipe(
          alertFromFlash(f),
          c => <AlertPushDownBase
            key={c.id}
            id={c.id}
            type={c.type}
            removeAction={a => props.dispatch(flashRemove(a))}
            closeButton={props.closeButton}
          >
            {c.children}
          </AlertPushDownBase>
        )) : <Empty />
    }
  </div>
);

export const Alerts = (props: AlertsProps) => (
  <AlertsBase {...props} closeButton={CloseXButton} />
);
