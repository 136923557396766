import { readonlyNonEmptyArrayC } from "../../codecs/readonlyNonEmptyArray";
import * as t from "io-ts";
import { bigNumberC } from "../../Big/bigNumberC";

export const ipreoHoldingsC = t.type({
  ownerName: t.string,
  parHeld: bigNumberC
});
export type IpreoHoldingsC = typeof ipreoHoldingsC;
export type IpreoHoldings = t.TypeOf<IpreoHoldingsC>;


export const holdingsWithCompanyDataC = t.type({
  holdings: t.readonlyArray(ipreoHoldingsC),
  ipreoToBLCompanies: t.record(t.string, readonlyNonEmptyArrayC(t.string))
});
export type HoldingsWithCompanyDataC = typeof holdingsWithCompanyDataC;
export type HoldingsWithCompanyData = t.TypeOf<HoldingsWithCompanyDataC>;


