import * as n from "fp-ts/lib/number";
import * as Ord from "fp-ts/lib/Ord";
import * as s from "fp-ts/lib/string";

import { Eq } from "@scripts/fp-ts";

export type IdEqBase = { id: number };
export const idEq = <A extends IdEqBase>() => Eq.contramap<number, A>(a => a.id)(n.Eq);
export const idOrd = <A extends IdEqBase>() => Ord.contramap<number, A>(a => a.id)(n.Ord);

export const idEqPartial = <A extends Partial<IdEqBase> | undefined | null>(): Eq.Eq<A> => Eq.contramap<number | undefined, A>(a => a?.id)(Eq.nullable(n.Eq));

export const tagEq = <A extends { _tag: string }>() => Eq.contramap<string, A>(a => a._tag)(s.Eq);
export const tagOrd = <A extends { _tag: string }>() => Ord.contramap<string, A>(a => a._tag)(s.Ord);

export const tagEqPartial = <A extends Partial<{ _tag: string }> | undefined | null>(): Eq.Eq<A> => Eq.contramap<string | undefined, A>(a => a?._tag)(Eq.nullable(s.Eq));

export const isTagged = (a: unknown): a is { _tag: string } => (a && typeof a === "object" && "_tag" in a) ? true : false;
