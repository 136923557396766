import { useState } from "react";

import { constVoid, E, O, pipe } from "@scripts/fp-ts";
import type { ConfirmAccountData, ConfirmAccountPostC } from "@scripts/generated/models/user";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { Form } from "@scripts/react/components/form/Form";
import { NewPasswordWithConfirmInput } from "@scripts/react/components/form/PasswordInput";
import { stringC } from "@scripts/react/form/codecs";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { klass } from "@scripts/react/util/classnames";

import { MinimalContainer, MinimalFormContainer, MinimalHeader } from "@scripts-ssr/components/MinimalLayout";

import { AcceptTermsCheckbox } from "../components/AcceptTerms";
import { loginSignUpRedirect } from "../components/loginPage/loginPageSyntax";

const accountInviteL = formLens<ConfirmAccountPostC>();
const accountInviteDL = formDataLens<ConfirmAccountPostC>();

const makeAccountInviteInitialState = (d: ConfirmAccountData) => pipe(
  emptyFormState<ConfirmAccountPostC>(),
  accountInviteDL.compose(accountInviteL("email")).set(d.email),
  accountInviteDL.compose(accountInviteL("hash")).set(d.hash),
);

export const AccountInvitePage = (props: ConfirmAccountData) => {
  const [formState, setFormState] = useState(makeAccountInviteInitialState(props));
  return (
    <MinimalContainer>
      <MinimalHeader text="Confirm Account" />
      <p>
        Welcome to BondLink! We’ve created an account for you using the email address below.
        Choose a password to complete your account activation.
      </p>
      <p {...klass("align-self-start")}><strong>{props.email}</strong></p>
      <MinimalFormContainer>
        <Form
          url={V2Router.baseAuthControllerConfirmInvitePost()}
          state={formState}
          setState={setFormState}
          onSuccess={(_, r) => loginSignUpRedirect(r.data.redirect)}
          onFailure={O.none}
          headers={O.none}
        >
          <NewPasswordWithConfirmInput
            codec={stringC}
            confirmProps={O.some({
              labelOrAriaLabel: E.left("Confirm Password"),
              lens: accountInviteL("confirmPassword"),
            })}
            labelOrAriaLabel={E.left("Password")}
            lens={accountInviteL("password")}
            placeholder={O.none}
            state={formState}
            setState={setFormState}
          />
          <AcceptTermsCheckbox
            formState={formState}
            setFormState={setFormState}
            lens={accountInviteL("termsAccepted")}
          />
          <ButtonSubmit
            {...klass("w-100")}
            loading={formState.loading}
            loadingText={"Confirming Account"}
            onClick={constVoid}
            text={"Confirm Account"}
          />
        </Form>
      </MinimalFormContainer>
    </MinimalContainer>
  );
};
