import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondSale = {
  _tag: `BondSale`,
  id: 1,
  name: `Bond Sale`
} as const;

export const bondSaleTaggedC = t.type({
  _tag: t.literal(`BondSale`)
});
export type BondSaleTaggedC = typeof bondSaleTaggedC;
export type BondSaleTagged = t.TypeOf<BondSaleTaggedC>;
export type BondSale = BondSaleTagged & typeof bondSale;
export const bondSaleC = pipe(bondSaleTaggedC, c => new t.Type<BondSale, BondSaleTagged>(
  `BondSale`,
  (u: unknown): u is BondSale => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondSale> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondSale }))),
  (x: BondSale): BondSaleTagged => ({ ...x, _tag: `BondSale`}),
));
export type BondSaleC = typeof bondSaleC;


export const privateLoan = {
  _tag: `PrivateLoan`,
  id: 2,
  name: `Private Loan`
} as const;

export const privateLoanTaggedC = t.type({
  _tag: t.literal(`PrivateLoan`)
});
export type PrivateLoanTaggedC = typeof privateLoanTaggedC;
export type PrivateLoanTagged = t.TypeOf<PrivateLoanTaggedC>;
export type PrivateLoan = PrivateLoanTagged & typeof privateLoan;
export const privateLoanC = pipe(privateLoanTaggedC, c => new t.Type<PrivateLoan, PrivateLoanTagged>(
  `PrivateLoan`,
  (u: unknown): u is PrivateLoan => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PrivateLoan> => pipe(c.decode(u), E.map(x => ({ ...x, ...privateLoan }))),
  (x: PrivateLoan): PrivateLoanTagged => ({ ...x, _tag: `PrivateLoan`}),
));
export type PrivateLoanC = typeof privateLoanC;


export const note = {
  _tag: `Note`,
  id: 3,
  name: `Note`
} as const;

export const noteTaggedC = t.type({
  _tag: t.literal(`Note`)
});
export type NoteTaggedC = typeof noteTaggedC;
export type NoteTagged = t.TypeOf<NoteTaggedC>;
export type Note = NoteTagged & typeof note;
export const noteC = pipe(noteTaggedC, c => new t.Type<Note, NoteTagged>(
  `Note`,
  (u: unknown): u is Note => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Note> => pipe(c.decode(u), E.map(x => ({ ...x, ...note }))),
  (x: Note): NoteTagged => ({ ...x, _tag: `Note`}),
));
export type NoteC = typeof noteC;


export const other = {
  _tag: `Other`,
  id: 4,
  name: `Other`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const allDebtTypeC = [bondSaleC, privateLoanC, noteC, otherC] as const;
export const allDebtTypeNames = [`BondSale`, `PrivateLoan`, `Note`, `Other`] as const;
export type DebtTypeName = (typeof allDebtTypeNames)[number];

export const DebtTypeCU = t.union([bondSaleC, privateLoanC, noteC, otherC]);
export type DebtTypeCU = typeof DebtTypeCU;
export type DebtTypeU = t.TypeOf<DebtTypeCU>;

export const debtTypeOrd: Ord.Ord<DebtTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allDebtType = [bondSale, privateLoan, note, other] as const;
export type DebtTypeMap<A> = { [K in DebtTypeName]: A };


