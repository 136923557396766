// This file was generated by postcss-transpiler. Do not edit by hand.

export const notification = {
  ".notification": {
    css: ".notification",
    html: "notification",
    attrs: {
      ".notification-danger": {
        css: ".notification-danger",
        html: "notification-danger"
      },
      ".notification-info": {
        css: ".notification-info",
        html: "notification-info"
      },
      ".notification-success": {
        css: ".notification-success",
        html: "notification-success"
      },
      ".notification-warning": {
        css: ".notification-warning",
        html: "notification-warning"
      }
    },
    ".body": {
      css: ".body",
      html: "body",
      attrs: {
        ".notification-danger": {
          css: ".notification-danger",
          html: "notification-danger"
        }
      }
    },
    ".header": {
      css: ".header",
      html: "header",
      ".title": {
        css: ".title",
        html: "title",
        "h5": {
          css: "h5",
          html: "h5"
        },
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      },
      ".icon-button": {
        css: ".icon-button",
        html: "icon-button",
        ".icon": {
          css: ".icon",
          html: "icon"
        }
      }
    }
  },
  ".notification-container": {
    css: ".notification-container",
    html: "notification-container"
  }
};
