import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

export const literalNumberFromString = <N extends number>(n: N): t.Type<N, `${N}`> => {
  const lit = t.literal(n);
  return new t.Type<N, `${N}`>(
    "literalNumberFromString",
    (u: unknown): u is N => NumberFromString.is(u) && lit.is(u),
    (u: unknown, c: t.Context): t.Validation<N> => pipe(NumberFromString.validate(u, c), E.chain(_ => lit.validate(_, c))),
    (x: N): `${N}` => `${x}`,
  );
};
