import * as t from "io-ts";

export const resendMfaSmsC = t.type({
  userId: t.number,
  verificationId: t.string
});
export type ResendMfaSmsC = typeof resendMfaSmsC;
export type ResendMfaSms = t.TypeOf<ResendMfaSmsC>;


