import * as E from "fp-ts/lib/Either";
import { flow } from "fp-ts/lib/function";
import type * as t from "io-ts";

import type { DeepLinkTarget } from "./codecs/deepLinkTarget";
import { deepLinkTargetC } from "./codecs/deepLinkTarget";

export let deepLinkTarget: DeepLinkTarget = {};

export const parseDeepLinks: (urlFragment: string) => t.Validation<DeepLinkTarget> = flow(deepLinkTargetC.decode, E.map(target => deepLinkTarget = target));

export const clearModalTarget = () => {
  delete deepLinkTarget.modal;
};

export const clearTabTarget = () => {
  delete deepLinkTarget.tab;
};
