import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bestPractices = {
  _tag: `BestPractices`,
  id: 1,
  name: `Best Practices`
} as const;

export const bestPracticesTaggedC = t.type({
  _tag: t.literal(`BestPractices`)
});
export type BestPracticesTaggedC = typeof bestPracticesTaggedC;
export type BestPracticesTagged = t.TypeOf<BestPracticesTaggedC>;
export type BestPractices = BestPracticesTagged & typeof bestPractices;
export const bestPracticesC = pipe(bestPracticesTaggedC, c => new t.Type<BestPractices, BestPracticesTagged>(
  `BestPractices`,
  (u: unknown): u is BestPractices => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BestPractices> => pipe(c.decode(u), E.map(x => ({ ...x, ...bestPractices }))),
  (x: BestPractices): BestPracticesTagged => ({ ...x, _tag: `BestPractices`}),
));
export type BestPracticesC = typeof bestPracticesC;


export const aroundTheNetwork = {
  _tag: `AroundTheNetwork`,
  id: 3,
  name: `Around The Network`
} as const;

export const aroundTheNetworkTaggedC = t.type({
  _tag: t.literal(`AroundTheNetwork`)
});
export type AroundTheNetworkTaggedC = typeof aroundTheNetworkTaggedC;
export type AroundTheNetworkTagged = t.TypeOf<AroundTheNetworkTaggedC>;
export type AroundTheNetwork = AroundTheNetworkTagged & typeof aroundTheNetwork;
export const aroundTheNetworkC = pipe(aroundTheNetworkTaggedC, c => new t.Type<AroundTheNetwork, AroundTheNetworkTagged>(
  `AroundTheNetwork`,
  (u: unknown): u is AroundTheNetwork => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, AroundTheNetwork> => pipe(c.decode(u), E.map(x => ({ ...x, ...aroundTheNetwork }))),
  (x: AroundTheNetwork): AroundTheNetworkTagged => ({ ...x, _tag: `AroundTheNetwork`}),
));
export type AroundTheNetworkC = typeof aroundTheNetworkC;


export const caseStudy = {
  _tag: `CaseStudy`,
  id: 4,
  name: `Case Study`
} as const;

export const caseStudyTaggedC = t.type({
  _tag: t.literal(`CaseStudy`)
});
export type CaseStudyTaggedC = typeof caseStudyTaggedC;
export type CaseStudyTagged = t.TypeOf<CaseStudyTaggedC>;
export type CaseStudy = CaseStudyTagged & typeof caseStudy;
export const caseStudyC = pipe(caseStudyTaggedC, c => new t.Type<CaseStudy, CaseStudyTagged>(
  `CaseStudy`,
  (u: unknown): u is CaseStudy => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CaseStudy> => pipe(c.decode(u), E.map(x => ({ ...x, ...caseStudy }))),
  (x: CaseStudy): CaseStudyTagged => ({ ...x, _tag: `CaseStudy`}),
));
export type CaseStudyC = typeof caseStudyC;


export const researchSlashWhitePapers = {
  _tag: `ResearchSlashWhitePapers`,
  id: 5,
  name: `Research / White Papers`
} as const;

export const researchSlashWhitePapersTaggedC = t.type({
  _tag: t.literal(`ResearchSlashWhitePapers`)
});
export type ResearchSlashWhitePapersTaggedC = typeof researchSlashWhitePapersTaggedC;
export type ResearchSlashWhitePapersTagged = t.TypeOf<ResearchSlashWhitePapersTaggedC>;
export type ResearchSlashWhitePapers = ResearchSlashWhitePapersTagged & typeof researchSlashWhitePapers;
export const researchSlashWhitePapersC = pipe(researchSlashWhitePapersTaggedC, c => new t.Type<ResearchSlashWhitePapers, ResearchSlashWhitePapersTagged>(
  `ResearchSlashWhitePapers`,
  (u: unknown): u is ResearchSlashWhitePapers => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ResearchSlashWhitePapers> => pipe(c.decode(u), E.map(x => ({ ...x, ...researchSlashWhitePapers }))),
  (x: ResearchSlashWhitePapers): ResearchSlashWhitePapersTagged => ({ ...x, _tag: `ResearchSlashWhitePapers`}),
));
export type ResearchSlashWhitePapersC = typeof researchSlashWhitePapersC;


export const userGuides = {
  _tag: `UserGuides`,
  id: 2,
  name: `User Guides`
} as const;

export const userGuidesTaggedC = t.type({
  _tag: t.literal(`UserGuides`)
});
export type UserGuidesTaggedC = typeof userGuidesTaggedC;
export type UserGuidesTagged = t.TypeOf<UserGuidesTaggedC>;
export type UserGuides = UserGuidesTagged & typeof userGuides;
export const userGuidesC = pipe(userGuidesTaggedC, c => new t.Type<UserGuides, UserGuidesTagged>(
  `UserGuides`,
  (u: unknown): u is UserGuides => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserGuides> => pipe(c.decode(u), E.map(x => ({ ...x, ...userGuides }))),
  (x: UserGuides): UserGuidesTagged => ({ ...x, _tag: `UserGuides`}),
));
export type UserGuidesC = typeof userGuidesC;


export const dataSheet = {
  _tag: `DataSheet`,
  id: 6,
  name: `Data Sheet`
} as const;

export const dataSheetTaggedC = t.type({
  _tag: t.literal(`DataSheet`)
});
export type DataSheetTaggedC = typeof dataSheetTaggedC;
export type DataSheetTagged = t.TypeOf<DataSheetTaggedC>;
export type DataSheet = DataSheetTagged & typeof dataSheet;
export const dataSheetC = pipe(dataSheetTaggedC, c => new t.Type<DataSheet, DataSheetTagged>(
  `DataSheet`,
  (u: unknown): u is DataSheet => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DataSheet> => pipe(c.decode(u), E.map(x => ({ ...x, ...dataSheet }))),
  (x: DataSheet): DataSheetTagged => ({ ...x, _tag: `DataSheet`}),
));
export type DataSheetC = typeof dataSheetC;


export const other = {
  _tag: `Other`,
  id: 7,
  name: `Other`
} as const;

export const otherTaggedC = t.type({
  _tag: t.literal(`Other`)
});
export type OtherTaggedC = typeof otherTaggedC;
export type OtherTagged = t.TypeOf<OtherTaggedC>;
export type Other = OtherTagged & typeof other;
export const otherC = pipe(otherTaggedC, c => new t.Type<Other, OtherTagged>(
  `Other`,
  (u: unknown): u is Other => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Other> => pipe(c.decode(u), E.map(x => ({ ...x, ...other }))),
  (x: Other): OtherTagged => ({ ...x, _tag: `Other`}),
));
export type OtherC = typeof otherC;


export const allBondLinkResourceTypeC = [bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC, userGuidesC, dataSheetC, otherC] as const;
export const allBondLinkResourceTypeNames = [`BestPractices`, `AroundTheNetwork`, `CaseStudy`, `ResearchSlashWhitePapers`, `UserGuides`, `DataSheet`, `Other`] as const;
export type BondLinkResourceTypeName = (typeof allBondLinkResourceTypeNames)[number];

export const BondLinkResourceTypeCU = t.union([bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC, userGuidesC, dataSheetC, otherC]);
export type BondLinkResourceTypeCU = typeof BondLinkResourceTypeCU;
export type BondLinkResourceTypeU = t.TypeOf<BondLinkResourceTypeCU>;

export const bondLinkResourceTypeOrd: Ord.Ord<BondLinkResourceTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceType = [bestPractices, aroundTheNetwork, caseStudy, researchSlashWhitePapers, userGuides, dataSheet, other] as const;
export type BondLinkResourceTypeMap<A> = { [K in BondLinkResourceTypeName]: A };


export const allBondLinkResourceBestPracticesTypeC = [bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC] as const;
export const allBondLinkResourceBestPracticesTypeNames = [`BestPractices`, `AroundTheNetwork`, `CaseStudy`, `ResearchSlashWhitePapers`] as const;
export type BondLinkResourceBestPracticesTypeName = (typeof allBondLinkResourceBestPracticesTypeNames)[number];

export const BondLinkResourceBestPracticesTypeCU = t.union([bestPracticesC, aroundTheNetworkC, caseStudyC, researchSlashWhitePapersC]);
export type BondLinkResourceBestPracticesTypeCU = typeof BondLinkResourceBestPracticesTypeCU;
export type BondLinkResourceBestPracticesTypeU = t.TypeOf<BondLinkResourceBestPracticesTypeCU>;

export const bondLinkResourceBestPracticesTypeOrd: Ord.Ord<BondLinkResourceBestPracticesTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceBestPracticesType = [bestPractices, aroundTheNetwork, caseStudy, researchSlashWhitePapers] as const;
export type BondLinkResourceBestPracticesTypeMap<A> = { [K in BondLinkResourceBestPracticesTypeName]: A };


export const allBondLinkResourceUserGuidesTypeC = [userGuidesC, dataSheetC, otherC] as const;
export const allBondLinkResourceUserGuidesTypeNames = [`UserGuides`, `DataSheet`, `Other`] as const;
export type BondLinkResourceUserGuidesTypeName = (typeof allBondLinkResourceUserGuidesTypeNames)[number];

export const BondLinkResourceUserGuidesTypeCU = t.union([userGuidesC, dataSheetC, otherC]);
export type BondLinkResourceUserGuidesTypeCU = typeof BondLinkResourceUserGuidesTypeCU;
export type BondLinkResourceUserGuidesTypeU = t.TypeOf<BondLinkResourceUserGuidesTypeCU>;

export const bondLinkResourceUserGuidesTypeOrd: Ord.Ord<BondLinkResourceUserGuidesTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBondLinkResourceUserGuidesType = [userGuides, dataSheet, other] as const;
export type BondLinkResourceUserGuidesTypeMap<A> = { [K in BondLinkResourceUserGuidesTypeName]: A };


