import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const ASC = {
  _tag: `ASC`,
  dir: `asc`
} as const;

export const ASCTaggedC = t.type({
  _tag: t.literal(`ASC`)
});
export type ASCTaggedC = typeof ASCTaggedC;
export type ASCTagged = t.TypeOf<ASCTaggedC>;
export type ASC = ASCTagged & typeof ASC;
export const ASCC = pipe(ASCTaggedC, c => new t.Type<ASC, ASCTagged>(
  `ASC`,
  (u: unknown): u is ASC => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ASC> => pipe(c.decode(u), E.map(x => ({ ...x, ...ASC }))),
  (x: ASC): ASCTagged => ({ ...x, _tag: `ASC`}),
));
export type ASCC = typeof ASCC;


export const DESC = {
  _tag: `DESC`,
  dir: `desc`
} as const;

export const DESCTaggedC = t.type({
  _tag: t.literal(`DESC`)
});
export type DESCTaggedC = typeof DESCTaggedC;
export type DESCTagged = t.TypeOf<DESCTaggedC>;
export type DESC = DESCTagged & typeof DESC;
export const DESCC = pipe(DESCTaggedC, c => new t.Type<DESC, DESCTagged>(
  `DESC`,
  (u: unknown): u is DESC => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DESC> => pipe(c.decode(u), E.map(x => ({ ...x, ...DESC }))),
  (x: DESC): DESCTagged => ({ ...x, _tag: `DESC`}),
));
export type DESCC = typeof DESCC;


export const allSortDirC = [ASCC, DESCC] as const;
export const allSortDirNames = [`ASC`, `DESC`] as const;
export type SortDirName = (typeof allSortDirNames)[number];

export const SortDirCU = t.union([ASCC, DESCC]);
export type SortDirCU = typeof SortDirCU;
export type SortDirU = t.TypeOf<SortDirCU>;

export const sortDirOrd: Ord.Ord<SortDirU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSortDir = [ASC, DESC] as const;
export type SortDirMap<A> = { [K in SortDirName]: A };


