import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { BLConfig } from "@scripts/generated/models/blConfig";

import type { Method, UrlInterface } from "../routes/urlInterface";
import { updProp } from "./updProp";

export const originFromUrl = (url: string) => new URL(url).origin;

type DeepLinkRoute<T extends "Basic" | "Modal" | "DataTable", A> = {
  route: (a: A) => UrlInterface<"GET">;
  type: T;
  // element identifier, it can be a container ID, or a modal name, or a data table id.
  selector: string;
};
type DeepLinkBasic<A> = DeepLinkRoute<"Basic", A>;
type DeepLinkModal<A> = DeepLinkRoute<"Modal", A> & {
  // itemId should be set when the page has the same modal for different records.
  // Example: domain.com/page#modal~about-{001}, this will open the modal [about] for the record with id [001].
  itemId: O.Option<string | number>;
};
type DeepLinkDataTable<A> = DeepLinkRoute<"DataTable", A> & {
  // rowId should match the record id and the row (tr) id html attribute;
  rowId: string;
  // Link to a table action: for example "edit" or "delete"
  action: O.Option<string>;
};

export type DeepLink<A> = DeepLinkBasic<A> | DeepLinkModal<A> | DeepLinkDataTable<A>;

export const deepLink = <A>(dl: DeepLink<A>) => (args: A): UrlInterface<"GET"> => {
  switch (dl.type) {
    case "Basic":
      return ({ method: "GET", url: `${dl.route(args).url}#${dl.selector}` });
    case "Modal":
      return ({
        method: "GET",
        url: pipe(
          `${dl.route(args).url}#modal~${dl.selector}`,
          (url: string) => O.fold(() => url, (itemId: string | number) => `${url}-${itemId}`)(dl.itemId)
        ),
      });
    case "DataTable":
      return ({
        method: "GET",
        url: pipe(
          `${dl.route(args).url}#dt~${dl.selector}~${dl.rowId}`,
          (url: string) => O.fold(() => url, (action: string) => `${url}~${action}`)(dl.action)
        ),
      });
  }
};

export const absoluteUrl = (config: Pick<BLConfig, "baseUrl">): <M extends Method, U extends UrlInterface<M>>(route: U) => U =>
  updProp("url")(u => `${config.baseUrl}${u}`);
