import type { ForwardRefExoticComponent, PropsWithChildren, ReactNode, RefAttributes } from "react";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

import type { AlertTypeMap, AlertTypeU } from "@scripts/generated/domaintables/alertTypes";
import type { Flash } from "@scripts/generated/models/flash";
import { Svg } from "@scripts/react/components/Svg";
import { klass, type KlassProp, klassPropO } from "@scripts/react/util/classnames";
import { idFromFlash } from "@scripts/syntax/flash";

import { alerts as a } from "@styles/components/_alerts";

import type { SVGString } from "@svgs/checkmark.svg";
import checkmark from "@svgs/checkmark.svg";
import information from "@svgs/information.svg";
import warning from "@svgs/warning.svg";

import type { CloseXButtonProps } from "./Button";
import { CloseXButton } from "./Button";
import { mapOrEmpty } from "./Empty";
import { UnsafeContent } from "./UnsafeContent";

type AlertBaseProps = { type: AlertTypeU, children: ReactNode, id?: string, klasses?: KlassProp };

export type AlertPushDownProps = { id: string } & Pick<AlertBaseProps, "type" | "children">;
type AlertPushDownRemoveAction = { removeAction: (id: string) => void };

export type AlertProps = {
  icon: boolean;
  pill: boolean;
  removeAction?: () => void;
} & AlertBaseProps;

const alertTypeToStyle = (type: AlertTypeU) => `alert-${type._tag.toLowerCase()}`;

export const alertFromFlash = (flash: Flash): AlertPushDownProps => ({
  id: idFromFlash(flash),
  type: flash.type,
  children: <UnsafeContent content={flash.msg} />,
});

export const iconStyle: AlertTypeMap<SVGString> = {
  "Success": checkmark,
  "Info": information,
  "Warning": warning,
  "Danger": warning,
};

const AlertBase = (props: AlertBaseProps) => (
  <div
    key={props.id}
    {...klassPropO([
      a[".alert"],
      alertTypeToStyle(props.type),
      "fade",
      "show",
    ])(props.klasses)}
    role="alert"
  >
    {props.children}
  </div>
);

const AlertBody = (props: PropsWithChildren<object>) => (
  <div {...klass(a[".alert"][".alert-body"])}>
    {props.children}
  </div>
);

const AlertIcon = (props: { type: AlertTypeU }) => <Svg src={iconStyle[props.type._tag]} />;

export type CloseButton = ForwardRefExoticComponent<CloseXButtonProps & RefAttributes<HTMLButtonElement>>;

export const AlertPushDownBase = (props: AlertPushDownProps & AlertPushDownRemoveAction & { closeButton: CloseButton }) => (
  <AlertBase
    id={props.id}
    type={props.type}
    klasses={a[".alert-pushdown"]}
  >
    <AlertBody>
      {props.children}
    </AlertBody>
    <props.closeButton onClick={() => props.removeAction(props.id)} />
  </AlertBase>
);

export const AlertPushDown = (props: AlertPushDownProps & AlertPushDownRemoveAction) => (
  <AlertPushDownBase {...props} closeButton={CloseXButton} />
);

export const Alert = (props: AlertProps) => (
  <AlertBase
    id={props.id}
    type={props.type}
    klasses={klassPropO(props.pill ? O.some(a[".alert"].attrs[".pill"]) : O.none)(props.klasses).className}
  >
    {props.icon && (
      <div {...klass(a[".alert"][".alert-icon"])}>
        <AlertIcon type={props.type} />
      </div>
    )}
    <AlertBody>
      {props.children}
    </AlertBody>
    {pipe(
      props.removeAction,
      O.fromNullable,
      mapOrEmpty(c => <CloseXButton onClick={c} />)
    )}
  </AlertBase>
);

export const AlertSubtle = (props: AlertBaseProps & { title: O.Option<string> }) => (
  <AlertBase
    id={props.id}
    type={props.type}
    klasses={klassPropO(a[".alert-subtle"])(props.klasses).className}
  >
    {pipe(props.title, mapOrEmpty(_ =>
      <div {...klass(a[".alert-subtle"][".title"])}>
        <AlertIcon type={props.type} />
        {_}
      </div>
    ))}
    <AlertBody>
      {props.children}
    </AlertBody>
  </AlertBase>
);
