import type { ReactElement } from "react";
import { renderToString } from "react-dom/server";
import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";
import type { Action } from "redux";

import type { BLConfigWithLog } from "@scripts/bondlink";
import { RA } from "@scripts/fp-ts";
import type { Flash } from "@scripts/generated/models/flash";
import { idFromFlash } from "@scripts/syntax/flash";

type ComponentFlash = Omit<Flash, "msg"> & {
  msg: ReactElement;
};

const serializeFlash = (flash: ComponentFlash): Flash => ({
  ...flash,
  msg: renderToString(flash.msg),
});

export const flashAdd = (flash: ComponentFlash): Action<"FLASH_ADD"> & { flash: ComponentFlash } => {
  return {
    type: "FLASH_ADD",
    flash,
  };
};

export const flashRemove = (id: string): Action<"FLASH_REMOVE"> & { id: string } => ({
  type: "FLASH_REMOVE",
  id,
});

export const flashRemoveAll = (): Action<"FLASH_REMOVE_ALL"> => ({
  type: "FLASH_REMOVE_ALL",
});

export const flashReducer = (config: BLConfigWithLog) =>
  (state: ReadonlyArray<Flash>, action: FlashAction): ReadonlyArray<Flash> => {
    switch (action.type) {
      case "FLASH_ADD":
        return ((s: ReadonlyArray<Flash>) => {
          const flashSerialized = serializeFlash(action.flash);
          return pipe(
            s,
            RA.findIndex(c => idFromFlash(c) === idFromFlash(flashSerialized)),
            O.fold(
              () => RA.append(flashSerialized)(state),
              () => state,
            )
          );
        })(state);
      case "FLASH_REMOVE":
        return state.filter(a => idFromFlash(a) !== action.id);
      case "FLASH_REMOVE_ALL":
        return [];
    }
    return config.exhaustive(action);
  };

export type FlashAdd = ReturnType<typeof flashAdd>;
export type FlashRemove = ReturnType<typeof flashRemove>;
export type FlashRemoveAll = ReturnType<typeof flashRemoveAll>;

export type FlashAction = FlashAdd | FlashRemove | FlashRemoveAll;
