import { useState } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";

import { E, O } from "@scripts/fp-ts";
import { success } from "@scripts/generated/domaintables/alertTypes";
import type { MfaInitiated, RedirectC } from "@scripts/generated/domaintables/loginStatus";
import type { VerifyTotpPostC } from "@scripts/generated/models/userSettings";
import * as V2Router from "@scripts/generated/routers/v2Router";
import { ResendCodeForm } from "@scripts/react/actions/forms/account-settings/security/Verification";
import { ContactBondLinkAnchor } from "@scripts/react/components/Anchor";
import { ButtonSubmit } from "@scripts/react/components/Button";
import { CustomIndicator } from "@scripts/react/components/form/CustomIndicator";
import { Form } from "@scripts/react/components/form/Form";
import { Input } from "@scripts/react/components/form/TextInput";
import { booleanC, stringC } from "@scripts/react/form/codecs";
import type { FormSuccessAction } from "@scripts/react/form/form";
import { emptyFormState, formDataLens, formLens } from "@scripts/react/form/form";
import { flashAdd } from "@scripts/react/state/flash";
import { klass } from "@scripts/react/util/classnames";

import { MinimalFormContainer, MinimalHeader } from "@scripts-ssr/components/MinimalLayout";

import { usePagesDispatch } from "../../state/store";

const verfiyFormL = formLens<VerifyTotpPostC>();
const verfiyFormDL = formDataLens<VerifyTotpPostC>();

const makeInitialState = (verification: MfaInitiated, showRememberCheckbox: boolean) => pipe(
  emptyFormState<VerifyTotpPostC>(),
  verfiyFormDL.compose(verfiyFormL("userId")).set(verification.userId),
  verfiyFormDL.compose(verfiyFormL("verificationId")).set(verification.verificationId),
  verfiyFormDL.compose(verfiyFormL("redirect")).set(verification.redirect),
  verfiyFormDL.compose(verfiyFormL("remember")).set(showRememberCheckbox),
);

export const HavingTroubleLoggingIn = () =>
  <div>
    Having trouble logging in? <ContactBondLinkAnchor title="Contact support" />
  </div>;

export const MFAVerification = (props: {
  mfaInitiated: MfaInitiated;
  onSuccess: FormSuccessAction<VerifyTotpPostC, RedirectC>;
  showRememberCheckbox: boolean;
}) => {
  const [verificationFormState, setVerificationFormState] =
    useState(makeInitialState(props.mfaInitiated, props.showRememberCheckbox));
  const dispatch = usePagesDispatch();

  return (
    <>
      <MinimalHeader text="Enter verification code" />
      <p {...klass("mb-15")}>
        We’ve sent a verification code via text message to the cell
        phone number ending in <strong>{props.mfaInitiated.phoneLineNumber}</strong>.
        This code expires in 5 minutes. Please enter the code below to log in.
      </p>
      <MinimalFormContainer>
        <Form
          url={V2Router.baseAuthControllerVerifyTotp()}
          state={verificationFormState}
          setState={setVerificationFormState}
          onSuccess={props.onSuccess}
          onFailure={O.none}
          headers={O.none}
        >
          <Input
            state={verificationFormState}
            setState={setVerificationFormState}
            type="text"
            placeholder={O.none}
            labelOrAriaLabel={E.left("Verification Code")}
            lens={verfiyFormL("totp")}
            codec={stringC}
          />
          {props.showRememberCheckbox && (
            <CustomIndicator
              state={verificationFormState}
              setState={setVerificationFormState}
              lens={verfiyFormL("remember")}
              codec={booleanC}
              name="remember"
              type="checkbox"
              options={[{
                label: O.some("Remember me on this device for 90 days"),
                value: true,
              }]}
              unselectedValue={O.some(false)}
              analyticsScope={O.none}
              ariaUId={O.none}
            />
          )}
          <ButtonSubmit
            {...klass("w-100", "mt-0")}
            loading={verificationFormState.loading}
            loadingText={"Submitting"}
            onClick={constVoid}
            text={"Submit"}
          />
        </Form>
        <div {...klass("form-footer-container")}>
          <div {...klass("mt-15")}>
            <ResendCodeForm
              klasses={["d-flex", "flex-col", "flex-sm-row", "justify-content-center", "align-items-center", "mb-1"]}
              onSuccess={() => {
                dispatch(flashAdd({
                  type: success,
                  safe: true,
                  msg: <>A new verification code has been sent to your phone.</>,
                }));
              }}
              verification={props.mfaInitiated}
            />
          </div>
          <HavingTroubleLoggingIn />
        </div>
      </MinimalFormContainer>
    </>
  );
};
