import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import * as Ord from "fp-ts/lib/Ord";
import { esgProgram as imported4_esgProgram } from "./featureFlags";

export const ESG = {
  _tag: `ESG`,
  enablingFlag: imported4_esgProgram,
  id: 1
} as const;

export const ESGTaggedC = t.type({
  _tag: t.literal(`ESG`)
});
export type ESGTaggedC = typeof ESGTaggedC;
export type ESGTagged = t.TypeOf<ESGTaggedC>;
export type ESG = ESGTagged & typeof ESG;
export const ESGC = pipe(ESGTaggedC, c => new t.Type<ESG, ESGTagged>(
  `ESG`,
  (u: unknown): u is ESG => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ESG> => pipe(c.decode(u), E.map(x => ({ ...x, ...ESG }))),
  (x: ESG): ESGTagged => ({ ...x, _tag: `ESG`}),
));
export type ESGC = typeof ESGC;


export const allContentTagTypeC = [ESGC] as const;
export const allContentTagTypeNames = [`ESG`] as const;
export type ContentTagTypeName = (typeof allContentTagTypeNames)[number];

export const ContentTagTypeCU = ESGC;
export type ContentTagTypeCU = typeof ContentTagTypeCU;
export type ContentTagTypeU = t.TypeOf<ContentTagTypeCU>;

export const contentTagTypeOrd: Ord.Ord<ContentTagTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allContentTagType = [ESG] as const;
export type ContentTagTypeMap<A> = { [K in ContentTagTypeName]: A };


export const contentTagsC = readonlySetFromArray(ContentTagTypeCU, contentTagTypeOrd);
export type ContentTagsC = typeof contentTagsC;
export type ContentTags = t.TypeOf<ContentTagsC>;


