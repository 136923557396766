import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const name = {
  _tag: `Name`,
  id: 1,
  name: `Name`
} as const;

export const nameTaggedC = t.type({
  _tag: t.literal(`Name`)
});
export type NameTaggedC = typeof nameTaggedC;
export type NameTagged = t.TypeOf<NameTaggedC>;
export type Name = NameTagged & typeof name;
export const nameC = pipe(nameTaggedC, c => new t.Type<Name, NameTagged>(
  `Name`,
  (u: unknown): u is Name => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Name> => pipe(c.decode(u), E.map(x => ({ ...x, ...name }))),
  (x: Name): NameTagged => ({ ...x, _tag: `Name`}),
));
export type NameC = typeof nameC;


export const emailAddress = {
  _tag: `EmailAddress`,
  id: 2,
  name: `Email address`
} as const;

export const emailAddressTaggedC = t.type({
  _tag: t.literal(`EmailAddress`)
});
export type EmailAddressTaggedC = typeof emailAddressTaggedC;
export type EmailAddressTagged = t.TypeOf<EmailAddressTaggedC>;
export type EmailAddress = EmailAddressTagged & typeof emailAddress;
export const emailAddressC = pipe(emailAddressTaggedC, c => new t.Type<EmailAddress, EmailAddressTagged>(
  `EmailAddress`,
  (u: unknown): u is EmailAddress => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailAddress> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailAddress }))),
  (x: EmailAddress): EmailAddressTagged => ({ ...x, _tag: `EmailAddress`}),
));
export type EmailAddressC = typeof emailAddressC;


export const phoneNumber = {
  _tag: `PhoneNumber`,
  id: 3,
  name: `Phone number`
} as const;

export const phoneNumberTaggedC = t.type({
  _tag: t.literal(`PhoneNumber`)
});
export type PhoneNumberTaggedC = typeof phoneNumberTaggedC;
export type PhoneNumberTagged = t.TypeOf<PhoneNumberTaggedC>;
export type PhoneNumber = PhoneNumberTagged & typeof phoneNumber;
export const phoneNumberC = pipe(phoneNumberTaggedC, c => new t.Type<PhoneNumber, PhoneNumberTagged>(
  `PhoneNumber`,
  (u: unknown): u is PhoneNumber => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PhoneNumber> => pipe(c.decode(u), E.map(x => ({ ...x, ...phoneNumber }))),
  (x: PhoneNumber): PhoneNumberTagged => ({ ...x, _tag: `PhoneNumber`}),
));
export type PhoneNumberC = typeof phoneNumberC;


export const organization = {
  _tag: `Organization`,
  id: 4,
  name: `Organization`
} as const;

export const organizationTaggedC = t.type({
  _tag: t.literal(`Organization`)
});
export type OrganizationTaggedC = typeof organizationTaggedC;
export type OrganizationTagged = t.TypeOf<OrganizationTaggedC>;
export type Organization = OrganizationTagged & typeof organization;
export const organizationC = pipe(organizationTaggedC, c => new t.Type<Organization, OrganizationTagged>(
  `Organization`,
  (u: unknown): u is Organization => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Organization> => pipe(c.decode(u), E.map(x => ({ ...x, ...organization }))),
  (x: Organization): OrganizationTagged => ({ ...x, _tag: `Organization`}),
));
export type OrganizationC = typeof organizationC;


export const title = {
  _tag: `Title`,
  id: 5,
  name: `Title`
} as const;

export const titleTaggedC = t.type({
  _tag: t.literal(`Title`)
});
export type TitleTaggedC = typeof titleTaggedC;
export type TitleTagged = t.TypeOf<TitleTaggedC>;
export type Title = TitleTagged & typeof title;
export const titleC = pipe(titleTaggedC, c => new t.Type<Title, TitleTagged>(
  `Title`,
  (u: unknown): u is Title => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Title> => pipe(c.decode(u), E.map(x => ({ ...x, ...title }))),
  (x: Title): TitleTagged => ({ ...x, _tag: `Title`}),
));
export type TitleC = typeof titleC;


export const userType = {
  _tag: `UserType`,
  id: 6,
  name: `User type`
} as const;

export const userTypeTaggedC = t.type({
  _tag: t.literal(`UserType`)
});
export type UserTypeTaggedC = typeof userTypeTaggedC;
export type UserTypeTagged = t.TypeOf<UserTypeTaggedC>;
export type UserType = UserTypeTagged & typeof userType;
export const userTypeC = pipe(userTypeTaggedC, c => new t.Type<UserType, UserTypeTagged>(
  `UserType`,
  (u: unknown): u is UserType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, UserType> => pipe(c.decode(u), E.map(x => ({ ...x, ...userType }))),
  (x: UserType): UserTypeTagged => ({ ...x, _tag: `UserType`}),
));
export type UserTypeC = typeof userTypeC;


export const institutionalInvestorType = {
  _tag: `InstitutionalInvestorType`,
  id: 7,
  name: `Institutional investor type`
} as const;

export const institutionalInvestorTypeTaggedC = t.type({
  _tag: t.literal(`InstitutionalInvestorType`)
});
export type InstitutionalInvestorTypeTaggedC = typeof institutionalInvestorTypeTaggedC;
export type InstitutionalInvestorTypeTagged = t.TypeOf<InstitutionalInvestorTypeTaggedC>;
export type InstitutionalInvestorType = InstitutionalInvestorTypeTagged & typeof institutionalInvestorType;
export const institutionalInvestorTypeC = pipe(institutionalInvestorTypeTaggedC, c => new t.Type<InstitutionalInvestorType, InstitutionalInvestorTypeTagged>(
  `InstitutionalInvestorType`,
  (u: unknown): u is InstitutionalInvestorType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InstitutionalInvestorType> => pipe(c.decode(u), E.map(x => ({ ...x, ...institutionalInvestorType }))),
  (x: InstitutionalInvestorType): InstitutionalInvestorTypeTagged => ({ ...x, _tag: `InstitutionalInvestorType`}),
));
export type InstitutionalInvestorTypeC = typeof institutionalInvestorTypeC;


export const productFocus = {
  _tag: `ProductFocus`,
  id: 8,
  name: `Product focus`
} as const;

export const productFocusTaggedC = t.type({
  _tag: t.literal(`ProductFocus`)
});
export type ProductFocusTaggedC = typeof productFocusTaggedC;
export type ProductFocusTagged = t.TypeOf<ProductFocusTaggedC>;
export type ProductFocus = ProductFocusTagged & typeof productFocus;
export const productFocusC = pipe(productFocusTaggedC, c => new t.Type<ProductFocus, ProductFocusTagged>(
  `ProductFocus`,
  (u: unknown): u is ProductFocus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProductFocus> => pipe(c.decode(u), E.map(x => ({ ...x, ...productFocus }))),
  (x: ProductFocus): ProductFocusTagged => ({ ...x, _tag: `ProductFocus`}),
));
export type ProductFocusC = typeof productFocusC;


export const sectorFocus = {
  _tag: `SectorFocus`,
  id: 9,
  name: `Sector focus`
} as const;

export const sectorFocusTaggedC = t.type({
  _tag: t.literal(`SectorFocus`)
});
export type SectorFocusTaggedC = typeof sectorFocusTaggedC;
export type SectorFocusTagged = t.TypeOf<SectorFocusTaggedC>;
export type SectorFocus = SectorFocusTagged & typeof sectorFocus;
export const sectorFocusC = pipe(sectorFocusTaggedC, c => new t.Type<SectorFocus, SectorFocusTagged>(
  `SectorFocus`,
  (u: unknown): u is SectorFocus => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SectorFocus> => pipe(c.decode(u), E.map(x => ({ ...x, ...sectorFocus }))),
  (x: SectorFocus): SectorFocusTagged => ({ ...x, _tag: `SectorFocus`}),
));
export type SectorFocusC = typeof sectorFocusC;


export const allActivityReportProfileDataPointC = [nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC] as const;
export const allActivityReportProfileDataPointNames = [`Name`, `EmailAddress`, `PhoneNumber`, `Organization`, `Title`, `UserType`, `InstitutionalInvestorType`, `ProductFocus`, `SectorFocus`] as const;
export type ActivityReportProfileDataPointName = (typeof allActivityReportProfileDataPointNames)[number];

export const ActivityReportProfileDataPointCU = t.union([nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC]);
export type ActivityReportProfileDataPointCU = typeof ActivityReportProfileDataPointCU;
export type ActivityReportProfileDataPointU = t.TypeOf<ActivityReportProfileDataPointCU>;

export const activityReportProfileDataPointOrd: Ord.Ord<ActivityReportProfileDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportProfileDataPoint = [name, emailAddress, phoneNumber, organization, title, userType, institutionalInvestorType, productFocus, sectorFocus] as const;
export type ActivityReportProfileDataPointMap<A> = { [K in ActivityReportProfileDataPointName]: A };


export const engagementScore = {
  _tag: `EngagementScore`,
  id: 10,
  name: `Engagement score`
} as const;

export const engagementScoreTaggedC = t.type({
  _tag: t.literal(`EngagementScore`)
});
export type EngagementScoreTaggedC = typeof engagementScoreTaggedC;
export type EngagementScoreTagged = t.TypeOf<EngagementScoreTaggedC>;
export type EngagementScore = EngagementScoreTagged & typeof engagementScore;
export const engagementScoreC = pipe(engagementScoreTaggedC, c => new t.Type<EngagementScore, EngagementScoreTagged>(
  `EngagementScore`,
  (u: unknown): u is EngagementScore => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EngagementScore> => pipe(c.decode(u), E.map(x => ({ ...x, ...engagementScore }))),
  (x: EngagementScore): EngagementScoreTagged => ({ ...x, _tag: `EngagementScore`}),
));
export type EngagementScoreC = typeof engagementScoreC;


export const lastActivityDate = {
  _tag: `LastActivityDate`,
  id: 11,
  name: `Date of last activity`
} as const;

export const lastActivityDateTaggedC = t.type({
  _tag: t.literal(`LastActivityDate`)
});
export type LastActivityDateTaggedC = typeof lastActivityDateTaggedC;
export type LastActivityDateTagged = t.TypeOf<LastActivityDateTaggedC>;
export type LastActivityDate = LastActivityDateTagged & typeof lastActivityDate;
export const lastActivityDateC = pipe(lastActivityDateTaggedC, c => new t.Type<LastActivityDate, LastActivityDateTagged>(
  `LastActivityDate`,
  (u: unknown): u is LastActivityDate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, LastActivityDate> => pipe(c.decode(u), E.map(x => ({ ...x, ...lastActivityDate }))),
  (x: LastActivityDate): LastActivityDateTagged => ({ ...x, _tag: `LastActivityDate`}),
));
export type LastActivityDateC = typeof lastActivityDateC;


export const offeringViews = {
  _tag: `OfferingViews`,
  id: 12,
  name: `Bond offering views`
} as const;

export const offeringViewsTaggedC = t.type({
  _tag: t.literal(`OfferingViews`)
});
export type OfferingViewsTaggedC = typeof offeringViewsTaggedC;
export type OfferingViewsTagged = t.TypeOf<OfferingViewsTaggedC>;
export type OfferingViews = OfferingViewsTagged & typeof offeringViews;
export const offeringViewsC = pipe(offeringViewsTaggedC, c => new t.Type<OfferingViews, OfferingViewsTagged>(
  `OfferingViews`,
  (u: unknown): u is OfferingViews => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, OfferingViews> => pipe(c.decode(u), E.map(x => ({ ...x, ...offeringViews }))),
  (x: OfferingViews): OfferingViewsTagged => ({ ...x, _tag: `OfferingViews`}),
));
export type OfferingViewsC = typeof offeringViewsC;


export const roadshowViews = {
  _tag: `RoadshowViews`,
  id: 13,
  name: `Roadshow views`
} as const;

export const roadshowViewsTaggedC = t.type({
  _tag: t.literal(`RoadshowViews`)
});
export type RoadshowViewsTaggedC = typeof roadshowViewsTaggedC;
export type RoadshowViewsTagged = t.TypeOf<RoadshowViewsTaggedC>;
export type RoadshowViews = RoadshowViewsTagged & typeof roadshowViews;
export const roadshowViewsC = pipe(roadshowViewsTaggedC, c => new t.Type<RoadshowViews, RoadshowViewsTagged>(
  `RoadshowViews`,
  (u: unknown): u is RoadshowViews => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadshowViews> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadshowViews }))),
  (x: RoadshowViews): RoadshowViewsTagged => ({ ...x, _tag: `RoadshowViews`}),
));
export type RoadshowViewsC = typeof roadshowViewsC;


export const documentDownloads = {
  _tag: `DocumentDownloads`,
  id: 14,
  name: `Document downloads`
} as const;

export const documentDownloadsTaggedC = t.type({
  _tag: t.literal(`DocumentDownloads`)
});
export type DocumentDownloadsTaggedC = typeof documentDownloadsTaggedC;
export type DocumentDownloadsTagged = t.TypeOf<DocumentDownloadsTaggedC>;
export type DocumentDownloads = DocumentDownloadsTagged & typeof documentDownloads;
export const documentDownloadsC = pipe(documentDownloadsTaggedC, c => new t.Type<DocumentDownloads, DocumentDownloadsTagged>(
  `DocumentDownloads`,
  (u: unknown): u is DocumentDownloads => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, DocumentDownloads> => pipe(c.decode(u), E.map(x => ({ ...x, ...documentDownloads }))),
  (x: DocumentDownloads): DocumentDownloadsTagged => ({ ...x, _tag: `DocumentDownloads`}),
));
export type DocumentDownloadsC = typeof documentDownloadsC;


export const infoRequests = {
  _tag: `InfoRequests`,
  id: 15,
  name: `Information requests`
} as const;

export const infoRequestsTaggedC = t.type({
  _tag: t.literal(`InfoRequests`)
});
export type InfoRequestsTaggedC = typeof infoRequestsTaggedC;
export type InfoRequestsTagged = t.TypeOf<InfoRequestsTaggedC>;
export type InfoRequests = InfoRequestsTagged & typeof infoRequests;
export const infoRequestsC = pipe(infoRequestsTaggedC, c => new t.Type<InfoRequests, InfoRequestsTagged>(
  `InfoRequests`,
  (u: unknown): u is InfoRequests => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, InfoRequests> => pipe(c.decode(u), E.map(x => ({ ...x, ...infoRequests }))),
  (x: InfoRequests): InfoRequestsTagged => ({ ...x, _tag: `InfoRequests`}),
));
export type InfoRequestsC = typeof infoRequestsC;


export const emailsReceived = {
  _tag: `EmailsReceived`,
  id: 16,
  name: `Emails received`
} as const;

export const emailsReceivedTaggedC = t.type({
  _tag: t.literal(`EmailsReceived`)
});
export type EmailsReceivedTaggedC = typeof emailsReceivedTaggedC;
export type EmailsReceivedTagged = t.TypeOf<EmailsReceivedTaggedC>;
export type EmailsReceived = EmailsReceivedTagged & typeof emailsReceived;
export const emailsReceivedC = pipe(emailsReceivedTaggedC, c => new t.Type<EmailsReceived, EmailsReceivedTagged>(
  `EmailsReceived`,
  (u: unknown): u is EmailsReceived => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsReceived> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsReceived }))),
  (x: EmailsReceived): EmailsReceivedTagged => ({ ...x, _tag: `EmailsReceived`}),
));
export type EmailsReceivedC = typeof emailsReceivedC;


export const emailsOpened = {
  _tag: `EmailsOpened`,
  id: 17,
  name: `Emails opened`
} as const;

export const emailsOpenedTaggedC = t.type({
  _tag: t.literal(`EmailsOpened`)
});
export type EmailsOpenedTaggedC = typeof emailsOpenedTaggedC;
export type EmailsOpenedTagged = t.TypeOf<EmailsOpenedTaggedC>;
export type EmailsOpened = EmailsOpenedTagged & typeof emailsOpened;
export const emailsOpenedC = pipe(emailsOpenedTaggedC, c => new t.Type<EmailsOpened, EmailsOpenedTagged>(
  `EmailsOpened`,
  (u: unknown): u is EmailsOpened => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsOpened> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsOpened }))),
  (x: EmailsOpened): EmailsOpenedTagged => ({ ...x, _tag: `EmailsOpened`}),
));
export type EmailsOpenedC = typeof emailsOpenedC;


export const emailsClicked = {
  _tag: `EmailsClicked`,
  id: 18,
  name: `Emails clicked`
} as const;

export const emailsClickedTaggedC = t.type({
  _tag: t.literal(`EmailsClicked`)
});
export type EmailsClickedTaggedC = typeof emailsClickedTaggedC;
export type EmailsClickedTagged = t.TypeOf<EmailsClickedTaggedC>;
export type EmailsClicked = EmailsClickedTagged & typeof emailsClicked;
export const emailsClickedC = pipe(emailsClickedTaggedC, c => new t.Type<EmailsClicked, EmailsClickedTagged>(
  `EmailsClicked`,
  (u: unknown): u is EmailsClicked => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, EmailsClicked> => pipe(c.decode(u), E.map(x => ({ ...x, ...emailsClicked }))),
  (x: EmailsClicked): EmailsClickedTagged => ({ ...x, _tag: `EmailsClicked`}),
));
export type EmailsClickedC = typeof emailsClickedC;


export const allActivityReportActivityDataPointC = [engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC] as const;
export const allActivityReportActivityDataPointNames = [`EngagementScore`, `LastActivityDate`, `OfferingViews`, `RoadshowViews`, `DocumentDownloads`, `InfoRequests`, `EmailsReceived`, `EmailsOpened`, `EmailsClicked`] as const;
export type ActivityReportActivityDataPointName = (typeof allActivityReportActivityDataPointNames)[number];

export const ActivityReportActivityDataPointCU = t.union([engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC]);
export type ActivityReportActivityDataPointCU = typeof ActivityReportActivityDataPointCU;
export type ActivityReportActivityDataPointU = t.TypeOf<ActivityReportActivityDataPointCU>;

export const activityReportActivityDataPointOrd: Ord.Ord<ActivityReportActivityDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportActivityDataPoint = [engagementScore, lastActivityDate, offeringViews, roadshowViews, documentDownloads, infoRequests, emailsReceived, emailsOpened, emailsClicked] as const;
export type ActivityReportActivityDataPointMap<A> = { [K in ActivityReportActivityDataPointName]: A };


export const allActivityReportDataPointC = [nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC, engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC] as const;
export const allActivityReportDataPointNames = [`Name`, `EmailAddress`, `PhoneNumber`, `Organization`, `Title`, `UserType`, `InstitutionalInvestorType`, `ProductFocus`, `SectorFocus`, `EngagementScore`, `LastActivityDate`, `OfferingViews`, `RoadshowViews`, `DocumentDownloads`, `InfoRequests`, `EmailsReceived`, `EmailsOpened`, `EmailsClicked`] as const;
export type ActivityReportDataPointName = (typeof allActivityReportDataPointNames)[number];

export const ActivityReportDataPointCU = t.union([nameC, emailAddressC, phoneNumberC, organizationC, titleC, userTypeC, institutionalInvestorTypeC, productFocusC, sectorFocusC, engagementScoreC, lastActivityDateC, offeringViewsC, roadshowViewsC, documentDownloadsC, infoRequestsC, emailsReceivedC, emailsOpenedC, emailsClickedC]);
export type ActivityReportDataPointCU = typeof ActivityReportDataPointCU;
export type ActivityReportDataPointU = t.TypeOf<ActivityReportDataPointCU>;

export const activityReportDataPointOrd: Ord.Ord<ActivityReportDataPointU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allActivityReportDataPoint = [name, emailAddress, phoneNumber, organization, title, userType, institutionalInvestorType, productFocus, sectorFocus, engagementScore, lastActivityDate, offeringViews, roadshowViews, documentDownloads, infoRequests, emailsReceived, emailsOpened, emailsClicked] as const;
export type ActivityReportDataPointMap<A> = { [K in ActivityReportDataPointName]: A };


