import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { LocalDateC } from "../../codecs/localDate";
import * as t from "io-ts";
import { markdownC } from "../../codecs/markdown";

export const eventC = t.type({
  eventDate: optionFromNullable(LocalDateC),
  endDate: optionFromNullable(LocalDateC),
  eventTitle: t.string,
  eventBlurb: optionFromNullable(markdownC)
});
export type EventC = typeof eventC;
export type Event = t.TypeOf<EventC>;


