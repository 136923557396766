import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const airports = {
  _tag: `Airports`,
  id: 1,
  name: `Airports`
} as const;

export const airportsTaggedC = t.type({
  _tag: t.literal(`Airports`)
});
export type AirportsTaggedC = typeof airportsTaggedC;
export type AirportsTagged = t.TypeOf<AirportsTaggedC>;
export type Airports = AirportsTagged & typeof airports;
export const airportsC = pipe(airportsTaggedC, c => new t.Type<Airports, AirportsTagged>(
  `Airports`,
  (u: unknown): u is Airports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Airports> => pipe(c.decode(u), E.map(x => ({ ...x, ...airports }))),
  (x: Airports): AirportsTagged => ({ ...x, _tag: `Airports`}),
));
export type AirportsC = typeof airportsC;


export const charterSchools = {
  _tag: `CharterSchools`,
  id: 2,
  name: `Charter Schools`
} as const;

export const charterSchoolsTaggedC = t.type({
  _tag: t.literal(`CharterSchools`)
});
export type CharterSchoolsTaggedC = typeof charterSchoolsTaggedC;
export type CharterSchoolsTagged = t.TypeOf<CharterSchoolsTaggedC>;
export type CharterSchools = CharterSchoolsTagged & typeof charterSchools;
export const charterSchoolsC = pipe(charterSchoolsTaggedC, c => new t.Type<CharterSchools, CharterSchoolsTagged>(
  `CharterSchools`,
  (u: unknown): u is CharterSchools => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CharterSchools> => pipe(c.decode(u), E.map(x => ({ ...x, ...charterSchools }))),
  (x: CharterSchools): CharterSchoolsTagged => ({ ...x, _tag: `CharterSchools`}),
));
export type CharterSchoolsC = typeof charterSchoolsC;


export const combinedUtility = {
  _tag: `CombinedUtility`,
  id: 3,
  name: `Combined Utility`
} as const;

export const combinedUtilityTaggedC = t.type({
  _tag: t.literal(`CombinedUtility`)
});
export type CombinedUtilityTaggedC = typeof combinedUtilityTaggedC;
export type CombinedUtilityTagged = t.TypeOf<CombinedUtilityTaggedC>;
export type CombinedUtility = CombinedUtilityTagged & typeof combinedUtility;
export const combinedUtilityC = pipe(combinedUtilityTaggedC, c => new t.Type<CombinedUtility, CombinedUtilityTagged>(
  `CombinedUtility`,
  (u: unknown): u is CombinedUtility => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CombinedUtility> => pipe(c.decode(u), E.map(x => ({ ...x, ...combinedUtility }))),
  (x: CombinedUtility): CombinedUtilityTagged => ({ ...x, _tag: `CombinedUtility`}),
));
export type CombinedUtilityC = typeof combinedUtilityC;


export const countyCityTown = {
  _tag: `CountyCityTown`,
  id: 4,
  name: `County / City / Town`
} as const;

export const countyCityTownTaggedC = t.type({
  _tag: t.literal(`CountyCityTown`)
});
export type CountyCityTownTaggedC = typeof countyCityTownTaggedC;
export type CountyCityTownTagged = t.TypeOf<CountyCityTownTaggedC>;
export type CountyCityTown = CountyCityTownTagged & typeof countyCityTown;
export const countyCityTownC = pipe(countyCityTownTaggedC, c => new t.Type<CountyCityTown, CountyCityTownTagged>(
  `CountyCityTown`,
  (u: unknown): u is CountyCityTown => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CountyCityTown> => pipe(c.decode(u), E.map(x => ({ ...x, ...countyCityTown }))),
  (x: CountyCityTown): CountyCityTownTagged => ({ ...x, _tag: `CountyCityTown`}),
));
export type CountyCityTownC = typeof countyCityTownC;


export const healthcare = {
  _tag: `Healthcare`,
  id: 5,
  name: `Healthcare`
} as const;

export const healthcareTaggedC = t.type({
  _tag: t.literal(`Healthcare`)
});
export type HealthcareTaggedC = typeof healthcareTaggedC;
export type HealthcareTagged = t.TypeOf<HealthcareTaggedC>;
export type Healthcare = HealthcareTagged & typeof healthcare;
export const healthcareC = pipe(healthcareTaggedC, c => new t.Type<Healthcare, HealthcareTagged>(
  `Healthcare`,
  (u: unknown): u is Healthcare => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Healthcare> => pipe(c.decode(u), E.map(x => ({ ...x, ...healthcare }))),
  (x: Healthcare): HealthcareTagged => ({ ...x, _tag: `Healthcare`}),
));
export type HealthcareC = typeof healthcareC;


export const higherEducation = {
  _tag: `HigherEducation`,
  id: 6,
  name: `Higher Education`
} as const;

export const higherEducationTaggedC = t.type({
  _tag: t.literal(`HigherEducation`)
});
export type HigherEducationTaggedC = typeof higherEducationTaggedC;
export type HigherEducationTagged = t.TypeOf<HigherEducationTaggedC>;
export type HigherEducation = HigherEducationTagged & typeof higherEducation;
export const higherEducationC = pipe(higherEducationTaggedC, c => new t.Type<HigherEducation, HigherEducationTagged>(
  `HigherEducation`,
  (u: unknown): u is HigherEducation => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, HigherEducation> => pipe(c.decode(u), E.map(x => ({ ...x, ...higherEducation }))),
  (x: HigherEducation): HigherEducationTagged => ({ ...x, _tag: `HigherEducation`}),
));
export type HigherEducationC = typeof higherEducationC;


export const housing = {
  _tag: `Housing`,
  id: 7,
  name: `Housing`
} as const;

export const housingTaggedC = t.type({
  _tag: t.literal(`Housing`)
});
export type HousingTaggedC = typeof housingTaggedC;
export type HousingTagged = t.TypeOf<HousingTaggedC>;
export type Housing = HousingTagged & typeof housing;
export const housingC = pipe(housingTaggedC, c => new t.Type<Housing, HousingTagged>(
  `Housing`,
  (u: unknown): u is Housing => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Housing> => pipe(c.decode(u), E.map(x => ({ ...x, ...housing }))),
  (x: Housing): HousingTagged => ({ ...x, _tag: `Housing`}),
));
export type HousingC = typeof housingC;


export const nonProfit = {
  _tag: `NonProfit`,
  id: 8,
  name: `Non-Profit`
} as const;

export const nonProfitTaggedC = t.type({
  _tag: t.literal(`NonProfit`)
});
export type NonProfitTaggedC = typeof nonProfitTaggedC;
export type NonProfitTagged = t.TypeOf<NonProfitTaggedC>;
export type NonProfit = NonProfitTagged & typeof nonProfit;
export const nonProfitC = pipe(nonProfitTaggedC, c => new t.Type<NonProfit, NonProfitTagged>(
  `NonProfit`,
  (u: unknown): u is NonProfit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, NonProfit> => pipe(c.decode(u), E.map(x => ({ ...x, ...nonProfit }))),
  (x: NonProfit): NonProfitTagged => ({ ...x, _tag: `NonProfit`}),
));
export type NonProfitC = typeof nonProfitC;


export const poolBondBankConduit = {
  _tag: `PoolBondBankConduit`,
  id: 9,
  name: `Pool / Bond Bank / Conduit`
} as const;

export const poolBondBankConduitTaggedC = t.type({
  _tag: t.literal(`PoolBondBankConduit`)
});
export type PoolBondBankConduitTaggedC = typeof poolBondBankConduitTaggedC;
export type PoolBondBankConduitTagged = t.TypeOf<PoolBondBankConduitTaggedC>;
export type PoolBondBankConduit = PoolBondBankConduitTagged & typeof poolBondBankConduit;
export const poolBondBankConduitC = pipe(poolBondBankConduitTaggedC, c => new t.Type<PoolBondBankConduit, PoolBondBankConduitTagged>(
  `PoolBondBankConduit`,
  (u: unknown): u is PoolBondBankConduit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PoolBondBankConduit> => pipe(c.decode(u), E.map(x => ({ ...x, ...poolBondBankConduit }))),
  (x: PoolBondBankConduit): PoolBondBankConduitTagged => ({ ...x, _tag: `PoolBondBankConduit`}),
));
export type PoolBondBankConduitC = typeof poolBondBankConduitC;


export const ports = {
  _tag: `Ports`,
  id: 10,
  name: `Ports`
} as const;

export const portsTaggedC = t.type({
  _tag: t.literal(`Ports`)
});
export type PortsTaggedC = typeof portsTaggedC;
export type PortsTagged = t.TypeOf<PortsTaggedC>;
export type Ports = PortsTagged & typeof ports;
export const portsC = pipe(portsTaggedC, c => new t.Type<Ports, PortsTagged>(
  `Ports`,
  (u: unknown): u is Ports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Ports> => pipe(c.decode(u), E.map(x => ({ ...x, ...ports }))),
  (x: Ports): PortsTagged => ({ ...x, _tag: `Ports`}),
));
export type PortsC = typeof portsC;


export const powerGas = {
  _tag: `PowerGas`,
  id: 11,
  name: `Power / Gas`
} as const;

export const powerGasTaggedC = t.type({
  _tag: t.literal(`PowerGas`)
});
export type PowerGasTaggedC = typeof powerGasTaggedC;
export type PowerGasTagged = t.TypeOf<PowerGasTaggedC>;
export type PowerGas = PowerGasTagged & typeof powerGas;
export const powerGasC = pipe(powerGasTaggedC, c => new t.Type<PowerGas, PowerGasTagged>(
  `PowerGas`,
  (u: unknown): u is PowerGas => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, PowerGas> => pipe(c.decode(u), E.map(x => ({ ...x, ...powerGas }))),
  (x: PowerGas): PowerGasTagged => ({ ...x, _tag: `PowerGas`}),
));
export type PowerGasC = typeof powerGasC;


export const projectFinanceLandSecured = {
  _tag: `ProjectFinanceLandSecured`,
  id: 12,
  name: `Project Finance / Land Secured`
} as const;

export const projectFinanceLandSecuredTaggedC = t.type({
  _tag: t.literal(`ProjectFinanceLandSecured`)
});
export type ProjectFinanceLandSecuredTaggedC = typeof projectFinanceLandSecuredTaggedC;
export type ProjectFinanceLandSecuredTagged = t.TypeOf<ProjectFinanceLandSecuredTaggedC>;
export type ProjectFinanceLandSecured = ProjectFinanceLandSecuredTagged & typeof projectFinanceLandSecured;
export const projectFinanceLandSecuredC = pipe(projectFinanceLandSecuredTaggedC, c => new t.Type<ProjectFinanceLandSecured, ProjectFinanceLandSecuredTagged>(
  `ProjectFinanceLandSecured`,
  (u: unknown): u is ProjectFinanceLandSecured => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ProjectFinanceLandSecured> => pipe(c.decode(u), E.map(x => ({ ...x, ...projectFinanceLandSecured }))),
  (x: ProjectFinanceLandSecured): ProjectFinanceLandSecuredTagged => ({ ...x, _tag: `ProjectFinanceLandSecured`}),
));
export type ProjectFinanceLandSecuredC = typeof projectFinanceLandSecuredC;


export const schoolDistricts = {
  _tag: `SchoolDistricts`,
  id: 13,
  name: `School Districts`
} as const;

export const schoolDistrictsTaggedC = t.type({
  _tag: t.literal(`SchoolDistricts`)
});
export type SchoolDistrictsTaggedC = typeof schoolDistrictsTaggedC;
export type SchoolDistrictsTagged = t.TypeOf<SchoolDistrictsTaggedC>;
export type SchoolDistricts = SchoolDistrictsTagged & typeof schoolDistricts;
export const schoolDistrictsC = pipe(schoolDistrictsTaggedC, c => new t.Type<SchoolDistricts, SchoolDistrictsTagged>(
  `SchoolDistricts`,
  (u: unknown): u is SchoolDistricts => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SchoolDistricts> => pipe(c.decode(u), E.map(x => ({ ...x, ...schoolDistricts }))),
  (x: SchoolDistricts): SchoolDistrictsTagged => ({ ...x, _tag: `SchoolDistricts`}),
));
export type SchoolDistrictsC = typeof schoolDistrictsC;


export const stateProvince = {
  _tag: `StateProvince`,
  id: 14,
  name: `State / Province`
} as const;

export const stateProvinceTaggedC = t.type({
  _tag: t.literal(`StateProvince`)
});
export type StateProvinceTaggedC = typeof stateProvinceTaggedC;
export type StateProvinceTagged = t.TypeOf<StateProvinceTaggedC>;
export type StateProvince = StateProvinceTagged & typeof stateProvince;
export const stateProvinceC = pipe(stateProvinceTaggedC, c => new t.Type<StateProvince, StateProvinceTagged>(
  `StateProvince`,
  (u: unknown): u is StateProvince => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StateProvince> => pipe(c.decode(u), E.map(x => ({ ...x, ...stateProvince }))),
  (x: StateProvince): StateProvinceTagged => ({ ...x, _tag: `StateProvince`}),
));
export type StateProvinceC = typeof stateProvinceC;


export const studentLoans = {
  _tag: `StudentLoans`,
  id: 15,
  name: `Student Loans`
} as const;

export const studentLoansTaggedC = t.type({
  _tag: t.literal(`StudentLoans`)
});
export type StudentLoansTaggedC = typeof studentLoansTaggedC;
export type StudentLoansTagged = t.TypeOf<StudentLoansTaggedC>;
export type StudentLoans = StudentLoansTagged & typeof studentLoans;
export const studentLoansC = pipe(studentLoansTaggedC, c => new t.Type<StudentLoans, StudentLoansTagged>(
  `StudentLoans`,
  (u: unknown): u is StudentLoans => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, StudentLoans> => pipe(c.decode(u), E.map(x => ({ ...x, ...studentLoans }))),
  (x: StudentLoans): StudentLoansTagged => ({ ...x, _tag: `StudentLoans`}),
));
export type StudentLoansC = typeof studentLoansC;


export const transit = {
  _tag: `Transit`,
  id: 16,
  name: `Transit`
} as const;

export const transitTaggedC = t.type({
  _tag: t.literal(`Transit`)
});
export type TransitTaggedC = typeof transitTaggedC;
export type TransitTagged = t.TypeOf<TransitTaggedC>;
export type Transit = TransitTagged & typeof transit;
export const transitC = pipe(transitTaggedC, c => new t.Type<Transit, TransitTagged>(
  `Transit`,
  (u: unknown): u is Transit => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Transit> => pipe(c.decode(u), E.map(x => ({ ...x, ...transit }))),
  (x: Transit): TransitTagged => ({ ...x, _tag: `Transit`}),
));
export type TransitC = typeof transitC;


export const transportationTollRoad = {
  _tag: `TransportationTollRoad`,
  id: 17,
  name: `Transportation / Toll Road`
} as const;

export const transportationTollRoadTaggedC = t.type({
  _tag: t.literal(`TransportationTollRoad`)
});
export type TransportationTollRoadTaggedC = typeof transportationTollRoadTaggedC;
export type TransportationTollRoadTagged = t.TypeOf<TransportationTollRoadTaggedC>;
export type TransportationTollRoad = TransportationTollRoadTagged & typeof transportationTollRoad;
export const transportationTollRoadC = pipe(transportationTollRoadTaggedC, c => new t.Type<TransportationTollRoad, TransportationTollRoadTagged>(
  `TransportationTollRoad`,
  (u: unknown): u is TransportationTollRoad => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TransportationTollRoad> => pipe(c.decode(u), E.map(x => ({ ...x, ...transportationTollRoad }))),
  (x: TransportationTollRoad): TransportationTollRoadTagged => ({ ...x, _tag: `TransportationTollRoad`}),
));
export type TransportationTollRoadC = typeof transportationTollRoadC;


export const waterSewer = {
  _tag: `WaterSewer`,
  id: 18,
  name: `Water / Sewer`
} as const;

export const waterSewerTaggedC = t.type({
  _tag: t.literal(`WaterSewer`)
});
export type WaterSewerTaggedC = typeof waterSewerTaggedC;
export type WaterSewerTagged = t.TypeOf<WaterSewerTaggedC>;
export type WaterSewer = WaterSewerTagged & typeof waterSewer;
export const waterSewerC = pipe(waterSewerTaggedC, c => new t.Type<WaterSewer, WaterSewerTagged>(
  `WaterSewer`,
  (u: unknown): u is WaterSewer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, WaterSewer> => pipe(c.decode(u), E.map(x => ({ ...x, ...waterSewer }))),
  (x: WaterSewer): WaterSewerTagged => ({ ...x, _tag: `WaterSewer`}),
));
export type WaterSewerC = typeof waterSewerC;


export const specialDistrict = {
  _tag: `SpecialDistrict`,
  id: 19,
  name: `Special District`
} as const;

export const specialDistrictTaggedC = t.type({
  _tag: t.literal(`SpecialDistrict`)
});
export type SpecialDistrictTaggedC = typeof specialDistrictTaggedC;
export type SpecialDistrictTagged = t.TypeOf<SpecialDistrictTaggedC>;
export type SpecialDistrict = SpecialDistrictTagged & typeof specialDistrict;
export const specialDistrictC = pipe(specialDistrictTaggedC, c => new t.Type<SpecialDistrict, SpecialDistrictTagged>(
  `SpecialDistrict`,
  (u: unknown): u is SpecialDistrict => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, SpecialDistrict> => pipe(c.decode(u), E.map(x => ({ ...x, ...specialDistrict }))),
  (x: SpecialDistrict): SpecialDistrictTagged => ({ ...x, _tag: `SpecialDistrict`}),
));
export type SpecialDistrictC = typeof specialDistrictC;


export const countrySovereignCreditIssuer = {
  _tag: `CountrySovereignCreditIssuer`,
  id: 20,
  name: `Country / Sovereign Credit Issuer`
} as const;

export const countrySovereignCreditIssuerTaggedC = t.type({
  _tag: t.literal(`CountrySovereignCreditIssuer`)
});
export type CountrySovereignCreditIssuerTaggedC = typeof countrySovereignCreditIssuerTaggedC;
export type CountrySovereignCreditIssuerTagged = t.TypeOf<CountrySovereignCreditIssuerTaggedC>;
export type CountrySovereignCreditIssuer = CountrySovereignCreditIssuerTagged & typeof countrySovereignCreditIssuer;
export const countrySovereignCreditIssuerC = pipe(countrySovereignCreditIssuerTaggedC, c => new t.Type<CountrySovereignCreditIssuer, CountrySovereignCreditIssuerTagged>(
  `CountrySovereignCreditIssuer`,
  (u: unknown): u is CountrySovereignCreditIssuer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, CountrySovereignCreditIssuer> => pipe(c.decode(u), E.map(x => ({ ...x, ...countrySovereignCreditIssuer }))),
  (x: CountrySovereignCreditIssuer): CountrySovereignCreditIssuerTagged => ({ ...x, _tag: `CountrySovereignCreditIssuer`}),
));
export type CountrySovereignCreditIssuerC = typeof countrySovereignCreditIssuerC;


export const insurance = {
  _tag: `Insurance`,
  id: 21,
  name: `Insurance`
} as const;

export const insuranceTaggedC = t.type({
  _tag: t.literal(`Insurance`)
});
export type InsuranceTaggedC = typeof insuranceTaggedC;
export type InsuranceTagged = t.TypeOf<InsuranceTaggedC>;
export type Insurance = InsuranceTagged & typeof insurance;
export const insuranceC = pipe(insuranceTaggedC, c => new t.Type<Insurance, InsuranceTagged>(
  `Insurance`,
  (u: unknown): u is Insurance => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Insurance> => pipe(c.decode(u), E.map(x => ({ ...x, ...insurance }))),
  (x: Insurance): InsuranceTagged => ({ ...x, _tag: `Insurance`}),
));
export type InsuranceC = typeof insuranceC;


export const allSectorC = [airportsC, charterSchoolsC, combinedUtilityC, countyCityTownC, healthcareC, higherEducationC, housingC, nonProfitC, poolBondBankConduitC, portsC, powerGasC, projectFinanceLandSecuredC, schoolDistrictsC, stateProvinceC, studentLoansC, transitC, transportationTollRoadC, waterSewerC, specialDistrictC, countrySovereignCreditIssuerC, insuranceC] as const;
export const allSectorNames = [`Airports`, `CharterSchools`, `CombinedUtility`, `CountyCityTown`, `Healthcare`, `HigherEducation`, `Housing`, `NonProfit`, `PoolBondBankConduit`, `Ports`, `PowerGas`, `ProjectFinanceLandSecured`, `SchoolDistricts`, `StateProvince`, `StudentLoans`, `Transit`, `TransportationTollRoad`, `WaterSewer`, `SpecialDistrict`, `CountrySovereignCreditIssuer`, `Insurance`] as const;
export type SectorName = (typeof allSectorNames)[number];

export const SectorCU = t.union([airportsC, charterSchoolsC, combinedUtilityC, countyCityTownC, healthcareC, higherEducationC, housingC, nonProfitC, poolBondBankConduitC, portsC, powerGasC, projectFinanceLandSecuredC, schoolDistrictsC, stateProvinceC, studentLoansC, transitC, transportationTollRoadC, waterSewerC, specialDistrictC, countrySovereignCreditIssuerC, insuranceC]);
export type SectorCU = typeof SectorCU;
export type SectorU = t.TypeOf<SectorCU>;

export const sectorOrd: Ord.Ord<SectorU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allSector = [airports, charterSchools, combinedUtility, countyCityTown, healthcare, higherEducation, housing, nonProfit, poolBondBankConduit, ports, powerGas, projectFinanceLandSecured, schoolDistricts, stateProvince, studentLoans, transit, transportationTollRoad, waterSewer, specialDistrict, countrySovereignCreditIssuer, insurance] as const;
export type SectorMap<A> = { [K in SectorName]: A };


