import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const bondLinkFisc = {
  _tag: `BondLinkFisc`,
  id: 1,
  name: `BondLink The Fisc`
} as const;

export const bondLinkFiscTaggedC = t.type({
  _tag: t.literal(`BondLinkFisc`)
});
export type BondLinkFiscTaggedC = typeof bondLinkFiscTaggedC;
export type BondLinkFiscTagged = t.TypeOf<BondLinkFiscTaggedC>;
export type BondLinkFisc = BondLinkFiscTagged & typeof bondLinkFisc;
export const bondLinkFiscC = pipe(bondLinkFiscTaggedC, c => new t.Type<BondLinkFisc, BondLinkFiscTagged>(
  `BondLinkFisc`,
  (u: unknown): u is BondLinkFisc => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkFisc> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkFisc }))),
  (x: BondLinkFisc): BondLinkFiscTagged => ({ ...x, _tag: `BondLinkFisc`}),
));
export type BondLinkFiscC = typeof bondLinkFiscC;


export const bondLinkMuniPod = {
  _tag: `BondLinkMuniPod`,
  id: 2,
  name: `BondLink Muniland Podcast`
} as const;

export const bondLinkMuniPodTaggedC = t.type({
  _tag: t.literal(`BondLinkMuniPod`)
});
export type BondLinkMuniPodTaggedC = typeof bondLinkMuniPodTaggedC;
export type BondLinkMuniPodTagged = t.TypeOf<BondLinkMuniPodTaggedC>;
export type BondLinkMuniPod = BondLinkMuniPodTagged & typeof bondLinkMuniPod;
export const bondLinkMuniPodC = pipe(bondLinkMuniPodTaggedC, c => new t.Type<BondLinkMuniPod, BondLinkMuniPodTagged>(
  `BondLinkMuniPod`,
  (u: unknown): u is BondLinkMuniPod => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkMuniPod> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkMuniPod }))),
  (x: BondLinkMuniPod): BondLinkMuniPodTagged => ({ ...x, _tag: `BondLinkMuniPod`}),
));
export type BondLinkMuniPodC = typeof bondLinkMuniPodC;


export const theBondBuyer = {
  _tag: `TheBondBuyer`,
  id: 3,
  name: `The Bond Buyer`
} as const;

export const theBondBuyerTaggedC = t.type({
  _tag: t.literal(`TheBondBuyer`)
});
export type TheBondBuyerTaggedC = typeof theBondBuyerTaggedC;
export type TheBondBuyerTagged = t.TypeOf<TheBondBuyerTaggedC>;
export type TheBondBuyer = TheBondBuyerTagged & typeof theBondBuyer;
export const theBondBuyerC = pipe(theBondBuyerTaggedC, c => new t.Type<TheBondBuyer, TheBondBuyerTagged>(
  `TheBondBuyer`,
  (u: unknown): u is TheBondBuyer => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, TheBondBuyer> => pipe(c.decode(u), E.map(x => ({ ...x, ...theBondBuyer }))),
  (x: TheBondBuyer): TheBondBuyerTagged => ({ ...x, _tag: `TheBondBuyer`}),
));
export type TheBondBuyerC = typeof theBondBuyerC;


export const municipalMarketAnalytics = {
  _tag: `MunicipalMarketAnalytics`,
  id: 4,
  name: `Municipal Market Analytics`
} as const;

export const municipalMarketAnalyticsTaggedC = t.type({
  _tag: t.literal(`MunicipalMarketAnalytics`)
});
export type MunicipalMarketAnalyticsTaggedC = typeof municipalMarketAnalyticsTaggedC;
export type MunicipalMarketAnalyticsTagged = t.TypeOf<MunicipalMarketAnalyticsTaggedC>;
export type MunicipalMarketAnalytics = MunicipalMarketAnalyticsTagged & typeof municipalMarketAnalytics;
export const municipalMarketAnalyticsC = pipe(municipalMarketAnalyticsTaggedC, c => new t.Type<MunicipalMarketAnalytics, MunicipalMarketAnalyticsTagged>(
  `MunicipalMarketAnalytics`,
  (u: unknown): u is MunicipalMarketAnalytics => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MunicipalMarketAnalytics> => pipe(c.decode(u), E.map(x => ({ ...x, ...municipalMarketAnalytics }))),
  (x: MunicipalMarketAnalytics): MunicipalMarketAnalyticsTagged => ({ ...x, _tag: `MunicipalMarketAnalytics`}),
));
export type MunicipalMarketAnalyticsC = typeof municipalMarketAnalyticsC;


export const marketReports = {
  _tag: `MarketReports`,
  id: 5,
  name: `Market Reports`
} as const;

export const marketReportsTaggedC = t.type({
  _tag: t.literal(`MarketReports`)
});
export type MarketReportsTaggedC = typeof marketReportsTaggedC;
export type MarketReportsTagged = t.TypeOf<MarketReportsTaggedC>;
export type MarketReports = MarketReportsTagged & typeof marketReports;
export const marketReportsC = pipe(marketReportsTaggedC, c => new t.Type<MarketReports, MarketReportsTagged>(
  `MarketReports`,
  (u: unknown): u is MarketReports => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, MarketReports> => pipe(c.decode(u), E.map(x => ({ ...x, ...marketReports }))),
  (x: MarketReports): MarketReportsTagged => ({ ...x, _tag: `MarketReports`}),
));
export type MarketReportsC = typeof marketReportsC;


export const bondLinkResearch = {
  _tag: `BondLinkResearch`,
  id: 6,
  name: `BondLink Research and Analysis`
} as const;

export const bondLinkResearchTaggedC = t.type({
  _tag: t.literal(`BondLinkResearch`)
});
export type BondLinkResearchTaggedC = typeof bondLinkResearchTaggedC;
export type BondLinkResearchTagged = t.TypeOf<BondLinkResearchTaggedC>;
export type BondLinkResearch = BondLinkResearchTagged & typeof bondLinkResearch;
export const bondLinkResearchC = pipe(bondLinkResearchTaggedC, c => new t.Type<BondLinkResearch, BondLinkResearchTagged>(
  `BondLinkResearch`,
  (u: unknown): u is BondLinkResearch => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondLinkResearch> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondLinkResearch }))),
  (x: BondLinkResearch): BondLinkResearchTagged => ({ ...x, _tag: `BondLinkResearch`}),
));
export type BondLinkResearchC = typeof bondLinkResearchC;


export const bloomberg = {
  _tag: `Bloomberg`,
  id: 7,
  name: `Bloomberg`
} as const;

export const bloombergTaggedC = t.type({
  _tag: t.literal(`Bloomberg`)
});
export type BloombergTaggedC = typeof bloombergTaggedC;
export type BloombergTagged = t.TypeOf<BloombergTaggedC>;
export type Bloomberg = BloombergTagged & typeof bloomberg;
export const bloombergC = pipe(bloombergTaggedC, c => new t.Type<Bloomberg, BloombergTagged>(
  `Bloomberg`,
  (u: unknown): u is Bloomberg => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Bloomberg> => pipe(c.decode(u), E.map(x => ({ ...x, ...bloomberg }))),
  (x: Bloomberg): BloombergTagged => ({ ...x, _tag: `Bloomberg`}),
));
export type BloombergC = typeof bloombergC;


export const allPublicationC = [bondLinkFiscC, bondLinkMuniPodC, theBondBuyerC, municipalMarketAnalyticsC, marketReportsC, bondLinkResearchC, bloombergC] as const;
export const allPublicationNames = [`BondLinkFisc`, `BondLinkMuniPod`, `TheBondBuyer`, `MunicipalMarketAnalytics`, `MarketReports`, `BondLinkResearch`, `Bloomberg`] as const;
export type PublicationName = (typeof allPublicationNames)[number];

export const PublicationCU = t.union([bondLinkFiscC, bondLinkMuniPodC, theBondBuyerC, municipalMarketAnalyticsC, marketReportsC, bondLinkResearchC, bloombergC]);
export type PublicationCU = typeof PublicationCU;
export type PublicationU = t.TypeOf<PublicationCU>;

export const publicationOrd: Ord.Ord<PublicationU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allPublication = [bondLinkFisc, bondLinkMuniPod, theBondBuyer, municipalMarketAnalytics, marketReports, bondLinkResearch, bloomberg] as const;
export type PublicationMap<A> = { [K in PublicationName]: A };


