import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const issuerId = {
  _tag: `IssuerId`,
  name: `dimension1`
} as const;

export const issuerIdTaggedC = t.type({
  _tag: t.literal(`IssuerId`)
});
export type IssuerIdTaggedC = typeof issuerIdTaggedC;
export type IssuerIdTagged = t.TypeOf<IssuerIdTaggedC>;
export type IssuerId = IssuerIdTagged & typeof issuerId;
export const issuerIdC = pipe(issuerIdTaggedC, c => new t.Type<IssuerId, IssuerIdTagged>(
  `IssuerId`,
  (u: unknown): u is IssuerId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerId> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerId }))),
  (x: IssuerId): IssuerIdTagged => ({ ...x, _tag: `IssuerId`}),
));
export type IssuerIdC = typeof issuerIdC;


export const bondlinkUserId = {
  _tag: `BondlinkUserId`,
  name: `dimension2`
} as const;

export const bondlinkUserIdTaggedC = t.type({
  _tag: t.literal(`BondlinkUserId`)
});
export type BondlinkUserIdTaggedC = typeof bondlinkUserIdTaggedC;
export type BondlinkUserIdTagged = t.TypeOf<BondlinkUserIdTaggedC>;
export type BondlinkUserId = BondlinkUserIdTagged & typeof bondlinkUserId;
export const bondlinkUserIdC = pipe(bondlinkUserIdTaggedC, c => new t.Type<BondlinkUserId, BondlinkUserIdTagged>(
  `BondlinkUserId`,
  (u: unknown): u is BondlinkUserId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BondlinkUserId> => pipe(c.decode(u), E.map(x => ({ ...x, ...bondlinkUserId }))),
  (x: BondlinkUserId): BondlinkUserIdTagged => ({ ...x, _tag: `BondlinkUserId`}),
));
export type BondlinkUserIdC = typeof bondlinkUserIdC;


export const clientType = {
  _tag: `ClientType`,
  name: `dimension3`
} as const;

export const clientTypeTaggedC = t.type({
  _tag: t.literal(`ClientType`)
});
export type ClientTypeTaggedC = typeof clientTypeTaggedC;
export type ClientTypeTagged = t.TypeOf<ClientTypeTaggedC>;
export type ClientType = ClientTypeTagged & typeof clientType;
export const clientTypeC = pipe(clientTypeTaggedC, c => new t.Type<ClientType, ClientTypeTagged>(
  `ClientType`,
  (u: unknown): u is ClientType => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, ClientType> => pipe(c.decode(u), E.map(x => ({ ...x, ...clientType }))),
  (x: ClientType): ClientTypeTagged => ({ ...x, _tag: `ClientType`}),
));
export type ClientTypeC = typeof clientTypeC;


export const issuerName = {
  _tag: `IssuerName`,
  name: `dimension4`
} as const;

export const issuerNameTaggedC = t.type({
  _tag: t.literal(`IssuerName`)
});
export type IssuerNameTaggedC = typeof issuerNameTaggedC;
export type IssuerNameTagged = t.TypeOf<IssuerNameTaggedC>;
export type IssuerName = IssuerNameTagged & typeof issuerName;
export const issuerNameC = pipe(issuerNameTaggedC, c => new t.Type<IssuerName, IssuerNameTagged>(
  `IssuerName`,
  (u: unknown): u is IssuerName => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, IssuerName> => pipe(c.decode(u), E.map(x => ({ ...x, ...issuerName }))),
  (x: IssuerName): IssuerNameTagged => ({ ...x, _tag: `IssuerName`}),
));
export type IssuerNameC = typeof issuerNameC;


export const bankId = {
  _tag: `BankId`,
  name: `dimension5`
} as const;

export const bankIdTaggedC = t.type({
  _tag: t.literal(`BankId`)
});
export type BankIdTaggedC = typeof bankIdTaggedC;
export type BankIdTagged = t.TypeOf<BankIdTaggedC>;
export type BankId = BankIdTagged & typeof bankId;
export const bankIdC = pipe(bankIdTaggedC, c => new t.Type<BankId, BankIdTagged>(
  `BankId`,
  (u: unknown): u is BankId => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BankId> => pipe(c.decode(u), E.map(x => ({ ...x, ...bankId }))),
  (x: BankId): BankIdTagged => ({ ...x, _tag: `BankId`}),
));
export type BankIdC = typeof bankIdC;


export const allGACustomDimensionC = [issuerIdC, bondlinkUserIdC, clientTypeC, issuerNameC, bankIdC] as const;
export const allGACustomDimensionNames = [`IssuerId`, `BondlinkUserId`, `ClientType`, `IssuerName`, `BankId`] as const;
export type GACustomDimensionName = (typeof allGACustomDimensionNames)[number];

export const GACustomDimensionCU = t.union([issuerIdC, bondlinkUserIdC, clientTypeC, issuerNameC, bankIdC]);
export type GACustomDimensionCU = typeof GACustomDimensionCU;
export type GACustomDimensionU = t.TypeOf<GACustomDimensionCU>;

export const gACustomDimensionOrd: Ord.Ord<GACustomDimensionU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allGACustomDimension = [issuerId, bondlinkUserId, clientType, issuerName, bankId] as const;
export type GACustomDimensionMap<A> = { [K in GACustomDimensionName]: A };


