import * as t from "io-ts";
import { InvestorFocusCU as imported5_InvestorFocusCU } from "../domaintables/investorFocuses";
import { InvestorTypeCU as imported4_InvestorTypeCU } from "../domaintables/investorTypes";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { SectorCU as imported18_SectorCU } from "../domaintables/sectors";

export const institutionalInvestorInfoC = t.type({
  investorTypeId: optionFromNullable(imported4_InvestorTypeCU),
  focuses: t.readonlyArray(imported5_InvestorFocusCU),
  sectors: t.readonlyArray(imported18_SectorCU)
});
export type InstitutionalInvestorInfoC = typeof institutionalInvestorInfoC;
export type InstitutionalInvestorInfo = t.TypeOf<InstitutionalInvestorInfoC>;


export const institutionalInvestorInfoPostC = t.type({
  investorTypeId: optionFromNullable(t.number),
  focusIds: t.readonlyArray(t.number),
  noFocus: t.boolean,
  sectorIds: t.readonlyArray(t.number),
  noSector: t.boolean
});
export type InstitutionalInvestorInfoPostC = typeof institutionalInvestorInfoPostC;
export type InstitutionalInvestorInfoPost = t.TypeOf<InstitutionalInvestorInfoPostC>;


