import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";

import { O } from "@scripts/fp-ts";
import { registeredInvestmentAdvisorSlashWealthManagement } from "@scripts/generated/domaintables/investorTypes";
import type { UserPersonaU } from "@scripts/generated/domaintables/userPersonas";
import { allUserPersona, investorInstitutional, userPersonaOrd } from "@scripts/generated/domaintables/userPersonas";
import type { Actor } from "@scripts/generated/models/actor";
import type { SelectOption } from "@scripts/react/components/form/Select";

export const UserPersonaIdC = NumberFromString.pipe(t.union([
  t.literal(0), t.literal(allUserPersona[0].id),
  ...allUserPersona.slice(1).map(c => t.literal(c.id))]));

export type UserPersonaId = t.TypeOf<typeof UserPersonaIdC>;

export type PersonaOptions = SelectOption<number, false>;

export const personaOptions = pipe(
  allUserPersona,
  RA.sort(userPersonaOrd),
  RA.map((persona: UserPersonaU) => ({
    id: `user-persona-${persona.id}`,
    value: persona.id,
    label: persona.personaName,
  })),
);
export const isInstitutionalPersona = O.fold(() => false, (id: number) => id === investorInstitutional.id);
export const isInstitutionalOrWealthManagement = (a: Actor) => isInstitutionalPersona(a.personaId) || pipe(a.investorType, O.fold(() => false, tpe => tpe.id === registeredInvestmentAdvisorSlashWealthManagement.id));
