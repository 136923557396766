import { issuerC as imported34_issuerC } from "./issuer";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { addressC as imported6_addressC } from "./address";
import { bondOfferingC as imported18_bondOfferingC, bondOfferingC as imported19_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported46_rfpC, rfpC as imported47_rfpC } from "./rfpBase";

export const issuerWithDealsC = t.type({
  issuer: imported34_issuerC,
  address: optionFromNullable(imported6_addressC),
  activeOfferings: t.readonlyArray(imported18_bondOfferingC),
  archivedOfferings: t.readonlyArray(imported19_bondOfferingC),
  activeRfps: t.readonlyArray(imported46_rfpC),
  archivedRfps: t.readonlyArray(imported47_rfpC)
});
export type IssuerWithDealsC = typeof issuerWithDealsC;
export type IssuerWithDeals = t.TypeOf<IssuerWithDealsC>;


