import * as t from "io-ts";
import { RatingAgencyCU as imported39_RatingAgencyCU, RatingAgencyCU as imported40_RatingAgencyCU, RatingAgencyCU as imported41_RatingAgencyCU } from "../domaintables/ratingAgencies";
import { LocalDateC } from "../../codecs/localDate";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { RatingOutlookCU as imported5_RatingOutlookCU, RatingOutlookCU as imported6_RatingOutlookCU, RatingOutlookCU as imported7_RatingOutlookCU } from "../domaintables/ratingOutlook";
import { markdownC } from "../../codecs/markdown";

export const issuerRatingC = t.type({
  _tag: t.literal(`IssuerRating`),
  ratingAgency: imported39_RatingAgencyCU,
  ratingOutlook: imported5_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
});
export type IssuerRatingC = typeof issuerRatingC;
export type IssuerRating = t.TypeOf<IssuerRatingC>;


export const programRatingC = t.type({
  _tag: t.literal(`ProgramRating`),
  bondProgramId: t.number,
  bondProgramName: t.string,
  ratingAgency: imported40_RatingAgencyCU,
  ratingOutlook: imported6_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
});
export type ProgramRatingC = typeof programRatingC;
export type ProgramRating = t.TypeOf<ProgramRatingC>;


export const bondRatingC = t.type({
  _tag: t.literal(`BondRating`),
  ratingAgency: imported41_RatingAgencyCU,
  ratingOutlook: imported7_RatingOutlookCU,
  ratingDate: LocalDateC,
  rating: t.string,
  ratingNotes: optionFromNullable(markdownC)
});
export type BondRatingC = typeof bondRatingC;
export type BondRating = t.TypeOf<BondRatingC>;


export const allRatingC = [issuerRatingC, programRatingC, bondRatingC] as const;
export const allRatingNames = [`IssuerRating`, `ProgramRating`, `BondRating`] as const;
export type RatingName = (typeof allRatingNames)[number];

export const RatingCU = t.union([issuerRatingC, programRatingC, bondRatingC]);
export type RatingCU = typeof RatingCU;
export type RatingU = t.TypeOf<RatingCU>;

export type RatingMap<A> = { [K in RatingName]: A };


export const allIssuerOrProgramRatingC = [issuerRatingC, programRatingC] as const;
export const allIssuerOrProgramRatingNames = [`IssuerRating`, `ProgramRating`] as const;
export type IssuerOrProgramRatingName = (typeof allIssuerOrProgramRatingNames)[number];

export const IssuerOrProgramRatingCU = t.union([issuerRatingC, programRatingC]);
export type IssuerOrProgramRatingCU = typeof IssuerOrProgramRatingCU;
export type IssuerOrProgramRatingU = t.TypeOf<IssuerOrProgramRatingCU>;

export type IssuerOrProgramRatingMap<A> = { [K in IssuerOrProgramRatingName]: A };


