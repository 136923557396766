import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as RA from "fp-ts/lib/ReadonlyArray";
import * as RNEA from "fp-ts/lib/ReadonlyNonEmptyArray";
import * as t from "io-ts";

const isReadonlyNonEmptyArray = <C extends t.Mixed>(item: C, i: unknown, context: t.Context) => pipe(
  i,
  t.readonlyArray(item).decode,
  E.filterOrElse(
    (_): _ is RNEA.ReadonlyNonEmptyArray<t.TypeOf<C>> => RA.isNonEmpty(_),
    (): t.Errors => [{ value: i, context, message: "ReadonlyNonEmptyArray is invalid, it has no elements" }]
  ),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class ReadonlyNonEmptyArrayType<C extends t.Mixed, A = any, O = A, I = unknown> extends t.Type<A, O, I> {
  readonly type: C;
  readonly _tag: "ReadonlyNonEmptyArrayType";

  constructor(
    name: string,
    is: ReadonlyNonEmptyArrayType<C, A, O, I>["is"],
    validate: ReadonlyNonEmptyArrayType<C, A, O, I>["validate"],
    encode: ReadonlyNonEmptyArrayType<C, A, O, I>["encode"],
    type: C
  ) {
    super(
      name,
      is,
      validate,
      encode
    );
    this.type = type;
    this._tag = "ReadonlyNonEmptyArrayType";
  }
}

export const readonlyNonEmptyArrayC = <C extends t.Mixed>(item: C) => new ReadonlyNonEmptyArrayType<C, RNEA.ReadonlyNonEmptyArray<t.TypeOf<C>>, RNEA.ReadonlyNonEmptyArray<t.OutputOf<C>>, unknown>(
  `ReadonlyNonEmptyArray<${item.name}>`,
  (i: unknown): i is RNEA.ReadonlyNonEmptyArray<t.TypeOf<C>> => t.readonlyArray(item).is(i) && RA.isNonEmpty(i),
  (i: unknown, c: t.Context): t.Validation<RNEA.ReadonlyNonEmptyArray<t.TypeOf<C>>> => isReadonlyNonEmptyArray(item, i, c),
  RNEA.map(item.encode),
  item
);
