import { UserContentSubscriptionTypeCU as imported2_UserContentSubscriptionTypeCU } from "../domaintables/userContentSubscriptionTypes";
import { ipreoHoldingsC as imported10_ipreoHoldingsC } from "./ipreoHoldings";
import * as t from "io-ts";
import { tabCountsC as imported3_tabCountsC, InvestorActivityCU as imported2_InvestorActivityCU } from "./investorActivityActions";
import { actorC as imported7_actorC } from "./actor";

export const investorProfileInfoC = t.type({
  actor: imported7_actorC,
  notificationSubscriptions: t.readonlyArray(imported2_UserContentSubscriptionTypeCU),
  investorActivities: t.readonlyArray(imported2_InvestorActivityCU),
  tabCounts: imported3_tabCountsC,
  holdings: t.readonlyArray(imported10_ipreoHoldingsC)
});
export type InvestorProfileInfoC = typeof investorProfileInfoC;
export type InvestorProfileInfo = t.TypeOf<InvestorProfileInfoC>;


