import { openInSameTab, replacePageUrl, setPageUrl } from "@scripts/routes/router";
import type { PageRouteLink, PageRouting } from "@scripts/routes/routing/base";
import { isPageRouting } from "@scripts/routes/routing/base";
import type { Method, UrlInterface } from "@scripts/routes/urlInterface";

import type { RouterAction } from "../state/router";
import { routerSetPageLastMeta, routerSetPageMeta } from "../state/router";

const isNewPortal = (route: PageRouting): boolean => globalThis.location.pathname.split("/")[1] !== route.url().split("/")[1];

const getNavigateBase = (routerAction: typeof routerSetPageMeta | typeof routerSetPageLastMeta) => (setOrReplace: (r: PageRouting) => void) => (dispatch: (action: RouterAction) => void) => (r: PageRouteLink) => {
  const url = isPageRouting(r) ? r.url() : r.route.url;
  if (isPageRouting(r) && !isNewPortal(r)) {
    setOrReplace(r);
    dispatch(routerAction(r));
  } else {
    openInSameTab(url);
  }
};

export const getUrl = (route: PageRouting | UrlInterface<Method>) => {
  return isPageRouting(route) ? route.url() : route.url;
};

export const getNavigateSetPageMeta = getNavigateBase(routerSetPageMeta);
export const getNavigatePushFn = getNavigateSetPageMeta(setPageUrl);
export const getNavigateReplaceFn = getNavigateSetPageMeta(replacePageUrl);

export const navigateToNewTab = (r: PageRouteLink) => {
  const url = isPageRouting(r) ? r.url() : r.route.url;
  window.open(url, "_blank");
};

export const getNavigateBackFn = getNavigateBase(routerSetPageLastMeta)(replacePageUrl);
