import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const unscheduled = {
  _tag: `Unscheduled`,
  name: `Unscheduled`
} as const;

export const unscheduledTaggedC = t.type({
  _tag: t.literal(`Unscheduled`)
});
export type UnscheduledTaggedC = typeof unscheduledTaggedC;
export type UnscheduledTagged = t.TypeOf<UnscheduledTaggedC>;
export type Unscheduled = UnscheduledTagged & typeof unscheduled;
export const unscheduledC = pipe(unscheduledTaggedC, c => new t.Type<Unscheduled, UnscheduledTagged>(
  `Unscheduled`,
  (u: unknown): u is Unscheduled => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Unscheduled> => pipe(c.decode(u), E.map(x => ({ ...x, ...unscheduled }))),
  (x: Unscheduled): UnscheduledTagged => ({ ...x, _tag: `Unscheduled`}),
));
export type UnscheduledC = typeof unscheduledC;


export const biddingOpen = {
  _tag: `BiddingOpen`,
  name: `BiddingOpen`
} as const;

export const biddingOpenTaggedC = t.type({
  _tag: t.literal(`BiddingOpen`)
});
export type BiddingOpenTaggedC = typeof biddingOpenTaggedC;
export type BiddingOpenTagged = t.TypeOf<BiddingOpenTaggedC>;
export type BiddingOpen = BiddingOpenTagged & typeof biddingOpen;
export const biddingOpenC = pipe(biddingOpenTaggedC, c => new t.Type<BiddingOpen, BiddingOpenTagged>(
  `BiddingOpen`,
  (u: unknown): u is BiddingOpen => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BiddingOpen> => pipe(c.decode(u), E.map(x => ({ ...x, ...biddingOpen }))),
  (x: BiddingOpen): BiddingOpenTagged => ({ ...x, _tag: `BiddingOpen`}),
));
export type BiddingOpenC = typeof biddingOpenC;


export const biddingClosed = {
  _tag: `BiddingClosed`,
  name: `BiddingClosed`
} as const;

export const biddingClosedTaggedC = t.type({
  _tag: t.literal(`BiddingClosed`)
});
export type BiddingClosedTaggedC = typeof biddingClosedTaggedC;
export type BiddingClosedTagged = t.TypeOf<BiddingClosedTaggedC>;
export type BiddingClosed = BiddingClosedTagged & typeof biddingClosed;
export const biddingClosedC = pipe(biddingClosedTaggedC, c => new t.Type<BiddingClosed, BiddingClosedTagged>(
  `BiddingClosed`,
  (u: unknown): u is BiddingClosed => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BiddingClosed> => pipe(c.decode(u), E.map(x => ({ ...x, ...biddingClosed }))),
  (x: BiddingClosed): BiddingClosedTagged => ({ ...x, _tag: `BiddingClosed`}),
));
export type BiddingClosedC = typeof biddingClosedC;


export const bidAwarded = {
  _tag: `BidAwarded`,
  name: `BidAwarded`
} as const;

export const bidAwardedTaggedC = t.type({
  _tag: t.literal(`BidAwarded`)
});
export type BidAwardedTaggedC = typeof bidAwardedTaggedC;
export type BidAwardedTagged = t.TypeOf<BidAwardedTaggedC>;
export type BidAwarded = BidAwardedTagged & typeof bidAwarded;
export const bidAwardedC = pipe(bidAwardedTaggedC, c => new t.Type<BidAwarded, BidAwardedTagged>(
  `BidAwarded`,
  (u: unknown): u is BidAwarded => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, BidAwarded> => pipe(c.decode(u), E.map(x => ({ ...x, ...bidAwarded }))),
  (x: BidAwarded): BidAwardedTagged => ({ ...x, _tag: `BidAwarded`}),
));
export type BidAwardedC = typeof bidAwardedC;


export const allBiddingStateC = [unscheduledC, biddingOpenC, biddingClosedC, bidAwardedC] as const;
export const allBiddingStateNames = [`Unscheduled`, `BiddingOpen`, `BiddingClosed`, `BidAwarded`] as const;
export type BiddingStateName = (typeof allBiddingStateNames)[number];

export const BiddingStateCU = t.union([unscheduledC, biddingOpenC, biddingClosedC, bidAwardedC]);
export type BiddingStateCU = typeof BiddingStateCU;
export type BiddingStateU = t.TypeOf<BiddingStateCU>;

export const biddingStateOrd: Ord.Ord<BiddingStateU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allBiddingState = [unscheduled, biddingOpen, biddingClosed, bidAwarded] as const;
export type BiddingStateMap<A> = { [K in BiddingStateName]: A };


