import { WithStatusCU as imported393_WithStatusCU, WithStatusCU as imported395_WithStatusCU, WithStatusCU as imported392_WithStatusCU, withModInfoC as imported240_withModInfoC, withIdC as imported300_withIdC, withModInfoC as imported243_withModInfoC, WithStatusCU as imported396_WithStatusCU, WithStatusCU as imported394_WithStatusCU, WithStatusCU as imported403_WithStatusCU, WithStatusCU as imported401_WithStatusCU, withModInfoC as imported237_withModInfoC, withModInfoC as imported241_withModInfoC, withModInfoC as imported239_withModInfoC, withIdC as imported301_withIdC, withModInfoC as imported242_withModInfoC, withModInfoC as imported238_withModInfoC, WithStatusCU as imported399_WithStatusCU, WithStatusCU as imported398_WithStatusCU, WithStatusCU as imported400_WithStatusCU, WithStatusCU as imported402_WithStatusCU, WithStatusCU as imported397_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import { LocalDateC } from "../../codecs/localDate";
import { taggedContentC as imported227_taggedContentC, taggedContentC as imported228_taggedContentC, taggedContentC as imported220_taggedContentC, taggedContentC as imported225_taggedContentC, taggedContentC as imported219_taggedContentC, taggedContentC as imported218_taggedContentC, taggedContentC as imported221_taggedContentC, taggedContentC as imported226_taggedContentC, taggedContentC as imported229_taggedContentC, taggedContentC as imported222_taggedContentC, taggedContentC as imported223_taggedContentC, taggedContentC as imported224_taggedContentC } from "./taggedContent";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { eventC as imported20_eventC, eventC as imported21_eventC } from "./event";
import { PageCU as imported6_PageCU, PageCU as imported7_PageCU } from "../domaintables/pages";
import { roadShowC as imported22_roadShowC, roadShowC as imported23_roadShowC } from "./roadshow";
import { projectC as imported28_projectC, projectC as imported29_projectC } from "./project";
import * as E from "fp-ts/lib/Either";
import { issuerNewsC as imported26_issuerNewsC, issuerNewsC as imported27_issuerNewsC } from "./issuerNewsBase";
import { documentWithCategoryC as imported22_documentWithCategoryC, documentWithCategoryC as imported23_documentWithCategoryC } from "./document";
import { bondOfferingC as imported32_bondOfferingC, bondOfferingC as imported33_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported65_rfpC, rfpC as imported66_rfpC } from "./rfpBase";

export const featuredBondC = t.type({
  _tag: t.literal(`FeaturedBond`),
  bond: imported392_WithStatusCU(imported237_withModInfoC(imported218_taggedContentC(imported32_bondOfferingC)))
});
export type FeaturedBondC = typeof featuredBondC;
export type FeaturedBond = t.TypeOf<FeaturedBondC>;


export const rfp = {
  _tag: `Rfp`,
  id: 8
} as const;

export const rfpTaggedC = t.type({
  _tag: t.literal(`Rfp`)
});
export type RfpTaggedC = typeof rfpTaggedC;
export type RfpTagged = t.TypeOf<RfpTaggedC>;
export type Rfp = RfpTagged & typeof rfp;
export const rfpC = pipe(rfpTaggedC, c => new t.Type<Rfp, RfpTagged>(
  `Rfp`,
  (u: unknown): u is Rfp => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Rfp> => pipe(c.decode(u), E.map(x => ({ ...x, ...rfp }))),
  (x: Rfp): RfpTagged => ({ ...x, _tag: `Rfp`}),
));
export type RfpC = typeof rfpC;


export const featuredRfpC = t.type({
  _tag: t.literal(`FeaturedRfp`),
  rfp: imported393_WithStatusCU(imported238_withModInfoC(imported219_taggedContentC(imported65_rfpC)))
});
export type FeaturedRfpC = typeof featuredRfpC;
export type FeaturedRfp = t.TypeOf<FeaturedRfpC>;


export const news = {
  _tag: `News`,
  id: 3
} as const;

export const newsTaggedC = t.type({
  _tag: t.literal(`News`)
});
export type NewsTaggedC = typeof newsTaggedC;
export type NewsTagged = t.TypeOf<NewsTaggedC>;
export type News = NewsTagged & typeof news;
export const newsC = pipe(newsTaggedC, c => new t.Type<News, NewsTagged>(
  `News`,
  (u: unknown): u is News => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, News> => pipe(c.decode(u), E.map(x => ({ ...x, ...news }))),
  (x: News): NewsTagged => ({ ...x, _tag: `News`}),
));
export type NewsC = typeof newsC;


export const featuredNewsC = t.type({
  _tag: t.literal(`FeaturedNews`),
  news: imported394_WithStatusCU(imported239_withModInfoC(imported220_taggedContentC(imported26_issuerNewsC)))
});
export type FeaturedNewsC = typeof featuredNewsC;
export type FeaturedNews = t.TypeOf<FeaturedNewsC>;


export const event = {
  _tag: `Event`,
  id: 2
} as const;

export const eventTaggedC = t.type({
  _tag: t.literal(`Event`)
});
export type EventTaggedC = typeof eventTaggedC;
export type EventTagged = t.TypeOf<EventTaggedC>;
export type Event = EventTagged & typeof event;
export const eventC = pipe(eventTaggedC, c => new t.Type<Event, EventTagged>(
  `Event`,
  (u: unknown): u is Event => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Event> => pipe(c.decode(u), E.map(x => ({ ...x, ...event }))),
  (x: Event): EventTagged => ({ ...x, _tag: `Event`}),
));
export type EventC = typeof eventC;


export const featuredEventC = t.type({
  _tag: t.literal(`FeaturedEvent`),
  event: imported395_WithStatusCU(imported240_withModInfoC(imported20_eventC))
});
export type FeaturedEventC = typeof featuredEventC;
export type FeaturedEvent = t.TypeOf<FeaturedEventC>;


export const document = {
  _tag: `Document`,
  id: 1
} as const;

export const documentTaggedC = t.type({
  _tag: t.literal(`Document`)
});
export type DocumentTaggedC = typeof documentTaggedC;
export type DocumentTagged = t.TypeOf<DocumentTaggedC>;
export type Document = DocumentTagged & typeof document;
export const documentC = pipe(documentTaggedC, c => new t.Type<Document, DocumentTagged>(
  `Document`,
  (u: unknown): u is Document => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Document> => pipe(c.decode(u), E.map(x => ({ ...x, ...document }))),
  (x: Document): DocumentTagged => ({ ...x, _tag: `Document`}),
));
export type DocumentC = typeof documentC;


export const featuredDocumentC = t.type({
  _tag: t.literal(`FeaturedDocument`),
  document: imported396_WithStatusCU(imported241_withModInfoC(imported221_taggedContentC(imported22_documentWithCategoryC)))
});
export type FeaturedDocumentC = typeof featuredDocumentC;
export type FeaturedDocument = t.TypeOf<FeaturedDocumentC>;


export const project = {
  _tag: `Project`,
  id: 6
} as const;

export const projectTaggedC = t.type({
  _tag: t.literal(`Project`)
});
export type ProjectTaggedC = typeof projectTaggedC;
export type ProjectTagged = t.TypeOf<ProjectTaggedC>;
export type Project = ProjectTagged & typeof project;
export const projectC = pipe(projectTaggedC, c => new t.Type<Project, ProjectTagged>(
  `Project`,
  (u: unknown): u is Project => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Project> => pipe(c.decode(u), E.map(x => ({ ...x, ...project }))),
  (x: Project): ProjectTagged => ({ ...x, _tag: `Project`}),
));
export type ProjectC = typeof projectC;


export const featuredProjectC = t.type({
  _tag: t.literal(`FeaturedProject`),
  project: imported397_WithStatusCU(imported242_withModInfoC(imported222_taggedContentC(imported28_projectC)))
});
export type FeaturedProjectC = typeof featuredProjectC;
export type FeaturedProject = t.TypeOf<FeaturedProjectC>;


export const roadShow = {
  _tag: `RoadShow`,
  id: 7
} as const;

export const roadShowTaggedC = t.type({
  _tag: t.literal(`RoadShow`)
});
export type RoadShowTaggedC = typeof roadShowTaggedC;
export type RoadShowTagged = t.TypeOf<RoadShowTaggedC>;
export type RoadShow = RoadShowTagged & typeof roadShow;
export const roadShowC = pipe(roadShowTaggedC, c => new t.Type<RoadShow, RoadShowTagged>(
  `RoadShow`,
  (u: unknown): u is RoadShow => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, RoadShow> => pipe(c.decode(u), E.map(x => ({ ...x, ...roadShow }))),
  (x: RoadShow): RoadShowTagged => ({ ...x, _tag: `RoadShow`}),
));
export type RoadShowC = typeof roadShowC;


export const featuredRoadshowC = t.type({
  _tag: t.literal(`FeaturedRoadshow`),
  roadshow: imported300_withIdC(imported243_withModInfoC(imported223_taggedContentC(imported22_roadShowC)))
});
export type FeaturedRoadshowC = typeof featuredRoadshowC;
export type FeaturedRoadshow = t.TypeOf<FeaturedRoadshowC>;


export const page = {
  _tag: `Page`,
  id: 5
} as const;

export const pageTaggedC = t.type({
  _tag: t.literal(`Page`)
});
export type PageTaggedC = typeof pageTaggedC;
export type PageTagged = t.TypeOf<PageTaggedC>;
export type Page = PageTagged & typeof page;
export const pageC = pipe(pageTaggedC, c => new t.Type<Page, PageTagged>(
  `Page`,
  (u: unknown): u is Page => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Page> => pipe(c.decode(u), E.map(x => ({ ...x, ...page }))),
  (x: Page): PageTagged => ({ ...x, _tag: `Page`}),
));
export type PageC = typeof pageC;


export const featuredPageC = t.type({
  _tag: t.literal(`FeaturedPage`),
  page: imported6_PageCU,
  header: t.string
});
export type FeaturedPageC = typeof featuredPageC;
export type FeaturedPage = t.TypeOf<FeaturedPageC>;


export const allFeaturedItemC = [featuredBondC, featuredRfpC, featuredNewsC, featuredEventC, featuredDocumentC, featuredProjectC, featuredRoadshowC, featuredPageC] as const;
export const allFeaturedItemNames = [`FeaturedBond`, `FeaturedRfp`, `FeaturedNews`, `FeaturedEvent`, `FeaturedDocument`, `FeaturedProject`, `FeaturedRoadshow`, `FeaturedPage`] as const;
export type FeaturedItemName = (typeof allFeaturedItemNames)[number];

export const FeaturedItemCU = t.union([featuredBondC, featuredRfpC, featuredNewsC, featuredEventC, featuredDocumentC, featuredProjectC, featuredRoadshowC, featuredPageC]);
export type FeaturedItemCU = typeof FeaturedItemCU;
export type FeaturedItemU = t.TypeOf<FeaturedItemCU>;

export type FeaturedItemMap<A> = { [K in FeaturedItemName]: A };


export const featuredItemWithMetaC = t.type({
  item: FeaturedItemCU,
  displayUntil: optionFromNullable(LocalDateC),
  sortOrder: t.number
});
export type FeaturedItemWithMetaC = typeof featuredItemWithMetaC;
export type FeaturedItemWithMeta = t.TypeOf<FeaturedItemWithMetaC>;


export const featuredItemsAvailableC = t.type({
  bonds: t.readonlyArray(imported398_WithStatusCU(imported224_taggedContentC(imported33_bondOfferingC))),
  rfps: t.readonlyArray(imported399_WithStatusCU(imported225_taggedContentC(imported66_rfpC))),
  news: t.readonlyArray(imported400_WithStatusCU(imported226_taggedContentC(imported27_issuerNewsC))),
  events: t.readonlyArray(imported401_WithStatusCU(imported21_eventC)),
  documents: t.readonlyArray(imported402_WithStatusCU(imported227_taggedContentC(imported23_documentWithCategoryC))),
  projects: t.readonlyArray(imported403_WithStatusCU(imported228_taggedContentC(imported29_projectC))),
  roadshows: t.readonlyArray(imported301_withIdC(imported229_taggedContentC(imported23_roadShowC))),
  pages: t.readonlyArray(imported7_PageCU)
});
export type FeaturedItemsAvailableC = typeof featuredItemsAvailableC;
export type FeaturedItemsAvailable = t.TypeOf<FeaturedItemsAvailableC>;


export const featuredItemPostBodyC = t.type({
  id: t.number,
  itemId: t.number,
  itemTypeId: t.number,
  header: optionFromNullable(t.string),
  displayUntil: optionFromNullable(LocalDateC),
  sortOrder: t.number
});
export type FeaturedItemPostBodyC = typeof featuredItemPostBodyC;
export type FeaturedItemPostBody = t.TypeOf<FeaturedItemPostBodyC>;


export const offering = {
  _tag: `Offering`,
  id: 4
} as const;

export const offeringTaggedC = t.type({
  _tag: t.literal(`Offering`)
});
export type OfferingTaggedC = typeof offeringTaggedC;
export type OfferingTagged = t.TypeOf<OfferingTaggedC>;
export type Offering = OfferingTagged & typeof offering;
export const offeringC = pipe(offeringTaggedC, c => new t.Type<Offering, OfferingTagged>(
  `Offering`,
  (u: unknown): u is Offering => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, Offering> => pipe(c.decode(u), E.map(x => ({ ...x, ...offering }))),
  (x: Offering): OfferingTagged => ({ ...x, _tag: `Offering`}),
));
export type OfferingC = typeof offeringC;


export const allFeaturedItemTypeC = [documentC, eventC, newsC, offeringC, pageC, projectC, roadShowC, rfpC] as const;
export const allFeaturedItemTypeNames = [`Document`, `Event`, `News`, `Offering`, `Page`, `Project`, `RoadShow`, `Rfp`] as const;
export type FeaturedItemTypeName = (typeof allFeaturedItemTypeNames)[number];

export const FeaturedItemTypeCU = t.union([documentC, eventC, newsC, offeringC, pageC, projectC, roadShowC, rfpC]);
export type FeaturedItemTypeCU = typeof FeaturedItemTypeCU;
export type FeaturedItemTypeU = t.TypeOf<FeaturedItemTypeCU>;

export const featuredItemTypeOrd: Ord.Ord<FeaturedItemTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allFeaturedItemType = [document, event, news, offering, page, project, roadShow, rfp] as const;
export type FeaturedItemTypeMap<A> = { [K in FeaturedItemTypeName]: A };


