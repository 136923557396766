import type { Eq } from "fp-ts/lib/Eq";
import { fromCompare } from "fp-ts/lib/Ord";
import { type Ordering } from "fp-ts/lib/Ordering";

import type { Joda, JodaDateInput } from "./joda";
import { toLDT } from "./joda";

const localDateOrdering = <A extends Joda.LocalDate | Joda.LocalDateTime>(
  first: A,
  second: A
): Ordering => {
  const ldtFirst = toLDT(first);
  const ldtSecond = toLDT(second);
  return ldtFirst.isBefore(ldtSecond)
    ? -1
    : ldtFirst.isAfter(ldtSecond)
      ? 1
      : 0;
};

export const LocalDateOrd = fromCompare<JodaDateInput>(localDateOrdering);

export const LocalDateEq: Eq<Joda.LocalDate | Joda.LocalDateTime> = {
  equals: (f, s) => f.hashCode() === s.hashCode(),
};
