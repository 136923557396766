import { either } from "io-ts-types/lib/either";
import * as t from "io-ts";

export const browserRedirectC = t.type({
  status: either(t.number, t.number),
  redirect: t.string
});
export type BrowserRedirectC = typeof browserRedirectC;
export type BrowserRedirect = t.TypeOf<BrowserRedirectC>;


