import * as t from "io-ts";
import { Ord as numberOrd } from "fp-ts/lib/number";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";

export const clientFeatureFlagC = t.type({
  featureFlagId: t.number,
  enabled: t.boolean
});
export type ClientFeatureFlagC = typeof clientFeatureFlagC;
export type ClientFeatureFlag = t.TypeOf<ClientFeatureFlagC>;


export const clientFeatureFlagsC = t.type({
  ffs: readonlyMapFromEntries(t.number, numberOrd, t.boolean)
});
export type ClientFeatureFlagsC = typeof clientFeatureFlagsC;
export type ClientFeatureFlags = t.TypeOf<ClientFeatureFlagsC>;


