import * as t from "io-ts";

import { allC } from "./filter";

export const activeC = t.literal("active");
export const archivedC = t.literal("archived");

export const archiveStatusC = t.union([activeC, archivedC, allC]);
export type ArchiveStatusC = typeof archiveStatusC;
export type ArchiveStatus = t.TypeOf<ArchiveStatusC>;
