import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";

const issuerIdRegex = /^i(\d+)$/;
type IssuerId = `i${number}`;

export const issuerIdC = new t.Type<number, IssuerId>(
  "issuerId",
  (u: unknown): u is number => typeof u === "string" && issuerIdRegex.test(u),
  (u: unknown, c: t.Context): t.Validation<number> => {
    if (typeof u === "string") {
      return NumberFromString.decode(issuerIdRegex.exec(u)?.[1]);
    }
    return E.left([{ value: u, context: c, message: "Expecting string for issuerId" }]);
  },
  issuerId => `i${issuerId}`,
);
