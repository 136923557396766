
import type { BLConfigWithLog } from "@scripts/bondlink";
import type { RespOrErrors } from "@scripts/fetch";
import { constVoid } from "@scripts/fp-ts";

import { ErrorBoundary } from "./ErrorBoundary";
import { ErrorContent } from "./ErrorContent";
import { FormattedRespErrors } from "./FormattedRespErrors";
import { ServerError } from "./ServerError";

export const RendererError = (config: BLConfigWithLog) => (re: RespOrErrors) =>
  <ErrorBoundary config={config}>
    <ServerError>
      <ErrorContent resetErrorState={constVoid}>
        <FormattedRespErrors {...re} />
      </ErrorContent>
    </ServerError>
  </ErrorBoundary>;
