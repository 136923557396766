import { pipe } from "fp-ts/lib/function";
import * as O from "fp-ts/lib/Option";

// eslint-disable-next-line no-undefined
const undefinedNaN = (n: number): number | undefined => isNaN(n) ? undefined : n;

export const safePercent = (total: number, remaining: number): number => pipe(
  (remaining / total),
  O.fromNullableK(undefinedNaN),
  O.fold(
    () => 0,
    (n) => n * 100,
  )
);

export const safeAverage = (sum: number, divisor: number) => pipe(
  (sum / divisor),
  undefinedNaN,
  O.fromNullable
);

export const progressPercent = (total: number, remaining: number) =>
  remaining > total ? 0 : total === 0 ? 100 : Math.round((1 - (remaining / total)) * 100);

export const progressPercentString = (total: number, remaining: number): string => `${progressPercent(total, remaining)}%`;

export type RangeTuple = [number, number];

export const normalizeInputToNewRange = (input: number, [inputMin, inputMax]: RangeTuple, [outputMin, outputMax]: RangeTuple) => {
  if (inputMax === inputMin && input < inputMin) return outputMin;
  if (inputMax === inputMin && input > inputMax || inputMax === inputMin) return outputMax;

  return ((input - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin;
};

export const normalizeInputToNewRangeBounded = (
  input: number,
  [inputMin, inputMax]: RangeTuple,
  [outputMin, outputMax]: RangeTuple
) => input > inputMax
    ? outputMax
    : input < inputMin
      ? outputMin
      : normalizeInputToNewRange(input, [inputMin, inputMax], [outputMin, outputMax]);

export const pxToEm = (value: number): number => value / 16;

export const emToPx = (value: number): number => value * 16;
