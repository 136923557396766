import * as E from "fp-ts/lib/Either";
import * as t from "io-ts";
import { NumberFromString } from "io-ts-types";

export const NumberFromUnknown = new t.Type(
  "NumberFromUnknown",
  NumberFromString.is,
  (i, c) => E.orElse(() => NumberFromString.validate(i, c))(t.number.validate(i, c)),
  NumberFromString.encode
);
