import { readonlySetFromArray } from "io-ts-types/lib/readonlySetFromArray";
import { UserContentSubscriptionTypeCU as imported3_UserContentSubscriptionTypeCU, userContentSubscriptionTypeOrd as imported1_userContentSubscriptionTypeOrd } from "../domaintables/userContentSubscriptionTypes";
import { issuerWithAddressC as imported8_issuerWithAddressC } from "./issuer";
import * as t from "io-ts";

export const notificationSubscriptionsStatusC = t.type({
  notificationsEnabled: t.boolean,
  subscribedIssuers: t.readonlyArray(imported8_issuerWithAddressC),
  subscribedContentTypes: readonlySetFromArray(imported3_UserContentSubscriptionTypeCU, imported1_userContentSubscriptionTypeOrd)
});
export type NotificationSubscriptionsStatusC = typeof notificationSubscriptionsStatusC;
export type NotificationSubscriptionsStatus = t.TypeOf<NotificationSubscriptionsStatusC>;


export const userIssuerSubscriptionsPostC = t.type({
  issuerIds: t.readonlyArray(t.number),
  enabled: t.boolean
});
export type UserIssuerSubscriptionsPostC = typeof userIssuerSubscriptionsPostC;
export type UserIssuerSubscriptionsPost = t.TypeOf<UserIssuerSubscriptionsPostC>;


