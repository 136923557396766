import * as t from "io-ts";

import { allC } from "@scripts/codecs/filter";
import { QueryStringArray } from "@scripts/codecs/routing";
import { allMethodOfSaleNames } from "@scripts/generated/domaintables/methodsOfSale";


export const otherIssuerFiltersC = t.union([t.literal("hasActiveBonds"), t.literal("hasActiveRfps")]);
type OtherIssuerFiltersC = typeof otherIssuerFiltersC;
export type OtherIssuerFilters = t.TypeOf<OtherIssuerFiltersC>;
export const allOtherIssuerFilters = otherIssuerFiltersC.types.map(a => a.value);

const otherIssuerFiltersKey = "otherFilters";
export const otherIssuerFiltersParamC = t.type({ [otherIssuerFiltersKey]: QueryStringArray(otherIssuerFiltersC) });
type OtherIssuerFiltersParamC = typeof otherIssuerFiltersParamC;
export type OtherIssuerFiltersParam = t.TypeOf<OtherIssuerFiltersParamC>;
export const otherIssuerFiltersParamDefault: OtherIssuerFiltersParam = { [otherIssuerFiltersKey]: [] };


export const methodOfSaleTypeC = t.union([
  allC,
  t.literal(allMethodOfSaleNames[0]),
  t.literal(allMethodOfSaleNames[1]),
]);
export type MethodOfSaleType = t.TypeOf<typeof methodOfSaleTypeC>;

const methodOfSaleKey = "methodOfSale";
export const methodOfSaleParamC = t.type({ [methodOfSaleKey]: methodOfSaleTypeC });
type MethodOfSaleParamC = typeof methodOfSaleParamC;
export type MethodOfSaleParam = t.TypeOf<MethodOfSaleParamC>;
export const methodOfSaleParamDefault: MethodOfSaleParam = { [methodOfSaleKey]: allC.value };
