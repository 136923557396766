import { SortColumnCU as imported2_SortColumnCU } from "../domaintables/sortColumns";
import * as t from "io-ts";
import { pageSortC as imported4_pageSortC } from "./pageSorts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { BucketCU as imported5_BucketCU } from "../domaintables/pages";
import { markdownC } from "../../codecs/markdown";

export class pageConfigCC<A1 extends t.Mixed>{ codec = (A1: A1) => t.type({
  page: A1,
  title: optionFromNullable(t.string),
  introSentence: optionFromNullable(markdownC),
  introSentencePlainText: optionFromNullable(t.string),
  sort: optionFromNullable(imported4_pageSortC),
  possibleSortColumns: t.readonlyArray(imported2_SortColumnCU),
  pageHeader: optionFromNullable(imported5_BucketCU)
})}
export const pageConfigC = <A1 extends t.Mixed>(A1: A1) => new pageConfigCC<A1>().codec(A1);
export type PageConfigC<A1 extends t.Mixed> = ReturnType<pageConfigCC<A1>["codec"]>;
export type PageConfig<A1> = t.TypeOf<PageConfigC<t.Type<A1>>>;

