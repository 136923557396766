import * as t from "io-ts";
import { Ord as stringOrd } from "fp-ts/lib/string";
import * as E from "fp-ts/lib/Either";
import { pipe } from "fp-ts/lib/function";
import * as Ord from "fp-ts/lib/Ord";

export const fixedRate = {
  _tag: `FixedRate`,
  id: 1,
  name: `Fixed Rate`
} as const;

export const fixedRateTaggedC = t.type({
  _tag: t.literal(`FixedRate`)
});
export type FixedRateTaggedC = typeof fixedRateTaggedC;
export type FixedRateTagged = t.TypeOf<FixedRateTaggedC>;
export type FixedRate = FixedRateTagged & typeof fixedRate;
export const fixedRateC = pipe(fixedRateTaggedC, c => new t.Type<FixedRate, FixedRateTagged>(
  `FixedRate`,
  (u: unknown): u is FixedRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FixedRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...fixedRate }))),
  (x: FixedRate): FixedRateTagged => ({ ...x, _tag: `FixedRate`}),
));
export type FixedRateC = typeof fixedRateC;


export const floatingRate = {
  _tag: `FloatingRate`,
  id: 2,
  name: `Floating Rate`
} as const;

export const floatingRateTaggedC = t.type({
  _tag: t.literal(`FloatingRate`)
});
export type FloatingRateTaggedC = typeof floatingRateTaggedC;
export type FloatingRateTagged = t.TypeOf<FloatingRateTaggedC>;
export type FloatingRate = FloatingRateTagged & typeof floatingRate;
export const floatingRateC = pipe(floatingRateTaggedC, c => new t.Type<FloatingRate, FloatingRateTagged>(
  `FloatingRate`,
  (u: unknown): u is FloatingRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, FloatingRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...floatingRate }))),
  (x: FloatingRate): FloatingRateTagged => ({ ...x, _tag: `FloatingRate`}),
));
export type FloatingRateC = typeof floatingRateC;


export const variableRate = {
  _tag: `VariableRate`,
  id: 3,
  name: `Variable Rate`
} as const;

export const variableRateTaggedC = t.type({
  _tag: t.literal(`VariableRate`)
});
export type VariableRateTaggedC = typeof variableRateTaggedC;
export type VariableRateTagged = t.TypeOf<VariableRateTaggedC>;
export type VariableRate = VariableRateTagged & typeof variableRate;
export const variableRateC = pipe(variableRateTaggedC, c => new t.Type<VariableRate, VariableRateTagged>(
  `VariableRate`,
  (u: unknown): u is VariableRate => E.isRight(c.decode(u)),
  (u: unknown): E.Either<t.Errors, VariableRate> => pipe(c.decode(u), E.map(x => ({ ...x, ...variableRate }))),
  (x: VariableRate): VariableRateTagged => ({ ...x, _tag: `VariableRate`}),
));
export type VariableRateC = typeof variableRateC;


export const allOfferingTypeC = [fixedRateC, floatingRateC, variableRateC] as const;
export const allOfferingTypeNames = [`FixedRate`, `FloatingRate`, `VariableRate`] as const;
export type OfferingTypeName = (typeof allOfferingTypeNames)[number];

export const OfferingTypeCU = t.union([fixedRateC, floatingRateC, variableRateC]);
export type OfferingTypeCU = typeof OfferingTypeCU;
export type OfferingTypeU = t.TypeOf<OfferingTypeCU>;

export const offeringTypeOrd: Ord.Ord<OfferingTypeU> = pipe(stringOrd, Ord.contramap(x => x._tag));
export const allOfferingType = [fixedRate, floatingRate, variableRate] as const;
export type OfferingTypeMap<A> = { [K in OfferingTypeName]: A };


