import * as RA from "fp-ts/lib/ReadonlyArray";

import { flow, O, pipe, RNEA } from "@scripts/fp-ts";

import type { ReadonlyBig } from "./bigNumberC";
import { zero } from "./bigNumberC";

export const min: (a: ReadonlyArray<ReadonlyBig>) => O.Option<ReadonlyBig> =
  flow(
    RNEA.fromReadonlyArray,
    O.map(_ => {
      const [[head], rest] = pipe(_, RNEA.splitAt(1));

      return pipe(
        rest,
        RA.reduce(head, (acc, curr) => curr.lt(acc) ? curr : acc)
      );
    })
  );

export const max: (a: ReadonlyArray<ReadonlyBig>) => ReadonlyBig =
  RA.reduce<ReadonlyBig, ReadonlyBig>(zero, (second, first) => second.gt(first) ? second : first);
