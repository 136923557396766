export * from "fp-ts/lib/TaskEither";
import type { Either } from "fp-ts/lib/Either";
import type { TaskEither } from "fp-ts/lib/TaskEither";
import * as TE from "fp-ts/lib/TaskEither";

import { tap } from "@scripts/util/tap";

import type { Match } from "./lib/types";

export type Any = TaskEither<unknown, unknown>;
export type InferLeft<A extends Match.AnyTaskEither> = A extends TaskEither<infer Left, any> ? Left : never; // eslint-disable-line @typescript-eslint/no-explicit-any
export type InferRight<A extends Match.AnyTaskEither> = A extends TaskEither<any, infer Right> ? Right : never; // eslint-disable-line @typescript-eslint/no-explicit-any

export const run = <E, A>(ma: TaskEither<E, A>): Promise<Either<E, A>> => ma();

export const biTap = <L, R>(onLeft: (left: L) => void, onRight: (right: R) => void) => TE.bimap(
  tap(onLeft),
  tap(onRight)
);
