import type { EqualityFn, NoInfer } from "react-redux";
import * as O from "fp-ts/lib/Option";
import type { Action } from "redux";

import { stateProvince } from "@scripts/generated/domaintables/sectors";
import type { Bank } from "@scripts/generated/models/bank";
import type { Flash } from "@scripts/generated/models/flash";
import type { Issuer } from "@scripts/generated/models/issuer";
import type { UserWithRoles } from "@scripts/generated/models/user";
import type { AnyPageMeta } from "@scripts/routes/routing/base";
import { localDateTimeNow } from "@scripts/syntax/date/joda";

import type { FlashAction } from "./flash";
import type { NotificationAction, NotificationStore } from "./notifications";
import { emptyNotificationStore } from "./notifications";
import type { RouterAction } from "./router";
import type { UserAction } from "./user";

export type ReduxAction = Action<"@@redux">;
export type ReduxDevtoolAction = Action<"@@INIT">;

// BaseActions is a tagged union of T extends Action<string>
export type BaseActions =
  NotificationAction
  | RouterAction
  | UserAction
  | ReduxAction
  | ReduxDevtoolAction
  | FlashAction;

export function isNotificationAction(action: Action<string> | BaseActions): action is NotificationAction {
  return action.type.startsWith("NOTIFICATION_");
}
export function isRouterAction(action: Action<string> | BaseActions): action is RouterAction {
  return action.type.startsWith("ROUTER_");
}
export function isUserAction(action: Action<string> | BaseActions): action is UserAction {
  return action.type.startsWith("USER_");
}
export function isReduxAction(action: Action<string> | BaseActions): action is ReduxAction {
  return action.type.startsWith("@@redux");
}
export function isDevtoolAction(action: Action<string> | BaseActions): action is ReduxDevtoolAction {
  return action.type.startsWith("@@INIT");
}
export function isFlashAction(action: Action<string> | BaseActions): action is FlashAction {
  return action.type.startsWith("FLASH_");
}


export type BaseState = {
  notifications: NotificationStore;
  user: UserWithRoles;
  flash: ReadonlyArray<Flash>;
};
export type PageHistoryOldToNew = ReadonlyArray<AnyPageMeta>;
export type SelectorHook<S extends BaseState> = <K extends keyof S>(k: K, equalityFn?: EqualityFn<NoInfer<S[K]>>) => S[K];
export type PageState = {
  page: AnyPageMeta;
  pageHistoryOldToNew: PageHistoryOldToNew;
};

export const emptyIssuer: Issuer = {
  id: 0,
  clientId: 0,
  bankId: O.none,
  issuerType: stateProvince,
  addressId: O.none,
  primaryOfficerId: O.none,
  welcomeSigneeId: O.none,
  slug: "",
  name: "",
  shortName: O.none,
  subheader: O.none,
  email: "",
  phoneNumber: "",
  phoneExtension: O.none,
  logoUrl: O.none,
  logoIsRectangle: false,
  website: O.none,
  customDomain: O.none,
  published: false,
  publishDate: O.none,
  hasSitePassword: false,
  domainOwner: false,
  suspended: false,
};

export const emptyUser = {
  user: {
    id: 0,
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: O.none,
    phoneExtension: O.none,
    company: O.none,
    title: O.none,
    primaryClientId: O.none,
    subscribedToHubspot: false,
    persona: O.none,
    investorType: O.none,
    created: localDateTimeNow(),
    termsAccepted: O.none,
    mfaPhone: O.none,
    mfaVerified: false,
  },
  clientType: "",
  roleIds: [],
  bondlinkAdmin: false,
  bondlinkAdminRole: O.none,
};

export const emptyBank: Bank = {
  id: 0,
  clientId: 0,
  slug: "",
  name: "",
  email: "",
  logoUrl: O.none,
};

export const initialBaseState: BaseState = {
  notifications: emptyNotificationStore,
  user: emptyUser,
  flash: [],
};
