import * as t from "io-ts";

export const siteTemplateC = t.type({
  primaryColor: t.string,
  secondaryColor: t.string,
  primaryForBg: t.boolean
});
export type SiteTemplateC = typeof siteTemplateC;
export type SiteTemplate = t.TypeOf<SiteTemplateC>;


