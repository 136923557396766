import { SortColumnCU as imported3_SortColumnCU } from "../domaintables/sortColumns";
import { SortDirCU as imported1_SortDirCU } from "../domaintables/sortDir";
import * as t from "io-ts";
import { SortableCU as imported4_SortableCU, SortableCU as imported5_SortableCU } from "../domaintables/pages";

export const pageSortC = t.type({
  column: imported3_SortColumnCU,
  direction: imported1_SortDirCU
});
export type PageSortC = typeof pageSortC;
export type PageSort = t.TypeOf<PageSortC>;


export const pageSortsC = t.readonlyArray(t.tuple([imported4_SortableCU, pageSortC]));
export type PageSortsC = typeof pageSortsC;
export type PageSorts = t.TypeOf<PageSortsC>;


export const postSortC = t.type({
  page: imported5_SortableCU,
  sort: pageSortC
});
export type PostSortC = typeof postSortC;
export type PostSort = t.TypeOf<PostSortC>;


