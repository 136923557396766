import type { RefObject } from "react";
import { constVoid, pipe } from "fp-ts/lib/function";

import { O } from "@scripts/fp-ts";

export const foldRef = <B>(onNone: () => B) => <A extends Element>(onSome: (e: A) => B) => (r: RefObject<A>) => pipe(
  r.current,
  O.fromNullable,
  O.fold(onNone, onSome)
);

export const foldVoidRef = foldRef(constVoid);
