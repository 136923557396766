import { AlertTypeCU as imported1_AlertTypeCU } from "../domaintables/alertTypes";
import * as t from "io-ts";

export const flashC = t.type({
  type: imported1_AlertTypeCU,
  safe: t.boolean,
  msg: t.string
});
export type FlashC = typeof flashC;
export type Flash = t.TypeOf<FlashC>;


