import { OnboardingStepCU as imported1_OnboardingStepCU } from "../domaintables/onboardingSteps";
import * as t from "io-ts";

export const onboardingStepStatusC = t.type({
  onboardingStep: imported1_OnboardingStepCU,
  completed: t.boolean
});
export type OnboardingStepStatusC = typeof onboardingStepStatusC;
export type OnboardingStepStatus = t.TypeOf<OnboardingStepStatusC>;


