import { taggedContentC as imported200_taggedContentC, taggedContentC as imported201_taggedContentC } from "./taggedContent";
import { bondOfferingWithRelatedContentC as imported16_bondOfferingWithRelatedContentC, bondOfferingWithRelatedContentC as imported17_bondOfferingWithRelatedContentC } from "./bondOffering";
import { WithStatusCU as imported371_WithStatusCU, WithStatusCU as imported372_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";

export const portalOfferingsC = t.type({
  active: t.readonlyArray(imported371_WithStatusCU(imported200_taggedContentC(imported16_bondOfferingWithRelatedContentC))),
  archived: t.readonlyArray(imported372_WithStatusCU(imported201_taggedContentC(imported17_bondOfferingWithRelatedContentC)))
});
export type PortalOfferingsC = typeof portalOfferingsC;
export type PortalOfferings = t.TypeOf<PortalOfferingsC>;


