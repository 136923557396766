import { WithStatusCU as imported355_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { mediaC as imported69_mediaC, mediaUploadResponseC as imported26_mediaUploadResponseC, mediaUploadResponseC as imported27_mediaUploadResponseC } from "./media";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { PhotoEditableCU as imported12_PhotoEditableCU, PhotoEditableCU as imported13_PhotoEditableCU } from "../domaintables/pages";
import { SectorCU as imported19_SectorCU } from "../domaintables/sectors";

export const headerPhotoC = t.type({
  page: imported12_PhotoEditableCU,
  media: imported355_WithStatusCU(imported69_mediaC),
  commonHeaderPhotoId: optionFromNullable(t.number)
});
export type HeaderPhotoC = typeof headerPhotoC;
export type HeaderPhoto = t.TypeOf<HeaderPhotoC>;


export const headerPhotoPostC = t.type({
  id: optionFromNullable(t.number),
  page: imported13_PhotoEditableCU,
  uploadResponse: optionFromNullable(imported26_mediaUploadResponseC),
  commonHeaderPhotoId: optionFromNullable(t.number)
});
export type HeaderPhotoPostC = typeof headerPhotoPostC;
export type HeaderPhotoPost = t.TypeOf<HeaderPhotoPostC>;


export const commonHeaderPhotoC = t.type({
  media: imported27_mediaUploadResponseC,
  sector: imported19_SectorCU
});
export type CommonHeaderPhotoC = typeof commonHeaderPhotoC;
export type CommonHeaderPhoto = t.TypeOf<CommonHeaderPhotoC>;


