import { map } from "fp-ts/lib/Identity";
import type { Iso, Prism } from "monocle-ts";
import type { Newtype } from "newtype-ts/lib";
import { iso, prism } from "newtype-ts/lib";

import { Struct } from "@scripts/fp-ts";

import * as windowLocation from "../syntax/windowLocation";

const ENCODED_PATH_KIND = "encodedPath";

export interface EncodedPath {
  kind: typeof ENCODED_PATH_KIND;
  toString(): string;
}

export const encodedPath = (s: string): EncodedPath => ({ kind: ENCODED_PATH_KIND, toString: () => s });

export const isEncodedPath = (a: unknown): a is EncodedPath => {
  return Struct.is(a) && a["kind"] === ENCODED_PATH_KIND && typeof a.toString === "function";
};

export const mkPath = (parts: Array<string | EncodedPath>): string =>
  map((p: string) => p.startsWith("/") ? p : "/" + p)(
    parts.map((p: string | EncodedPath) => isEncodedPath(p) ? p.toString() : encodeURIComponent(p)).join("/"));

export const mkQS = (pairs: Array<[string, string]>): string =>
  map((ss: string[]) => ss.length === 0 ? "" : "?" + ss.join("&"))(
    pairs.map(encodeURIPair));

export const encodeURIPair = ([k, v]: [string, string] | readonly [string, string]) => encodeURIComponent(k) + "=" + encodeURIComponent(v);

type AbsolutePath = Newtype<{ readonly AbsolutePath: unique symbol }, string>;
const isAbsolutePath = (s: string) => s.startsWith("/");

/**
 * Example of potential usage when dealing with an unknown value
 *
 * const apO = apPrism.getOption("foo");
 */
export const apPrism: Prism<string, AbsolutePath> = prism<AbsolutePath>(isAbsolutePath);
export const apIso: Iso<AbsolutePath, string> = iso<AbsolutePath>();

export function absPathToUrl(rp: AbsolutePath): (origin?: string | URL) => URL {
  return (origin?: string | URL) => new URL(apIso.unwrap(rp), typeof origin === "undefined" ? new URL(windowLocation.currentOrigin()) : origin);
}

const isNewPage = (url: string): boolean => globalThis.location.href !== globalThis.location.origin + url || globalThis.location.href !== url;
// eslint-disable-next-line no-restricted-syntax
export const pushState = (url: string): void => { if (isNewPage(url)) globalThis.history.pushState({}, "", url); };
export const setPageUrl = (r: { url: () => string }): void => pushState(r.url());
// eslint-disable-next-line no-restricted-syntax
export const replaceState = (url: string): void => { if (isNewPage(url)) globalThis.history.replaceState({}, "", url); };
export const replacePageUrl = (r: { url: () => string }): void => replaceState(r.url());

// eslint-disable-next-line no-restricted-syntax
export const openInSameTab = (href: string): void => { if (isNewPage(href)) globalThis.location.href = href; };

export const openInNewTab = (href: string): void => { if (isNewPage(href)) globalThis.open(href, "_blank"); };
