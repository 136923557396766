import * as t from "io-ts";

import { bLWindowC } from "@scripts/generated/models/blWindow";

export const pageDataId = "__PAGE_DATA__";
export const pageDataAttribute = "pagedata";

export const makePageDataC =
  <SessionA, SessionO, StoreA, StoreO, PropsA, PropsO>(
    sessionCodec: t.Type<SessionA, SessionO>,
    storeCodec: t.Type<StoreA, StoreO>,
    propsCodec: t.Type<PropsA, PropsO>
  ) =>
    t.type({
      ...bLWindowC(sessionCodec).props,
      props: propsCodec,
      title: t.string,
      initialState: storeCodec,
      url: t.string,
    });

export type PageDataC<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO> =
  ReturnType<typeof makePageDataC<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO>>;

export type PageData<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO> =
  t.TypeOf<PageDataC<SessionA, SessionO, StoreA, StoreO, PropsA, PropsO>>;
