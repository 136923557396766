import { withIdC as imported236_withIdC, withModInfoC as imported181_withModInfoC, WithStatusCU as imported300_WithStatusCU, WithStatusCU as imported301_WithStatusCU } from "./threadThrough";
import * as t from "io-ts";
import { taggedContentC as imported155_taggedContentC, taggedContentC as imported156_taggedContentC, taggedContentC as imported154_taggedContentC } from "./taggedContent";
import { roadShowDataC as imported26_roadShowDataC } from "./roadshow";
import { bondOfferingC as imported23_bondOfferingC } from "./bondOfferingBase";
import { rfpC as imported58_rfpC } from "./rfpBase";
import { readonlyMapFromEntries } from "io-ts-types/lib/readonlyMapFromEntries";
import { Ord as numberOrd } from "fp-ts/lib/number";

export const roadShowsListC = t.type({
  shows: t.readonlyArray(imported236_withIdC(imported181_withModInfoC(imported154_taggedContentC(imported26_roadShowDataC)))),
  offerings: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported300_WithStatusCU(imported155_taggedContentC(imported23_bondOfferingC)))),
  rfps: readonlyMapFromEntries(t.number, numberOrd, t.readonlyArray(imported301_WithStatusCU(imported156_taggedContentC(imported58_rfpC))))
});
export type RoadShowsListC = typeof roadShowsListC;
export type RoadShowsList = t.TypeOf<RoadShowsListC>;


